import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = createUseStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		fontFamily: "Inter-SemiBold",
		color: theme.palette.colors.purplishBlue,
		fontSize: "14px",
		lineHeight: "20px",
		whiteSpace: "nowrap",
		"& svg": {
			fill: "CurrentColor",
		},
		"&:hover": {
			color: "#65A3FF",
		},
	},
	text: {
		marginRight: "7.4px",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			fontSize: "12px",
			lineHeight: "16px",
		},
	},
}));

const MoreLink = (props) => {
	const { text, src, svg } = props;
	const classes = useStyles();

	return (
		<Link className={classes.root} to={src}>
			<div className={classes.text}>{text}</div>
			{svg && svg}
		</Link>
	);
};

MoreLink.propTypes = {
	text: PropTypes.string,
	src: PropTypes.string,
	svg: PropTypes.node,
};
export default MoreLink;
