import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { createUseStyles } from "react-jss";
import NoIndex from "ComponentsUI/NoIndex";

const useStyles = createUseStyles((theme) => ({
	root: {
		margin: "0 auto",
		padding: 0,
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		gap: "8px",
	},
	linkText: {
		marginRight: "16px",
		fontSize: "14px",
		lineHeight: "20px",
		fontFamily: "Inter-Semibold",
		fontWeight: "600",
		color: "#6D7482",
		"&:hover": {
			color: "#5813FE",
		},
	},
	linkLi: {
		marginRight: 0,
		listStyle: "none",
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		linkText: {
			fontSize: "12px",
			lineHeight: "16px",
		},
	},
	[`@media (max-width: ${theme.media.md})`]: {
		root: {
			margin: "0",
			marginBottom: "24px",
			display: "grid",
			gridTemplateColumns: "repeat(2, 2fr)",
			order: 2,
		},
		linkText: {
			height: "32px",
			fontSize: "12px",
			lineHeight: "16px",
			marginRight: "8px",
		},
	},
}));

const FooterNavBar = (props) => {
	const classes = useStyles();
	const { linksList } = props;

	return (
		<div itemScope itemType="http://schema.org/SiteNavigationElement">
			<menu
				className={classes.root}
				itemProp="about"
				itemScope
				itemType="http://schema.org/ItemList"
			>
				{linksList.map((elem) => {
					if (!elem) {
						return null;
					} else if (elem.noIndex) {
						return (
							<li
								key={elem.title}
								className={classes.linkLi}
								itemProp="itemListElement"
								itemScope
								itemType="http://schema.org/ItemList"
							>
								<NoIndex>
									<Link
										to={elem.linkTo}
										rel={"nofollow"}
										className={classes.linkText}
										datatest={elem.datatest}
										itemProp="url"
									>
										{elem.title}
									</Link>
								</NoIndex>
							</li>
						);
					} else if (elem.anotherUrl) {
						return (
							<li
								key={elem.title}
								className={classes.linkLi}
								itemProp="itemListElement"
								itemScope
								itemType="http://schema.org/ItemList"
							>
								<a
									href={elem.linkTo}
									className={classes.linkText}
									datatest={elem.datatest}
									itemProp="url"
								>
									{elem.title}
								</a>
							</li>
						);
					} else {
						return (
							<li
								key={elem.title}
								className={classes.linkLi}
								itemProp="itemListElement"
								itemScope
								itemType="http://schema.org/ItemList"
							>
								<Link
									to={elem.linkTo}
									className={classes.linkText}
									datatest={elem.datatest}
									itemProp="url"
								>
									{elem.title}
								</Link>
							</li>
						);
					}
				})}
			</menu>
		</div>
	);
};

export default FooterNavBar;
