import * as React from "react";
import { withRouter, Redirect } from "react-router-dom";
import url from "url";
import withDefaultParams from "Services/withDefaultParams";
import { graphql } from "@apollo/client/react/hoc";
import { REDIRECT_QUERY_V2 } from "Services/queries";

class RedirectCheck extends React.Component {
	render() {
		if (this.props.data?.loading) {
			return null;
		}

		const redirect = this.props?.data?.redirectV2;

		if (!redirect) {
			return null;
		}

		if (redirect?.from === redirect?.to) {
			return null;
		}

		if (!redirect?.to) {
			return null;
		}

		const parsed = url.parse(redirect.to);

		const location = {
			pathname: !parsed.host ? parsed.pathname : parsed.href,
			search: !parsed.host ? parsed.query : null,
			state: {
				isRedirect: true,
			},
		};

		return <Redirect to={location} />;
	}
}

export default withRouter(
	withDefaultParams(
		graphql(REDIRECT_QUERY_V2, {
			options: (props) => {
				return {
					variables: {
						url: props.location.pathname + props.location.search,
					},
				};
			},
		})(RedirectCheck)
	)
);
