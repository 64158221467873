export const getDeclination = (num, one, two, five) => {
	if (num === undefined || num === null) {
		return null;
	}

	num %= 100;
	if (num >= 5 && num <= 20) {
		return five;
	}
	num %= 10;
	if (num === 1) {
		return one;
	} else if (num >= 2 && num <= 4) {
		return two;
	} else {
		return five;
	}
};

export const getDeclinationV2 = (num, arrayText) => {
	if (num === undefined || num === null || arrayText === undefined || arrayText.length !== 3) {
		return null;
	}
	let number = num;
	num %= 100;

	if (num >= 5 && num <= 20) {
		return `${number} ${arrayText[2]}`;
	}
	num %= 10;
	if (num === 1) {
		return `${number} ${arrayText[0]}`;
	} else if (num >= 2 && num <= 4) {
		return `${number} ${arrayText[1]}`;
	} else {
		return `${number} ${arrayText[2]}`;
	}
};

export const getDeclinationV3 = (num, arrayText) => {
	if (num === undefined || num === null || arrayText === undefined || arrayText.length !== 3) {
		return null;
	}
	let numAbs = Math.abs(num);

	var word = "";

	if (numAbs.toString().indexOf(".") > -1) {
		word = arrayText[1];
	} else {
		word =
			numAbs % 10 == 1 && numAbs % 100 != 11
				? arrayText[0]
				: numAbs % 10 >= 2 && numAbs % 10 <= 4 && (numAbs % 100 < 10 || numAbs % 100 >= 20)
					? arrayText[1]
					: arrayText[2];
	}

	return `${num} ${word}`;
};
