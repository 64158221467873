import withStyles from "react-jss";
import { pipe } from "Utils/functionalUtils";
import { inject, observer } from "mobx-react";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";
import React from "react";
import Container from "reactstrap/lib/Container";
import CardsLinkItem from "./CardsLinkItem";

const styles = (theme) => {
	return {
		Container: {
			marginBottom: "64px",
		},
		CardLinkAddress: {
			fontFamily: theme.fonts.bold,
			color: theme.palette.colors.darkSlateBlue2,
			fontSize: "18px",
			lineHeight: "17px",
			marginBottom: "20px",
			display: "block",
			fontWeight: "600",
			cursor: "pointer",
		},
		CardsLink: {
			fontFamily: theme.fonts.medium,
			backgroundColor: "#FFFFFF",
			padding: "30px 40px",
			columnCount: "2",
			columnGap: "20px",
			borderRadius: "10px",
			border: "1px solid #D2CEE0",
		},
		Links: {
			display: "flex",
			alignItems: "flex-start",
			alignSelf: "stretch",
		},
		[`@media (max-width: ${theme.media.md})`]: {
			CardsLink: {
				columnCount: "2",
			},
		},
		[`@media (max-width: ${theme.media.xs})`]: {
			CardsLink: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				padding: "30px 20px",
			},
			CardLinkAddress: {
				textAlign: "center",
				lineHeight: "20px",
				"&:last-child": {
					marginBottom: 0,
				},
			},
		},
	};
};
const CardsLinkNew = (props) => {
	let {
		ApplicationModel: { text, commonViewModel },
		classes,
		declinations,
		data,
		defaultItems,
		seoBlock,
		place,
	} = props;
	let layoutPlace = place === "улицы" ? `${place}` : "адреса";
	let headerText = `${text.other} ${layoutPlace} ${text.in} ${declinations?.prepositional}`;
	return (
		<Container className={classes.Container}>
			<HeaderTextSeo header={seoBlock?.seoHeader || headerText} text={seoBlock?.seoText} />
			<div className={classes.CardsLink}>
				{data.map((elem, i) => (
					<CardsLinkItem
						key={i}
						className={classes.CardLinkAddress}
						elem={elem}
						text={text}
						defaultItems={defaultItems}
						commonViewModel={commonViewModel}
					/>
				))}
			</div>
		</Container>
	);
};

export default pipe(observer, inject("ApplicationModel"))(withStyles(styles)(CardsLinkNew));
