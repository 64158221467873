import { matchPath } from "react-router";
import Routes from "Configs/routes";
import pathToRegexp from "path-to-regexp";

const arrayCheckRegionUrl = [
	"orders",
	"providers",
	"news",
	"reviews",
	"compare",
	"actions",
	"rates",
	"business",
	"rating",
	"address",
	"feedback",
	"exclusive",
	"about",
	"quiz",
	"user",
	"contact-feedback",
	"operatory",
	"sim-karty",
	"nomera",
	"ratesmobile",
];

const regionRedirectCalc = (path) => {
	let pathname = path;
	let pathParts = pathname.split("/");
	if (pathParts.length > 1) {
		if (!arrayCheckRegionUrl.find((item) => pathParts[1] === item)) {
			pathParts.splice(1, 1);
			pathname = pathParts.join("/");
		}
	} else {
		pathname = "/";
	}
	let findedRoutes = [];
	Object.keys(Routes).forEach((item) => {
		if (!!Routes[item] && !!Routes[item].route) {
			if (
				!!matchPath(path, {
					path: Routes[item].route,
					exact: true,
					strict: true,
				})
			) {
				findedRoutes.push(item);
			}
		}
	});
	let routeName = getRouteName(findedRoutes);
	let compiledPath = null;
	switch (routeName) {
		//оставляем тот же путь, меняется только регион
		case "Default":
		case "AddressDistricts":
		case "Profile":
		case "ProfileApproveLink":
		case "OrderToHome":
		case "OrderOffice":
		case "OrderOuthome":
		case "OrderAss":
		case "Rating":
		case "Business":
		case "Compare":
		case "TermsOfUse":
		case "PersonalData":
		case "AddReview":
		case "Partner":
		case "Quiz":
			break;
		case "AddressStreets":
		case "AddressStreet":
		case "OrderHome":
			compiledPath = pathToRegexp.compile(Routes.OrderToHome.route);
			pathname = compiledPath({
				regionUrl: null,
			});
			break;
		case "ProviderRates":
			compiledPath = pathToRegexp.compile(Routes.ProviderRates.route);
			pathname = compiledPath({
				regionUrl: null,
				providerName: pathParts[pathParts.length - 2],
			});
			break;
		case "ProvidersActionsList":
			compiledPath = pathToRegexp.compile(Routes.ProvidersActionsList.route);
			pathname = compiledPath({
				regionUrl: null,
				providerName: pathParts[pathParts.length - 1],
			});
			break;
		case "Providers":
		case "ProviderRatesTags":
		case "ActionsItem":
			compiledPath = pathToRegexp.compile(Routes.Providers.route);
			pathname = compiledPath({
				regionUrl: null,
				page: null,
			});
			break;
		case "ProviderDetails":
			compiledPath = pathToRegexp.compile(Routes.ProviderDetails.route);
			pathname = compiledPath({
				regionUrl: null,
				providerName: pathParts[pathParts.length - 1],
			});
			break;
		case "Reviews":
			compiledPath = pathToRegexp.compile(Routes.Reviews.route);
			pathname = compiledPath({
				regionUrl: null,
				page: null,
			});
			break;
		case "ProvidersReviewListOld":
			compiledPath = pathToRegexp.compile(Routes.ProvidersReviewListOld.route);
			pathname = compiledPath({
				regionUrl: null,
				providerName: pathParts[pathParts.length - 1],
			});
			break;
		case "ReviewItem":
			compiledPath = pathToRegexp.compile(Routes.Rating.route);
			pathname = compiledPath({
				regionUrl: null,
			});
			break;
		case "Actions":
			compiledPath = pathToRegexp.compile(Routes.Actions.route);
			pathname = compiledPath({
				regionUrl: null,
				page: null,
			});
			break;
		case "Rates":
		case "Exclusive":
			compiledPath = pathToRegexp.compile(Routes.Rates.route);
			pathname = compiledPath({
				regionUrl: null,
				page: null,
			});
			break;
		case "Operatory":
		case "OperatorDetails":
		case "OperatoryTariffs":
		case "OperatoryBeautifulNumbers":
		case "OperatoryActions":
			compiledPath = pathToRegexp.compile(Routes.Operatory.route);
			pathname = compiledPath({
				regionUrl: null,
				page: null,
			});
			break;
		case "RatesMobile":
			compiledPath = pathToRegexp.compile(Routes.RatesMobile.route);
			pathname = compiledPath({
				regionUrl: null,
				page: null,
			});
			break;
		case "BeautifulNumbers":
			compiledPath = pathToRegexp.compile(Routes.BeautifulNumbers.route);
			pathname = compiledPath({
				regionUrl: null,
				page: null,
			});
			break;
		default:
			pathname = "/";
			break;
	}

	return pathname;
};

const getRouteName = (routesArray) => {
	if (routesArray.length === 1) {
		return routesArray[0];
	}

	let filteredArrayRoutes = routesArray.filter((item) => item !== "Default");
	return filteredArrayRoutes[0];
};

export default regionRedirectCalc;
