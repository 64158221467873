import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";

const errorStyle = (theme) => ({
	root: {
		width: "100%",
		fontFamily: theme.fonts.medium,
		fontSize: "16px",
		lineHeight: "1.15",
		color: "#ff586c",
		transform: "scaleY(0)",
		transition: "opacity 1s ease,transform .2s ease",
		opacity: "0",
		transformOrigin: "top left",
		paddingTop: "0",
		overflow: "hidden",
	},
	show: {
		"margin-top": "10px",
		"margin-bottom": "0",
		transform: "scaleY(1)",
		opacity: "1",
	},
	tariffForm: {
		marginTop: "-10px",
		marginBottom: "20px",
	},
	tariffFormContainer: {
		marginTop: "-10px",
		marginBottom: "20px",
	},
	hidden: {
		height: 0,
	},
	[`@media (max-width: ${theme.media.md})`]: {
		root: {
			fontSize: "14px",
		},
	},
});

const Error = withStyles(errorStyle)(({
	children,
	classes,
	show,
	hidden,
	tariffForm,
	tariffFormContainer,
	datatest,
}) => {
	const className = classNames(classes.root, {
		[classes.show]: show,
		[classes.tariffForm]: tariffForm,
		[classes.tariffFormContainer]: tariffFormContainer && show,
		[classes.hidden]: hidden,
	});
	return (
		<div datatest={datatest} className={className}>
			{children}
		</div>
	);
});

export default Error;
