import React from "react";
import { TextField } from "ComponentsUI/TextField";
import withStyles from "react-jss";
import PropTypes from "prop-types";

const formStyle = (theme) => ({
	root: {
		width: "100%",
		display: "flex",
		alignItems: "flex-start",
		position: "relative",
		marginTop: "16px",
		"flex-direction": "column",
	},
	textFieldWrapper: {
		width: "100%",
		marginRight: "auto",
		position: "relative",
	},
	buttonWrapper: {
		width: "auto",
		flexShrink: "0",
		marginLeft: "20px",
	},
	successHeader: {
		fontSize: "24px",
		fontFamily: theme.fonts.bold,
		color: "#f5f7fa",
		lineHeight: "1.2",
		textAlign: "center",
		marginBottom: "22px",
	},
	successSubHeader: {
		fontSize: "16px",
		fontFamily: theme.fonts.light,
		color: "#f5f7fa",
		lineHeight: "1.2",
		textAlign: "center",
	},
	dropdownWrapper: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		position: "relative",
		"& div:first-child": {
			width: "100%",
			flexShrink: "1",
			marginRight: "20px",
		},
		"& div:last-child": {
			width: "100%",
			flexShrink: "2",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			flexWrap: "wrap",
			marginTop: "24px",
		},
		buttonWrapper: {
			width: "100%",
			marginTop: "10px",
			marginLeft: "0",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			flexWrap: "wrap",
		},
		buttonWrapper: {
			width: "100%",
			marginTop: "20px",
			marginLeft: "0",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			flexWrap: "wrap",
		},
		buttonWrapper: {
			width: "100%",
			marginTop: "10px",
			marginLeft: "0",
		},
	},
});

const AdForm = withStyles(formStyle)(({
	children,
	classes,
	isSuccess,
	successHeader,
	successSubHeader,
}) => {
	if (!isSuccess) {
		let dropdDownChildren = [];
		let newChildren = [];
		children.map((child, i) => {
			if (!child) return null;
			if (child.type.displayName === "TextField") {
				return newChildren.push(
					<div key={i} className={classes.textFieldWrapper}>
						{React.cloneElement(child, {
							...child.props,
							key: i,
						})}
					</div>
				);
			}

			if (child.type.displayName === "Dropdown") {
				return dropdDownChildren.push(React.cloneElement(child, { ...child.props, key: i }));
			}

			if (child.type.displayName === "Button") {
				return newChildren.push(
					<div key={i} className={classes.buttonWrapper}>
						{React.cloneElement(child, {
							...child.props,
							key: i,
						})}
					</div>
				);
			}
			return newChildren.push(React.cloneElement(child, { ...child.props, key: i }));
		});
		if (dropdDownChildren.length) {
			return (
				<div className={classes.root}>
					<div className={classes.dropdownWrapper}>{dropdDownChildren}</div>
					{newChildren}
				</div>
			);
		}
		return <div className={classes.root}>{newChildren}</div>;
	} else {
		return (
			<div className={classes.root}>
				<div className={classes.successHeader}>{successHeader}</div>
				<div className={classes.successSubHeader}>{successSubHeader}</div>
			</div>
		);
	}
});

AdForm.propTypes = {
	children: PropTypes.node,
};

export default AdForm;
