import React, { Component } from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import { LeftArrow, RightArrow } from "ComponentsUI/CarouselComponents";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";

const styles = (theme) => ({
	root: {
		paddingTop: "10px",
	},
	header: {
		margin: "10px 0",
		fontSize: "36px",
		textAlign: "center",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.bold,
	},
	carousel: {
		margin: "20px 0",
		position: "relative",
		padding: "10px 40px",
		display: "flex",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
	},
	arrow: {
		top: "60px",
		padding: "0",
		fontSize: "24px",
		lineHeight: "24px",
		color: theme.palette.colors.white,
		display: "block",
	},
	list: {
		width: "1120px",
		overflow: "hidden",
		"& ul": {
			height: "130px",
			width: "199999px",
			margin: "0",
			padding: "0",
			listStyle: "none",
			transition: "margin-left 500ms",
		},
		"& li": {
			display: "inline-block",
		},
	},
	backgroundNoneHeader: {
		color: theme.palette.colors.darkSlateBlue,
	},
	backgroundNoneButton: {
		color: theme.palette.colors.purplishBlue,
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		list: {
			width: "900px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		list: {
			width: "650px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		header: {
			fontSize: "24px",
		},
		list: {
			width: "160px",
			minWidth: "160px",
		},
		carousel: {
			padding: "10px",
			justifyContent: "space-between",
		},
	},
});

class CarouselUi extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: this.props.count,
			width: 0,
			space: 0,
			positionStyle: 0,
			position: 0,
			listLength: this.props.list.length,
			singleList: this.props.list,
			listCarousel: this.props.list,
		};
	}

	// Родительский общий элемент самой карусели без кнопок
	setDivContainer(container) {
		this.divContainer = container;
	}

	componentDidMount() {
		this.initList();
	}

	componentDidUpdate(prevProps) {
		let isChanged = false;
		// Заново инициализировать карусель из-за изменения размера экрана
		Object.keys(this.props.windowSizes).forEach((item) => {
			if (this.props.windowSizes[item] !== prevProps.windowSizes[item]) {
				isChanged = true;
			}
		});

		isChanged && this.initList();
	}

	// Все элементы list всегда должны состоять из тегов li.
	// Сделать все элементы массива тегом li
	remakeList(arr) {
		return arr.map((elem, index) => {
			return <li key={index}>{elem}</li>;
		});
	}

	// Установка всех настроек для карусели
	initList() {
		let liContainer = this.divContainer.querySelector("li");
		let list = this.divContainer.querySelector("ul");

		let width = parseInt(getComputedStyle(liContainer).width) + 1;
		this.setState({ width, positionStyle: 0 });
		let widthContainer = width * this.state.count;

		if (this.state.count >= this.props.list.length) {
			list.style.width = widthContainer + "px";
			list.style.textAlign = "center";
		}
	}

	// Нажатие влево
	previousSlide() {
		let list = this.divContainer.querySelector("ul");
		// Сдвиг в сторону
		let positionStyle = this.state.positionStyle;
		positionStyle += this.state.width * this.state.count;

		// Если список закончился, начинаем с конца
		if (positionStyle === this.state.width * this.state.count) {
			positionStyle = -((this.state.listLength - this.state.count) * this.state.width);
		}
		// Последнее передвижение влево может быть на меньшее количество элементов
		positionStyle = Math.min(positionStyle, 0);
		//TODO Сделать возможность рендеринга списка в начале карусели, чтобы была возможность к ней прокрутиться
		// if (this.props.infinity && positionStyle == 0) {
		//     let newList = this.state.singleList.concat(this.state.listCarousel);
		//     this.setState({listCarousel: newList});
		// }
		list.style.marginLeft = positionStyle + "px";
		this.setState({ positionStyle: positionStyle });
	}

	// Нажатие вправо
	nextSlide() {
		let list = this.divContainer.querySelector("ul");
		let listElems = this.divContainer.querySelectorAll("li");
		// Сдвиг в сторону
		let positionStyle = this.state.positionStyle;
		positionStyle -= this.state.width * this.state.count;

		// Если список закончился, начинаем сначала
		if (positionStyle == -this.state.listLength * this.state.width) {
			positionStyle = 0;
		}
		// Последнее передвижение вправо может быть на меньшее количество элементов
		positionStyle = Math.max(
			positionStyle,
			-this.state.width * (listElems.length - this.state.count)
		);
		// Бесконечная карусель, которая работает
		// if (this.props.infinity && positionNoMath !== positionStyle) {
		//     // let newList = this.state.listCarousel.concat(this.state.singleList);
		//     // this.setState({listCarousel: newList});
		// }
		list.style.marginLeft = positionStyle + "px";
		this.setState({ positionStyle: positionStyle });
	}

	// Генерация всей карусели
	generateList() {
		let { classes, remake, defaultButtons, backgroundNone } = this.props;
		let list = this.state.listCarousel;

		let visibleButton = this.state.count < list.length;

		return (
			<>
				{defaultButtons && visibleButton && (
					<LeftArrow
						className={classNames(classes.arrow, {
							[classes.backgroundNoneButton]: backgroundNone,
						})}
						previousSlide={() => this.previousSlide()}
					/>
				)}
				<div className={classes.list}>
					<ul>{remake ? this.remakeList(list) : list}</ul>
				</div>
				{defaultButtons && visibleButton && (
					<RightArrow
						className={classNames(classes.arrow, {
							[classes.backgroundNoneButton]: backgroundNone,
						})}
						nextSlide={() => this.nextSlide()}
					/>
				)}
			</>
		);
	}

	render() {
		let { classes, declinations, title, backgroundNone, seoBlock } = this.props;

		return (
			<div className={classes.root}>
				{title && (
					<HeaderTextSeo
						header={seoBlock?.seoHeader || `${title} ${declinations}`}
						text={seoBlock?.seoText}
						whiteHeader={!backgroundNone}
					/>
				)}
				<div
					ref={(divContainer) => {
						this.setDivContainer(divContainer);
					}}
					className={classes.carousel}
				>
					{this.generateList()}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(CarouselUi);
