import React from "react";
import PropTypes from "prop-types";
import FooterTopTitle from "ComponentsUIV2/Footer/FooterTopTitle";
import { useState } from "react";

const FooterTopTitleContainer = (props) => {
	const { isLink, isButton, text, clickedBlock, src } = props;
	const [isOpen, setIsOpen] = useState(false);

	const clickHandler = () => {
		setIsOpen(!isOpen);
		clickedBlock();
	};

	return (
		<FooterTopTitle
			isLink={isLink}
			src={src}
			isButton={isButton}
			text={text}
			clickHandler={clickHandler}
			isOpen={isOpen}
		/>
	);
};

FooterTopTitleContainer.propTypes = {
	isLink: PropTypes.bool,
	isButton: PropTypes.bool,
	text: PropTypes.string,
};

export default FooterTopTitleContainer;
