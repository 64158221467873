import React from "react";
import { observer, inject } from "mobx-react";
import Metrika from "Services/Metrika";
import { EVENT_CATEGORY_PAGE_VIEW } from "Constants/metriks/GoogleCategorys";
import { CLICK_LOAD_POPUP_TC } from "Constants/metriks/GoogleActions";
import PopupAContainer from "Containers/PopupAContainer";

@inject("ApplicationModel")
@observer
export default class PopupAHOC extends React.Component {
	onClickHandler() {
		Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_PAGE_VIEW, CLICK_LOAD_POPUP_TC);
		let modalModel = this.props.ApplicationModel.modalModel;
		modalModel.setPopupA(true);
		let promise1 = import("Layouts/modalLayout");
		promise1.then((modules) => {
			let ModalLayout = modules.default;
			modalModel.toggleModal(
				<ModalLayout
					closeModal={() => this.closeModal()}
					fullCloseElement={true}
					onlyDesktopCloseElement={true}
					closeOnClickLayout={true}
					popupB={true}
					withoutClose
				>
					<PopupAContainer closeModal={() => this.closeModal()} tariffId={this.props.tariffId} />
				</ModalLayout>
			);
		});
	}

	closeModal() {
		let modalModel = this.props.ApplicationModel.modalModel;
		modalModel.toggleModal();
		modalModel.setPopupA(false);
	}

	render() {
		return React.cloneElement(this.props.children, {
			onClick: () => this.onClickHandler(),
		});
	}
}
