var gql = require("@apollo/client").gql;

const REVIEWS_MAIN_PAGE_V3 = gql`
	query ReviewsProvidersMainPage($filter: String, $providerJoin: Boolean) {
		reviewsMainPage(filter: $filter, providerJoin: $providerJoin) {
			data {
				id
				title
				date
				rating_type
				review
				views_all
				provider {
					id
					name
					url_name
					logo_2
					contacts {
						phones
					}
				}
				region {
					name
					id
				}
				author {
					id
					name
				}
				status {
					code
				}
			}
		}
	}
`;

export default REVIEWS_MAIN_PAGE_V3;
