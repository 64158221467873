import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter, NavLink, useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { generateUrl } from "Services/withDefaultParams";
import useDefaultParams from "Services/useDefaultParams";
import { sendMetriks } from "Services/sendMetriks";
import { isMoscowSiteCode, isMainSiteCode, isPiterSiteCode } from "Utils/siteConfig";
import Routes from "Configs/routes";
import compileTerms from "Texts/terms";
import CompilePrivate from "Texts/private";
import { compilePrivatePart2 } from "Texts/private_part_2";
import { ModalLayout } from "ComponentsUI/Modallayout";

const useStyles = createUseStyles((theme) => ({
	root: {
		marginTop: "16px",
		fontFamily: theme.fonts.light,
	},
	rootFindTariffs: {
		textAlign: "center",
	},
	rootOneClick: {
		textAlign: "left",
	},
	rootOutHome: {
		textAlign: "center",
	},
	rootTariffFormContainer: {},
	rootQuiz: {
		textAlign: "center",
	},
	rootFeedback: {
		margin: "0 auto",
		textAlign: "center",
	},
	textAlignCenter: {
		textAlign: "center",
	},
	largeMarginTop: {
		marginTop: "35px",
	},
	text: {
		fontSize: "16px",
	},
	textTariffFormContainer: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	textFindTariffs: {
		fontSize: "14px",
		color: theme.palette.colors.white,
	},
	textOneClick: {
		fontSize: "14px",
		color: theme.palette.colors.white,
	},
	textTender: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	textOutHome: {
		fontSize: "14px",
		color: theme.palette.colors.white,
	},
	textPopupB: {
		color: theme.palette.colors.white,
	},
	textPopupQuiz: {
		color: theme.palette.colors.oneMoreGrey,
	},
	textPopupOperator: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	textRegistry: {
		fontSize: "12px",
		color: theme.palette.colors.cloudyBlue,
	},
	textTariffOrderPopup: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	textAddReview: {
		color: theme.palette.colors.headerColour,
	},
	textPartners: {
		color: theme.palette.colors.oneMoreGrey,
	},
	textFeedback: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	textIncorrectHousePopup: {
		color: theme.palette.colors.oneMoreGrey,
	},
	textLink: {
		fontSize: "16px",
		color: theme.palette.colors.black,
		textDecoration: "underline",
	},
	linkTariffFormContainer: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	linkFindTariffs: {
		fontSize: "14px",
		color: theme.palette.colors.white,
	},
	linkOneClick: {
		fontSize: "14px",
		color: theme.palette.colors.white,
	},
	linkTender: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	linkOutHome: {
		fontSize: "14px",
		color: theme.palette.colors.white,
	},
	linkPopupB: {
		color: theme.palette.colors.white,
	},
	linkPopupQuiz: {
		color: theme.palette.colors.oneMoreGrey,
	},
	linkPopupOperator: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	linkRegistry: {
		fontSize: "12px",
		color: theme.palette.colors.cloudyBlue,
	},
	linkAddReview: {
		color: theme.palette.colors.headerColour,
	},
	linkPartners: {
		color: theme.palette.colors.oneMoreGrey,
	},
	linkFeedback: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	linkIncorrectHousePopup: {
		color: theme.palette.colors.oneMoreGrey,
	},
	linkTariffOrderPopup: {
		fontSize: "14px",
		color: theme.palette.colors.oneMoreGrey,
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		linkOneClick: {
			fontSize: "12px",
		},
		textOneClick: {
			fontSize: "12px",
		},
		rootOneClick: {
			marginTop: "10px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		rootTariffFormContainer: {
			textAlign: "center",
		},
		rootQuiz: {},
		textTariffFormContainer: {
			fontSize: "12px",
		},
		textPopupQuiz: {
			fontSize: "14px",
		},
		textAddReview: {
			fontSize: "14px",
		},
		textPartners: {
			fontSize: "14px",
		},
		textFeedback: {
			fontSize: "12px",
		},
		textIncorrectHousePopup: {
			fontSize: "12px",
		},
		extOneClick: {
			fontSize: "12px",
		},
		linkTariffFormContainer: {
			fontSize: "12px",
		},
		linkPopupQuiz: {
			fontSize: "14px",
		},
		linkAddReview: {
			fontSize: "14px",
		},
		linkPartners: {
			fontSize: "14px",
		},
		linkFeedback: {
			fontSize: "12px",
		},
		linkIncorrectHousePopup: {
			fontSize: "12px",
		},
		linkOneClick: {
			fontSize: "10px",
		},
		textOneClick: {
			fontSize: "10px",
		},
		rootOneClick: {
			marginTop: "10px",
		},
	},
}));
//
const TermsOfUse = (props) => {
	const {
		isTariffFormContainer,
		isFindTariffs,
		isOneClick,
		isTender,
		isOutHome,
		isPopupB,
		isPopupQuiz,
		isPopupOperator,
		isRegistry,
		isAddReview,
		isPartners,
		isFeedback,
		isTariffOrderPopup,
		isIncorrectHousePopup,
		largeMarginTop,
		buttonText,
		textAlignCenter,
		ApplicationModel,
		ApplicationModel: { text, siteConfig },
	} = props;
	const classes = useStyles();
	let rootClasses = {
		[classes.rootFindTariffs]: isFindTariffs,
		[classes.rootOneClick]: isOneClick,
		[classes.rootOutHome]: isOutHome,
		[classes.rootTariffFormContainer]: isTariffFormContainer,
		[classes.rootQuiz]: isPopupQuiz,
		[classes.rootFeedback]: isFeedback,
		[classes.largeMarginTop]: largeMarginTop,
		[classes.textAlignCenter]: textAlignCenter,
	};
	let textClasses = {
		[classes.textTariffFormContainer]: isTariffFormContainer,
		[classes.textFindTariffs]: isFindTariffs,
		[classes.textOneClick]: isOneClick,
		[classes.textTender]: isTender,
		[classes.textOutHome]: isOutHome,
		[classes.textPopupB]: isPopupB,
		[classes.textPopupQuiz]: isPopupQuiz,
		[classes.textPopupOperator]: isPopupOperator,
		[classes.textAddReview]: isAddReview,
		[classes.textPartners]: isPartners,
		[classes.textFeedback]: isFeedback,
		[classes.textIncorrectHousePopup]: isIncorrectHousePopup,
		[classes.textRegistry]: isRegistry,
		[classes.textTariffOrderPopup]: isTariffOrderPopup,
	};
	let linkClasses = {
		[classes.linkTariffFormContainer]: isTariffFormContainer,
		[classes.linkFindTariffs]: isFindTariffs,
		[classes.linkOneClick]: isOneClick,
		[classes.linkTender]: isTender,
		[classes.linkOutHome]: isOutHome,
		[classes.linkPopupB]: isPopupB,
		[classes.linkPopupQuiz]: isPopupQuiz,
		[classes.linkPopupOperator]: isPopupOperator,
		[classes.linkAddReview]: isAddReview,
		[classes.linkPartners]: isPartners,
		[classes.linkFeedback]: isFeedback,
		[classes.linkIncorrectHousePopup]: isIncorrectHousePopup,
		[classes.linkRegistry]: isRegistry,
		[classes.linkTariffOrderPopup]: isTariffOrderPopup,
	};

	const routeParams = useParams();
	const { regionUrl } = useDefaultParams(routeParams);
	const urlTermsOfUse = generateUrl(Routes.TermsOfUse.route, {
		regionUrl: regionUrl,
	});
	const urlPersonalData = generateUrl(Routes.PersonalData.route, {
		regionUrl: regionUrl,
	});

	const openModal = async (about) => {
		const { modalModel, text, windowSizeModel } = ApplicationModel;

		const sitePrefix = `http${siteConfig.ssl ? "s" : ""}://${
			isMoscowSiteCode(siteConfig.code) ? "www." : ""
		}`;

		let titleName =
			about === "TermsOfUse"
				? text.TERMS_OF_USE
				: about === "PersonalData"
					? text.PERSONAL_DATA_TITLE
					: about === "Processing"
						? text.PROCESSING_PERSONAL_DATA_TITLE
						: "";

		let companyName = isMoscowSiteCode(siteConfig.code)
			? text.company_name_moskvaonline
			: isPiterSiteCode(siteConfig.code)
				? text.company_name_piteronline
				: isMainSiteCode(siteConfig.code)
					? text.company_name_101internet
					: "";

		let address = isMoscowSiteCode(siteConfig.code)
			? text.address_moskvaonline
			: isPiterSiteCode(siteConfig.code)
				? text.address_piteronline
				: isMainSiteCode(siteConfig.code)
					? text.address_101internet
					: "";

		let compileTermText = compileTerms(siteConfig.domain_name, urlTermsOfUse, sitePrefix);

		let compilePrivateText = (
			<CompilePrivate
				domain={siteConfig.domain_name}
				location={urlPersonalData}
				shema={sitePrefix}
				company={companyName}
				address={address}
				siteCode101={isMainSiteCode(siteConfig.code)}
			/>
		);

		let processingPrivateText = compilePrivatePart2(
			siteConfig.domain_name,
			sitePrefix,
			companyName,
			siteConfig.code
		);

		let fullText =
			about === "TermsOfUse"
				? compileTermText
				: about === "PersonalData"
					? compilePrivateText
					: about === "Processing"
						? processingPrivateText
						: "";

		const News = await import("ComponentsUI/News");

		modalModel.toggleModalAgreement(
			<ModalLayout
				newsView={true}
				fullCloseElement={true}
				onlyDesktopCloseElement={true}
				closeOnClickLayout={true}
				withoutClose={true}
			>
				<News.FullNewsItem
					toggleWindow={() => modalModel.isOpenAgreement && modalModel.toggleModalAgreement()}
					windowSizes={windowSizeModel.windowSizes}
					typeB={true}
					texts={text}
					name={titleName}
					full_text={fullText}
					showTextCloseButton
					textCloseButtonContent={text.CLOSE_BUTTON}
					changeTable={true}
					siteCode={siteConfig.code}
					noDangerously={about == "PersonalData"}
				/>
			</ModalLayout>
		);
	};

	const handleLinkClickTermsOfUse = (e) => {
		e.preventDefault();

		sendMetriks("CLICK_TERMS_OF_USE_FROM_ORDER");
		openModal("TermsOfUse");
	};
	const handleLinkClickPoliticsPersonalData = (e) => {
		e.preventDefault();

		sendMetriks("CLICK_PERSONAL_DATA_FROM_ORDER");
		openModal("PersonalData");
	};

	const handleLinkClickProcessingPersonalData = (e) => {
		e.preventDefault();

		sendMetriks("CLICK_CONSENT_FROM_ORDER");
		openModal("Processing");
	};

	// if (isRegistry) {
	// 	return (
	// 		<div className={classes.root}>
	// 			<span className={classNames(classes.text, classes.textRegistry)}>
	// 				{text.AGREE_WITH_SOMETHING_1}
	// 			</span>
	// 			<NavLink to={urlTermsOfUse} onClick={handleLinkClickTermsOfUse} exact>
	// 				<span className={classNames(classes.textLink, classes.linkRegistry)}>
	// 					{text.AGREE_WITH_SOMETHING_2}
	// 				</span>
	// 			</NavLink>
	// 			<span className={classNames(classes.text, classes.textRegistry)}>
	// 				{text.AGREE_WITH_SOMETHING_3}
	// 			</span>
	// 		</div>
	// 	);
	// }

	return (
		<div className={classNames(classes.root, rootClasses)}>
			<span className={classNames(classes.text, textClasses)}>
				{text.terms_of_use_new_1}
				{buttonText}
				{text.terms_of_use_new_2}
			</span>
			<NavLink
				to={urlPersonalData}
				onClick={handleLinkClickProcessingPersonalData}
				aria-label="Согласие на обработку перс. данных"
				exact
			>
				<span className={classNames(classes.textLink, linkClasses)}>{text.terms_of_use_new_3}</span>
			</NavLink>
			<span className={classNames(classes.text, textClasses)}>{text.terms_of_use_new_4}</span>
			<NavLink
				to={urlPersonalData}
				onClick={handleLinkClickPoliticsPersonalData}
				aria-label="Политика обработки перс. данных"
				exact
			>
				<span className={classNames(classes.textLink, linkClasses)}>{text.terms_of_use_new_5}</span>
			</NavLink>
			<span className={classNames(classes.text, textClasses)}>{text.terms_of_use_new_6}</span>
		</div>
	);
};

export default withRouter(inject("ApplicationModel")(observer(TermsOfUse)));
