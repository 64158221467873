import React from "react";
import withStyles from "react-jss";

const styles = (theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		margin: "16px 0",
		justifyContent: "center",
	},
	loading: {
		color: theme.palette.colors.purplishBlue,
		lineHeight: 0,
		fontSize: "20px",
		transform: "rotate(0deg)",
		animation: "rotate360 1s linear infinite",
		transformOrigin: "50%",
		display: "block",
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		border: `1px ${theme.palette.colors.purplishBlue} solid`,
		borderRight: "none",
		padding: 0,
	},
	text: {
		marginLeft: "16px",
		fontFamily: theme.fonts.medium,
		color: theme.palette.colors.darkSlateBlue,
		lineHeight: 1,
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {},
		text: {
			fontSize: "14px",
		},
	},
});

const Loading = ({ classes, text }) => (
	<div className={classes.root}>
		<div className={classes.loading} />
		<div className={classes.text}>{text}</div>
	</div>
);

export default withStyles(styles)(Loading);
