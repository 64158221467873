import React from "react";
import { observer, inject } from "mobx-react";
import Metrika from "Services/Metrika";
import { CLICK_PHONE_HEADER } from "Constants/metriks/Yandex";
import {
	Header,
	UpperPart,
	BottomPart,
	MobileWindow,
	FixedNavigation,
} from "ComponentsUI/Navigation";
import { withRouter } from "react-router-dom";
import MainMenuContainer from "Containers/MainMenuContainer";
import Routes from "Configs/routes";
import { generateUrl } from "Services/withDefaultParams";
import withDefaultParams from "Services/withDefaultParams";
import { replaceStaticHost } from "Helpers/ImageManager";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";
import { EVENT_ACTION_CLICK_BUTTON_ADDRESS_HEADER } from "Constants/metriks/GoogleActions";
import { withPhone } from "HOC/WithPhone";
import ReactDOM from "react-dom";

@withRouter
@withDefaultParams
@withPhone
@inject("ApplicationModel")
@observer
export default class HeaderContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showNavigation: false,
			showFindTariffs: false,
			fixedMenu: false,
			refs: [],
		};
	}

	openMenu() {
		let model = this.props.ApplicationModel.commonViewModel;
		model.toggleMobileMenu();
	}

	closeMenu() {
		let model = this.props.ApplicationModel.commonViewModel;
		model.toggleMobileMenu();
	}

	toggleMenu() {
		this.setState({
			fixedMenu: !this.state.fixedMenu,
		});
	}

	changeShowNavigation(value) {
		this.setState({
			showNavigation: value,
			showFindTariffs: !value && !!this.state.showFindTariffs ? false : this.state.showFindTariffs,
			fixedMenu: !value && !!this.state.fixedMenu ? false : this.state.fixedMenu,
		});
		let model = this.props.ApplicationModel.commonViewModel;
		model.setShowFindTariffs(
			!value && !!this.state.showFindTariffs ? false : this.state.showFindTariffs
		);
	}

	mouseDownFunc = (e) => {
		this.mouseDown(e);
	};
	mouseDown(event) {
		const div = ReactDOM.findDOMNode(this.state.refs[0]);
		if (this.state.refs[0] && !div.contains(event.target)) {
			let model = this.props.ApplicationModel.commonViewModel;
			model.setShowFindTariffs(false);
		}
	}

	toggleFindTariffs() {
		let model = this.props.ApplicationModel.commonViewModel;
		if (model.showFindTariffs) {
			// document.removeEventListener("mousedown", this.mouseDownFunc);
			model.showSideWidget();
		} else {
			model.hideSideWidget();
			// document.addEventListener("mousedown", this.mouseDownFunc);
			Metrika.getInstance().google.sendEvent(
				EVENT_CATEGORY_CLICK_BUTTON,
				EVENT_ACTION_CLICK_BUTTON_ADDRESS_HEADER
			);
		}

		model.setShowFindTariffs(!model.showFindTariffs);
		this.setState({
			showFindTariffs: !this.state.showFindTariffs,
		});
	}

	render() {
		let text = this.props.ApplicationModel.text;
		let showMobileMenu = this.props.ApplicationModel.commonViewModel.showMobileMenu;
		let siteCode = this.props.ApplicationModel.siteCode;
		let currentRegionUrl = this.props.ApplicationModel.currentRegion?.url;
		let hrefToMain = generateUrl(
			Routes.Default.route,
			{
				regionUrl: this.props.match.params.regionUrl,
			},
			currentRegionUrl
		);
		let phone404 = this.props.phone404;
		let xs = this.props.ApplicationModel.windowSizeModel.windowSizes.xs;
		let upperPart = (
			<UpperPart
				openMobileMenu={() => this.openMenu()}
				hrefToMain={hrefToMain}
				logo={
					this.props.ApplicationModel.siteConfig &&
					replaceStaticHost(this.props.ApplicationModel.siteConfig.logo_4)
				}
				fullLogo={
					this.props.ApplicationModel.siteConfig &&
					replaceStaticHost(this.props.ApplicationModel.siteConfig.logo)
				}
				phone404={phone404}
				siteCode={siteCode}
			/>
		);
		let bottomPart = <BottomPart menu={<MainMenuContainer />} />;
		let mobileWindow = (
			<MobileWindow
				closeMobileMenu={() => this.closeMenu()}
				show={showMobileMenu}
				wrapNameRegion={true}
				phone404={phone404}
			/>
		);
		let widgetMenu = (
			<MobileWindow
				closeMobileMenu={() => this.toggleMenu()}
				show={this.state.fixedMenu}
				inWidget={true}
			/>
		);
		let showPhone = true;
		if (this.props.siteRegionPhones && !phone404) {
			if (this.props.siteRegionPhones.hasOwnProperty("show_on_site")) {
				showPhone = this.props.siteRegionPhones.show_on_site;
			}
		} else if (phone404) {
			showPhone = true;
		} else {
			showPhone = false;
		}
		let model = this.props.ApplicationModel.commonViewModel;
		let showFindTariffs = model.showFindTariffs;
		// let showFindTariffs = this.state.showFindTariffs;
		let fixedNavigation = (
			<FixedNavigation
				text={text}
				hrefToMain={hrefToMain}
				logo={
					this.props.ApplicationModel.siteConfig &&
					replaceStaticHost(this.props.ApplicationModel.siteConfig.logo_3)
				}
				showNavigation={this.state.showNavigation}
				openMenu={() => this.toggleMenu()}
				widgetMenu={widgetMenu}
				menu={<MainMenuContainer purpleColor={true} withoutMain={true} />}
				openMobileMenu={() => this.openMenu()}
				toggleFindTariffs={() => this.toggleFindTariffs()}
				showFindTariffs={showFindTariffs}
				showOnSite={showPhone}
				xs={xs}
				refs={this.state.refs}
				phone404={phone404}
				changeWhatsappHeader={this.props.changeWhatsappHeader}
			/>
		);
		return (
			<Header
				changeShowNavigation={(value) => this.changeShowNavigation(value)}
				showNavigation={this.state.showNavigation}
				onClickHandler={() => this.onClickHandler()}
				openMobileMenu={() => this.openMenu()}
				upperPart={upperPart}
				bottomPart={bottomPart}
				mobileWindow={mobileWindow}
				fixedNavigation={fixedNavigation}
				siteCode={siteCode}
			/>
		);
	}
}
