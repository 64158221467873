import React from "react";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";

export default class PhoneEmailMask extends React.Component {
	static propTypes = {
		dataTest: PropTypes.string,
		className: PropTypes.string.isRequired,
		value: PropTypes.string,
		changeCallback: PropTypes.func,
		onMount: PropTypes.func,
		onFocus: PropTypes.func,
		onEnter: PropTypes.func,
	};

	static defaultProps = {
		dataTest: "",
		className: "",
		changeCallback: () => {},
		onMount: () => {},
		onFocus: () => {},
		onEnter: () => {},
	};

	constructor(props) {
		super(props);
		this.state = {
			isPhone: this.checkOnTypePhoneInput(this.props.value),
		};
	}

	componentDidMount() {
		this.props.onMount();
	}

	componentDidUpdate() {
		let value = this.input.value;
		if (this.state.isPhone && this.props.value.length == 1) {
			this.input.blur();
			this.input.focus();
		}
	}

	changeHandler(e) {
		let value = e.target.value;
		if (this.state.isPhone) {
			value = value.replace(/[+()\-\\ \_|[\]\\]/g, "");
			if (!this.checkOnTypePhoneInput(value)) {
				if (value.length > 1 && !!this.props.value) {
					value = value.substring(1);
				}

				this.setState({
					isPhone: false,
				});
			}
		}
		if (!value || e.target.value == "+7 (") {
			let isPhone = this.state.isPhone;
			this.setState({
				isPhone: true,
			});
			this.props.changeCallback("");
			return;
		}
		this.props.changeCallback(value);
	}

	filterInt(value) {
		if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) return true;
		return false;
	}

	checkOnTypePhoneInput(value) {
		if (!value) {
			return true;
		}
		return this.filterInt(value);
	}

	formatChars = {
		"*": ".",
		"&": "\\D",
	};

	onKeyPress(e) {
		if (e.charCode === 13) {
			this.props.onEnter(e);
		}
	}

	render() {
		let isPhone = this.state.isPhone;
		let options = {
			mask:
				isPhone && !!this.props.value
					? `+7 (***) ***-**-**&`
					: "******************************************",
			formatChars: this.formatChars,
			maskChar: null,
			className: this.props.className,
			onChange: (e) => {
				this.changeHandler(e);
			},
			// beforeMaskedValueChange: this.beforeMaskedValueChange,
			onFocus: this.props.onFocus,
			onKeyPress: (e) => this.onKeyPress(e),
			datatest: this.props.datatest,
			autoComplete: "on",
			id: this.props.id,
			inputRef: (input) => (this.input = input),
			autoFocus: this.props.auoFocus,
		};
		if (typeof this.props.value === "string") {
			options.value = this.props.value;
			if (options.value == "7" && this.state.isPhone) {
				options.value = "77";
			}
		}
		return <InputMask {...options} />;
	}
}
