var gql = require("@apollo/client").gql;

const ADDRESS_INTER_LINKS_V2 = gql`
	query ADDRESS_INTER_LINKS_V2($address_id: Int!) {
		addressInterLinksV2(address_id: $address_id) {
			data {
				id
				house
				building
				construction
				url
				avg_price
				avg_speed
				price_diff
				review {
					review
					date
					provider {
						name
					}
					author {
						name
					}
				}
				street {
					id
					name
					full
					suburb
					type
				}
				district {
					id
					parentId
					name
					is_city
					service
				}
				providers {
					id
					name
					logo
				}
			}
		}
	}
`;

module.exports = ADDRESS_INTER_LINKS_V2;
