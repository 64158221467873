import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { ReviewItem } from "ComponentsUI/Reviews";
import { Link } from "react-router-dom";
import emotions from "Helpers/emotions";
import { webpSimpleTransformer, webpTransformer } from "Helpers/ImageManager";

export default class ReviewItemContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			wasViewed: false,
		};
	}

	wasViewed(isVisible) {
		if (!this.props.stub && isVisible && !this.state.wasViewed) {
			this.setState({
				wasViewed: true,
			});
			this.props.markReviewAsViewed(this.props.item.id);
		}
	}

	render() {
		return (
			<VisibilitySensor onChange={(isVisible) => this.wasViewed(isVisible)}>
				<ReviewItem
					{...this.props}
					logo={this.props.item ? this.props.item.provider.logo_2 : ""}
					rating={this.props.item ? this.props.item.rating : 0}
					webpTransformer={webpSimpleTransformer}
					linkContainer={<Link to={"/"} />}
				/>
			</VisibilitySensor>
		);
	}
}
