import React from "react";
import classNames from "classnames";
import { Col } from "ComponentsUI/Styles/Grid";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.colors.transparent,
		paddingBottom: "60px",
	},
	rootReviewsProvider: {
		marginTop: "40px",
		paddingBottom: "20px",
	},
	wrapper: {
		paddingBottom: "46px",
	},
	withoutBottomPadding: {
		paddingBottom: "0px !important",
	},
	headerWrapper: {
		display: "grid",
		gridAutoFlow: "column",
		justifyContent: "space-between",
		paddingLeft: "30px",
		alignItems: "end",
		gap: "20px",
		gridTemplateColumns: "1fr",
		gridAutoColumns: "auto",
	},
	headerText: {
		fontFamily: theme.fonts.bold,
		fontSize: "18px",
		lineHeight: 2,
		letterSpacing: "2px",
		color: theme.palette.colors.darkSlateBlue,
		margin: 0,
		padding: 0,
		textTransform: "uppercase",
		position: "relative",
		marginLeft: "31px",
		"&:after": {
			content: "''",
			position: "absolute",
			height: "2px",
			width: "79px",
			bottom: "-9px",
			left: 0,
			backgroundColor: theme.palette.colors.purplishBlue,
		},
	},
	headerWithoutMargin: {
		marginLeft: "0 !important",
	},
	headerTextWhite: {
		color: theme.palette.colors.white,
	},
	hideUnderLine: {
		"&:after": {
			display: "none",
		},
	},
	buttonsWrapper: {
		display: "flex",
	},
	headerContent: {
		paddingLeft: "10px",
	},
	headerButton: {
		width: "auto",
		marginRight: "20px",
		color: theme.palette.colors.purplishBlue,
		"&:last-child": {
			marginRight: 0,
		},
	},
	brandButton: {
		width: "720px",
		margin: "20px auto 0",
	},
	withoutBottomMargin: {
		paddingBottom: 0,
	},
	isStub: {
		"& $headerText": {
			height: "18px",
			borderRadius: "8px",
			width: "200px",
			backgroundColor: theme.palette.colors.disabled,
			"&:after": {
				display: "none",
			},
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		headerText: {
			"&:after": {
				width: "58px",
			},
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			paddingBottom: "40px",
		},
		wrapper: {
			paddingBottom: "30px",
		},
		headerText: {
			fontSize: "16px",
			lineHeight: 2.25,
			letterSpacing: "1.8px",
			marginLeft: "21px",
			"&:after": {
				width: "48px",
				bottom: "-8px",
			},
		},
		headerButton: {
			marginRight: "10px",
		},
		headerWrapper: {
			paddingLeft: "21px",
		},
		brandButton: {
			width: "100%",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			paddingBottom: "32px",
		},
		rootReviewsProvider: {
			paddingBottom: "16px",
		},
		wrapper: {
			paddingBottom: "20px",
		},
		headerContent: {
			paddingLeft: "0",
			width: "100%",
			gap: "0",
		},
		headerWrapper: {
			gridAutoFlow: "row",
			justifyContent: "unset",
			paddingLeft: "0",
			gap: 0,
		},
		headerText: {
			fontSize: "16px",
			lineHeight: 1,
			marginLeft: 0,
			"&:after": {
				display: "none",
			},
		},
		headerButton: {
			width: "100%",
			maxWidth: "100%",
			textAlign: "center",
			margin: "10px auto 0",
			"&:last-child": {
				marginRight: "auto",
			},
		},
		brandButton: {
			width: "100%",
			margin: "20px 0 0 0",
		},
	},
}));

const Block = (props) => {
	let classes = useStyles();
	let { withoutBottomPadding } = props;

	const wrapperClassNames = [classes.wrapper];
	if (withoutBottomPadding) {
		wrapperClassNames.push(classes.withoutBottomPadding);
	}

	return (
		<div
			className={classNames(classes.root, {
				[classes.withoutBottomMargin]: props.withoutBottomMargin,
				[classes.isStub]: props.stub,
				[classes.rootReviewsProvider]: props.styleReviewsProvider,
			})}
			datatest={props.datatest}
		>
			{(props.header || props.headerContent) && (
				<Container>
					<Row>
						<Col xs={12} className={classNames(wrapperClassNames)}>
							<div className={classes.headerWrapper}>
								{props.header && (
									<div style={{ alignSelf: "center" }}>
										<div
											className={classNames(classes.headerText, {
												[classes.headerTextWhite]: props.whiteHeader,
												[classes.headerWithoutMargin]: !!props.headerWithoutMargin,
												[classes.hideUnderLine]: !!props.hideUnderLine,
											})}
										>
											{!props.stub && props.header}
										</div>
									</div>
								)}

								{props.headerContent}

								{(!!props.secondButton || !!props.btn) && (
									<div className={classes.buttonsWrapper}>
										{!!props.secondButton && (
											<div className={classNames(classes.headerButton, "onlyNotXs")}>
												{props.secondButton}
											</div>
										)}
										{!!props.btn && (
											<div className={classNames(classes.headerButton, "onlyNotXs")}>
												{props.btn}
											</div>
										)}
									</div>
								)}
							</div>
						</Col>
					</Row>
				</Container>
			)}
			{props.children}
			<Container>
				<Row>
					<Col xs={12}>
						{!!props.secondButton && (
							<div className={classNames(classes.headerButton, "onlyXs")}>{props.secondButton}</div>
						)}
						{!!props.btn && (
							<div className={classNames(classes.headerButton, "onlyXs")}>{props.btn}</div>
						)}
						{!!props.brandButton && (
							<div className={classNames(classes.brandButton)}>{props.brandButton}</div>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Block;
