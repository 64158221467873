import React from "react";
import classNames from "classnames";
import { Col } from "ComponentsUI/Styles/Grid";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import withStyles from "react-jss";
import SwitchCityWindowContainer from "Containers/SwitchCityWindowContainer";
import HeadMenuContainer from "Containers/HeadMenuContainer";
import PhoneContainerHeader from "Containers/PhoneContainerHeader";
import LoginHeaderContainer from "Containers/LoginHeaderContainer";
import LoginLogic from "HOC/LoginLogic";
import MainMenuContainer from "Containers/MainMenuContainer";

const styles = (theme) => {
	return {
		root: {
			visibility: "visible",
			opacity: 1,
			transition: "opacity .3s ease",
			position: "absolute",
			bottom: "-20px",
			transform: "translateY(100%)",
			width: "380px",
			background: theme.palette.colors.paleGrey,
			boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.5)",
			borderRadius: "10px",
			right: "10px",
			padding: "15px 20px 30px",
		},
		showMobile: {},
		wrapperUpperPart: {
			padding: "10px 0",
			display: "flex",
			alignItems: "center",
		},
		wrapperBottomPart: {
			paddingTop: "20px",
		},
		location: {},
		upperMenu: {
			maxWidth: "100%",
			display: "flex",
			justifyContent: "center",
			marginBottom: "30px",
		},
		phoneButton: {
			display: "none",
		},
		login: {
			display: "flex",
			justifyContent: "center",
			width: "100%",
		},
		mobileMenu: {
			display: "block",
			marginLeft: "auto",
			flexShrink: 0,
			cursor: "pointer",
			"& span": {
				fontSize: "24px",
				color: theme.palette.colors.purplishBlue,
			},
		},
		hidden: {
			transform: "translateX(100%)",
			visibility: "hidden",
			opacity: 0,
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				right: "5px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				display: "block",
				transform: "translateX(0)",
				transition: "transform .3s ease",
				position: "fixed",
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				backgroundColor: theme.palette.colors.paleGrey,
				zIndex: 10000,
				boxShadow: "none",
				width: "100%",
				padding: 0,
				borderRadius: 0,
			},
			hidden: {
				transform: "translateX(100%)",
			},
			wrapperUpperPart: {
				padding: "10px 0",
				display: "flex",
				alignItems: "center",
			},
			wrapperBottomPart: {
				paddingTop: "20px",
				maxHeight: "calc(100vh - 80px)",
			},
			login: {
				flexShrink: 0,
			},
			phoneButton: {
				width: "40px",
				flexShrink: 0,
				marginLeft: "auto",
				display: "block",
			},
			location: {
				width: "auto",
				paddingLeft: 0,
				flexShrink: 5,
				minWidth: "10px",
				marginRight: "20px",
			},
			mobileMenu: {
				display: "flex",
				height: "40px",
				width: "40px",
				alignItems: "center",
				justifyContent: "center",
				marginLeft: "10px",
				flexShrink: 0,
				cursor: "pointer",
				"& span": {
					fontSize: "16px",
					color: theme.palette.colors.purplishBlue,
				},
			},
			upperMenu: {
				maxWidth: "100%",
				display: "flex",
				justifyContent: "center",
				marginBottom: "30px",
			},
		},
	};
};

class MobileWindow extends React.Component {
	render() {
		let classes = this.props.classes;
		return (
			<div
				ref={(root) => (this.root = root)}
				className={classNames(classes.root, {
					[classes.hidden]: !this.props.show,
				})}
			>
				<Container>
					<Row>
						<Col xs="12">
							<div className={classes.wrapperUpperPart}>
								<div className={classes.location}>
									<SwitchCityWindowContainer
										mobileMenu
										purpleTheme={true}
										clickCallback={this.props.closeMobileMenu}
										wrapNameRegion={this.props.wrapNameRegion}
									/>
								</div>
								<div className={classes.phoneButton}>
									<PhoneContainerHeader phone404={this.props.phone404} />
								</div>
								<div className={classes.mobileMenu} onClick={this.props.closeMobileMenu}>
									<span className="icon24 icon-close" />
								</div>
							</div>
							<div className={classes.wrapperBottomPart}>
								<div className={classes.upperMenu}>
									<HeadMenuContainer inWidget={this.props.inWidget} />
								</div>
								<div className={classes.menu}>
									<MainMenuContainer
										inWidget={this.props.inWidget}
										onClickHandler={this.props.closeMobileMenu}
										withoutMain={true}
										purpleColor={true}
									/>
								</div>
								<div className={classes.login}>
									<LoginLogic>
										<LoginHeaderContainer greyTheme={true} inWidget={true} />
									</LoginLogic>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withStyles(styles)(MobileWindow);
