import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";

const styles = (theme) => {
	return {
		root: {
			fontFamily: "MuseoSansCyrl-300",
			fontSize: "14px",
			lineHeight: "1.14",
			color: theme.palette.colors.white,
			textTransform: "none",
			letterSpacing: "0px",
			marginTop: "8px",
			"& p": {
				margin: "2px 0",
			},
			"& ul": {
				listStyle: "none",
				margin: 0,
				padding: 0,
			},
		},
		rootTariffName: {
			fontFamily: "MuseoSansCyrl-300",
			fontSize: "14px",
			lineHeight: "1.14",
			color: theme.palette.colors.white,
			textTransform: "none",
			letterSpacing: "0px",
			marginTop: "8px",
			"& p": {
				margin: "2px 0",
			},
			"& ul": {
				listStyle: "none",
				margin: 0,
				padding: 0,
			},
		},
		rootPriceSale: {
			fontFamily: "MuseoSansCyrl-300",
			fontSize: "14px",
			lineHeight: "1.14",
			color: theme.palette.colors.white,
			textTransform: "none",
			letterSpacing: "0px",
			"& p": {
				margin: "2px 0",
			},
			"& ul": {
				listStyle: "none",
				margin: 0,
				padding: 0,
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				fontSize: "12px",
				lineHeight: "1.17",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				fontSize: "12px",
				lineHeight: "1.17",
			},
		},
	};
};

const TooltipBody = ({ classes, children, tariffName, priceSale }) => {
	return (
		<div
			className={classNames("TooltipBody", {
				[classes.root]: !tariffName && !priceSale,
				[classes.rootTariffName]: tariffName,
				[classes.rootPriceSale]: priceSale,
			})}
		>
			{children}
		</div>
	);
};

export default withStyles(styles)(TooltipBody);
