import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";
import { isMainSiteCode } from "Utils/siteConfig";

const styles = (theme) => {
	return {
		root: {
			cursor: "pointer",
			margin: 0,
			// padding: "16px 40px 16px 40px",
			"&:hover > ul": {
				visibility: "visible",
				opacity: 0.96,
				transition: "all 250ms ease",
			},
			// "&:hover": {
			//     backgroundColor: theme.palette.colors.purplishBlue,
			//     transition: "all 250ms ease"
			// }
			// activeTitle: {
			//     backgroundColor: theme.palette.colors.violet
			// },
		},
		onlyLg: {},
		footerTheme: {
			padding: "0px",
			"&:hover": {
				backgroundColor: theme.palette.colors.white,
			},
			"& $title": {
				lineHeight: "36px",
				textAlign: "left",
				display: "block",
				padding: "0 0 1px",
				"&:hover:after, &$activeTitle:after": {
					display: "none",
				},
			},
		},
		footerTheme101: {
			"& $title": {
				fontSize: "13px",
			},
		},
		purpleColor: {
			"& $title": {
				color: theme.palette.colors.purplishBlue,
				"&:hover:after, &$activeTitle:after": {
					backgroundColor: theme.palette.colors.purplishBlue,
				},
			},
		},
		providerTheme: {
			padding: "0px",
			position: "relative",
			"&:hover": {
				backgroundColor: theme.palette.colors.white,
			},
			"& $activeTitle:after": {
				content: '""',
				height: "3px",
				width: "100%",
				backgroundColor: theme.palette.colors.purplishBlue,
				position: "absolute",
				left: 0,
				bottom: 0,
			},
			marginRight: "48px",
			"& $title": {
				padding: "16px 0 18px",
				fontSize: "16px",
				lineHeight: "2.25",
				letterSpacing: "2.3px",
				"&:hover:after": {
					content: '""',
					height: "3px",
					width: "100%",
					backgroundColor: theme.palette.colors.purplishBlue,
					position: "absolute",
					left: 0,
					bottom: 0,
				},
			},

			"&:last-child": {
				marginRight: 0,
			},
		},
		title: {
			fontSize: "14px",
			letterSpacing: "2px",
			color: theme.palette.colors.white,
			cursor: "pointer",
			fontFamily: theme.fonts.bold,
			textTransform: "uppercase",
			textAlign: "center",
			padding: "16px 40px 16px 40px",
			display: "block",
			width: "100%",
			// "& activeTitle:after": {
			//     content: '""',
			//     height: "3px",
			//     width: "100%",
			//     backgroundColor: theme.palette.colors.white,
			//     position: "absolute",
			//     left: 0,
			//     bottom: 0
			// }
		},
		titleMainMenu: {},
		counter: {
			position: "absolute",
			right: 0,
			top: 0,
			transform: "translate(50%, -50%)",
			borderRadius: "13px",
			backgroundColor: "#00c853",
			fontFamily: theme.fonts.medium,
			fontSize: "16px",
			lineHeight: 1,
			color: theme.palette.colors.white,
			padding: "5px 8px",
		},
		compare: {
			transform: "translate(100%, 60%)",
			backgroundColor: theme.palette.colors.coralPink,
		},
		activeTitle: {},
		inWidget: {
			marginBottom: "30px",
			"& $title": {
				fontSize: "14px",
				lineHeight: 1,
				letterSpacing: "1.7px",
				"&:hover:after, &$activeTitle:after": {
					bottom: "-9px",
					height: "2px",
				},
			},
		},
		subNav: {
			position: "absolute",
			left: "13px",
			top: "100%",
			zIndex: 101,
			visibility: "hidden",
			opacity: 0,
			backgroundColor: "#ffffff",
			width: "calc(100% - 13px)",
			listStyle: "none",
		},
		subNavPadding: {
			paddingTop: "30px",
			paddingBottom: "30px",
			paddingLeft: "0px",
			fontFamily: theme.fonts.bold,
			fontSize: "18px",
		},
		reduceMargin: {},
		reduceMarginV2: {},
		[`@media (max-width: ${theme.media.lg})`]: {
			onlyLg: {
				display: "none",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			title: {},
			titleMainMenu: {
				padding: "16px",
			},
			footerTheme: {
				"& $title": {
					fontSize: "12px",
					lineHeight: "2.5",
					letterSpacing: "1.7px",
				},
			},
			providerTheme: {
				"& $title": {
					fontSize: "14px",
					letterSpacing: "2px",
				},
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				marginBottom: "30px",
			},
			activeTitle: {},
			title: {
				fontSize: "12px",
				lineHeight: 1,
				letterSpacing: "1.7px",
				"&:hover:after, &$activeTitle:after": {
					bottom: "-9px",
					height: "2px",
				},
			},
			footerTheme: {
				margin: "0 15px 12px",
				textAlign: "center",
				"&:last-child": {
					marginBottom: 0,
				},
				"& $title": {
					fontSize: "12px",
					lineHeight: "1.5",
					letterSpacing: "1.7px",
				},
				'& $title[datatest*="download"], $title[datatest="main_processingAboutPersonal_downbutton"]':
					{
						lineHeight: "2",
					},
			},
			reduceMargin: {
				marginBottom: "7px",
			},
			reduceMarginV2: {
				marginBottom: "10px",
			},
		},
	};
};

class MenuItem extends React.PureComponent {
	static defaultProps = {
		closeMobileMenu: () => {},
		clickMenuItem: () => {},
		isProviderMenu: false,
		isActive: false,
	};

	handleClick() {
		this.props.clickMenuItem();
	}

	render() {
		const { classes, isLink, isProviderMenu, isActive, isMainMenu } = this.props;

		if (!isProviderMenu) {
			return (
				<li
					itemProp="itemListElement"
					itemScope
					itemType="http://schema.org/ItemList"
					onClick={() => this.handleClick()}
					className={classNames(classes.root, {
						[classes.onlyLg]: this.props.onlyLg,
						[classes.footerTheme]: this.props.footerTheme,
						[classes.footerTheme101]: this.props.footerTheme && isMainSiteCode(this.props.siteCode),
						[classes.purpleColor]: this.props.purpleColor,
						[classes.providerTheme]: this.props.providerTheme,
						[classes.inWidget]: this.props.inWidget,
						[classes.reduceMargin]: this.props.reduceMargin,
						[classes.reduceMarginV2]: this.props.reduceMarginV2,
					})}
				>
					{!this.props.toAnotherSite
						? React.cloneElement(this.props.children, {
								className: classNames(classes.title, {
									[classes.titleMainMenu]: isMainMenu,
								}),
								activeClassName: classes.activeTitle,
							})
						: React.cloneElement(this.props.children, {
								className: classes.title,
							})}
					{this.props.counter > 0 && <span className={classes.counter}>{this.props.counter}</span>}
					{this.props.compare > 0 && (
						<span className={classNames(classes.counter, classes.compare)}>
							{this.props.compare}
						</span>
					)}
					<ul
						className={classNames(classes.subNav, {
							[classes.subNavPadding]: !!this.props.subMenuContent,
						})}
					>
						<li>{this.props.subMenuContent}</li>
					</ul>
				</li>
			);
		} else {
			let activeLinkClass = {};
			activeLinkClass[classes.activeTitle] = isActive;
			return (
				<li
					itemProp="itemListElement"
					itemScope
					itemType="http://schema.org/ItemList"
					onClick={() => this.handleClick()}
					className={classNames(classes.root, {
						[classes.onlyLg]: this.props.onlyLg,
						[classes.footerTheme]: this.props.footerTheme,
						[classes.footerTheme101]: this.props.footerTheme && isMainSiteCode(this.props.siteCode),
						[classes.purpleColor]: this.props.purpleColor,
						[classes.providerTheme]: this.props.providerTheme,
						[classes.inWidget]: this.props.inWidget,
					})}
				>
					{isLink !== false
						? React.cloneElement(this.props.children, {
								className: classes.title,
								activeClassName: classNames(activeLinkClass),
							})
						: React.cloneElement(this.props.children, {
								className: classNames(classes.title, activeLinkClass),
							})}
					{this.props.counter > 0 && <span className={classes.counter}>{this.props.counter}</span>}
					{this.props.compare > 0 && (
						<span className={classNames(classes.counter, classes.compare)}>
							{this.props.compare}
						</span>
					)}
				</li>
			);
		}
	}
}

export default withStyles(styles)(MenuItem);
