import { observable, action, computed } from "mobx";

export default class Field {
	@observable _value = null;
	@observable isModified = false;

	constructor(defaultValue) {
		this._defaultValue = defaultValue;
	}

	@computed
	get value() {
		if (this.isModified) {
			return this._value;
		} else {
			return this._defaultValue;
		}
	}

	set value(value) {
		this._value = value;
		this.isModified = true;
	}

	@action
	reset() {
		this._value = null;
		this.isModified = false;
	}
}
