import Cookies from "js-cookie";
import { YANDEX_TARGETS } from "../../../constants/metriks/yandex-targets";

class Yandex {
	constructor() {
		if (typeof window !== "undefined" && typeof window.Ya !== "undefined") {
			this.id = window?.Ya?._metrika?.getCounters()?.[0]?.id
				? window.Ya._metrika.getCounters()[0].id
				: window.__YANDEX_COUNTER__;
		}
	}

	async sendEvent(target, data) {
		if (typeof window !== "undefined" && typeof window.Ya !== "undefined") {
			const params = this._getData(target, data);
			if (Object.keys(params).length > 0) {
				try {
					if (typeof window.ym == "function" && window.Ya && window.Ya._metrika) {
						window.ym(
							window?.Ya?._metrika?.getCounters()?.[0]?.id
								? window.Ya._metrika.getCounters()[0].id
								: window.__YANDEX_COUNTER__,
							"reachGoal",
							target,
							params
						);
					}
				} catch (e) {
					console.warn("YM sendEvent error", e);
				}
			}
		}
	}

	async sendHitEvent(url) {
		if (typeof window !== "undefined" && typeof window.Ya !== "undefined") {
			try {
				if (typeof window.ym == "function" && window.Ya && window.Ya._metrika) {
					window.ym(
						window?.Ya?._metrika?.getCounters()?.[0]?.id
							? window.Ya._metrika.getCounters()[0].id
							: window.__YANDEX_COUNTER__,
						"hit",
						url
					);
				}
			} catch (e) {
				console.warn("YM sendHitEvent error", e);
			}
		}
	}

	_getData(target, data) {
		let params = {};
		let providers = [];
		switch (target) {
			case YANDEX_TARGETS.PROVIDERS_DISTRICT_LOAD:
				providers = data.filter((i) => i.vip_provider == 1);
				params = {
					providers_district_load: providers.length,
				};
				break;
			case YANDEX_TARGETS.PROVIDERS_STREET_LOAD:
				providers = data.filter((i) => i.vip_provider == 1);
				params = {
					providers_street_load: providers.length,
				};
				break;
			case YANDEX_TARGETS.PROVIDERS_TS_LOAD:
				providers = [
					...new Set(data.filter((i) => i.provider.vip_provider == 1).map((i) => i.provider.id)),
				];
				params = {
					providers_ts_load: providers.length,
				};
				break;
			case YANDEX_TARGETS.PROVIDERS_RATES_LOAD:
				providers = data.filter((i) => i.vip_provider == 1);
				params = {
					providers_rates_load: providers.length,
				};
				break;
			case YANDEX_TARGETS.CARD_PROVIDERS_RATES_LOAD:
				params[YANDEX_TARGETS.CARD_PROVIDERS_RATES_LOAD] = data.length;
				break;
			case YANDEX_TARGETS.DISTRICT_STREETS_LOAD:
				params[YANDEX_TARGETS.DISTRICT_STREETS_LOAD] = data.length;
				break;
			case YANDEX_TARGETS.STREETS_HOUSES_LOAD:
				params[YANDEX_TARGETS.STREETS_HOUSES_LOAD] = data.length;
				break;
			case YANDEX_TARGETS.TARIFFS_TS_LOAD:
				params[YANDEX_TARGETS.TARIFFS_TS_LOAD] = data.length;
				break;
			case YANDEX_TARGETS.OPERATORY_CLICK_CONNECT:
				params[YANDEX_TARGETS.OPERATORY_CLICK_CONNECT] = "click";
				break;
			case YANDEX_TARGETS.CLICK_PROVIDER:
				params[YANDEX_TARGETS.CLICK_PROVIDER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_COMPASSION_PHONE_BUTTON_REAL:
				params[YANDEX_TARGETS.CLICK_COMPASSION_PHONE_BUTTON_REAL] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TARIF:
				params[YANDEX_TARGETS.CLICK_CONNECT_TARIF] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SEND_MOBILE_ORDER:
				params[YANDEX_TARGETS.CLICK_SEND_MOBILE_ORDER] = "click";
				break;
			case YANDEX_TARGETS.PROVIDER_NE_VIP:
				params[YANDEX_TARGETS.PROVIDER_NE_VIP] = "page_view";
				break;
			case YANDEX_TARGETS.PROVIDER_NE_VIP_WITHOUT_TARIFF:
				params[YANDEX_TARGETS.PROVIDER_NE_VIP_WITHOUT_TARIFF] = "page_view";
				break;
			case YANDEX_TARGETS.NEDAVNO_PODCLUCHENNYE_ORDERS_TOHOME:
				params[YANDEX_TARGETS.NEDAVNO_PODCLUCHENNYE_ORDERS_TOHOME] = "click";
				break;
			case YANDEX_TARGETS.NEDAVNO_PODCLUCHENNYE_KARTOCHKA_PROVA:
				params[YANDEX_TARGETS.NEDAVNO_PODCLUCHENNYE_KARTOCHKA_PROVA] = "click";
				break;
			case YANDEX_TARGETS.PROVIDER_VIP:
				params[YANDEX_TARGETS.PROVIDER_VIP] = "page_view";
				break;
			case YANDEX_TARGETS.PROVIDER_VIP_WITHOUT_TARIFF:
				params[YANDEX_TARGETS.PROVIDER_VIP_WITHOUT_TARIFF] = "page_view";
				break;
			case YANDEX_TARGETS.TC_WITHOUT_TARIFF:
				params[YANDEX_TARGETS.TC_WITHOUT_TARIFF] = "page_view";
				break;
			case YANDEX_TARGETS.ENTERING_ANY_SYMBOLS:
				params[YANDEX_TARGETS.ENTERING_ANY_SYMBOLS] = "click";
				break;
			case YANDEX_TARGETS.CLICK_DISTRICT_FROM_HOMEPAGE:
				params[YANDEX_TARGETS.CLICK_DISTRICT_FROM_HOMEPAGE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_DISTRICT_FROM_ADDRESS:
				params[YANDEX_TARGETS.CLICK_DISTRICT_FROM_ADDRESS] = "click";
				break;
			case YANDEX_TARGETS.CLICK_TARIFF_FROM_CATALOG:
				params[YANDEX_TARGETS.CLICK_TARIFF_FROM_CATALOG] = "click";
				break;
			case YANDEX_TARGETS.CLICK_TARIFF_FROM_RATING:
				params[YANDEX_TARGETS.CLICK_TARIFF_FROM_RATING] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CHECK_ADDRESS:
				params[YANDEX_TARGETS.CLICK_CHECK_ADDRESS] = "click";
				break;
			case YANDEX_TARGETS.ORDER_FROM_POPUP_SUCCESS_ADDRESS:
				params[YANDEX_TARGETS.ORDER_FROM_POPUP_SUCCESS_ADDRESS] = "page_view";
				break;
			case YANDEX_TARGETS.POPUP_OTHER_PROVIDERS:
				params[YANDEX_TARGETS.POPUP_OTHER_PROVIDERS] = "page_view";
				break;
			case YANDEX_TARGETS.POPUP_NO_RESULT:
				params[YANDEX_TARGETS.POPUP_NO_RESULT] = "page_view";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_KARTOCHKA_O_PROVIDERE:
				params[YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_KARTOCHKA_O_PROVIDERE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_KARTOCHKA_RATES:
				params[YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_KARTOCHKA_RATES] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_TAG_KARTOCHKA_RATES:
				params[YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_TAG_KARTOCHKA_RATES] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CHECK_ADRESS_FROM_ACTIONS:
				params[YANDEX_TARGETS.CLICK_CHECK_ADRESS_FROM_ACTIONS] = "click";
				break;

			case YANDEX_TARGETS.CLICK_FIELD_STREET:
				params[YANDEX_TARGETS.CLICK_FIELD_STREET] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIELD_HOUSE:
				params[YANDEX_TARGETS.CLICK_FIELD_HOUSE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SELECT_STREET:
				params[YANDEX_TARGETS.CLICK_SELECT_STREET] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SELECT_HOUSE:
				params[YANDEX_TARGETS.CLICK_SELECT_HOUSE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIELD_STREET_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_FIELD_STREET_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SELECT_STREET_AND_HOUSE_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_SELECT_STREET_AND_HOUSE_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SELECT_STREET_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_SELECT_STREET_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.ENTERING_NUMBER_NEW_SEARCH:
				params[YANDEX_TARGETS.ENTERING_NUMBER_NEW_SEARCH] = "entering";
				break;
			case YANDEX_TARGETS.CLICK_POPUP_WRONG_HOUSE_LOAD_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_POPUP_WRONG_HOUSE_LOAD_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIND_TOHOME_BUTTON_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_FIND_TOHOME_BUTTON_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIND_OFFICE_BUTTON_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_FIND_OFFICE_BUTTON_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIND_SAT_BUTTON_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_FIND_SAT_BUTTON_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIND_OFFICE_BUTTON:
				params[YANDEX_TARGETS.CLICK_FIND_OFFICE_BUTTON] = "click";
				break;
			case YANDEX_TARGETS.CLICK_START_TENDER:
				params[YANDEX_TARGETS.CLICK_START_TENDER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SEND_OFFICE_BUTTON:
				params[YANDEX_TARGETS.CLICK_SEND_OFFICE_BUTTON] = "click";
				break;
			case YANDEX_TARGETS.CLICK_START_TENDER_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_START_TENDER_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SEND_OFFICE_BUTTON_NEW_SEARCH:
				params[YANDEX_TARGETS.CLICK_SEND_OFFICE_BUTTON_NEW_SEARCH] = "click";
				break;
			case YANDEX_TARGETS.CLICK_PROVIDER_FROM_TOP:
				params[YANDEX_TARGETS.CLICK_PROVIDER_FROM_TOP] = "click";
				break;
			case YANDEX_TARGETS.open_quiz:
				params[YANDEX_TARGETS.open_quiz] = "open_quiz";
				break;
			case YANDEX_TARGETS.PAGE_NOT_FOUND:
				params[YANDEX_TARGETS.PAGE_NOT_FOUND] = "page_not_found";
				break;
			case YANDEX_TARGETS.OPEN_POPUP_EXIT:
				params[YANDEX_TARGETS.OPEN_POPUP_EXIT] = "open_popup";
				break;
			case YANDEX_TARGETS.OPEN_POPUP_EXIT1:
				params[YANDEX_TARGETS.OPEN_POPUP_EXIT1] = "open_popup";
				break;
			case YANDEX_TARGETS.OPEN_POPUP_EXIT2:
				params[YANDEX_TARGETS.OPEN_POPUP_EXIT2] = "open_popup";
				break;
			case YANDEX_TARGETS.OPEN_POPUP_EXIT3:
				params[YANDEX_TARGETS.OPEN_POPUP_EXIT3] = "open_popup";
				break;
			case YANDEX_TARGETS.OPEN_POPUP_EXIT4:
				params[YANDEX_TARGETS.OPEN_POPUP_EXIT4] = "open_popup";
				break;
			case YANDEX_TARGETS.LOAD_WANT_SIM:
				params[YANDEX_TARGETS.LOAD_WANT_SIM] = "load";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_WITH_ACTION:
				params[YANDEX_TARGETS.CLICK_CONNECT_WITH_ACTION] = "click";
				break;
			case YANDEX_TARGETS.LOAD_PAGE_RATES:
				params[YANDEX_TARGETS.LOAD_PAGE_RATES] = "load";
				break;
			case YANDEX_TARGETS.CLICK_CHECK_ADDRESS_RATES_TAG:
				params[YANDEX_TARGETS.CLICK_CHECK_ADDRESS_RATES_TAG] = "click";
				break;
			case YANDEX_TARGETS.LOAD_TAG_PAGE_RATES_PROVIDER:
				params[YANDEX_TARGETS.LOAD_TAG_PAGE_RATES_PROVIDER] = "load";
				break;
			case YANDEX_TARGETS.CLICK_3IN1_FROM_HOMEPAGE:
				params[YANDEX_TARGETS.CLICK_3IN1_FROM_HOMEPAGE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_TERMS_OF_USE_FROM_ORDER:
				params[YANDEX_TARGETS.CLICK_TERMS_OF_USE_FROM_ORDER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONSENT_FROM_ORDER:
				params[YANDEX_TARGETS.CLICK_CONSENT_FROM_ORDER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_PERSONAL_DATA_FROM_ORDER:
				params[YANDEX_TARGETS.CLICK_PERSONAL_DATA_FROM_ORDER] = "click";
				break;
			case YANDEX_TARGETS.OPEN_WRONG_QUIZ:
				params[YANDEX_TARGETS.OPEN_WRONG_QUIZ] = "load";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_OPERATORY:
				params[YANDEX_TARGETS.CLICK_CONNECT_OPERATORY] = "click";
				break;
			case YANDEX_TARGETS.USER_SELECT_REGION:
				params[YANDEX_TARGETS.USER_SELECT_REGION] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT:
				params[YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT1:
				params[YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT1] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT2:
				params[YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT2] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT3:
				params[YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT3] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT4:
				params[YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT4] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT:
				params[YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT1:
				params[YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT1] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT2:
				params[YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT2] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT3:
				params[YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT3] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT4:
				params[YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT4] = "click";
				break;
			case YANDEX_TARGETS.OPEN_RATESMOBILE_REGION:
				params[YANDEX_TARGETS.OPEN_RATESMOBILE_REGION] = "load";
				break;
			case YANDEX_TARGETS.OPEN_ACTIONS_OPERATORY:
				params[YANDEX_TARGETS.OPEN_ACTIONS_OPERATORY] = "load";
				break;
			case YANDEX_TARGETS.OPEN_NOMERA_OPERATOR:
				params[YANDEX_TARGETS.OPEN_NOMERA_OPERATOR] = "load";
				break;
			case YANDEX_TARGETS.OPEN_NOMERA_REGION:
				params[YANDEX_TARGETS.OPEN_NOMERA_REGION] = "load";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_NOMERA:
				params[YANDEX_TARGETS.CLICK_CONNECT_NOMERA] = "click";
				break;
			case YANDEX_TARGETS.LOAD_TARIFF_WITH_WANT_SIM:
				params[YANDEX_TARGETS.LOAD_TARIFF_WITH_WANT_SIM] = "load_tariff";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_MTC_FROM_RATESMOBILE:
				params[YANDEX_TARGETS.CLICK_CONNECT_MTC_FROM_RATESMOBILE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_MTC_FROM_NOMERA:
				params[YANDEX_TARGETS.CLICK_CONNECT_MTC_FROM_NOMERA] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CHECK_ADDRESS_RATES_TAG_PROVIDER:
				params[YANDEX_TARGETS.CLICK_CHECK_ADDRESS_RATES_TAG_PROVIDER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TARIFF_FROM_TAG_RATES:
				params[YANDEX_TARGETS.CLICK_CONNECT_TARIFF_FROM_TAG_RATES] = "click";
				break;
			case YANDEX_TARGETS.OPEN_POPUP_EXIT_CAT:
				params[YANDEX_TARGETS.OPEN_POPUP_EXIT_CAT] = "load";
				break;
			case YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT_CAT:
				params[YANDEX_TARGETS.CLICK_CLOSE_POPUP_EXIT_CAT] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT_CAT:
				params[YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT_CAT] = "click";
				break;
			case YANDEX_TARGETS.LEAD_ALL:
				params[YANDEX_TARGETS.LEAD_ALL] = "click";
				break;
			case YANDEX_TARGETS.OPEN_ORDER_FORM:
				params[YANDEX_TARGETS.OPEN_ORDER_FORM] = "load";
				break;
			case YANDEX_TARGETS.CLICK_SEND_ORDER_WITH_TARIFF:
				params[YANDEX_TARGETS.CLICK_SEND_ORDER_WITH_TARIFF] = "click";
				break;
			case YANDEX_TARGETS.CLICK_RATES_POPUP1_FROM_QUIZ_SEND_PHONE:
				params[YANDEX_TARGETS.CLICK_RATES_POPUP1_FROM_QUIZ_SEND_PHONE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SEND_ORDER_OFFICE:
				params[YANDEX_TARGETS.CLICK_SEND_ORDER_OFFICE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SEND_ORDER_WITHOUT_TARIFF:
				params[YANDEX_TARGETS.CLICK_SEND_ORDER_WITHOUT_TARIFF] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CALLBACK:
				params[YANDEX_TARGETS.CLICK_CALLBACK] = "click";
				break;
			case YANDEX_TARGETS.CALLBACK_PROV_PAGE:
				params[YANDEX_TARGETS.CALLBACK_PROV_PAGE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_COMPASSION_PHONE_BUTTON_FAKE:
				params[YANDEX_TARGETS.CLICK_COMPASSION_PHONE_BUTTON_FAKE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIND_TOHOME_BUTTON:
				params[YANDEX_TARGETS.CLICK_FIND_TOHOME_BUTTON] = "click";
				break;
			case YANDEX_TARGETS.CLICK_HEADER:
				params[YANDEX_TARGETS.CLICK_HEADER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_POPUP_WRONG_HOUSE_LOAD:
				params[YANDEX_TARGETS.CLICK_POPUP_WRONG_HOUSE_LOAD] = "click";
				break;
			case YANDEX_TARGETS.CLICK_REGISTRATION_BUTTON_REGISTER:
				params[YANDEX_TARGETS.CLICK_REGISTRATION_BUTTON_REGISTER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FEEDBACK_BUTTON:
				params[YANDEX_TARGETS.CLICK_FEEDBACK_BUTTON] = "click";
				break;
			case YANDEX_TARGETS.OPEN_RECENTLY_CONNECTED_PROVIDERS:
				params[YANDEX_TARGETS.OPEN_RECENTLY_CONNECTED_PROVIDERS] = "load";
				break;
			case YANDEX_TARGETS.OPEN_RECENTLY_CONNECTED_OPERATORY:
				params[YANDEX_TARGETS.OPEN_RECENTLY_CONNECTED_OPERATORY] = "load";
				break;
			case YANDEX_TARGETS.OPEN_WATCH_FROM_FEEDBACK:
				params[YANDEX_TARGETS.OPEN_WATCH_FROM_FEEDBACK] = "click";
				break;
			case YANDEX_TARGETS.CLICK_WATCH_FROM_FEEDBACK:
				params[YANDEX_TARGETS.CLICK_WATCH_FROM_FEEDBACK] = "click";
				break;
			case YANDEX_TARGETS.OPEN_CALLBACK:
				params[YANDEX_TARGETS.OPEN_CALLBACK] = "open";
				break;
			case YANDEX_TARGETS.VISIT_PROVIDERS:
				params[YANDEX_TARGETS.VISIT_PROVIDERS] = "open";
				break;
			case YANDEX_TARGETS.VISIT_SELECT_REGION:
				params[YANDEX_TARGETS.VISIT_SELECT_REGION] = "open";
				break;
			case YANDEX_TARGETS.VISITOR_MOBILE:
				params[YANDEX_TARGETS.VISITOR_MOBILE] = "load";
				break;
			case YANDEX_TARGETS.VISITOR_TABLET:
				params[YANDEX_TARGETS.VISITOR_TABLET] = "load";
				break;
			case YANDEX_TARGETS.VISITOR_DESKTOP:
				params[YANDEX_TARGETS.VISITOR_DESKTOP] = "load";
				break;
			case YANDEX_TARGETS.CLICK_TARIFF_FROM_OTZYVY_NEVIP:
				params[YANDEX_TARGETS.CLICK_TARIFF_FROM_OTZYVY_NEVIP] = "click";
				break;
			case YANDEX_TARGETS.CLICK_TARIFF_FROM_OTZYVY_VIP:
				params[YANDEX_TARGETS.CLICK_TARIFF_FROM_OTZYVY_VIP] = "click";
				break;
			case YANDEX_TARGETS.OPEN_OTZYVY_VIP:
				params[YANDEX_TARGETS.OPEN_OTZYVY_VIP] = "load";
				break;
			case YANDEX_TARGETS.OPEN_OTZYVY_NEVIP:
				params[YANDEX_TARGETS.OPEN_OTZYVY_NEVIP] = "load";
				break;
			case YANDEX_TARGETS.CLICK_CALLBACK_FROM_RATING_MAIN:
				params[YANDEX_TARGETS.CLICK_CALLBACK_FROM_RATING_MAIN] = "click";
				break;
			case YANDEX_TARGETS.OPEN_NEW_TEXT:
				params[YANDEX_TARGETS.OPEN_NEW_TEXT] = "load";
				break;
			case YANDEX_TARGETS.CLICK_NEW_TEXT:
				params[YANDEX_TARGETS.CLICK_NEW_TEXT] = "click";
				break;
			case YANDEX_TARGETS.OPEN_CONNECT_TARIFF:
				params[YANDEX_TARGETS.OPEN_CONNECT_TARIFF] = "load";
				break;
			case YANDEX_TARGETS.SEND_CONNECT_TARIFF:
				params[YANDEX_TARGETS.SEND_CONNECT_TARIFF] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_UNIKALNYE_REGION:
				params[YANDEX_TARGETS.CLICK_CONNECT_UNIKALNYE_REGION] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_UNIKALNYE_MTS:
				params[YANDEX_TARGETS.CLICK_CONNECT_UNIKALNYE_MTS] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_UNIKALNYE_OPERATOR:
				params[YANDEX_TARGETS.CLICK_CONNECT_UNIKALNYE_OPERATOR] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT_HOLD:
				params[YANDEX_TARGETS.CLICK_BUTTON_POPUP_EXIT_HOLD] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIELD_NUMBER_POPUP_EXIT:
				params[YANDEX_TARGETS.CLICK_FIELD_NUMBER_POPUP_EXIT] = "click";
				break;
			case YANDEX_TARGETS.OPEN_QUIZ_MOBILE:
				params[YANDEX_TARGETS.OPEN_QUIZ_MOBILE] = "open";
				break;
			case YANDEX_TARGETS.OPEN_QUIZ_TABLET:
				params[YANDEX_TARGETS.OPEN_QUIZ_TABLET] = "open";
				break;
			case YANDEX_TARGETS.OPEN_QUIZ_DESKTOP:
				params[YANDEX_TARGETS.OPEN_QUIZ_DESKTOP] = "open";
				break;
			case YANDEX_TARGETS.CLICK_OUT_QUIZ:
				params[YANDEX_TARGETS.CLICK_OUT_QUIZ] = "click";
				break;
			case YANDEX_TARGETS.CLOSE_QUIZ:
				params[YANDEX_TARGETS.CLOSE_QUIZ] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_WHATSAPP:
				params[YANDEX_TARGETS.CLICK_BUTTON_WHATSAPP] = "click";
				break;
			case YANDEX_TARGETS.CLICK_WHATSAPP_HEADER:
				params[YANDEX_TARGETS.CLICK_WHATSAPP_HEADER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BUTTON_WHATSAPP_MOBILE:
				params[YANDEX_TARGETS.CLICK_BUTTON_WHATSAPP_MOBILE] = "click";
				break;
			case YANDEX_TARGETS.OPEN_ACTION_DETAL:
				params[YANDEX_TARGETS.OPEN_ACTION_DETAL] = "open";
				break;
			case YANDEX_TARGETS.OPEN_ACTION_REGION:
				params[YANDEX_TARGETS.OPEN_ACTION_REGION] = "open";
				break;
			case YANDEX_TARGETS.OPEN_YOU_REGION:
				params[YANDEX_TARGETS.OPEN_YOU_REGION] = "open";
				break;
			case YANDEX_TARGETS.REGION_YES:
				params[YANDEX_TARGETS.REGION_YES] = "click";
				break;
			case YANDEX_TARGETS.REGION_OTHER:
				params[YANDEX_TARGETS.REGION_OTHER] = "click";
				break;
			case YANDEX_TARGETS.REDIRECT_MAIN_WITHOUT_REGION:
				params[YANDEX_TARGETS.REDIRECT_MAIN_WITHOUT_REGION] = "load";
				break;
			case YANDEX_TARGETS.CLICK_PHONE:
				params[YANDEX_TARGETS.CLICK_PHONE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_GO_SITE:
				params[YANDEX_TARGETS.CLICK_GO_SITE] = "click";
				break;
			case YANDEX_TARGETS.ENTER_NUMBER:
				params[YANDEX_TARGETS.ENTER_NUMBER] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_PROV1:
				params[YANDEX_TARGETS.CLICK_CONNECT_PROV1] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_PROV2:
				params[YANDEX_TARGETS.CLICK_CONNECT_PROV2] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FIND_ADDRESS_HEADER:
				params[YANDEX_TARGETS.CLICK_FIND_ADDRESS_HEADER] = "click";
				break;
			case YANDEX_TARGETS.OPEN_FORM_1CLICK:
				params[YANDEX_TARGETS.OPEN_FORM_1CLICK] = "open";
				break;
			case YANDEX_TARGETS.CLICK_PHONE_1CLICK:
				params[YANDEX_TARGETS.CLICK_PHONE_1CLICK] = data[0];
				break;
			case YANDEX_TARGETS.SEND_1CLICK:
				params[YANDEX_TARGETS.SEND_1CLICK] = data[0];
				break;
			case YANDEX_TARGETS.OPEN_OTZYVY_REGION:
				params[YANDEX_TARGETS.OPEN_OTZYVY_REGION] = "open";
				break;
			case YANDEX_TARGETS.OPEN_MAIN:
				params[YANDEX_TARGETS.OPEN_MAIN] = "open";
				break;
			case YANDEX_TARGETS.CHANGE_ANTIFILTR:
				params[YANDEX_TARGETS.CHANGE_ANTIFILTR] = "click";
				break;
			case YANDEX_TARGETS.CLICK_DUDO:
				params[YANDEX_TARGETS.CLICK_DUDO] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BANNER_PHONE_BUTTON_REAL:
				params[YANDEX_TARGETS.CLICK_BANNER_PHONE_BUTTON_REAL] = "click";
				break;
			case YANDEX_TARGETS.CLICK_FOOTER_PHONE_BUTTON_REAL:
				params[YANDEX_TARGETS.CLICK_FOOTER_PHONE_BUTTON_REAL] = "click";
				break;
			case YANDEX_TARGETS.CLICK_NUMBERFROM_LEADPOPUP:
				params[YANDEX_TARGETS.CLICK_NUMBERFROM_LEADPOPUP] = "click";
				break;
			case YANDEX_TARGETS.SEND_LEADPOPUP:
				params[YANDEX_TARGETS.SEND_LEADPOPUP] = "click";
				break;
			case YANDEX_TARGETS.CLICK_DETAIL_MTS:
				params[YANDEX_TARGETS.CLICK_DETAIL_MTS] = "click";
				break;
			case YANDEX_TARGETS.CLICK_DETAIL_MOB_TARIFF:
				params[YANDEX_TARGETS.CLICK_DETAIL_MOB_TARIFF] = "click";
				break;
			case YANDEX_TARGETS.OPEN_ESIM:
				params[YANDEX_TARGETS.OPEN_ESIM] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_ESIM_MTS:
				params[YANDEX_TARGETS.CLICK_CONNECT_ESIM_MTS] = "click";
				break;
			case YANDEX_TARGETS.CLOSE_ESIM_MTS:
				params[YANDEX_TARGETS.CLOSE_ESIM_MTS] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SPISOK:
				params[YANDEX_TARGETS.CLICK_SPISOK] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONSTRUCTOR:
				params[YANDEX_TARGETS.CLICK_CONSTRUCTOR] = "click";
				break;
			case YANDEX_TARGETS.CLICK_INSTRUCTION:
				params[YANDEX_TARGETS.CLICK_INSTRUCTION] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TINKOFF_FROM_RATESMOBILE:
				params[YANDEX_TARGETS.CLICK_CONNECT_TINKOFF_FROM_RATESMOBILE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_BEELINE_FROM_RATESMOBILE:
				params[YANDEX_TARGETS.CLICK_CONNECT_BEELINE_FROM_RATESMOBILE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_MEGAFON_FROM_RATESMOBILE:
				params[YANDEX_TARGETS.CLICK_CONNECT_MEGAFON_FROM_RATESMOBILE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TELE2_FROM_RATESMOBILE:
				params[YANDEX_TARGETS.CLICK_CONNECT_TELE2_FROM_RATESMOBILE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_YOTA_FROM_RATESMOBILE:
				params[YANDEX_TARGETS.CLICK_CONNECT_YOTA_FROM_RATESMOBILE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_SBER_FROM_RATESMOBILE:
				params[YANDEX_TARGETS.CLICK_CONNECT_SBER_FROM_RATESMOBILE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TINKOFF_NOMERA:
				params[YANDEX_TARGETS.CLICK_CONNECT_TINKOFF_NOMERA] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_BEELINE_NOMERA:
				params[YANDEX_TARGETS.CLICK_CONNECT_BEELINE_NOMERA] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_MEGAFON_NOMERA:
				params[YANDEX_TARGETS.CLICK_CONNECT_MEGAFON_NOMERA] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_TELE2_NOMERA:
				params[YANDEX_TARGETS.CLICK_CONNECT_TELE2_NOMERA] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_YOTA_NOMERA:
				params[YANDEX_TARGETS.CLICK_CONNECT_YOTA_NOMERA] = "click";
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_SBER_NOMERA:
				params[YANDEX_TARGETS.CLICK_CONNECT_SBER_NOMERA] = "click";
				break;
			case YANDEX_TARGETS.CLICK_AUDIO_ON_1ClICK:
				params[YANDEX_TARGETS.CLICK_AUDIO_ON_1ClICK] = data[0];
				break;
			case YANDEX_TARGETS.CLICK_AUDIO_OFF_1ClICK:
				params[YANDEX_TARGETS.CLICK_AUDIO_OFF_1ClICK] = data[0];
				break;
			case YANDEX_TARGETS.SCROLL_TO_1CLICK:
				params[YANDEX_TARGETS.SCROLL_TO_1CLICK] = "click";
				break;
			case YANDEX_TARGETS.OPEN_QUIZ_TEST:
				params[YANDEX_TARGETS.OPEN_QUIZ_TEST] = data[0];
				break;
			case YANDEX_TARGETS.CLICK_RATES_POPUP1_FROM_QUIZ_SEND_PHONE_TEST:
				params[YANDEX_TARGETS.CLICK_RATES_POPUP1_FROM_QUIZ_SEND_PHONE_TEST] = data[0];
				break;
			case YANDEX_TARGETS.CLICK_ERROR_REQUEST_TEST:
				params[YANDEX_TARGETS.CLICK_ERROR_REQUEST_TEST] = data[0];
				break;
			case YANDEX_TARGETS.CLICK_CONNECT_ACTION:
				params[YANDEX_TARGETS.CLICK_CONNECT_ACTION] = "click";
				break;
			case YANDEX_TARGETS.POP_UP_OPERATOR_CLOSED:
				params[YANDEX_TARGETS.POP_UP_OPERATOR_CLOSED] = "click";
				break;
			case YANDEX_TARGETS.POP_UP_OPERATOR_MNP:
				params[YANDEX_TARGETS.POP_UP_OPERATOR_MNP] = "click";
				break;
			case YANDEX_TARGETS.POP_UP_OPERATOR_SIM:
				params[YANDEX_TARGETS.POP_UP_OPERATOR_SIM] = "click";
				break;
			case YANDEX_TARGETS.POP_UP_OPERATOR_ESIM:
				params[YANDEX_TARGETS.POP_UP_OPERATOR_ESIM] = "click";
				break;
			case YANDEX_TARGETS.POP_UP_OPERATOR_CHANGE:
				params[YANDEX_TARGETS.POP_UP_OPERATOR_CHANGE] = "click";
				break;
			case YANDEX_TARGETS.POP_UP_OPERATOR_CLICK_CONNECT:
				params[YANDEX_TARGETS.POP_UP_OPERATOR_CLICK_CONNECT] = "click";
				break;
			case YANDEX_TARGETS.POP_UP_OPERATOR_WHATSAPP:
				params[YANDEX_TARGETS.POP_UP_OPERATOR_WHATSAPP] = "click";
				break;
			case YANDEX_TARGETS.OPEN_TC:
				params[YANDEX_TARGETS.OPEN_TC] = "click";
				break;
			case YANDEX_TARGETS.OPEN_TC_POPULAR:
				params[YANDEX_TARGETS.OPEN_TC_POPULAR] = "click";
				break;
			case YANDEX_TARGETS.OPEN_TC_NZL:
				params[YANDEX_TARGETS.OPEN_TC_NZL] = "click";
				break;
			case YANDEX_TARGETS.CLICK_ON_GPT:
				params[YANDEX_TARGETS.CLICK_ON_GPT] = "click";
				break;
			case YANDEX_TARGETS.OPEN_OTZYV_DOM:
				params[YANDEX_TARGETS.OPEN_OTZYV_DOM] = "view";
				break;
			case YANDEX_TARGETS.VIEW_TEST_SKOR:
				params[YANDEX_TARGETS.VIEW_TEST_SKOR] = "view";
				break;
			case YANDEX_TARGETS.CLICK_TEST_SCOR:
				params[YANDEX_TARGETS.CLICK_TEST_SCOR] = "click";
				break;
			case YANDEX_TARGETS.CLICK_PODROB_UNLIM_STREET:
				params[YANDEX_TARGETS.CLICK_PODROB_UNLIM_STREET] = "click";
				break;
			case YANDEX_TARGETS.CLICK_PODROB_UNLIM_DOM:
				params[YANDEX_TARGETS.CLICK_PODROB_UNLIM_DOM] = "click";
				break;
			case YANDEX_TARGETS.VIEW_CHAVO_STREET:
				params[YANDEX_TARGETS.VIEW_CHAVO_STREET] = "view";
				break;
			case YANDEX_TARGETS.VIEW_CHAVO_HOUSE:
				params[YANDEX_TARGETS.VIEW_CHAVO_HOUSE] = "view";
				break;
			case YANDEX_TARGETS.OPEN_CHAVO_STREET:
				params["question"] = data;
				params[YANDEX_TARGETS.OPEN_CHAVO_STREET] = "click";
				break;
			case YANDEX_TARGETS.OPEN_CHAVO_HOUSE:
				params["question"] = data;
				params[YANDEX_TARGETS.OPEN_CHAVO_HOUSE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BAN_OTZYV_STREET:
				params[YANDEX_TARGETS.CLICK_BAN_OTZYV_STREET] = "click";
				break;
			case YANDEX_TARGETS.CLICK_BAN_OTZYV_HOUSE:
				params[YANDEX_TARGETS.CLICK_BAN_OTZYV_HOUSE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_OTPR_OTZYV_STREET:
				params[YANDEX_TARGETS.CLICK_OTPR_OTZYV_STREET] = "click";
				break;
			case YANDEX_TARGETS.CLICK_OTPR_OTZYV_HOUSE:
				params[YANDEX_TARGETS.CLICK_OTPR_OTZYV_HOUSE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_101RUB_STREET:
				params[YANDEX_TARGETS.CLICK_101RUB_STREET] = "click";
				break;
			case YANDEX_TARGETS.CLICK_101RUB_HOUSE:
				params[YANDEX_TARGETS.CLICK_101RUB_HOUSE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_ON_NUMBER_HOUSE:
				params[YANDEX_TARGETS.CLICK_ON_NUMBER_HOUSE] = "click";
				break;
			case YANDEX_TARGETS.CLICK_ON_NAME_STREET:
				params[YANDEX_TARGETS.CLICK_ON_NAME_STREET] = "click";
				break;
			case YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_1:
				params[YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_1] = "open";
				break;
			case YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_2:
				params[YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_2] = "open";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_CALENDAR:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_CALENDAR] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_SLOT:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_SLOT] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_STEP_2_SEND:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_STEP_2_SEND] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_STEP_2_NO_PHONE_SEND:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_STEP_2_NO_PHONE_SEND] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_SLOT_UNAVAILABLE:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_SLOT_UNAVAILABLE] = data;
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_SLOT_AVAILABLE:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_SLOT_AVAILABLE] = data;
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_HOT_SLOT_AVAILABLE:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_HOT_SLOT_AVAILABLE] = data;
				break;
			case YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_3:
				params[YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_3] = "open";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_TOOLTIP:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_TOOLTIP] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_CHANGE:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_CHANGE] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_PHONE_SEND:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_PHONE_SEND] = "click";
				break;
			case YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_4:
				params[YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_4] = "open";
				break;
			case YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_5:
				params[YANDEX_TARGETS.OPEN_ONLINE_ZAYAVKA_STEP_5] = "open";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_WA:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_WA] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_TG:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_TG] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_CLICK_PHONE:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_CLICK_PHONE] = "click";
				break;
			case YANDEX_TARGETS.ONLINE_ZAYAVKA_DETAILS:
				params[YANDEX_TARGETS.ONLINE_ZAYAVKA_DETAILS] = "click";
				break;
			case YANDEX_TARGETS.CLICK_EXCLUSIVE_NEW_TARIFF:
				params[YANDEX_TARGETS.CLICK_EXCLUSIVE_NEW_TARIFF] = "click";
				break;
			case YANDEX_TARGETS.CLICK_ACTION_NEW_TARIFF:
				params[YANDEX_TARGETS.CLICK_ACTION_NEW_TARIFF] = "click";
				break;
			case YANDEX_TARGETS.NEW_OPEN_CONNECT_TARIFF:
				params[YANDEX_TARGETS.NEW_OPEN_CONNECT_TARIFF] = "open";
				break;
			case YANDEX_TARGETS.OPEN_DETAILS_NEW_TARIFF:
				params[YANDEX_TARGETS.OPEN_DETAILS_NEW_TARIFF] = "open";
				break;
			case YANDEX_TARGETS.CLOSE_DETAILS_NEW_TARIFF:
				params[YANDEX_TARGETS.CLOSE_DETAILS_NEW_TARIFF] = "click";
				break;
			case YANDEX_TARGETS.CLICK_POISK_GLAVNAYA_BEZ_REGIONA:
				params[YANDEX_TARGETS.CLICK_POISK_GLAVNAYA_BEZ_REGIONA] = "click";
				break;
			case YANDEX_TARGETS.CLICK_WIDGET_RATING:
				params[YANDEX_TARGETS.CLICK_WIDGET_RATING] = "click";
				break;
			case YANDEX_TARGETS.CLICK_SUBMIT_BUTTON_IN_WIDGET_RATING:
				params[YANDEX_TARGETS.CLICK_SUBMIT_BUTTON_IN_WIDGET_RATING] = "click";
				break;
			default:
				break;
		}
		params["UserID"] = Cookies.get("uuid");
		return params;
	}

	getCounter() {
		let counter = "";
		if (typeof window !== "undefined" && typeof window.Ya !== "undefined") {
			counter = window?.Ya?._metrika?.getCounters()?.[0]?.id
				? window.Ya._metrika.getCounters()[0].id
				: window.__YANDEX_COUNTER__;
		}

		return counter;
	}

	getCID() {
		let cid = "";
		if (typeof window !== "undefined" && typeof window.Ya !== "undefined") {
			try {
				if (typeof window.ym == "function" && window.Ya && window.Ya._metrika) {
					window.ym(
						window?.Ya?._metrika?.getCounters()?.[0]?.id
							? window.Ya._metrika.getCounters()[0].id
							: window.__YANDEX_COUNTER__,
						"getClientID",
						function (clientID) {
							cid = clientID;
						}
					);
				}
			} catch (e) {
				console.warn("YM getCID error", e);
			}
		}

		return cid;
	}
}

export default Yandex;
