import Url from "./Url";

const arrayNotRegionUrl = [
	"orders",
	"providers",
	"news",
	"reviews",
	"compare",
	"actions",
	"rates",
	"business",
	"rating",
	"ratesMobile",
	"address",
	"feedback",
	"exclusive",
	"about",
	"sim-karty",
	"quiz",
	"contact-feedback",
	"operator",
	"operatory",
	"nomera",
	"ratesmobile",
];

const useDefaultParams = (routeParams) => {
	let params = Url.getDefaultParams();

	let regionUrl = routeParams.regionUrl;
	const returnObj = {
		regionUrl: null,
		regionUrlComing: null,
	};

	returnObj.regionUrlComing = routeParams.regionUrl;
	if (arrayNotRegionUrl.find((r) => r == regionUrl)) {
		regionUrl = "";
	}

	if (!regionUrl && params.hasOwnProperty("regionUrl")) {
		regionUrl = params.regionUrl;
	}

	returnObj.regionUrl = regionUrl;

	return returnObj;
};

export default useDefaultParams;
