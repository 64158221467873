var gql = require("@apollo/client").gql;

const MUTATE_SITECONFIG_COUNTER = gql`
	mutation {
		counterIncrement {
			order_counter
		}
	}
`;

export default MUTATE_SITECONFIG_COUNTER;
