import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";

const styles = (theme) => ({
	root: {
		position: "relative",
		zIndex: 1,
		"&:hover": {
			"& $popupWrapper": {
				opacity: 1,
				visibility: "visible",
			},
		},
	},
	element: {
		cursor: "pointer",
	},
	popupWrapper: {
		position: "absolute",
		top: 0,
		transform: "translateY(-100%)",
		left: "calc(50% - 32px)",
		width: "240px",
		paddingBottom: "11px",
		opacity: 0,
		visibility: "hidden",
	},
	typeB: {
		top: "20px",
	},
	popup: {
		width: "100%",
		padding: "14px 24px",
		backgroundColor: theme.palette.colors.purplishBlue,
		cursor: "pointer",
		userSelect: "none",
		borderRadius: "10px",
	},
	popupTriangle: {
		backgroundColor: theme.palette.colors.purplishBlue,
		width: "20px",
		height: "20px",
		transform: "rotate(45deg)",
		position: "absolute",
		bottom: "4px",
		left: "23px",
	},
	popupText: {
		color: theme.palette.colors.white,
		fontSize: "14px",
		fontFamily: theme.fonts.medium,
		lineHeight: 1.29,
	},
});

const PurplePopup = ({ classes, element, typeB, children }) => (
	<div className={classes.root}>
		<div className={classes.element}>{element}</div>
		<div
			className={classNames(classes.popupWrapper, {
				[classes.typeB]: !!typeB,
			})}
		>
			<div className={classes.popup}>
				<div className={classes.popupText}>{children}</div>
				<div className={classes.popupTriangle} />
			</div>
		</div>
	</div>
);

export default withStyles(styles)(PurplePopup);
