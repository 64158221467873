var gql = require("@apollo/client").gql;

const MUTATE_SEND_POLL = gql`
	mutation SendPoll(
		$user_info: UserInfoInput
		$comment: String
		$current_url: String
		$score: Int
	) {
		sendPoll(user_info: $user_info, comment: $comment, current_url: $current_url, score: $score)
	}
`;

module.exports = MUTATE_SEND_POLL;
