var StorageAdapter = require("react-abtesting").StorageAdapter;
var Cookies = require("js-cookie");

const cookies = Cookies.withConverter({
	read: function (value, name) {
		return value;
	},
	write: function (value, name) {
		return value;
	},
});

const storageAdapter = new StorageAdapter({
	setter: (name, val) => {
		cookies.set(name, val, {
			expires: new Date(Number(new Date()) + 1000 * 60 * 60 * 24 * 365),
		});
	},
	getter: (name) => cookies.get(name),
});

module.exports = storageAdapter;
