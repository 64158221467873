import React from "react";
import loadable from "@loadable/component";
import LoadingSpinnerHOC from "HOC/LoadingSpinnerHOC";

export const Rates = loadable(() => import("./rate"), {
	fallback: <LoadingSpinnerHOC />,
});

export const RatesTags = loadable(() => import("./rateTag"), {
	fallback: <LoadingSpinnerHOC />,
});
