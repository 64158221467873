import { observable, action } from "mobx";

export default class SVGInlineModel {
	@observable SVGInlineInstance = null;

	@action
	reset() {
		this.SVGInlineInstance = null;
	}

	@action
	initialize() {
		let SVG = require("react-inlinesvg");
		this.SVGInlineInstance = SVG;
	}

	getInstance() {
		return this.SVGInlineInstance;
	}
}
