import { gql } from "@apollo/client";

const MUTATE_INFO_USER_POPUP = gql`
	mutation (
		$name_popup: String
		$view_quiz: String
		$time_show_popup: Int
		$uuid_user: String
		$phone_user: String
		$tariffs_count: Int
		$min_price: Int
		$max_speed: Int
		$close_button: String
		$width: Int
		$height: Int
		$user_agent: String
		$region: String
		$region_id: Int
		$district: String
		$district_id: Int
		$full_street: String
		$street_id: Int
		$house: String
		$house_id: Int
		$ab_enabled: Boolean
		$ab_variant: String
	) {
		infoUserPopup(
			name_popup: $name_popup
			view_quiz: $view_quiz
			time_show_popup: $time_show_popup
			uuid_user: $uuid_user
			phone_user: $phone_user
			user_agent: $user_agent
			width: $width
			height: $height
			tariffs_count: $tariffs_count
			min_price: $min_price
			max_speed: $max_speed
			ab_test: { enabled: $ab_enabled, variant: $ab_variant }
			address: {
				region: $region
				region_id: $region_id
				district: $district
				district_id: $district_id
				full_street: $full_street
				street_id: $street_id
				house: $house
				house_id: $house_id
			}
			close_button: $close_button
		)
	}
`;

export default MUTATE_INFO_USER_POPUP;
