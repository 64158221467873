import { gql } from "@apollo/client";

const MUTATE_SEND_ORDER = gql`
	mutation (
		$url: String
		$phone_connection: String
		$fio: String
		$provider_id: Int
		$tariff_id: Int
		$region_id: Int
		$lead_form_type: String
		$info: String
		$need_convergent: Boolean
		$convergent_tariff_id: Int
		$api_only: Int
		$convergent_display_price: Int
		$convergent_name: String
		$convergent_tv_channels: Int
		$convergent_tv_channels_hd: Int
		$convergent_sms: Int
		$convergent_sms_type: Int
		$convergent_internet: Int
		$convergent_internet_type: Int
		$convergent_calls: Int
		$convergent_calls_type: Int
		$uuid: String
		$ga_client_id: String
		$ya_client_id: String
		$ya_counter_id: Int
		$type_connection: Int
		$office_square: String
		$why_change: Int
		$office_location: Int
		$connected_office: Int
		$building_square: Int
		$contact_locality: String
		$street: String
		$street_id: Int
		$house_id: Int
		$addresses: String
		$title_company: String
		$website: String
		$email: String
		$utm_campaign: String
		$utm_medium: String
		$utm_source: String
		$utm_content: String
		$utm_term: String
	) {
		sendOrder(
			url: $url
			phone_connection: $phone_connection
			fio: $fio
			provider_id: $provider_id
			tariff_id: $tariff_id
			region_id: $region_id
			lead_form_type: $lead_form_type
			info: $info
			need_convergent: $need_convergent
			convergent_tariff_id: $convergent_tariff_id
			api_only: $api_only
			convergent_display_price: $convergent_display_price
			convergent_name: $convergent_name
			convergent_tv_channels: $convergent_tv_channels
			convergent_tv_channels_hd: $convergent_tv_channels_hd
			convergent_sms: $convergent_sms
			convergent_sms_type: $convergent_sms_type
			convergent_internet: $convergent_internet
			convergent_internet_type: $convergent_internet_type
			convergent_calls: $convergent_calls
			convergent_calls_type: $convergent_calls_type
			uuid: $uuid
			ga_client_id: $ga_client_id
			ya_client_id: $ya_client_id
			ya_counter_id: $ya_counter_id
			type_connection: $type_connection
			office_square: $office_square
			why_change: $why_change
			office_location: $office_location
			connected_office: $connected_office
			building_square: $building_square
			contact_locality: $contact_locality
			street: $street
			street_id: $street_id
			house_id: $house_id
			addresses: $addresses
			title_company: $title_company
			website: $website
			email: $email
			utm_campaign: $utm_campaign
			utm_medium: $utm_medium
			utm_source: $utm_source
			utm_content: $utm_content
			utm_term: $utm_term
		)
	}
`;

export default MUTATE_SEND_ORDER;
