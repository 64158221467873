var gql = require("@apollo/client").gql;

const SEARCH_FULL_ADDRESS_QUERY_V2 = gql`
	query SEARCH_FULL_ADDRESS_QUERY_V2($filter: String) {
		searchFullAddressV2(filter: $filter) {
			data {
				data {
					region_guid
					house_guid
					street_guid
					house
					construction
					building
					street_full
					address_id
					street_id
					district_id
					region_id
				}
				result
				highlight
			}
		}
	}
`;

export default SEARCH_FULL_ADDRESS_QUERY_V2;
