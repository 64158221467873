import React from "react";
import { observer, inject } from "mobx-react";
import { ModalLayout } from "ComponentsUI/Modallayout";
import CtrlEnterSend from "ComponentsUI/CtrlEnterSend";

@inject("ApplicationModel")
@observer
export default class CtrlEnterSendContainer extends React.Component {
	componentDidMount() {
		if (window && document) {
			document.addEventListener("keydown", this.bindedFunc);
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.bindedFunc);
	}

	handlePressedKey(e) {
		if (e.keyCode === 13 && e.ctrlKey) {
			let modalModel = this.props.ApplicationModel.modalModel;
			modalModel.toggleModal(
				<ModalLayout
					closeModal={() => this.toggleModal()}
					fullCloseElement={true}
					newsView={true}
					closeOnClickLayout={true}
				>
					<CtrlEnterSend />
				</ModalLayout>
			);
		}
	}

	bindedFunc = (e) => {
		this.handlePressedKey(e);
	};

	toggleModal() {
		let modalModel = this.props.ApplicationModel.modalModel;
		modalModel.toggleModal();
	}

	render() {
		return null;
	}
}
