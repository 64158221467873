import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { captureMessage } from "@sentry/react";
import PropTypes from "prop-types";

const LazyImage = ({ src, className, alt, ...other }) => {
	return (
		<LazyLoadImage
			width={other.width || 360}
			height={other.height || 180}
			src={src}
			className={className}
			alt={alt}
			onError={() => {
				let url;
				if (typeof window !== "undefined") {
					url = window?.location?.href;
				}

				return captureMessage("Error image load", {
					tags: {
						"error.type": "image_load",
					},
					extra: {
						src,
						alt,
						className,
						url,
					},
					level: "error",
				});
			}}
			{...other}
		/>
	);
};

LazyImage.propTypes = {
	src: PropTypes.string.isRequired,
	className: PropTypes.string,
	alt: PropTypes.string,
};

export default LazyImage;
