//Массив сегментов URL которые НЕ являются regionUrl (костыль для МОЛ и ПОЛ, где есть регион по-умолчанию)
export const notRegionUrls = [
	"orders",
	"providers",
	"operatory",
	"news",
	"reviews",
	"compare",
	"actions",
	"rates",
	"business",
	"rating",
	"ratesMobile",
	"address",
	"feedback",
	"about",
	"ratesmobile",
	"operatory",
	"nomera",
	"quiz",
	"contact-feedback",
	"operator",
	"doma-nzl",
	"oplata-i-garantii",
	"site-map",
	"dom",
	"podklyuchit-internet",
];
