import * as React from "react";

export default class OnlySSR extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			wasMounted: false,
		};
	}

	componentDidMount() {
		this.init();
	}

	init() {
		this.setState({
			wasMounted: true,
		});
	}

	render() {
		if (!this.state.wasMounted) {
			return this.props.children;
		}
		return null;
	}
}
