import React from "react";
import { withRouter, useParams } from "react-router-dom";
import useDefaultParams from "Services/useDefaultParams";
import { generateUrl } from "Services/withDefaultParams";
import { isMainSiteCode, isMoscowSiteCode, isPiterSiteCode } from "Utils/siteConfig";
import routes from "Configs/routes";
import { FooterNavBar } from "ComponentsUIV2/NavBars";

const FooterNavBarContainer = (props) => {
	const { siteCode, text } = props;
	const routeParams = useParams();
	const { regionUrl } = useDefaultParams(routeParams);

	const reviewsURL = isMoscowSiteCode(siteCode)
		? "https://yandex.by/maps/org/moskva_on_line/1035796011/reviews/?ll=37.714127%2C55.710511&mode=search&sctx=ZAAAAAgBEAAaKAoSCZS%2Fe0eN30JAEXR%2BiuPA10tAEhIJN1DgnXx6tD8RRrbz%2FdR4qT8iBgABAgMEBSgKOABA5K0HSAFqAnVhnQHNzEw9oAEAqAEAvQFtC3scwgEFq%2Fzz7QOCAhN3d3cubW9za3Zhb25saW5lLnJ1igIAkgIAmgIMZGVza3RvcC1tYXBz&sll=37.714127%2C55.710511&sspn=0.302811%2C0.099436&tab=reviews&text=www.moskvaonline.ru&z=12"
		: "https://yandex.by/maps/org/101_internet/118153615879/reviews/?ll=37.746499%2C55.685574&z=13";

	const documentsURL = generateUrl(routes.PersonalData.route, {
		regionUrl,
		anchorUrl: "#processing",
	});

	const sitemap = generateUrl(routes.SiteMap.route, {
		regionUrl,
	});

	const linksList = [
		{
			linkTo: "/about/company",
			noIndex: true,
			title: text.menuAboutCompany,
			datatest: "menu_about",
		},
		{
			linkTo: "/about/oplata-i-garantii",
			noIndex: true,
			title: text.menuPayWarranty,
			datatest: "menu_pay_warranty",
		},
		{ linkTo: "/articles", anotherUrl: true, title: text.menuBlog, datatest: "menu_blog" },
		// { linkTo: "", title: text.menuFaq, datatest: "menu_faq" },
		!isPiterSiteCode(siteCode)
			? {
					linkTo: reviewsURL,
					anotherUrl: true,
					title: text.menuReviewAboutCompany,
					datatest: "menu_about_company",
				}
			: null,
		{ linkTo: "/about/contacts", noIndex: true, title: text.contacts, datatest: "menu_contacts" },
		{ linkTo: "/career", anotherUrl: true, title: text.CAREER, datatest: "menu_career" },
		{ linkTo: "/about/partners", noIndex: true, title: text.menuPartner, datatest: "menu_partner" },
		{ linkTo: sitemap, title: text.menuSiteMap, datatest: "menu_sitemap" },
		{ linkTo: documentsURL, title: text.menuDocuments, datatest: "menu_documents" },
		{
			linkTo: "/about/personal-data",
			noIndex: true,
			title: text.PERSONAL_DATA_TITLE,
			datatest: "menu_personal_data",
		},
	];

	return <FooterNavBar linksList={linksList} />;
};

export default withRouter(FooterNavBarContainer);
