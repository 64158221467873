import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import { isMoscowSiteCode, isMainSiteCode, isPiterSiteCode } from "Utils/siteConfig";
import LoginLogic from "HOC/LoginLogic";
import {
	SVGLogoWhite,
	SVGInternet101White,
	SVGMoskvaOnlineWhite,
	SVGPiterOnlineWhite,
} from "Constants/SVG";
import SwitchCityWindowContainer from "Containers/SwitchCityWindowContainer";
import PhoneContainerHeader from "Containers/PhoneContainerHeader";
import LoginHeaderContainer from "Containers/LoginHeaderContainer";
import { Col } from "ComponentsUI/Styles/Grid";

const useStyles = createUseStyles((theme) => {
	return {
		root: {
			padding: "30px 0 0",
		},
		showMobile: {},
		wrapper: {
			display: "flex",
			alignItems: "center",
		},
		wrapperLogs: {},
		logoWrapperFull: {
			lineHeight: "0",
			width: "280px",
			marginRight: "20px",
			paddingLeft: "3px",
			"& a": {
				display: "inline-block",
				width: "80%",
				maxHeight: "50px",
			},
			"& svg": {
				width: "100%",
				maxHeight: "50px",
				pointerEvents: "none",
			},
		},
		logo101: {
			height: "45px",
		},
		logoMoskva: {
			height: "33px",
		},
		logoPiter: {
			height: "35px",
		},
		location: {
			width: "280px",
			marginRight: "20px",
			paddingLeft: "3px",
		},
		phoneButton: {
			width: "250px",
			marginLeft: "auto",
		},
		login: {
			marginLeft: "20px",
		},
		logoWrapper: {
			display: "none",
		},
		mobileMenu: {
			display: "none",
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			logoWrapperFull: {
				width: "220px",
				"& a": {
					width: "90%",
				},
			},
			logo101: {
				height: "40px",
			},
			logoMoskva: {
				height: "29px",
			},
			logoPiter: {
				height: "31px",
			},
			location: {
				width: "220px",
				marginRight: "20px",
				paddingLeft: "3px",
			},
			login: {
				marginLeft: "10px",
			},
			phoneButton: {
				width: "230px",
				margin: "0 auto",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			wrapperLogs: {
				width: "43px",
			},
			logoWrapperFull: {
				display: "none",
			},
			location: {
				display: "block",
				width: "230px",
				marginRight: "10px",
				paddingLeft: "3px",
			},
			login: {
				marginLeft: 0,
			},
			phoneButton: {
				width: "50px",
				margin: "0 10px 0 auto",
			},
			logoWrapper: {
				display: "flex",
				marginRight: "10px",
				cursor: "pointer",
				flexShrink: 0,
				height: "40px",
				width: "40px",
				alignItems: "center",
				justifyContent: "center",
				"& a": {
					width: "40px",
					height: "40px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					"&:active": {
						borderRadius: "50%",
						backgroundColor: "#1E2A58",
					},
				},
				"& svg": {
					height: "25px",
					width: "auto",
					pointerEvents: "none",
				},
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			logoWrapperFull: {
				display: "none",
			},
			root: {
				padding: "10px 0",
			},
			login: {
				display: "none",
			},
			phoneButton: {
				display: "flex",
				justifyContent: "flex-end",
				width: "40px",
				flexShrink: 0,
			},
			location: {
				display: "block",
				width: "auto",
				paddingLeft: 0,
				flexShrink: 5,
				minWidth: "10px",
			},
			logoWrapper: {
				marginRight: "10px",
				display: "flex",
				height: "40px",
				width: "40px",
				alignItems: "center",
				justifyContent: "center",
				cursor: "pointer",
				flexShrink: 0,
				"& a": {
					height: "40px",
					width: "40px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					"&:active": {
						borderRadius: "50%",
						backgroundColor: "#1E2A58",
					},
				},
				"& svg": {
					height: "20px",
					width: "26px",
					pointerEvents: "none",
				},
			},
			mobileMenu: {
				display: "flex",
				height: "40px",
				width: "40px",
				alignItems: "center",
				justifyContent: "center",
				marginLeft: "10px",
				flexShrink: 0,
				"& span": {
					fontSize: "20px",
					color: theme.palette.colors.white,
				},
			},
		},
	};
});

const UpperPart = (props) => {
	let classes = useStyles();
	let siteCode = props.siteCode;
	return (
		<Container
			className={classNames(classes.root, {
				[classes.showMobile]: props.showMobile,
			})}
		>
			<Row>
				<Col xs="12">
					<div className={classes.wrapper}>
						<div className={classes.wrapperLogs}>
							<div className={classes.logoWrapper}>
								<Link aria-label={props.hrefToMain} to={props.hrefToMain}>
									{SVGLogoWhite}
								</Link>
							</div>
							<div className={classes.logoWrapperFull}>
								<Link aria-label={props.hrefToMain} to={props.hrefToMain}>
									{isMainSiteCode(siteCode) && SVGInternet101White}
									{isMoscowSiteCode(siteCode) && SVGMoskvaOnlineWhite}
									{isPiterSiteCode(siteCode) && SVGPiterOnlineWhite}
								</Link>
							</div>
						</div>
						<div className={classes.location}>
							<SwitchCityWindowContainer />
							{/* {isMainSiteCode(siteCode) && (
                                    <DetectRegionContainer />
                                )} */}
						</div>
						<div className={classes.phoneButton}>
							<PhoneContainerHeader upperPart={true} phone404={props.phone404} />
						</div>
						<div className={classes.login}>
							<LoginLogic>
								<LoginHeaderContainer purpleTheme={true} greyTheme={true} />
							</LoginLogic>
						</div>
						<div className={classes.mobileMenu} onClick={props.openMobileMenu}>
							<span className="icon24 icon-hamburger-menu-1" />
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default UpperPart;
