import { observable, action } from "mobx";

export const OPERATOR_TAB_CARD = "operator_tab_card";
export const OPERATOR_TAB_TARIFFS = "operator_tab_tariffs";
export const OPERATOR_TAB_BEAUTIFUL_NUMBERS = "operator_tab_beautiful_numbers";
export const OPERATOR_TAB_ACTIONS = "operator_tab_actions";

export class OperatorModel {
	tabs = [OPERATOR_TAB_CARD, OPERATOR_TAB_TARIFFS];

	@observable visibleTab = OPERATOR_TAB_CARD;
	@observable operatorForFeedback = null;
	@observable operatorIdForOrder = null;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	changeTab(tab) {
		this.visibleTab = tab;
	}

	@action
	setOperatorFeedback(operatorName) {
		this.operatorForFeedback = operatorName;
	}

	@action
	setOperatorIdForOrder(id) {
		this.operatorIdForOrder = id;
	}
}
