var gql = require("@apollo/client").gql;

const STREET_BY_HOUSE_QUERY = gql`
	query STREET_BY_HOUSE_QUERY($id: Int) {
		streetByHouse(id: $id) {
			id
			region_id
			name
			full
			type
			district {
				id
				name
			}
			region {
				id
				name
				url
				is_capital
			}
		}
	}
`;

export default STREET_BY_HOUSE_QUERY;
