import { Link } from "react-router-dom";
import React from "react";
import { useHistory } from "react-router";
const CardsLinkItem = (props) => {
	let { className, elem, defaultItems, text, commonViewModel } = props;
	const history = useHistory();
	let url;
	let house = elem.house ? `${text.house_short} ${elem.house}` : "";
	let building = elem.building ? `${text.building_short} ${elem.building}` : "";
	let construction = elem.construction ? `${text.construction_short} ${elem.construction}` : "";
	let id = elem.id;
	let needDefaultItem = defaultItems?.find((item) => item.id === id);
	url = needDefaultItem.link;
	let address = elem.full ? elem.full : `${elem.street.full} ${house} ${building} ${construction}`;

	const handleClickLink = (e) => {
		//Здесь костыль из-за того, что OnClick в компоненте Link не всегда вызывается
		if (!!e) {
			e.preventDefault();
			e.stopPropagation();
		}

		commonViewModel.changeShowModalOnRates(true);
		commonViewModel.changeNameModalOnRates("cardLink");

		history.push({
			pathname: url,
		});
	};

	return (
		<Link className={className} onClick={handleClickLink} to={url}>
			{address}
		</Link>
	);
};

export default CardsLinkItem;
