import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			width: "100%",
			height: "180px",
			borderRadius: "10px",
			boxSizing: "border-box",
			position: "relative",
			backgroundColor: theme.palette.colors.white,
			border: `solid 1px ${theme.palette.colors.lightGrayishViolet}`,
			padding: "25px 30px 29px",
			overflow: "hidden",
			minHeight: "100%",
			cursor: "pointer",
		},
		header: {
			fontFamily: theme.fonts.bold,
			fontSize: "24px",
			lineHeight: "1",
			color: theme.palette.colors.darkSlateBlue,
			paddingRight: "160px",
			marginBottom: "33px",
		},
		comment: {
			fontFamily: theme.fonts.light,
			fontSize: "14px",
			lineHeight: "1.43",
			color: theme.palette.colors.oneMoreGrey,
		},
		image: {
			position: "absolute",
			top: "30px",
			right: "30px",
			width: "120px",
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				height: "170px",
			},
			header: { fontSize: "18px" },
			comment: { fontSize: "12px" },
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			header: { fontSize: "20px" },
			comment: { fontSize: "12px" },
			image: { top: "30px", right: "0", width: "120px" },
		},
	};
};

const ConsultBlock = ({ classes, title, image, desc, phone, onClick }) => {
	return (
		<div className={classes.root} onClick={onClick}>
			<div className={classes.header}>{title}</div>
			<div className={classes.comment}>{desc}</div>
			{image && <img alt={"consult"} src={image} className={classes.image} />}
		</div>
	);
};

ConsultBlock.defaultProps = {
	onClick: () => {},
};

ConsultBlock.propTypes = {
	image: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
	desc: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
	onClick: PropTypes.func,
};

export default withStyles(styles)(ConsultBlock);
