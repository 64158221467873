import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => ({
	root: {
		fontFamily: theme.fonts.bold,
		opacity: ".5",
		fontSize: "14px",
		lineHeight: "1.29",
		letterSpacing: "2px",
		display: "inline-block",
		verticalAlign: "middle",
		textTransform: "uppercase",
		marginBottom: "7px",
		// cursor: "pointer",
		padding: 0,
		marginTop: 0,
		color: theme.palette.colors.purplishBlue,
		"& li": {
			display: "inline-block",
			"& a": {
				color: theme.palette.colors.purplishBlue,
			},
		},
	},
	grey: {
		color: theme.palette.colors.purplishBlue,
		"& li": {
			"& a": {
				color: theme.palette.colors.oneMoreGrey,
			},
		},
	},
	white: {
		color: theme.palette.colors.white,
		"& li": {
			"& a": {
				color: theme.palette.colors.white,
			},
		},
	},
	separator: {
		padding: "0 4px",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			fontSize: "12px",
			lineHeight: "1.5",
			letterSpacing: "1.7px",
		},
	},
});

const Component = ({ white = false, grey = false, classes, children }) => {
	let childrens = [];
	React.Children.forEach(children, (child, key) => {
		if (key != 0) {
			childrens.push(
				<li className={classes.separator} key={`delimetr_${key}`}>
					/
				</li>
			);
		}
		childrens.push(child);
	});

	if (childrens.length == 0) {
		return null;
	}

	return (
		<ul
			itemScope
			itemType="http://schema.org/BreadcrumbList"
			className={classNames(classes.root, {
				[classes.white]: white,
				[classes.grey]: grey,
			})}
		>
			{childrens}
		</ul>
	);
};

const Breadcrumbs = withStyles(styles)(Component);
Breadcrumbs.displayName = "Breadcrumbs";

export default Breadcrumbs;
