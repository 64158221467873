function smoothScrollTo(endX, endY, duration) {
	window.scrollTo(endX, endY);
}

export function scrollToY(duration, selector = "html", endYParam = 0) {
	const element = document.querySelector(selector);
	if (!element) {
		return;
	}

	const endX = 0,
		endY = endYParam,
		startX = element.scrollX || element.pageXOffset,
		startY = element.scrollY || element.scrollTop,
		distanceX = endX - startX,
		distanceY = endY - startY,
		startTime = new Date().getTime();

	const easeInOutQuart = function (time, from, distance, duration) {
		if ((time /= duration / 2) < 1) return (distance / 2) * time * time * time * time + from;
		return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
	};

	const timer = setInterval(function () {
		const time = new Date().getTime() - startTime,
			newX = easeInOutQuart(time, startX, distanceX, duration),
			newY = easeInOutQuart(time, startY, distanceY, duration);
		if (time >= duration) {
			clearInterval(timer);
		}
		element.scrollTo(newX, newY);
		setTimeout(() => element.scrollTo(newX, newY), 1);
	}, 1000 / 60);
}

export default smoothScrollTo;
