import React, { useState } from "react";
import PropTypes from "prop-types";
import { FooterTopTitleContainer } from "Containers/Footer";
import FooterTopSubtitle from "ComponentsUIV2/Footer/FooterTopSubtitle";

import FooterTopList from "ComponentsUIV2/Footer/FooterTopList";

const FooterTopListContainer = (props) => {
	const { title, subtitles, moreLink, moreLink_text, isButton, moreLinkSvg } = props;
	const [isOpen, setIsOpen] = useState(false);

	let subtitlesList = subtitles?.map((item, index) => (
		<FooterTopSubtitle text={item.text} src={item.src} key={`${index}_${item.text}`} />
	));

	return (
		<FooterTopList
			moreLink={moreLink}
			moreLink_text={moreLink_text}
			moreLinkSvg={moreLinkSvg}
			isButton={isButton}
			isOpen={isOpen}
			subtitlesList={subtitlesList}
		>
			<FooterTopTitleContainer
				text={title}
				isButton={isButton}
				clickedBlock={() => setIsOpen(!isOpen)}
			/>
		</FooterTopList>
	);
};

FooterTopListContainer.propTypes = {
	isButton: PropTypes.bool,
	title: PropTypes.string,
	subtitles: PropTypes.array,
	moreLink: PropTypes.string,
	moreLink_text: PropTypes.string,
	moreLinkSvg: PropTypes.node,
};

export default FooterTopListContainer;
