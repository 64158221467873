import React, { Component } from "react";
import withStyles from "react-jss";
import { BackgroundImage } from "../BackgroundImage";
import { webpSimpleTransformer } from "Helpers/ImageManager";

const styles = (theme) => ({
	root: {
		display: "block",
		position: "relative",
		marginBottom: "60px",
	},
	[`@media (max-width:${theme.media.sm})`]: {
		root: {
			display: "none",
		},
	},
});

class ComponentBackground extends Component {
	render() {
		let { classes, children, images, windowSizes, backgroundNone } = this.props;

		if (!children) {
			return null;
		}

		return (
			<div className={classes.root}>
				<BackgroundImage
					webpTransformer={webpSimpleTransformer}
					background={images}
					windowSizes={windowSizes}
					backgroundNone={backgroundNone}
				/>
				{children}
			</div>
		);
	}
}

export default withStyles(styles)(ComponentBackground);
