import React from "react";
import { observer, inject } from "mobx-react";
import Metrika from "Services/Metrika";
import { EVENT_CATEGORY_PAGE_VIEW } from "Constants/metriks/GoogleCategorys";
import { CLICK_LOAD_POPUP_MOBILE_NEW_VISITOR } from "Constants/metriks/GoogleActions";
import PopupBContainer from "Containers/PopupBContainer";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/ru";
import { checkTimeMatch } from "Services/Time";
import { withRouter } from "react-router-dom";
moment.locale("ru");

@withRouter
@inject("ApplicationModel")
@observer
export default class PopupBHOC extends React.Component {
	timer = undefined;

	closeModal() {
		let modalModel = this.props.ApplicationModel.modalModel;
		modalModel.setPopupB(false);
		modalModel.toggleModal();
	}

	startTimer = () => {
		this.timer = setTimeout(() => {
			if (this.props.ApplicationModel.currentRegion) {
				if (this.props.ApplicationModel.currentRegion.id) {
					Metrika.getInstance().google.sendEvent(
						EVENT_CATEGORY_PAGE_VIEW,
						CLICK_LOAD_POPUP_MOBILE_NEW_VISITOR
					);

					let modalModel = this.props.ApplicationModel.modalModel;
					if (!modalModel.popapA && !modalModel.isOpen) {
						let promise1 = import("Layouts/modalLayout");
						promise1.then((modules) => {
							let ModalLayout = modules.default;
							modalModel.setPopupB(true);
							modalModel.toggleModal(
								<ModalLayout
									closeModal={() => this.closeModal()}
									fullCloseElement={true}
									onlyDesktopCloseElement={true}
									closeOnClickLayout={true}
									popupB={true}
								>
									<PopupBContainer closeModal={() => this.closeModal()} />
								</ModalLayout>
							);
						});
						Cookies.set("isViewPopupB", true, { expires: 1 });
					}
				}
			}
		}, 20000);
	};

	render() {
		const mobile_popup = this.props.ApplicationModel.siteConfig?.settings?.mobile_popup;

		if (!mobile_popup?.enabled) {
			return <></>;
		}

		if (mobile_popup.time_start && mobile_popup.time_end) {
			const show = checkTimeMatch(mobile_popup.time_start, mobile_popup.time_end);
			if (!show) {
				return <></>;
			}
		}

		let viewPopupBC = Cookies.get("isViewPopupB");

		if (!viewPopupBC && this.props.viewMobilePopup !== false) {
			if (this.timer === undefined) {
				this.startTimer();
			}
		} else {
			if (this.timer !== undefined) {
				clearTimeout(this.timer);
				this.timer = undefined;
			}
		}

		return <></>;
	}
}
