// require("es6-promise").polyfill();
// import "whatwg-fetch";
import cookie from "js-cookie";
import querystring from "querystring";

export default class LoaderData {
	static get(url, params, method) {
		let str = "";
		if ((!method || method == "get") && params) {
			str =
				"?" +
				Object.keys(params)
					.map(function (key) {
						if (typeof params[key] == "object") {
							let arrayRet = [];
							for (let i in params[key]) {
								arrayRet.push(`${key}[${i}]=${params[key][i]}`);
							}
							return arrayRet.join("&");
						} else {
							return key + "=" + params[key];
						}
					})
					.join("&");
		}
		return fetch(`/api${url}${str}`).then((response) => {
			return response.json();
		});
	}

	static getAnyHost(url, params, method) {
		let str = "";
		if ((!method || method == "get") && params) {
			str =
				"?" +
				Object.keys(params)
					.map(function (key) {
						if (typeof params[key] == "object") {
							let arrayRet = [];
							for (let i in params[key]) {
								arrayRet.push(`${key}[${i}]=${params[key][i]}`);
							}
							return arrayRet.join("&");
						} else {
							return key + "=" + params[key];
						}
					})
					.join("&");
		}
		return fetch(`${url}${str}`, {
			mode: "cors",
		}).then((response) => {
			return response.json();
		});
	}

	static post(url, params, host = "") {
		const optionsSend = {
			mode: "cors",
			method: "post",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(params),
		};

		if (cookie.get("jwt_token")) {
			optionsSend.headers["Authorization"] = "Bearer " + cookie.get("jwt_token");
		}

		return fetch(`${host}/api${url}`, optionsSend).then((response) => response.json());
	}

	static post2(url, params, host = "", timeout = 2000) {
		const optionsSend = {
			mode: "cors",
			method: "post",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(params),
		};

		if (typeof AbortController === "undefined") {
			return fetch(`${host}/api${url}`, optionsSend).then((response) => response.json());
		} else {
			return LoaderData.fetchWithTimeout(`${host}/api${url}`, optionsSend, timeout).then(
				(response) => response.json()
			);
		}
	}

	static async fetchWithTimeout(url, options, timeout) {
		const ac = new AbortController();
		const id = setTimeout(() => ac.abort(), timeout);

		options.signal = ac.signal;

		const response = await fetch(url, options);

		clearTimeout(id);

		return response;
	}

	static put(url, params, host = "") {
		const optionsSend = {
			mode: "cors",
			method: "put",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(params),
		};

		if (cookie.get("jwt_token")) {
			optionsSend.headers["Authorization"] = "Bearer " + cookie.get("jwt_token");
		}

		return fetch(`${host}/api${url}`, optionsSend).then((response) => response.json());
	}

	static postToLocal(url, params) {
		const optionsSend = {
			mode: "cors",
			method: "post",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: querystring.stringify(params),
		};

		if (cookie.get("jwt_token")) {
			optionsSend.headers["Authorization"] = "Basic MTAxaW50ZXI6dGVzdDEwMQ==";
		}

		return fetch(url, optionsSend).then((response) => {
			return response.json().then((data) => {
				return { ...data, __STATUS_CODE: response.status };
			});
		});
	}

	static getFromLocal(url) {
		const optionsSend = {
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Basic MTAxaW50ZXI6dGVzdDEwMQ==",
			},
		};

		return fetch(url, optionsSend).then((response) => {
			return response.json().then((data) => {
				return { ...data, __STATUS_CODE: response.status };
			});
		});
	}
}
