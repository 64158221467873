let pathToRegexp = require("path-to-regexp");

let params = {};

const setDefaultParams = (p) => {
	params = p;
};

const getDefaultParams = () => {
	return params;
};

const generateUrl = (route, prms, currentRegionUrl) => {
	let usrParams = prms;
	let anchorUrl;
	if (params.regionUrl && usrParams.regionUrl == params.regionUrl) {
		delete usrParams.regionUrl;
	}

	if (!usrParams.regionUrl && currentRegionUrl && currentRegionUrl !== params.regionUrl) {
		usrParams.regionUrl = currentRegionUrl;
	}
	if (usrParams.regionUrl == "") {
		delete usrParams.regionUrl;
	}
	if (!!usrParams.anchorUrl) {
		anchorUrl = usrParams.anchorUrl;
		delete usrParams.anchorUrl;
	}

	let toPath = pathToRegexp.compile(route);

	let newPath = toPath(usrParams);

	if (!newPath) {
		newPath = "/";
	}

	if (anchorUrl) {
		newPath += anchorUrl;
	}

	return newPath;
};

module.exports.params = params;
module.exports.setDefaultParams = setDefaultParams;
module.exports.getDefaultParams = getDefaultParams;
module.exports.generateUrl = generateUrl;
