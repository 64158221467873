var gql = require("@apollo/client").gql;

const TOP_PROVIDERS_QUERY = gql`
	query TopProviders($regionUrl: String!) {
		topProviders(regionUrl: $regionUrl) {
			data {
				id
				name
				logo
				url_name
				region {
					url
				}
			}
		}
	}
`;

export default TOP_PROVIDERS_QUERY;
