import React from "react";

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	iconBlock: {
		padding: "12px",
		border: "1px solid #DCE0E9",
		borderRadius: "50%",
		boxShadow: `0px 0px 0.5px 0px #1118240D,
        0px 0.75px 0.5px 0px #1118240A,
        0px 1.5px 0.5px 0px #11182408,
        0px 2px 1px 0px #11182403,
        0px 3px 1px 0px #11182400
        `,
		"&:hover": {
			background: "#FAFAFA",
			cursor: "pointer",
		},
	},
	icon: {
		width: "16px",
		height: "16px",
		display: "block",
		"& svg": {
			height: "16px",
			width: "16px",
		},
	},
}));

const FooterIcon = (props) => {
	const classes = useStyles();
	const { svgComponent, href } = props;

	return (
		<div className={classes.iconBlock}>
			<a href={href} className={classes.icon} aria-label="Соц.сеть">
				{svgComponent}
			</a>
		</div>
	);
};

export default FooterIcon;
