import { action, computed, observable, toJS } from "mobx";
import Debounce from "debounce-decorator";
import Translater from "convert-layout/ru";
import cookie from "js-cookie";
import Field from "./form/Field";
import isEmpty from "lodash/isEmpty";
import {
	CHECK_PROVIDER_ADDRESS_QUERY_V2,
	MUTATE_SEARCH_EVENT_QUERY,
	SEARCH_FULL_ADDRESS_QUERY_V2,
	STREET_ONE_QUERY,
	MUTATE_EMPTY_ADDRESS_PROVIDER,
	REFINE_FULL_ADDRESS_QUERY_V2,
	MUTATE_EMPTY_REGION_SEARCH,
	HOUSE_FULL_QUERY_V4,
} from "Services/queries";
import { addABData } from "Utils";

const notTranslateChars = [",", ".", "/"];

export default class SearcheModel {
	timeOutID = null;
	searcheWithDelay = true;

	@observable
	searcheForm = {
		fields: {
			fullAddress: new Field(""),
		},
		meta: {
			isValid: true,
			error: null,
		},
	};

	@observable currentFullAddress = {};
	@observable loading = false;
	@observable viewQuizFromTariffs = false;
	@observable fullAddressList = [];
	@observable recentlyConnectedSelectedTariffId = 0;
	@observable actionSelectedTariffId = 0;
	@observable selectOneString = false;

	@observable house_id = 0;

	lastEmptySearchValue = "";

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	setHouseId(id) {
		this.house_id = id;
		cookie.set("house_id", id, { expires: 1 });
	}

	submit() {
		this.sendSearchEvent(
			new SearchEvent({
				confirm: true,
				value: this.searcheForm.fields.fullAddress.value,
				item: toJS(this.currentFullAddress),
			})
		);
	}

	emitSearchEvent(type, value) {
		this.sendSearchEvent(
			new SearchEvent({
				type,
				value,
			})
		);
	}

	selectRoute(route) {
		this.sendSearchEvent(
			new SearchEvent({
				item: { route: route.name },
				value: route.name,
			})
		);
	}

	sendSearchEvent(event) {
		const client = this.rootStore.apolloTransportLayer;

		const userInfo = {
			uuid_user: cookie.get("uuid") || "Не был задан uuid",
			user_agent: navigator.userAgent,
			width: 0,
			height: 0,
		};

		if (typeof window == "object") {
			userInfo.width = window.innerWidth;
			userInfo.height = window.innerHeight;
			userInfo.url = window?.location?.href;
		}

		const abData = addABData({});
		const experiments = [];

		if (abData?.ab && Object.keys(abData.ab)?.length) {
			for (const e of Object.keys(abData.ab)) {
				experiments.push({ name: e, variant: abData.ab[e] });
			}
		}

		switch (event.type) {
			case "onStreetSelect":
			case "onAddressSelect":
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value,
						action: {
							select_address: {
								street_id: event.item.street_id || null,
								address_id: event.item.address_id || event.item.id || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			case "onFullAddressSelect":
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value,
						action: {
							select_address: {
								street_id: event.item.street_id || null,
								address_id: event.item.address_id || event.item.id || null,
								streetGuid: event.item.street_guid || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			case "onMadeSelect": {
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value,
						action: {
							show_tariffs: {
								address_id: event.item.address_id
									? event.item.address_id
									: event.item.id
										? event.item.id
										: null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			}
			case "onRouteSelect": {
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value,
						action: {
							select_typesearch: {
								type: event.value || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			}
			case "onComponentMount": {
				const found = cookie.get("search_shown");

				if (!found) {
					cookie.set("search_shown", 1);

					client.mutate({
						mutation: MUTATE_SEARCH_EVENT_QUERY,
						variables: {
							query: event.type,
							action: {
								other_action: {
									type: event.value || null,
								},
							},
							user_info: userInfo,
							experiments: experiments,
						},
					});
				}

				break;
			}
			case "onAddressFirstInput": {
				const found = cookie.get("search_address_first_input");

				if (!found) {
					cookie.set("search_address_first_input", 1);

					client.mutate({
						mutation: MUTATE_SEARCH_EVENT_QUERY,
						variables: {
							query: event.value,
							action: {
								other_action: {
									type: event.type || null,
								},
							},
							user_info: userInfo,
							experiments: experiments,
						},
					});
				}

				break;
			}
			case "onOtherSearchAction": {
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.type,
						action: {
							other_action: {
								type: event.value || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});

				break;
			}
			case "not_selected": {
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value || null,
						action: {
							select_address: {
								street_id: event.item.street_id || null,
								address_id: event.item.address_id || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			}
			default: {
				console.log("not send by default");
			}
		}
	}

	@action
	setSelectProvider(provider) {
		const client = this.rootStore.apolloTransportLayer;
		client.mutate({
			mutation: MUTATE_EMPTY_ADDRESS_PROVIDER,
			variables: {
				likeProvider: provider.likeProviderName,
				provider: provider.valueProvider,
				regionId: this.rootStore.currentRegion?.id,
				regionName: this.rootStore.currentRegion?.name,
				districtId: this.rootStore.currentRegion?.district_id,
				streetId: this.currentFullAddress?.data?.street_id,
				streetName: this.currentFullAddress?.data?.street_full,
				home: this.currentFullAddress.data ? this.getFullHouse() : 0,
			},
		});
	}

	@action
	setCurrentFullAddress(item, noSplit = false, no_event) {
		if (!item) {
			return;
		}

		if (!no_event) {
			this.sendSearchEvent(
				new SearchEvent({
					value: this.searcheForm.fields.fullAddress.value,
					item: item,
				})
			);
		}

		let valueFullAddress;

		if (noSplit) {
			valueFullAddress = item.result;
		} else {
			valueFullAddress = item.result.split(",");
			valueFullAddress = valueFullAddress[valueFullAddress.length - 1];
		}

		let newItem = JSON.parse(JSON.stringify(item));
		newItem.data.address_id = item.data.address_id;
		newItem.data.street_id = item.data.street_id;
		newItem.result = valueFullAddress.trim();
		this.currentFullAddress = newItem;
		this.fullAddressList.clear();

		this.searcheForm.fields.fullAddress.value = newItem.result;

		return this.refineFullAddress();
	}

	@action
	setCurrentFullAddressStreetOnly(item) {
		if (!item) {
			return;
		}

		let valueFullAddress;

		valueFullAddress = item.result.split(",");
		valueFullAddress = valueFullAddress[valueFullAddress.length - 1];

		let newItem = JSON.parse(JSON.stringify(item));
		newItem.data.street_id = item.data.street_id;
		newItem.result = valueFullAddress.trim();
		this.currentFullAddress = newItem;

		this.searcheForm.fields.fullAddress.value = newItem.result;

		this.loadFullAddresses(newItem.result);
	}

	@action
	setCurrentStreet(item) {
		if (!item) {
			return;
		}

		let valueFullAddress = item.result;

		let newItem = JSON.parse(JSON.stringify(item));
		newItem.result = valueFullAddress.trim();
		this.currentFullAddress = newItem;
		this.fullAddressList.clear();

		this.searcheForm.fields.fullAddress.value = newItem.result;
	}

	@action
	setFullAddresses(data) {
		if (data != null) {
			this.fullAddressList.replace(data);
		} else {
			this.fullAddressList = [];
		}
	}

	@action
	resetData() {
		this.fullAddressList.clear();
		this.currentFullAddress = {};
		this.loading = false;
		this.searcheForm.fields.fullAddress.reset();
		this.house_id = 0;
	}

	@action
	removeHouseFromInput() {
		if (this.currentFullAddress?.data?.street_full) {
			this.searcheForm.fields.fullAddress.value = this.currentFullAddress?.data?.street_full;
		} else {
			this.resetData();
		}
	}

	saveToMemoryData() {
		this.memoryData = {
			currentFullAddress: this.currentFullAddress,
			fullAddressField: this.searcheForm.fields.fullAddress.value,
		};
	}

	deleteMemoryData() {
		this.memoryData = {};
	}

	@action
	recoveryMemoryData() {
		this.currentFullAddress = this.memoryData.currentFullAddress;
		this.searcheForm.fields.fullAddress.value = this.memoryData.fullAddressField;
	}

	@computed
	get isShowFullAddressList() {
		return !!this.fullAddressList.length;
	}

	@computed
	get isDisabledFullAddress() {
		return isEmpty(this.rootStore.currentRegion) || isEmpty(this.currentFullAddress);
	}

	@action
	updateValueFullAddress(value) {
		clearTimeout(this.timeOutID);

		if (value === this.searcheForm.fields.fullAddress.value) {
			return;
		}

		let trValue = this.translateAddress(value);

		this.searcheForm.fields.fullAddress.value = trValue;
		this.currentFullAddress = {};

		if (trValue.toString().length >= 3) {
			this.debounceLoadFullAddresses(trValue);
		} else if (trValue.toString().length === 0) {
			this.setFullAddresses([]);
		} else {
			if (this.searcheWithDelay) {
				this.timeOutID = setTimeout(() => {
					this.searcheWithDelay = false;
					this.loadFullAddresses(trValue);
				}, 3000);
			} else {
				this.loadFullAddresses(trValue);
			}
		}
	}

	@action
	setSelectOneString(boolean) {
		this.selectOneString = boolean;
	}

	translateAddress(address) {
		return address
			.split("")
			.map((item) => {
				if (notTranslateChars.indexOf(item) < 0) {
					return Translater.fromEn(item);
				}
				return item;
			})
			.join("");
	}

	@Debounce(300)
	debounceLoadFullAddresses(value) {
		return this.loadFullAddresses(value);
	}

	loadFullAddresses(value) {
		let uuid = cookie.get("uuid");
		if (value.length > 0) {
			this.loading = true;
			let filter = `with_tariffs=1&with_around=1&region_id=${this.rootStore.currentRegion.id}&query=${value}&uuid=${uuid}&from_bound=street`;

			if (this.rootStore.currentRegion.district_id) {
				filter += `&district_id=${this.rootStore.currentRegion.district_id}`;
			}

			let query = this.rootStore.apolloTransportLayer.query({
				query: SEARCH_FULL_ADDRESS_QUERY_V2,
				variables: { filter: filter },
			});
			query.then((json) => {
				if (this.searcheForm.fields.fullAddress.value === value) {
					this.setFullAddresses(json.data.searchFullAddressV2.data);
					this.loading = false;
				}
			});
		} else {
			this.setFullAddresses([]);
		}
	}

	refineFullAddress() {
		if (this.currentFullAddress?.data?.address_id) {
			return new Promise((res) => {
				res(this.currentFullAddress);
			});
		}

		this.loading = true;
		let query = this.rootStore.apolloTransportLayer.query({
			query: REFINE_FULL_ADDRESS_QUERY_V2,
			variables: {
				region_guid: this.currentFullAddress.data.region_guid,
				house_guid: this.currentFullAddress.data.house_guid,
				street_guid: this.currentFullAddress.data.street_guid,
				house: this.currentFullAddress.data.house,
				building: this.currentFullAddress.data.building,
				construction: this.currentFullAddress.data.construction,
			},
		});
		return query.then((json) => {
			const data = json?.data?.refineFullAddressV2;

			if (data) {
				if (data.address_id) {
					this.currentFullAddress.data.address_id = data.address_id;
				}
				if (data.street_id) {
					this.currentFullAddress.data.street_id = data.street_id;
				}
				if (data.district_id) {
					this.currentFullAddress.data.district_id = data.district_id;
				}
				if (data.region_id) {
					this.currentFullAddress.data.region_id = data.region_id;
				}
			}

			this.loading = false;

			return this.currentFullAddress;
		});
	}

	@action
	findAddress(houseId) {
		if (Number.isInteger(parseInt(houseId))) {
			const query = this.rootStore.apolloTransportLayer.query({
				query: HOUSE_FULL_QUERY_V4,
				variables: { id: parseInt(houseId) },
			});

			query.then((json) => {
				let house = json?.data?.houseFullV4;

				if (!!house?.address_id) {
					let queryStreet = this.rootStore.apolloTransportLayer.query({
						query: STREET_ONE_QUERY,
						variables: { id: house.street_id },
					});

					queryStreet.then((json) => {
						let street = json?.data?.streetsOne;

						if (street) {
							let currentFullAddressData = {
								...this.currentFullAddress.data,
								house: house?.house_full,
								address_id: house.address_id,
								street_id: street.id,
								street: street.name,
								street_full: street.full,
								street_name: street.name,
								district_id: street.district?.id,
								district_name: street.district?.name,
								region_id: street.region?.id,
								region_name: street.region?.name,
								region_is_capital: street.region?.is_capital,
							};

							let result =
								this.currentFullAddress?.result ||
								`${street.type} ${street.name}, ${house.house_full}`;

							this.setCurrentFullAddress(
								{
									...this.currentFullAddress,
									result: result,
									data: currentFullAddressData,
								},
								true
							);
						}
					});
				}
			});
		}
	}

	@action
	findTariffsByAddress(provider_id) {
		if (this.currentFullAddress.data.address_id === -1) {
			return null;
		}

		if (!this.currentFullAddress.data.address_id) {
			return null;
		}

		let query = this.rootStore.apolloTransportLayer.query({
			query: CHECK_PROVIDER_ADDRESS_QUERY_V2,
			variables: {
				address_id: this.currentFullAddress.data?.address_id,
				provider_id: provider_id,
			},
		});

		return query.then((json) => {
			return json?.data?.checkProviderAddressV2;
		});
	}

	getFullAddresses() {
		return toJS(this.fullAddressList);
	}

	getCurrentFullAddress() {
		return toJS(this.currentFullAddress);
	}

	checkErrors() {
		if (
			this.currentFullAddress.data?.address_id &&
			this.searcheForm.fields.fullAddress.value.length
		) {
			return false;
		} else {
			return (
				this.searcheForm.fields.fullAddress.value.length === 0 ||
				!this.currentFullAddress.data?.street_guid ||
				!this.currentFullAddress.data?.house
			);
		}
	}

	checkErrorsTender() {
		return !this.searcheForm.fields.fullAddress.value.length;
	}

	checkOnUnselectedFullAddress() {
		return (
			!this.currentFullAddress?.data?.address_id &&
			!this.currentFullAddress?.data?.house_guid &&
			!this.currentFullAddress?.data?.house
		);
	}

	@action setViewQuizFromTariffs(value) {
		this.viewQuizFromTariffs = value;
	}

	@action
	setRecentlyConnectedSelectedTariffId(value) {
		this.recentlyConnectedSelectedTariffId = value;
	}

	@action
	setActionSelectedTariffId(value) {
		this.actionSelectedTariffId = value;
	}

	getFullHouse() {
		let house = `${this.currentFullAddress.data.house}`;
		if (this.currentFullAddress.data.building) {
			house += ` к${this.currentFullAddress.data.building}`;
		}
		if (this.currentFullAddress.data.construction) {
			house += ` с${this.currentFullAddress.data.construction}`;
		}

		return house;
	}

	@action
	sendRegionEmptySearch(region_id, region_name, value) {
		//Компонент, из которого вызывается данный метод, обновляется несколько раз
		//при каждом изменении пользовательского ввода, поэтому фильтруем вызовы
		//чтобы не засорять логи
		if (this.lastEmptySearchValue !== value) {
			const client = this.rootStore.apolloTransportLayer;
			client.mutate({
				mutation: MUTATE_EMPTY_REGION_SEARCH,
				variables: {
					region_id: region_id,
					region_name: region_name,
					value: value,
				},
			});

			this.lastEmptySearchValue = value;
		}
	}
}

class SearchEvent {
	type = "";
	value = "";
	item = {};

	constructor(options) {
		this.value = options.value;
		this.item = options.item;

		if (options.type) {
			this.type = options.type;
			return;
		}

		if (!!options.confirm) {
			this.type = "onMadeSelect";
		} else if (options.item.route) {
			this.type = "onRouteSelect";
		} else if (options.item.address_id) {
			this.type = "onAddressSelect";
		} else if (options.item.street_id) {
			this.type = "onStreetSelect";
		} else if (options.item.district_id) {
			this.type = "onDistrictSelect";
		} else if (options.item.region_id) {
			this.type = "onRegionSelect";
		} else {
			this.type = "not_selected";
		}
	}
}
