import React from "react";
import { observer, inject } from "mobx-react";
import { UpperMenu } from "ComponentsUI/Navigation";

import { NavLink, withRouter } from "react-router-dom";
var Routes = require("Configs/routes");
import { generateUrl } from "Services/withDefaultParams";
import withDefaultParams from "Services/withDefaultParams";

import Metrika from "Services/Metrika";
import { CLICK_SELF, CLICK_BUSINESS } from "Constants/metriks/Yandex";

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
export default class HeadMenuContainer extends React.Component {
	onClickHandler(goal) {
		this.props.ApplicationModel.commonViewModel.toggleMobileMenu();
	}

	render() {
		let textConfig = this.props.ApplicationModel.text;
		let currentRegionUrl = this.props.ApplicationModel.currentRegion?.url;

		let regionUrl = this.props.match.params.regionUrl;
		let arrayNotRegionUrl = [
			"providers",
			"news",
			"reviews",
			"compare",
			"actions",
			"rate",
			"orders",
			"business",
			"rating",
			"address",
			"terms-of-use",
			"personal-data",
			"feedback",
			"about",
			"contact-feedback",
		];
		let regionUrlReal = arrayNotRegionUrl.indexOf(regionUrl) >= 0 ? "" : regionUrl;

		let defaultUrl = generateUrl(
			Routes.Default.route,
			{
				regionUrl: regionUrlReal,
			},
			currentRegionUrl
		);

		let businessUrl = generateUrl(
			Routes.OrderOffice.route,
			{
				regionUrl: regionUrlReal,
			},
			currentRegionUrl
		);

		let isBusinessPage =
			this.props.location.pathname.indexOf("/business") >= 0 ||
			this.props.location.pathname.indexOf("/orders/office") >= 0;
		return (
			<UpperMenu inWidget={this.props.inWidget}>
				<NavLink
					exact
					key={0}
					isActive={() => !isBusinessPage}
					to={defaultUrl}
					datatest="business_forme_button"
					onClick={() => this.onClickHandler(CLICK_SELF)}
				>
					{textConfig.headerForMyself}
				</NavLink>
				<NavLink
					exact
					key={1}
					isActive={() => isBusinessPage}
					to={businessUrl}
					datatest="main_business_upper_button"
					onClick={() => this.onClickHandler(CLICK_BUSINESS)}
				>
					{textConfig.headerForBusiness}
				</NavLink>
			</UpperMenu>
		);
	}
}
