import { observable, action } from "mobx";

export default class FiltersModel {
	@observable rootStore = {};
	@observable modifyFilters = [];
	@observable modifySort = [];
	@observable paginatorPage = 1;
	@observable selectedFiltersUrl = "";
	@observable loadFilters = 0;

	@observable filtersAvialable = [];
	@observable houseId = null;
	@observable streetId = null;
	@observable badhouse = null;
	@observable exist = null;
	@observable badHouseAddress = [];

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	setBadHouseAddress(data) {
		this.badHouseAddress = data;
	}
	@action
	setFiltersAvalable(data) {
		this.filtersAvialable = data;
		// this.loadFilters = 1;
	}

	@action
	addFiltersAvalable(data) {
		this.filtersAvialable = data;
	}

	@action
	setHouseId(data) {
		if (!!data) {
			if (this.houseId == null) {
				this.exist = null;
				this.houseId = data;
			} else {
				if (data != this.houseId) {
					this.exist = null;
					this.houseId = data;
				}
			}
		}
	}

	@action
	setExists(data) {
		this.exist = data;
	}

	@action
	setStreetId(data) {
		this.streetId = data;
	}

	@action
	setBadhouse(data) {
		this.badhouse = data;
	}

	@action
	setPaginatorPage(page) {
		this.paginatorPage = page;
	}

	@action
	changeFilters(filters) {
		this.modifyFilters = filters;
	}

	@action
	changeFilters2(filters) {
		for (let keyFilter in this.filtersAvialable) {
			if (filters) {
				for (let keyNewFilter in filters) {
					if (keyNewFilter == keyFilter) {
						if (["price", "speed"].indexOf(keyFilter) != -1) {
							if (
								filters[keyFilter][0].min == this.filtersAvialable[keyFilter][0].min &&
								filters[keyFilter][0].max == this.filtersAvialable[keyFilter][0].max
							) {
								filters[keyNewFilter] = null;
							}
						}
					}
				}
			}
		}
		this.modifyFilters = filters;
	}

	@action
	dropFilters(filter) {
		if (this.modifyFilters[filter]) {
			this.modifyFilters[filter] = null;
		}
	}

	@action
	selectedFilters(filters) {
		this.selectedFiltersUrl = filters;
	}

	@action
	changeSort(modifySort) {
		this.modifySort = modifySort;
	}

	@action
	reset() {
		// this.filtersAvialable = [];
		this.modifyFilters = [];
		this.modifySort = [];
		this.selectedFiltersUrl = "";
		this.loadFilters = 0;
		this.houseId = null;
		this.streetId = null;
		this.badhouse = null;
		this.badHouseAddress = [];
	}

	getFilters(filters) {
		this.changeFilters(filters);
		let filtersUrl = "";
		let i = 0;
		for (let filter in this.modifyFilters) {
			if (this.modifyFilters[filter] && this.modifyFilters[filter].length) {
				if (i > 0) {
					filtersUrl += "&";
				}
				if (filter == "price" || filter == "speed") {
					filtersUrl += `${filter}=${this.modifyFilters[filter][0].min},${this.modifyFilters[filter][0].max}`;
				} else {
					filtersUrl += `${filter}=${this.modifyFilters[filter].map((item) => item.id).join()}`;
				}
				i++;
			}
		}
		this.selectedFilters(filtersUrl);
		return filtersUrl;
	}

	getFilters2() {
		let queryStr = [];
		let i = 0;
		for (let filter in this.modifyFilters) {
			if (this.modifyFilters[filter] && this.modifyFilters[filter].length) {
				if (filter == "price" || filter == "speed") {
					queryStr.push(
						`${filter}=${this.modifyFilters[filter][0].min},${this.modifyFilters[filter][0].max}`
					);
				} else {
					queryStr.push(`${filter}=${this.modifyFilters[filter].map((item) => item.id).join()}`);
				}
				i++;
			}
		}

		if (!!this.houseId) {
			queryStr.push(`house_id=${this.houseId}`);
		} else if (!!this.badHouseAddress) {
			queryStr.push(`street_id=${this.badHouseAddress.street_id}`);
			queryStr.push(`badhouse=${this.badHouseAddress.badhouse}`);
		}

		if (this.exist && this.exist.exist.length) {
			queryStr.push(`exists=${this.exist.exist[0].id}`);
		}

		const filtersUrl = queryStr.join("&");
		this.selectedFilters(filtersUrl);
		return filtersUrl;
	}

	getFiltersUrl() {
		let filtersUrl = "";
		let i = 0;
		for (let filter in this.modifyFilters) {
			if (this.modifyFilters[filter].length) {
				if (i > 0) {
					filtersUrl += "&";
				}
				if (filter == "price" || filter == "speed") {
					filtersUrl += `${filter}=${this.modifyFilters[filter][0].min},${this.modifyFilters[filter][0].max}`;
				} else {
					filtersUrl += `${filter}=${this.modifyFilters[filter].map((item) => item.id).join()}`;
				}
				i++;
			}
		}
		this.selectedFilters(filtersUrl);
		return filtersUrl;
	}
}
