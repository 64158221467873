import React from "react";
import { TextField } from "ComponentsUI/TextField";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Col } from "ComponentsUI/Styles/Grid";
import Row from "reactstrap/lib/Row";

const formStyle = (theme) => ({
	root: {
		alignItems: "flex-end",
	},
	textFieldWrapper: {
		width: "100%",
	},
	buttonWrapper: {
		width: "100%",
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			display: "block",
			width: "290px",
			flexDirection: "column",
			alignItems: "flex-start",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		textFieldWrapper: {
			marginBottom: "13px",
			maxWidth: "280px",
			margin: "0 auto",
		},
		buttonWrapper: {
			marginTop: "18px",
			marginBottom: "13px",
			maxWidth: "280px",
			marginLeft: "auto",
			marginRight: "auto",
		},
	},
});

const InlineForm = withStyles(formStyle)(({ children, classes }) => {
	const rowStyle = {
		alignItems: "flex-end",
	};
	let newChildren = children.map((child, i) => {
		if (!child) return null;
		if (child.type.displayName === "TextField" && i === 0) {
			return (
				<Col
					lg={{ size: 4, offset: 1 }}
					md={{ size: 4 }}
					sm={{ size: 4 }}
					xs={{ size: 12 }}
					key={i}
				>
					<div key={i} className={classes.textFieldWrapper}>
						{React.cloneElement(child, { ...child.props, key: i })}
					</div>
				</Col>
			);
		}
		if (child.type.displayName === "TextField" && i === 1) {
			return (
				<Col lg={3} md={4} sm={4} xs={12} key={i}>
					<div key={i} className={classes.textFieldWrapper}>
						{React.cloneElement(child, { ...child.props, key: i })}
					</div>
				</Col>
			);
		}
		if (child.type.displayName === "Button") {
			return (
				<Col lg={3} md={4} sm={4} xs={12} key={i}>
					<div key={i} className={classes.buttonWrapper}>
						{React.cloneElement(child, { ...child.props, key: i })}
					</div>
				</Col>
			);
		}
		return React.cloneElement(child, { ...child.props, key: i });
	});
	return (
		<form>
			<Row style={rowStyle}>{newChildren}</Row>
		</form>
	);
});
InlineForm.propTypes = {
	children: PropTypes.node,
};
export default InlineForm;
