import { action, computed, observable, toJS } from "mobx";
import cookie from "js-cookie";
import Field from "./form/Field";
import isEmpty from "lodash/isEmpty";
import {
	CHECK_PROVIDER_ADDRESS_QUERY_V2,
	MUTATE_SEARCH_EVENT_QUERY,
	SEARCH_ADDRESS_QUERY_V3,
	SEARCH_HOUSE_QUERY_V6,
	STREET_BY_HOUSE_QUERY,
	MUTATE_EMPTY_ADDRESS_PROVIDER,
	MUTATE_INFO_EXIT_USER,
	MUTATE_EMPTY_REGION_SEARCH,
	HOUSE_FULL_QUERY_V4,
	MUTATE_LOG_SELECT_REGION,
	MUTATE_LOG_PROVIDERS,
} from "Services/queries";
import Translater from "convert-layout/ru";
import { addABData } from "Utils";

const notTranslateChars = [",", ".", "/"];

export default class SearcheModel {
	timeOutID = null;
	searcheWithDelay = true;
	fetchHouseUrl = "/houses";

	@observable
	searcheForm = {
		fields: {
			street: new Field(""),
			address: new Field(""),
		},
		meta: {
			isValid: true,
			error: null,
		},
	};

	@observable currentAddress = {};
	@observable currentStreet = {};
	@observable loading = false;
	@observable viewQuizFromTariffs = false;
	@observable streetList = [];
	@observable addressList = [];
	@observable recentlyConnectedSelectedTariffId = 0;
	@observable actionSelectedTariffId = 0;
	@observable house_id = 0;

	lastEmptySearchValue = "";

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	setHouseId(id) {
		this.house_id = id;
		cookie.set("house_id", id, { expires: 1 });
	}

	submit() {
		this.sendSearchEvent(
			new SearchEvent({
				confirm: true,
				value: this.searcheForm.fields.address.value,
				item: toJS(this.currentAddress),
			})
		);
	}

	selectRoute(route) {
		this.sendSearchEvent(
			new SearchEvent({
				item: { route: route.name },
				value: route.name,
			})
		);
	}

	emitSearchEvent(type, value) {
		this.sendSearchEvent(
			new SearchEvent({
				type,
				value,
			})
		);
	}

	sendSearchEvent(event) {
		const client = this.rootStore.apolloTransportLayer;

		const userInfo = {
			uuid_user: cookie.get("uuid") || "Не был задан uuid",
			user_agent: navigator.userAgent,
			width: 0,
			height: 0,
		};

		if (typeof window == "object") {
			userInfo.width = window.innerWidth;
			userInfo.height = window.innerHeight;
			userInfo.url = window?.location?.href;
		}

		const abData = addABData({});
		const experiments = [];

		if (abData?.ab && Object.keys(abData.ab)?.length) {
			for (const e of Object.keys(abData.ab)) {
				experiments.push({ name: e, variant: abData.ab[e] });
			}
		}

		switch (event.type) {
			case "onStreetSelect":
			case "onAddressSelect":
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value,
						action: {
							select_address: {
								street_id: event.item.street_id || null,
								address_id: event.item.address_id || event.item.id || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			case "onMadeSelect": {
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value,
						action: {
							show_tariffs: {
								address_id: event.item.address_id
									? event.item.address_id
									: event.item.id
										? event.item.id
										: null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			}
			case "onRouteSelect": {
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value,
						action: {
							select_typesearch: {
								type: event.value || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			}
			case "onComponentMount": {
				const found = cookie.get("search_shown");

				if (!found) {
					cookie.set("search_shown", 1);

					client.mutate({
						mutation: MUTATE_SEARCH_EVENT_QUERY,
						variables: {
							query: event.type,
							action: {
								other_action: {
									type: event.value || null,
								},
							},
							user_info: userInfo,
							experiments: experiments,
						},
					});
				}

				break;
			}
			case "onStreetFirstInput": {
				const found = cookie.get("search_street_first_input");

				if (!found) {
					cookie.set("search_street_first_input", 1);

					client.mutate({
						mutation: MUTATE_SEARCH_EVENT_QUERY,
						variables: {
							query: event.value,
							action: {
								other_action: {
									type: event.type || null,
								},
							},
							user_info: userInfo,
							experiments: experiments,
						},
					});
				}

				break;
			}
			case "onHouseFirstInput": {
				const found = cookie.get("search_house_first_input");

				if (!found) {
					cookie.set("search_house_first_input", 1);

					client.mutate({
						mutation: MUTATE_SEARCH_EVENT_QUERY,
						variables: {
							query: event.value,
							action: {
								other_action: {
									type: event.type || null,
								},
							},
							user_info: userInfo,
							experiments: experiments,
						},
					});
				}

				break;
			}
			case "onOtherSearchAction": {
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.type,
						action: {
							other_action: {
								type: event.value || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});

				break;
			}
			case "not_selected": {
				client.mutate({
					mutation: MUTATE_SEARCH_EVENT_QUERY,
					variables: {
						query: event.value || null,
						action: {
							select_address: {
								street_id: event.item.street_id || null,
								address_id: event.item.address_id || null,
							},
						},
						user_info: userInfo,
						experiments: experiments,
					},
				});
				break;
			}
			default: {
				console.log("not send by default");
			}
		}
	}

	@action
	setSelectProvider(provider) {
		const client = this.rootStore.apolloTransportLayer;
		client.mutate({
			mutation: MUTATE_EMPTY_ADDRESS_PROVIDER,
			variables: {
				likeProvider: provider.likeProviderName,
				provider: provider.valueProvider,
				regionId: provider.address.region.id,
				regionName: provider.address.region.name,
				districtId: provider.address.district.id,
				districtName: provider.address.district.name,
				streetId: provider.address.street.id,
				streetName: provider.address.street.name,
				home: provider.address.home,
			},
		});
	}

	@action
	setInfoUserExitSite(userInfo) {
		const client = this.rootStore.apolloTransportLayer;
		client.mutate({
			mutation: MUTATE_INFO_EXIT_USER,
			variables: {
				uuidUser: cookie.get("uuid") || "Не был задан uuid",
				selectQuestion: userInfo.selectQuestion,
				motive: userInfo.motive,
				url: userInfo.url,
				regionId: userInfo.region.id,
				regionName: userInfo.region.name,
				timeEventSite: userInfo.timeEventSite,
				userAgent: navigator.userAgent,
				resolution: `${screen.width}x${screen.height}`,
				catNumber: userInfo.catNumber,
				type: userInfo.type,
			},
		});
	}

	@action
	sendRegionEmptySearch(region_id, region_name, value) {
		//Компонент, из которого вызывается данный метод, обновляется несколько раз
		//при каждом изменении пользовательского ввода, поэтому фильтруем вызовы
		//чтобы не засорять логи
		if (this.lastEmptySearchValue !== value) {
			const client = this.rootStore.apolloTransportLayer;
			client.mutate({
				mutation: MUTATE_EMPTY_REGION_SEARCH,
				variables: {
					region_id: region_id,
					region_name: region_name,
					value: value,
				},
			});

			this.lastEmptySearchValue = value;
		}
	}
	@action
	sendSelectRegion(focus_select_region, region_name, district_name) {
		const client = this.rootStore.apolloTransportLayer;
		const uuid = cookie.get("uuid") || "Не был задан uuid";
		client.mutate({
			mutation: MUTATE_LOG_SELECT_REGION,
			variables: {
				uuid: uuid,
				focus_select_region: focus_select_region,
				region_name: region_name,
				district_name: district_name,
			},
		});
	}
	@action
	sendLogProviders(url, region_name) {
		const client = this.rootStore.apolloTransportLayer;
		const uuid = cookie.get("uuid") || "Не был задан uuid";
		client.mutate({
			mutation: MUTATE_LOG_PROVIDERS,
			variables: {
				uuid: uuid,
				url: url,
				region_name: region_name,
			},
		});
	}

	@action
	setCurrentStreet(item, no_event = false) {
		if (!item?.full) {
			return;
		}

		if (!no_event) {
			this.sendSearchEvent(
				new SearchEvent({
					value: this.searcheForm.fields.street.value,
					item: item,
				})
			);
		}

		let valueStreet = item.full.split(",");
		valueStreet = valueStreet[valueStreet.length - 1];

		let newItem = JSON.parse(JSON.stringify(item));
		newItem.id = item.street_id;
		newItem.full_street = item.full;
		newItem.full = valueStreet;
		this.currentStreet = newItem;
		this.streetList.clear();
		this.searcheForm.fields.street.value = newItem.full;
		this.searcheForm.fields.address.value = "";
		this.currentAddress = {};
	}

	@action
	setCurrentAddress(item, no_event = false) {
		if (!item) {
			return;
		}

		if (!no_event) {
			this.sendSearchEvent(
				new SearchEvent({
					value: this.searcheForm.fields.address.value,
					item: item,
				})
			);
		}

		let newItem = JSON.parse(JSON.stringify(item));
		newItem.id = item.id;
		this.currentAddress = newItem;
		this.addressList.clear();
		this.searcheForm.fields.address.value = newItem.full;
	}

	generateStreetForChoosenHouse(item) {
		let full = item.full.substr(0, item.full.indexOf(item.house) - 1);
		return {
			...item,
			full: full,
		};
	}

	@action
	setAddresses(data) {
		if (data != null) {
			this.addressList.replace(data);
		} else {
			this.addressList = [];
		}
	}

	@action
	setStreets(data) {
		if (data != null) {
			this.streetList.replace(data);
		} else {
			this.streetList = [];
		}
	}

	@action
	resetData() {
		this.addressList.clear();
		this.streetList.clear();
		this.currentAddress = {};
		this.currentStreet = {};
		this.loading = false;
		this.searcheForm.fields.street.reset();
		this.searcheForm.fields.address.reset();
		this.house_id = 0;
	}

	saveToMemoryData() {
		this.memoryData = {
			currentAddress: this.currentAddress,
			currentStreet: this.currentStreet,
			streetField: this.searcheForm.fields.street.value,
			addressField: this.searcheForm.fields.address.value,
		};
	}

	deleteMemoryData() {
		this.memoryData = {};
	}

	@action
	recoveryMemoryData() {
		this.currentStreet = this.memoryData.currentStreet;
		this.currentAddress = this.memoryData.currentAddress;
		this.searcheForm.fields.street.value = this.memoryData.streetField;
		this.searcheForm.fields.address.value = this.memoryData.addressField;
	}

	@computed
	get isShowAddressList() {
		return !!this.addressList.length;
	}

	@computed
	get isShowStreetList() {
		return !!this.streetList.length;
	}

	@computed
	get isDisabledAddress() {
		return isEmpty(this.rootStore.currentRegion) || isEmpty(this.currentStreet);
	}

	@action
	updateValueStreet(value) {
		clearTimeout(this.timeOutID);

		if (value === this.searcheForm.fields.street.value) {
			return;
		}

		let trValue = this.translateAddress(value);

		this.searcheForm.fields.street.value = trValue;
		this.searcheForm.fields.address.value = "";
		this.currentStreet = {};
		this.currentAddress = {};
		this.setAddresses([]);

		if (trValue.toString().length >= 3) {
			this.loadStreets(trValue);
		} else if (trValue.toString().length == 0) {
			this.setStreets([]);
		} else {
			if (this.searcheWithDelay) {
				this.timeOutID = setTimeout(() => {
					this.searcheWithDelay = false;
					this.loadStreets(trValue);
				}, 3000);
			} else {
				this.loadStreets(trValue);
			}
		}
	}

	@action
	updateValueAddress(value) {
		let trValue = this.translateAddress(value);

		if (value === this.searcheForm.fields.address.value) {
			return;
		}

		this.searcheForm.fields.address.value = trValue;
		this.currentAddress = {};
		this.loadAddresses(trValue);
	}

	translateAddress(address) {
		return address
			.split("")
			.map((item) => {
				if (notTranslateChars.indexOf(item) < 0) {
					return Translater.fromEn(item);
				}
				return item;
			})
			.join("");
	}

	loadStreets(value) {
		const sessionId = cookie.get("uuid");

		if (value.length > 0) {
			this.loading = true;

			const queryData = {
				with_around: 1,
				query: value,
				type: "street",
			};

			if (this.rootStore?.currentRegion?.id) {
				queryData.region_id = this.rootStore.currentRegion.id;
			}

			const userInfo = {
				uuid_user: sessionId || "Не был задан uuid",
				user_agent: navigator.userAgent,
				width: 0,
				height: 0,
			};

			if (typeof window == "object") {
				userInfo.width = window.innerWidth;
				userInfo.height = window.innerHeight;
				userInfo.url = window?.location?.href;
			}

			const abData = addABData({});
			const experiments = [];

			if (abData?.ab && Object.keys(abData.ab)?.length) {
				for (const e of Object.keys(abData.ab)) {
					experiments.push({ name: e, variant: abData.ab[e] });
				}
			}

			let query = this.rootStore.apolloTransportLayer.query({
				query: SEARCH_ADDRESS_QUERY_V3,
				variables: { query_data: queryData, user_info: userInfo, experiments: experiments },
			});

			query.then((json) => {
				if (this.searcheForm.fields.street.value === value) {
					this.setStreets(json.data.searchStreetV3.data);
					this.loading = false;
				}
			});
		} else {
			this.setAddresses([]);
		}
	}

	loadAddresses(value) {
		if (value.length === 0) {
			this.setAddresses([]);
			return;
		}
		if (!this.currentStreet?.street_id) {
			return;
		}

		const sessionId = cookie.get("uuid");

		this.loading = true;

		const queryData = {
			street_id: this.currentStreet.street_id,
			query: value.toLowerCase(),
			with_tariffs: 1,
			with_default: 1,
			type: "address",
		};

		const userInfo = {
			uuid_user: sessionId || "Не был задан uuid",
			user_agent: navigator.userAgent,
			width: 0,
			height: 0,
		};

		if (typeof window == "object") {
			userInfo.width = window.innerWidth;
			userInfo.height = window.innerHeight;
			userInfo.url = window?.location?.href;
		}

		const abData = addABData({});
		const experiments = [];

		if (abData?.ab && Object.keys(abData.ab)?.length) {
			for (const e of Object.keys(abData.ab)) {
				experiments.push({ name: e, variant: abData.ab[e] });
			}
		}

		let query = this.rootStore.apolloTransportLayer.query({
			query: SEARCH_HOUSE_QUERY_V6,
			variables: {
				query_data: queryData,
				user_info: userInfo,
				experiments: experiments,
			},
		});

		query.then((json) => {
			if (this.searcheForm.fields.address.value === value) {
				let addresses = json.data.searchHousesV6.data;
				if (addresses && addresses.length > 0) {
					this.setAddresses(addresses);
				} else {
					this.setAddresses([
						{
							highlight: {
								full: `<em>${value}</em>`,
							},
							data: {
								id: -1,
								house: value,
								full: value,
							},
						},
					]);
				}
				this.loading = false;
			}
		});
	}

	@action
	findAddress(houseId) {
		if (Number.isInteger(parseInt(houseId))) {
			Promise.all([
				this.rootStore.apolloTransportLayer.query({
					query: STREET_BY_HOUSE_QUERY,
					variables: { id: parseInt(houseId) },
				}),
				this.rootStore.apolloTransportLayer.query({
					query: HOUSE_FULL_QUERY_V4,
					variables: { id: parseInt(houseId) },
				}),
			]).then((data) => {
				if (data?.length === 2) {
					const street = data[0]?.data?.streetByHouse;
					if (street) {
						this.setCurrentStreet(
							{
								...street,
								street_id: street.id,
								district: street.district?.name,
							},
							true
						);
					}

					const house = data[1]?.data?.houseFullV4;
					if (!!house?.address_id) {
						this.setCurrentAddress(
							{
								...house,
								id: house?.address_id,
								full: house?.house_full,
							},
							true
						);
					}
				}
			});
		}
	}

	@action
	findTariffsByAddress(provider_id) {
		if (this.currentAddress.id === -1) {
			return null;
		}

		if (!this.currentAddress.id) {
			return null;
		}

		let query = this.rootStore.apolloTransportLayer.query({
			query: CHECK_PROVIDER_ADDRESS_QUERY_V2,
			variables: {
				address_id: this.currentAddress.id,
				provider_id: provider_id,
			},
		});

		return query.then((json) => {
			return json?.data?.checkProviderAddressV2;
		});
	}

	getAddresses() {
		return toJS(this.addressList);
	}

	getStreets() {
		return toJS(this.streetList);
	}

	getCurrentAddress() {
		return toJS(this.currentAddress);
	}

	getCurrentStreet() {
		return toJS(this.currentStreet);
	}

	checkErrors() {
		return this.searcheForm.fields.address.value.length === 0 || !this.currentStreet.id;
	}

	checkOnUnselectedHouse() {
		return this.searcheForm.fields.address.value.length === 0;
	}

	checkOnUnselectedStreet() {
		return !this.currentStreet.id;
	}

	@action setViewQuizFromTariffs(value) {
		this.viewQuizFromTariffs = value;
	}

	@action
	setRecentlyConnectedSelectedTariffId(value) {
		this.recentlyConnectedSelectedTariffId = value;
	}

	@action
	setActionSelectedTariffId(value) {
		this.actionSelectedTariffId = value;
	}
}

class SearchEvent {
	type = "";
	value = "";
	item = {};

	constructor(options) {
		this.value = options.value;
		this.item = options.item;

		if (options.type) {
			this.type = options.type;
			return;
		}

		if (!!options.confirm) {
			this.type = "onMadeSelect";
		} else if (options.item.route) {
			this.type = "onRouteSelect";
		} else if (options.item.address_id || options.item.id) {
			this.type = "onAddressSelect";
		} else if (options.item.street_id) {
			this.type = "onStreetSelect";
		} else if (options.item.district_id) {
			this.type = "onDistrictSelect";
		} else if (options.item.region_id) {
			this.type = "onRegionSelect";
		} else {
			this.type = "not_selected";
		}
	}
}
