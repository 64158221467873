import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			width: "100%",
			position: "relative",
		},
		wrapper: {
			position: "relative",
		},
		text: {
			width: "100%",
			fontFamily: theme.fonts.medium,
			fontSize: "16px",
			lineHeight: "1.15",
			color: theme.palette.colors.coralPink,
			transform: "scaleY(0)",
			transition: "opacity 1s ease, transform .2s ease",
			overflow: "hidden",
			opacity: 0,
			transformOrigin: "top left",
			paddingTop: 0,
		},
		isError: {
			paddingTop: "10px",
			transform: "scaleY(1)",
			opacity: 1,
		},
		isAbsolute: {
			position: "absolute",
			top: "10px",
			left: 0,
		},
		isAbsoluteNoTop: {
			position: "absolute",
			left: 0,
		},
		isSmall: {
			fontSize: "16px",
		},
		isVerySmall: {
			fontSize: "12px",
		},
		isCenter: {
			textAlign: "center",
		},
		isSearchAddress: {
			margin: "10px 0",
			padding: 0,
		},
		isReview: {
			margin: "0",
			marginTop: "15px",
		},
		hint: {
			height: "20px",
			marginBottom: "10px",
			fontFamily: theme.fonts.light,
			fontSize: "16px",
			color: theme.palette.colors.white,
		},
		otherColorHint: {
			color: theme.palette.colors.oneMoreGrey,
		},
		providerColorHint: {},
		[`@media (max-width: ${theme.media.md})`]: {
			isSmall: {
				fontSize: "14px",
			},
			hint: {
				marginLeft: "60px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			text: {
				fontSize: "14px",
			},
			hint: {
				marginLeft: 0,
			},
			providerColorHint: {
				color: theme.palette.colors.yellow,
			},
		},
	};
};

class AlertWrapper extends React.Component {
	static defaultProps = {
		error: false,
		absolute: false,
		small: false,
		center: false,
		datatest: null,
		searchAddress: true,
	};

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				{this.props.showHint && (
					<div
						className={classNames(classes.hint, {
							[classes.providerColorHint]: this.props.providerColorHint,
							[classes.otherColorHint]: this.props.otherColorHint,
						})}
					>
						{this.props.hint}
					</div>
				)}
				{this.props.children}
				<div className={classes.wrapper}>
					<div
						datatest={this.props.datatest}
						className={classNames(classes.text, {
							[classes.isError]: this.props.error,
							[classes.isAbsolute]: this.props.absolute,
							[classes.isAbsoluteNoTop]: this.props.absoluteNoTop,
							[classes.isSmall]: this.props.small,
							[classes.isVerySmall]: this.props.verySmall,
							[classes.isCenter]: this.props.center,
							[classes.isSearchAddress]: this.props.searchAddress && this.props.error,
							[classes.isReview]: this.props.isReview && this.props.error,
						})}
					>
						{this.props.error}
					</div>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(AlertWrapper);
