var gql = require("@apollo/client").gql;

const TARIFFS_MAIN_PAGE_WITHOUT_REGION_QUERY = gql`
	query tariffsMainPageWithoutRegion(
		$region_url: String
		$providerJoin: Boolean
		$applySample: Boolean
	) {
		tariffsMainPageWithoutRegion(
			region_url: $region_url
			providerJoin: $providerJoin
			applySample: $applySample
		) {
			data {
				id
				provider_id
				name
				displayPrice
				provider {
					id
					name
					logo
					logo_2
					url_name
					vip_provider
					reviews_info {
						count_total
					}
					rating {
						expert {
							final
						}
						order_types {
							position
							order_type_id
							review_all
							in_main_rating
						}
						rating_avg
					}
					info {
						cnt_reviews
					}
					contacts {
						phones
						link
						card
					}
					provider_settings {
						tariffs {
							value
							button_text
						}
					}
				}
				after {
					rule {
						code
						value
						month_cnt
						date_end
					}
					change {
						code
						name
						id
					}
					data {
						price {
							license_fee
							license_fee_month
							license_fee_interval {
								code
								name
								name_short
							}
						}
						internet {
							type {
								id
								name
							}
							speed_in
							include_trafic_metrics {
								id
								name
							}
							include_trafic_interval {
								id
								name
							}
							include_trafic_value
							excess_speed_in
							excess_speed_out
						}
					}
				}
				price {
					license_fee
					license_fee_month
					system_calculation_connect
					license_fee_interval {
						code
						name
						name_short
					}
					system_calculation {
						id
						name
					}
					system_cancel_type {
						id
						name
					}
				}
				technologies {
					id
					name
				}
				internet {
					icon
					type {
						id
						name
					}
					speed_in
					include_trafic_metrics {
						id
						name
					}
					include_trafic_interval {
						id
						name
					}
					include_trafic_value
					excess_speed_in
					excess_speed_out
				}
				tv {
					channels
					channels_hd
					use_text
				}
				dody {
					image_24px
					image_48px
					name
					short_description
					full_description
					comment_tariff
					state
				}
				connection {
					price
					time
				}
				region {
					id
					name
					url
				}
				exclusive {
					priority
				}
				is_exclusive
				mobile_new {
					internet
					calls
					sms
					internet_type
					calls_type
					sms_type
				}
				order_exclusive
			}
		}
	}
`;

export default TARIFFS_MAIN_PAGE_WITHOUT_REGION_QUERY;
