import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import compose from "recompose/compose";
import { graphql } from "@apollo/client/react/hoc";
import { TOP_PROVIDERS_QUERY, DECLINATION_QUERY } from "Services/queries";
import { generateUrl } from "Services/withDefaultParams";
import withDefaultParams from "Services/withDefaultParams";
import images from "Configs/images.config";
import Routes from "Configs/routes";
import CarouselContainer from "Containers/CarouselContainer";
import ComponentBackground from "ComponentsUI/ComponentBackground";
import TopProviderBlock from "ComponentsUI/TopProviderBlock";
import TopProviders from "ComponentsUI/TopProviders";

@inject("ApplicationModel")
@observer
class TopProviderContainer extends Component {
	constructor(props) {
		super(props);
	}

	generateProviderRatesUrl(data) {
		if (data) {
			let providerRatesUrl = generateUrl(Routes.ProviderRates.route, {
				providerName: data.url_name,
				regionUrl: data.region.url,
			});
			return providerRatesUrl;
		}
		return "/";
	}

	render() {
		let {
			ApplicationModel: {
				windowSizeModel: { windowSizes },
				text,
			},
			loading,
			region,
			topProviders,
			onlyBig,
			onlySmall,
			backgroundNone,
			seoBlock,
			route,
		} = this.props;

		const linkComponent = <Link to={"/"} />;

		// Ссылки на изображения для бекграунда блока
		let bjImages = {
			lg: images.benefitLg,
			md: images.benefitLg,
			sm: images.benefitLg,
			xs: images.benefitXs,
		};

		if (loading || loading == undefined || !topProviders || topProviders.data.length === 0) {
			return null;
		}

		// Сделать из каждого элемента с данными div нужного вида для вставки в карусель.
		let list = topProviders.data.slice(0, topProviders.data.length).map((elem, index) => {
			return (
				<TopProviderBlock
					key={index}
					provider={elem}
					linkComponent={linkComponent}
					linkGeneratorRates={(data) => this.generateProviderRatesUrl(data)}
					backgroundNone={backgroundNone}
				/>
			);
		});

		return (
			<>
				{!onlyBig && (
					<TopProviders
						seoBlock={seoBlock}
						title={text.Top_Providers}
						declinations={region.declinations.prepositional}
						data={topProviders.data}
						linkComponent={linkComponent}
						linkGeneratorRates={(data) => this.generateProviderRatesUrl(data)}
					/>
				)}
				{!onlySmall && (
					<ComponentBackground
						images={bjImages}
						windowSizes={windowSizes}
						backgroundNone={backgroundNone}
					>
						<CarouselContainer
							seoBlock={seoBlock}
							title={text.Top_Providers}
							declinations={region.declinations.prepositional}
							list={list}
							remake={true}
							count={3}
							windowSizes={windowSizes}
							infinity={true}
							backgroundNone={backgroundNone}
						/>
					</ComponentBackground>
				)}
			</>
		);
	}
}

export default withRouter(
	withDefaultParams(
		compose(
			graphql(TOP_PROVIDERS_QUERY, {
				skip: (ownProps) => {
					return !ownProps.match.params.regionUrl;
				},
				options: (props) => {
					let {
						match: {
							params: { regionUrl },
						},
					} = props;
					return {
						variables: {
							regionUrl,
						},
					};
				},
				props: ({ data }) => {
					return { topProviders: data.topProviders };
				},
			}),
			graphql(DECLINATION_QUERY, {
				skip: (ownProps) => {
					return ownProps.match.params.regionUrl ? false : true;
				},
				options: (props) => {
					let {
						match: {
							params: { regionUrl },
						},
					} = props;
					return {
						variables: {
							filter: `url=${regionUrl}`,
						},
					};
				},
				props: ({ data }) => {
					if (data.region) {
						return data;
					}
					return null;
				},
			})
		)(TopProviderContainer)
	)
);
