import React from "react";
import loadable from "@loadable/component";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { pipe } from "Utils/functionalUtils";

const MainLayoutV2 = (props) => {
	const { ApplicationModel, component: Component } = props;

	return <Component />;
};

export default pipe(observer, inject("ApplicationModel"), withRouter)(MainLayoutV2);
