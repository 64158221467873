import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import Container from "reactstrap/lib/Container";
import { useQuery } from "@apollo/client";
import { TARIFFS_MAIN_PAGE_WITHOUT_REGION_QUERY } from "Services/queries";
import { generateUrl } from "Services/withDefaultParams";
import { useWithDefaultParams } from "Services/useWithDefaultParams";
import PlansBTableContainer from "Containers/PlansBTableContainer";
import configRouters from "Configs/routes";
import Block from "ComponentsUI/Block";
import Button from "ComponentsUI/Button";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";

const TariffsContainer = (props) => {
	let {
		ApplicationModel: { windowSizeModel, text },
		isMainPage,
		seoBlock,
	} = props;
	let match = useWithDefaultParams();
	let regionUrl = match.params.regionUrl;
	let linkViewTariffs = generateUrl(configRouters.Rates.route, {
		regionUrl: regionUrl,
	});

	let mainTariifsQuery = useQuery(TARIFFS_MAIN_PAGE_WITHOUT_REGION_QUERY, {
		variables: {
			region_url: regionUrl,
			providerJoin: true,
			applySample: Boolean(regionUrl),
		},
		notifyOnNetworkStatusChange: true,
	});

	let mainTariifs = mainTariifsQuery?.data?.tariffsMainPageWithoutRegion?.data;

	useEffect(() => {
		if (windowSizeModel.windowSizes.xs && mainTariifs?.length > 4) {
			mainTariifs = mainTariifs.slice(1, 4);
		}
	}, [mainTariifsQuery?.loading, windowSizeModel.windowSizes.xs]);

	if (!mainTariifs || !mainTariifs.length) {
		return null;
	}

	let tariffsForView = mainTariifs;
	let countOfPlans = windowSizeModel.windowSizes.lg ? 6 : 4;

	return (
		<>
			<Container>
				<HeaderTextSeo
					header={seoBlock?.seoHeader || text.exlusiveTariffsTitle}
					text={seoBlock?.seoText}
				/>
			</Container>
			<Block
				header=" "
				hideUnderLine={true}
				btn={
					<Button
						typeText="big"
						component={<Link to={!!linkViewTariffs ? linkViewTariffs : "/"} />}
						size={"middle"}
						transparent={true}
						status={"tertiaryAccent"}
						datatest="providers_provider_alltariff_button"
						fullWidth={true}
					>
						{text.tariffsShowMore}
					</Button>
				}
			>
				<Container>
					<PlansBTableContainer
						tariffs={tariffsForView.slice(0, countOfPlans)}
						datatest="main_tariff_list"
						isMainPage={isMainPage}
						pageName="main"
					/>
				</Container>
			</Block>
		</>
	);
};
export default withRouter(inject("ApplicationModel")(observer(TariffsContainer)));
