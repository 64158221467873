import React from "react";
import { observer, inject } from "mobx-react";

import { withRouter } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { ORDER_COUNTER_QUERY } from "Services/queries";
import { CounterBlock } from "ComponentsUI/InfoBlocks";

@inject("ApplicationModel")
@observer
class CounterContainer extends React.Component {
	static defaultProps = {
		site: {},
	};

	render() {
		let textConfig = this.props.ApplicationModel.text;

		let orderCounter = 0;
		if (this.props.data.siteConfig) {
			orderCounter = this.props.data.siteConfig.order_counter;
		}

		let orderCounterDecimal = 0;

		if (orderCounter) {
			orderCounterDecimal = `${orderCounter}`.replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ");
		}

		let textForCounter = `${
			textConfig.heroTextNumberPeople
		} ${new Date().getFullYear() - 2009} ${textConfig.YEARS}`;

		return <CounterBlock count={orderCounterDecimal} text={textForCounter} />;
	}
}

export default withRouter(
	graphql(ORDER_COUNTER_QUERY, {
		options: {
			pollInterval: 10000,
		},
	})(CounterContainer)
);
