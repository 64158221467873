import React, { useEffect } from "react";
import withStyles from "react-jss";
import { Link } from "react-router-dom";
import { sendMetriks } from "Services/sendMetriks";

const styles = (theme) => {
	return {
		root: {
			padding: "29px 30px 0",
			width: "auto",
			minWidth: "330px",
			height: "auto",
			borderRadius: "10px",
			backgroundColor: theme.palette.colors.white,
			boxShadow: "0 2px 20px 0 rgba(44, 29, 93, 0.3)",
			position: "absolute",
			left: "30%",
			top: "-30px",
			transform: "translateY(100%)",
			zIndex: 10000,
			"&:before": {
				content: '""',
				position: "absolute",
				width: "15px",
				height: "15px",
				transform: "rotate(45deg)",
				top: "-8px",
				left: "50%",
				backgroundColor: theme.palette.colors.white,
				zIndex: 9000,
			},
		},
		title: {
			fontFamily: theme.fonts.light,
			fontSize: "14px",
			lineHeight: 1.14,
			color: theme.palette.colors.charcoalGre,
			width: "100%",
			marginBottom: "15px",
			textAlign: "center",

			"& strong": {
				fontFamily: theme.fonts.bold,
			},
		},
		buttonWrapper: {
			display: "flex",
			width: "auto",
			justifyContent: "space-between",
			marginBottom: "15px",
		},
		button: {
			width: "80px",
			height: "40px",
			borderRadius: "10px",
			border: `solid 1px ${theme.palette.colors.purplishBlue}`,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontFamily: theme.fonts.bold,
			fontSize: "14px",
			lineHeight: "1.29",
			letterSpacing: "1.6px",
			backgroundColor: theme.palette.colors.purplishBlue,
			color: theme.palette.colors.white,
			textTransform: "uppercase",
			cursor: "pointer",
		},
		buttonWite: {
			width: "auto",
			height: "40px",
			padding: "0 14px 0 14px",
			borderRadius: "10px",
			border: `solid 1px ${theme.palette.colors.purplishBlue}`,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontFamily: theme.fonts.bold,
			fontSize: "14px",
			lineHeight: "1.29",
			letterSpacing: "1.6px",
			backgroundColor: theme.palette.colors.white,
			color: theme.palette.colors.purplishBlue,
			textTransform: "uppercase",
			cursor: "pointer",
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			root: {
				left: "20%",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				left: "10%",
				top: "-5px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				left: "50%",
				transform: "translate(-50%,0)",
				top: "70px",
				minWidth: "295px",
				padding: "29px 23px 0",
				"&:before": {
					display: "none",
				},
			},
			button: {
				fontSize: "13px",
			},
			buttonWite: {
				fontSize: "13px",
			},
		},
	};
};

function DetectRegion(props) {
	let classes = props.classes;
	let text = props.text;

	useEffect(() => {
		sendMetriks("OPEN_YOU_REGION");
	}, [props.pathname]);

	return (
		<div className={classes.root}>
			<div className={classes.title}>
				{text.YOUR_REGION_IS} <strong>{props.name}?</strong>
			</div>
			<div className={classes.buttonWrapper}>
				<div className={classes.button} onClick={() => props.setRegion()}>
					{text.YES}
				</div>

				<Link to={props.linkSelectRegion}>
					<div className={classes.buttonWite} onClick={props.changeRegion}>
						{"ВЫБРАТЬ ДРУГОЙ"}
					</div>
				</Link>
			</div>
		</div>
	);
}

export default withStyles(styles)(DetectRegion);
