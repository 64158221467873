import React from "react";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			animation: "2s fromFade",
		},
	};
};

class FromFade extends React.PureComponent {
	render() {
		let classes = this.props.classes;
		return <div className={classes.root}>{this.props.children}</div>;
	}
}
export default withStyles(styles)(FromFade);
