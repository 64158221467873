import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	root: {
		width: "100%",
		border: "none",
		marginRight: "auto",
		position: "relative",
	},
	placeholder: {
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "1",
		color: theme.palette.colors.grayishBlue,
		paddingLeft: "22px",
		position: "absolute",
		top: "50%",
		transform: "translate(0, -50%)",
		left: "0",
		cursor: "inherit",
		transition: "opacity 1s ease, transform .2s ease",
	},
	greyPlaceholder: {
		color: theme.palette.colors.oneMoreGrey,
	},
	smallPlaceholder: {},
	isPaddingRight: {
		paddingRight: "40px",
	},
	wrapper: {
		width: "100%",
		border: "none",
		marginRight: "auto",
		position: "relative",
	},
	hiddenPlaceholder: {
		display: "none",
		transition: "none",
	},

	input: {
		fontFamily: theme.fonts.medium,
		fontSize: "16px",
		lineHeight: "1",
		color: theme.palette.colors.veryDarkGray,
		width: "100%",
		border: `solid 1px ${theme.palette.colors.lightGrayishViolet}`,
		borderRadius: "10px",
		paddingLeft: "22px",
		"background-color": theme.palette.colors.white,
	},
	withOpacity: {
		"background-color": "rgba(255, 255, 255, 0.8)",
	},
	whiteTextField: {
		backgroundColor: "#ffffff",
	},
	inputSmaller: {
		height: "40px",
	},
	inputSmall: {
		height: "50px",
	},
	inputMedium: {
		height: "50px",
	},
	inputBig: {
		height: "60px",
	},
	marginBottom: {
		marginBottom: "20px",
	},
	noMargin: {
		marginBottom: 0,
	},
	always50: {
		height: "50px",
	},
	isError: {
		border: "1px solid #ff586c",
	},
	error: {
		width: "100%",
		fontFamily: theme.fonts.medium,
		fontSize: "18px",
		lineHeight: "1.15",
		color: theme.palette.colors.coralPink,
		transform: "scaleY(0)",
		transition: "opacity 1s ease, transform .2s ease",
		overflow: "hidden",
		opacity: "0",
		transformOrigin: "top left",
		paddingTop: "0",
	},
	errorShow: {
		paddingTop: "10px",
		fontSize: "16px",
		transform: "scaleY(1)",
		opacity: "1",
	},
	errorPhone: {
		border: `1px solid ${theme.palette.colors.coralPink} !important`,
		animation: "blink .7s ease 2",
	},
	centerText: {
		textAlign: "center",
	},
	label: {
		fontFamily: theme.fonts.bold,
		fontSize: "16px",
		lineHeight: "1",
		letterSpacing: "2.3px",
		color: theme.palette.colors.veryDarkGray,
		marginBottom: "17px",
		textTransform: "uppercase",
	},
	labelStub: {
		height: "16px",
		borderRadius: "10px",
		backgroundColor: theme.palette.colors.disabled,
		width: "150px",
	},
	labelWhiteSpace: {
		whiteSpace: "nowrap",
	},
	labelBlue: {
		color: theme.palette.colors.darkSlateBlue,
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		error: {
			fontSize: "14px",
		},
		inputSmall: {
			height: "50px",
		},
		inputMedium: {
			height: "50px",
		},
		inputBig: {
			height: "50px",
		},
		overflowShow: {
			width: "170%",
		},
		label: {
			fontSize: "14px",
			lineHeight: "1.14",
			letterSpacing: "2px",
			marginBottom: "12px",
		},
		noMargin: {
			marginBottom: 0,
		},
		placeholder: {
			top: "51% !important",
		},
		smallPlaceholder: {
			fontSize: "14px",
		},
	},
	"@media (max-width: 495px)": {
		inputSmall: {
			height: "50px",
		},
		overflowShow: {
			width: "100%",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			"& > input": {
				height: "50px",
			},
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		overflowShow: {
			width: "170%",
		},
	},
}));

export default useStyles;
