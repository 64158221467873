import React from "react";
import * as classNames from "classnames";
import withStyles from "react-jss";
import { PlaceholderInput } from "ComponentsUI/Placeholderinput";
import DropdownList from "../DropdownList";
import { MatrixWalker } from "Services/MatrixWalker";
import Metrika from "Services/Metrika";
import { YANDEX_TARGETS } from "Constants/metriks/yandex-targets";

const arrowButton = {
	top: 38,
	bottom: 40,
};

const styles = (theme) => ({
	root: {
		width: "100%",
	},
	error: {
		"& $input": {
			border: `1px solid ${theme.palette.colors.coralPink} !important`,
			animation: "blink .7s ease 2",
		},
	},
	empty: {
		"& $inputIsOpen": {
			borderRadius: "8px !important",
			borderBottomColor: theme.palette.colors.grey,
		},
		"& $list": {
			display: "none",
		},
	},
	wrapper: {
		position: "relative",
		zIndex: "12",
		width: "100%",
	},
	portal: {},
	expandWrapper: {
		position: "relative",
		left: 0,
		top: 0,
		width: "100%",
	},
	expandWrapperFocus: {
		position: "absolute",
		left: 0,
		width: "100%",
		top: 0,
		zIndex: 100,
	},
	wrapperInModal: {
		"& $input": {
			border: `1px solid ${theme.palette.colors.grey}`,
			backgroundColor: theme.palette.colors.white,
			color: theme.palette.colors.headerColour,
		},
		"& $inputIsOpen": {
			//borderBottomColor: theme.palette.colors.transparent
		},
		"& $list": {
			border: `1px solid ${theme.palette.colors.grey}`,
			borderTop: "none",
		},
		"& $placeholder": {
			color: theme.palette.colors.lightGrayishViolet,
		},
		"& $boxShadow": {
			boxShadow: "none",
		},
		"& $listIsOpen": {
			boxShadow: "none",
		},
	},
	whiteTheme: {
		"& $input": {
			backgroundColor: `${theme.palette.colors.white}`,
		},
		"& $placeholder": {
			color: `${theme.palette.colors.grayishBlue} !important`,
		},
	},
	close: {
		color: theme.palette.colors.cloudyBlue,
		fontSize: "16px",
		cursor: "pointer",
		opacity: 0,
		visibility: "hidden",
	},

	directionUp: {
		"& $list": {
			transform: "translateY(-100%)",
			top: 0,
			borderTop: `1px solid ${theme.palette.colors.grey}`,
			borderBottom: "none",
			borderRadius: "8px 8px 0 0",
		},
		"& $inputIsOpen": {
			border: `1px solid ${theme.palette.colors.oneMoreGrey}`,
			borderBottomColor: `${theme.palette.colors.oneMoreGrey} !important`,
			borderTopColor: theme.palette.colors.transparent,
			borderRadius: "0 0 8px 8px",
		},
	},
	isFixedList: {
		position: "fixed !important",
	},
	iconContainer: {
		position: "absolute",
		top: "50%",
		left: "24px",
		transform: "rotate(0deg) translateY(-47%)",
		zIndex: 5,
		lineHeight: 0,
	},
	loading: {
		color: theme.palette.colors.purplishBlue,
		lineHeight: 0,
		fontSize: "20px",
		transform: "rotate(0deg)",
		animation: "rotate360 1s linear infinite",
		transformOrigin: "50%",
		display: "block",
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		border: `1px ${theme.palette.colors.purplishBlue} solid`,
		borderRight: "none",
		padding: 0,
		left: "-5px !important",
	},
	closeIsShow: {
		transition: "opacity .3s .3s ease",
		padding: "10px",
		opacity: 1,
		visibility: "visible",
		display: "block",
		position: "relative",
		left: "-10px",
	},
	active: {},
	wrapperInTender: {
		"& $input": {
			height: "50px",
			paddingLeft: "16px",
			paddingRight: "65px",
			backgroundColor: theme.palette.colors.paleGrey,
			border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
			fontSize: "16px",
			fontFamily: theme.fonts.medium,
			textOverflow: "ellipsis",

			"& strong": {
				fontFamily: theme.fonts.medium,
			},
			"&:focus": {
				backgroundColor: theme.palette.colors.white,
				border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
			},
		},

		"& $inputIsNotEmpty": {
			backgroundColor: theme.palette.colors.white,
			border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
			paddingLeft: "56px",
		},

		"& $inputIsOpen": {
			border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
		},

		"& $placeholder": {
			paddingLeft: "20px",
		},

		"& $list": {
			top: "50px",
		},

		"& $listItem": {
			paddingLeft: "16px",
			paddingRight: "16px",
			fontSize: "16px",
			fontFamily: theme.fonts.light,
		},
		"& $listItemError": {
			textAlign: "center",
			color: theme.palette.colors.lightCoral,
		},

		"& $listIsOpen": {
			boxShadow: "none",
			zIndex: 105,
		},
		"& $boxShadow": {
			display: "none",
		},
		"&$active": {
			"& $input, & $inputIsNotEmpty, & $inputIsOpen": {
				border: `1px solid ${theme.palette.colors.purplishBlue}`,
				color: theme.palette.colors.purplishBlue,
			},
			"& $close": {
				color: theme.palette.colors.purplishBlue,
			},
			"& $inputIsNotEmpty": {
				backgroundColor: theme.palette.colors.paleGrey,
			},
		},
	},
	wrapperInAdBlock: {
		"& $input": {
			height: "50px",
			border: "none",
			fontSize: "16px",
			color: theme.palette.colors.grayishBlue,
			backgroundColor: theme.palette.colors.white,
			"&:focus": {
				backgroundColor: theme.palette.colors.white,
				border: "none",
			},
			"&:focus+$placeholder": {
				color: theme.palette.colors.grayishBlue,
			},
		},
		"& $inputIsNotEmpty": {
			backgroundColor: theme.palette.colors.white,
			border: "none",
		},
		"& $inputIsOpen": {
			backgroundColor: theme.palette.colors.white,
			border: "none",
		},
		"& $list": {
			top: "40px",
			backgroundColor: "rgba(255, 255, 255,1)",
			maxHeight: "350px",
			overflowY: "auto",
			borderRadius: "0 0 8px 8px",
			zIndex: "10",
			border: "none",
			borderTop: "none",
		},
		"& $listIsOpen": {
			boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.1)",
			zIndex: "105",
		},
		"& $placeholder": {
			paddingLeft: "22px",
			color: theme.palette.colors.grayishBlue,
		},
		"& $boxShadow": {
			display: "none",
		},
	},
	input: {
		width: "100%",
		height: "60px",
		borderRadius: "8px",
		border: "1px solid transparent",
		fontFamily: theme.fonts.light,
		fontSize: "18px",
		lineHeight: "1.5",
		color: theme.palette.colors.headerColour,
		paddingLeft: "22px",
		backgroundColor: "rgba(255, 255, 255, 0.8)",
		"-webkit-appearance": "none",
		zIndex: "5",
		position: "relative",
		textOverflow: "ellipsis",
		"&:focus": {
			backgroundColor: theme.palette.colors.white,
			border: `1px solid ${theme.palette.colors.grey}`,
		},
		"&:focus+$placeholder": {
			color: theme.palette.colors.lightGrayishViolet,
		},
	},
	whiteInput: {
		backgroundColor: "#ffffff",
	},
	withBigButton: {
		paddingRight: "270px",
	},
	withSmallButton: {
		paddingRight: "95px !important",
	},
	inputSmall: {
		height: "40px",
	},
	inputMedium: {
		height: "50px",
	},
	inputBig: {
		height: "60px",
	},
	withoutBorder: {
		borderTop: "1px solid transparent !important",
		borderRight: "1px solid transparent !important",
		borderLeft: "1px solid transparent !important",
		borderBottom: "1px solid transparent",
	},
	inputIsOpen: {
		borderRadius: "8px 8px 0 0",
		backgroundColor: theme.palette.colors.white,
		border: `1px solid ${theme.palette.colors.grey}`,
		//borderBottomColor: theme.palette.colors.transparent + " !important"
	},
	inputIsNotEmpty: {
		backgroundColor: theme.palette.colors.white,
		border: `1px solid ${theme.palette.colors.grey}`,
		paddingLeft: "56px",
		transition: "padding .3s ease-out",
	},
	list: {
		display: "none",
		listStyle: "none",
		margin: 0,
		padding: 0,
		position: "absolute",
		left: 0,
		right: 0,
		top: "60px",
		backgroundColor: "rgba(255, 255, 255,1)",
		maxHeight: "350px",
		overflowY: "auto",
		borderRadius: "0 0 8px 8px",
		zIndex: 10,
		border: `1px solid ${theme.palette.colors.grey}`,
		borderTop: "none",
		"&::-webkit-scrollbar": {
			"-webkit-appearance": "none",
			width: "7px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: "4px",
			backgroundColor: "rgba(0,0,0,.5)",
			"-webkit-box-shadow": "0 0 1px rgba(255,255,255,.5)",
		},
	},
	listIsOpen: {
		display: "block",
		boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.1)",
		zIndex: 105,
	},
	listItem: {
		padding: "15px 23px",
		cursor: "pointer",
		fontFamily: theme.fonts.medium,
		fontSize: "18px",
		lineHeight: "1.29",
		color: theme.palette.colors.dropdownListColor,
		minHeight: "50px",
		"& strong, & em": {
			color: theme.palette.colors.darkSlateBlue,
			fontStyle: "normal",
		},
		"&:hover": {
			backgroundColor: theme.palette.colors.paleGrey,
		},
	},
	listItemError: {
		textAlign: "center",
		color: theme.palette.colors.lightCoral,
	},
	listItemIsSelected: {
		backgroundColor: theme.palette.colors.paleGrey,
	},
	boxShadow: {
		position: "absolute",
		zIndex: "0",
		top: 0,
		left: 0,
		width: "100%",
		bottom: 0,
		boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
	},
	placeholder: {
		fontFamily: theme.fonts.light,
		zIndex: "6",
		paddingLeft: "26px",
		color: theme.palette.colors.oneMoreGrey,
	},
	withBorder: {
		"& $input": {
			border: `1px solid ${theme.palette.colors.cloudyBlue}`,
		},
	},
	withoutShadow: {
		"& $boxShadow": {
			display: "none",
		},
		"& $listIsOpen": {
			boxShadow: "none",
		},
	},
	withoutClear: {
		"& $input": {
			paddingLeft: "22px !important",
		},
	},
	withoutButton: {
		paddingRight: "10px !important",
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		input: {
			height: "60px",
			fontSize: "16px",
		},
		withBigButton: {
			paddingRight: "250px",
		},
		placeholder: {
			paddingLeft: "23px",
		},
		list: {
			top: "60px",
		},
		inputSmall: {
			height: "40px",
		},
		inputMedium: {
			height: "50px",
		},
		inputBig: {
			height: "60px",
		},
		inputIsOpen: {},
		directionUp: {
			"& $list": {
				transform: "translateY(-100%)",
				top: 0,
				borderTop: `1px solid ${theme.palette.colors.grey}`,
				borderBottom: "none",
				borderRadius: "8px 8px 0 0",
			},
			"& $inputIsOpen": {
				border: `1px solid ${theme.palette.colors.oneMoreGrey}`,
				borderBottomColor: `${theme.palette.colors.oneMoreGrey} !important`,
				borderTopColor: theme.palette.colors.transparent,
				borderRadius: "0 0 8px 8px",
			},
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		inputSmall: {
			height: "40px",
		},
		inputMedium: {
			height: "50px",
		},
		inputBig: {
			height: "60px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		input: {
			height: "60px",
			fontSize: "16px",
			paddingLeft: "15px",
		},
		iconContainer: {
			left: "16px",
		},
		inputIsNotEmpty: {
			paddingLeft: "48px !important",
		},
		withBigButton: {
			paddingRight: "50px",
		},
		withSmallButton: {
			paddingRight: "50px !important",
		},
		inputSmall: {
			height: "50px",
		},
		inputMedium: {
			height: "50px",
		},
		inputBig: {
			height: "50px",
		},
		placeholder: {
			top: "52%",
			paddingLeft: "16px !important",
		},
		list: {
			top: "50px",
			maxHeight: "260px",
		},
		listItem: {
			fontSize: "16px",
			padding: "5px 15px",
			lineHeight: "1.14",
			minHeight: "30px",
		},
		listItemError: {
			textAlign: "center",
			color: theme.palette.colors.lightCoral,
		},
		wrapperInTender: {
			"& $input": {
				height: "50px",
			},
			"& $list": {
				top: "50px",
			},
		},
		inputIsOpen: {},
		directionUp: {
			"& $list": {
				transform: "translateY(-100%)",
				top: 0,
				borderTop: `1px solid ${theme.palette.colors.grey}`,
				borderBottom: "none",
				borderRadius: "8px 8px 0 0",
			},
			"& $inputIsOpen": {
				border: `1px solid ${theme.palette.colors.oneMoreGrey}`,
				borderBottomColor: `${theme.palette.colors.oneMoreGrey} !important`,
				borderTopColor: theme.palette.colors.transparent,
				borderRadius: "0 0 8px 8px",
			},
		},
	},
});

class Dropdown extends React.Component {
	static defaultProps = {
		datatest: "",
		disabled: false,
		onBlur: () => {},
		addTextFunc: () => "",
		onEnter: null,
		onItemSelect: null,
		highlightPart: "",
		onInput: null,
		size: "big",
		currentElementPos: 1,
		items: [],
		classes: {},
		errorCounter: 0,
		error: null,
		inModal: false,
		inAdBlock: false,
		tender: false,
		placeholder: "",
		value: "",
		id: undefined,
		showList: false,
		clickInputHandler: null,
		isFixedList: false,
		minLenValue: 3,
	};
	dropdown = null;
	dropdownWrapper = null;
	inputField = null;
	needScroll = false;
	dropdownList = false;
	portal = null;

	constructor(props) {
		super(props);
		this.state = {
			currentElementPos: this.props.currentElementPos ? this.props.currentElementPos : 1,
			items: this.props.items,
			direction: "down",
			isClear: true,
		};
	}

	keyDown(e) {
		if (e.keyCode === 13) {
			if (this.props.correctAddress) {
				!!this.props.onEnter && this.props.onEnter();
			} else {
				this.selectItem(e, this.props.items[this.state.currentElementPos - 1]);
			}
		}

		if (!this.props.items.length) return;

		let current;
		let oldCurrent = this.state.currentElementPos;
		for (let i in arrowButton) {
			if (arrowButton[i] === e.keyCode) {
				switch (i) {
					case "top":
						current = MatrixWalker.create(this.props.items.length, 1, oldCurrent)
							.top()
							.getCurrent();
						break;
					case "bottom":
						current = MatrixWalker.create(this.props.items.length, 1, oldCurrent)
							.bottom()
							.getCurrent();
						break;
				}
				this.setState({
					currentElementPos: current,
				});
				this.needScroll = true;
			}
		}
	}

	setDirection(value) {
		this.setState({
			direction: value,
		});
	}

	changeInputValue(event) {
		this.props.onInput(event.target.value);
		if (this.props.isStreetDropdownToTC === true) {
			if (this.state.isClear) {
				this.setState({
					isClear: false,
				});
				Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.ENTERING_ANY_SYMBOLS, []);
			} else if (!event.target.value.length) {
				this.setState({
					isClear: true,
				});
			}
		}
	}

	selectItem(e, item) {
		e.preventDefault();
		this.props.onItemSelect(item);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.items !== props.items) {
			return { ...state, currentElementPos: 1, items: props.items };
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState) {
		// if (
		//     this.props.items.length !== prevProps.items.length &&
		//     this.props.items.length > 0
		// )
		//     this.inputField.focus();
		if (this.props.errorCounter && this.props.error) {
			if (this.props.errorCounter !== prevProps.errorCounter) {
				this.dropdown.classList.remove(this.props.classes.error);
				setTimeout(() => {
					this.dropdown.classList.add(this.props.classes.error);
				}, 0);
			}
		}

		if (this.needScroll) {
			this.scrollList();
			this.needScroll = false;
		}
	}

	scrollList() {
		let classes = this.props.classes;
		let list = this.dropdownList;
		if (!list) {
			return;
		}
		let listHeight = list.offsetHeight;
		let selectedOption = list.querySelector(`.${classes.listItemIsSelected}`);
		if (!selectedOption) {
			return;
		}
		let currentScrollList = list.scrollTop;
		let selectedOptionHeight = selectedOption.offsetHeight;
		let selectedOptionTop = selectedOption.offsetTop;
		if (selectedOptionTop + selectedOptionHeight - currentScrollList > listHeight) {
			list.scrollTop = selectedOptionTop + selectedOptionHeight - listHeight;
		}
		if (selectedOptionTop < currentScrollList) {
			list.scrollTop = selectedOptionTop;
		}
	}

	onFocus() {
		this.setState({
			showList: true,
		});
		// document.addEventListener("scroll", this.bindedScrollElement)
		this.props.onDropdownOpenCallback && this.props.onDropdownOpenCallback();
	}

	onBlur() {
		this.setState({
			showList: false,
		});
		// document.removeEventListener("scroll", this.bindedScrollElement)
		!!this.props.onBlur && this.props.onBlur();
		this.props.onDropdownCloseCallback && this.props.onDropdownCloseCallback();
	}

	clearSelect() {
		this.props.onInput("");
		this.setState({
			isClear: true,
		});
	}

	clickInputHandler() {
		this.props.clickInputHandler && this.props.clickInputHandler();
	}

	// scrollElement(e) {
	//     if (this.portal.getBoundingClientRect().top < 70) {
	//         this.onBlur();
	//         document.activeElement.blur()
	//     }
	// }
	// bindedScrollElement = e => this.scrollElement(e)

	render() {
		let classes = this.props.classes;
		let correctAddress = this.props.correctAddress;
		let rootClassName = classNames(classes.root, {
			[classes.error]: this.props.error,
			[classes.empty]:
				(this.props.items.length === 0 && this.props.value.length < this.props.minLenValue) ||
				this.props.loading,
			[classes.withBorder]: this.props.withBorder,
			[classes.withoutShadow]: this.props.withoutShadow,
		});
		let wrapperClassName = classNames(classes.wrapper, {
			[classes.wrapperInModal]: this.props.inModal,
			[classes.wrapperInAdBlock]: this.props.inAdBlock,
			[classes.wrapperInTender]: this.props.tender,
			[classes.whiteTheme]: this.props.whiteTheme,
			[classes.active]: correctAddress,
			[classes.directionUp]: this.state.direction === "up",
			[classes.withoutClear]: this.props.withoutClear,
		});

		return (
			<div
				className={rootClassName}
				ref={(dropdown) => {
					this.dropdown = dropdown;
				}}
			>
				<div
					className={classNames(classes.expandWrapper, {
						[classes.expandWrapperFocus]: !!this.props.autoExpansion && this.state.showList,
					})}
				>
					<div
						className={wrapperClassName}
						ref={(dropdown) => {
							this.dropdownWrapper = dropdown;
						}}
					>
						<PlaceholderInput
							placeholder={this.props.placeholder}
							value={this.props.value}
							nativeHandlers={true}
							classNamePlaceholder={classes.placeholder}
							clickInputHandler={() => this.clickInputHandler()}
							childrensInputIdAria="label"
						>
							{/*
                            // @ts-ignore */}
							<input
								onKeyDown={(e) => this.keyDown(e)}
								maxLength={500}
								aria-labelledby="label"
								className={classNames([classes.input], {
									[classes.inputIsOpen]:
										this.props.showList && this.state.showList && !correctAddress,
									[classes.inputIsNotEmpty]: this.props.value,
									[classes.withoutBorder]: this.props.withoutBorder,
									[classes.inputSmall]: this.props.size === "small",
									[classes.inputMedium]: this.props.size === "medium",
									[classes.inputBig]: this.props.size === "big",
									[classes.withBigButton]: this.props.withBigButton,
									[classes.withSmallButton]: this.props.smallButton,
									[classes.withoutButton]: this.props.withoutButton,
									[classes.whiteInput]: this.props.whiteInput,
								})}
								onClick={() => this.clickInputHandler()}
								type="text"
								id={this.props.id}
								onBlur={() => this.onBlur()}
								onFocus={() => this.onFocus()}
								autoComplete="off"
								onChange={(e) => this.changeInputValue(e)}
								value={this.props.value}
								disabled={this.props.disabled}
								ref={(input) => {
									this.inputField = input;
									!!this.props.setRef && this.props.setRef(input);
								}}
								datatest={this.props.datatest}
							/>
						</PlaceholderInput>
						{!this.props.withoutClear && (
							<div className={classes.iconContainer}>
								<span
									className={classNames(classes.close, "icon24", {
										[classes.closeIsShow]: !!this.props.value,
										"icon-close": !this.props.loading,
										[classes.loading]: this.props.loading,
									})}
									onClick={() => this.clearSelect()}
								/>
							</div>
						)}
						<ul
							className={classNames(classes.list, {
								[classes.listIsOpen]: this.props.showList && this.state.showList && !correctAddress,
							})}
						>
							{!!this.props.showList &&
								this.state.showList &&
								!correctAddress &&
								!this.props.loading && (
									<DropdownList
										textField={this.props.textField}
										text={this.props.text}
										classes={this.props.classes}
										items={this.props.items}
										listIsOpen={this.props.showList && this.state.showList && !correctAddress}
										value={this.props.value}
										target={this.dropdownWrapper}
										refList={(dropdownList) => {
											this.dropdownList = dropdownList;
										}}
										correctAddress={correctAddress}
										minLenValue={this.props.minLenValue}
										currentElementPos={this.state.currentElementPos}
										currentAddress={this.props.currentAddress}
										selectItem={(e, item) => this.selectItem(e, item)}
										rootClassName={rootClassName}
										wrapperClassName={wrapperClassName}
										isFixedList={this.props.isFixedList}
										setDirection={(value) => this.setDirection(value)}
										listContainerFunc={this.props.listContainerFunc}
										windowSizes={this.props.windowSizes}
										onMount={(portal) => (this.portal = portal)}
										datatest={this.props.datatest}
									/>
								)}
						</ul>
						<div className={classes.boxShadow} />
					</div>
				</div>
			</div>
		);
	}
}

class DropdownLifecycleAbusser extends React.Component {
	render() {
		return <Dropdown {...this.props} />;
	}
}

const styledDropdown = withStyles(styles)(DropdownLifecycleAbusser);
styledDropdown.displayName = "Dropdown";

export default styledDropdown;
export { Dropdown as __Dropdown };
