import React from "react";
import { AdFormColorLayout } from "Layouts/Form";
import withStyles from "react-jss";
import { inject, observer } from "mobx-react";
import BenefitForm from "ComponentsUI/Benefit/Components/BenefitForm";

const callStyle = (theme) => ({
	root: {
		marginBottom: "1px",
		cursor: "pointer",
		marginTop: "23px",
		display: "block",
	},
	call: {
		fontFamily: theme.fonts.bold,
		fontSize: "22px",
		lineHeight: "1.09",
		letterSpacing: "-.6px",
		userSelect: "none",
		color: "#cf0",
		"& > a": {
			color: "#cf0",
			fontSize: "22px",
			"& .icon24": {
				marginRight: "10px",
			},
		},
	},
	text: {
		fontFamily: theme.fonts.light,
		fontSize: "14px",
		lineHeight: "1.43",
		color: "#fff",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		text: {
			fontSize: "12px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		text: {
			fontSize: "12px",
		},
	},
});

const Call = withStyles(callStyle)(({ children, classes, call, text }) => {
	return (
		<div className={classes.root}>
			<div className={classes.call}>{call}</div>
			<div className={classes.text}>{text}</div>
		</div>
	);
});

@inject("ApplicationModel")
@observer
class FreeConsultationAdverBlock extends React.Component {
	render() {
		let text = this.props.ApplicationModel.text;
		return (
			<AdFormColorLayout backgroundColor={"green"}>
				<BenefitForm
					checkout
					header={text.heroFreeConsultation}
					text={text.CONSULTATION_HELP}
					icon={
						<span
							style={{
								fontSize: "48px",
								color: "#ccff00",
							}}
							className="icon48 icon-support"
						/>
					}
				/>

				<Call call={this.props.phone} text={text.CONSULTATION_TIME} />
			</AdFormColorLayout>
		);
	}
}

export default FreeConsultationAdverBlock;
