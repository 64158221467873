import { replaceStaticHost } from "Helpers/ImageManager";
import { CDN_URL_NO_PROTO } from "Constants/CDN";

const emotions = {
	negative: replaceStaticHost(`${CDN_URL_NO_PROTO}/images/redesign/icons/sad.png`),
	positive: replaceStaticHost(`${CDN_URL_NO_PROTO}/images/redesign/icons/smile.png`),
	neutral: replaceStaticHost(`${CDN_URL_NO_PROTO}/images/redesign/icons/frown.png`),
	bubble: replaceStaticHost(`${CDN_URL_NO_PROTO}/images/redesign/icons/bubble.png`),
	paid: replaceStaticHost(`${CDN_URL_NO_PROTO}/images/redesign/icons/paid.png`),
	without: replaceStaticHost(`${CDN_URL_NO_PROTO}/images/redesign/icons/frown.png`),
	sunglasses: replaceStaticHost(`${CDN_URL_NO_PROTO}/images/redesign/icons/Sunglasses.png`),
};

export default emotions;
