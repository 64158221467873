const CDN_URL = require("Constants/CDN").CDN_URL;

module.exports = {
	benefitLg: `${CDN_URL}/images/redesign/v2/benefit/benefit-lg.jpg`,
	benefitXs: `${CDN_URL}/images/redesign/v2/benefit/benefit-xs.jpg`,
	callback: `${CDN_URL}/images/redesign/callback/manager-all_size.png`,
	heroBannerLg: `${CDN_URL}/images/redesign/hero-banner-lg.jpg`,
	heroBannerMd: `${CDN_URL}/images/redesign/hero-banner-md.jpg`,
	heroBannerSm: `${CDN_URL}/images/redesign/hero-banner-sm.jpg`,
	heroBannerXs: `${CDN_URL}/images/redesign/hero-banner-xs600.jpg`,
	pageTitleLg: `${CDN_URL}/images/redesign/v2/page_title/page_title-lg.jpg`,
	pageTitleXs: `${CDN_URL}/images/redesign/v2/page_title/page_title-xs.jpg`,
	heroBannerSvgLg: `${CDN_URL}/images/background/lg.svg`,
	heroBannerSvgMd: `${CDN_URL}/images/background/md.svg`,
	heroBannerSvgSm: `${CDN_URL}/images/background/md.svg`,
	heroBannerSvgXs: `${CDN_URL}/images/background/xs.svg`,
};
