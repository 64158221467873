import React from "react";
import * as classNames from "classnames";
import withStyles from "react-jss";

const styles = {
	root: {
		position: "relative",
		overflow: "hidden",
		userSelect: "none",
		cursor: "inherit",
	},
	placeholder: {
		position: "absolute",
		top: "50%",
		transform: "translate(0, -50%)",
		left: 0,
		cursor: "inherit",
	},
	isHidden: {
		display: "none",
	},
};

class PlaceholderInput extends React.Component {
	static defaultProps = {
		show: true,
		classNamePlaceholder: null,
		nativeHandlers: false,
	};

	bindedBlurHandler = (e) => this.blurHandler(e);
	bindedInputHandler = (e) => this.inputHandler(e);

	constructor(props) {
		super(props);
		this.state = {
			show: true,
			nativeChangleHandler: props.children.props.onChange,
			nativeFocusHandler: props.children.props.onFocus,
			nativeBlurHandler: props.children.props.onBlur,
		};
	}

	wrapper = null;

	componentDidMount() {
		if (!this.props.nativeHandlers) {
			let input = this.wrapper.querySelector("input") || this.wrapper.querySelector("textarea");
			if (input.addEventListener) {
				input.addEventListener("blur", this.bindedBlurHandler);
				input.addEventListener("input", this.bindedInputHandler);
			}
		}
	}

	componentWillUnmount() {
		if (!this.props.nativeHandlers) {
			let input = this.wrapper.querySelector("input") || this.wrapper.querySelector("textarea");
			if (input.removeEventListener) {
				input.removeEventListener("blur", this.bindedBlurHandler);
				input.removeEventListener("input", this.bindedInputHandler);
			}
		}
	}

	fakeFocus() {
		let input = this.wrapper.querySelector("input") || this.wrapper.querySelector("textarea");
		input.focus();
		this.props.clickInputHandler && this.props.clickInputHandler();
		let windowSizes = this.props.windowSizes;
		if (this.props.centering && (windowSizes.xs || windowSizes.sm) && input) {
			input.scrollIntoView({ block: "center" });
		}
	}

	blurHandler(event) {
		this.state.nativeBlurHandler && this.state.nativeBlurHandler(event);
		this.setState({
			show: event.currentTarget.value == "",
		});
	}

	inputHandler(event) {
		this.state.nativeChangleHandler && this.state.nativeChangleHandler(event);
		this.setState({
			show: event.currentTarget.value == "",
		});
	}

	focusWrapper() {
		let windowSizes = this.props.windowSizes;
		if (this.props.centering && (windowSizes.xs || windowSizes.sm) && this.wrapper) {
			this.wrapper.scrollIntoView({ block: "center" });
		}
	}

	render() {
		let classes = this.props.classes;
		return (
			<div
				ref={(wrapper) => {
					this.wrapper = wrapper;
				}}
				className={classNames(classes.root, this.props.className)}
				onClick={() => this.focusWrapper()}
			>
				<span
					className={classNames(classes.placeholder, this.props.classNamePlaceholder, {
						[classes.isHidden]:
							(!this.state.show && !(typeof this.props.value === "string")) || !!this.props.value,
					})}
					onClick={() => this.fakeFocus()}
					id={this.props.childrensInputIdAria}
				>
					{this.props.placeholder}
				</span>
				{this.props.children}
			</div>
		);
	}
}

export default withStyles(styles)(PlaceholderInput);

export { PlaceholderInput as __Placeholder };
