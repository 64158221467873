import React from "react";

import { createUseStyles } from "react-jss";
import { SVGFooterPhone } from "Constants/SVG";
import { isMobilePage } from "Utils/routeCheck";
import classNames from "classnames";

const useStyles = createUseStyles((theme) => ({
	wrapper: {
		gap: "8px",
		"&:hover ": {
			cursor: "pointer",
			"& $title": {
				color: "#5813FE",
			},
			"& svg": {
				"& rect": {
					fill: "#5813FE",
				},
			},
			"& $phoneNumberText": {
				color: "#5813FE",
			},
		},
	},
	title: {
		fontSize: "12px",
		lineHeight: "16px",
		fontFamily: "Inter-Regular",
		fontWeight: "400",
		color: "#4B5563",
		margin: "0",
	},
	phoneNumberWrapper: {
		display: "flex",
		alignItems: "center",
		gap: "4px",
	},
	phoneNumberText: {
		fontSize: "16px",
		lineHeight: "24px",
		fontFamily: "Inter-Semibold",
		fontWeight: "600",
		color: "#09090B",
	},
	[`@media (max-width: ${theme.media.md})`]: {
		wrapper: {
			marginBottom: "2px",
		},
		title: {
			marginBottom: "2px",
			fontSize: "10px",
			lineHeight: "12px",
			textAlign: "center",
		},
		phoneNumberWrapper: {
			gap: "6px",
		},
		phoneNumberText: {
			fontSize: "14px",
			lineHeight: "20px",
		},
	},
}));

const ConsultationPhone = (props) => {
	const { windowSizes, phoneNumber, path, onClick } = props;
	const classes = useStyles();

	return (
		!isMobilePage(path) &&
		(windowSizes?.xs || windowSizes?.sm ? (
			<a aria-label="call" href={`tel:${phoneNumber}`} className={classNames("roistat-phone-tel")}>
				<div className={classes.wrapper}>
					<p className={classes.title}>Бесплатная консультация</p>
					<div className={classes.phoneNumberWrapper}>
						{SVGFooterPhone}
						<span className={classes.phoneNumberText}>{phoneNumber}</span>
					</div>
				</div>
			</a>
		) : (
			<div className={classes.wrapper} onClick={onClick}>
				<p className={classes.title}>Бесплатная консультация</p>
				<div className={classes.phoneNumberWrapper}>
					{SVGFooterPhone}
					<span className={classes.phoneNumberText}>{phoneNumber}</span>
				</div>
			</div>
		))
	);
};

export default ConsultationPhone;
