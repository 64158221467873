var gql = require("@apollo/client").gql;

const REDIRECT_QUERY_V2 = gql`
	query REDIRECT_QUERY_V2($url: String!) {
		redirectV2(url: $url) {
			from
			to
			status
		}
	}
`;

export default REDIRECT_QUERY_V2;
