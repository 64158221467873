var gql = require("@apollo/client").gql;

const MUTATE_AB = gql`
	mutation (
		$uuid: String
		$user_agent: String
		$experiment: String
		$variant: String
		$goal: Boolean
		$event: String
	) {
		abLog(
			uuid: $uuid
			user_agent: $user_agent
			experiment: $experiment
			variant: $variant
			goal: $goal
			event: $event
		)
	}
`;

module.exports = MUTATE_AB;
