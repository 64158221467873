import { observable, action } from "mobx";

export const PROVIDER_TAB_CARD = "provider_tab_card";
export const PROVIDER_TAB_TARIFFS = "provider_tab_tariffs";
export const PROVIDER_TAB_REVIEWS = "provider_tab_review";
export const PROVIDER_TAB_ACTIONS = "provider_tab_actions";

export class ProviderModel {
	tabs = [PROVIDER_TAB_CARD];

	@observable visibleTab = PROVIDER_TAB_CARD;
	@observable providerForFeedback = null;
	@observable providerIdForOrder = null;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	changeTab(tab) {
		this.visibleTab = tab;
	}

	@action
	setProviderFeedback(providerName) {
		this.providerForFeedback = providerName;
	}

	@action
	setProviderIdForOrder(id) {
		this.providerIdForOrder = id;
	}
}
