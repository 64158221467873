import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { captureMessage } from "@sentry/react";
import withDefaultParams from "Services/withDefaultParams";

@withRouter
@withDefaultParams
export default class Redirect404 extends React.PureComponent {
	render() {
		let redirLocation = {
			pathname: this.props.location.pathname,
			search: this.props.location.search,
			state: {
				...this.props.location.state,
				is404: true,
				message404: this.props.message404,
			},
		};

		let url = "";
		if (typeof window !== "undefined") {
			url = window?.location?.href;
		}

		if (!this.props.location.pathname?.includes("/otzyv-")) {
			captureMessage("Redirect to 404", {
				tags: {
					"error.type": "redirect_404",
				},
				extra: {
					pathname: this?.props?.location?.pathname,
					search: this?.props?.location?.search,
					message: this?.props?.message404,
					url: url,
				},
				level: "error",
			});
		}

		return <Redirect to={redirLocation} />;
	}
}
