import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { generateUrl } from "Services/withDefaultParams";
import { notRegionUrls } from "Constants/defaultParams";
import NotificationAboutCookie from "ComponentsUI/NotificationAboutCookie";
import Routes from "Configs/routes";
import Cookies from "js-cookie";

const CookieNotificationHOCContainer = (props) => {
	const allowClose = Cookies.get("closeNotificationAboutCookie");
	let firstURL = Cookies.get("firstURL");
	let currentURL = props.location.pathname;
	let date = new Date();
	date.setTime(date.getTime() + 60 * 60 * 24 * 30 * 1000);
	if (!firstURL) {
		Cookies.set("firstURL", false, { expires: date });
		firstURL = Cookies.get("firstURL");
	}

	const [isClosed, setIsClosed] = useState(allowClose);

	const onClose = () => {
		Cookies.set("closeNotificationAboutCookie", true, { expires: date });
		setIsClosed(true);
	};

	if (!isClosed) {
		let regionUrl = props.match.params.regionUrl;

		let regionUrlReal = notRegionUrls.indexOf(regionUrl) >= 0 ? "" : regionUrl;

		let link = generateUrl(Routes.PersonalData.route, {
			regionUrl: regionUrlReal,
		});
		if (firstURL == "false") {
			Cookies.set("firstURL", currentURL, { expires: date });
			return <NotificationAboutCookie onClose={onClose} link={link} text={props.text} />;
		} else {
			return null;
		}
	} else {
		return null;
	}
};

export default withRouter(CookieNotificationHOCContainer);
