const SITE_CODES = {
	MOSCOW: "moskvaonline",
	MAIN: "101internet",
	PITER: "piteronline",
};

const PHONE_NUMBER_BY_CODE = {
	[SITE_CODES.MOSCOW]: "+7 (495) 256-00-21",
	[SITE_CODES.MAIN]: "+7 800 302-32-76",
	[SITE_CODES.PITER]: "+7 (812) 385-66-34",
};

const COMPANY_NAME_BY_CODE = {
	[SITE_CODES.MOSCOW]: "Москва Онлайн",
	[SITE_CODES.MAIN]: "101 Интернет",
	[SITE_CODES.PITER]: "Питер Онлайн",
};

const COMPANY_INFO_BY_CODE = {
	[SITE_CODES.MOSCOW]: {
		address: "Покровский бульвар, 4/17с1",
		region: "Москва",
		email: " info@moskvaonline.ru",
		postalCode: "109028",
	},
	[SITE_CODES.MAIN]: {
		address: "Тихая ул., 33, офис 158",
		region: "Москва",
		email: "info@101internet.ru",
		postalCode: "109387",
	},
	[SITE_CODES.PITER]: {
		address: "Невский проспект, стр.153",
		region: "Санкт-Петербург",
		email: "pr@piter-online.net",
		postalCode: "191167",
	},
};

export const isMoscowSiteCode = (code) => SITE_CODES.MOSCOW === code;
export const isMainSiteCode = (code) => SITE_CODES.MAIN === code;
export const isPiterSiteCode = (code) => SITE_CODES.PITER === code;

export const getPhoneNumberByCode = (code) =>
	PHONE_NUMBER_BY_CODE[code] || PHONE_NUMBER_BY_CODE[[SITE_CODES.MOSCOW]];

export const getCompanyNameByCode = (code) => COMPANY_NAME_BY_CODE[code];

export const getDomainSite = (siteConfig) => {
	const sitePrefix = `http${siteConfig.ssl ? "s" : ""}://${
		isMoscowSiteCode(siteConfig.code) ? "www." : ""
	}`;
	return `${sitePrefix}${siteConfig.domain_name}`;
};

export const getFullUrlSite = (siteConfig, url) => {
	const sitePrefix = `http${siteConfig.ssl ? "s" : ""}://${
		isMoscowSiteCode(siteConfig.code) ? "www." : ""
	}`;
	return `${sitePrefix}${siteConfig.domain_name}${url}`;
};

export const getCompanyInfoByCode = (code) => COMPANY_INFO_BY_CODE[code];
