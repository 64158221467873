import React from "react";
import { createUseStyles } from "react-jss";
import VisibilitySensor from "react-visibility-sensor";
import PropTypes from "prop-types";
import classNames from "classnames";
import Container from "reactstrap/lib/Container";
import { PaddingBottom } from "ComponentsUI/Spacings";
import QuestionAnswer from "ComponentsUI/QuestionAnswer";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";

const useStyles = createUseStyles((theme) => ({
	root: {
		position: "static",
	},
	withBackground: {
		position: "relative",
	},
	text: {
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "1.38",
		color: theme.palette.colors.darkSlateBlue,
	},
	textString: {
		padding: "20px",
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "1.38",
		color: theme.palette.colors.darkSlateBlue,
		backgroundColor: theme.palette.colors.white,
		border: `solid 1px ${theme.palette.colors.disabled}`,
		borderRadius: "20px",
	},
	wrapper: {
		display: "flex",
		flexDirection: "column",
	},
	orderLast: {
		order: 1000,
	},
	orderLastText: {
		order: 1000,
	},
	orderLastFaq: {
		order: 1001,
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		text: {
			fontSize: "14px",
			lineHeight: "1.29",
		},
		textString: {
			fontSize: "14px",
			lineHeight: "1.29",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		text: {
			fontSize: "14px",
			lineHeight: "1.29",
			margin: "0",
		},
		textString: {
			fontSize: "14px",
			lineHeight: "1.29",
			margin: "0",
		},
	},
}));

const SeoText = ({
	children,
	seoData,
	hideText,
	background,
	text,
	routeName,
	streetId,
	house_id,
	sendingStore,
}) => {
	let classes = useStyles();

	if (!seoData) {
		return null;
	}

	let { ttltext: seoTitle, main_text, faq } = seoData;
	let seoText = main_text;

	const wasViewed = (view) => {
		if (view && routeName.includes("RatesInHouse") && sendingStore && house_id) {
			sendingStore.sendMetriksAndSaveArray("VIEW_CHAVO_HOUSE", house_id);
		}

		if (view && routeName.includes("AddressStreet") && sendingStore && streetId) {
			sendingStore.sendMetriksAndSaveArray("VIEW_CHAVO_STREET", streetId);
		}
	};

	const clickQuestion = (index) => {
		if (index && routeName.includes("RatesInHouse") && sendingStore && house_id) {
			sendingStore.sendMetriksAndSaveArray("OPEN_CHAVO_HOUSE", house_id, index);
		}

		if (index && routeName.includes("AddressStreet") && sendingStore && streetId) {
			sendingStore.sendMetriksAndSaveArray("OPEN_CHAVO_STREET", streetId, index);
		}
	};
	return (
		<div className={classes.wrapper}>
			{!hideText && seoText && (
				<div className={classes.orderLast}>
					<PaddingBottom>
						<div
							className={classNames(classes.root, {
								[classes.withBackground]: !!background,
							})}
						>
							{background}
							<Container>
								<HeaderTextSeo header={seoTitle} text={seoText} bgText={true} textAlign="left" />
							</Container>
						</div>
					</PaddingBottom>
				</div>
			)}

			{!!faq?.length && (
				<div className={classes.orderLast}>
					<PaddingBottom>
						<div className={classes.root}>
							<Container>
								<VisibilitySensor onChange={(visible) => wasViewed(visible)}>
									<HeaderTextSeo header={text.FREQUENT_QUESTION} />
								</VisibilitySensor>
								<QuestionAnswer clickQuestion={clickQuestion} faq={faq} />
							</Container>
						</div>
					</PaddingBottom>
				</div>
			)}

			{children}
		</div>
	);
};

SeoText.defaultProps = {
	background: null,
	seoData: {},
	routeName: "",
	streetId: 0,
	house_id: 0,
	sendingStore: () => {},
	// text: "",
	// title: "",
};

SeoText.propTypes = {
	background: function (props, propName, componentName) {
		if (props[propName] !== null) {
			if (
				!props[propName].type ||
				!props[propName].type.displayName ||
				props[propName].type.displayName != "Jss(Background)"
			) {
				return new Error(
					`Invalid prop ${propName}, value must be Jss(Background) component. Validation failed.`
				);
			}
		}
	},
	seoData: PropTypes.object,
	streetId: PropTypes.number,
	house_id: PropTypes.number,
	routeName: PropTypes.string,
	// text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// title: PropTypes.string,
};

export default SeoText;
