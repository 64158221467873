import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const withPaginationSEO = (ComposedComponent) => {
	return class extends React.Component {
		constructor() {
			super();
		}

		render() {
			let pageCurrent = parseInt(this.props.pageCurrent);
			let prevPage = `${this.props.urlDefault}`;
			if (pageCurrent > 2) {
				prevPage = prevPage + `/${pageCurrent - 1}`;
			}
			let nextPage = `${this.props.urlDefault}/${pageCurrent + 1}`;
			return (
				<Fragment>
					<Helmet>
						{/*{pageCurrent != 1 && (*/}
						{/*    <meta name="robots" content="noindex, follow" />*/}
						{/*)}*/}
						{pageCurrent != 1 && <link rel="prev" href={prevPage} />}
						{pageCurrent != this.props.pageTotal && <link rel="next" href={nextPage} />}
					</Helmet>
					<ComposedComponent {...this.props} />
				</Fragment>
			);
		}
	};
};

export default withPaginationSEO;
