import React, { forwardRef } from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import { MeasureA, MeasureAction } from "ComponentsUI/TarifficationB";

const styles = (theme) => ({
	root: {
		alignItems: "flex-start",
		maxWidth: "50px",
	},
	rootSales: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		maxWidth: "50px",
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			flexDirection: "row",
			flexWrap: "wrap",
			maxWidth: "210px",
		},
		rootSales: {
			flexDirection: "row",
			flexWrap: "wrap",
			maxWidth: "210px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			maxWidth: "195px",
		},
		rootSales: {
			flexDirection: "row",
			flexWrap: "wrap",
			maxWidth: "195px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			maxWidth: "110px",
			width: "110px",
		},
		rootSales: {
			maxWidth: "110px",
			width: "110px",
		},
	},
	[`@media (max-width: ${theme.media.tariffsSmall})`]: {
		root: {
			width: "100%",
			maxWidth: "224px",
			paddingLeft: "50px",
		},
		rootSales: {
			width: "100%",
			maxWidth: "224px",
			paddingLeft: "67px",
		},
	},
});

const MeasureTotal = forwardRef((props, ref) => {
	let { classes } = props;
	return (
		<div
			className={classNames("MeasureTotal", {
				[classes.root]: !props.valueSale,
				[classes.rootSales]: !!props.valueSale,
			})}
			ref={ref}
		>
			<MeasureA
				value={props.value}
				valueSale={props.valueSale}
				measure={props.measure}
				// withAsterisk={props.withAsterisk}
				flagValuePrice={true}
			/>
			{props.valueSale && (
				<MeasureAction
					valueSale={props.valueSale}
					measure={props.measure}
					withAsterisk={props.withAsterisk}
				/>
			)}
		</div>
	);
});

export default withStyles(styles)(MeasureTotal);
