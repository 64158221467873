import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { useQuery } from "@apollo/client";
import { EXPERIMENT_QUERY } from "Services/queries";
import { pipe } from "Utils/functionalUtils";
import { sendMetriks } from "Services/sendMetriks";

const ABTestContainerAudio = (props) => {
	let { testName, variantName } = props;

	const {
		loading: loadingExperiment,
		data: dataExperiment,
		error: errorExperiment,
	} = useQuery(EXPERIMENT_QUERY, {
		variables: {
			test_name: testName,
			variant_name: variantName,
		},
		notifyOnNetworkStatusChange: true,
		nextFetchPolicy: "network-only",
	});

	const sendClickPlay = () => {
		let testABVariant = Cookies.get("AB_testtest__Test5_zayavka_1_click_TS_audio");
		let testName = "No_name";
		if (Boolean(testABVariant)) {
			testName = JSON.parse(testABVariant)?.name || "No_name";
		}

		sendMetriks("CLICK_AUDIO_ON_1ClICK", [testName]);
	};

	const sendClickPause = () => {
		let testABVariant = Cookies.get("AB_testtest__Test5_zayavka_1_click_TS_audio");
		let testName = "No_name";
		if (Boolean(testABVariant)) {
			testName = JSON.parse(testABVariant)?.name || "No_name";
		}

		sendMetriks("CLICK_AUDIO_OFF_1ClICK", [testName]);
	};

	useEffect(() => {
		const btnPlay = document.getElementById("btn-play");
		const btnPause = document.getElementById("btn-pause");

		if (btnPlay) {
			btnPlay.addEventListener("click", sendClickPlay);
		}

		if (btnPause) {
			btnPause.addEventListener("click", sendClickPause);
		}

		return () => {
			if (btnPlay) {
				btnPlay.removeEventListener("click", sendClickPlay);
			}
			if (btnPause) {
				btnPause.removeEventListener("click", sendClickPause);
			}
		};
	}, [dataExperiment]);

	useEffect(() => {
		if (dataExperiment?.experiment?.script) {
			const script = document.createElement("script");

			script.text = dataExperiment.experiment.script;
			script.async = true;
			script.type = "module";

			document.body.appendChild(script);

			return () => {
				document.body.removeChild(script);
			};
		}
	}, [dataExperiment]);

	if (loadingExperiment || errorExperiment || !dataExperiment) {
		return null;
	}

	let htmlText = dataExperiment.experiment?.html || "";

	return (
		<div
			data-test={testName}
			dangerouslySetInnerHTML={{
				__html: htmlText,
			}}
		></div>
	);
};

ABTestContainerAudio.propTypes = {
	testName: PropTypes.string.isRequired,
	variantName: PropTypes.string.isRequired,
	otherProps: PropTypes.object,
	changeComplete: PropTypes.func,
};

export default pipe(observer, inject("ApplicationModel"))(ABTestContainerAudio);
