import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import { checkTimeMatch, getCurrentDayOfWeek } from "Services/Time";

const styles = (props) => ({
	root: {
		backgroundColor: props?.settings?.info_popup?.bg_color || "#fbda61",
		color: props?.settings?.info_popup?.text_color || "black",
		padding: "5px",
		textAlign: "center",
	},
	hide: {
		display: "none",
	},
});

const withStylesProps = (styles) => (Component) => (props) => {
	const Comp = withStyles(styles(props))(Component);
	return <Comp {...props} />;
};

const InfoBlock = ({ classes, current_region, settings }) => {
	const info_popup = settings?.info_popup;

	const classArr = [classes.root];
	if (!info_popup?.enabled && !current_region?.info_popup) {
		classArr.push(classes.hide);
	}

	const dow = getCurrentDayOfWeek();

	const time_start_field = `time_start_${dow}`;
	const time_end_field = `time_end_${dow}`;

	if (info_popup && info_popup[time_start_field] && info_popup[time_end_field]) {
		const show = checkTimeMatch(info_popup[time_start_field], info_popup[time_end_field]);

		if (!show) {
			classArr.push(classes.hide);
		}
	}

	let body_text = "";

	if (info_popup?.body_text) {
		body_text = info_popup.body_text;
	} else {
		classArr.push(classes.hide);
	}

	//При SSR скрывать всегда
	if (typeof window === "undefined") {
		classArr.push(classes.hide);
	}

	return (
		<div
			data-testid="InfoBlock"
			className={classNames(classArr)}
			dangerouslySetInnerHTML={{ __html: body_text }}
		/>
	);
};

export default withStylesProps(styles)(InfoBlock);
