import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { BackgroundImage } from "ComponentsUI/BackgroundImage";
import Container from "reactstrap/lib/Container";
import { webpSimpleTransformer } from "Helpers/ImageManager";

const styles = (theme) => {
	return {
		root: {
			backgroundColor: theme.palette.colors.paleGrey,
			padding: "226px 0 60px",
			position: "relative",
			zIndex: 0,
		},
		darkBackground: {
			backgroundColor: "#162043",
			padding: "226px 0 30px",
			marginBottom: "30px",
		},
		container: {
			paddingLeft: "40px",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		containerHeader: {
			width: "100%",
		},
		image: {
			backgroundColor: theme.palette.colors.slateBlue,
			marginBottom: "50px",
		},
		marginBottom40: {
			marginBottom: "40px",
		},
		mainBreadCrumbs: {},
		actionBreadCrumbs: {},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			container: {
				paddingLeft: "35px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				padding: "235px 0 40px",
			},
			darkBackground: {
				padding: "235px 0 20px",
				marginBottom: "20px",
			},
			container: {
				paddingLeft: "25px",
			},
			image: {
				marginBottom: "40px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				padding: "80px 0 20px",
			},
			darkBackground: {
				padding: "80px 0 10px",
				marginBottom: "10px",
			},
			container: {
				paddingLeft: "8.33%",
			},
			image: {
				marginBottom: "20px",
			},
			mainBreadCrumbs: {
				minHeight: "79px",
			},
			actionBreadCrumbs: {
				minHeight: "54px",
			},
		},
	};
};

class PageTitleWrapper extends React.Component {
	static defaultProps = {
		background: {},
		upper: null,
		bottom: null,
		controllers: null,
		classes: {},
	};

	static propTypes = {
		background: PropTypes.object,
		upper: PropTypes.element,
		bottom: PropTypes.element,
		controllers: PropTypes.element,
	};

	render() {
		let image = null;
		let classes = this.props.classes;
		if (this.props.background)
			image = (
				<BackgroundImage
					webpTransformer={webpSimpleTransformer}
					windowSizes={this.props.windowSizes}
					background={this.props.background}
					shiftY={25}
				/>
			);
		return (
			<div
				className={classNames(classes.root, {
					[classes.image]: this.props.background,
					[classes.darkBackground]: this.props.darkBackground,
					[classes.marginBottom40]: this.props.marginBottom40,
				})}
			>
				<Container>
					<div className={classes.container}>
						<div className={classes.containerHeader}>
							<div
								className={classNames("breadCrumbs", {
									[classes.mainBreadCrumbs]: this.props.isMain,
									[classes.actionBreadCrumbs]: this.props.isAction,
								})}
							>
								{this.props.upper}
							</div>
							{this.props.bottom}
						</div>
						<div>{this.props.controllers}</div>
						{this.props.button}
						{image}
					</div>
				</Container>
			</div>
		);
	}
}

export default withStyles(styles)(PageTitleWrapper);
