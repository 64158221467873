var gql = require("@apollo/client").gql;

const ORDER_COUNTER_QUERY = gql`
	query SiteConfig {
		siteConfig {
			order_counter
		}
	}
`;

module.exports = ORDER_COUNTER_QUERY;
