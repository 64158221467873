import React from "react";
import { FindTariffs } from "ComponentsUI/Navigation";
import Metrika from "Services/Metrika";
import { SUCCESS_SEARCH_ADDRESS_PROVIDER_COVERAGE } from "Constants/metriks/Yandex";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";
import { EVENT_SUCCESS_SEARCH_COVERAGE_PROVIDER } from "Constants/metriks/GoogleActions";

export default class extends React.Component {
	successSearchCallback() {
		// Metrika.getInstance().google.sendEvent(
		//     EVENT_CATEGORY_CLICK_BUTTON,
		//     EVENT_SUCCESS_SEARCH_COVERAGE_PROVIDER
		// );
	}

	render() {
		let header = `Подключить интернет в ${this.props.declinations.nominative} по адресу`;
		return (
			<FindTariffs
				{...this.props}
				header={header}
				typeB={true}
				successCallback={() => this.successSearchCallback()}
				datatestForAddressButton={"find_tohome_from_not_partners_button_popup"}
			/>
		);
	}
}
