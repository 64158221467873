import { isMoscowSiteCode, isPiterSiteCode, isMainSiteCode } from "Utils/siteConfig";

let table_101 = `<div>
<table id="person">
    <thead>
        <tr>
            <th><p>Провайдер</p></th>
            <th><p>Юр. Лицо</p></th>
            <th><p>ИНН</p></th>
            <th><p>ОГРН</p></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td><p>Ринет</p></td>
            <td><p>ООО "Кроникс Плюс"</p></td>
            <td><p>7729342140</p></td>
            <td><p>1027700573273</p></td>
        </tr>
        <tr>
            <td><p>Марьино Нэт</p></td>
            <td><p>ООО "Экстрим"</p></td>
            <td><p>7715354219</p></td>
            <td><p>1027715012225</p></td>
        </tr>
        <tr>
            <td><p>ИнетКом</p></td>
            <td><p>ООО "Инетком"</p></td>
            <td><p>9721009951</p></td>
            <td><p>1167746736343</p></td>
        </tr>
        <tr>
            <td><p>Уфанет, КГС, ВЛ-Телеком</p></td>
            <td><p>АО "Уфанет"</p></td>
            <td><p>0278109628</p></td>
            <td><p>1050204596914</p></td>
        </tr>
        <tr>
            <td><p>Кверти</p></td>
            <td><p>ПАО «Центральный телеграф»</p></td>
            <td><p>7710146208</p></td>
            <td><p>1027739044189</p></td>
        </tr>
        <tr>
            <td><p>ДомРу+В2В</p></td>
            <td><p>АО "ЭР-Телеком Холдинг"</p></td>
            <td><p>5902202276</p></td>
            <td><p>1065902028620</p></td>
        </tr>
        <tr>
            <td><p>2Ком</p></td>
            <td><p>ООО "Компания 2КОМ"</p></td>
            <td><p>5046042235</p></td>
            <td><p>1025006037352</p></td>
        </tr>
        <tr>
            <td><p>Старлинк</p></td>
            <td><p>ООО "Олимп Коннект"</p></td>
            <td><p>7704426260</p></td>
            <td><p>1177746605519</p></td>
        </tr>
        <tr>
            <td><p>Етелеком</p></td>
            <td><p>ООО "ЕТелеком"</p></td>
            <td><p>7802487790</p></td>
            <td><p>1099847015410</p></td>
        </tr>
        <tr>
            <td><p>Электронный город</p></td>
            <td><p>ООО "Новотелеком"</p></td>
            <td><p>5406260827</p></td>
            <td><p>1035402503950</p></td>
        </tr>
        <tr>
            <td><p>Билайн (МСК, Регионы)</p></td>
            <td><p>ПАО "Вымпел - Коммуникации"</p></td>
            <td><p>7713076301</p></td>
            <td><p>1027700166636</p></td>
        </tr>
        <tr>
            <td><p>Скайнет</p></td>
            <td><p>ООО "Скайнет"</p></td>
            <td><p>7816223580</p></td>
            <td><p>1037835054190</p></td>
        </tr>
        <tr>
            <td><p>АКАДО</p></td>
            <td><p>АО "КОМКОР"</p></td>
            <td><p>7717020170</p></td>
            <td><p>1027739387521</p></td>
        </tr>
        <tr>
            <td><p>Ростелеком/Онлайм</p></td>
            <td><p>ПАО «Ростелеком»</p></td>
            <td><p>7707049388</p></td>
            <td><p>1027700198767</p></td>
        </tr>
        <tr>
            <td><p>Ростелеком Башкортостан</p></td>
            <td><p>ПАО "БАШИНФОРМСВЯЗЬ"</p></td>
            <td><p>0274018377</p></td>
            <td><p>1020202561686</p></td>
        </tr>
        <tr>
            <td><p>Мегафон</p></td>
            <td><p>ПАО "МЕГАФОН"</p></td>
            <td><p>7812014560</p></td>
            <td><p>1027809169585</p></td>
        </tr>
        <tr>
            <td><p>МГТС (МТС)</p></td>
            <td><p>ПАО "МТС"</p></td>
            <td><p>7740000076</p></td>
            <td><p>1027700149124</p></td>
        </tr>
        <tr>
            <td><p>Сибирские сети</p></td>
            <td><p>ООО "СИБИРСКИЕ СЕТИ"</p></td>
            <td><p>5404227535</p></td>
            <td><p>1045401493950</p></td>
        </tr>
        <tr>
            <td><p>Люби, Телинком</p></td>
            <td><p>ООО "Современные технологии"</p></td>
            <td><p>5047083837</p></td>
            <td><p>1075047006055</p></td>
        </tr>
        <tr>
            <td><p>Эт Хоум</p></td>
            <td><p>ООО "НЕВСКОЕ"</p></td>
            <td><p>7839446350</p></td>
            <td><p>1117847269946</p></td>
        </tr>
        <tr>
            <td><p>ТТК</p></td>
            <td><p>ООО "ТТК-Связь"</p></td>
            <td><p>7709362765</p></td>
            <td><p>1037739164451</p></td>
        </tr>
        <tr>
            <td><p>Череповец Телеком, Айзет, ЯР.ком, Новгород Телеком</p></td>
            <td><p>АО "ЧЕРЕПОВЕЦ ТЕЛЕКОМ"</p></td>
            <td><p>3528293632</p></td>
            <td><p>1183525032459</p></td>
        </tr>
        <tr>
            <td><p>Фарлайн</p></td>
            <td><p>ООО "Фарлайн"</p></td>
            <td><p>9102177469</p></td>
            <td><p>1159102077848</p></td>
        </tr>
        <tr>
            <td><p>Тушино Телеком</p></td>
            <td><p>ООО "МВМ Технолоджи"</p></td>
            <td><p>7733101404</p></td>
            <td><p>1037700071342</p></td>
        </tr>
        <tr>
            <td><p>АйФлэт</p></td>
            <td><p>ООО "Юнионтел"</p></td>
            <td><p>7721344988</p></td>
            <td><p>1157746788561</p></td>
        </tr>
        <tr>
            <td><p>ЮСИЭЙ Нетворкс</p></td>
            <td><p>ООО "Фастнет"</p></td>
            <td><p>7726525466</p></td>
            <td><p>1057747727993</p></td>
        </tr>
        <tr>
            <td><p>Рнет</p></td>
            <td><p>ООО "Фишнет Коммюникейшнз"</p></td>
            <td><p>7811149131</p></td>
            <td><p>1047818005553</p></td>
        </tr>
        <tr>
            <td><p>Ситителеком</p></td>
            <td><p>ООО «Ситителеком СПб»</p></td>
            <td><p>7838067849</p></td>
            <td><p>1177847124840</p></td>
        </tr>
        <tr>
            <td><p>СатНет</p></td>
            <td><p>ООО "Компьютерные информационные технологии"</p></td>
            <td><p>7802313561</p></td>
            <td><p>1057810000390</p></td>
        </tr>
        <tr>
            <td><p>Гигабит</p></td>
            <td><p>ООО «Гигабит»</p></td>
            <td><p>7111020781</p></td>
            <td><p>1167154058147</p></td>
        </tr>
        <tr>
            <td><p>Тералайн (МСК)</p></td>
            <td><p>ООО "Тералайн Телеком"</p></td>
            <td><p>9709033531</p></td>
            <td><p>1187746602273</p></td>
        </tr>
        <tr>
            <td><p>Мифрил (МСК)</p></td>
            <td><p>ООО "Мифрил+"</p></td>
            <td><p>7714117938</p></td>
            <td><p>1037739063570</p></td>
        </tr>
        <tr>
            <td><p>БизнесТелеком (МСК, МО, Регионы)</p></td>
            <td><p>ООО "Бизнес Телеком"</p></td>
            <td><p>7804558895</p></td>
            <td><p>1167847094370</p></td>
        </tr>
        <tr>
            <td><p>Глобал Телеком (МСК)</p></td>
            <td><p>ООО «Глобал Телеком»</p></td>
            <td><p>7708317167</p></td>
            <td><p>1177746465731</p></td>
        </tr>
        <tr>
            <td><p>Филанко (МСК)</p></td>
            <td><p>ООО «ДОМОНЕТ»</p></td>
            <td><p>7726762643</p></td>
            <td><p>5147746428650</p></td>
        </tr>
        <tr>
            <td><p>Окнет (МО)</p></td>
            <td><p>ООО "Окнет"</p></td>
            <td><p>7709466066</p></td>
            <td><p>1157746767683</p></td>
        </tr>
        <tr>
            <td><p>МТ6 (МО)</p></td>
            <td><p>ООО «Мой Телеком»</p></td>
            <td><p>9706025170</p></td>
            <td><p>1227700431925</p></td>
        </tr>
        <tr>
            <td><p>Етелеком (СПБ)</p></td>
            <td><p>АО "ЭЛЕКТРОНТЕЛЕКОМ"</p></td>
            <td><p>7802184161</p></td>
            <td><p>1037804006360</p></td>
        </tr>
        <tr>
            <td><p>Обит (СПБ)</p></td>
            <td><p>ООО "ОБИТ"</p></td>
            <td><p>7810204213</p></td>
            <td><p>1037821038860</p></td>
        </tr>
        <tr>
            <td><p>Филанко (СПБ+ЛО)</p></td>
            <td><p>ООО "Филанко СПБ"</p></td>
            <td><p>7838492138</p></td>
            <td><p>1137847244204</p></td>
        </tr>
        <tr>
            <td><p>Еконнект Сибирь (Регионы)</p></td>
            <td><p>ИП Дудин Дмитрий Викторович</p></td>
            <td><p>246215439093</p></td>
            <td><p>318246800070542</p></td>
        </tr>
        <tr>
            <td><p>База.нэт (Вологда, Вологодская обл.) ЮЛ+ЧС</p></td>
            <td><p>ООО "БАЗА.НЭТ"</p></td>
            <td><p>3525385324</p></td>
            <td><p>1163525094699</p></td>
        </tr>
        <tr>
            <td><p>Хайлинк (Иркутск) ЮЛ+ЧС</p></td>
            <td><p>ООО "ХАЙЛИНК"</p></td>
            <td><p>3849038095</p></td>
            <td><p>1143850039805</p></td>
        </tr>
        <tr>
            <td><p>Информационные технологии (Самара, Самарская обл.)</p></td>
            <td><p>ООО "ИНФОРМАЦИОННЫЕ ТЕХНОЛОГИИ"</p></td>
            <td><p>6317073915</p></td>
            <td><p>1086317002254</p></td>
        </tr>
        <tr>
            <td><p>Мослайн (МСК, МО)</p></td>
            <td><p>ООО "Телеком 51"</p></td>
            <td><p>5006013774</p></td>
            <td><p>1095015001520</p></td>
        </tr>
        <tr>
            <td><p>ИП Антошин (Брянск, Брянск.обл.)</p></td>
            <td><p>ИП Антошин Сергей Александрович</p></td>
            <td><p>324301695700</p></td>
            <td><p>317325600004585</p></td>
        </tr>
    </tbody>
</table>
</div>`;

let table_Mol = `<div>
<table id="person">
    <thead>
        <tr>
            <th><p>Провайдер</p></th>
            <th><p>Юр. Лицо</p></th>
            <th><p>ИНН</p></th>
            <th><p>ОГРН</p></th>
            <th><p>регион</p></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td><p>Ринет</p></td>
            <td><p>ООО "Кроникс Плюс"</p></td>
            <td><p>7729342140</p></td>
            <td><p>1027700573273</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Марьино Нэт</p></td>
            <td><p>ООО "Экстрим"</p></td>
            <td><p>7715354219</p></td>
            <td><p>1027715012225</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>ИнетКом</p></td>
            <td><p>ООО "Инетком"</p></td>
            <td><p>9721009951</p></td>
            <td><p>1167746736343</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Уфанет, КГС, ВЛ-Телеком</p></td>
            <td><p>АО "Уфанет"</p></td>
            <td><p>0278109628</p></td>
            <td><p>1050204596914</p></td>
            <td><p>мск+рег</p></td>
        </tr>
        <tr>
            <td><p>Кверти</p></td>
            <td><p>ПАО «Центральный телеграф»</p></td>
            <td><p>7710146208</p></td>
            <td><p>1027739044189</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>ДомРу+В2В</p></td>
            <td><p>АО "ЭР-Телеком Холдинг"</p></td>
            <td><p>5902202276</p></td>
            <td><p>1065902028620</p></td>
            <td><p>мск+спб+рег</p></td>
        </tr>
        <tr>
            <td><p>2Ком</p></td>
            <td><p>ООО "Компания 2КОМ"</p></td>
            <td><p>5046042235</p></td>
            <td><p>1025006037352</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Старлинк</p></td>
            <td><p>ООО "Олимп Коннект"</p></td>
            <td><p>7704426260</p></td>
            <td><p>1177746605519</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Билайн (МСК, Регионы)</p></td>
            <td><p>ПАО "Вымпел - Коммуникации"</p></td>
            <td><p>7713076301</p></td>
            <td><p>1027700166636</p></td>
            <td><p>мск+спб+рег</p></td>
        </tr>
        <tr>
            <td><p>АКАДО</p></td>
            <td><p>АО "КОМКОР"</p></td>
            <td><p>7717020170</p></td>
            <td><p>1027739387521</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Ростелеком/Онлайм</p></td>
            <td><p>ПАО «Ростелеком»</p></td>
            <td><p>7707049388</p></td>
            <td><p>1027700198767</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Мегафон</p></td>
            <td><p>ПАО "МЕГАФОН"</p></td>
            <td><p>7812014560</p></td>
            <td><p>1027809169585</p></td>
            <td><p>мск+спб+рег</p></td>
        </tr>
        <tr>
            <td><p>МГТС (МТС)</p></td>
            <td><p>ПАО "МТС"</p></td>
            <td><p>7740000076</p></td>
            <td><p>1027700149124</p></td>
            <td><p>мск+спб+рег</p></td>
        </tr>
        <tr>
            <td><p>Люби, Телинком</p></td>
            <td><p>ООО "Современные технологии"</p></td>
            <td><p>5047083837</p></td>
            <td><p>1075047006055</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Тушино Телеком</p></td>
            <td><p>ООО "МВМ Технолоджи"</p></td>
            <td><p>7733101404</p></td>
            <td><p>1037700071342</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>АйФлэт</p></td>
            <td><p>ООО "Юнионтел"</p></td>
            <td><p>7721344988</p></td>
            <td><p>1157746788561</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>ЮСИЭЙ Нетворкс</p></td>
            <td><p>ООО "Фастнет"</p></td>
            <td><p>7726525466</p></td>
            <td><p>1057747727993</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Тералайн (МСК)</p></td>
            <td><p>ООО "Тералайн Телеком"</p></td>
            <td><p>9709033531</p></td>
            <td><p>1187746602273</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Мифрил (МСК)</p></td>
            <td><p>ООО "Мифрил+"</p></td>
            <td><p>7714117938</p></td>
            <td><p>1037739063570</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>БизнесТелеком (МСК, МО, Регионы)</p></td>
            <td><p>ООО "Бизнес Телеком"</p></td>
            <td><p>7804558895</p></td>
            <td><p>1167847094370</p></td>
            <td><p>мск+рег</p></td>
        </tr>
        <tr>
            <td><p>Глобал Телеком (МСК)</p></td>
            <td><p>ООО «Глобал Телеком»</p></td>
            <td><p>7708317167</p></td>
            <td><p>1177746465731</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Филанко (МСК)</p></td>
            <td><p>ООО «ДОМОНЕТ»</p></td>
            <td><p>7726762643</p></td>
            <td><p>5147746428650</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Окнет (МО)</p></td>
            <td><p>ООО "Окнет"</p></td>
            <td><p>7709466066</p></td>
            <td><p>1157746767683</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>МТ6 (МО)</p></td>
            <td><p>ООО «Мой Телеком»</p></td>
            <td><p>9706025170</p></td>
            <td><p>1227700431925</p></td>
            <td><p>мск</p></td>
        </tr>
        <tr>
            <td><p>Мослайн (МСК, МО)</p></td>
            <td><p>ООО "Телеком 51"</p></td>
            <td><p>5006013774</p></td>
            <td><p>1095015001520</p></td>
            <td><p>мск</p></td>
        </tr>
    </tbody>
</table>
</div>`;

let table_Pol = `<div>
<table id="person">
    <thead>
        <tr>
            <th><p>Провайдер</p></th>
            <th><p>Юр. Лицо</p></th>
            <th><p>ИНН</p></th>
            <th><p>ОГРН</p></th>
            <th><p>регион</p></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td><p>ДомРу+В2В</p></td>
            <td><p>АО "ЭР-Телеком Холдинг"</p></td>
            <td><p>5902202276</p></td>
            <td><p>1065902028620</p></td>
            <td><p>мск+спб+рег</p></td>
        </tr>
        <tr>
            <td><p>Етелеком</p></td>
            <td><p>ООО "ЕТелеком"</p></td>
            <td><p>7802487790</p></td>
            <td><p>1099847015410</p></td>
            <td><p>спб</p></td>
        </tr>
        <tr>
            <td><p>Билайн (МСК, Регионы)</p></td>
            <td><p>ПАО "Вымпел - Коммуникации"</p></td>
            <td><p>7713076301</p></td>
            <td><p>1027700166636</p></td>
            <td><p>мск+спб+рег</p></td>
        </tr>
        <tr>
            <td><p>Скайнет</p></td>
            <td><p>ООО "Скайнет"</p></td>
            <td><p>7816223580</p></td>
            <td><p>1037835054190</p></td>
            <td><p>спб</p></td>
        </tr>
        <tr>
            <td><p>Мегафон</p></td>
            <td><p>ПАО "МЕГАФОН"</p></td>
            <td><p>7812014560</p></td>
            <td><p>1027809169585</p></td>
            <td><p>мск+спб+рег</p></td>
        </tr>
        <tr>
            <td><p>МГТС (МТС)</p></td>
            <td><p>ПАО "МТС"</p></td>
            <td><p>7740000076</p></td>
            <td><p>1027700149124</p></td>
            <td><p>мск+спб+рег</p></td>
        </tr>
        <tr>
            <td><p>Эт Хоум</p></td>
            <td><p>ООО "НЕВСКОЕ"</p></td>
            <td><p>7839446350</p></td>
            <td><p>1117847269946</p></td>
            <td><p>спб</p></td>
        </tr>
        <tr>
            <td><p>ТТК</p></td>
            <td><p>ООО "ТТК-Связь"</p></td>
            <td><p>7709362765</p></td>
            <td><p>1037739164451</p></td>
            <td><p>спб+рег</p></td>
        </tr>
        <tr>
            <td><p>Рнет</p></td>
            <td><p>ООО "Фишнет Коммюникейшнз"</p></td>
            <td><p>7811149131</p></td>
            <td><p>1047818005553</p></td>
            <td><p>спб</p></td>
        </tr>
        <tr>
            <td><p>Ситителеком</p></td>
            <td><p>ООО «Ситителеком СПб»</p></td>
            <td><p>7838067849</p></td>
            <td><p>1177847124840</p></td>
            <td><p>спб</p></td>
        </tr>
        <tr>
            <td><p>СатНет</p></td>
            <td><p>ООО "Компьютерные информационные технологии"</p></td>
            <td><p>7802313561</p></td>
            <td><p>1057810000390</p></td>
            <td><p>спб</p></td>
        </tr>
        <tr>
            <td><p>Етелеком (СПБ)</p></td>
            <td><p>АО "ЭЛЕКТРОНТЕЛЕКОМ"</p></td>
            <td><p>7802184161</p></td>
            <td><p>1037804006360</p></td>
            <td><p>спб</p></td>
        </tr>
        <tr>
            <td><p>Обит (СПБ)</p></td>
            <td><p>ООО "ОБИТ"</p></td>
            <td><p>7810204213</p></td>
            <td><p>1037821038860</p></td>
            <td><p>спб</p></td>
        </tr>
        <tr>
            <td><p>Филанко (СПБ+ЛО)</p></td>
            <td><p>ООО "Филанко СПБ"</p></td>
            <td><p>7838492138</p></td>
            <td><p>1137847244204</p></td>
            <td><p>спб</p></td>
        </tr>
    </tbody>
</table>
</div>`;

export const compilePrivatePart2 = (domain, shema, company, siteCode) => {
	return `<div>
<h2>
Согласие на обработку персональных данных, применяемое при направлении заявки в электронном виде в информационно-телекоммуникационной сети «Интернет» на сайте <a href="${shema}${domain}" target="_blank">${shema}${domain}</a>  в целях получения услуг ${company} (далее — «Согласие»)
</h2>
<p>Я, (далее — «Заявитель»), свободно, своей волей и в своем интересе даю конкретное, информированное и сознательное согласие ${company} (далее — «Оператор») на обработку информации, относящейся к моим персональным данным, в том числе: Ф.И.О.; год, месяц, дата, место рождения; гражданство; пол; данные документа, удостоверяющего личность (тип, серия, номер, кем и когда выдан), в том числе за пределами Российской Федерации, включая их копии; адрес: места жительства, места регистрации, места работы; ИНН; СНИЛС; сведения о номерах телефонов, абонентом и/или пользователем которых я являюсь; сведения об адресах электронной почты Заявителя, имени пользователя Заявителя в сети Интернет, данные о созданном на сайте Оператора аккаунте (учетной записи); метаданные, данные cookie-файлов, cookie-идентификаторы, IP-адреса, сведения о браузере и операционной системе; и любую иную информацию, представленную Оператору</p>
<p>Обработка персональных данных может осуществляться с использованием средств автоматизации или без таковых, а также путем смешанной обработки персональных данных, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, в том числе в информационных системах Оператора, и совершение иных действий, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».</p>
<p>Целями обработки персональных данных является:</p>
<ul>
<li>заключение с Оператором и третьими лицами любых договоров и соглашений;</li>
<li>проверка достоверности указанных Заявителем сведений;</li>
<li>получение персональных данных Заявителя из иных разрешенных источников;</li>
<li>подбор и предоставление Оператором услуг Заявителю;</li>
<li>предоставление Заявителю информации о результатах рассмотрения Оператором;</li>
<li>создание информационных систем данных, анализ, моделирование, прогнозирование, построение математических (скоринг) моделей, анализ агрегированных и анонимных данных, статистические и исследовательские цели;</li>
<li>хранение данных Оператором;</li>
<li>продвижение продуктов и услуг Оператора и/или третьих лиц, в том числе передача информационных и рекламных сообщений об услугах Оператора и/или третьих лиц путем осуществления прямых контактов с помощью средств связи и иным способом.</li>
</ul>
<p>В вышеуказанных целях Заявитель дает также согласие на обработку перечисленных персональных данных Оператору; передачу и поручение обработки третьим лицам, перечень которых размещен на сайте Оператора в таблице «Перечень третьих лиц» («Перечень третьих лиц, которым могут передаваться персональные данные Клиентов на основании согласий Клиентов и заключенных ими с Оператором договоров») (далее — «Третьи лица»).</p>
<p>Таблица «Перечень третьих лиц»</p>
</div>
${isMainSiteCode(siteCode) ? table_101 : ""}
${isMoscowSiteCode(siteCode) ? table_Mol : ""}
${isPiterSiteCode(siteCode) ? table_Pol : ""}
<div>
<p>Заявитель, являющийся пользователем абонентского номера, указанного в заявлении в электронном виде на сайте Оператора  (далее — «Пользователь»), в целях предоставления Оператором услуг выражает согласие операторам связи на обработку номеров телефонов, пользователем которых является Пользователь, сведений об оказанных операторами услугах связи (в том числе сведений о местонахождении абонентского оборудования при получении услуг связи, оплате оказанных услуг связи), сведений об идентификаторах абонентского оборудования и передачу указанных сведений или результата их обработки Оператору, в том числе организацию канала коммуникации Оператора с использованием телефонных номеров, используемых Пользователем.</p>
<p>Оператор осуществляет обработку персональных данных Заявителя в течение 3 (трех) лет со дня подачи настоящего Согласия. Согласие может быть отозвано субъектом персональных данных путем письменного заявления, направленного  Оператору  на электронный адрес: <a href="mailto:info@${domain}">info@${domain}</a>.</p>
</div>
`;
};
