var gql = require("@apollo/client").gql;

const STREET_INTER_LINK = gql`
	query STREET_INTER_LINK($street_id: Int!) {
		streetInterLink(street_id: $street_id) {
			data {
				id
				region_id
				name
				full
				suburb
				type
				avg_price
				avg_speed
				price_diff
				review {
					review
					date
					provider {
						name
					}
					author {
						name
					}
				}
				district {
					id
					parentId
					name
					is_city
					service
				}
				region {
					id
					name
					url
					is_capital
				}
				providers {
					id
					name
					logo
				}
			}
		}
	}
`;

module.exports = STREET_INTER_LINK;
