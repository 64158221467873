import React from "react";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";

export default class Mask extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasValue: false,
			isFocus: false,
		};
	}

	wasAutofilled = false;

	static propTypes = {
		dataTest: PropTypes.string,
		className: PropTypes.string.isRequired,
		value: PropTypes.string,
		changeCallback: PropTypes.func,
		onMount: PropTypes.func,
		onFocus: PropTypes.func,
		onEnter: PropTypes.func,
	};

	static defaultProps = {
		dataTest: "",
		className: "",
		changeCallback: () => {},
		onMount: () => {},
		onFocus: () => {},
		onEnter: () => {},
		onBlur: () => {},
	};

	onFocus() {
		this.focus = true;
		setTimeout(
			() =>
				this.setState(
					{
						isFocus: this.focus,
					},
					() =>
						this.changeHandler({
							target: {
								value: this.input.value,
							},
						})
				),
			0
		);
		this.props.onFocus();
	}

	onBlur() {
		this.focus = false;
		this.setState({
			isFocus: false,
		});
		this.props.onBlur();
	}

	changeHandler(e) {
		let value = e.target.value;
		value = this.tranformPhone(value);
		this.setState({
			hasValue: value.length !== 0,
		});
		this.props.changeCallback(value);
	}

	onKeyPress(e) {
		if (e.charCode === 13) {
			this.props.onEnter(e);
		}
	}

	tranformPhone(phone) {
		return phone.replace(/[.*+?^${}()\-\\ \_|[\]\\]/g, "");
	}

	beforeMaskedValueChange(newState, oldState, userInput) {
		let value = newState.value;
		let inputValue = this.input.value;
		//если пользователь что-то вставил в инпут
		let condition = this.state.isFocus ? userInput && userInput.length > 1 : false;
		let condition2 =
			this.tranformPhone(inputValue).length === 11 &&
			inputValue !== newState.value &&
			this.tranformPhone(inputValue) !== this.tranformPhone(newState.value);
		if (condition) {
			//еcли автофилл
			if (
				this.tranformPhone(oldState.value).length === 1 &&
				this.tranformPhone(inputValue).length === 11
			) {
				//вырезаем + либо 8
				value = inputValue.substring(1);
				//если была 8, то добавляем спереди 7
				if (value.length === 10) {
					value = "7" + value;
				}
			} else {
				//просто добавляем инпут к предыдущему
				value = this.tranformPhone(oldState.value) + userInput;
			}
		} else {
		}

		if (!this.state.isFocus) {
			if (condition2) {
				//вырезаем + либо 8
				value = inputValue.substring(1);
				//если была 8, то добавляем спереди 7
				if (value.length === 10) {
					value = "7" + value;
				}
			}
		}

		if (!condition && !condition2 && !this.wasAutofilled) {
			if (!!userInput && userInput.length === 1) {
				let phone = this.tranformPhone(inputValue);
				if (phone.length === 12 && (phone[1] === "8" || phone[1] === "7")) {
					value = phone.slice(0, 1) + phone.slice(2, phone.length);
					this.wasAutofilled = true;
				}
			}
		}

		if (
			oldState.value.length == newState.value.length &&
			newState.value.length == 18 &&
			oldState.value != newState.value
		) {
			value = newState.value;
			value = this.tranformPhone(newState.value);
			this.wasAutofilled = false;
		}
		if (value === "+7 (" || value === "") {
			this.wasAutofilled = false;
		}

		return {
			value: value,
			selection: newState.selection,
		};
	}

	render() {
		let options = {
			mask: this.state.isFocus || this.state.hasValue ? `+7 (999) 999-99-99` : "",
			type: "tel",
			maskChar: null,
			className: this.props.className,
			onChange: (e) => {
				this.changeHandler(e);
			},
			beforeMaskedValueChange: (newState, oldState, userInput) =>
				this.beforeMaskedValueChange(newState, oldState, userInput),
			onFocus: () => this.onFocus(),
			onKeyPress: (e) => this.onKeyPress(e),
			datatest: this.props.datatest,
			onBlur: () => this.onBlur(),
			autoComplete: this.props.autocomplete,
			name: this.props.name,
			id: this.props.id,
			autoCorrect: "off",
			placeholder: this.props.placeholder,
			inputRef: (input) => (this.input = input),
			autoFocus: this.props.autoFocus,
		};
		if (typeof this.props.value === "string") {
			options.value = this.props.value;
		}

		return <InputMask aria-labelledby={this.props.id} {...options} />;
	}
}
