module.exports = {
	typePolicies: {
		fieldProviderContacts: {
			merge: true,
		},
		fieldProviderInfo: {
			merge: true,
		},
		ProviderRating: {
			merge: true,
		},
		fieldTariffProviderRating: {
			merge: true,
		},
		fieldTariffPrice: {
			merge: true,
		},
		fieldTariffInternet: {
			merge: true,
		},
	},
};
