import React from "react";

export default class HideBeforeMount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			wasMount: false,
		};
	}

	componentDidMount() {
		if (!this.state.wasMount) {
			this.initComponent();
		}
	}

	initComponent() {
		this.setState({
			wasMount: true,
		});
	}

	render() {
		if (!this.state.wasMount) {
			return null;
		}
		return this.props.children;
	}
}
