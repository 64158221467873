import React from "react";
import { createUseStyles } from "react-jss";

import { pipe } from "Utils/functionalUtils";
import { observer, inject } from "mobx-react";
import { withRouter, useParams } from "react-router-dom";
import { generateUrl } from "Services/withDefaultParams";
import useDefaultParams from "Services/useDefaultParams";
import routes from "Configs/routes";

const useStyles = createUseStyles((theme) => ({
	download: {
		width: "100%",
		margin: "10px 0",
		display: "inline-block",
		color: `${theme.palette.colors.purplishBlue} !important`,
		textDecoration: "underline",
		fontSize: "16px",
		cursor: "pointer",
	},
}));

const CompilePrivate = ({
	domain,
	location,
	shema,
	company,
	address,
	siteCode101,
	part2,
	download,
	downloadPart2,
	handleClickDownloadUrl,
	otherInfoBoolean,
}) => {
	const classes = useStyles();
	const routeParams = useParams();
	const { regionUrl } = useDefaultParams(routeParams);

	let termsOfUseURL = generateUrl(routes.TermsOfUse.route, {
		regionUrl,
	});
	let privateText = `<h2>Назначение и область действия документа</h2>
<p> «Политика обработки персональных данных в Обществе ${company} (далее по тексту также – Общество)» (далее – Политика) определяет Политику Общества в отношении обработки персональных данных. </p>
<p>Обработка персональных данных, объем и содержание обрабатываемых персональных данных определяется в соответствии с Конституцией Российской Федерации, Федеральным законом № 152-ФЗ «О персональных данных», другими федеральными законами и подзаконными актами. </p>
<div>

<h2>1. Определения </h2>

<p>1.1. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (гражданину), т.е. к такой информации, в частности, можно отнести: ФИО, год, месяц, дату и место рождения, адрес, сведения о семейном, социальном, имущественном положении, сведения об образовании, профессии, доходах, сведения о состоянии здоровья, а также другую информацию.</p>
<p>1.2. Обработка персональных данных – любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемые с использованием средств автоматизации или без использования таких средств. К таким действиям (операциям) можно отнести: сбор, получение, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
</div>

<div>
<h2>2. Субъекты персональных данных</h2>

<p>2.1. К субъектам персональных данных относятся:</p>
<ul>
<li>работники Общества;</li>
<li>субъекты, с которыми заключены договоры гражданско-правового характера;</li>
<li>кандидаты на вакантные должности;</li>
<li>клиенты Общества (в том числе потенциальные клиенты, размещающие заявки на сайте);</li>
<li>зарегистрированные пользователи сайта;</li>
<li>представители контрагентов Общества, включая контактные лица контрагентов.</li>
</ul> 
</div>
<div>
<h2>3. Принципы, условия и цели обработки персональных данных </h2>

<p>3.1. Под безопасностью персональных данных Общество понимает защищенность персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных и принимает необходимые правовые, организационные и технические меры для защиты персональных данных.</p>
<p>3.2. Обработка и обеспечение безопасности персональных данных в Обществе осуществляется в соответствии с требованиями Конституции Российской Федерации, Федерального закона № 152-ФЗ «О персональных данных», подзаконных актов, других определяющих случаи и особенности обработки персональных данных федеральных законов Российской Федерации.</p>
<p>3.3. При обработке персональных данных Общество придерживается следующих принципов:</p>
<p>- ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей; </p>
<p>- недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;</p>
<p>- недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</p>
<p>- обработки персональных данных, которые отвечают целям их обработки;</p>
<p>- обеспечения точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных. Общество принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных или неточных данных;</p>
<p>- хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом. Порядок уничтожения персональных данных определяется Приложением № 2 к настоящей Политике. </p>
<p>3.4. Общество обрабатывает персональные данные только в следующих случаях:</p>
<p>- обработка персональных данных осуществляется на законной и справедливой основе; </p>
<p>- обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных; </p>
<p>- обработка персональных данных необходима для достижения целей, предусмотренных законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей;</p>
<p>- обработка персональных данных необходима для осуществления прав и законных интересов Общества или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;</p>
<p>- осуществляется обработка персональных данных совершеннолетних пользователей;</p>
<p>- осуществляется обработка персональных данных, доступ неограниченного круга лиц, к которым предоставлен субъектом персональных данных либо по его просьбе;</p>
<p>- осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.</p>
<p>3.5. Общество обрабатывает персональные данные в следующих целях:</p>
<p>- для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;</p>
<p>- предоставление посетителям сайта <a href="${shema}${domain}" target="_blank">${shema}${domain}</a> для подачи заявок на доступ к продуктам и сервисам;</p>
<p>- продвижение товаров, работ, услуг Общества на рынке путем осуществления прямых контактов с потенциальным потребителем (обратившимся с заявкой на сайт  <a href="${shema}${domain}" target="_blank">${shema}${domain}</a>) с помощью средств связи;</p>
<p>- подбор работников Общества и ведение кадрового учета работников Общества.</p>
<p>3.6. Способы обработки персональных данных – автоматизированная обработка персональных данных и неавтоматизированная обработка персональных данных.</p>
<p>3.7. Общество вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, на основании заключаемого с этим лицом договора, в том числе государственного или муниципального контракта, либо путем принятия государственным органом или муниципальным органом соответствующего акта.</p>
<p>3.8. Лица, осуществляющие обработку персональных данных по поручению Общества, обязуются соблюдать принципы и правила обработки и защиты персональных данных, предусмотренные Федеральным законом № 152-ФЗ «О персональных данных». Для каждого лица определены перечень действий (операций) с персональными данными, которые будут совершаться юридическим лицом, осуществляющим обработку персональных данных, цели обработки, установлена обязанность такого лица соблюдать конфиденциальность и обеспечивать безопасность персональных данных при их обработке, а также указаны требования к защите обрабатываемых персональных данных. </p>
<p>3.9. В случаях, установленных законодательством Российской Федерации, Общество вправе осуществлять передачу персональных данных граждан. </p>
<p>3.10. Общество уничтожает либо обезличивает персональные данные по достижении целей обработки или в случае утраты необходимости достижения цели обработки.</p>
<p>3.11. Общество не осуществляет обработку биометрических персональных данных (сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность).</p>
<p>3.12. Общество не выполняет обработку специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни.</p>
<p>3.13. Общество не производит трансграничную (на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу) передачу персональных данных.</p>
<p>3.14. Общество при обработке персональных данных принимает все необходимые правовые, организационные и технические меры для их защиты от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, предоставления, распространения, а также от иных неправомерных действий в отношении них. Обеспечение безопасности персональных данных достигается, в частности, следующими способами:</p>
<p>- осуществлением внутреннего контроля и соответствия обработки персональных данных Федеральному закону от 27.07.2006 № 152-ФЗ «О персональных данных» и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных;</p>
<p>- ознакомлением работников Общества, непосредственно осуществляющих обработку персональных данных, с положениями законодательства Российской Федерации о персональных данных, в том числе с требованиями к защите персональных данных, локальными актами в отношении обработки персональных данных и/или обучением указанных сотрудников;</p>
<p>- определением угроз безопасности персональных данных при их обработке в информационных системах персональных данных;</p>
<p>- применением организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных, необходимых для выполнения требований к защите персональных данных;</p>
<p>- оценкой эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы персональных данных;</p>
<p>- выявлением фактов несанкционированного доступа к персональным данным и принятием соответствующих мер;</p>
<p>- восстановлением персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</p>
<p>- контроль над принимаемыми мерами по обеспечению безопасности персональных данных и уровнем защищенности информационных систем персональных данных.</p>
<p>3.15. Объем и категории обрабатываемых персональных данных, категории субъектов персональных данных определены в Приложении № 1 к настоящей Политике.  </p>
</div>

<div>
<h2> 4. Права субъекта персональных данных</h2>

<p>4.1. Гражданин, персональные данные которого обрабатываются Обществом, имеет право получать от Общества:</p>
<p>- сведения об Обществе, о месте нахождения, о наличии у Общества сведений о персональных данных, относящихся к соответствующему Субъекту;</p>
<p>- перечень обрабатываемых персональных данных, относящихся к гражданину, от которого поступил запрос, и источник их получения, если иной порядок предоставления таких данных не предусмотрен федеральным законом;</p>
<p>- наименование и адрес лица, осуществляющего обработку персональных данных по поручению Общества;</p>
<p>- иные сведения, предусмотренные Федеральным законом «О персональных данных» № 152-ФЗ или другими федеральными законами.</p>
<p>4.2. Гражданин, персональные данные которого обрабатываются Обществом, имеет право:</p>
<p>- требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</p>
<p>- отозвать свое согласие на обработку персональных данных путем отправки обращения на почту <a href="mailto:info@${domain}">info@${domain}</a>;</p>
<p>- требовать устранения неправомерных действий Общества в отношении его персональных данных;</p>
<p>- обжаловать в уполномоченный орган по защите прав Субъектов персональных данных или в судебном порядке неправомерный действия или бездействие Общества при обработке и защите его персональных данных.</p>
</div>
<div>
<h2> 5. Ответственность </h2>

<p> 5.1. В случае неисполнения положений настоящей Политики Общество несет ответственность в соответствии с действующим законодательством Российской Федерации.</p>
</div>

<div>
<h2>6. Заключительные положения </h2>

<p>6.1 Получить разъяснения по интересующим вас вопросам обработки Ваших персональных данных вы можете, обратившись лично в Общество, направив официальный запрос по электронной почте по адресу: <a href="mailto:info@${domain}">info@${domain}</a>.</p>
<p>6.2 В случае направления официального запроса в Общество в тексте запроса необходимо указать:</p>
<p>- ФИО; </p>
<p>- номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе; </p>
<p>- сведения, подтверждающие ваше участие в отношениях с Обществом, либо сведения, иным способом подтверждающие факт обработки персональных данных Обществом;</p>
<p>- документ должен быть подписан лицом собственноручно или электронной подписью в соответствии с законодательством РФ.</p>
<p>Срок получения ответа не может превышать 10 рабочих дней с возможностью продления до 15 рабочих дней.</p>
<p>6.3. На сайте <a href="${shema}${domain}" target="_blank">${shema}${domain}</a> публикуется актуальная версия «Политики обработки персональных данных в Обществе». Действующая редакция Политики на бумажном носителе хранится по месту нахождения исполнительного органа Общества по адресу: ${address}.</p>  
<p>6.4. Настоящая Политика подлежит изменению, дополнению в случае появления новых законодательных актов и специальных нормативных документов по обработке и защите персональных данных.</p>
</div>
`;

	let additionPrivateText = `<div>
<h2 style="color: #787193; margin-top: 100px">ПРИЛОЖЕНИЕ №1 к «Политике обработки персональных данных в Обществе ${company}${
		siteCode101 ? "" : "»"
	}</h2>
<p>1. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки, предусмотренным в разд. 3.5. настоящей Политики.</p> 
<p>2. Общество может обрабатывать персональные данные следующих категорий субъектов персональных данных:</p>
</div>

<div>
<table id="disposal">
    <thead>
        <tr>
            <th><p>№</p></th>
            <th><p>Наименование категории категорий субъектов ПД</p></th>
            <th><p>Перечень персональных данных </p></th>
            <th><p>Способы, сроки обработки и хранения, порядок уничтожения ПД</p></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td><p>1.</p></td>
            <td><p>Кандидаты для приема на работу:</p></td>
            <td>
                <p>- фамилия, имя, отчество;</p>
                <p>- пол; </p>
                <p>- гражданство; </p>
                <p>- дата и место рождения; </p>
                <p>- контактные данные; </p>
                <p>- сведения об образовании, опыте работы, квалификации; </p>
                <p>- иные персональные данные, сообщаемые кандидатами в резюме и сопроводительных письмах. </p></td>
            <td>
                <p>- автоматизированная обработка персональных данных;  </p>
                <p>- неавтоматизированная обработка персональных данных.</p>
                <p>Срок обработки и хранения – в течение периода рассмотрения кандидатуры, а также в случае отказа в приеме на работу – в течение одного года.</p>
                <p>Уничтожение персональных данных производится в порядке, предусмотренном Приложением № 2 к настоящей Политике. </p>
            </td>
        </tr>
        <tr>
            <td><p>2.</p></td>
            <td><p>Работники и бывшие работники</p></td>
            <td>
                <p>- фамилия, имя, отчество; </p>
                <p>- пол; </p>
                <p>- дата и место рождения;</p> 
                <p>- сведения, содержащиеся в паспорте или ином документе, удостоверяющем личность;</p>
                <p>- адрес места жительства (регистрации, фактический);</p>
                <p>- ИНН;</p>
                <p>- данные СНИЛС (номер);</p> 
                <p>- номера телефонов (домашний, служебный, сотовый);</p> 
                <p>- информация о банковских счетах;</p> 
                <p>- анкетные данные, предоставленные при поступлении на работу или в процессе работы (в том числе – автобиография, сведения о семейном положении, составе семьи, перемене фамилии, наличии детей и иждивенцев);</p>
                <p>- сведения о воинском учете;</p> 
                <p>- сведения об образовании;</p>
                <p>- сведения, содержащиеся в трудовой книжке или в сведениях о трудовой деятельности, предоставляемые из информационных ресурсов Фонда пенсионного и социального страхования;</p> 
                <p>- данные о наличии или отсутствии судимости;</p> 
                <p>- сведения, дающие право на получение дополнительных социальных гарантий и льгот в соответствии с действующим законодательством;</p> 
                <p>- данные иных документов, которые с учетом специфики работы и в соответствии с законодательством Российской Федерации могут быть предъявлены при заключении трудового договора или в период его действия;</p>
                <p>- документы о состоянии здоровья (сведения об инвалидности, о наличии хронических заболеваний, медицинское заключение об отсутствии противопоказаний для работы в Организации);</p> 
                <p>- сведения о взысканиях (наказаниях).</p>
            </td>
            <td>
                <p>- автоматизированная обработка персональных данных; </p>
                <p>- неавтоматизированная обработка персональных данных.</p>
                <p>Срок обработки и хранения – в течение периода работы, а после прекращения трудовых отношений – в течение трех лет.</p>
                <p>Уничтожение производится в порядке, предусмотренном Приложением № 2 к настоящей Политике. </p>
            </td>
        </tr>
        <tr>
            <td><p>3.</p></td>
            <td><p>Лица, с которыми заключены договоры гражданско-правового характера</p></td>
            <td>
                <p>- фамилия, имя, отчество; </p>
                <p>- пол;</p> 
                <p>- дата и место рождения;</p> 
                <p>- сведения, содержащиеся в паспорте или ином документе, удостоверяющем личность;</p>
                <p>- адрес места жительства (регистрации, фактический);</p>
                <p>- ИНН;</p>
                <p>- данные СНИЛС (номер);</p> 
                <p>- номера телефонов (домашний, служебный, сотовый);</p> 
                <p>- информация о банковских счетах;</p> 
                <p>- сведения об образовании;</p>
                <p>- сведения, содержащиеся в трудовой книжке или в сведениях о трудовой деятельности, предоставляемые из информационных ресурсов Фонда пенсионного и социального страхования.</p>
            </td>
            <td>
                <p>- автоматизированная обработка персональных данных;  </p>
                <p>- неавтоматизированная обработка персональных данных.</p>
                <p>Срок обработки и хранения составляет три года.</p>
                <p>Уничтожение персональных данных производится в порядке, предусмотренном Приложением № 2 к настоящей Политике. </p>
            </td>
        </tr>
        <tr>
            <td><p>4.</p></td>
            <td><p>Клиенты, в том числе потенциальные клиенты, разместившие заявку на сайте Общества, а также контрагенты Общества</p></td>
            <td>
                <p>- фамилия, имя, отчество; </p>
                <p>- пол;</p> 
                <p>- дата и место рождения;</p> 
                <p>- сведения, содержащиеся в паспорте или ином документе, удостоверяющем личность;</p>
                <p>- адрес места жительства (регистрации, фактический);</p>
                <p>- ИНН;</p>
                <p>- данные СНИЛС (номер);</p> 
                <p>- номера телефонов (домашний, служебный, сотовый);</p>
                <p>- иные персональные данные, предоставляемые клиентами и контрагентами (физическими лицами), необходимые для заключения и исполнения договоров.</p>
            </td>
            <td>
                <p>- автоматизированная обработка персональных данных;</p>  
                <p>- неавтоматизированная обработка персональных данных.</p>
                <p>Срок обработки и хранения составляет три года.</p> 
                <p>Уничтожение персональных данных производится в порядке, предусмотренном Приложением № 2 к настоящей Политике. </p>
            </td>
        </tr>
    </tbody>
</table>
</div>

<div>
<h2 style="color: #787193; margin-top: 100px">ПРИЛОЖЕНИЕ №2 к «Политике обработки персональных данных в Обществе ${company}${
		siteCode101 ? "" : "»"
	}</h2>
<h2>1. Правила уничтожения носителей, содержащих персональные данные</h2>

<p>1.1. Уничтожение носителей, содержащих персональные данные субъектов персональных данных, должно соответствовать следующим правилам:</p>
<p>– быть конфиденциальным, исключая возможность последующего восстановления;</p>
<p>– оформляться юридически, в частности, актом о выделении документов, содержащих персональные данные субъектов персональных данных, к уничтожению и актом об уничтожении носителей, содержащих персональные данные субъектов персональных данных;</p>
<p>– должно проводиться комиссией по уничтожению персональных данных;</p>
<p>– уничтожение должно касаться только тех носителей, содержащих персональные данные субъектов персональных данных, которые подлежат уничтожению в связи с истечением срока хранения, достижением цели обработки указанных персональных данных либо утратой необходимости в их достижении, не допуская случайного или преднамеренного уничтожения актуальных носителей.</p>

<h2>2. Порядок уничтожения носителей, содержащих персональные данные</h2>

<p>2.1. Персональные данные субъектов персональных данных хранятся не дольше, чем этого требуют цели их обработки, и подлежат уничтожению по истечении срока хранения, достижении целей обработки или в случае утраты необходимости в их достижении.</p>
<p>2.2. Носители, содержащие персональные данные субъектов персональных данных, уничтожаются в специально отведенном для этих целей помещении комиссией по уничтожению персональных данных, утвержденной приказом генерального директора Общества (далее – Комиссия).</p>
<p>2.3. Носители, содержащие персональные данные субъектов персональных данных, уничтожаются Комиссией в срок, не превышающий 30 дней с даты истечения срока хранения, достижения цели обработки персональных данных либо утраты необходимости в их достижении.</p>
<p>2.4. Комиссия производит отбор бумажных носителей персональных данных, подлежащих уничтожению, с указанием оснований для уничтожения.</p>
<p>2.5. На все отобранные к уничтожению документы составляется акт о выделении документов, содержащих персональные данные субъектов персональных данных, к уничтожению.</p>
<p>2.6. В актах о выделении документов, содержащих персональные данные субъектов персональных данных, к уничтожению и актах об уничтожении носителей, содержащих персональные данные субъектов персональных данных, исправления не допускаются.</p>
<p>2.7. Комиссия проверяет наличие всех документов, включенных в акт о выделении носителей, содержащих персональные данные субъектов персональных данных, к уничтожению.</p>
<p>2.8. По окончании сверки акт о выделении документов, содержащих персональные данные субъектов персональных данных, к уничтожению подписывается всеми членами Комиссии и утверждается проректором по направлению.</p>
<p>2.9. Носители, содержащие персональные данные субъектов персональных данных, отобранные для уничтожения и включенные в акт, после проверки их Комиссией передаются ответственному за уничтожение документов в помещение отдела кадров.</p>
<p>2.10. Уничтожение носителей, содержащих персональные данные субъектов персональных данных, производится после утверждения акта в присутствии всех членов Комиссии, которые несут персональную ответственность за правильность и полноту уничтожения перечисленных в акте носителей.</p>
<p>2.11. Уничтожение персональных данных, если это допускается материальным носителем, может производиться способом, исключающим дальнейшую обработку этих персональных данных с сохранением возможности обработки иных данных, зафиксированных на материальном носителе (удаление, вымарывание).</p>
<p>2.12. Уничтожение носителей, содержащих персональные данные, осуществляется в следующем порядке:</p>
<p>– уничтожение персональных данных, содержащихся на бумажных носителях, осуществляется путем измельчения на мелкие части, исключающие возможность последующего восстановления информации. Измельчение осуществляется с использованием шредера (уничтожителя документов);</p>
<p>– уничтожение персональных данных, содержащихся на машиночитаемых носителях, осуществляется путем нанесения им неустранимого физического повреждения, исключающего возможность их использования, а также восстановления данных. Вышеуказанное достигается путем деформирования, нарушения единой целостности носителя;</p>
<p>– подлежащие уничтожению файлы с персональными данными субъектов персональных данных, расположенные на жестком диске, удаляются средствами операционной системы компьютера с последующим «очищением корзины»;</p>
<p>– в случае допустимости повторного использования носителя CD-RW, DVD-RW применяется программное удаление («затирание») содержимого диска путем его форматирования с последующей записью новой информации на данный носитель.</p>
</div>

<div>
<h2>3. Порядок оформления документов об уничтожении персональных данных</h2>

<p>3.1. Об уничтожении носителей, содержащих персональные данные, Комиссия составляет и подписывает акт об уничтожении носителей, содержащих персональные данные субъектов персональных данных, который утверждается директором Общества.</p>
<p>3.2. Акт об уничтожении носителей, содержащих персональные данные субъектов персональных данных, составляется по установленной форме.</p>
<p>В акте указываются:</p>
<p>– дата, место и время уничтожения;</p>
<p>– должности, фамилии, инициалы членов Комиссии;</p>
<p>– вид и количество уничтожаемых носителей, содержащих персональные данные субъектов персональных данных;</p>
<p>– основание для уничтожения;</p>
<p>– способ уничтожения.</p>
<p>3.3. Факт уничтожения носителей, содержащих персональные данные субъектов персональных данных, фиксируется в журнале учета документов, переданных на уничтожение. Данный документ является документом конфиденциального характера и вместе с актами хранится в помещении Общества в течение одного года. По истечении срока хранения акт о выделении документов, содержащих персональные данные субъектов персональных данных, к уничтожению и акт об уничтожении носителей, содержащих персональные данные субъектов персональных данных, передаются в архив Общества на хранение.</p>
</div>`;

	let otherInfo = `<div>
    <h2>Прочая информация</h2>
    <p>Программа для ЭВМ «101 Интернет Ру» распространяется в виде интернет-сервиса, специальные действия по установке Программы для ЭВМ «101 Интернет Ру» на стороне пользователя не требуются.</p>
    <p>Стоимость услуг, оказываемых с помощью Программы для ЭВМ «101 Интернет Ру», рассчитывается индивидуально. Для уточнения стоимости услуг просим обращаться к нашим специалистам: email: <a href="mailto:info@${domain}">info@${domain}</a></p>
    <p>Компания осуществляет деятельность в области информационных технологий по оказанию услуг размещения предложений (объявлений) о реализации услуг Интернет-провайдеров.</p>
</div>`;

	return (
		<>
			<div
				dangerouslySetInnerHTML={{
					__html: privateText,
				}}
			/>
			{otherInfoBoolean && (
				<div
					dangerouslySetInnerHTML={{
						__html: otherInfo,
					}}
				/>
			)}
			{(!!download && !!downloadPart2 && (
				<div id="processing">
					{download.map((elem, index) => {
						return (
							<div
								className={classes.download}
								key={index}
								onClick={() => handleClickDownloadUrl(elem)}
							>
								{elem.name}
							</div>
						);
					})}
					<h2>Другие документы</h2>
					<a className={classes.download} href={termsOfUseURL}>
						Пользовательское соглашение
					</a>
					{downloadPart2.map((elem, index) => {
						return (
							<div
								className={classes.download}
								key={index}
								onClick={() => handleClickDownloadUrl(elem)}
							>
								{elem.name}
							</div>
						);
					})}
				</div>
			)) || (
				<div
					dangerouslySetInnerHTML={{
						__html: additionPrivateText,
					}}
				/>
			)}

			{/*{!!part2 && !download && (*/}
			{/*    <div*/}
			{/*        id="processing"*/}
			{/*        dangerouslySetInnerHTML={{*/}
			{/*            __html: part2,*/}
			{/*        }}*/}
			{/*    />*/}
			{/*)}*/}
		</>
	);
};

export default pipe(observer, inject("ApplicationModel"), withRouter)(CompilePrivate);
