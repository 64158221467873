import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			position: "relative",
			cursor: "pointer",
			backgroundColor: theme.palette.colors.purplishBlue,
			transition: "background-color .3s ease",
			height: "50px",
			padding: "0 10px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "10px",
		},
		wrapper: {
			display: "flex",
			alignItems: "center",
		},
		icon: {
			marginRight: "20px",
			"& span": {
				fontSize: "24px",
				color: theme.palette.colors.white,
				transition: "color .3s ease",
			},
		},
		text: {
			fontFamily: theme.fonts.bold,
			fontSize: "14px",
			textTransform: "uppercase",
			lineHeight: 1,
			letterSpacing: "2px",
			color: theme.palette.colors.white,
			transition: "color .3s ease",
		},
		passiveTheme: {
			backgroundColor: theme.palette.colors.transparent,
			"& $icon": {
				"& span": {
					color: theme.palette.colors.purplishBlue,
				},
			},
			"& $text": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		active: {
			backgroundColor: theme.palette.colors.oneMoreGrey,
		},
		flashing: {
			"& span": {
				animation: "typing 4s steps(12) infinite",
			},
		},
		heartbeat: {
			animation: "heartbeat 1.6s infinite",
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				padding: "0 20px",
			},
			icon: {
				marginRight: "15px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				height: "40px",
			},
			text: {
				// display: "none"
				fontSize: "12px",
			},
			icon: {
				marginRight: "10px",
				"& span": {
					fontSize: "20px",
				},
			},
		},
	};
};

class MenuWidget extends React.Component {
	static defaultProps = {
		upperPart: null,
		bottomPart: null,
	};

	render() {
		let classes = this.props.classes;
		return (
			<div
				datatest="button_upper_compare_tariffs"
				className={classNames(classes.root, {
					[classes.passiveTheme]: this.props.passiveTheme && !this.props.active,
					[classes.active]: this.props.active,
					[classes.heartbeat]: this.props.heartbeat,
				})}
				onClick={this.props.onClick}
			>
				<div className={classes.wrapper}>
					<div
						className={classNames(classes.icon, {
							[classes.flashing]: this.props.flashing,
						})}
					>
						{this.props.icon}
					</div>
					<p className={classes.text}>{this.props.text}</p>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(MenuWidget);
