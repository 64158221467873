import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";
import { BackgroundImage } from "ComponentsUI/BackgroundImage";
import { webpSimpleTransformer } from "Helpers/ImageManager";

const styles = (theme) => {
	return {
		root: {
			backgroundColor: theme.palette.colors.paleGrey,
			position: "relative",
			marginBottom: "60px",
		},
		rootWithoutMargin: {
			marginBottom: 0,
		},
		wrapper: {
			height: "100vh",
			minHeight: "720px",
			maxHeight: "2000px",
			paddingTop: "166px",
			backgroundColor: "#162043",
			position: "relative",
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			wrapper: {
				minHeight: "770px",
				paddingTop: "180px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				marginBottom: "30px",
			},
			wrapper: {
				minHeight: "740px",
				paddingTop: "160px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				marginBottom: "20px",
			},
			wrapper: {
				minHeight: "620px",
				paddingTop: "60px",
			},
		},
	};
};

class HeroBanner extends React.Component {
	static defaultProps = {
		slides: [],
		middleComponent: null,
		bottomComponent: null,
	};

	render() {
		let classes = this.props.classes;

		return (
			<div
				className={classNames(classes.root, {
					[classes.rootWithoutMargin]: this.props.withoutMargin,
				})}
			>
				<div
					className={classes.wrapper}
					datatest={this.props.datatest}
					ref={(wrapper) => (this.wrapper = wrapper)}
				>
					{this.props.background && (
						<BackgroundImage
							windowSizes={this.props.windowSizes}
							zIndexZero={true}
							background={this.props.background}
							webpTransformer={webpSimpleTransformer}
						/>
					)}
					{this.props.content}
				</div>
				{this.props.bottomComponent}
			</div>
		);
	}
}

export default withStyles(styles)(HeroBanner);
