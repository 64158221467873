import React from "react";
import { Link } from "react-router-dom";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import Container from "reactstrap/lib/Container";
import { isMoscowSiteCode, isMainSiteCode, isPiterSiteCode } from "Utils/siteConfig";
import ProviderConnectWithPhoneHOC from "HOC/ProviderConnectWithPhoneHOC";
import {
	SVGInternet101Blue,
	SVGMoskvaOnlineBlue,
	SVGPiterOnlineBlue,
	SVGFooterVkLogo,
	SVGFooterOkLogo,
	SVGFooterDzenLogo,
} from "Constants/SVG";
import { FooterNavBarContainer } from "Containers/Footer";
import { ConsultationPhone } from "ComponentsUIV2/Phones";
import FooterIcon from "../FooterIcon";

const useStyles = createUseStyles((theme) => ({
	root: {
		paddingTop: "24px",
		paddingBottom: "26px",
		background: theme.palette.colors.white,
	},
	firstPart: {
		marginBottom: "16px",
		display: "flex",
		flexWrap: "wrap",
	},
	logo: {
		width: "50%",
		marginBottom: "30px",
		"& svg": {
			height: "38px",
			width: "190px",
		},
	},
	consultation: {
		width: "50%",
		display: "flex",
		justifyContent: "flex-end",
		marginBottom: "30px",
	},
	cityText: {
		fontSize: "12px",
		lineHeight: "16px",
		fontFamily: "Inter-Regular",
		fontWeight: "400",
		color: "#6D7482",
	},
	secondPart: {
		display: "flex",
		borderTop: "1px solid #EDF0F5",
	},
	copyright: {
		width: "50%",
		marginTop: "16px",
		display: "flex",
		flexDirection: "column",
	},
	footerText: {
		fontSize: "12px",
		lineHeight: "16px",
		fontFamily: "Inter-Regular",
		fontWeight: "400",
		color: "#6D7482",
	},
	copyrightText: {
		marginBottom: "16px",
	},
	dateText: {
		marginBottom: "12px",
		display: "block",
	},
	socials: {
		width: "50%",
		marginTop: "16px",
		marginBottom: "16px",
		display: "flex",
		justifyContent: "flex-end",
	},
	socialLinksList: {
		height: "36px",
		margin: "0",
		paddingLeft: "0",
		listStyle: "none",
		display: "flex",
		justifyContent: "flex-end",
		gap: "12px",
	},
	requisitesList: {
		height: "40px",
		width: "600px",
		margin: "0",
		paddingLeft: "0",
		listStyle: "none",
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
	},
	requisitesItem: {
		width: "50%",
	},
	socialsWrapper: {},
	[`@media (max-width: ${theme.media.md})`]: {
		firstPart: {
			flexDirection: "column",
			alignItems: "center",
		},
		logo: {
			width: "auto",
			marginBottom: "24px",
			order: "1",
			"& svg": {
				height: "32px",
				width: "160px",
			},
		},
		consultation: {
			width: "auto",
			marginBottom: "0",
			order: "3",
		},
		cityText: {
			display: "flex",
			justifyContent: "center",
			fontSize: "10px",
			lineHeight: "12px",
		},
		footerText: {
			fontSize: "10px",
			lineHeight: "12px",
		},
		secondPart: {
			flexDirection: "column",
		},
		copyright: {
			width: "100%",
			marginTop: "0",
			marginBottom: "10px",
			order: 2,
		},
		socials: {
			width: "100%",
			marginBottom: "0",
			order: 1,
		},
		socialsWrapper: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
		},
		socialLinksList: {
			height: "42px",
			justifyContent: "center",
			marginBottom: "16px",
			order: 1,
		},
		dateText: {
			order: 2,
		},
		requisitesList: {
			height: "70px",
			width: "200px",
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		requisitesItem: {
			width: "100%",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		socialLinksList: {
			justifyContent: "flex-end",
		},
	},
}));

const FooterBottomPart = (props) => {
	const classes = useStyles();
	const {
		siteCode,
		currentRegion,
		currentPhone,
		currentYear,
		currentMonth,
		currentDay,
		defaultUrl,
		is404Layout,
		path,
		text,
		windowSizes,
	} = props;

	let currentRegionPhone = currentRegion ? currentPhone : text.phone_for_none_region_page;
	let visiblePhone = is404Layout ? text.phone_for_404_page : currentRegionPhone;

	return (
		<footer className={classes.root} itemScope itemType="http://schema.org/WPFooter">
			<Container>
				<div className={classes.firstPart}>
					<div className={classes.logo}>
						{isMainSiteCode(siteCode) && (
							<Link to={defaultUrl} aria-label="Главная">
								{SVGInternet101Blue}
							</Link>
						)}
						{isMoscowSiteCode(siteCode) && (
							<Link to={defaultUrl} aria-label="Главная">
								{SVGMoskvaOnlineBlue}
							</Link>
						)}
						{isPiterSiteCode(siteCode) && (
							<Link to={defaultUrl} aria-label="Главная">
								{SVGPiterOnlineBlue}
							</Link>
						)}
					</div>
					<div className={classes.consultation}>
						<div>
							<ProviderConnectWithPhoneHOC>
								<ConsultationPhone
									windowSizes={windowSizes}
									phoneNumber={visiblePhone}
									path={path}
								/>
							</ProviderConnectWithPhoneHOC>
							<span className={classes.cityText}>{currentRegion}</span>
						</div>
					</div>
					<FooterNavBarContainer text={text} siteCode={siteCode} className={classes.linksList} />
				</div>
				<div className={classes.secondPart}>
					<div className={classes.copyright}>
						{isMainSiteCode(siteCode) && (
							<>
								<meta itemProp="copyrightYear" content="2008"></meta>
								<meta itemProp="copyrightHolder" content="101 Интернет"></meta>
								<span
									className={cn(classes.footerText, classes.copyrightText)}
								>{`© 2008-${currentYear} «101 Интернет» — поиск провайдеров по адресу`}</span>
								<ul className={classes.requisitesList}>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ООО "ИНТЕРНЕТ ПРОМОУШЕН"`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`e-mail: info@101internet.ru`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ИНН: 6234200687`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ОГРН: 1226200005899`}</span>
									</li>
								</ul>
							</>
						)}
						{isMoscowSiteCode(siteCode) && (
							<>
								<meta itemProp="copyrightYear" content="2008"></meta>
								<meta itemProp="copyrightHolder" content="Москва Онлайн"></meta>
								<span
									className={cn(classes.footerText, classes.copyrightText)}
								>{`© 2008-${currentYear} «Москва Онлайн» — поиск провайдеров по адресу`}</span>
								<ul className={classes.requisitesList}>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ООО "Онлайн Сервисы"`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`e-mail: info@moskvaonline.ru`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ИНН: 6234184650`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ОГРНИП: 1196234008915`}</span>
									</li>
								</ul>
							</>
						)}
						{isPiterSiteCode(siteCode) && (
							<>
								<meta itemProp="copyrightYear" content="2008"></meta>
								<meta itemProp="copyrightHolder" content="Питер Онлайн"></meta>
								<span
									className={cn(classes.footerText, classes.copyrightText)}
								>{`© 2008-${currentYear} «Питер Онлайн» — поиск провайдеров по адресу`}</span>
								<ul className={classes.requisitesList}>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ИП Рубцов Дмитрий Сергеевич`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`e-mail: pr@piter-online.net`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ИНН: 623017935007`}</span>
									</li>
									<li className={classes.requisitesItem}>
										<span className={classes.footerText}>{`ОГРНИП: 319623400010678`}</span>
									</li>
								</ul>
							</>
						)}
					</div>
					<div className={classes.socials}>
						<div className={classes.socialsWrapper}>
							<span
								className={cn(classes.footerText, classes.dateText)}
							>{`Последнее обновление ${currentDay}.${currentMonth}.${currentYear}`}</span>
							{isMainSiteCode(siteCode) && (
								<ul className={classes.socialLinksList}>
									<li>
										<FooterIcon
											svgComponent={SVGFooterVkLogo}
											href={"https://vk.com/ru101internet"}
										/>
									</li>
									<li>
										<FooterIcon
											svgComponent={SVGFooterOkLogo}
											href={"https://ok.ru/group/51961592610882"}
										/>
									</li>
									<li>
										<FooterIcon
											svgComponent={SVGFooterDzenLogo}
											href={"https://dzen.ru/id/5ae1dd2455876b8b5f233540"}
										/>
									</li>
								</ul>
							)}
							{isMoscowSiteCode(siteCode) && (
								<ul className={classes.socialLinksList}>
									<li>
										<FooterIcon
											svgComponent={SVGFooterVkLogo}
											href={"https://vk.com/moskvaonlineru"}
										/>
									</li>
									<li>
										<FooterIcon
											svgComponent={SVGFooterOkLogo}
											href={"https://ok.ru/group/53245467426882"}
										/>
									</li>
									<li>
										<FooterIcon
											svgComponent={SVGFooterDzenLogo}
											href={"https://dzen.ru/id/5ae1dd2455876b8b5f233540"}
										/>
									</li>
								</ul>
							)}
							{isPiterSiteCode(siteCode) && (
								<ul className={classes.socialLinksList}>
									<li>
										<FooterIcon
											svgComponent={SVGFooterVkLogo}
											href={"https://vk.com/spbonlinerf"}
										/>
									</li>
									<li>
										<FooterIcon
											svgComponent={SVGFooterOkLogo}
											href={"https://ok.ru/group/53245494165570"}
										/>
									</li>
									<li>
										<FooterIcon
											svgComponent={SVGFooterDzenLogo}
											href={"https://dzen.ru/id/5ae1dd2455876b8b5f233540"}
										/>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</Container>
		</footer>
	);
};

export default FooterBottomPart;
