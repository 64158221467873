import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";
import { sendMetriks } from "Services/sendMetriks";
import { Tooltip, TooltipHeader, TooltipBody } from "ComponentsUI/Tooltip";
import { SVGWiFi, SVGTv, SVGInfo, SVGConnect, SVGActions } from "Constants/SVG";
import Cookies from "js-cookie";

const styles = (theme) => ({
	root: {
		width: "100%",
	},
	wrapper: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		justifyContent: "center",
	},
	svgItem: {
		"& svg": {
			width: "24px",
			height: "24px",
			fill: theme.palette.colors.cloudyBlue,
		},
	},
	svgTV: {
		marginTop: "5px",
	},
	itemNotExist: {
		"& svg": {
			fill: theme.palette.colors.cloudyBlue,
			"& .cls-1": {
				fill: theme.palette.colors.cloudyBlue,
			},
		},
	},
	itemYetInclude: {
		"& svg": {
			fill: "#23c4a0",
			"& .cls-1": {
				fill: "#23c4a0",
			},
		},
	},
	itemMayBeInclude: {
		"& svg": {
			fill: theme.palette.colors.purplishBlue,
			"& .cls-1": {
				fill: theme.palette.colors.purplishBlue,
			},
		},
	},
	connect: {
		"& svg": {
			fill: "#23c4a0",
			"& .cls-1": {
				fill: "#23c4a0",
			},
		},
	},
	itemWrapper: {
		marginRight: "5px",
		marginLeft: "5px",
		marginBottom: "5px",
		display: "block",
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		wrapper: {
			justifyContent: "flex-start",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		wrapper: {
			justifyContent: "center",
		},
		svgItem: {
			"& svg": {
				width: "20px",
				height: "20px",
			},
		},
		itemWrapper: {
			marginRight: "4px",
			marginLeft: "4px",
			marginBottom: "0",
			display: "block",
		},
	},
});

class DodysList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			metriks: true,
		};
	}

	generateDodysWrapper() {
		const { items, classes } = this.props;

		if (items) {
			return <div className={classNames(classes.wrapper)}>{this.renderDodyList(items)}</div>;
		} else {
			return null;
		}
	}

	preventBehavior(e) {
		let Set_Click_Dodo = Cookies.get("Set_click_dodo");
		e.stopPropagation();
		if (this.state.metriks && Set_Click_Dodo !== "true") {
			sendMetriks("CLICK_DUDO");
			Cookies.set("Set_click_dodo", "true");
			this.setState({
				metriks: false,
			});
		}
	}

	renderDodyList(items) {
		let classes = this.props.classes;
		return items.map((item, index) => {
			let textTooltip =
				index === 3
					? `${this.props.connectText} <br> ${this.props.prepaidText}`
					: item.short_description || "";
			textTooltip = <div dangerouslySetInnerHTML={{ __html: textTooltip }} />;
			return (
				<div
					key={index}
					className={classNames(classes.svgItem, {
						[classes.itemNotExist]: !item.state,
						[classes.itemYetInclude]: item.state === 1,
						[classes.itemMayBeInclude]: item.state === 2,
						[classes.connect]: index === 3,
					})}
				>
					<Tooltip
						header={
							<TooltipHeader text={index === 3 ? this.props.texts.servicesConnect : item.name} />
						}
						body={<TooltipBody>{textTooltip}</TooltipBody>}
						openClick={true}
						windowSizeModel={this.props.windowSizeModel}
					>
						<div className={classes.itemWrapper}>
							{index === 0 && SVGWiFi}
							{index === 1 && SVGTv}
							{index === 2 && SVGInfo}
							{index === 3 && SVGConnect}
							{index === 4 && SVGActions}
						</div>
					</Tooltip>
				</div>
			);
		});
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root} onClick={(e) => this.preventBehavior(e)}>
				{this.generateDodysWrapper()}
			</div>
		);
	}
}

export default withStyles(styles)(DodysList);
