import React, { useMemo } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { pipe } from "Utils/functionalUtils";
import { useSeo } from "hooks/useSeo";

const SeoBlockHOC = (props) => {
	let { ApplicationModel, route, placeText } = props;

	if (!route || !placeText) {
		return props.children;
	}

	let seoData = useSeo(route, ApplicationModel);

	const seoBlock = useMemo(() => {
		if (!seoData) {
			return undefined;
		}
		return seoData.seoBlock?.find((elem) => elem.place === placeText);
	}, [seoData]);

	return React.cloneElement(props.children, {
		seoBlock: seoBlock || {},
	});
};

SeoBlockHOC.propTypes = {
	route: PropTypes.object.isRequired,
	placeText: PropTypes.string.isRequired,
};

export default pipe(observer, inject("ApplicationModel"))(SeoBlockHOC);
