import React from "react";
import withStyles from "react-jss";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import classNames from "classnames";
import Metrika from "Services/Metrika";
import { YANDEX_TARGETS } from "Constants/metriks/yandex-targets";

const styles = (theme) => {
	return {
		root: {
			paddingLeft: "26px",
			paddingTop: "32px",
			border: "1px solid #D8D8D8",
			borderRadius: "10px",
			height: "430px",
			backgroundColor: "#FFFFFF",
			overflowY: "auto",
			"&::-webkit-scrollbar": {
				width: "12px",
				"-webkit-appearance": "none",
			},
			"&::-webkit-scrollbar-thumb": {
				backgroundColor: "#5813FE",
				borderRadius: "10px",
				height: "90px",
			},
			"&::-webkit-scrollbar-track": {
				backgroundColor: "#D2CEE0",
				borderRadius: "10px",
				marginTop: "30px",
				marginBottom: "30px",
			},
		},
		header: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			paddingBottom: "65px",
		},
		headerMain: {
			fontFamily: theme.fonts.bold,
			fontSize: "24px",
			lineHeight: 0.75,
			color: theme.palette.colors.addressBlackHeader,
		},
		headerMainAlt: {
			fontFamily: theme.fonts.bold,
			fontSize: "24px",
			lineHeight: 0.75,
			color: "#2b1d5d",
		},
		headerSecondary: {
			fontFamily: theme.fonts.bold,
			fontSize: "16px",
			lineHeight: 1,
			letterSpacing: "2.3px",
			color: theme.palette.colors.purplishBlue,
			textTransform: "uppercase",
			cursor: "pointer",
		},
		list: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "flex-start",
			flexWrap: "wrap",
			marginRight: "-20px",
		},
		col: {
			width: "180px",
			marginBottom: "45px",
			marginRight: "20px",
		},
		colHeader: {
			fontFamily: theme.fonts.bold,
			fontSize: "16px",
			lineHeight: 1,
			color: theme.palette.colors.darkSlateBlue,
			marginBottom: "21px",
		},
		colItem: {
			"& a": {
				fontFamily: theme.fonts.medium,
				fontSize: "16px",
				lineHeight: 1.71,
				color: "#5813FE",
				textOverflow: "ellipsis",
				overflow: "hidden",
				cursor: "pointer",
			},
		},
		alphabet: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "flex-start",
			flexWrap: "wrap",
		},
		alphabetCol: {
			width: "100%",
			marginBottom: 0,
		},
		alphabetBlock: {
			display: "flex",
			flexWrap: "wrap",
			width: "100%",
			position: "relative",
			marginBottom: "12px",
			paddingLeft: "30px",
		},
		alphabetColHeader: {
			position: "absolute",
			top: "4px",
			left: 0,
			fontFamily: theme.fonts.bold,
			fontSize: "14px",
			lineHeight: 1.14,
			color: theme.palette.colors.darkSlateBlue,
		},
		alphabetColItem: {
			marginBottom: "10px",
			width: "275px",
			height: "auto",
			"& a": {
				fontFamily: theme.fonts.medium,
				fontSize: "16px",
				lineHeight: 1.14,
				color: "#5813FE",
				textOverflow: "ellipsis",
				overflow: "hidden",
				cursor: "pointer",
			},
		},
		footer: {
			display: "flex",
			justifyContent: "flex-start",
			paddingBottom: "70px",
			paddingTop: "30px",
			alignSelf: "flex-end",
			width: "100%",
		},
		footerItem: {
			fontFamily: theme.fonts.medium,
			fontSize: "16px",
			lineHeight: 1.13,
			color: theme.palette.colors.oneMoreGrey,
			marginRight: "25px",
			textTransform: "uppercase",
			cursor: "pointer",
		},
		footerItemAll: {
			margin: "0 auto",
		},
		isActive: {
			color: theme.palette.colors.purplishBlue,
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			root: {
				paddingLeft: "26px",
			},
			col: {
				width: "220px",
				marginBottom: "35px",
			},
			alphabetCol: {
				width: "100%",
			},
			alphabetColItem: {
				width: "215px",
			},
			footerItem: {
				marginRight: "20px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				paddingRight: "26px",
				marginBottom: "50px",
			},
			header: {
				paddingBottom: "50px",
			},
			headerMain: {
				fontSize: "18px",
				lineHeight: 1,
			},
			headerSecondary: {
				fontSize: "14px",
				lineHeight: 1.14,
				letterSpacing: "2px",
			},
			list: {
				marginRight: 0,
			},
			col: {
				width: "100%",
				marginBottom: "45px",
				marginRight: 0,
				display: "flex",
				flexWrap: "wrap",
				alignItems: "center",
			},
			colHeader: {
				fontSize: "14px",
				lineHeight: 1.14,
				marginBottom: "11px",
				width: "100%",
			},
			colItem: {
				width: "110px",
				marginRight: "70px",
				"&:nth-child(3n+1)": {
					marginRight: 0,
				},
			},
			alphabet: {
				marginRight: 0,
			},
			alphabetCol: {
				width: "100%",
				marginBottom: 0,
				marginRight: 0,
			},
			alphabetBlock: {
				paddingLeft: "30px",
				justifyContent: "space-between",
				display: "flex",
				flexWrap: "wrap",
				alignItems: "flex-start",
			},
			alphabetColItem: {
				width: "155px",
			},
			footer: {
				display: "none",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				paddingLeft: "120px",
				marginBottom: "50px",
			},
			header: {
				paddingBottom: "33px",
				alignItems: "flex-start",
			},
			headerMain: {
				fontSize: "18px",
				lineHeight: 1,
			},
			headerSecondary: {
				fontSize: "14px",
				lineHeight: 1.14,
				letterSpacing: "2px",
				marginLeft: "30px",
				flexShrink: 0,
				marginTop: "2px",
			},
			list: {
				marginRight: 0,
			},
			col: {
				width: "100%",
				marginBottom: "33px",
				marginRight: 0,
				display: "flex",
				flexWrap: "wrap",
				alignItems: "center",
			},
			colHeader: {
				width: "100%",
			},
			colItem: {
				width: "100%",
			},
			alphabet: {
				marginRight: 0,
			},
			alphabetCol: {
				width: "100%",
				marginBottom: 0,
				marginRight: 0,
			},
			alphabetBlock: {
				justifyContent: "space-between",
				display: "flex",
				flexWrap: "wrap",
				alignItems: "flex-start",
			},
			alphabetColItem: {
				width: "265px",
			},
			footer: {
				display: "none",
			},
		},
		[`@media (max-width: ${theme.media.xs})`]: {
			root: {
				paddingLeft: "23px",
				marginBottom: "50px",
				height: "300px",
			},
			alphabetBlock: {
				paddingLeft: "35px",
			},
			alphabetColItem: {
				width: "265px",
			},
			footer: {
				display: "none",
			},
		},
		["@media (max-height: 700px)"]: {
			root: {
				height: "300px",
			},
		},
		["@media (max-height: 600px)"]: {
			root: {
				height: "250px",
			},
		},
		["@media (max-height: 350px)"]: {
			root: {
				height: "150px",
			},
		},
	};
};

class ListOfDistricts extends React.PureComponent {
	static defaultProps = {
		showAlphabet: false,
		onItemSelect: () => {},
		type: "districts",
		isSwitchView: false,
		isBlockLetterView: true,
		listHeader: "",
	};

	constructor(props) {
		super(props);
		this.state = {
			showAlphabet: this.props.showAlphabet,
			letterValue: "",
		};
	}

	changeOrder() {
		this.setState({
			showAlphabet: !this.state.showAlphabet,
		});
	}

	onSelectRegionHandler(region) {
		this.props.onItemSelect(region);
		Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.CLICK_DISTRICT_FROM_HOMEPAGE, []);
	}

	generateListHeader(text, onClick) {
		let classes = this.props.classes;
		return (
			<div className={classes.headerSecondary} onClick={onClick}>
				{text}
			</div>
		);
	}

	generateRegions() {
		let { items } = this.props;
		let classes = this.props.classes;
		return items.map((item, index) => {
			let streetsArray = item.s.map((item, index) => {
				return (
					<div
						onClick={() => this.onSelectRegionHandler(item)}
						key={index}
						className={classes.colItem}
					>
						<Link to={item.link}>{item.name}</Link>
					</div>
				);
			});
			return (
				<div key={index} className={classes.col}>
					<div className={classes.colHeader}>{item.name}</div>
					{streetsArray}
				</div>
			);
		});
	}

	generateAlphabetical = () => {
		let {
			defaultItems,
			windowSizes: { lg, md, sm, xs },
			type,
			isBlockLetterView,
		} = this.props;
		let classes = this.props.classes;

		// if (defaultItems.length == 0) return null;
		function toNextColumn() {
			if (objectsInBlock.length) {
				blockInColumns.push(
					<div key={blocksCounter} className={classes.alphabetBlock}>
						{isBlockLetterView && <div className={classes.alphabetColHeader}>{blockLetter}</div>}
						{objectsInBlock}
					</div>
				);
			}

			blocksCounter = 0;
			columns.push(
				<div key={columnsCounter} className={classes.alphabetCol}>
					{blockInColumns}
				</div>
			);

			columnsCounter++;

			currentHeight = 0;
			objectsInBlock = [];
			blockInColumns = [];
		}

		function toNextBlock() {
			blockInColumns.push(
				<div key={blocksCounter} className={classes.alphabetBlock}>
					{isBlockLetterView && <div className={classes.alphabetColHeader}>{blockLetter}</div>}
					{objectsInBlock}
				</div>
			);
			currentHeight += defaultBlockMargin;
			blocksCounter++;
			objectsInBlock = [];
		}

		let columns = [];
		let columnsCounter = 0;
		let blockInColumns = [];
		let blocksCounter = 0;
		let objectsInBlock = [];
		let currentHeight = 0;
		let maxHeight = 720;
		if (md) maxHeight = 890;
		if (sm || xs) maxHeight = Number.POSITIVE_INFINITY;
		let itemHeight = 24;
		let defaultBlockMargin = 24;
		let blockLetter = defaultItems[0].name.substring(0, 1);
		let testHeight = 0;
		if (this.state.letterValue) {
			let regexp = new RegExp("^" + this.state.letterValue, "gi");
			defaultItems = defaultItems.filter((o) => {
				if (o.name.match(regexp)) {
					return true;
				} else {
					return false;
				}
			});
		}

		blockLetter = defaultItems[0].name.substring(0, 1);
		for (var i = 0; i < defaultItems.length; i++) {
			let item = defaultItems[i];
			let service = 0;
			if (item.district && item.district.service == 1) {
				service = 1;
			}
			if (item.name.substring(0, 1) !== blockLetter) {
				toNextBlock();
				i--;
				blockLetter = item.name.substring(0, 1);
				continue;
			}
			if (currentHeight + itemHeight <= maxHeight) {
				objectsInBlock.push(
					<div
						onClick={() => this.onSelectRegionHandler(item)}
						key={i}
						className={classes.alphabetColItem}
					>
						<Link to={item.link} onClick={!!item.onClickHandler ? item.onClickHandler : null}>
							{service == 1 ? item.full : item.name}
						</Link>
					</div>
				);
				//currentHeight += itemHeight;
				if (i == defaultItems.length - 1) {
					toNextColumn();
				}
			}
		}
		return columns;
	};

	setLettersValue(value) {
		this.setState({
			...this.state,
			letterValue: value,
		});
	}

	getRenderRegions() {
		return this.props.defaultItems;
	}

	generateFirstLetters() {
		let objects = this.getRenderRegions();
		let classes = this.props.classes;
		let lastLetter = "";
		return objects.map((item, index) => {
			if (lastLetter !== item.name.substring(0, 1)) {
				lastLetter = item.name.substring(0, 1);
				let closureLetter = lastLetter;
				return (
					<div
						key={v4()}
						className={classNames(classes.footerItem, {
							[classes.isActive]: this.state.letterValue == lastLetter,
						})}
						onClick={() => this.setLettersValue(closureLetter)}
					>
						{lastLetter}
					</div>
				);
			}
		});
	}

	render() {
		let { defaultItems } = this.props;
		let classes = this.props.classes;
		if (defaultItems.length == 0) return null;
		let { showAlphabet } = this.state;
		return (
			<>
				<div className={classes.root}>
					{!showAlphabet && <div className={classes.list}>{this.generateRegions()}</div>}
					{showAlphabet && <div className={classes.alphabet}>{this.generateAlphabetical()}</div>}
				</div>
				<div className={classes.footer}>
					{this.generateFirstLetters()}
					<div
						className={classNames(classes.footerItem, classes.footerItemAll, {
							[classes.isActive]: !this.state.letterValue && !this.props.searcheValue,
						})}
						onClick={() => this.setLettersValue("")}
					>
						Все
					</div>
				</div>
			</>
		);
	}
}

export default withStyles(styles)(ListOfDistricts);
