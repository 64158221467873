var gql = require("@apollo/client").gql;

const MUTATE_EMPTY_ADDRESS_PROVIDER = gql`
	mutation (
		$likeProvider: String
		$provider: String
		$regionId: Int
		$regionName: String
		$districtId: Int
		$districtName: String
		$streetId: Int
		$streetName: String
		$home: String
	) {
		emptyAddressProvider(
			likeProvider: $likeProvider
			provider: $provider
			address: {
				region: { id: $regionId, name: $regionName }
				district: { id: $districtId, name: $districtName }
				street: { id: $streetId, name: $streetName }
				home: $home
			}
		)
	}
`;

export default MUTATE_EMPTY_ADDRESS_PROVIDER;
