import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import { observer, inject } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import PhoneContainerNew from "Containers/PhoneContainerNew";
import { Col } from "ComponentsUI/Styles/Grid";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import BenefitCategory from "ComponentsUI/Benefit/Components/BenefitCategory";
import { BlueBlock, ConsultBlock } from "ComponentsUI/InfoBlocks";
import ProviderConnectWithPhoneHOC from "HOC/ProviderConnectWithPhoneHOC";
import { sendMetriks } from "Services/sendMetriks";

const styles = (theme) => ({
	root: {
		marginTop: "-90px",
		position: "relative",
	},
	row: {
		marginBottom: "20px",
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			marginTop: "-40px",
		},
		row: {
			marginBottom: "10px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			display: "none",
		},
	},
});

@withRouter
@inject("ApplicationModel")
@observer
class UnderBannerLayout extends React.Component {
	static defaultProps = {
		counter: [],
		onConnectOrder: () => {},
		onConnectOfficeOrder: () => {},
		onConnectOutHomeOrder: () => {},
		onClickRating: () => {},
		onClickCompare: () => {},
		onClickRates: () => {},
		ratingUrl: "/",
		compareUrl: "/",
		orderToHomeUrl: "/",
		orderToOutHomeUrl: "/",
		orderToOfficeUrl: "/",
	};

	handlePhoneClick() {
		sendMetriks("CLICK_BANNER_PHONE_BUTTON_REAL");
	}

	render() {
		let text = this.props.ApplicationModel.text;
		let classes = this.props.classes;
		let windowSizes = this.props.ApplicationModel?.windowSizeModel?.windowSizes;
		const isDesktop = windowSizes?.md || windowSizes?.lg;

		const desc = !!isDesktop ? (
			<div>
				<ProviderConnectWithPhoneHOC banner_phone={true}>
					<PhoneContainerNew
						big={true}
						isPurple
						roistatClass={"roistat-phone"}
						onClickPhone={this.handlePhoneClick}
					/>
				</ProviderConnectWithPhoneHOC>
			</div>
		) : (
			<div>
				<PhoneContainerNew
					big={true}
					isPurple
					roistatClass={"roistat-phone"}
					onClickPhone={this.handlePhoneClick}
				/>
			</div>
		);

		return (
			<div className={classes.root} datatest={this.props.datatest}>
				<Container>
					<Row className={classes.row}>
						<Col sm={3} lg={2}>
							<Link to={this.props.ratingUrl}>
								<BlueBlock
									text={this.props.heroRatingProviders}
									icon={<span className="icon48 icon-votes-2" />}
									onClick={this.props.onClickRating}
									datatest="main_raitingprovider_button"
								/>
							</Link>
						</Col>
						<Col sm={3} lg={2}>
							<Link to={this.props.tariffTagInternetTvMobile}>
								<BlueBlock
									text={text.profitable_internet}
									secondText={text.three_in_one}
									icon={<span className="icon48 icon-bar-chart" />}
									onClick={this.props.onClickRates}
									datatest="main_comparetariff_button"
								/>
							</Link>
						</Col>
						<Col sm={6} lg={4}>
							{this.props.counter}
						</Col>
						<Col lg={4} xsHidden smHidden mdHidden>
							<ConsultBlock
								title={this.props.heroFreeConsultation}
								image={this.props.imageConsultation}
								desc={desc}
							/>
						</Col>
					</Row>
					<Row className={classNames(classes.row, "align-items-stretch")}>
						<Col xs={12} sm={4}>
							<Link to={this.props.orderToHomeUrl}>
								<BenefitCategory
									header={this.props.toHome}
									text={this.props.toHomeHint}
									icon={<span style={{ fontSize: "48px" }} className="icon48 icon-armchair" />}
									category={this.props.heroInternet}
									onClick={this.props.onConnectOrder}
									datatest="main_inflat_button"
								/>
							</Link>
						</Col>
						<Col xs={12} sm={4}>
							<Link to={this.props.orderToOutHomeUrl}>
								<BenefitCategory
									header={this.props.toOutHome}
									text={this.props.toOutHomeHint}
									icon={<span style={{ fontSize: "48px" }} className="icon48 icon-home" />}
									category={this.props.heroInternet}
									onClick={this.props.onConnectOutHomeOrder}
									datatest="main_inhouse_button"
								/>
							</Link>
						</Col>
						<Col xs={12} sm={4}>
							<Link to={this.props.orderToOfficeUrl}>
								<BenefitCategory
									header={this.props.toOffice}
									text={this.props.toOfficeHint}
									icon={<span style={{ fontSize: "48px" }} className="icon48 icon-tie" />}
									category={this.props.heroInternet}
									datatest="main_inoffice_button"
									onClick={this.props.onConnectOfficeOrder}
								/>
							</Link>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withStyles(styles)(UnderBannerLayout);
