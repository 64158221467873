import ReactDOM from "react-dom";
import React from "react";
import "isomorphic-unfetch";
import { Provider } from "mobx-react";
import AppControl from "HOC/AppControl";
import { createBrowserHistory } from "history";
import App from "../../../application/App";
import { Router } from "react-router";
import scss from "../../../application/styles/index.scss";
import "swiper/swiper-bundle.min.css";
import "ComponentsUI/Styles/styles/index.scss";
import "react-calendar/dist/Calendar.css";
import { jss } from "react-jss";
import ThemeProvider from "ComponentsUI/Styles/ThemeProvider";
import StyleProvider from "ComponentsUI/Styles/StyleProvider";
import normalize from "normalize-jss";
import AppModel from "../../../application/stores/ApplicationModel";
import { HelmetProvider } from "react-helmet-async";
import { loadableReady } from "@loadable/component";
import { useBaseTag } from "Helpers/useBasename";
import cookie from "js-cookie";
import "intersection-observer";

import { AB_Provider, AB_Test } from "react-abtesting";
import { adapter } from "ABTesting";

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import cacheSettings from "Configs/apolloCacheSettings";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

jss.createStyleSheet(normalize).attach();

function generateKeyForState() {
	Math.random().toString(36).substr(2, 6);
}

loadableReady(() => {
	if (window.__ROUTER_CONTEXT.location) {
		if (
			window.__ROUTER_CONTEXT.location.state &&
			(window.__ROUTER_CONTEXT.location.state.is404 ||
				window.__ROUTER_CONTEXT.location.state.isYandexCaptcha)
		) {
			window.history.replaceState(
				{
					key: generateKeyForState(),
					state: window.__ROUTER_CONTEXT.location.state,
				},
				"ssr"
			);
		}
	}

	if (!window.__ROUTER_CONTEXT.location) {
		window.history.replaceState(
			{
				key: generateKeyForState(),
				state: {},
			},
			"ssr"
		);
	}

	const history = useBaseTag(createBrowserHistory)();

	let user_id = cookie.get("uuid");
	if (user_id === undefined) {
		user_id = null;
	}

	const httpLink = new HttpLink({
		uri: window.__GQL_HOST__,
		fetch: fetch,
	});

	const customLink = setContext((_, { headers }) => {
		return {
			headers: {
				...headers,
				Authorization: "Basic MTAxaW50ZXI6dGVzdDEwMQ==",
			},
		};
	});

	const client = new ApolloClient({
		cache: new InMemoryCache(cacheSettings).restore(window.__APOLLO_STATE__),
		link: customLink.concat(httpLink),
	});

	[...document.querySelectorAll(".js_on")].forEach((item) => {
		item.classList.remove("js_on");
	});

	let ApplicationModel = new AppModel(history, null, client);
	ApplicationModel.preloadData(window.__MOBX_STATE__);
	ApplicationModel.preloadTexts(window.texts);
	ApplicationModel.setOrdersRequestTimeout(window.__ORDERS_REQUEST_TIMEOUT__);
	ApplicationModel.setYandexCaptchaClientKey(window.__YANDEX_CAPTHCA_CLIENT_KEY__);

	const experimentMap = window.__EXPERIMENT_MAP__;

	const stores = {
		// routing: routingStore,
		ApplicationModel: ApplicationModel,
	};

	const createGenerateClassName = () => {
		let counter = 0;

		return (rule, sheet) => `app${counter++}`;
	};

	const abTest = new AB_Test(experimentMap || [], adapter);

	// if ("serviceWorker" in navigator) {
	//     if (navigator.serviceWorker.controller) {
	//         if (
	//             navigator.serviceWorker.controller.scriptURL ===
	//             `${window.__PATH_TO_ASSETS__}/sw.js`
	//         ) {
	//             console.log(
	//                 "[PWA Builder] active service worker found, no need to register"
	//             );
	//         } else {
	//             navigator.serviceWorker
	//                 .register(`${window.__PATH_TO_ASSETS__}/sw.js`, {
	//                     scope: "./"
	//                 })
	//                 .then(function(reg) {
	//                     console.log(
	//                         "[PWA Builder] Service worker has been registered for scope: " +
	//                             reg.scope
	//                     );
	//                 });
	//         }
	//     } else {
	//         // Register the service worker
	//         navigator.serviceWorker
	//             .register(`${window.__PATH_TO_ASSETS__}/sw.js`, {
	//                 scope: "./"
	//             })
	//             .then(function(reg) {
	//                 console.log(
	//                     "[PWA Builder] Service worker has been registered for scope: " +
	//                         reg.scope
	//                 );
	//             });
	//     }
	// }

	if ("serviceWorker" in navigator) {
		navigator.serviceWorker
			.getRegistrations()
			.then(function (registrations) {
				for (let registration of registrations) {
					registration.unregister();
				}
			})
			.catch(function (err) {
				console.log("Service Worker registration failed: ", err);
			});
	}

	if (window.__SENTRY_DSN_REACT__ && window.__NAMESPACE__) {
		Sentry.init({
			dsn: window.__SENTRY_DSN_REACT__,
			integrations: [new BrowserTracing()],
			environment: window.__NAMESPACE__,
			tracesSampleRate: 0,
			allowUrls: [
				"https://101internet.ru",
				"https://www.moskvaonline.ru",
				"https://piter-online.net",
				"https://6795958b-b182-4407-a38b-200df0582b44.selcdn.net",
			],
			release: window.__RELEASE_VERSION__,
		});
	}

	ReactDOM.hydrate(
		<ApolloProvider client={client}>
			<Router history={history}>
				<Provider {...stores}>
					<AppControl>
						<StyleProvider generateId={createGenerateClassName()}>
							<ThemeProvider>
								<AB_Provider abTest={abTest} history={history}>
									<HelmetProvider>
										<App />
									</HelmetProvider>
								</AB_Provider>
							</ThemeProvider>
						</StyleProvider>
					</AppControl>
				</Provider>
			</Router>
		</ApolloProvider>,
		document.getElementById("root"),
		() => {
			// remove static css
			const ssStyles = document.getElementById("server-side-styles");
			ssStyles.parentNode.removeChild(ssStyles);
		}
	);
});
