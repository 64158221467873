import Yandex from "../helpers/Metriks/types/yandex";
import Google from "../helpers/Metriks/types/google";

let Metrika = (function () {
	let instance;
	let createInstance = () => {
		return {
			yandex: new Yandex(),
			google: new Google(),
		};
	};

	return {
		getInstance: function () {
			return instance || (instance = createInstance());
		},
	};
})();

export default Metrika;
