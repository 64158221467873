import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			display: "flex",
			alignItems: "center",
			cursor: "pointer",
			"& .icon24": {
				fontSize: "24px",
				color: theme.palette.colors.white,
			},
		},
		text: {
			fontFamily: theme.fonts.bold,
			lineHeight: 1,
			letterSpacing: "1.8px",
			fontSize: "14px",
			color: theme.palette.colors.white,
			userSelect: "none",
			marginLeft: "15px",
			textTransform: "uppercase",
		},
		purpleTheme: {
			"& .icon24": {
				color: theme.palette.colors.purplishBlue,
			},
			"& $text": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		greyTheme: {
			backgroundColor: theme.palette.colors.disabled,
			height: "50px",
			padding: "0 15px",
			borderRadius: "10px",
		},
		onlyLogo: {
			"& $text": {
				display: "none",
			},
		},
		inWidget: {
			height: "50px",
			backgroundColor: theme.palette.colors.cloudyBlue,
			"& .icon24": {
				fontSize: "20px",
			},
			"& $text": {
				fontSize: "14px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				"& .icon24": {
					fontSize: "20px",
				},
			},
			text: {
				display: "none",
			},
			greyTheme: {
				width: "50px",
			},
			inWidget: {
				width: "auto",
				"& $text": {
					display: "block",
				},
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			text: {
				display: "none",
			},
			root: {
				"& .icon24": {
					fontSize: "20px",
				},
			},
			inWidget: {
				width: "auto",
				height: "50px",
				"& $text": {
					display: "block",
				},
			},
		},
	};
};

class LoginHeaderButton extends React.Component {
	render() {
		let classes = this.props.classes;
		return (
			<div
				datatest="main_button_enter"
				className={classNames(classes.root, {
					[classes.purpleTheme]: this.props.purpleTheme,
					[classes.greyTheme]: this.props.greyTheme,
					[classes.onlyLogo]: this.props.onlyLogo,
					[classes.inWidget]: this.props.inWidget,
				})}
				onClick={this.props.onClickButton}
			>
				<span className="icon24 icon-inside" />
				<div className={classes.text}> {this.props.text} </div>
			</div>
		);
	}
}

export default withStyles(styles)(LoginHeaderButton);
