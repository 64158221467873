import React from "react";
import TariffForm from "./TariffForm";
import TariffFormV2 from "./TariffFormV2";
import InlineForm from "./InlineForm";
import AdFormColor from "./AdFormColor";
import AdForm from "./AdForm";
import AltAdFormColor from "./AltAdFormColor";
import PopupForm from "./PopupForm";

const Form = ({
	children,
	type,
	isSuccess,
	isSend,
	statusSend,
	successHeader,
	successSubHeader,
	error,
	successDark,
	successButton,
	homeOrder,
	orderPhone,
	inlineOrderPhone,
	text,
	order_after,
	siteCode,
	windowSizes,
}) => {
	if (type === "tariffForm")
		return (
			<TariffForm
				isSuccess={isSuccess}
				successHeader={successHeader}
				successSubHeader={successSubHeader}
				successButton={successButton}
				homeOrder={homeOrder}
			>
				{children}
			</TariffForm>
		);
	if (type === "tariffFormV2")
		return (
			<TariffFormV2
				isSuccess={isSuccess}
				isSend={isSend}
				statusSend={statusSend}
				successHeader={successHeader}
				successSubHeader={successSubHeader}
				successButton={successButton}
				homeOrder={homeOrder}
				text={text}
				orderPhone={orderPhone}
				inlineOrderPhone={inlineOrderPhone}
				order_after={order_after}
				siteCode={siteCode}
				windowSizes={windowSizes}
			>
				{children}
			</TariffFormV2>
		);
	if (type === "popupForm")
		return (
			<PopupForm isSuccess={isSuccess} successHeader={successHeader} successButton={successButton}>
				{children}
			</PopupForm>
		);
	if (type === "inlineForm") return <InlineForm>{children}</InlineForm>;
	if (type === "adForm") return <AdForm>{children}</AdForm>;
	if (type === "adFormColor")
		return (
			<AdFormColor
				isSuccess={isSuccess}
				successHeader={successHeader}
				successSubHeader={successSubHeader}
			>
				{children}
			</AdFormColor>
		);
	if (type === "altAdFormColor")
		return (
			<AltAdFormColor
				error={error}
				isSuccess={isSuccess}
				successHeader={successHeader}
				successSubHeader={successSubHeader}
				successDark={successDark}
			>
				{children}
			</AltAdFormColor>
		);
};
Form.defaultProps = {
	type: "tariffForm",
};
export default Form;
