import React, { useEffect, useRef, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import withDefaultParams from "Services/withDefaultParams";

const useStyles = createUseStyles((theme) => ({
	root: {
		padding: "20px",
		border: `solid 1px ${theme.palette.colors.disabled}`,
		borderRadius: "20px",
		backgroundColor: theme.palette.colors.white,
	},
	wrapperFaq: {
		fontSize: "16px",
		lineHeight: "1.38",
	},
	containerQuestion: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	iconArrow: {
		paddingLeft: "10px",
		fontSize: "10px",
		fontWeight: "bold",
		color: theme.palette.colors.oneMoreGrey,
	},
	clickable: {
		cursor: "pointer",
	},
	question: {
		display: "inline-block",
		color: theme.palette.colors.darkSlateBlue,
		fontFamily: theme.fonts.light,
	},
	answer: {
		cursor: "text",
		color: theme.palette.colors.darkSlateBlue,
		fontFamily: theme.fonts.light,
		"& table": {
			width: "100%",
			margin: "20px 0",
			borderCollapse: "collapse",
			border: `1px solid ${theme.palette.colors.black}`,
			color: theme.palette.colors.oneMoreGrey,
			"& th, td": {
				padding: "7px",
				border: `1px solid ${theme.palette.colors.black}`,
				textAlign: "left",
				verticalAlign: "top",
				wordBreak: "break-all",
			},
		},
	},
	hideAnswer: {
		display: "none",
	},
	showAnswer: {
		display: "block",
		color: theme.palette.colors.darkSlateBlue,
		fontFamily: theme.fonts.light,
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		wrapperFaq: {
			fontSize: "14px",
			lineHeight: "1.29",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		wrapperFaq: {
			fontSize: "14px",
			lineHeight: "1.29",
			// margin: "0",
		},
		containerQuestion: {
			justifyContent: "space-between",
			borderBottom: `1px solid ${theme.palette.colors.oneMoreGrey}`,
		},
	},
}));

const QuestionAnswer = (props) => {
	//TODO ВРЕМЕННО, пока текст не появистся в сеосервисе
	let { clickQuestion, region, providerInfo, tariffsInfo, siteCode } = props;
	// let { qa } = faqProviderDetails({
	//     siteCode,
	//     provider: providerInfo,
	//     tariffsInfo,
	// });
	let { faq } = props;
	const classes = useStyles();

	// if (!faq?.length && !!qa?.length) {
	//     faq = qa;
	// }
	if (!faq?.length) {
		return null;
	}
	const [questionAqnswers, setQuestionAqnswers] = useState(() =>
		faq.map((elem, index) => ({
			...elem,
			isOpened: false,
			key: index,
		}))
	);

	useEffect(() => {
		setQuestionAqnswers(
			faq.map((elem, index) => ({
				...elem,
				isOpened: false,
				key: index,
			}))
		);
	}, [faq]);

	const toggleAnswerHandler = (faq) => {
		if (clickQuestion) {
			clickQuestion(faq.key + 1);
		}
		setQuestionAqnswers(
			questionAqnswers.map((elem) =>
				elem.key === faq.key ? { ...elem, isOpened: !elem.isOpened } : elem
			)
		);
	};

	return (
		<div
			itemScope
			itemType="https://schema.org/FAQPage"
			className={classNames({
				[classes.root]: Boolean(questionAqnswers.length),
			})}
		>
			{questionAqnswers.map((elem) => {
				if (!elem) {
					return null;
				}

				return (
					<div
						className={classes.wrapperFaq}
						key={elem.key}
						itemScope
						itemProp="mainEntity"
						itemType="https://schema.org/Question"
					>
						<div className={classes.containerQuestion}>
							<h3
								className={classNames(classes.question, classes.clickable)}
								itemProp="name"
								onClick={() => toggleAnswerHandler(elem)}
							>
								{elem.question}
							</h3>
							<span
								onClick={() => toggleAnswerHandler(elem)}
								className={classNames(
									elem.isOpened ? "icon24 icon-arrow-1-up" : "icon24 icon-arrow-1-down",
									classes.iconArrow,
									classes.clickable
								)}
							/>
						</div>
						<div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
							<div
								className={classNames(
									classes.answer,
									elem.isOpened ? classes.showAnswer : classes.hideAnswer
								)}
								itemProp="text"
								dangerouslySetInnerHTML={{
									__html: elem.answer,
								}}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default withRouter(withDefaultParams(QuestionAnswer));
