import React from "react";
import { TextField } from "ComponentsUI/TextField";
import withStyles from "react-jss";
import classNames from "classnames";
import { FromFade } from "ComponentsUI/Animations";

const formStyle = (theme) => ({
	root: {
		width: "100%",
		display: "flex",
		alignItems: "flex-start",
		position: "relative",
		marginTop: "16px",
		"flex-direction": "row",
		"flex-wrap": "wrap",
		"justify-content": "flex-start",
	},
	rootIsSuccess: {
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "center",
	},
	textFieldWrapper: {
		width: "100%",
		marginRight: "auto",
		position: "relative",
	},
	buttonWrapper: {
		width: "100%",
		marginRight: "auto",
		position: "relative",
		marginTop: "20px",
	},
	error: {
		width: "100%",
		fontFamily: theme.fonts.medium,
		fontSize: "16px",
		lineHeight: "1.15",
		color: theme.palette.colors.coralPink,
		transform: "scaleY(0)",
		transition: "opacity 1s ease, transform .2s ease, -webkit-transform .2s ease",
		overflow: "hidden",
		opacity: "0",
		transformOrigin: "top left",
		paddingTop: "0",
	},
	errorShow: {
		paddingTop: "10px",
		transform: "scaleY(1)",
		opacity: "1",
	},
	successHeader: {
		fontSize: "24px",
		fontFamily: theme.fonts.bold,
		color: ({ successDark }) =>
			successDark ? theme.palette.colors.darkSlateBlue : theme.palette.colors.paleGrey,
		lineHeight: "1.2",
		textAlign: "center",
		marginBottom: "22px",
	},
	successSubHeader: {
		fontSize: "16px",
		fontFamily: theme.fonts.light,
		color: ({ successDark }) =>
			successDark ? theme.palette.colors.oneMoreGrey : theme.palette.colors.paleGrey,
		lineHeight: "1.2",
		textAlign: "center",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		error: {
			fontSize: "14px",
		},
	},
});

const AdFormColorTypeB = withStyles(formStyle)(({
	children,
	classes,
	isSuccess,
	successHeader,
	successSubHeader,
	error,
}) => {
	if (!isSuccess) {
		let newChildren = [];
		let textfieldChildren = [];
		children.map((child, i) => {
			if (!child) return null;
			if (child.type.displayName === "TextField") {
				return textfieldChildren.push(
					<div key={i} className={classes.textFieldWrapper}>
						{React.cloneElement(child, {
							...child.props,
							key: i,
						})}
					</div>
				);
			}

			if (child.type.displayName === "Button") {
				return newChildren.push(
					<div key={i} className={classes.buttonWrapper}>
						{React.cloneElement(child, {
							...child.props,
							key: i,
						})}
					</div>
				);
			}
			return newChildren.push(React.cloneElement(child, { ...child.props, key: i }));
		});

		const errorShow = classNames(classes.error, {
			[classes.errorShow]: error,
		});

		if (textfieldChildren.length) {
			return (
				<form className={classes.root}>
					{textfieldChildren}
					<div className={errorShow}>{error}</div>
					{newChildren}
				</form>
			);
		}
		return <form className={classes.root}>{newChildren}</form>;
	} else {
		return (
			<FromFade>
				<div className={classNames(classes.root, classes.rootIsSuccess)}>
					<div className={classes.successHeader}>{successHeader}</div>
					<div className={classes.successSubHeader}>{successSubHeader}</div>
				</div>
			</FromFade>
		);
	}
});

export default AdFormColorTypeB;
