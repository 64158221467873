module.exports = {
	palette: {
		colors: {
			white: "#ffffff",
			whiteGray: "#f5f5f5",
			purplishBlue: "#5812fe",
			darkSlateBlue: "#2b1d5d",
			darkSlateBlue2: "#2c1d5d",
			spanishGrey: "#979797",
			coralPink: "#ff586c",
			giftPink: "#ff7485",
			paleGrey: "#f5f7fa",
			cloudyBlue: "#c0bbd2",
			slimeGreen: "#96d206",
			charcoalGrey: "#35333c",
			headerColour: "#20124a",
			yellowGreen: "#cf0",
			yellow: "#ccff00",
			black: "#000000",
			lightGrayishViolet: "#d3cee1",
			disabled: "#d2cee0",
			transparent: "transparent",
			grey: "#cccccc",
			veryDarkGray: "#4a4a4a",
			grayishBlue: "#726A9F",
			positiveBodyReview: "#22c4a0",
			oneMoreGrey: "#787193",
			whatsapp: "#3CD566",
			oneMoreGreen: "#19c141",
			superLightGrey: "#e4e8f1",
			veryBlackGrey: "#7b7392",
			dropdownListColor: "#c8c2d9",
			addressBlackHeader: "#28262e",
			orange: "#f88e34",
			orange2: "#f7671c",
			violet: "#5813fe",
			liteGreen: "#23c4a0",
			slateBlue: "#976efc",
			lightCoral: "#ff8888",
			softYellow: "#FAD961",
			vividOrange: "#F7671C",
			telegrey: "#8C8C8C",
		},
	},
	button: {
		color: "#5812fe",
	},
	media: {
		lg: "1439.98px",
		md: "1199.98px",
		sm: "764.98px",
		xs: "600px",
		ratingItemSmall: "590px",
		ratingItemVerySmall: "370px",
		tariffsSmall: "400px",
		footerXs: "450px",
		verySmall: "360px",
		operatorListSmall: "500px",
	},
	breakpoints: {
		lg: 1440,
		md: 1200,
		sm: 765,
		xs: 600,
	},
	fonts: {
		light: "MuseoSansCyrl-300",
		medium: "MuseoSansCyrl-500",
		bold: "MuseoSansCyrl-700",
	},
};
