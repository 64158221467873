import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import { CatRating1, CatRating2, CatRating3, CatRating4, CatRating5 } from "Constants/SVG";

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
	},
	label: {
		"& *": {
			cursor: "pointer",
		},
	},
	checkbox: {
		position: "absolute",
		appearance: "none",
	},
	icon: {
		width: "56px",
		height: "56px",
		"& svg": {
			width: "56px",
			height: "56px",
		},
	},
	notChecked: {
		opacity: "0.4",
	},
}));

const CATS_RATING = [
	{ id: "1", icon: CatRating1, value: "1" },
	{ id: "2", icon: CatRating2, value: "2" },
	{ id: "3", icon: CatRating3, value: "3" },
	{ id: "4", icon: CatRating4, value: "4" },
	{ id: "5", icon: CatRating5, value: "5" },
];

export const CatRating = (props) => {
	const { checked, onChange } = props;
	const classes = useStyles();

	return (
		<div className={classes.container}>
			{CATS_RATING.map((cat) => (
				<label key={cat.id} className={classes.label} htmlFor={cat.id}>
					<input
						id={cat.id}
						name="rating"
						type="checkbox"
						checked={checked === cat.value}
						className={classes.checkbox}
						value={cat.value}
						onChange={(event) => onChange(event)}
					/>
					<div
						className={cn(classes.icon, {
							[classes.notChecked]: checked !== cat.value && checked !== "",
						})}
					>
						{cat.icon}
					</div>
				</label>
			))}
		</div>
	);
};
