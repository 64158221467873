import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	displayNone: {
		display: "none",
	},
}));

const TariffsLayout = (props) => {
	let { tariffs, children, skipLayout } = props;
	const classes = useStyles();
	if (!tariffs?.length || skipLayout) {
		return children;
	}

	let tariffMinPrice = tariffs.slice().sort((a, b) => {
		if (a.displayPrice > b.displayPrice) {
			return 1;
		} else if (a.displayPrice < b.displayPrice) {
			return -1;
		} else {
			return 0;
		}
	})[0];
	return (
		<>
			<div itemProp="offers" itemScope itemType=" http://schema.org/AggregateOffer ">
				<span itemProp="priceCurrency" content="RUB"></span>

				<div className={classes.displayNone}>
					Цена: от <span itemProp="lowPrice">{tariffMinPrice.displayPrice}</span>
				</div>
			</div>
			{children}
		</>
	);
};

export default TariffsLayout;
