import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { sendMetriks } from "Services/sendMetriks";
import { pipe } from "Utils/functionalUtils";
import { LimitPlansBTableContainer } from "Constants/Limit";
import { TariffsLayout as MicroMarkupTariffsLayout } from "Layouts/MicroMarkup";
import PlanBRowContainer from "Containers/PlanBRowContainer";
import { TariffsTable } from "ComponentsUI/TariffsTable";
import { TariffsHeaderRow } from "ComponentsUI/TarifficationB";
import OneClick from "Containers/OneClick";
import { Paginator, PaginatorElem } from "ComponentsUI/Pagination";

const PlansBTableContainer = (props) => {
	// Сколько элементов выводить на странице
	// const LIMIT = 30;

	let {
		ApplicationModel: { windowSizeModel, text },
		pagination,
		skipLayout,
	} = props;
	let connectTariffButton =
		props.ApplicationModel?.siteConfig?.settings?.connect_tariff_button?.[props.pageName] ||
		"connect";

	useEffect(() => {
		if (
			windowSizeModel.windowSizes.height &&
			connectTariffButton === "connect" &&
			!!props.tariffs?.length &&
			props.tariffs.find((tariff) => tariff?.provider?.vip_provider == 1) &&
			props.tariffs.find(
				(tariff) => tariff?.provider?.provider_settings?.tariffs?.value === "button_text"
			)
		) {
			sendMetriks("OPEN_NEW_TEXT");
		}
	}, [props.tariffs, windowSizeModel.windowSizes.height]);

	let generateTariffs = () => {
		let xs = windowSizeModel.windowSizes.xs;

		let adElem = [<OneClick key="adblock2" />];

		let blocks = props.tariffs.map((item) => {
			return (
				<PlanBRowContainer
					key={item.id}
					data={item}
					showName={props.showNames}
					compareIsHideInConnectTariffs={props.compareIsHideInConnectTariffs}
					orderToHomeCompareBtnHidden={props.orderToHomeCompareBtnHidden}
					compareIsHide={props.compareIsHide}
					isCardPage={props.isCardPage}
					isOrderToHome={props.isOrderToHome}
					isTS={props.isTS}
					isExclusives={props.isExclusives}
					isAboutProvider={props.isAboutProvider}
					isActions={props.isActions}
					isRates={props.isRates}
					isProviderRatesTags={props.isProviderRatesTags}
					isCard={props.isCard}
					isMainPage={props.isMainPage}
					is404={props.is404}
					isReviewsProviders={props.isReviewsProviders}
					pageName={props.pageName}
					update_date={props.update_date}
					noShowExlusive={props.noShowExlusive}
					isActionRegion={props.isActionRegion}
					skipLayout={skipLayout}
				/>
			);
		});

		if (xs && props.addFormOneClick) {
			if (blocks.length > 5) {
				blocks.splice(5, 0, adElem);
			} else {
				blocks.push(adElem);
			}
		}
		return blocks;
	};

	let tableHeader = <TariffsHeaderRow texts={text} showName={props.showNames} />;

	let pageTotal = 0;
	let page = 1;
	if (pagination) {
		pageTotal = Math.ceil(pagination.items_total / LimitPlansBTableContainer);
		if (props.match.params.page) {
			page = +props.match.params.page;
		}
	}
	let stylePagination = props.presenceBrand ? {} : { paddingTop: "15px" };

	return (
		<>
			<MicroMarkupTariffsLayout tariffs={props.tariffs} skipLayout={skipLayout}>
				<TariffsTable datatest={props.datatest} tableHeader={tableHeader}>
					{generateTariffs()}
				</TariffsTable>
			</MicroMarkupTariffsLayout>

			{pagination && (
				<div style={stylePagination}>
					<Paginator
						key={23}
						itemsTotal={pagination.items_total}
						itemsPerPage={LimitPlansBTableContainer}
						pageCurrent={page}
						pageTotal={pageTotal}
						windowSizes={windowSizeModel.windowSizes}
						urlDefault={props.urlDefault}
						wrapperItem={<PaginatorElem />}
						location={props.location}
						datatest="tariffs"
						presenceBrand={props.presenceBrand}
					/>
				</div>
			)}
		</>
	);
};

export default pipe(observer, inject("ApplicationModel"), withRouter)(PlansBTableContainer);
