import React, { Component } from "react";
import CarouselUi from "ComponentsUI/CarouselUi";

export default class CarouselContainer extends Component {
	render() {
		let {
			title,
			declinations,
			windowSizes,
			infinity,
			remake,
			list,
			count,
			backgroundNone,
			isTariffs,
			seoBlock,
		} = this.props;

		return (
			<CarouselUi
				seoBlock={seoBlock}
				declinations={declinations}
				title={title}
				defaultButtons={true}
				list={list}
				count={count}
				windowSizes={windowSizes}
				infinity={infinity}
				remake={remake}
				backgroundNone={backgroundNone}
				isTariffs={isTariffs}
			/>
		);
	}
}
