import React from "react";

const BreadcrumbsItem = ({ children }) => (
	<li itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
		{children}
	</li>
);

BreadcrumbsItem.displayName = "BreadcrumbsItem";

export default BreadcrumbsItem;
