import React from "react";
import { observer, inject } from "mobx-react";
import Container from "reactstrap/lib/Container";
import { Col } from "ComponentsUI/Styles/Grid";
import Row from "reactstrap/lib/Row";

@inject("ApplicationModel")
@observer
export default class LoginLogic extends React.Component {
	openRegistrationHandler() {
		this.closeModal();
		let modalModel = this.props.ApplicationModel.modalModel;
		let promise1 = import("Layouts/modalLayout");
		let promise2 = import("Containers/RegistrateFormContainer");
		Promise.all([promise1, promise2]).then((modules) => {
			let ModalLayout = modules[0].default;
			let RegistrateFormContainer = modules[1].default;
			modalModel.toggleModal(
				<ModalLayout
					closeModal={() => this.closeModal()}
					authView={true}
					withoutClose={true}
					closeOnClickLayout={true}
				>
					<Container>
						<Row className="justify-content-center">
							<Col xs="12" md="10">
								<RegistrateFormContainer
									registrationSuccesfullCallback={(login) => this.openActivate(login)}
									succesfullCallback={this.props.succesfullCallback || this.closeModal}
									closeModal={() => this.closeModal()}
									addButton={this.props.addButton}
									callback={() => this.closeModal()}
									openLoginHandler={() => this.openLoginHandler()}
								/>
							</Col>
						</Row>
					</Container>
				</ModalLayout>
			);
		});
	}

	openActivate(login) {
		this.closeModal();
		let type = "email";
		let modalModel = this.props.ApplicationModel.modalModel;
		let sendingModel = this.props.ApplicationModel.sendingStore;
		if (sendingModel.validatePhone(login)) {
			type = "phone";
		}
		let promise1 = import("Layouts/modalLayout");
		let promise2 = import("Containers/ActivationContainer");
		Promise.all([promise1, promise2]).then((modules) => {
			let ModalLayout = modules[0].default;
			let ActivationContainer = modules[1].default;
			modalModel.toggleModal(
				<ModalLayout
					closeModal={() => this.closeModal()}
					authView={true}
					withoutClose={true}
					closeOnClickLayout={true}
				>
					<Container>
						<Row className="justify-content-center">
							<Col xs="12" md="10">
								<ActivationContainer
									type={type}
									succesfullCallback={this.props.succesfullCallback}
									login={login}
									closeModal={() => this.closeModal()}
									callback={() => this.closeModal()}
								/>
							</Col>
						</Row>
					</Container>
				</ModalLayout>
			);
		});
	}

	openLoginHandler() {
		this.closeModal();
		let modalModel = this.props.ApplicationModel.modalModel;
		let promise1 = import("Layouts/modalLayout");
		let promise2 = import("Containers/LoginFormContainer");
		Promise.all([promise1, promise2]).then((modules) => {
			let ModalLayout = modules[0].default;
			let LoginFormContainer = modules[1].default;
			modalModel.toggleModal(
				<ModalLayout
					closeModal={() => this.closeModal()}
					authView={true}
					withoutClose={true}
					closeOnClickLayout={true}
				>
					<Container>
						<Row className="justify-content-center">
							<Col xs="12" md="10">
								<LoginFormContainer
									succesfullCallback={this.props.succesfullCallback || this.closeModal}
									closeModal={() => this.closeModal()}
									addButton={this.props.addButton}
									callback={() => this.closeModal()}
									openRegistrationHandler={() => this.openRegistrationHandler()}
								/>
							</Col>
						</Row>
					</Container>
				</ModalLayout>
			);
		});
	}

	closeModal = () => {
		let modalModel = this.props.ApplicationModel.modalModel;
		if (modalModel.isOpen) {
			modalModel.toggleModal();
		}
	};

	logout() {
		let authModel = this.props.ApplicationModel.newAuthModel;
		authModel.logout();
	}

	render() {
		let children = React.cloneElement(this.props.children, {
			openRegistrationHandler: () => this.openRegistrationHandler(),
			logout: () => this.logout(),
		});
		return children;
	}
}
