import React from "react";
import Button from "ComponentsUI/Button";
import { createUseStyles } from "react-jss";
import { TextField } from "ComponentsUI/TextField";
import { SupportCat } from "Constants/url";

const useStyles = createUseStyles((theme) => ({
	root: {
		position: "relative",
	},
	subtext: {
		fontFamily: theme.fonts.light,
		fontSize: "14px",
		lineHeight: 1.29,
		color: theme.palette.colors.white,
		textAlign: "center",
		margin: "20px 0px",
	},
	imgWrapper: {
		width: "100%",
		marginBottom: "15px",
	},
	img: {
		display: "block",
		height: "130px",
		margin: "auto",
	},
	textField: {
		width: "100%",
		marginBottom: "16px",
	},
	layoutBButton: {
		width: "100%",
		marginTop: "16px",
	},
	layoutBSwitcher: {
		marginTop: "16px",
	},
	header: {
		fontFamily: theme.fonts.bold,
		fontSize: "18px",
		lineHeight: 1,
		color: theme.palette.colors.white,
		textAlign: "center",
		marginBottom: "15px",
	},
	header2: {
		fontFamily: theme.fonts.bold,
		fontSize: "18px",
		lineHeight: 1,
		color: theme.palette.colors.white,
		textAlign: "center",
		marginBottom: "15px",
	},
	phoneNumber: {
		whiteSpace: "nowrap",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			margin: 0,
		},
		imgWrapper: {
			height: "150px",
		},
		img: {
			height: "150px",
		},
		header: {
			width: "100%",
		},
		header2: {
			width: "100%",
		},
	},
}));

const WaitingCall = (props) => {
	const { text } = props;
	let classes = useStyles();

	const renderSmallButton = () => {
		return (
			<Button
				onClick={props.onClickButton}
				fullWidth={true}
				noPaddings={true}
				datatest={"waitingCall_button"}
				size={"middle"}
				typeText={"middle"}
				status={props.phone.length != 11 ? "info" : "tertiaryAccent"}
			>
				{text.CALLBACK_FORM_BUTTON}
			</Button>
		);
	};

	const renderButtonStep2 = () => {
		return (
			<Button
				onClick={props.onClose}
				fullWidth={true}
				noPaddings={true}
				size="big"
				datatest={"waitingCall_button_thanks"}
				typeText={"verybig"}
				status="tertiaryAccent"
			>
				{text.THANKS_YOU}
			</Button>
		);
	};

	const renderLayout = () => {
		return (
			<>
				<div className={classes.imgWrapper}>
					<img className={classes.img} src={SupportCat} alt={"Поддержка"} />
				</div>
				<div className={classes.header}>{props.step == 1 && props.header}</div>
				<div className={classes.header}>{props.step == 1 && props.header2}</div>
				<div className={classes.textField}>
					<TextField
						autocomplete={"tel"}
						name={"tel"}
						placeholder={"+7"}
						type={"phone"}
						formatting={"phone"}
						id={"fix_callback_phone"}
						datatest={"callback"}
						width={"100%"}
						onChange={(e) => props.onChangePhone(e)}
						errorText={props.errorText}
						value={props.phone}
						errorCounter={props.errorCounter}
						searchWithPhone={true}
						autoFocus={!props.windowSizes.xs && !props.windowSizes.sm}
						onEnter={props.onEnter}
						isError={props.error}
					/>
				</div>
				<div className={classes.layoutBButton}>{renderSmallButton()}</div>
			</>
		);
	};
	const renderStep2 = () => {
		return (
			<>
				<div className={classes.layoutBSwitcher}>
					<div className={classes.subtext}>
						<div>{text.ORDER_SUCCESS_0}</div>
						<div>
							{text.ORDER_SUCCESS_1}
							<span className={classes.phoneNumber}>{text.ORDER_SUCCESS_2}</span>
							{text.ORDER_SUCCESS_3}
						</div>
						<div>{text.ORDER_SUCCESS_4}</div>
						<div>{text.ORDER_SUCCESS_5}</div>
					</div>
					<div className={classes.layoutBButton}>{renderButtonStep2()}</div>
				</div>
			</>
		);
	};

	return <div className={classes.root}>{props.step == 1 ? renderLayout() : renderStep2()}</div>;
};

export default WaitingCall;
