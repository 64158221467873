import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { SVGAnotherArrowRight } from "Constants/SVG";
import { FooterTopListContainer } from "Containers/Footer";
import { FooterTopTitleContainer } from "Containers/Footer";
import FooterTopMobileBlockContainer from "Containers/Footer/FooterTopMobileBlockContainer";
import { isMoscowSiteCode, isPiterSiteCode } from "Utils/siteConfig";

const useStyles = createUseStyles((theme) => ({
	root: {},
	is404Layout: {
		paddingTop: "56px",
	},
	list: {
		listStyleType: "none",
		padding: 0,
		margin: 0,
	},
	blocksList: {
		display: "flex",
		justifyContent: "space-between",
		gap: "20px",
	},
	componentItem: {
		width: "calc(25% - 10px)",
	},
	subList: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: "-8px",
	},
	titlesItem: {
		width: "calc(50% - 10px)",
	},
	titleItem: {
		width: "calc(50% - 10px)",
		"& >div": {
			marginBottom: "8px",
		},
		"& >a": {
			marginBottom: "8px",
		},
	},
	mobileList: {
		display: "flex",
		gap: "20px",
	},
	mobileItem: {
		width: "calc(25% - 10px)",
	},
	mobileBlock: {
		marginTop: "20px",
	},
	[`@media (max-width: ${theme.media.md})`]: {
		componentItem: {
			width: "calc(33% - 10px)",
		},
		subList: {
			flexDirection: "column",
		},
		titlesItem: {
			width: "calc(33% - 10px)",
		},
		titleItem: {
			width: "100%",
		},
		mobileList: {
			justifyContent: "space-between",
			marginTop: "4px",
		},
		mobileItem: {
			width: "calc(33% - 10px)",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			marginLeft: "16px",
		},
		is404Layout: {
			paddingTop: "32px",
		},
		blocksList: {
			flexDirection: "column",
			gap: "16px",
		},
		titleItem: {
			"& >div": {
				marginBottom: "16px",
			},
			"& >a": {
				marginBottom: "16px",
			},
		},
		componentItem: {
			width: "100%",
		},
		titlesItem: {
			width: "100%",
		},
		mobileList: {
			flexDirection: "column",
			gap: "16px",
			marginTop: 0,
		},
		mobileItem: {
			width: "100%",
		},
		mobileBlock: {
			marginTop: "24px",
		},
	},
}));

const FooterTopPart = (props) => {
	const {
		is404Layout,
		windowSizes,
		moreLink_text,
		textRatesHome,
		textDomashnijInternet,
		textInternetITv,
		textInternetTvMobile,
		textInternetIMobilnayaSvyaz,
		textOnlineKinoteatr,
		textOrderToHome,
		urlDomashnijInternet,
		urlInternetITv,
		urlInternetTvMobile,
		urlInternetIMobilnayaSvyaz,
		urlOnlineKinoteatr,
		urlOrderToHome,
		textMobileRatesmobile,
		urlRatesMobileUnlim,
		textRatesMobileUnlim,
		urlRatesMobileMezhdunarodnye,
		textRatesMobileMezhdunarodnye,
		urlRatesMobilePerenosNomera,
		textRatesMobilePerenosNomera,
		urlRatesMobileEsim,
		textRatesMobileEsim,
		urlRatesMobile,
		ratesMobile_text,
		textMobileOperators,
		urlOperatorDetailsMts,
		urlOperatorDetailsTinkoff,
		urlOperatorDetailsYota,
		urlOperatorDetailsBeeline,
		urlOperatorDetailsTele2,
		urlOperatorDetailsSbermobile,
		urlOperatorDetailsMegafon,
		providersText,
		providersInText,
		currentRegion,
		urlProviderDetailsMts,
		TextMts,
		urlProviderDetailsRostelecom,
		TextRostelecom,
		urlProviderDetailsBeeline,
		TextBeeline,
		urlProviderDetailsDomRu,
		TextDomRu,
		urlProviderDetailsMegafon,
		TextMegafon,
		urlProviders,
		TextTinkoff,
		TextYota,
		TextTele2,
		TextSbermobile,
		textAddress,
		urlAddressDistricts,
		textInternetInOffice,
		urlOrderOffice,
		textInternetOuthome,
		urlOrderOuthome,
		textReviews,
		urlReviews,
		textRating,
		urlRating,
		textActions,
		urlActions,
		textBesplatnye,
		urlBesplatnye,
		textBronzovyj,
		urlBronzovyj,
		textSerebrjanyj,
		urlSerebrjanyj,
		textZolotoj,
		urlZolotoj,
		textBeautifulNumbers,
		declinations,
		mobileBlockTitle,
		textAllOperators,
		urlAllOperators,
		textAllNumbers,
		urlAllNumbers,
	} = props;
	const classes = useStyles();

	return (
		<section
			className={classNames(classes.root, {
				[classes.is404Layout]: is404Layout,
			})}
		>
			<ul className={classNames(classes.list, classes.blocksList)}>
				<li className={classes.componentItem}>
					<FooterTopListContainer
						title={textRatesHome}
						subtitles={[
							{ text: textDomashnijInternet, src: urlDomashnijInternet },
							{ text: textInternetITv, src: urlInternetITv },
							{ text: textInternetTvMobile, src: urlInternetTvMobile },
							{ text: textInternetIMobilnayaSvyaz, src: urlInternetIMobilnayaSvyaz },
							{ text: textOnlineKinoteatr, src: urlOnlineKinoteatr },
							{ text: textOrderToHome, src: urlOrderToHome },
						]}
						isButton={windowSizes.xs}
					/>
				</li>
				<li className={classes.componentItem}>
					<FooterTopListContainer
						title={
							currentRegion && declinations?.prepositional
								? `${providersInText}${declinations?.prepositional}`
								: providersText
						}
						subtitles={[
							{ text: TextMts, src: urlProviderDetailsMts },
							{ text: TextRostelecom, src: urlProviderDetailsRostelecom },
							{ text: TextBeeline, src: urlProviderDetailsBeeline },
							{ text: TextDomRu, src: urlProviderDetailsDomRu },
							{ text: TextMegafon, src: urlProviderDetailsMegafon },
						]}
						moreLink={urlProviders}
						moreLink_text={moreLink_text}
						moreLinkSvg={SVGAnotherArrowRight}
						isButton={windowSizes.xs}
					/>
				</li>
				<li className={classes.titlesItem}>
					<ul className={classNames(classes.list, classes.subList)}>
						<li className={classes.titleItem}>
							<FooterTopTitleContainer text={textAddress} isLink src={urlAddressDistricts} />
							<FooterTopTitleContainer text={textReviews} isLink src={urlReviews} />
							<FooterTopTitleContainer text={textRating} isLink src={urlRating} />
							<FooterTopTitleContainer text={textActions} isLink src={urlActions} />
						</li>
						<li className={classes.titleItem}>
							<FooterTopTitleContainer text={textInternetInOffice} isLink src={urlOrderOffice} />
							<FooterTopTitleContainer text={textInternetOuthome} isLink src={urlOrderOuthome} />
						</li>
					</ul>
				</li>
			</ul>
			<div className={classes.mobileBlock}>
				<FooterTopMobileBlockContainer
					windowSizes={windowSizes}
					mobileBlockTitle={mobileBlockTitle}
				>
					<ul className={classNames(classes.list, classes.mobileList)}>
						<li className={classes.mobileItem}>
							<FooterTopListContainer
								title={textMobileRatesmobile}
								subtitles={[
									{ text: textRatesMobileUnlim, src: urlRatesMobileUnlim },
									{ text: textRatesMobileMezhdunarodnye, src: urlRatesMobileMezhdunarodnye },
									{ text: textRatesMobilePerenosNomera, src: urlRatesMobilePerenosNomera },
									{ text: textRatesMobileEsim, src: urlRatesMobileEsim },
								]}
								moreLink={urlRatesMobile}
								moreLink_text={ratesMobile_text}
								moreLinkSvg={SVGAnotherArrowRight}
								isButton={windowSizes.xs}
							/>
						</li>
						<li className={classes.mobileItem}>
							<FooterTopListContainer
								title={textMobileOperators}
								subtitles={[
									{ text: TextMts, src: urlOperatorDetailsMts },
									{ text: TextTinkoff, src: urlOperatorDetailsTinkoff },
									{ text: TextYota, src: urlOperatorDetailsYota },
									{ text: TextBeeline, src: urlOperatorDetailsBeeline },
									{ text: TextTele2, src: urlOperatorDetailsTele2 },
									{ text: TextSbermobile, src: urlOperatorDetailsSbermobile },
									{ text: TextMegafon, src: urlOperatorDetailsMegafon },
								]}
								moreLink={urlAllOperators}
								moreLink_text={textAllOperators}
								moreLinkSvg={SVGAnotherArrowRight}
								isButton={windowSizes.xs}
							/>
						</li>
						<li className={classes.mobileItem}>
							<FooterTopListContainer
								title={textBeautifulNumbers}
								subtitles={[
									{ text: textBesplatnye, src: urlBesplatnye },
									{ text: textBronzovyj, src: urlBronzovyj },
									{ text: textSerebrjanyj, src: urlSerebrjanyj },
									{ text: textZolotoj, src: urlZolotoj },
								]}
								moreLink={urlAllNumbers}
								moreLink_text={textAllNumbers}
								moreLinkSvg={SVGAnotherArrowRight}
								isButton={windowSizes.xs}
							/>
						</li>
					</ul>
				</FooterTopMobileBlockContainer>
			</div>
		</section>
	);
};

FooterTopPart.propTypes = {
	moreLink_text: PropTypes.string,
	textRatesHome: PropTypes.string,
	textMobileRatesmobile: PropTypes.string,
	textMobileOperators: PropTypes.string,
	providersText: PropTypes.string,
	textAddress: PropTypes.string,
	urlAddressDistricts: PropTypes.string,
	textInternetInOffice: PropTypes.string,
	urlOrderOffice: PropTypes.string,
	textInternetOuthome: PropTypes.string,
	urlOrderOuthome: PropTypes.string,
	textReviews: PropTypes.string,
	urlReviews: PropTypes.string,
	textRating: PropTypes.string,
	urlRating: PropTypes.string,
	textActions: PropTypes.string,
	urlActions: PropTypes.string,
	textBesplatnye: PropTypes.string,
	urlBesplatnye: PropTypes.string,
	textBronzovyj: PropTypes.string,
	urlBronzovyj: PropTypes.string,
	textSerebrjanyj: PropTypes.string,
	urlSerebrjanyj: PropTypes.string,
	textZolotoj: PropTypes.string,
	urlZolotoj: PropTypes.string,
	textBeautifulNumbers: PropTypes.string,
	textDomashnijInternet: PropTypes.string,
	textInternetITv: PropTypes.string,
	textInternetTvMobile: PropTypes.string,
	textInternetIMobilnayaSvyaz: PropTypes.string,
	textOnlineKinoteatr: PropTypes.string,
	urlDomashnijInternet: PropTypes.string,
	urlInternetITv: PropTypes.string,
	urlInternetTvMobile: PropTypes.string,
	urlInternetIMobilnayaSvyaz: PropTypes.string,
	urlOnlineKinoteatr: PropTypes.string,
	mobileBlockTitle: PropTypes.string,
	textAllOperators: PropTypes.string,
	urlAllOperators: PropTypes.string,
	textAllNumbers: PropTypes.string,
	urlAllNumbers: PropTypes.string,
};

export default FooterTopPart;
