import React from "react";
import withStyles from "react-jss";
import styles from "../styles";
import classNames from "classnames";
import { EmptyCell } from "./../index";
import { Tooltip, TooltipBody } from "ComponentsUI/Tooltip";
import { SVGHint } from "Constants/SVG";

const TariffsRow = ({
	classes,
	onClickConnect,
	buttonConnectComponent,
	connectButtonDatatest,
	logoComponent,
	inCompare,
	texts,
	onCompareClickHandler,
	name,
	providerNameComponent,
	providerDistrictsComponent,
	speedComponent,
	channelsComponent,
	mobileDataComponent,
	dodyComponent,
	priceComponent,
	actionImageComponent,
	compareIsHideInConnectTariffs,
	orderToHomeCompareBtnHidden,
	compareIsHide,
	onClickHandler,
	hideProviderLogo,
	regionNameComponent,
	windowSizes,
	convergentOffer,
	exclusive,
}) => {
	const xs = windowSizes?.xs || false;

	return (
		<div className={classNames(classes.row, classes.bodyRow, classes.nameExist)}>
			<div
				className={classNames(classes.baseTD, classes.commonInfoHeader, {
					[classes.providerNameHidden]: hideProviderLogo,
				})}
			>
				<div
					className={classNames(classes.baseTDWrapper, classes.infoWrapper, {
						[classes.providerNameHide]: hideProviderLogo,
					})}
				>
					<div className={classes.imageWrapper}>{logoComponent}</div>
					{xs ? (
						<div
							className={classNames(classes.nameWrapper, {
								[classes.nameTextWrap]: hideProviderLogo,
							})}
						>
							{name}
						</div>
					) : (
						<Tooltip tariffName={true} body={<TooltipBody tariffName={true}>{name}</TooltipBody>}>
							<div
								className={classNames(classes.nameWrapper, {
									[classes.nameTextWrap]: hideProviderLogo,
								})}
							>
								{name}
							</div>
						</Tooltip>
					)}
					<div className={classes.providerNameWrapper} onClick={onClickHandler}>
						{providerNameComponent}
					</div>
					{!!providerDistrictsComponent ? (
						<div className={classes.providerDistrictsWrapper}>{providerDistrictsComponent}</div>
					) : (
						<div className={classes.providerRegionNameWrapper}>{regionNameComponent}</div>
					)}
					<div className={classes.actionImageWrapper}>{actionImageComponent}</div>
				</div>
			</div>
			{/*<span>{eliteComponent}</span>*/}
			{exclusive && (
				<div className={classes.exclusive}>
					<svg
						className={classes.svgImage}
						width={"36"}
						height={"30"}
						viewBox={"0 0 36 30"}
						fill={"none"}
						xmlns={"http://www.w3.org/2000/svg"}
					>
						<path d={SVGHint[0]} fill={"#F88E34"}></path>
						<path d={SVGHint[1]} fill={"white"}></path>
					</svg>
				</div>
			)}
			<div
				className={classNames(classes.baseTD, classes.speedHeader, {
					[classes.notExistXs]: !speedComponent,
				})}
			>
				<div className={classes.baseTDWrapper}>
					{!!speedComponent ? speedComponent : <EmptyCell />}
				</div>
			</div>
			<div
				className={classNames(classes.baseTD, classes.channelsHeader, {
					[classes.notExistXs]: !channelsComponent,
				})}
			>
				<div className={classes.baseTDWrapper}>
					{!!channelsComponent ? channelsComponent : <EmptyCell />}
				</div>
			</div>
			<div
				className={classNames(classes.baseTD, classes.mobileHeader, {
					[classes.hideXs]: !mobileDataComponent,
				})}
			>
				<div className={classes.baseTDWrapper}>
					{!!mobileDataComponent ? mobileDataComponent : <EmptyCell />}
				</div>
			</div>
			{xs && convergentOffer && (
				<div className={classNames(classes.baseTD, classes.mobileHeader)}>{convergentOffer}</div>
			)}
			<div
				className={classNames(classes.baseTD, classes.optionsHeader, {
					[classes.notExistXs]: !dodyComponent,
				})}
			>
				<div className={classes.baseTDWrapper}>
					{!!dodyComponent ? dodyComponent : <EmptyCell />}
				</div>
			</div>
			<div className={classNames(classes.baseTD, classes.connectHeader)}>
				<div className={classes.baseTDWrapper}>
					<div className={classes.connectWrapper}>
						<div className={classes.priceWrapper}>{priceComponent}</div>
						<div className={classes.connectButtonWrapper}>
							<div
								className={classNames(classes.compareButton, {
									[classes.hideCompare]:
										compareIsHideInConnectTariffs || orderToHomeCompareBtnHidden || compareIsHide,
								})}
								onClick={onCompareClickHandler}
							>
								{!!inCompare ? (
									<svg
										width={"24px"}
										height={"24px"}
										xmlns="http://www.w3.org/2000/svg"
										viewBox={`0 0 24 24`}
										className={classNames(classes.icon, classes.iconIsGrey)}
									>
										<path d="M16,14 L2,14 C1.448,14 1,13.552 1,13 C1,12.448 1.448,12 2,12 L16,12 C16.552,12 17,12.448 17,13 C17,13.552 16.552,14 16,14 Z M16,10 L2,10 C1.448,10 1,9.552 1,9 C1,8.448 1.448,8 2,8 L16,8 C16.552,8 17,8.448 17,9 C17,9.552 16.552,10 16,10 Z M16,6 L2,6 C1.448,6 1,5.552 1,5 C1,4.448 1.448,4 2,4 L16,4 C16.552,4 17,4.448 17,5 C17,5.552 16.552,6 16,6 Z M2,16 L12,16 C12.552,16 13,16.448 13,17 C13,17.552 12.552,18 12,18 L2,18 C1.448,18 1,17.552 1,17 C1,16.448 1.448,16 2,16 Z M19.493,20.536 C19.343,20.762 19.106,20.918 18.839,20.966 C18.78,20.976 18.721,20.982 18.662,20.982 C18.453,20.982 18.247,20.915 18.076,20.79 L15.4,18.839 C14.956,18.515 14.859,17.892 15.183,17.448 C15.507,17.004 16.131,16.908 16.575,17.231 L18.408,18.568 L21.134,14.46 C21.438,14.001 22.056,13.876 22.515,14.18 C22.973,14.484 23.098,15.101 22.794,15.559 L19.493,20.536 Z" />
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox={`0 0 24 24`}
										width={"24px"}
										height={"24px"}
										className={classNames(classes.icon, classes.addIcon)}
									>
										<path
											d="M731,5643H717a1,1,0,0,1,0-2h14A1,1,0,0,1,731,5643Zm0,4H717a1,1,0,0,1,0-2h14A1,1,0,0,1,731,5647Zm0,4H717a1,1,0,0,1,0-2h14A1,1,0,0,1,731,5651Zm-4,4H717a1,1,0,0,1,0-2h10A1,1,0,0,1,727,5655Zm8,4a1,1,0,0,1-1-1v-8a1,1,0,0,1,2,0v8A1,1,0,0,1,735,5659Zm4-4h-8a1,1,0,0,1,0-2h8A1,1,0,0,1,739,5655Z"
											transform="translate(-716 -5641)"
										/>
									</svg>
								)}
								<span
									className={classNames(classes.compareButtonText, {
										[classes.compareButtonTextIsGrey]: !!inCompare,
									})}
								>
									{!!inCompare ? texts.ADDED : texts.compare}
								</span>
							</div>
							<div
								className={classNames(classes.connectButton, {
									[classes.roundConnectBtn]:
										compareIsHideInConnectTariffs || orderToHomeCompareBtnHidden || compareIsHide,
								})}
								onClick={() => !!onClickConnect && onClickConnect()}
								datatest={connectButtonDatatest}
							>
								{buttonConnectComponent}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles)(TariffsRow);
