import React, { forwardRef } from "react";
import withStyles from "react-jss";
import classNames from "classnames";

const styles = (theme) => ({
	root: {
		textAlign: "center",
	},
	rootTypeA: {
		textAlign: "center",
		"& $value": {
			marginBottom: "6px",
		},
	},
	rootTypeB: {
		textAlign: "center",
		// "& $value": {
		//     marginBottom: "6px"
		// }
	},
	rootValue: {
		display: "flex",
		alignItems: "center",
	},
	rootValueSales: {
		display: "flex",
		textAlign: "center",
		textDecoration: "line-through",
		textDecorationColor: theme.palette.colors.purplishBlue,
		textDecorationThickness: "2px",
	},
	value: {
		fontSize: "18px",
		fontFamily: theme.fonts.bold,
		lineHeight: 1.1,
		color: theme.palette.colors.darkSlateBlue2,
		letterSpacing: "-0.5px",
		position: "relative",
		wordWrap: "break-word",
		// maxWidth: "50px"
		// marginBottom: "6px",
	},
	valuePoint: {
		fontSize: "18px",
		fontFamily: theme.fonts.bold,
		lineHeight: 1.1,
		letterSpacing: "-0.5px",
		position: "relative",
		cursor: "pointer",
		color: theme.palette.colors.purplishBlue,
		marginBottom: "6px",
	},
	valuePrice: {
		fontSize: "18px",
		fontFamily: theme.fonts.bold,
		lineHeight: 1.1,
		color: theme.palette.colors.darkSlateBlue2,
		letterSpacing: "-0.5px",
		position: "relative",
		wordWrap: "break-word",
		maxWidth: "50px",
		textAlign: "left",
	},
	valueSale: {
		maxWidth: "50px",
		fontSize: "18px",
		fontFamily: theme.fonts.bold,
		lineHeight: 1.1,
		color: theme.palette.colors.darkSlateBlue2,
		letterSpacing: "-0.5px",
		position: "relative",
		textAlign: "left",
		textDecoration: "line-through",
		textDecorationThickness: "2px",
		textDecorationColor: theme.palette.colors.purplishBlue,
		wordWrap: "break-word",
	},
	asterisk: {
		color: "#23c4a0",
		top: "-2px",
		right: "1px",
	},
	// usePointer: {
	//     cursor: "pointer",
	//     color: theme.palette.colors.purplishBlue
	// },
	measure: {
		fontSize: "12px",
		fontFamily: theme.fonts.medium,
		lineHeight: 1,
		color: theme.palette.colors.oneMoreGrey,
		letterSpacing: "2px",
		textTransform: "uppercase",
	},
	measurePrice: {
		display: "none",
		width: "50px",
		fontSize: "12px",
		fontFamily: theme.fonts.medium,
		lineHeight: 1,
		color: theme.palette.colors.oneMoreGrey,
		letterSpacing: "2px",
		textTransform: "uppercase",
	},
	[`@media (min-width: ${theme.media.lg})`]: {
		rootTypeB: {
			display: "flex",
			alignItems: "center",
			// "& $measure": {
			//     marginLeft: "5px"
			// },
			// "& $value": {
			//     marginBottom: 0
			// }
		},
		rootValue: {
			alignItems: "flex-start",
			flexDirection: "column",
		},
		rootValueSales: {
			alignItems: "center",
		},
		value: {
			marginBottom: "6px",
		},
		valuePoint: {
			marginBottom: "6px",
		},
		valueSale: {
			marginBottom: "6px",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		valuePrice: {
			maxWidth: "150px",
			marginLeft: "5px",
		},
		valueSale: {
			maxWidth: "195px",
		},
		measure: {
			marginLeft: "5px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		valuePrice: {
			maxWidth: "130px",
		},
		valueSale: {
			maxWidth: "175px",
		},
		measure: {
			marginLeft: "5px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		rootValueSales: {
			alignItems: "center",
		},
		valuePrice: {
			maxWidth: "50px",
		},
		valueSale: {
			maxWidth: "50px",
		},
		measure: {
			marginLeft: "5px",
		},
	},
	[`@media (max-width: ${theme.media.tariffsSmall})`]: {
		valuePrice: {
			maxWidth: "125px",
		},
		valueSale: {
			maxWidth: "125px",
		},
	},
});

const MeasureA = forwardRef((props, ref) => {
	const {
		classes,
		value,
		valueSale,
		onClickHandler,
		typeA,
		typeB,
		usePointer,
		withAsterisk,
		measure,
		flagValuePrice,
	} = props;

	return (
		<div
			ref={ref}
			className={classNames("MeasureA", {
				[classes.root]: !typeA && !typeB,
				[classes.rootTypeA]: typeA,
				[classes.rootTypeB]: typeB && !valueSale,
				[classes.rootValueSales]: !!valueSale,
				[classes.rootValue]: flagValuePrice,
			})}
		>
			<div
				onClick={onClickHandler}
				className={classNames({
					[classes.value]: !usePointer && !valueSale && !valueSale,
					[classes.valuePrice]: flagValuePrice,
					[classes.valuePoint]: !!usePointer,
					[classes.valueSale]: !!valueSale,
				})}
			>
				{!!value ? value : "-"}
				{withAsterisk && <span className={classes.asterisk}> * </span>}
			</div>
			{!!measure && !!value && (
				<div
					className={classNames({
						[classes.measure]: !valueSale,
						[classes.measurePrice]: !!valueSale,
					})}
				>
					{measure}
				</div>
			)}
		</div>
	);
});

export default withStyles(styles)(MeasureA);
