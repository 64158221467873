import React, { useState, useEffect } from "react";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { DetectRegion } from "ComponentsUI/Navigation";
import { generateUrl } from "Services/withDefaultParams";
import { useQuery } from "@apollo/client";
import { REGION_DETECT_QUERY } from "Services/queries";
import Routes from "Configs/routes";
import { sendMetriks } from "Services/sendMetriks";
const OPEN = "/";
const REGION = "/:regionUrl?";

const DetectRegionContainer = (props) => {
	let {
		ApplicationModel: {
			detectRegionModel,
			commonViewModel,
			compareTarifficationsModel,
			searcheModel,
			regionConfirmationModel,
			searcheModelOneString,
			filtersModel,
			text,
			currentRegion,
		},
		location: { pathname },
		match: { path },
		history,
	} = props;

	let isViewDetectRegion = Cookies.get("isViewDetectRegion");
	let linkSelectRegion = generateUrl(Routes.SelectRegion.route, {});

	function allowedUrl() {
		if (pathname === OPEN) return false;
		if (path === REGION) return false;
		return true;
	}

	const [flag, setFlag] = useState(null);

	const regionOneQuery = useQuery(REGION_DETECT_QUERY, {
		skip: !!currentRegion || allowedUrl() || flag || isViewDetectRegion === "false",
	});

	if (allowedUrl()) {
		Cookies.set("isViewDetectRegion", "false");
	}

	if (isViewDetectRegion === "false") {
		return null;
	}

	function yesRegion() {
		sendMetriks("REGION_YES");
		Cookies.set("isViewDetectRegion", "false");
		setFlag(true);
	}

	function changeRegion() {
		sendMetriks("REGION_OTHER");
	}

	if (!!currentRegion && !allowedUrl() && isViewDetectRegion === "true") {
		return (
			<DetectRegion
				pathname={pathname}
				text={text}
				name={currentRegion.name}
				setRegion={() => yesRegion()}
				changeRegion={() => changeRegion()}
				linkSelectRegion={linkSelectRegion}
			/>
		);
	}

	if (!!currentRegion || allowedUrl()) {
		return null;
	}
	if (regionOneQuery.loading || !regionOneQuery.data.regionDetect) {
		return null;
	}
	let region = regionOneQuery.data.regionDetect;
	let linkDefault = generateUrl(Routes.Default.route, {
		regionUrl: region.url,
	});

	function setRegion() {
		sendMetriks("REDIRECT_MAIN_WITHOUT_REGION");
		commonViewModel.unblocking();
		compareTarifficationsModel.closeCompare();
		detectRegionModel.setAccept(region);
		props.ApplicationModel.setRegion(region);
		searcheModel.resetData();
		searcheModelOneString.resetData();
		filtersModel.reset();
		regionConfirmationModel.toggle();
		Cookies.set("isViewDetectRegion", "true");
		history.push(linkDefault);
	}

	setRegion();

	return null;
};

export default withRouter(inject("ApplicationModel")(DetectRegionContainer));
