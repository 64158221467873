import React from "react";
import classNames from "classnames";

export default class LeftArrow extends React.Component {
	static defaultProps = {
		previousSlide: () => {},
		hideWhenEnd: false,
		currentSlide: 0,
		font: "icon24",
	};

	leftArrowClickHandler() {
		this.props.previousSlide();
	}

	renderElement() {
		return (
			<div
				aria-label={"previous"}
				className={this.props.className || "components__slider-button"}
				onClick={() => {
					this.leftArrowClickHandler();
				}}
			>
				<span className={classNames(this.props.font, "icon-arrow-1-left")} />
			</div>
		);
	}

	render() {
		if (this.props.hideWhenEnd)
			if (this.props.currentSlide) {
				return this.renderElement();
			} else {
				return null;
			}
		return this.renderElement();
	}
}
