import React from "react";

export default class NoIndex extends React.Component {
	render() {
		return (
			<>
				<div dangerouslySetInnerHTML={{ __html: "<!--noindex-->" }} />
				{this.props.children}
				<div dangerouslySetInnerHTML={{ __html: "<!--/noindex-->" }} />
			</>
		);
	}
}
