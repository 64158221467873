import React from "react";
import withStyles from "react-jss";
import styles from "../styles";
import classNames from "classnames";

const TariffsHeader = ({ classes, texts, showName }) => (
	<div className={classNames(classes.row, classes.headerRow)}>
		<div className={classNames(classes.header, classes.commonInfoHeader)}>
			<span className={classes.label}>
				{!!showName ? texts.NAME_OF_TARIFF : texts.FILTERS_PROVIDER}
			</span>
		</div>
		<div className={classNames(classes.header, classes.speedHeader)}>
			<span className={classes.label}>{texts.FILTERS_SPEED}</span>
		</div>
		<div className={classNames(classes.header, classes.channelsHeader)}>
			<span className={classes.label}>{texts.CHANNELS}</span>
		</div>
		<div className={classNames(classes.header, classes.mobileHeader)}>
			<span className={classes.label}>{texts.MOBILE_CONNECT}</span>
		</div>
		<div className={classNames(classes.header, classes.optionsHeader)}>
			<span className={classes.label}>{texts.OPTIONS}</span>
		</div>
		<div className={classNames(classes.header, classes.connectHeader)}>
			<span className={classes.label}>{texts.COST}</span>
		</div>
	</div>
);

export default withStyles(styles)(TariffsHeader);
