import { useRouteMatch } from "react-router";
import { notRegionUrls } from "Constants/defaultParams";
import Url from "./Url";

export const useWithDefaultParams = (region) => {
	let params = Url.getDefaultParams();
	const routerMatch = useRouteMatch();

	const match = JSON.parse(JSON.stringify(routerMatch));

	let regionUrl = match.params.regionUrl;
	match.params.regionUrlComing = regionUrl;

	if (notRegionUrls.find((r) => r == regionUrl)) {
		regionUrl = "";
	}

	if (!!region && !notRegionUrls.find((r) => r == region)) {
		regionUrl = region;
	}

	if (!regionUrl && params.hasOwnProperty("regionUrl")) {
		regionUrl = params.regionUrl;
	}

	match.params.regionUrl = regionUrl;

	return match;
};
