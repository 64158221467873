import { gql } from "@apollo/client";

const EXPERIMENT_QUERY = gql`
	query EXPERIMENT_QUERY($test_name: String, $variant_name: String) {
		experiment(test_name: $test_name, variant_name: $variant_name) {
			html
			script
		}
	}
`;

export default EXPERIMENT_QUERY;
