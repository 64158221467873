import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			fontFamily: theme.fonts.bold,
			fontSize: "36px",
			lineHeight: "1",
			color: theme.palette.colors.darkSlateBlue,
			padding: 0,
			margin: "0 0 0 -2px",
			zIndex: "1",
			minHeight: "36px",
			wordWrap: "break-word",
		},
		txtAfter: {
			color: theme.palette.colors.darkSlateBlue,
			zIndex: "1",
			fontFamily: theme.fonts.light,
			fontSize: "24px",
			lineHeight: 1.17,
			margin: "10px auto 0",
		},
		white: {
			color: theme.palette.colors.white,
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				fontSize: "30px",
				minHeight: "30px",
			},
			txtAfter: {
				fontSize: "18px",
				lineHeight: 1.22,
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				fontSize: "24px",
				minHeight: "24px",
			},
			txtAfter: {
				fontSize: "14px",
				lineHeight: "1.29",
				margin: "5px auto 25px",
			},
		},
	};
};

class PageTitleHeader extends React.Component {
	static defaultProps = {
		text: null,
		white: false,
		classes: {},
	};

	static propTypes = {
		text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		textAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		white: PropTypes.bool,
	};
	render() {
		const classes = this.props.classes;
		return (
			<>
				<h1
					className={classNames(classes.root, {
						[classes.white]: this.props.white,
					})}
				>
					{this.props.text}
				</h1>
				<div
					className={classNames(classes.txtAfter, {
						[classes.white]: this.props.white,
					})}
				>
					{this.props.textAfter}
				</div>
			</>
		);
	}
}

export default withStyles(styles)(PageTitleHeader);
