import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";
import { MatrixWalker } from "Services/MatrixWalker";
import { PlaceholderInput } from "ComponentsUI/Placeholderinput";
import SelectFieldList from "../SelectFieldList";
import { fromEn as fromEnConvert, toEn as toEnConvert } from "convert-layout/ru";

const arrowButton = {
	top: 38,
	bottom: 40,
};

const styles = (theme) => ({
	root: {
		width: "100%",
	},
	transparentType: {},
	emptyList: {
		"&$singleChoose": {
			"& $wrapperInFocus": {
				borderBottomLeftRadius: "10px",
				borderBottomRightRadius: "10px",
				"& $input": {
					border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
					borderBottomLeftRadius: "10px",
					borderBottomRightRadius: "10px",
				},
				"& $boxShadow": {
					position: "absolute",
					zIndex: "-1",
					top: 0,
					left: 0,
					width: "100%",
					bottom: 0,
					boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				},
				"& $body": {
					display: "none",
				},
			},
		},
	},
	singleChoose: {},
	disabled: {
		opacity: ".9",
		"& $input": {
			cursor: "default",
		},
		"& $svg": {
			cursor: "default",
		},
	},
	error: {
		"& $input": {
			border: `1px solid ${theme.palette.colors.coralPink} !important`,
			animation: "blink .7s ease 2",
		},
	},
	header: {
		fontFamily: theme.fonts.bold,
		fontSize: "16px",
		lineHeight: "1",
		letterSpacing: "2.3px",
		color: theme.palette.colors.darkSlateBlue,
		textTransform: "uppercase",
		marginBottom: "17px",
	},
	wrapper: {
		position: "relative",
		borderRadius: "10px 10px 0 0",
	},
	wrapperIsNotEmpty: {
		"& $input": {
			border: `1px solid ${theme.palette.colors.purplishBlue}`,
		},
		"& $svg": {
			color: theme.palette.colors.purplishBlue,
		},
		"& $arrow": {
			display: "none",
		},
		"& $close": {
			display: "block",
		},
	},
	wrapperInFocus: {
		boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.1)",
		"& $placeholder": {
			color: theme.palette.colors.lightGrayishViolet,
			zIndex: 7,
		},
		"& $input": {
			border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
			borderBottom: "none",
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			backgroundColor: theme.palette.colors.white,
			position: "relative",
			zIndex: "6",
		},
		"& $svg": {
			transform: "rotate(180deg) translateY(50%)",
			zIndex: "7",
		},
		"& $arrow": {
			transform: "rotate(180deg) translateY(59%)",
		},
		"& $boxShadow": {
			position: "absolute",
			zIndex: "-1",
			top: 0,
			left: 0,
			width: "100%",
			bottom: 0,
			boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.1)",
			borderRadius: "8px",
		},
		"& $body": {
			transition: "max-height .3s ease",
			border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
			borderTopColor: "transparent",
			boxShadow: `0 2px 20px 0 rgba(0, 0, 0, 0.1)`,
			zIndex: "5",
		},
		"& $bodyIsMultiselect": {
			borderTopColor: theme.palette.colors.lightGrayishViolet,
		},
	},
	placeholder: {
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "1",
		color: theme.palette.colors.grayishBlue,
		paddingLeft: "25px",
		top: "51%",
	},
	input: {
		borderRadius: "10px",
		border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
		width: "100%",
		height: "50px",
		padding: "0 22px",
		fontFamily: theme.fonts.medium,
		fontSize: "16px",
		lineHeight: "1",
		color: theme.palette.colors.purplishBlue,
		backgroundColor: "rgba(255,255,255, .7)",
		paddingRight: "65px",
		cursor: "pointer",
		"-webkit-appearance": "none",
		"-moz-appearance": "none",
		appearance: "none",
		outline: "0",
		textOverflow: "ellipsis",
	},
	whiteInput: {
		backgroundColor: "#ffffff",
	},
	svg: {
		color: theme.palette.colors.oneMoreGrey,
		fontSize: "24px",
		position: "absolute",
		top: "50%",
		right: "22px",
		transform: "rotate(0deg) translateY(-50%)",
		cursor: "pointer",
	},
	arrow: {
		top: "54%",
	},
	close: {
		display: "none",
		fontSize: "20px",
	},
	body: {
		maxHeight: 0,
		overflow: "hidden",
		backgroundColor: theme.palette.colors.white,
		width: "100%",
		border: "none",
		position: "absolute",
		top: "50px",
		boxSizing: "borderBox",
		borderRadius: "0 0 10px 10px",
		zIndex: "9001",
		transform: "translate3d(0,0,0)",
	},
	isFixedList: {
		position: "fixed !important",
		zIndex: "105 !important",
	},
	bodyIsMultiselect: {},
	bodyIsOpen: {
		maxHeight: "500px",
	},
	list: {
		listStyle: "none",
		margin: 0,
		padding: 0,
		maxHeight: "320px",
		overflow: "hidden",
		overflowY: "auto",
	},
	listProviders: {
		maxHeight: "200px",
	},
	singleListItem: {
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "3.13",
		color: theme.palette.colors.grayishBlue,
		cursor: "pointer",
		textOverflow: "ellipsis",
		maxHeight: "50px",
		overflowY: "hidden",
		wordBreak: "break-all",
		padding: "0 22px",
		"&:hover": {
			backgroundColor: theme.palette.colors.paleGrey,
		},
	},
	multiListItemWrapper: {
		width: "100%",
		paddingLeft: "22px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.colors.paleGrey,
		},
	},
	multiListItemWrapperIsChecked: {
		"& $multiListItemFakeCheckbox": {
			border: `2px solid ${theme.palette.colors.transparent}`,
			backgroundColor: theme.palette.colors.purplishBlue,
			"& span": {
				display: "block",
			},
		},
		"& $multiListItemName": {
			color: theme.palette.colors.purplishBlue,
			fontFamily: theme.fonts.medium,
		},
	},
	multiListItemWrapperIsSelected: {
		backgroundColor: theme.palette.colors.paleGrey,
	},
	multiListItemWrapperCheckbox: {
		display: "none",
	},
	multiListItem: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	multiListItemFakeCheckbox: {
		width: "24px",
		height: "24px",
		minWidth: "24px",
		border: "2px solid #e4e8f1",
		marginRight: "16px",
		position: "relative",
		boxSizing: "border-box",
		"& span": {
			color: theme.palette.colors.white,
			fontSize: "13px",
			position: "absolute",
			top: "4px",
			left: "4px",
			display: "none",
		},
	},
	multiListItemName: {
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "3.13",
		color: theme.palette.colors.grayishBlue,
		cursor: "pointer",
		textOverflow: "ellipsis",
		maxHeight: "50px",
		overflow: "hidden",
		wordBreak: "break-all",
		whiteSpace: "nowrap",
		textTransform: "capitalize",
	},
	isQualityChannels: {
		color: "#2b1d5d",
	},
	footer: {
		display: "flex",
		borderTop: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
		height: "50px",
		alignItems: "center",
	},
	footerCancel: {
		fontFamily: theme.fonts.medium,
		fontSize: "16px",
		lineHeight: "50px",
		color: theme.palette.colors.oneMoreGrey,
		marginLeft: "22px",
		marginRight: "auto",
		cursor: "pointer",
	},
	footerOk: {
		fontFamily: theme.fonts.medium,
		fontSize: "16px",
		lineHeight: "50px",
		color: theme.palette.colors.purplishBlue,
		marginRight: "22px",
		cursor: "pointer",
	},
	boxShadow: {},
	withoutClose: {
		"& $wrapperIsNotEmpty": {
			"& $arrow": {
				display: "block",
			},
		},
	},
	smallBtn: {
		"& $input": {
			height: "40px !important",
		},
	},
	mainBannerView: {
		"&$transparentType": {
			"& $input": {
				backgroundColor: "rgba(255, 255, 255, 0.8)",
				border: "none",
			},
			"& $singleListItem": {
				color: theme.palette.colors.purplishBlue,
			},
			"& $wrapperInFocus": {
				"& $input": {
					backgroundColor: theme.palette.colors.white,
					border: "none",
				},
			},
		},
		height: "100%",
		"& $input": {
			height: "60px",
			borderRadius: "8px",
			fontFamily: theme.fonts.bold,
			fontSize: "18px",
			letterSpacing: "1px",
			paddingRight: "35px",
		},
		"& $body": {
			top: "59px",
		},
		"& $singleListItem": {
			fontFamily: theme.fonts.bold,
			fontSize: "18px",
			letterSpacing: "1px",
			maxHeight: "60px",
		},
		"& $wrapperInFocus": {
			"& $input": {
				borderRadius: "8px 8px 0 0",
			},
		},
	},
	directionUp: {
		"&$wrapperInFocus": {
			borderRadius: "0 0 10px 10px",
			"& $input": {
				borderRadius: "0 0 8px 8px",
			},
		},
		"& $body": {
			transform: "translateY(-100%)",
			borderRadius: "8px 8px 0 0",
			borderTopColor: "#d3cee1",
			borderBottomColor: "transparent",
		},
	},
	whiteTheme: {
		"& $input": {
			backgroundColor: "white",
			border: "1px solid #d3cee1",
		},
		"& $singleListItem": {
			color: theme.palette.colors.purplishBlue,
		},
		"& $wrapperInFocus": {
			"& $input": {
				backgroundColor: theme.palette.colors.white,
				border: "1px solid #d3cee1",
			},
		},
		height: "100%",
		"& $body": {
			top: "59px",
		},
	},
	hiddenTitle: {
		display: "block",
	},
	purplishBlue: {
		color: theme.palette.colors.purplishBlue,
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		header: {
			fontSize: "13px",
			lineHeight: "1.14",
			letterSpacing: "2px",
			marginBottom: "12px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		hiddenTitle: {
			display: "none",
		},
		header: {
			fontSize: "13px",
			lineHeight: "1.14",
			letterSpacing: "2px",
			marginBottom: "12px",
		},
		list: {
			maxHeight: "200px",
		},
		input: {
			padding: "0 60px 0 17px",
			height: "50px",
		},
		singleListItem: {
			paddingLeft: "17px",
		},
		placeholder: {
			fontFamily: theme.fonts.light,
			fontSize: "16px",
			lineHeight: "1",
			color: theme.palette.colors.grayishBlue,
			paddingLeft: "20px",
			top: "53%",
		},
		svg: {
			fontSize: "20px",
			right: "20px",
		},
		body: {
			top: "49px",
		},
		mainBannerView: {
			height: "100%",
			"& $input": {
				height: "50px",
				fontSize: "14px",
				letterSpacing: "1.4px",
				textTransform: "uppercase",
				paddingRight: "30px",
			},
			"& $body": {
				top: "49px",
			},
			"& $singleListItem": {
				fontSize: "14px",
				letterSpacing: "1px",
				maxHeight: "50px",
			},
		},
	},
});

class SelectField extends React.Component {
	static defaultProps = {
		title: "",
		activePlaceHolder: null,
		items: [],
		all_items: [],
		onItemSelect: () => {},
		currentElementPos: 1,
		onlyInputDisabled: false,
		findItems: [],
		equalBy: "id",
		allowInput: true,
		placeholder: null,
		onMount: () => {},
		classes: {},
		multiselect: false,
		error: false,
		datatest: null,
		centering: false,
	};

	select = null;

	constructor(props) {
		super(props);
		this.state = {
			inputValue: "",
			currentElementPos: props.currentElementPos,
			findItems: props.findItems,
			outerFindItems: props.findItems,
			focus: false,
			direction: "down",
			wasMount: false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		// TODO: fix second statement
		if (
			props.findItems.length != state.outerFindItems.length ||
			state.outerFindItems[0]?.id !== props.findItems[0]?.id
		) {
			return {
				...state,
				findItems: props.findItems,
				outerFindItems: props.findItems,
			};
		}
		return state;
	}

	bindedHandleClickOutside = (e) => this.handleClickOutside(e);
	input = null;
	selectBodyList = null;
	previusChoose = null;
	filterWrapper = null;
	selectionStart = null;
	portal = null;
	needChangeSelection = false;
	needScroll = null;
	arrow = null;

	checkContent() {
		document.removeEventListener("click", this.bindedHandleClickOutside);
		let input = this.input;
		input.blur();
		this.setState({
			focus: false,
			currentElementPos: 1,
			inputValue: "",
		});
		this.props.onBlur?.();
	}

	focusInput() {
		if (!this.props.multiselect && !this.props.allowInput) this.input.disabled = true;
		this.selectBodyList.scrollTop = 0;
		document.addEventListener("click", this.bindedHandleClickOutside);
		this.previusChoose = this.state.findItems;

		this.setState((state) => {
			if (!state.focus) {
				this.props.onFocus?.();
			}

			return { focus: true };
		});
	}

	handleClickOutside(e) {
		if (this.filterWrapper != null) {
			if (!this.filterWrapper.contains(e.target) && !this.portal.contains(e.target)) {
				this.cancelChoose();
			}
		}
	}

	selectItem(item) {
		let findItems = [];
		let isDel = false;
		this.state.findItems.forEach((elem) => {
			if (elem[this.props.equalBy] !== item[this.props.equalBy]) {
				findItems.push(elem);
			} else {
				isDel = true;
			}
		});

		if (!isDel) {
			findItems.push(item);
		}

		this.setState({
			...this.state,
			inputValue: "",
			findItems: findItems,
		});
	}

	selectItemSingle(item) {
		let findItems = [];
		findItems.push(item);
		this.setState({
			findItems: findItems,
			inputValue: "",
		});

		this.props.onItemSelect(findItems);
		this.checkContent();
	}

	buildOptionsListSelect() {
		let classes = this.props.classes;
		let items = this.generateItems();
		return items.map((item, index) => {
			return (
				<li
					onClick={(event) => this.selectItemSingle(item)}
					key={index}
					className={classes.singleListItem}
				>
					{!!this.props.generateItem ? this.props.generateItem(item, index) : item.name}
				</li>
			);
		});
	}

	generateItems() {
		let fromEn = this.state.inputValue
			.toLowerCase()
			.split("")
			.map((item) => {
				return fromEnConvert(item);
			})
			.join("")
			.toLowerCase();

		let fromRu = this.state.inputValue
			.toLowerCase()
			.split("")
			.map((item) => {
				return toEnConvert(item);
			})
			.join("")
			.toLowerCase();

		let inputValue = this.state.inputValue.toLowerCase();

		let items = !this.state.inputValue
			? this.props.items
			: this.props.items.filter((item) => {
					let finded = this.state.findItems.find((o) => {
						return o[this.props.equalBy] === item[this.props.equalBy];
					});
					if (!!finded) {
						return true;
					}
					if (item["name"].toLowerCase().indexOf(inputValue) == 0) {
						return true;
					}

					if (item.name.toLowerCase().indexOf(fromEn) === 0) {
						return true;
					}

					if (!!item.translit_name) {
						if (item.translit_name.toLowerCase().indexOf(inputValue) === 0) {
							return true;
						}

						if (item.translit_name.toLowerCase().indexOf(fromRu) === 0) {
							return true;
						}
					}

					return false;
				});
		return items;
	}

	selectedItemsToForward(items) {
		let newItems = items.filter((item) => {
			let finded = this.state.findItems.find((o) => {
				return o[this.props.equalBy] === item[this.props.equalBy];
			});
			return !finded;
		});
		return [...this.state.findItems, ...newItems];
	}

	buildOptionsListMultiSelect() {
		let classes = this.props.classes;
		let items = this.generateItems();
		items = this.selectedItemsToForward(items);
		return items.map((item, index) => {
			return (
				<li
					onClick={() => this.selectItem(item)}
					key={index}
					className={classNames(classes.multiListItemWrapper, {
						[classes.multiListItemWrapperIsChecked]: this.state.findItems.find((o) => {
							return o[this.props.equalBy] === item[this.props.equalBy];
						}),
						[classes.multiListItemWrapperIsSelected]: this.state.currentElementPos === index + 1,
					})}
				>
					<input
						className={classes.multiListItemWrapperCheckbox}
						checked={item.checked}
						type="checkbox"
						name={item.name + index}
					/>
					<label className={classes.multiListItem}>
						<div className={classes.multiListItemFakeCheckbox}>
							<span className="icon24 icon-check" />
						</div>
						<div
							className={classNames(classes.multiListItemName, {
								[classes.isQualityChannels]: this.props.isQualityChannels,
							})}
						>
							{item.name}
						</div>
					</label>
				</li>
			);
		});
	}

	cancelChoose() {
		this.setState({
			findItems: this.previusChoose,
		});
		this.checkContent();
	}

	acceptChoose() {
		this.checkContent();
		this.props.onItemSelect(this.state.findItems);
	}

	onChangeHandler(e) {
		let itemsSelected = this.state.findItems.map((o) => o.name).join(", ");
		if (itemsSelected.length) itemsSelected += " ";
		let inputValue = e.target.value.slice(itemsSelected.length);

		if (itemsSelected.length > e.target.value.length) {
			let selectStart = this.input.selectionStart;
			let newSelectStart = 0;
			itemsSelected.split(", ").forEach((item) => {
				if (item.length + newSelectStart <= selectStart) {
					newSelectStart += item.length + 2;
				}
			});
			this.selectionStart = newSelectStart;
			this.needChangeSelection = true;
			let changedValue = e.target.value.split(", ");
			changedValue[changedValue.length - 1] = changedValue[changedValue.length - 1].substring(
				0,
				changedValue[changedValue.length - 1].length - 1
			);
			if (this.state.findItems.length !== changedValue.length) return;
			let changedValueIndex = 0;
			changedValue.forEach((item, index) => {
				if (item.localeCompare(this.state.findItems[index].name) !== 0) changedValueIndex = index;
			});
			this.selectItem(this.state.findItems[changedValueIndex]);
		} else {
			this.setState({
				...this.state,
				inputValue: inputValue,
			});
		}
	}

	scrollList() {
		let classes = this.props.classes;
		let list = this.selectBodyList;
		let listHeight = list.offsetHeight;
		let selectedOption = list.querySelector(`.${classes.multiListItemWrapperIsSelected}`);
		if (!selectedOption) {
			return;
		}
		let currentScrollList = list.scrollTop;
		let selectedOptionHeight = selectedOption.offsetHeight;
		let selectedOptionTop = selectedOption.offsetTop;
		if (selectedOptionTop + selectedOptionHeight - currentScrollList > listHeight) {
			list.scrollTop = selectedOptionTop + selectedOptionHeight - listHeight;
		}
		if (selectedOptionTop < currentScrollList) {
			list.scrollTop = selectedOptionTop;
		}
	}

	keyDown(e) {
		if (!this.props.items.length) return;

		let current;
		let oldCurrent = this.state.currentElementPos;
		for (let i in arrowButton) {
			if (arrowButton[i] === e.keyCode) {
				switch (i) {
					case "top":
						current = MatrixWalker.create(this.props.items.length, 1, oldCurrent)
							.top()
							.getCurrent();
						break;
					case "bottom":
						current = MatrixWalker.create(this.props.items.length, 1, oldCurrent)
							.bottom()
							.getCurrent();
						break;
				}
				this.setState({
					...this.state,
					currentElementPos: current,
				});
				this.needScroll = true;
			}
		}
	}

	keyPress(event) {
		if (event.charCode === 13 && this.props.items.length > 0) {
			this.selectItem(this.props.items[this.state.currentElementPos - 1]);
		}
	}

	emulateFocus() {
		if (this.state.focus) {
			this.checkContent();
		} else {
			let windowSizes = this.props.windowSizes;
			if (this.props.centering && (windowSizes.xs || windowSizes.sm) && this.input) {
				this.input.scrollIntoView({ block: "center" });
			}
			this.focusInput();
		}
	}

	componentDidMount() {
		!!this.props.onMount && this.props.onMount(this);
		this.onMount();
	}

	onMount() {
		this.setState({
			wasMount: true,
		});
	}

	clearSingleSelect() {
		let findItems = [];
		this.setState({
			inputValue: "",
			findItems: [],
		});

		this.checkContent();
		this.props.onItemSelect(findItems);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.multiselect && this.needScroll) {
			this.scrollList();
			this.needScroll = false;
		}

		if (this.input.setSelectionRange && this.needChangeSelection) {
			this.input.setSelectionRange(this.selectionStart, this.selectionStart);
			this.needChangeSelection = false;
		}
		// this.selectionStart = this.input.value.length;

		if (this.props.errorCounter && this.props.error) {
			if (this.props.errorCounter !== prevProps.errorCounter) {
				this.select.classList.remove(this.props.classes.error);
				setTimeout(() => {
					this.select.classList.add(this.props.classes.error);
				}, 0);
			}
		}

		if (prevProps.allowInput && !this.props.allowInput) {
			this.checkContent();
		}
	}

	clickOnArrow() {
		if (this.props.allowInput) {
			this.emulateFocus();
		}
	}

	setDirection(value) {
		this.setState({
			direction: value,
		});
	}

	render() {
		let text = this.props.text;
		let placeholder = !!this.props.placeholder ? this.props.placeholder : text.EVERYTHING;
		let activePlaceholder = !!this.props.activePlaceHolder
			? this.props.activePlaceHolder
			: text.ENTER_A_NAME;
		let value = this.state.findItems.map((o) => o.name).join(", ");
		if (value.length) value += " ";
		let classes = this.props.classes;
		let rootClassName = classNames(classes.root, {
			[classes.emptyList]: this.props.items.length === 0,
			[classes.singleChoose]: !this.props.multiselect,
			[classes.disabled]: !this.props.allowInput,
			[classes.error]: this.props.error,
			[classes.mainBannerView]: this.props.inHeroBannerView,
			[classes.transparentType]: this.props.transparentType,
			[classes.withoutClose]: this.props.withoutClose,
			[classes.whiteTheme]: this.props.whiteTheme,
			[classes.smallBtn]: this.props.isSmallBtn,
		});

		let wrapperClassName = classNames(classes.wrapper, {
			[classes.wrapperInFocus]: this.state.focus,
			[classes.wrapperIsNotEmpty]: this.props.findItems.length > 0,
			[classes.directionUp]: this.state.direction === "up",
		});

		let bodyClassName = classNames(classes.body, {
			[classes.bodyIsMultiselect]: this.props.multiselect,
			[classes.bodyIsOpen]: this.state.focus,
			[classes.isFixedList]: this.props.isFixedList,
		});

		return (
			<div
				className={rootClassName}
				ref={(select) => {
					this.select = select;
				}}
			>
				{this.props.title && (
					<div
						className={classNames(classes.header, {
							[classes.hiddenTitle]: this.props.hideTitleOnMobile,
						})}
						aria-labelledby={this.props.id}
					>
						{this.props.title}
					</div>
				)}
				<div
					ref={(filterWrapper) => (this.filterWrapper = filterWrapper)}
					className={wrapperClassName}
				>
					<PlaceholderInput
						centering={this.props.centering}
						placeholder={this.state.focus ? activePlaceholder : placeholder}
						value={value + this.state.inputValue}
						classNamePlaceholder={classes.placeholder}
						windowSizes={this.props.windowSizes}
						childrensInputIdAria="label"
					>
						<input
							aria-labelledby="label"
							className={classNames(classes.input, {
								[classes.whiteInput]: this.props.whiteInput,
							})}
							name={this.props.inputName}
							type="text"
							maxLength={500}
							value={value + this.state.inputValue}
							onChange={(e) => this.onChangeHandler(e)}
							onKeyDown={(e) => this.keyDown(e)}
							onKeyPress={(e) => this.keyPress(e)}
							onFocus={(e) => this.focusInput()}
							disabled={!this.props.allowInput}
							readOnly={!!this.props.onlyInputDisabled}
							ref={(input) => (this.input = input)}
							datatest={this.props.datatest}
							id={this.props.id}
						/>
					</PlaceholderInput>
					<span
						onClick={() => this.clickOnArrow()}
						ref={(arrow) => (this.arrow = arrow)}
						className={classNames(classes.svg, classes.arrow, "icon24", "icon-arrow-1-down", {
							[classes.purplishBlue]: this.props.purplishBlueArrow,
						})}
					/>
					{!this.props.withoutClose && (
						<span
							className={classNames(classes.svg, classes.close, "icon24", "icon-close")}
							onClick={() => this.clearSingleSelect()}
						/>
					)}
					{this.state.wasMount && (
						<SelectFieldList
							rootClassName={rootClassName}
							wrapperClassName={wrapperClassName}
							bodyClassName={bodyClassName}
							classes={classes}
							text={text}
							focus={this.state.focus}
							target={this.filterWrapper}
							ref={(selectBodyList) => {
								this.selectBodyList = selectBodyList;
							}}
							multiselect={this.props.multiselect}
							buildOptionsListMultiSelect={() => this.buildOptionsListMultiSelect()}
							buildOptionsListSelect={() => this.buildOptionsListSelect()}
							cancelChoose={() => this.cancelChoose()}
							acceptChoose={() => this.acceptChoose()}
							setDirection={(value) => this.setDirection(value)}
							onMount={(portal) => (this.portal = portal)}
							windowSizes={this.props.windowSizes}
							isFixedList={this.props.isFixedList}
							listContainerFunc={this.props.listContainerFunc}
							height200={this.props.height200}
						/>
					)}
					<div className={classes.boxShadow} />
				</div>
			</div>
		);
	}
}

class SelectFieldLifecycleAbusser extends React.Component {
	render() {
		return <SelectField {...this.props} />;
	}
}

export default withStyles(styles)(SelectFieldLifecycleAbusser);

export { SelectField as __SelectField };
