import React from "react";
import { inject, observer } from "mobx-react";
import ListOfDistricts from "ComponentsUI/ListOfDistricts";
import { graphql } from "@apollo/client/react/hoc";
import { DISTRICTS_QUERY } from "Services/queries";
import Routes from "Configs/routes";
import { generateUrl } from "Services/withDefaultParams";
import withDefaultParams from "Services/withDefaultParams";
import { withRouter } from "react-router-dom";
import { BigBlockStub } from "Layouts/Stubs";
import UrlManager from "Helpers/UrlManager";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";

@inject("ApplicationModel")
@observer
class Districts extends React.Component {
	componentDidMount() {
		let searcheModel = this.props.ApplicationModel.searcheModel;
		searcheModel.saveToMemoryData();
		//searcheModel.resetData();
	}

	componentWillUnmount() {
		let searcheModel = this.props.ApplicationModel.searcheModel;
		if (!!searcheModel.getCurrentStreet().id) {
			searcheModel.deleteMemoryData();
		} else {
			searcheModel.recoveryMemoryData();
		}
	}

	render() {
		let {
			districts,
			ApplicationModel: {
				windowSizeModel: { windowSizes },
				text,
			},
			match: {
				params: { regionUrl },
				path,
			},
			seoBlock,
		} = this.props;
		let headerTextDistrictContainer;
		let declinations;

		// Проверяем, на каком url находится, для выставления нужных данных
		if (path === "/:regionUrl?/orders/tohome") {
			headerTextDistrictContainer = text.HEADER_ORDERS_TOHOME_DISTRICTS_CONTAINER;
			declinations = this.props.declinations.genitive;
		} else if (path === "/:regionUrl?/rating") {
			headerTextDistrictContainer = text.HEADER_RATING_DISTRICTS_CONTAINER;
			declinations = this.props.declinations.genitive;
		} else if (path.includes("/:regionUrl?/providers")) {
			headerTextDistrictContainer = text.HEADER_PROVIDERS_DISTRICTS_CONTAINER;
			declinations = this.props.declinations.genitive;
		} else if (path === "/:regionUrl?") {
			headerTextDistrictContainer = text.HEADER_MAIN_PAGE_DISTRICTS_CONTAINER;
			declinations = this.props.declinations.prepositional;
		}

		let isStub = !districts;

		let middleComponent = <BigBlockStub />;

		if (!isStub) {
			let showAlphabet = true;
			let isSwitchView = false;
			let cloneDistricts = JSON.parse(JSON.stringify(districts.data));
			cloneDistricts = cloneDistricts.map((s) => {
				return {
					...s,
					name: s.name.charAt(0).toUpperCase() + s.name.slice(1),
				};
			});
			let sortDistricts = cloneDistricts.sort(function (a, b) {
				if (a.name > b.name) return 1;
				if (a.name < b.name) return -1;
			});
			sortDistricts.map(
				(district) =>
					(district.link = decodeURIComponent(
						generateUrl(Routes.AddressStreets.route, {
							regionUrl: regionUrl,
							districtName: UrlManager.generateValidChankUrl(district.name).toLowerCase(),
							districtId: district.id,
						})
					))
			);

			let items = [];

			// Пункты в таблице
			middleComponent = [
				<ListOfDistricts
					key="districts"
					text={text}
					items={items}
					defaultItems={sortDistricts}
					windowSizes={windowSizes}
					showAlphabet={showAlphabet}
					onItemSelect={this.props.onItemSelect}
					type={"districts"}
					isSwitchView={isSwitchView}
				/>,
			];
		}

		return (
			<React.Fragment>
				<HeaderTextSeo
					header={seoBlock?.seoHeader || `${headerTextDistrictContainer} ${declinations}`}
					text={seoBlock?.seoText}
				/>
				{middleComponent}
			</React.Fragment>
		);
	}
}

export default withRouter(
	withDefaultParams(
		graphql(DISTRICTS_QUERY, {
			skip: (ownProps) => {
				return !ownProps.match.params.regionUrl;
			},
			options: (props) => {
				let {
					match: {
						params: { regionUrl },
					},
				} = props;
				return {
					variables: {
						regionUrl,
					},
				};
			},
			props: ({ data }) => {
				if (data.districts) {
					return data;
				}
				return null;
			},
		})(Districts)
	)
);
