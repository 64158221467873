import React from "react";
import { inject } from "mobx-react";
import timers from "Configs/timers";
import OurModal from "ComponentsUI/OurModal";

@inject("ApplicationModel")
class LoadingSpinnerHOC extends React.Component {
	componentDidMount() {
		OurModal.forOpenedModalBody();
		this.props.ApplicationModel.commonViewModel.changeShowLoadingSpinner(true);
	}

	componentWillUnmount() {
		setTimeout(() => {
			this.props.ApplicationModel.commonViewModel.changeShowLoadingSpinner(false);
			OurModal.forClosedModalBody();
		}, timers.preloadSpinnerTimer);
	}

	render() {
		return null;
	}
}

export default LoadingSpinnerHOC;
