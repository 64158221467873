import { action, computed } from "mobx";
import Routes from "Configs/routes";
import { matchPath } from "react-router-dom";
import { generateUrl } from "Services/withDefaultParams";

export default class RoutesModel {
	houseFull = null;
	street = null;
	district = null;
	region = null;
	tag = null;
	provider = null;
	currentNews = null;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	setCurrentNews(data) {
		this.currentNews = data;
	}

	@action
	setProvider(data) {
		this.provider = data;
	}

	@action
	setTag(data) {
		this.tag = data;
	}

	@action
	setRegion(data) {
		this.region = data;
	}

	@action
	setDistrict(data) {
		this.district = data;
	}

	@action
	setStreet(data) {
		this.street = data;
	}

	@action
	setHouseFull(data) {
		this.houseFull = data;
	}

	getBreadCrumbs(location, params) {
		let findedRoutKey;
		let data = "";
		for (var i in Routes) {
			data = matchPath(location.pathname, {
				path: Routes[i].route,
				exact: true,
			});

			if (data !== null) {
				findedRoutKey = Routes[i].name;
				break;
			}
		}

		let breadCrumbs = this.dependence(Routes[findedRoutKey], findedRoutKey, [], params);

		if (!Routes[findedRoutKey].noDefaultBc) {
			breadCrumbs.push(Routes.Default);
		}

		let house_id =
			this.rootStore?.searcheModel?.house_id || this.rootStore?.searcheModelOneString?.house_id;

		if (!house_id) {
			house_id = +params.house_id;
		}

		breadCrumbs = breadCrumbs.reverse();
		breadCrumbs.forEach((elem, i) => {
			breadCrumbs[i] = {
				name:
					typeof elem.bcName == "function"
						? elem.bcName({
								params: data.params,
								houseFull: this.houseFull,
								house_id: house_id,
								street: this.street,
								district: this.district,
								region: this.region,
								tag: this.tag,
								provider: this.provider,
								currentNews: this.currentNews,
							})
						: elem.bcName,
				url: !elem.bcRoute
					? generateUrl(elem.route, { ...data.params, page: null })
					: this.calculateUrlFromBcRoute(
							elem.bcRoute({
								params,
								houseFull: this.houseFull,
								house_id: house_id,
								street: this.street,
								district: this.district,
								currentNews: this.currentNews,
							})
						),
			};
		});
		return breadCrumbs;
	}

	dependence(obj, key, arrayDependence, params) {
		arrayDependence.push(Routes[key]);
		let house_id =
			this.rootStore?.searcheModel?.house_id || this.rootStore?.searcheModelOneString?.house_id;

		if (!house_id) {
			house_id = +params?.house_id;
		}

		let depend =
			typeof obj?.depend == "function"
				? obj.depend({
						params,
						houseFull: this.houseFull,
						house_id: house_id,
					})
				: obj?.depend;
		if (depend.length) {
			this.dependence(Routes[depend], depend, arrayDependence);
		}

		return arrayDependence;
	}

	calculateUrlFromBcRoute(data) {
		if (!data) return "";
		return generateUrl(data.route, data.params);
	}
}
