import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";
import { observer, inject } from "mobx-react";

const styles = (theme) => {
	return {
		root: {
			display: "none",
			position: "fixed",
			padding: 0,
			margin: 0,
			top: 0,
			left: 0,
			overflowX: "hidden",
			overflowY: "auto",
			overflowScrolling: "touch",
			"-webkit-overflow-scrolling": "touch",
			border: "none",
			borderRadius: 0,
			zIndex: "105",
			width: "100%",
			height: "100%",
		},
		content: {
			position: "absolute",
			padding: 0,
			margin: 0,
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			overflowX: "hidden",
			zIndex: "10000",
			overflowScrolling: "touch",
			"-webkit-overflow-scrolling": "touch",
		},
		isOpen: {
			display: "block",
		},
	};
};

@inject("ApplicationModel")
@observer
class OurModal extends React.Component {
	static counter = 0;

	static forOpenedModalBody() {
		OurModal.counter++;
		document.querySelector("html").classList.add("_modal-open");
	}

	static forClosedModalBody() {
		OurModal.counter--;
		if (OurModal.counter <= 0) {
			document.querySelector("html").classList.remove("_modal-open");
			OurModal.counter = 0;
		}
	}

	render() {
		let modalModel = this.props.ApplicationModel.modalModel;
		let classes = this.props.classes;

		return (
			<div
				className={classNames(classes.root, {
					[classes.isOpen]: modalModel.isOpenMtsConnect,
				})}
			>
				<div className={classNames(classes.content, "ourModal__content")}>
					{modalModel.contentMtsConnect}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(OurModal);
