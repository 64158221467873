import React from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { graphql } from "@apollo/client/react/hoc";
import { DECLINATION_QUERY } from "Configs/queries";
import withDefaultParams from "Services/withDefaultParams";

@inject("ApplicationModel")
@observer
class DeclinationContainer extends React.Component {
	static defaultProps = {
		type: "nominative",
		preString: "",
		afterString: "",
		defaultString: "",
	};

	render() {
		if (!this.props.data) return null;
		if (!this.props.data.region) return null;
		let region = this.props.data.region;
		if (!region) return this.props.defaultString;
		return region && region.declinations
			? this.props.preString + region.declinations[this.props.type] + this.props.afterString
			: this.props.defaultString;
	}
}

export default withRouter(
	withDefaultParams(
		graphql(DECLINATION_QUERY, {
			skip: (ownProps) => {
				return ownProps.match.params.regionUrl ? false : true;
			},
			options: (props) => {
				let filter = `url=${props.match.params.regionUrl}`;
				return {
					variables: {
						filter: filter,
					},
				};
			},
		})(DeclinationContainer)
	)
);
