import React from "react";
import { PopupB } from "ComponentsUI/Popup";
import { inject, observer } from "mobx-react";
import Metrika from "Services/Metrika";
import { EVENT_CATEGORY_LEAD } from "Constants/metriks/GoogleCategorys";
import { CLICK_POPUP_MOBILE_NEW_VISITOR } from "Constants/metriks/GoogleActions";
import userInfo from "Services/UserInfo";
import { addAddressData, addABData, addRefererData } from "Utils";

@inject("ApplicationModel")
@observer
export default class PopupBContainer extends React.Component {
	state = {
		isError: false,
		error: "",
		phone_connection: "",
		isSuccess: false,
		componentHeight: 0,
	};

	onChangeInputHandler = (e) => {
		this.setState({
			phone_connection: e.target.value,
		});
	};

	onFocus = () => {
		this.setState({
			isError: false,
		});
	};

	onBlur = () => {
		this.setState({
			isError:
				this.state.phone_connection.length !== 11 && this.state.phone_connection.length !== 1,
		});
	};

	onClickHandler = async (e) => {
		if (!!e) {
			e.preventDefault();
			e.stopPropagation();
		}

		let {
			ApplicationModel: { sendingStore, currentRegion, text, popupModel },
		} = this.props;

		let data = {
			phone_connection: this.state.phone_connection,
			region_id: !!currentRegion ? currentRegion.id : null,
			lead_form_type: userInfo("popup_mobile"),
		};

		data = addAddressData(data, this.props.ApplicationModel);
		data = addABData(data);
		data = addRefererData(data, this.props.ApplicationModel);

		let infoUser = {
			name_popup: "PopupB",
		};
		popupModel.setInfoUser(infoUser);

		let error = await sendingStore.onCallback(data);
		sendingStore.setFromPopupB(true);
		if (!error) {
			Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_LEAD, CLICK_POPUP_MOBILE_NEW_VISITOR);
			this.setState({
				isSuccess: true,
			});
		} else {
			this.setState({
				error: text.CALLBACK_ERROR,
			});
		}
	};

	render() {
		let {
			ApplicationModel: {
				sendingStore,
				windowSizeModel: { windowSizes },
				text,
			},
		} = this.props;
		let isSuccess = this.state.isSuccess || sendingStore.send;
		return (
			<PopupB
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onChangeInputHandler={this.onChangeInputHandler}
				onClickHandler={this.onClickHandler}
				inputValue={this.state.phone_connection}
				text={text}
				closeModal={this.props.closeModal}
				success={isSuccess}
				windowSizes={windowSizes}
				errorText={this.state.error}
				text={text}
			/>
		);
	}
}
