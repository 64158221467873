import React, { forwardRef } from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	withAction: {},
	price: {
		display: "flex",
		flexDirection: "column",
	},
	value: {
		fontSize: "18px",
		fontFamily: theme.fonts.bold,
		lineHeight: 1,
		color: theme.palette.colors.darkSlateBlue2,
		letterSpacing: "-0.5px",
		position: "relative",
		wordWrap: "break-word",
	},
	valueAction: {
		textDecoration: "line-through",
		textDecorationColor: theme.palette.colors.purplishBlue,
		textDecorationThickness: "2px",
	},
	valueSale: {
		fontSize: "18px",
		fontFamily: theme.fonts.bold,
		lineHeight: 1,
		color: theme.palette.colors.liteGreen,
		letterSpacing: "-0.5px",
		position: "relative",
		wordWrap: "break-word",
		"&:after": {
			content: "'*'",
			position: "relative",
			display: "inline-block",
			color: theme.palette.colors.liteGreen,
			top: "-2px",
			right: "-1px",
		},
	},
	measure: {
		width: "50px",
		fontSize: "12px",
		fontFamily: theme.fonts.medium,
		lineHeight: 1,
		color: theme.palette.colors.oneMoreGrey,
		letterSpacing: "2px",
		textTransform: "uppercase",
	},
	measureAction: {},
	[`@media (min-width: ${theme.media.lg})`]: {
		root: {
			maxWidth: "64px",
		},
		price: {
			maxWidth: "64px",
		},
		value: {
			maxWidth: "60px",
			marginBottom: "6px",
		},
		valueSale: {
			maxWidth: "64px",
			marginBottom: "6px",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			flexDirection: "row",
			alignItems: "center",
			marginLeft: "10px",
			maxWidth: "210px",
		},
		price: {
			flexDirection: "row",
			maxWidth: "150px",
		},
		value: {
			maxWidth: "150px",
		},
		valueAction: {
			maxWidth: "70px",
		},
		valueSale: {
			marginLeft: "10px",
			maxWidth: "69px",
		},
		measure: {
			marginLeft: "5px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			alignItems: "center",
			flexWrap: "wrap",
			justifyContent: "center",
			marginLeft: "10px",
			maxWidth: "185px",
		},
		price: {
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "center",
			maxWidth: "185px",
			paddingTop: "1px",
			marginBottom: "9px",
		},
		value: {
			maxWidth: "185px",
		},
		valueSale: {
			maxWidth: "185px",
			marginLeft: "9px",
		},
	},
	[`@media (min-width: ${theme.media.tariffsSmall}) and (max-width: ${theme.media.sm})`]: {
		root: {
			flexDirection: "row",
			alignItems: "center",
			maxWidth: "125px",
			width: "125px",
		},
		withAction: {
			alignItems: "flex-end",
		},
		price: {
			alignItems: "flex-start",
			maxWidth: "65px",
		},
		value: {
			maxWidth: "65px",
		},
		valueSale: {
			maxWidth: "65px",
			marginLeft: "auto",
			"&:after": {
				position: "absolute",
				left: "100%",
				right: "0",
			},
		},
		valueAction: {
			marginLeft: "auto",
		},
		measure: {
			marginLeft: "5px",
		},
		measureAction: {
			marginBottom: "3px",
			marginLeft: "10px",
		},
	},
	[`@media (max-width: ${theme.media.tariffsSmall})`]: {
		root: {
			position: "relative",
			maxWidth: "185px",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "flex-end",
			left: "30px",
		},
		price: {
			maxWidth: "125px",
			alignItems: "center",
		},
		value: {
			maxWidth: "125px",
			letterSpacing: 0,
		},
		valueSale: {
			maxWidth: "125px",
			position: "relative",
			// left: "4px",
			letterSpacing: 0,
			marginLeft: "auto",
			"&:after": {
				position: "absolute",
				left: "100%",
				right: 0,
			},
		},
		valueAction: {
			marginLeft: "auto",
		},
		measure: {
			marginLeft: "8px",
			marginBottom: "3px",
		},
		measureAction: {
			marginLeft: "10px",
		},
	},
}));

const MeasurePrice = forwardRef((props, ref) => {
	const { value, valueSale, withAsterisk, measure } = props;
	const classes = useStyles();

	return (
		<div
			ref={ref}
			className={classNames(classes.root, {
				[classes.withAction]: withAsterisk,
			})}
		>
			<div className={classes.price}>
				<span
					className={classNames(classes.value, {
						[classes.valueAction]: withAsterisk,
					})}
				>
					{value}
				</span>
				{withAsterisk && <div className={classes.valueSale}>{valueSale}</div>}
			</div>
			{!!measure && (
				<div
					className={classNames(classes.measure, {
						[classes.measureAction]: withAsterisk,
					})}
				>
					{measure}
				</div>
			)}
		</div>
	);
});

export default MeasurePrice;
