import React from "react";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			marginTop: "9px",
			display: "flex",
			alignItems: "flex-start",
		},
		logo: {
			marginRight: "8px",
			marginTop: "3px",
			"& span": {
				color: theme.palette.colors.disabled,
				fontSize: "24px",
			},
		},
		text: {
			fontFamily: "MuseoSansCyrl-500",
			fontSize: "15px",
			color: theme.palette.colors.grayishBlue,
			lineHeight: "1.2",
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			text: {
				width: "240px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				alignItems: "center",
				paddingRight: "8.33%",
			},
			logo: {
				marginRight: "10px",
			},
		},
	};
};

class ReviewItemStatus extends React.Component {
	render() {
		let classes = this.props.classes;
		return (
			<div className={classes.root}>
				<div className={classes.logo}>{this.props.svg}</div>
				<div className={classes.text}>{this.props.text}</div>
			</div>
		);
	}
}

export default withStyles(styles)(ReviewItemStatus);
