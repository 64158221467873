import React, { useState } from "react";
import { inject } from "mobx-react";
import { sendMetriks } from "Services/sendMetriks";
import userInfo from "Services/UserInfo";
import AlertWrapper from "ComponentsUI/AlertWrapper";
import WaitingCall from "ComponentsUI/WaitingCall";
import { addAddressData, addABData, addRefererData } from "Utils";

const WaitingCallContainer = (props) => {
	const [step, setStep] = useState(1);
	const [errorCounter, setErrorCounter] = useState(0);
	const [errorPhone, setErrorPhone] = useState("");
	const [error, setError] = useState(false);
	const [phone, setPhone] = useState("");

	const {
		ApplicationModel: {
			sendingStore,
			currentRegion,
			windowSizeModel: { windowSizes },
			text,
			popupModel,
		},
	} = props;

	const goToRates = async () => {
		let regionId = currentRegion?.id || null;
		let data = {
			no_address: 1,
			phone_connection: phone,
			region_id: regionId,
			lead_form_type: userInfo("callback"),
		};

		if (props.providerId) {
			data.provider_id = props.providerId;
		}
		data = addAddressData(data, props.ApplicationModel);
		data = addABData(data);
		data = addRefererData(data, props.ApplicationModel);

		let infoUser = {
			name_popup: "WaitingCall",
		};
		popupModel.setInfoUser(infoUser);

		let errorSending = await sendingStore.onCallback(data);
		if (!errorSending) {
			sendMetriks("CLICK_CALLBACK");
			sendMetriks("SEND_LEADPOPUP");
			setStep(2);
			props.onStepChange();
		}
	};

	const deleteError = () => {
		setErrorCounter(0);
		setErrorPhone("");
		setError(false);
	};

	const onChangePhone = (e) => {
		let {
			ApplicationModel: { sendingStore },
		} = props;

		//Событие на начало ввода
		if (e.target.value?.length === 2) {
			sendMetriks("CLICK_NUMBERFROM_LEADPOPUP");
		}

		deleteError();
		sendingStore.setPhone(e.target.value);
		setPhone(e.target.value);
	};

	const onClickButton = () => {
		if (phone.length != 11) {
			setError(true);
			setErrorCounter(errorCounter + 1);
			setErrorPhone(text.check_phone);
			return;
		}
		sendMetriks("LEAD_ALL");
		goToRates();
	};

	const onPressEnter = () => {
		onClickButton();
	};

	return (
		<AlertWrapper error={error && errorPhone} center={true} absoluteNoTop={true}>
			<WaitingCall
				step={step}
				text={text}
				errorCounter={errorCounter}
				error={error}
				phone={phone}
				header={props.header}
				header2={props.header2}
				windowSizes={windowSizes}
				onClose={props.onClose}
				onChangePhone={onChangePhone}
				onClickButton={() => onClickButton()}
				onEnter={() => onPressEnter()}
			/>
		</AlertWrapper>
	);
};

export default inject("ApplicationModel")(WaitingCallContainer);
