import React from "react";
import { useQuery } from "@apollo/client";
import { SITE_REGION_PHONE_QUERY } from "Services/queries";

export const withPhone = (ComposedComponent) => (props) => {
	const region = props?.match?.params?.regionUrl;

	const { data } = useQuery(SITE_REGION_PHONE_QUERY, {
		skip: !region,
		variables: {
			regionUrl: region,
		},
	});

	const phones = data?.siteRegionPhones;

	return <ComposedComponent {...props} siteRegionPhones={phones} />;
};
