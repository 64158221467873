import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import ProviderConnectWithPhoneHOC from "HOC/ProviderConnectWithPhoneHOC";
const styles = (theme) => {
	return {
		phoneWrapper: {
			width: "50px",
			backgroundColor: theme.palette.colors.orange,
			height: "50px",
			borderRadius: "10px",
			overflow: "hidden",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer",
			transition: "background-color .3s ease",
			padding: "0 13px",
			position: "fixed",
			bottom: "140px",
			right: "50px",
			zIndex: "100",
		},
		iconPart: {
			lineHeight: 0,
			"& span": {
				color: theme.palette.colors.white,
				transition: "color .3s ease",
				fontSize: "24px",
			},
		},
		textPart: {
			marginLeft: "23px",
		},
		phone: {
			fontFamily: theme.fonts.bold,
			fontSize: "14px",
			letterSpacing: ".3px",
			color: theme.palette.colors.white,
			transition: "color .3s ease",
			marginBottom: "1px",
		},
		text: {
			fontFamily: theme.fonts.bold,
			fontSize: "9px",
			letterSpacing: "0.8px",
			color: theme.palette.colors.white,
			transition: "color .3s ease",
		},
		fixedTheme: {
			"& $text": {
				display: "none",
			},
		},
		passiveTheme: {
			backgroundColor: theme.palette.colors.transparent,
			"& $iconPart": {
				"& span": {
					color: theme.palette.colors.purplishBlue,
				},
			},
			"& $phone": {
				color: theme.palette.colors.purplishBlue,
			},
			"& $text": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			textPart: {
				marginLeft: "10px",
			},
			text: {
				fontSize: "8px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			iconPart: {
				"& span": {
					fontSize: "20px",
				},
			},
			phoneWrapper: {
				display: "none",
			},
			textPart: {
				display: "none",
			},
			fixedTheme: {
				padding: "0 20px",
				"& $textPart": {
					display: "block",
					marginLeft: "15px",
				},
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			phoneWrapper: {
				display: "none",
			},
			iconPart: {
				"& span": {
					fontSize: "20px",
				},
			},
			textPart: {
				display: "none",
			},
		},
	};
};

class WaitCallPhoneButton extends React.Component {
	static defaultProps = {
		onClickHandler: () => {},
	};

	clickOnIcon() {
		this.props.store.fixedPanelModel.callbackIsOpen = true;
		this.props.store.fixedPanelModel.giftWidgetIsOpen = true;
	}

	render() {
		const { classes, text, phone, insinePhone, fixedTheme, passiveTheme } = this.props;
		let renderPhone = insinePhone;
		if (!renderPhone) {
			renderPhone = phone;
		}
		return (
			<ProviderConnectWithPhoneHOC phone_button={true}>
				<div
					aria-label={"call"}
					className={classNames(classes.phoneWrapper, "roistat-phone-tel", {
						[classes.fixedTheme]: fixedTheme,
						[classes.passiveTheme]: passiveTheme,
					})}
				>
					<div className={classes.iconPart}>
						<span className="icon24 icon-call" />
					</div>
				</div>
			</ProviderConnectWithPhoneHOC>
		);
	}
}

export default withStyles(styles)(WaitCallPhoneButton);
