export const YANDEX_TARGETS = {
	PROVIDERS_STREET_LOAD: "providers_street_load",
	PROVIDERS_TS_LOAD: "providers_ts_load",
	TARIFFS_TS_LOAD: "tariffs_ts_load",
	PROVIDERS_DISTRICT_LOAD: "providers_district_load",
	PROVIDERS_RATES_LOAD: "providers_rates_load",
	CARD_PROVIDERS_RATES_LOAD: "card_providers_rates_load",
	DISTRICT_STREETS_LOAD: "district_streets_load",
	STREETS_HOUSES_LOAD: "streets_houses_load",
	OPERATORY_CLICK_CONNECT: "operatory_click_connect",
	NEDAVNO_PODCLUCHENNYE_ORDERS_TOHOME: "nedavno_podcluchennye_orders_tohome",
	CLICK_PROVIDER: "click_provider",
	CLICK_COMPASSION_PHONE_BUTTON_REAL: "click_compassion_phone_button_real",
	CLICK_CONNECT_TARIF: "click_connect_tarif",
	CLICK_SEND_MOBILE_ORDER: "click_send_mobile_order",
	PROVIDER_NE_VIP: "provider_ne-vip",
	PROVIDER_NE_VIP_WITHOUT_TARIFF: "provider_ne-vip_without_tariff",
	NEDAVNO_PODCLUCHENNYE_KARTOCHKA_PROVA: "nedavno_podcluchennye_kartochka_prova",
	PROVIDER_VIP: "provider_vip",
	PROVIDER_VIP_WITHOUT_TARIFF: "provider_vip_without_tariff",
	TC_WITHOUT_TARIFF: "TC_without_tariff",
	ENTERING_ANY_SYMBOLS: "entering_any_symbols",
	CLICK_DISTRICT_FROM_HOMEPAGE: "click_district_from_homepage",
	CLICK_DISTRICT_FROM_ADDRESS: "click_district_from_address",
	CLICK_TARIFF_FROM_CATALOG: "click_tariff_from_catalog",
	CLICK_TARIFF_FROM_RATING: "click_tariff_from_rating",
	CLICK_CHECK_ADDRESS: "click_check_adress",
	ORDER_FROM_POPUP_SUCCESS_ADDRESS: "order_from_popup_sucsess_adress",
	POPUP_OTHER_PROVIDERS: "popup_other_providers",
	POPUP_NO_RESULT: "popup_no_result",
	CLICK_CONNECT_TARIF_FROM_KARTOCHKA_O_PROVIDERE: "click_connect_tarif_from_kartochka_o_providere",
	CLICK_CONNECT_TARIF_FROM_KARTOCHKA_RATES: "click_connect_tarif_from_kartochka_rates",
	CLICK_CONNECT_TARIF_FROM_TAG_KARTOCHKA_RATES: "click_connect_tarif_from_tag_kartochka_rates",
	CLICK_CHECK_ADDRESS_RATES_TAG_PROVIDER: "click_check_adress_rates_tag_provider",
	CLICK_CHECK_ADRESS_FROM_ACTIONS: "click_check_adress_from_actions",
	CLICK_FIELD_STREET: "click_field_street",
	CLICK_FIELD_HOUSE: "click_field_house",
	CLICK_SELECT_STREET: "click_select_street",
	CLICK_SELECT_HOUSE: "click_select_house",
	CLICK_FIELD_STREET_NEW_SEARCH: "click_field_street_new_search",
	CLICK_SELECT_STREET_AND_HOUSE_NEW_SEARCH: "click_select_street_and_house_new_search",
	CLICK_SELECT_STREET_NEW_SEARCH: "click_select_street_new_search",
	ENTERING_NUMBER_NEW_SEARCH: "entering_number_new_search",
	CLICK_POPUP_WRONG_HOUSE_LOAD_NEW_SEARCH: "click_popup_wrong_house_load_new_search",
	CLICK_FIND_TOHOME_BUTTON_NEW_SEARCH: "click_find_tohome_button_new_search",
	CLICK_FIND_OFFICE_BUTTON_NEW_SEARCH: "click_find_office_button_new_search",
	CLICK_FIND_SAT_BUTTON_NEW_SEARCH: "click_callback_sat_new_search",
	CLICK_FIND_OFFICE_BUTTON: "click_find_office_button",
	CLICK_START_TENDER: "click_start_tender",
	CLICK_SEND_OFFICE_BUTTON: "click_send_office_button",
	CLICK_START_TENDER_NEW_SEARCH: "click_start_tender_new_search",
	CLICK_SEND_OFFICE_BUTTON_NEW_SEARCH: "click_send_office_button_new_search",
	CLICK_PROVIDER_FROM_TOP: "click_provider_from_top",
	open_quiz: "open_quiz",
	PAGE_NOT_FOUND: "page_not_found",
	OPEN_POPUP_EXIT: "open_popup_exit",
	OPEN_POPUP_EXIT1: "open_popup_exit1",
	OPEN_POPUP_EXIT2: "open_popup_exit2",
	OPEN_POPUP_EXIT3: "open_popup_exit3",
	OPEN_POPUP_EXIT4: "open_popup_exit4",
	LOAD_WANT_SIM: "load_want_sim",
	CLICK_CONNECT_WITH_ACTION: "click_connect_with_action",
	LOAD_PAGE_RATES: "load_page_rates",
	CLICK_CHECK_ADDRESS_RATES_TAG: "click_check_adress_rates_tag",
	LOAD_TAG_PAGE_RATES_PROVIDER: "load_tag_page_rates_provider",
	CLICK_3IN1_FROM_HOMEPAGE: "click_3in1_from_homepage",
	CLICK_TERMS_OF_USE_FROM_ORDER: "click_terms_of_use_from_order",
	CLICK_PERSONAL_DATA_FROM_ORDER: "click_personal-data_from_order",
	CLICK_CONSENT_FROM_ORDER: "click_consent_from_order",
	OPEN_WRONG_QUIZ: "open_wrong_quiz",
	CLICK_CONNECT_OPERATORY: "click_connect_operatory",
	USER_SELECT_REGION: "user_select_region",
	CLICK_CLOSE_POPUP_EXIT: "click_close_popup_exit",
	CLICK_CLOSE_POPUP_EXIT1: "click_close_popup_exit1",
	CLICK_CLOSE_POPUP_EXIT2: "click_close_popup_exit2",
	CLICK_CLOSE_POPUP_EXIT3: "click_close_popup_exit3",
	CLICK_CLOSE_POPUP_EXIT4: "click_close_popup_exit4",
	CLICK_BUTTON_POPUP_EXIT: "click_button_popup_exit",
	CLICK_BUTTON_POPUP_EXIT1: "click_button_popup_exit1",
	CLICK_BUTTON_POPUP_EXIT2: "click_button_popup_exit2",
	CLICK_BUTTON_POPUP_EXIT3: "click_button_popup_exit3",
	CLICK_BUTTON_POPUP_EXIT4: "click_button_popup_exit4",
	OPEN_RATESMOBILE_REGION: "open_ratesmobile_region",
	OPEN_ACTIONS_OPERATORY: "open_actions_operatory",
	OPEN_NOMERA_OPERATOR: "open_nomera_operator",
	OPEN_NOMERA_REGION: "open_nomera_region",
	CLICK_CONNECT_NOMERA: "click_connect_nomera",
	LOAD_TARIFF_WITH_WANT_SIM: "load_tariff_with_want_sim",
	CLICK_CONNECT_MTC_FROM_RATESMOBILE: "click_connect_MTC_from_ratesmobile",
	CLICK_CONNECT_MTC_FROM_NOMERA: "click_connect_MTC_from_nomera",
	CLICK_CONNECT_TARIFF_FROM_TAG_RATES: "click_connect_tariff_from_tag_rates",
	OPEN_POPUP_EXIT_CAT: "open_popup_exit_cat",
	CLICK_CLOSE_POPUP_EXIT_CAT: "click_close_popup_exit_cat",
	CLICK_BUTTON_POPUP_EXIT_CAT: "click_button_popup_exit_cat",
	LEAD_ALL: "lead_all",
	OPEN_ORDER_FORM: "open_order_form",
	CLICK_SEND_ORDER_WITH_TARIFF: "click_send_order_with_tariff",
	CLICK_RATES_POPUP1_FROM_QUIZ_SEND_PHONE: "click_rates_popup1_from_quiz_send_phone",
	CLICK_SEND_ORDER_OFFICE: "click_send_order_office",
	CLICK_SEND_ORDER_WITHOUT_TARIFF: "click_send_order_without_tariff",
	CLICK_CALLBACK: "click_callback",
	CALLBACK_PROV_PAGE: "callback_prov_page",
	CLICK_COMPASSION_PHONE_BUTTON_FAKE: "click_compassion_phone_button_fake",
	CLICK_FIND_TOHOME_BUTTON: "click_find_tohome_button",
	CLICK_HEADER: "click_header",
	CLICK_POPUP_WRONG_HOUSE_LOAD: "click_popup_wrong_house_load",
	CLICK_REGISTRATION_BUTTON_REGISTER: "click_registration_button_register",
	CLICK_FEEDBACK_BUTTON: "click_feedback_button",
	OPEN_RECENTLY_CONNECTED_PROVIDERS: "open_recently_connected_providers",
	OPEN_RECENTLY_CONNECTED_OPERATORY: "open_recently_connected_operatory",
	OPEN_WATCH_FROM_FEEDBACK: "open_watch_from_feedback",
	CLICK_WATCH_FROM_FEEDBACK: "click_watch_from_feedback",
	OPEN_CALLBACK: "open_callback",
	VISIT_PROVIDERS: "visit_providers",
	VISIT_SELECT_REGION: "visit_select_region",
	VISITOR_MOBILE: "visitor_mobile",
	VISITOR_TABLET: "visitor_tablet",
	VISITOR_DESKTOP: "visitor_desktop",
	CLICK_TARIFF_FROM_OTZYVY_NEVIP: "click_tariff_from_otzyvy_nevip",
	CLICK_TARIFF_FROM_OTZYVY_VIP: "click_tariff_from_otzyvy_vip",
	OPEN_OTZYVY_VIP: "open_otzyvy_vip",
	OPEN_OTZYVY_NEVIP: "open_otzyvy_nevip",
	CLICK_CALLBACK_FROM_RATING_MAIN: "click_callback_from_rating_main",
	OPEN_NEW_TEXT: "open_new_text",
	CLICK_NEW_TEXT: "click_new_text",
	OPEN_CONNECT_TARIFF: "Open_connect_tariff",
	SEND_CONNECT_TARIFF: "send_connect_tariff",
	CLICK_BUTTON_POPUP_EXIT_HOLD: "click_button_popup_exit_hold",
	CLICK_CONNECT_UNIKALNYE_REGION: "click_connect_unikalnye_region",
	CLICK_CONNECT_UNIKALNYE_MTS: "click_connect_unikalnye_mts",
	CLICK_CONNECT_UNIKALNYE_OPERATOR: "click_connect_unikalnye_operator",
	CLICK_FIELD_NUMBER_POPUP_EXIT: "click_field_number_popup_exit",
	OPEN_QUIZ_MOBILE: "open_quiz_mobile",
	OPEN_QUIZ_TABLET: "open_quiz_tablet",
	OPEN_QUIZ_DESKTOP: "open_quiz_desktop",
	CLICK_OUT_QUIZ: "click_out_quiz",
	CLOSE_QUIZ: "close_quiz",
	CLICK_BUTTON_WHATSAPP: "click_button_whatsapp",
	CLICK_WHATSAPP_HEADER: "click_whatsapp_header",
	CLICK_BUTTON_WHATSAPP_MOBILE: "click_button_whatsapp_mobile",
	OPEN_ACTION_DETAL: "open_action_detal",
	OPEN_ACTION_REGION: "open_action_region",
	REGION_YES: "region_yes",
	REGION_OTHER: "region_other",
	OPEN_YOU_REGION: "open_you_region",
	REDIRECT_MAIN_WITHOUT_REGION: "redirect_main_without_region",
	CLICK_PHONE: "click_phone",
	CLICK_GO_SITE: "click_go_site",
	ENTER_NUMBER: "enter_number",
	CLICK_CONNECT_PROV1: "click_connect_prov1",
	CLICK_CONNECT_PROV2: "click_connect_prov2",
	CLICK_FIND_ADDRESS_HEADER: "click_find_address_header",
	OPEN_FORM_1CLICK: "open_form_1click",
	CLICK_PHONE_1CLICK: "click_phone_1click",
	SEND_1CLICK: "send_1click",
	OPEN_OTZYVY_REGION: "open_otzyvy_region",
	OPEN_MAIN: "open_main",
	CHANGE_ANTIFILTR: "change_antifiltr",
	CLICK_DUDO: "click_dudo",
	CLICK_BANNER_PHONE_BUTTON_REAL: "click_banner_phone_button_real",
	CLICK_FOOTER_PHONE_BUTTON_REAL: "click_footer_phone_button_real",
	CLICK_NUMBERFROM_LEADPOPUP: "click_numberform_leadpopup",
	SEND_LEADPOPUP: "send_leadpopup",
	CLICK_DETAIL_MTS: "click_detail_MTS",
	CLICK_DETAIL_MOB_TARIFF: "click_detail_mob_tariff",
	OPEN_ESIM: "open_esim",
	CLICK_CONNECT_ESIM_MTS: "click_connect_esim_mts",
	CLOSE_ESIM_MTS: "close_esim_mts",
	CLICK_SPISOK: "click_spisok",
	CLICK_CONSTRUCTOR: "click_constructor",
	CLICK_INSTRUCTION: "click_instruction",
	CLICK_CONNECT_TINKOFF_FROM_RATESMOBILE: "click_connect_tinkoff_ratesmobile",
	CLICK_CONNECT_BEELINE_FROM_RATESMOBILE: "click_connect_beeline_ratesmobile",
	CLICK_CONNECT_MEGAFON_FROM_RATESMOBILE: "click_connect_megafon_ratesmobile",
	CLICK_CONNECT_TELE2_FROM_RATESMOBILE: "click_connect_tele2_ratesmobile",
	CLICK_CONNECT_YOTA_FROM_RATESMOBILE: "click_connect_yota_ratesmobile",
	CLICK_CONNECT_SBER_FROM_RATESMOBILE: "click_connect_sber_ratesmobile",
	CLICK_CONNECT_TINKOFF_NOMERA: "click_connect_tinkoff_nomera",
	CLICK_CONNECT_BEELINE_NOMERA: "click_connect_beeline_nomera",
	CLICK_CONNECT_MEGAFON_NOMERA: "click_connect_megafon_nomera",
	CLICK_CONNECT_TELE2_NOMERA: "click_connect_tele2_nomera",
	CLICK_CONNECT_YOTA_NOMERA: "click_connect_yota_nomera",
	CLICK_CONNECT_SBER_NOMERA: "click_connect_sber_nomera",
	SCROLL_TO_1CLICK: "scroll_to_1click",
	OPEN_QUIZ_TEST: "open_quiz_test",
	CLICK_RATES_POPUP1_FROM_QUIZ_SEND_PHONE_TEST: "click_rates_popup1_from_quiz_send_phone_test",
	CLICK_ERROR_REQUEST_TEST: "click_error_request_test",
	CLICK_AUDIO_ON_1ClICK: "сlick_audio_on_1click",
	CLICK_AUDIO_OFF_1ClICK: "сlick_audio_off_1click",
	CLICK_CONNECT_ACTION: "click_connect_action",
	POP_UP_OPERATOR_CLOSED: "pop-up_operator_closed",
	POP_UP_OPERATOR_MNP: "pop-up_operator_mnp",
	POP_UP_OPERATOR_SIM: "pop-up_operator_sim",
	POP_UP_OPERATOR_ESIM: "pop-up_operator_esim",
	POP_UP_OPERATOR_CHANGE: "pop-up_operator_change",
	POP_UP_OPERATOR_CLICK_CONNECT: "pop-up_operator_click_connect",
	POP_UP_OPERATOR_WHATSAPP: "pop-up_operator_whatsapp",
	OPEN_TC: "open_TC",
	OPEN_TC_POPULAR: "open_TC_popular",
	OPEN_TC_NZL: "open_TC_nzl",
	CLICK_ON_GPT: "click_on_gpt",
	OPEN_OTZYV_DOM: "open_otzyv_dom",
	VIEW_TEST_SKOR: "view_test_skor",
	CLICK_TEST_SCOR: "click_test_scor",
	CLICK_PODROB_UNLIM_STREET: "click_podrob_unlim_street",
	CLICK_PODROB_UNLIM_DOM: "click_podrob_unlim_dom",
	VIEW_CHAVO_STREET: "view_chavo_street",
	VIEW_CHAVO_HOUSE: "view_chavo_house",
	OPEN_CHAVO_STREET: "open_chavo_street",
	OPEN_CHAVO_HOUSE: "open_chavo_house",
	CLICK_BAN_OTZYV_STREET: "click_ban_otzyv_street",
	CLICK_BAN_OTZYV_HOUSE: "click_ban_otzyv_house",
	CLICK_OTPR_OTZYV_STREET: "click_otpr_otzyv_street",
	CLICK_OTPR_OTZYV_HOUSE: "click_otpr_otzyv_house",
	CLICK_101RUB_STREET: "click_101rub_street",
	CLICK_101RUB_HOUSE: "click_101rub_house",
	CLICK_ON_NUMBER_HOUSE: "click_on_number_house",
	CLICK_ON_NAME_STREET: "click_on_name_street",
	OPEN_ONLINE_ZAYAVKA_STEP_1: "open_online_zayavka_step_1",
	OPEN_ONLINE_ZAYAVKA_STEP_2: "open_online_zayavka_step_2",
	ONLINE_ZAYAVKA_CALENDAR: "online_zayavka_calendar",
	ONLINE_ZAYAVKA_SLOT: "online_zayavka_slot",
	ONLINE_ZAYAVKA_STEP_2_SEND: "online_zayavka_step_2_send",
	ONLINE_ZAYAVKA_STEP_2_NO_PHONE_SEND: "online_zayavka_step_2_no_phone_send",
	ONLINE_ZAYAVKA_SLOT_UNAVAILABLE: "online_zayavka_slot_unavailable",
	ONLINE_ZAYAVKA_SLOT_AVAILABLE: "online_zayavka_slot_available",
	ONLINE_ZAYAVKA_HOT_SLOT_AVAILABLE: "online_zayavka_hot_slot_available",
	OPEN_ONLINE_ZAYAVKA_STEP_3: "open_online_zayavka_step_3",
	ONLINE_ZAYAVKA_TOOLTIP: "online_zayavka_tooltip",
	ONLINE_ZAYAVKA_CHANGE: "online_zayavka_change",
	ONLINE_ZAYAVKA_PHONE_SEND: "online_zayavka_phone_send",
	OPEN_ONLINE_ZAYAVKA_STEP_4: "open_online_zayavka_step_4",
	OPEN_ONLINE_ZAYAVKA_STEP_5: "open_online_zayavka_step_5",
	ONLINE_ZAYAVKA_WA: "online_zayavka_wa",
	ONLINE_ZAYAVKA_TG: "online_zayavka_tg",
	ONLINE_ZAYAVKA_CLICK_PHONE: "online_zayavka_click_phone",
	ONLINE_ZAYAVKA_DETAILS: "online_zayavka_details",
	CLICK_EXCLUSIVE_NEW_TARIFF: "click_exclusive_new_tariff",
	CLICK_ACTION_NEW_TARIFF: "click_action_new_tariff",
	NEW_OPEN_CONNECT_TARIFF: "new_open_connect_tariff",
	OPEN_DETAILS_NEW_TARIFF: "open_details_new_tariff",
	CLOSE_DETAILS_NEW_TARIFF: "close_details_new_tariff",
	VIEW_SEARCH: "view_seacrh",
	ENTERING_ANY_SYMBOLS_NEW: "entering_any_symbols_new",
	CLICK_POISK_GLAVNAYA_BEZ_REGIONA: "click_poisk_glavnaya_bez_regiona",
	CLICK_PODKLUCHIT: "click_podkluchit",
	OPEN_OFFICE: "open_office",
	CLICK_FIND_DACHA_BUTTON: "click_find_dacha_button",
	CLICK_POKRYTIE_CATALOG: "click_pokrytie_catalog",
	CLICK_WIDGET_RATING: "click_on_catbutton_vidget_os",
	CLICK_SUBMIT_BUTTON_IN_WIDGET_RATING: "send_score_vidget_os",
};
