import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { CloseButton } from "Constants/SVG";

const useStyles = createUseStyles((theme) => ({
	dialog: {
		width: "100%",
		height: "100%",
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
	},
	bg: {
		position: "fixed",
		zIndex: "9998",
		backgroundColor: "rgba(17, 24, 39, 0.24)",
	},
	popup: {
		width: "100%",
		position: "fixed",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: "16px",
		padding: "32px 12px 24px",
		backgroundColor: "#ffffff",
		borderRadius: "24px",
		zIndex: "9999",
	},
	close: {
		position: "absolute",
		top: "8px",
		right: "8px",
		width: "2.25rem",
		height: "2.25rem",
		padding: "8px",
		border: "none",
		background: "none",
		cursor: "pointer",
		"& svg": {
			width: "1.25rem",
			height: "1.25rem",
			color: "#6b7280",
		},
	},
	["@media (min-width: 1280px)"]: {
		popup: {
			width: "376px",
			paddingRight: "16px",
			paddingLeft: "16px",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
		},
	},
}));

export const Popup = (props) => {
	const { children, isOpen, onClose } = props;
	const classes = useStyles();
	const closeOnClickLayout = true;
	const closeHandler = () => (closeOnClickLayout ? onClose() : "");

	return (
		<>
			{isOpen && (
				<div>
					<div
						className={cn(classes.dialog, classes.bg)}
						onClick={closeHandler}
						aria-label="Диалоговое окно"
					></div>
					<div className={classes.popup}>
						{children}
						<button className={classes.close} onClick={onClose} aria-label="Закрыть">
							{CloseButton}
						</button>
					</div>
				</div>
			)}
		</>
	);
};
