import React from "react";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import { pipe } from "Utils/functionalUtils";
import NoIndex from "ComponentsUI/NoIndex";
import PopupAHOC from "HOC/PopupAHOC";
import RecentlyConnectedTariffConnectHOC from "HOC/RecentlyConnectedTariffConnectHOC";
import ProvidersOrderPopup from "Popups/ProvidersOrderPopup";
import TariffsConnectHOC from "HOC/TariffsConnectHOC";
import { generateUrl } from "Services/withDefaultParams";
import Routes from "Configs/routes";

const ConnectButton = (props) => {
	let {
		ApplicationModel: {
			siteConfig: {
				settings: { connect_tariff_button, recently_connect_tariff_button, connect_action },
			},
			modalModel,
			sendingStore,
			text,
			tariffsModel,
		},
		data,
	} = props;

	let connectTariffButton = props.recentlyConnect
		? recently_connect_tariff_button
		: connect_tariff_button;

	let orderToHomeUrl = generateUrl(Routes.OrderHome.route, {
		regionUrl: data.region.url,
	});
	orderToHomeUrl = {
		pathname: orderToHomeUrl,
	};

	let addressUrl = generateUrl(Routes.AddressDistricts.route, {
		regionUrl: props.match.params.regionUrl,
	});
	let linkUrl = props.compare && !data.provider.vip_provider ? addressUrl : orderToHomeUrl;

	let button_text;
	if (data?.provider?.provider_settings?.tariffs?.value === "button_text" && !props.compare) {
		button_text = data.provider.provider_settings.tariffs.button_text;
	} else {
		button_text = props.comparisonConnect
			? props.comparisonConnect
			: props.showActionButton
				? text.CONNECT_ACTION
				: text.connect;
	}

	let buttonConnectComponent = (
		<Link to={linkUrl} onClick={() => onClickHandler(props, data.id)}>
			<NoIndex>
				<span>
					{props.compareIsHideInConnectTariffs || props.orderToHomeCompareBtnHidden
						? text.tariffsReadMore
						: button_text}
				</span>
				{props.compare && <span className="icon24 icon-power" />}
			</NoIndex>
		</Link>
	);
	if (data.provider.vip_provider == 0) {
		buttonConnectComponent = null;
	}

	let buttonConnect = (
		<PopupAHOC tariffId={data.id}>
			<span>
				<span>
					{props.compareIsHideInConnectTariffs || props.orderToHomeCompareBtnHidden
						? text.tariffsReadMore
						: button_text}
				</span>
			</span>
		</PopupAHOC>
	);

	const onClickHandler = (props, tariff_id) => {
		tariffsModel.setTariffId(tariff_id);
		tariffsModel.setABTest(
			props.match.path === Routes.DomaNzl.route || props.match.path === Routes.RatesInHouse.route
		);
	};
	if (data.provider.vip_provider == 1) {
		if (data.provider.contacts && !props.recentlyConnect) {
			if (data.provider.contacts.card == 3) {
				buttonConnect = (
					<NoIndex>
						<span onClick={() => props.onClickHref()}>
							<span>
								{props.compareIsHideInConnectTariffs || props.orderToHomeCompareBtnHidden
									? text.tariffsReadMore
									: button_text}
							</span>
							{props.compare && <span className="icon24 icon-power" />}
						</span>
					</NoIndex>
				);
			} else if (data.provider.contacts.card == 4) {
				buttonConnect = (
					<PopupAHOC tariffId={data.id}>
						<NoIndex>
							<span>
								<span>
									{props.compareIsHideInConnectTariffs || props.orderToHomeCompareBtnHidden
										? text.tariffsReadMore
										: button_text}
								</span>
								{props.compare && <span className="icon24 icon-power" />}
							</span>
						</NoIndex>
					</PopupAHOC>
				);
			} else if (
				data.provider.contacts.card == 0 ||
				data.provider.contacts.card == 1 ||
				data.provider.contacts.card == 2
			) {
				buttonConnect = buttonConnectComponent;
			}
		}

		if (connectTariffButton) {
			let connectButtonSetting =
				connectTariffButton[props.pageName] ||
				(props.recentlyConnect ? "check_coverage" : "connect");

			if (connectButtonSetting === "check_coverage") {
				let CheckAddressButton = ({ onCheckButtonClick }) => {
					return (
						<a>
							<span onClick={() => onCheckButtonClick(data)}>{text.CHECK_ADDRESS}</span>
						</a>
					);
				};

				buttonConnect = (
					<RecentlyConnectedTariffConnectHOC
						action={props.action}
						recently={props.recently}
						isRates={props.isRates}
						isCard={props.isCard}
						isRatesTag={props.isRatesTag}
					>
						<CheckAddressButton />
					</RecentlyConnectedTariffConnectHOC>
				);
			} else if (
				connectButtonSetting === "connect" &&
				connect_action === "popup" &&
				data?.provider?.contacts?.card !== 3
			) {
				buttonConnect = (
					<ProvidersOrderPopup
						tariff={data}
						modalModel={modalModel}
						sendingStore={sendingStore}
						text={text}
						providerInfo={data.provider}
					>
						<NoIndex>
							<span>{button_text}</span>
						</NoIndex>
						{props.compare && <span className="icon24 icon-power" />}
					</ProvidersOrderPopup>
				);
			} else if (connectButtonSetting === "connect" && props.recentlyConnect) {
				buttonConnect = buttonConnectComponent;
			}
		}
	} else if (!props.recentlyConnect) {
		buttonConnect = (
			<>
				<TariffsConnectHOC hideProviderLogo={props.hideProviderLogo}>
					<span style={{ textAlign: "center" }}>
						<span>{text.CHECK_COVERAGE}</span>
						{props.compare && <span className="icon24 icon-power" />}
					</span>
				</TariffsConnectHOC>
			</>
		);
	}
	return buttonConnect;
};

export default pipe(observer, inject("ApplicationModel"), withRouter)(ConnectButton);
