import { gql } from "@apollo/client";

const MUTATE_INFO_CLICK = gql`
	mutation (
		$name: String!
		$position: Int
		$search_url: String
		$uuid_user: String
		$user_agent: String
		$width: Int
		$height: Int
		$experiment: String
		$variant: String
	) {
		infoClick(
			name: $name
			position: $position
			search_url: $search_url
			user_info: { uuid_user: $uuid_user, user_agent: $user_agent, width: $width, height: $height }
			ab_test: { experiment: $experiment, variant: $variant }
		)
	}
`;

export default MUTATE_INFO_CLICK;
