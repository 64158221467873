import Routes from "Configs/routes";

export const isMobilePage = (path) =>
	[
		Routes.RatesMobile.route,
		Routes.BeautifulNumbers.route,
		Routes.Operatory.route,
		Routes.OperatorDetails.route,
		Routes.OperatoryTariffs.route,
		Routes.OperatoryBeautifulNumbers.route,
		Routes.OperatoryActions.route,
	].includes(path);

export const showExitPopup = (path, location, tariff_id = 0, house_id = 0) => {
	let flag = true;

	if (
		[
			Routes.Business.route,
			Routes.Providers.route,
			Routes.Rating.route,
			Routes.RatesInHouse.route,
			Routes.DomaNzl.route,
		].includes(path)
	) {
		flag = false;
	}

	if (path === Routes.OrderHome.route && tariff_id) {
		flag = false;
	}

	//TODO Тут должна еще быть проверка на vip_provider
	if (
		[
			Routes.ProviderDetails.route,
			Routes.ProviderRates.route,
			Routes.ProviderRatesTags.route,
		].includes(path)
	) {
		flag = false;
	}

	return flag;
};

const regexp = {
	"101internet":
		/^\/(moskva|moskovskaya-oblast|sankt-peterburg|leningradskaya-oblast|compare$|orders\/home$|about$|user$)|(\?backUrl=|\?d|\?sa=|\?utm_source=|\?momentcode=|\?u_house=|\?regionId=|lentest|contact-feedback$|feedback$|about\/terms-of-use$|about\/personal-data$|about\/contacts$|about\/partners$|business\?house_id=|orders\/tohome\?providerId=)/,
	moskvaonline:
		/^\/(moskovskaya-oblast\/about\/contacts$|user$)|(\?momentcode=|\?u_house="|lentest|contact-feedback$|feedback$|business\?house_id=)/,
	piteronline:
		/^\/(leningradskaya-oblast\/about\/contacts$|user$)|(\?momentcode=|\?u_house="|lentest|contact-feedback$|feedback$|business\?house_id=)/,
};

export const noIndex = (url, siteCode) => {
	return !!regexp[siteCode].test(url);
};
