import { observable, action } from "mobx";
import OurModal from "ComponentsUI/OurModal";
import OurModalExit from "ComponentsUI/OurModalExit";
import OurModalAgreement from "ComponentsUI/OurModalAgreement";
import OurModalMtsConnect from "ComponentsUI/OurModalMtsConnect";

export default class ModalModel {
	@observable isOpen = false;
	@observable isOpenExit = false;
	@observable isOpenAgreement = false;
	@observable isOpenMtsConnect = false;
	@observable popapA = false;
	@observable popapB = false;
	@observable content = null;
	@observable contentExit = null;
	@observable contentAgreement = null;
	@observable contentMtsConnect = null;

	constructor() {}

	@action
	openModal(content) {
		if (content) {
			OurModal.forOpenedModalBody();
			this.content = content;
			this.isOpen = true;
		}
	}

	@action
	openModalExit(content) {
		if (content) {
			OurModalExit.forOpenedModalBody();
			this.contentExit = content;
			this.isOpenExit = true;
		}
	}

	@action
	openModalAgreement(content) {
		if (content) {
			OurModalAgreement.forOpenedModalBody();
			this.contentAgreement = content;
			this.isOpenAgreement = true;
		}
	}

	@action
	openModalMtsConnect(content) {
		if (content) {
			OurModalMtsConnect.forOpenedModalBody();
			this.contentMtsConnect = content;
			this.isOpenMtsConnect = true;
		}
	}

	@action setPopupA(value) {
		this.popapA = value;
	}

	@action setPopupB(value) {
		this.popapB = value;
	}

	@action
	closeModal() {
		OurModal.forClosedModalBody();
		this.isOpen = false;
		this.content = null;
	}

	@action
	closeModalExit() {
		OurModalExit.forClosedModalBody();
		this.isOpenExit = false;
		this.contentExit = null;
	}

	@action
	closeModalAgreement() {
		OurModalAgreement.forClosedModalBody();
		this.isOpenAgreement = false;
		this.contentAgreement = null;
	}

	@action
	closeModalMtsConnect() {
		OurModalMtsConnect.forClosedModalBody();
		this.isOpenMtsConnect = false;
		this.contentMtsConnect = null;
	}

	scrollToTop(duration = 400, selector) {
		OurModal.scrollToTop(duration, selector);
	}

	toggleModal(content) {
		if (!this.isOpen) {
			this.openModal(content);
		} else {
			this.closeModal();
		}
	}

	toggleModalExit(content) {
		if (!this.isOpenExit) {
			this.openModalExit(content);
		} else {
			this.closeModalExit();
		}
	}

	toggleModalAgreement(content) {
		if (!this.isOpenAgreement) {
			this.openModalAgreement(content);
		} else {
			this.closeModalAgreement();
		}
	}

	toggleModalMtsConnect(content) {
		if (!this.isOpenMtsConnect) {
			this.openModalMtsConnect(content);
		} else {
			this.closeModalMtsConnect();
		}
	}

	@action
	setContent(content) {
		this.content = content;
	}
}
