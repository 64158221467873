import React from "react";
import Button from "ComponentsUI/Button";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { SVGWhatsapp } from "Constants/SVG";
import { sendMetriks } from "Services/sendMetriks";

const useStyles = createUseStyles((theme) => ({
	root: {
		height: "50px",
		width: "50px",
		position: "fixed",
		overflow: "hidden",
		right: "50px",
		bottom: "210px",
		cursor: "pointer",
		zIndex: 105,
	},
	header: {
		height: "40px",
		width: "40px",
		marginLeft: "15px",
		cursor: "pointer",
		zIndex: 105,
	},
	sizeRoot: {
		height: "50px",
		width: "50px",
	},
	sizeHeader: {
		height: "40px",
		width: "40px",
	},
	or: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginTop: "10px",
		fontSize: "14px",
		lineHeight: "1.14",
		letterSpacing: "2px",
		fontFamily: theme.fonts.bold,
		color: theme.palette.colors.veryDarkGray,
	},
	popupOperatorWrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "baseline",
		fontSize: "14px",
		lineHeight: "16.8px",
		fontWeight: 300,
		color: theme.palette.colors.oneMoreGrey,
		marginTop: "10px",
	},
	popupOperatorContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "baseline",
		marginLeft: "7px",
		cursor: "pointer",
	},
	popupOperatorText: {
		display: "inline-flex",
		fontWeight: 700,
		color: theme.palette.colors.whatsapp,
		marginLeft: "5px",
	},
	pricingButton: {
		height: "40px",
		width: "40px",
		cursor: "pointer",
	},
	[`@media (max-width: ${theme.media.md})`]: {
		root: {
			display: "none",
		},
	},
}));

const WhatsappSendMessage = (props) => {
	const classes = useStyles();
	const sizeClasses = {
		[classes.sizeRoot]: !props.changeStyle,
		[classes.sizeHeader]: props.changeStyle === "header",
	};

	const clickHandler = () => {
		if (props.whatsappType === "fixed_navigation") {
			sendMetriks("CLICK_WHATSAPP_HEADER");
		} else if (props.whatsappType === "fixed_navigation_operatory") {
			sendMetriks("CLICK_BUTTON_WHATSAPP_MOBILE");
		} else if (props.whatsappType === "pup_up_operator") {
			sendMetriks("POP_UP_OPERATOR_WHATSAPP");
		} else {
			sendMetriks("CLICK_BUTTON_WHATSAPP");
		}
	};

	if (props.changeStyle === "button") {
		return (
			<>
				<div className={classNames("", classes.or)}> {"ИЛИ"} </div>
				<a
					href={props.whatssappUrl}
					target="_blank"
					onClick={() => clickHandler()}
					className={classNames("", sizeClasses)}
					aria-label="Кнопка"
					rel="noreferrer"
				>
					<Button
						status={"whatsappAccent"}
						datatest="order_form_input_connect_button"
						fullWidth={true}
						marginTop={true}
					>
						{props.text.CONTINUE_WHATSAPP}
						<svg
							width="40"
							height="40"
							viewBox={"0 0 50 50"}
							className={classNames("", sizeClasses)}
						>
							<rect width="50" height="50" rx="10" fill="#ffffff" />
							<path fillRule="evenodd" clipRule="evenodd" d={SVGWhatsapp[0]} fill="#3CD566" />
							<path fillRule="evenodd" clipRule="evenodd" d={SVGWhatsapp[1]} fill="#3CD566" />
						</svg>
					</Button>
				</a>
			</>
		);
	} else if (props.changeStyle === "pup_up_operator") {
		return (
			<>
				<div className={classNames("", classes.popupOperatorWrapper)}>
					Задать вопрос в
					<a
						href={props.whatssappUrl}
						rel="noreferrer"
						target="_blank"
						onClick={() => clickHandler()}
						aria-label="Кнопка"
						className={classNames("", classes.popupOperatorContainer)}
					>
						<svg
							width="17"
							height="17"
							viewBox="0 0 17 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.3639 2.48896C12.955 1.06609 11.0841 0.193481 9.08859 0.0285473C7.09306 -0.136386 5.10427 0.417209 3.48096 1.58948C1.85764 2.76175 0.706671 4.47552 0.235646 6.42167C-0.235379 8.36782 0.00455309 10.4182 0.912163 12.2031L0.0212305 16.5285C0.0119861 16.5715 0.0117243 16.616 0.0204615 16.6592C0.0291988 16.7023 0.0467469 16.7432 0.0720087 16.7793C0.109016 16.834 0.16184 16.8762 0.223429 16.9001C0.285017 16.924 0.352438 16.9286 0.416688 16.9131L4.65593 15.9083C6.43573 16.793 8.47167 17.0175 10.4015 16.5419C12.3313 16.0663 14.0298 14.9215 15.1947 13.3112C16.3597 11.7009 16.9155 9.72946 16.7634 7.74776C16.6112 5.76606 15.761 3.9026 14.3639 2.48896ZM13.0421 13.0386C12.0673 14.0106 10.8121 14.6523 9.45337 14.8731C8.09461 15.0939 6.70081 14.8829 5.46838 14.2696L4.87751 13.9772L2.27857 14.5927L2.28626 14.5604L2.82482 11.9445L2.53554 11.3737C1.90582 10.1369 1.68368 8.73263 1.90094 7.36192C2.1182 5.99121 2.7637 4.72443 3.74499 3.74304C4.97799 2.51042 6.65008 1.81797 8.39354 1.81797C10.137 1.81797 11.8091 2.51042 13.0421 3.74304C13.0526 3.75508 13.0639 3.76639 13.0759 3.77689C14.2937 5.01269 14.9734 6.67978 14.9671 8.41472C14.9608 10.1497 14.2688 11.8117 13.0421 13.0386Z"
								fill="#00C95C"
							/>
							<path
								d="M12.8113 11.1199C12.4928 11.6216 11.9896 12.2355 11.3572 12.3879C10.2493 12.6556 8.54899 12.3971 6.43322 10.4244L6.40706 10.4013C4.54672 8.6764 4.06355 7.24075 4.1805 6.10208C4.24512 5.45581 4.78368 4.87109 5.23761 4.48948C5.30938 4.42823 5.39448 4.38462 5.48611 4.36214C5.57775 4.33966 5.67337 4.33894 5.76533 4.36002C5.85729 4.3811 5.94305 4.42341 6.01573 4.48357C6.08842 4.54372 6.14603 4.62005 6.18394 4.70644L6.86868 6.24519C6.91318 6.34496 6.92967 6.45496 6.91639 6.5634C6.9031 6.67183 6.86055 6.7746 6.79328 6.86068L6.44707 7.31C6.37278 7.40277 6.32795 7.51565 6.31835 7.63412C6.30876 7.75258 6.33483 7.87121 6.39321 7.97473C6.58709 8.3148 7.05179 8.81489 7.56727 9.27805C8.14584 9.80122 8.7875 10.2798 9.19372 10.4429C9.30242 10.4873 9.42193 10.4981 9.53684 10.474C9.65176 10.4499 9.75681 10.3919 9.83846 10.3075L10.2401 9.90278C10.3176 9.82636 10.4139 9.77187 10.5194 9.74483C10.6248 9.7178 10.7355 9.71921 10.8402 9.7489L12.4666 10.2105C12.5564 10.238 12.6386 10.2857 12.7071 10.3499C12.7755 10.4141 12.8284 10.4931 12.8617 10.5808C12.8949 10.6686 12.9077 10.7628 12.899 10.8562C12.8902 10.9496 12.8602 11.0399 12.8113 11.1199Z"
								fill="#00C95C"
							/>
						</svg>
						<span className={classNames("", classes.popupOperatorText)}>WhatsApp</span>
					</a>
				</div>
			</>
		);
	} else {
		return (
			<div
				className={classNames("", {
					[classes.root]: !props.changeStyle,
					[classes.header]: props.changeStyle === "header",
				})}
			>
				<a
					href={props.whatssappUrl}
					target="_blank"
					onClick={() => clickHandler()}
					className={classNames("", sizeClasses)}
					aria-label="Мессенджер WhatsApp"
					rel="noreferrer"
				>
					<svg width="50" height="50" viewBox={"0 0 50 50"} className={classNames("", sizeClasses)}>
						<rect width="50" height="50" rx="10" fill="#3CD566" />
						<path fillRule="evenodd" clipRule="evenodd" d={SVGWhatsapp[0]} fill="white" />
						<path fillRule="evenodd" clipRule="evenodd" d={SVGWhatsapp[1]} fill="white" />
					</svg>
				</a>
				{props.children}
			</div>
		);
	}
};

export default WhatsappSendMessage;
