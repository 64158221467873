import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import classNames from "classnames";

const styles = (theme) => ({
	root: {
		width: "100%",
		height: "100%",
		borderRadius: "10px",
		boxSizing: "border-box",
		position: "relative",
		backgroundColor: theme.palette.colors.white,
		border: "solid 1px #d3cee1",
		padding: "25px 30px 29px",
		overflow: "hidden",
		cursor: "pointer",
	},
	pic: {
		position: "absolute",
		color: theme.palette.colors.purplishBlue,
		top: "31px",
		right: "30px",
		width: "48px",
		maxWidth: "48px",
		maxHeight: "48px",
		overflow: "hidden",
	},
	category: {
		fontFamily: theme.fonts.bold,
		fontSize: "14px",
		lineHeight: "1.29",
		letterSpacing: "2px",
		color: theme.palette.colors.purplishBlue,
		textTransform: "uppercase",
		marginBottom: "11px",
	},
	header: {
		fontFamily: theme.fonts.bold,
		fontSize: "24px",
		lineHeight: "1",
		color: theme.palette.colors.black,
		marginBottom: "22px",
	},
	text: {
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "1.25",
		color: theme.palette.colors.oneMoreGrey,
		"padding-right": "120px",
	},
	textNoPadding: {
		paddingRight: 0,
	},
	business: {
		marginRight: "20px",
		marginBottom: "20px",
		"& $text": {
			paddingRight: 0,
		},
	},
	[`@media ( min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			width: "100%",
		},
		pic: {
			position: "absolute",
			color: theme.palette.colors.purplishBlue,
			top: "31px",
			right: "30px",
			width: "48px",
		},
		text: {
			fontFamily: theme.fonts.light,
			fontSize: "16px",
			lineHeight: "1.25",
			color: theme.palette.colors.oneMoreGrey,
			"padding-right": "0px",
		},
		business: {
			height: "200px",
		},
	},
	[`@media ( min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			width: "100%",
			padding: "20px 20px 23px",
			textAlign: "center",
			marginBottom: "10px",
		},
		pic: {
			display: "none",
		},
		header: {
			fontSize: "22px",
			lineHeight: "0.91",
			"margin-bottom": "25px",
		},
		text: {
			fontSize: "14px",
			lineHeight: "1.29",
			"padding-right": 0,
		},
		business: {
			marginRight: "10px",
			marginBottom: "10px",
			padding: "20px 18px",
			"& $header": {
				paddingRight: "17px",
			},
		},
	},
});

const BenefitCategory = ({
	header,
	icon,
	text,
	category,
	onClick,
	business,
	datatest,
	classes,
}) => {
	const className = classNames(classes.root, {
		[classes.business]: business,
	});
	return (
		<div onClick={onClick} className={className} datatest={datatest}>
			<div className={classes.category}>{category}</div>
			<div className={classes.header}>{header}</div>
			<div className={classNames(classes.text)}>{text}</div>
			<div className={classes.pic}>{icon}</div>
		</div>
	);
};

BenefitCategory.propTypes = {
	header: PropTypes.string.isRequired,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
	category: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	business: PropTypes.bool,
};
BenefitCategory.defaultProps = {
	header: "",
	text: "",
	category: "",
	business: false,
	onClick: () => {},
};
export default withStyles(styles)(BenefitCategory);
