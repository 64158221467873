import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useStyles from "./styles";

const Label = ({ children, whiteSpace, labelBlue, stub }) => {
	const classes = useStyles();
	if (!children) return null;
	return (
		<div
			className={classNames(classes.label, {
				[classes.labelWhiteSpace]: whiteSpace,
				[classes.labelStub]: stub,
				[classes.labelBlue]: labelBlue,
			})}
		>
			{children}
		</div>
	);
};

Label.propTypes = {
	children: PropTypes.string,
};

export default Label;
