import React from "react";
import withStyles from "react-jss";
import Container from "reactstrap/lib/Container";

const styles = (theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.colors.disabled,
		height: "70vh",
		borderRadius: "10px",
	},
});

class BigBlockStub extends React.Component {
	render() {
		let classes = this.props.classes;
		return (
			<React.Fragment>
				<Container>
					<div className={classes.root} />
				</Container>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(BigBlockStub);
