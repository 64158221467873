var gql = require("@apollo/client").gql;

const SEND_YANDEX_CAPTCHA_QUERY = gql`
	query SEND_YANDEX_CAPTCHA_QUERY($ipAddress: String, $token: String) {
		sendYandexCaptcha(ipAddress: $ipAddress, token: $token) {
			result
		}
	}
`;

module.exports = SEND_YANDEX_CAPTCHA_QUERY;
