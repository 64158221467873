import { observable, action, toJS } from "mobx";
import OurModal from "ComponentsUI/OurModal";

export default class CommonViewModel {
	rootModel = null;

	@observable openChangeLoc = false;
	@observable currentRegion = {};
	@observable showMobileMenu = false;
	@observable showModalOnRates = false;
	@observable nameModalOnRates = "seo";
	@observable visibleSideWidget = true;
	@observable automaticDetectedAccept = false;
	@observable automaticDetectedRegion = null;
	@observable bannerText = null;
	@observable showLoadingSpinner = false;
	@observable regionPhone = {};
	@observable previousPath = null;
	@observable ratesMobilePage = null;
	@observable blockedPath = null;
	@observable callbackSended = false;
	@observable subscribeSended = false;
	@observable freeYearInternetEnabled = false;
	@observable historyOfPages = [];
	@observable showRequestToProvider = true;
	@observable dontShowExitPopup = false;
	@observable showFakeHousePopup = false;
	@observable showSearchAddressPopup = false;
	@observable formOuthomeWasSended = false;
	@observable showFindTariffs = false;
	@observable unblocking = () => {};

	constructor(rootModel) {
		this.rootModel = rootModel;
	}

	@action
	changeOpenChangeLoc() {
		this.openChangeLoc = !this.openChangeLoc;
	}

	@action
	changeFormOuthomeSendFlag(value) {
		this.formOuthomeWasSended = value;
	}

	@action
	setShowFakeHousePopup(value) {
		this.showFakeHousePopup = value;
	}

	@action
	setRatesMobilePage(value) {
		this.ratesMobilePage = value;
	}

	@action
	setShowSearchAddressPopup(value) {
		this.showSearchAddressPopup = value;
	}

	@action
	setShowFindTariffs = (isShow) => {
		this.showFindTariffs = isShow;
	};

	@action
	toggleMobileMenu() {
		if (this.rootModel.windowSizeModel.windowSizes.xs) {
			!this.showMobileMenu ? OurModal.forOpenedModalBody() : OurModal.forClosedModalBody();
			this.showMobileMenu = !this.showMobileMenu;
		}
	}

	@action
	changeShowRequestToProvider(value) {
		this.showRequestToProvider = value;
	}

	@action
	changeDontShowExitPopup(value) {
		this.dontShowExitPopup = value;
	}

	@action
	changeShowModalOnRates(value) {
		this.showModalOnRates = value;
	}

	@action
	changeNameModalOnRates(value) {
		this.nameModalOnRates = value;
	}

	@action
	hideSideWidget() {
		this.visibleSideWidget = false;
	}

	@action
	changeShowLoadingSpinner(value) {
		this.showLoadingSpinner = value;
	}

	@action
	showSideWidget() {
		this.visibleSideWidget = false;
	}

	@action
	closeMobileMenu() {
		if (this.rootModel.windowSizeModel.windowSizes.xs) {
			OurModal.forClosedModalBody();
			this.showMobileMenu = false;
		}
	}

	@action
	setAutomaticDetectedAccept(value) {
		this.automaticDetectedAccept = value;
	}

	@action
	setBannerText(bannerText) {
		this.bannerText = bannerText;
	}

	@action
	changeCallback() {
		this.callbackSended = true;
	}

	@action
	changeSubscribe() {
		this.subscribeSended = true;
	}

	@action
	setPreviousPath(path) {
		this.previousPath = path;
	}

	@action
	pushToHistory(object) {
		this.historyOfPages.push(object);
	}

	@action
	setUnblocking(fn) {
		this.unblocking = fn;
	}

	@action
	setBlockedPath(path) {
		this.blockedPath = path;
	}

	@action
	setRegionPhone(phone) {
		this.regionPhone = phone;
	}

	getBannerText() {
		return toJS(this.bannerText);
	}

	getHistory() {
		return toJS(this.historyOfPages);
	}

	goDetectRegion() {}

	@action
	activateFreeYear() {
		this.freeYearInternetEnabled = true;
		window.localStorage.setItem("free_year", true);
	}

	@action
	initActivateFreeYear() {
		let free = window.localStorage.getItem("free_year");
		this.freeYearInternetEnabled = !!free;
	}
}
