var gql = require("@apollo/client").gql;

const MUTATE_INFO_EXIT_USER = gql`
	mutation (
		$uuidUser: String
		$selectQuestion: String
		$motive: String
		$url: String
		$regionId: Int
		$regionName: String
		$timeEventSite: Int
		$userAgent: String
		$resolution: String
		$catNumber: Int
		$type: String
	) {
		exitUser(
			uuidUser: $uuidUser
			selectQuestion: $selectQuestion
			motive: $motive
			url: $url
			region: { regionId: $regionId, regionName: $regionName }
			timeEventSite: $timeEventSite
			userAgent: $userAgent
			resolution: $resolution
			catNumber: $catNumber
			type: $type
		)
	}
`;

export default MUTATE_INFO_EXIT_USER;
