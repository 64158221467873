import classNames from "classnames";
import SimCardIcon from "ComponentsUI/Icons/SimCardIcon";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	wrapper: {
		color: theme.palette.colors.veryBlackGrey,
		textAlign: "center",
		fontSize: "14px",
		fontFamily: theme.fonts.medium,
	},
	value: {
		color: theme.palette.colors.purplishBlue,
		textTransform: "lowercase",
	},
	bold: {
		fontWeight: "bold",
	},
	notWrap: {
		whiteSpace: "nowrap",
	},
	text: {
		marginLeft: "10px",
	},
}));

const AdditionalSim = ({ text, convergentPrice, tariffPrice }) => {
	const classes = useStyles();
	const priceDiff = convergentPrice - tariffPrice;

	return (
		<div className={classes.wrapper}>
			<SimCardIcon />
			<span className={classes.text}>
				{priceDiff > 0 && (
					<>
						{text.SIM_CONVERGENT_OFFER.POSSIBLE_BUY}
						<span className={classes.bold}>{text.SIM}</span>
						{text.SIM_CONVERGENT_OFFER.PER}
						<span className={classes.notWrap}>
							<span className={classNames(classes.value, classes.bold)}>{priceDiff}</span>{" "}
							<span>{text.SIM_CONVERGENT_OFFER.RUB}</span>
						</span>
					</>
				)}
				{priceDiff < 0 && (
					<>
						{text.SIM_CONVERGENT_OFFER.THIS_TARIFF}
						<span className={classes.bold}>{text.SIM}</span>
						{text.SIM_CONVERGENT_OFFER.WILL_CHEAPER}
						<span>
							<span className={classNames(classes.value, classes.bold)}>{Math.abs(priceDiff)}</span>{" "}
							<span>{text.SIM_CONVERGENT_OFFER.RUB}</span>
						</span>
					</>
				)}
				{priceDiff === 0 && (
					<>
						{text.SIM_CONVERGENT_OFFER.CAN_ADD}
						<span className={classes.bold}>{text.SIM}</span>{" "}
						<span className={classNames(classes.value, classes.bold)}>{text.FREE_SIM}</span>
					</>
				)}
			</span>
		</div>
	);
};

export default AdditionalSim;
