import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { setDisplayName } from "recompose";

const styles = (theme) => ({
	root: {
		minHeight: "320px",
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: theme.palette.colors.paleGrey,
		flexDirection: "column",
	},
	fullScreen: {
		height: "100vh",
	},
	header: {
		minWidth: "280px",
		textAlign: "center",
		fontFamily: theme.fonts.bold,
		fontSize: "18px",
		lineHeight: "1.11",
		color: theme.palette.colors.darkSlateBlue,
		marginBottom: "10px",
	},
	text: {
		minWidth: "300px",
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "1.25",
		textAlign: "center",
		color: theme.palette.colors.oneMoreGrey,
		marginBottom: "22px",
	},
	icon: {
		marginBottom: "17px",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		header: {
			minWidth: "250px",
			fontSize: "16px",
			lineHeight: "1.25",
			marginBottom: "12px",
		},
		text: {
			minWidth: "225px",
			fontSize: "12px",
			lineHeight: "1.33",
		},
	},
});

const EmptyStub = ({ classes, header, text, icon, button, hideButton, fullScreen, children }) => {
	return (
		<div
			className={classNames(classes.root, {
				[classes.fullScreen]: fullScreen,
			})}
		>
			<div className={classes.icon}>{icon}</div>
			<div className={classes.header}>{header}</div>
			<div className={classes.text}>{text}</div>
			{children}
			{!hideButton ? button : null}
		</div>
	);
};

EmptyStub.propTypes = {
	button: PropTypes.node,
	icon: PropTypes.node.isRequired,
	header: PropTypes.string.isRequired,
	text: PropTypes.any.isRequired,
	fullScreen: PropTypes.bool,
};

EmptyStub.defaultProps = {
	button: null,
	icon: null,
	header: "",
	text: "",
	fullScreen: false,
};
export default setDisplayName("EmptyStub")(withStyles(styles)(EmptyStub));
