import { observable, action, runInAction, computed, toJS } from "mobx";
import LoaderData from "Services/LoaderData";
import find from "lodash/find";
import cookie from "js-cookie";

import Metrika from "Services/Metrika";
import { CLICK_COMPARE_TARIFF, CLICK_COMPARE } from "Constants/metriks/Yandex";

export default class CompareTarifficationsModel {
	fetchProviders = "/providers";
	@observable isOpen = false;
	@observable isClearTariffsClick = false;
	@observable tariffs = [];
	@observable restoreTariffs = [];
	@observable providers = [];

	@observable currentSlide = 0;
	@observable maxAmountCols = 0;
	@observable nextSlide = null;
	@observable previusSlide = null;
	@observable refererUrl = "";
	@observable widgetIsHide = false;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	addTariff(tariff) {
		if (this.tariffs.length > 0) {
			this.isOpen = true;
		}
		this.tariffs.push(tariff);
		this.saveToStorage();
	}

	@action
	removeTariff(tariff) {
		let finded = find(this.tariffs, (o) => o.id == tariff.id);
		if (finded) {
			this.tariffs.remove(finded);
			if (this.tariffs.length < 2) this.closeCompare();
			this.saveToStorage();
		}
	}

	findTariff(tariff) {
		let finded = find(this.tariffs, (o) => o.id == tariff.id);
		return !!finded;
	}

	@computed
	get visibleControllers() {
		return this.tariffs.length > this.maxAmountCols;
	}

	@action
	resetCompare() {
		this.isOpen = false;
		this.restoreTariffs.replace(this.tariffs);
		this.tariffs.clear();
		this.isClearTariffsClick = true;
		window.localStorage.removeItem("compare_tariffs");
	}

	@action
	restoreCompare() {
		this.tariffs.replace(this.restoreTariffs);
		this.restoreTariffs.clear();
		this.isClearTariffsClick = false;
	}

	@action
	resetRestoreCompare() {
		this.restoreTariffs.clear();
		this.isClearTariffsClick = false;
	}

	@action
	closeCompare() {
		this.isOpen = false;
	}

	@action
	openCompare() {
		this.isOpen = true;
	}

	@action
	setProviders(providers) {
		this.providers = providers;
	}

	@action
	setRefererUrl(url) {
		this.refererUrl = url;
	}

	getTariffs() {
		return toJS(this.tariffs);
	}

	getUpdateTariffs() {
		let tariffs = this.getTariffs();
		tariffs.forEach((tariff, index) => {
			tariffs[index].provider = this.providers.find(
				(provider) => (provider.id = tariff.provider_id)
			);
		});
		this.saveToStorage();
		return tariffs;
	}

	@action
	addToCompare(tariff) {
		let finded = find(this.tariffs, (o) => o.id == tariff.id);

		if (!finded) {
			this.addTariff(toJS(tariff));
			this.saveToStorage();
		} else {
			this.removeTariff(tariff);
		}
	}

	loadProviders() {
		let providers = [];
		providers = this.tariffs.map((item) => item.provider_id);
		let params = {
			provider_id: providers.join(),
		};

		LoaderData.get(this.fetchProviders, params).then((json) =>
			runInAction(() => {
				this.setProviders(json.data);
			})
		);
	}

	getProviders() {
		return toJS(this.providers);
	}

	saveToStorage() {
		let index = cookie.get("current_region") ? cookie.get("current_region").toString() : false;
		if (!index) return null;
		let fromStorage = window.localStorage.getItem("compare_tariffs");
		if (fromStorage && fromStorage.length) {
			fromStorage = JSON.parse(fromStorage);
		}
		fromStorage = {
			...fromStorage,
		};
		fromStorage[index] = JSON.stringify(toJS(this.tariffs));
		window.localStorage.setItem("compare_tariffs", JSON.stringify(fromStorage));
	}

	@action
	loadFromStorage() {
		let index = cookie.get("current_region") ? cookie.get("current_region").toString() : false;
		if (!index) {
			//this.ratesMobile = []; пока коммент
			return null;
		}
		let tariffs = window.localStorage.getItem("compare_tariffs");
		if (tariffs && tariffs.length > 0) {
			tariffs = JSON.parse(tariffs);
			if (tariffs[index]) {
				tariffs = JSON.parse(tariffs[index]);
				this.tariffs = tariffs;
			} else {
				this.tariffs = [];
			}
		}
	}

	@action
	setCurrentSlide(value) {
		this.currentSlide = value;
	}

	@action
	setMaxAmountCols(value) {
		this.maxAmountCols = value;
	}

	@action
	setNextSlideFunction(fn) {
		this.nextSlide = fn;
	}

	@action
	setPreviousSlideFunction(fn) {
		this.previusSlide = fn;
	}
	@action
	setWidgetIsHide() {
		this.widgetIsHide = true;
	}
	@action
	setWidgetIsVisible() {
		this.widgetIsHide = false;
	}
}
