import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import PropTypes from "prop-types";

const useStyles = createUseStyles((theme) => ({
	root: {
		// display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "40px",
		marginBottom: "40px",
	},
	headerText: {
		textAlign: "center",
		fontFamily: theme.fonts.bold,
		fontSize: "28px",
		lineHeight: "28px",
		color: theme.palette.colors.darkSlateBlue,
	},
	whiteHeader: {
		color: theme.palette.colors.white,
	},
	borderBGText: {
		padding: "20px",
		backgroundColor: theme.palette.colors.white,
		border: `solid 1px ${theme.palette.colors.disabled}`,
		borderRadius: "20px",
	},
	text: {
		textAlign: "center",
		fontFamily: theme.fonts.light,
		fontSize: "18px",
		lineHeight: "21px",
		color: theme.palette.colors.darkSlateBlue,
		"& h2": {
			fontFamily: theme.fonts.bold,
			fontSize: "28px",
			lineHeight: "28px",
			color: theme.palette.colors.darkSlateBlue,
		},
		"& table": {
			width: "100%",
			borderSpacing: "0",
		},
		"& thead": {
			background: "#F5F5F5",
			"& tr": {
				borderTopLeftRadius: "8px",
				borderTopRightRadius: "8px",
			},
		},
		"& tr": {
			display: "grid",
			gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
			borderTop: "1px solid #D2CEE0",
			borderBottom: "none",
			borderRight: "1px solid #D2CEE0",
			borderLeft: "1px solid #D2CEE0",
			textAlign: "start",
			"& th": {
				padding: "20px",
				fontSize: "12px",
				lineHeight: "10px",
				letterSpacing: "2px",
				fontWeight: "500",
				color: theme.palette.colors.black,
				textAlign: "start",
				textTransform: "uppercase",
			},
			"& td": {
				"&:not(:first-child)": {
					display: "flex",
					alignItems: "center",
				},
			},
		},
		"& tbody": {
			"& tr": {
				"&:last-child": {
					borderBottom: "1px solid #D2CEE0",
					borderBottomRightRadius: "8px",
					borderBottomLeftRadius: "8px",
				},
			},
		},
		"& td": {
			width: "200px",
			padding: "15px 20px",
			fontSize: "14px",
			lineHeight: "17px",
			fontWeight: "500",
			color: "#2C1D5D",
		},
	},
	textLeft: {
		textAlign: "left",
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			marginTop: "30px",
			marginBottom: "30px",
		},
		text: {
			fontSize: "16px",
			"& div": {
				overflowX: "auto",
			},
			"& tr": {
				gridAutoFlow: "column",
			},
		},
	},
	[`@media (max-width: ${theme.media.md})`]: {
		root: {
			marginTop: "30px",
			marginBottom: "30px",
		},
		headerText: {
			fontSize: "18px",
			lineHeight: "20px",
		},
		text: {
			fontSize: "14px",
			lineHeight: "16px",
			"& div": {
				overflowX: "auto",
			},
			"& h2": {
				fontSize: "18px",
				lineHeight: "20px",
			},
			"& table": {
				width: "1000px",
			},
			"& tr": {
				gridAutoFlow: "column",
			},
		},
	},
}));

const HeaderTextSeo = (props) => {
	const { header, text, hideTitle, bgText, textAlign, className } = props;
	let classes = useStyles();
	if (!header && !text) {
		return null;
	}

	return (
		<div className={classNames(classes.root, className)}>
			{!hideTitle && !!header && (
				<h2
					className={classNames(classes.headerText, {
						[classes.whiteHeader]: props.whiteHeader,
					})}
				>
					{header}
				</h2>
			)}
			{text && (
				<div
					className={classNames(classes.text, {
						[classes.borderBGText]: bgText,
						[classes.textLeft]: textAlign === "left",
					})}
					dangerouslySetInnerHTML={{
						__html: text,
					}}
				/>
			)}
		</div>
	);
};

HeaderTextSeo.defaultProps = {
	bgText: false,
	hideTitle: false,
	whiteHeader: false,
	header: "",
	text: "",
};

HeaderTextSeo.propTypes = {
	header: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
	bgText: PropTypes.bool,
	hideTitle: PropTypes.bool,
	whiteHeader: PropTypes.bool,
};

export default HeaderTextSeo;
