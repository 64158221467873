import React from "react";
import loadable from "@loadable/component";
import LoadingSpinnerHOC from "HOC/LoadingSpinnerHOC";

export const Actions = loadable(() => import("./actions"), {
	fallback: <LoadingSpinnerHOC />,
});
export const ActionsItem = loadable(() => import("./actionsItem"), {
	fallback: <LoadingSpinnerHOC />,
});
