import { useQuery } from "@apollo/client";
import { COUNT_PROVIDERS_QUERY, COUNT_TARIFFS_QUERY } from "Services/queries";

export const useGetCountProviders = (filter, skip) => {
	const countProvidersQuery = useQuery(COUNT_PROVIDERS_QUERY, {
		skip,
		variables: { filter },
		notifyOnNetworkStatusChange: true,
	});
	return {
		isCountProvidersLoading: countProvidersQuery.laoding,
		countProviders: countProvidersQuery.data?.countProvidersQuery?.count,
	};
};

export const useGetCountTariffs = (filter, skip) => {
	const countTariffsQuery = useQuery(COUNT_TARIFFS_QUERY, {
		skip,
		variables: { filter },
		notifyOnNetworkStatusChange: true,
	});
	return {
		isCountTariffsLoading: countTariffsQuery.loading,
		countTariffs: countTariffsQuery.data?.countTariffsQuery?.count,
	};
};
