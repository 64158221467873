import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { PhoneMask, PhoneEmailMask } from "ComponentsUI/Masks";
import classNames from "classnames";
import { setDisplayName } from "recompose";
import useStyles from "./styles";
import { Label } from "ComponentsUI/TextField";
import { sendMetriks } from "Services/sendMetriks";

const DefaultInput = (props) => {
	const onKeyPress = (e) => {
		if (e.charCode === 13) {
			!!props.onEnter && props.onEnter(e);
		}
	};

	let objValue = {};
	if (typeof props.value == "string") {
		objValue.value = props.value;
	}
	return (
		<input
			aria-labelledby="label"
			ref={(ref) => {
				props.inputRef && props.inputRef(ref);
			}}
			autoFocus={props.autoFocus}
			maxLength={100}
			type={props.type}
			onChange={props.onChange}
			onKeyPress={(e) => onKeyPress(e)}
			className={props.className}
			datatest={props.datatest}
			autoComplete={props.autocomplete}
			name={props.name}
			{...objValue}
		/>
	);
};

DefaultInput.propTypes = {
	type: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};
DefaultInput.defaultProps = {
	type: "text",
	onChange: () => {},
};

/*class TextField extends React.Component {
    state = {
        hiddenPlaceholder: false,
    };
    static propTypes = {
        placeholder: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        inputBackgroundColor: PropTypes.string,
        size: PropTypes.oneOf(["small", "medium", "big", "always50"]),
    };

    static defaultProps = {
        placeholder: "",
        label: "",
        type: "",
        onChange: () => {},
        inputBackgroundColor: "",
        size: "medium",
    };
    phoneMask = null;
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.phoneMask) {
            this.phoneMask.classList.remove(this.props.classes.errorPhone);
            if (
                this.props.errorCounter &&
                this.props.errorText &&
                this.props.errorCounter !== prevProps.errorCounter
            ) {
                setTimeout(() => {
                    this.phoneMask.classList.add(this.props.classes.errorPhone);
                }, 0);
            }
        }
    }

    onChangeHandler(e) {
        this.setState({
            hiddenPlaceholder: !!e.target.value.length,
        });
        if (typeof this.props.onChange === "function") {
            this.props.onChange(e);
        }
    }

    onEnter(e) {
        !!this.props.onEnter && this.props.onEnter(e);
    }

    onClickPlaceholderHandler() {
        let input =
            this.wrapper.querySelector("input") ||
            this.wrapper.querySelector("textarea");
        input.focus();
    }

    _renderError() {
        const { classes, errorText, overflowShow, classNameError } = this.props;
        const errorClassName = classNames(classes.error, classNameError, {
            [classes.errorShow]: !!errorText,
            [classes.overflowShow]: overflowShow,
            [classes.centerText]: this.props.searchWithPhone,
        });
        return <div className={errorClassName}>{errorText}</div>;
    }

    _renderInput() {
        const { type, classes, size, isError } = this.props;
        const className = classNames(classes.input, {
            [classes.inputSmall]: size === "small",
            [classes.inputMedium]: size === "medium",
            [classes.inputBig]: size === "big",
            [classes.always50]: size === "always50",
            [classes.isError]: isError,
            [classes.noMargin]: this.props.noMargin,
            [classes.withOpacity]: this.props.withOpacity,
            [classes.isPaddingRight]: this.props.isPaddingRight,
            [classes.whiteTextField]: this.props.whiteTextField,
        });
        let input = null;
        switch (type) {
            case "phone":
                input = (
                    <PhoneMask
                        refPhoneMask={input => (this.phoneMask = input)}
                        className={className}
                        onBlur={this.props.onBlur}
                        onFocus={this.props.onFocus}
                        onEnter={e => this.onEnter(e)}
                        datatest={this.props.datatest}
                        id={this.props.id}
                        changeCallback={value =>
                            this.onChangeHandler({ target: { value: value } })
                        }
                        value={this.props.value}
                        autocomplete={this.props.autocomplete}
                        name={this.props.name}
                        autoFocus={this.props.autoFocus}
                    />
                );
                break;
            case "phone_email":
                input = (
                    <PhoneEmailMask
                        onEnter={e => this.onEnter(e)}
                        className={className}
                        datatest={this.props.datatest}
                        id={this.props.id}
                        changeCallback={value =>
                            this.onChangeHandler({ target: { value: value } })
                        }
                        value={this.props.value}
                        autocomplete={this.props.autocomplete}
                        name={this.props.name}
                        autoFocus={this.props.autoFocus}
                    />
                );
                break;
            default:
                input = (
                    <DefaultInput
                        onEnter={e => this.onEnter(e)}
                        className={className}
                        value={this.props.value}
                        datatest={this.props.datatest}
                        type={this.props.type || "text"}
                        onChange={e => this.onChangeHandler(e)}
                        autocomplete={this.props.autocomplete}
                        name={this.props.name}
                        autoFocus={this.props.autoFocus}
                    />
                );
                break;
        }
        return input;
    }

    render() {
        const {
            classes,
            placeholder,
            label,
            marginBottom,
            smallPlaceholder,
            greyPlaceholder,
            homeOrder,
        } = this.props;
        const { hiddenPlaceholder } = this.state;

        const placeholderClassName = classNames(
            classes.placeholder,
            this.props.classNamePlaceholder,
            {
                [classes.hiddenPlaceholder]: hiddenPlaceholder,
                [classes.smallPlaceholder]: smallPlaceholder,
                [classes.greyPlaceholder]: greyPlaceholder,
            }
        );

        const className = classNames(classes.root, {
            [classes.marginBottom]: marginBottom,
            [classes.homeOrder]: homeOrder,
        });

        return (
            <div
                ref={wrapper => {
                    this.wrapper = wrapper;
                }}
                className={className}
            >
                <Label labelBlue={this.props.labelBlue}>{label}</Label>
                <div className={classes.wrapper}>
                    {!this.props.value && (
                        <span
                            className={placeholderClassName}
                            onClick={() => this.onClickPlaceholderHandler()}
                        >
                            {placeholder}
                        </span>
                    )}
                    {this._renderInput()}
                </div>

                {this._renderError()}
            </div>
        );
    }
}*/

const TextField = (props) => {
	const [hiddenPlaceholder, setHiddenPlaceholder] = useState(false);
	const [firstClikInput, setfirstClikInput] = useState(true);
	const [lastNumber, setlastNumbert] = useState(true);
	const [wrapper, setWrapper] = useState(null);
	const classes = useStyles();
	let phoneMaskRef = useRef(null);

	useEffect(() => {
		let phoneMask = phoneMaskRef?.current?.input;
		if (phoneMask) {
			phoneMask.classList.remove(classes.errorPhone);
			if (props.errorCounter && (props.errorText || props.isError)) {
				setTimeout(() => {
					phoneMask.classList.add(classes.errorPhone);
				}, 0);
			}
		}
	}, [props.errorCounter]);

	const onChangeHandler = (e) => {
		setHiddenPlaceholder(!!e.target.value.length);
		if (typeof props.onChange === "function") {
			props.onChange(e);
		}
		if (firstClikInput) {
			sendMetriks("CLICK_PHONE");
			setfirstClikInput(false);
		}
		if (lastNumber && e.target.value.length === 11) {
			sendMetriks("ENTER_NUMBER");
			setlastNumbert(false);
		}
	};

	const onEnter = (e) => {
		!!props.onEnter && props.onEnter(e);
	};

	const onClickPlaceholderHandler = () => {
		let input = wrapper.querySelector("input") || wrapper.querySelector("textarea");
		input.focus();
	};

	const _renderError = () => {
		const { errorText, overflowShow, classNameError } = props;
		const errorClassName = classNames(classes.error, classNameError, {
			[classes.errorShow]: !!errorText,
			[classes.overflowShow]: overflowShow,
			[classes.centerText]: props.searchWithPhone,
		});
		return <div className={errorClassName}>{errorText}</div>;
	};

	const _renderInput = () => {
		const { type, size, isError } = props;
		const className = classNames(classes.input, {
			[classes.inputSmaller]: size === "smaller",
			[classes.inputSmall]: size === "small",
			[classes.inputMedium]: size === "medium",
			[classes.inputBig]: size === "big",
			[classes.always50]: size === "always50",
			[classes.isError]: isError,
			[classes.noMargin]: props.noMargin,
			[classes.withOpacity]: props.withOpacity,
			[classes.isPaddingRight]: props.isPaddingRight,
			[classes.whiteTextField]: props.whiteTextField,
		});
		let input = null;
		switch (type) {
			case "phone":
				input = (
					<PhoneMask
						ref={phoneMaskRef}
						className={className}
						onBlur={props.onBlur}
						onFocus={props.onFocus}
						onEnter={(e) => onEnter(e)}
						datatest={props.datatest}
						id={props.id}
						changeCallback={(value) => onChangeHandler({ target: { value: value } })}
						value={props.value}
						autocomplete={props.autocomplete}
						name={props.name}
						autoFocus={props.autoFocus}
					/>
				);
				break;
			case "phone_email":
				input = (
					<PhoneEmailMask
						onEnter={(e) => onEnter(e)}
						className={className}
						datatest={props.datatest}
						id={props.id}
						changeCallback={(value) => onChangeHandler({ target: { value: value } })}
						value={props.value}
						autocomplete={props.autocomplete}
						name={props.name}
						auoFocus={props.autoFocus}
					/>
				);
				break;
			default:
				input = (
					<DefaultInput
						inputRef={props.inputRef}
						onEnter={(e) => onEnter(e)}
						className={className}
						value={props.value}
						datatest={props.datatest}
						type={props.type || "text"}
						onChange={(e) => onChangeHandler(e)}
						autocomplete={props.autocomplete}
						name={props.name}
						autoFocus={props.autoFocus}
					/>
				);
				break;
		}
		return input;
	};

	const { placeholder, label, marginBottom, smallPlaceholder, greyPlaceholder, homeOrder } = props;

	const placeholderClassName = classNames(classes.placeholder, props.classNamePlaceholder, {
		[classes.hiddenPlaceholder]: hiddenPlaceholder,
		[classes.smallPlaceholder]: smallPlaceholder,
		[classes.greyPlaceholder]: greyPlaceholder,
	});

	const className = classNames(classes.root, {
		[classes.marginBottom]: marginBottom,
		[classes.homeOrder]: homeOrder,
	});
	return (
		<div
			ref={(wrapper) => {
				setWrapper(wrapper);
			}}
			className={className}
		>
			<Label labelBlue={props.labelBlue}>{label}</Label>
			<div className={classes.wrapper} ref={props.refTextField}>
				{!props.value && (
					<span
						id={props.id}
						className={placeholderClassName}
						onClick={() => onClickPlaceholderHandler()}
					>
						{placeholder}
					</span>
				)}
				{_renderInput()}
			</div>

			{_renderError()}
		</div>
	);
};

TextField.propTypes = {
	placeholder: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	inputBackgroundColor: PropTypes.string,
	size: PropTypes.oneOf(["small", "smaller", "medium", "big", "always50"]),
};
TextField.defaultProps = {
	placeholder: "",
	label: "",
	type: "",
	onChange: () => {},
	inputBackgroundColor: "",
	size: "medium",
};

export default setDisplayName("TextField")(TextField);
