import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";
import { RatingStarTen } from "ComponentsUI/RatingsViews";

const styles = (theme) => {
	return {
		root: {
			width: "100%",
			position: "relative",
			cursor: "pointer",
			marginBottom: "40px",
			"&:hover": {
				"& $like": {
					visibility: "visible",
					opacity: "1",
				},
			},
		},
		header: {
			marginBottom: "20px",
			display: "flex",
			alignItems: "flex-start",
		},
		headerLogo: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginLeft: "6px",
			marginRight: "6px",
			maxWidth: "100px",
			maxHeight: "80px",
		},
		logo: {
			display: "inline-block",
			maxWidth: "70px",
			height: "auto",
			// maxHeight: "60px",
			// marginRight: "16px",
			flexShrink: 0,
			objectFit: "scale-down",
		},
		headerInfo: {
			overflow: "hidden",
			width: "100%",
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
		},
		headerNameProvider: {
			display: "flex",
			fontFamily: theme.fonts.bold,
			fontSize: "16px",
			lineHeight: "1.25",
			color: theme.palette.colors.darkSlateBlue,
			marginBottom: "1.5px",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "wrap",
			width: "90%",
		},
		headerProviderWrapper: {
			display: "flex",
			flexWrap: "nowrap",
			alignItems: "center",
			overflow: "hidden",
			marginTop: "6px",
		},
		headerInfoOwner: {
			fontFamily: theme.fonts.bold,
			fontSize: "8px",
			lineHeight: "1.29",
			letterSpacing: "2px",
			color: theme.palette.colors.grayishBlue,
			// marginRight: "9px",
			textTransform: "uppercase",
			overflow: "hidden",
			textOverflow: "ellipsis",
			textAlign: "center",
			display: "block",
			width: "auto",
			// flexShrink: 0,
		},
		headerCity: {
			fontFamily: theme.fonts.medium,
			fontSize: "14px",
			lineHeight: "1.43",
			color: theme.palette.colors.purplishBlue,
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			display: "block",
			width: "100%",
		},
		headerInfoWrapper: {
			width: "100%",
			marginTop: "2px",
			display: "flex",
			alignItems: "center",
		},
		headerInfoDate: {
			fontFamily: theme.fonts.medium,
			fontSize: "14px",
			lineHeight: "1.29",
			color: theme.palette.colors.grayishBlue,
			marginRight: "10px",
		},
		// headerSvgEye: {
		//     color: theme.palette.colors.cloudyBlue,
		//     fontSize: "16px",
		//     marginRight: "7px"
		// },
		// headerWatchCounter: {
		//     fontFamily: theme.fonts.medium,
		//     fontSize: "14px",
		//     lineHeight: "1.29",
		//     color: theme.palette.colors.grayishBlue
		// },
		body: {
			padding: "20px 18px 26px 22px",
			borderRadius: "0 20px 20px",
			backgroundColor: theme.palette.colors.white,
			border: `solid 1px ${theme.palette.colors.disabled}`,
			position: "relative",
			display: "flex",
			flexDirection: "column",
			"&:before": {
				content: "''",
				position: "absolute",
				border: "13px solid transparent",
				top: "-25px",
				left: "-1px",
				borderBottom: `13px solid ${theme.palette.colors.disabled}`,
				borderLeft: `13px solid ${theme.palette.colors.disabled}`,
			},
			"&:after": {
				content: "''",
				position: "absolute",
				border: "12px solid transparent",
				top: "-23px",
				left: "0",
				zIndex: 2,
				borderBottom: `12px solid ${theme.palette.colors.white}`,
				borderLeft: `12px solid ${theme.palette.colors.white}`,
			},
		},
		bodyText: {
			overflow: "hidden",
			maxHeight: "104px",
			"& pre": {
				fontFamily: theme.fonts.light,
				fontSize: "15px",
				color: theme.palette.colors.darkSlateBlue,
				whiteSpace: "pre-line",
				margin: 0,
				display: "-webkit-box",
				"-webkit-line-clamp": 6,
				"-webkit-box-orient": "vertical",
			},
		},
		bodyButton: {
			fontFamily: theme.fonts.bold,
			fontSize: "14px",
			letterSpacing: "2px",
			color: theme.palette.colors.purplishBlue,
			marginTop: "20px",
			marginBottom: 0,
			textTransform: "uppercase",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		like: {
			position: "absolute",
			bottom: 0,
			left: 0,
			fontSize: 0,
			lineHeight: 0,
			visibility: "hidden",
			opacity: 0,
			transition: "all linear .3s",
		},
		likeText: {
			fontFamily: theme.fonts.medium,
			fontSize: "15px",
			color: theme.palette.colors.grayishBlue,
			marginRight: "7px",
			display: "block",
			marginBottom: "1px",
			lineHeight: "1.5",
		},
		likeAnswers: {
			display: "inline-block",
		},
		likeButton: {
			fontFamily: theme.fonts.medium,
			fontSize: "15px",
			letterSpacing: "2px",
			textTransform: "uppercase",
			lineHeight: "1",
			cursor: "pointer",
		},
		likeYesButton: {
			color: "#24ba8e",
			marginRight: "4px",
		},
		likeNoButton: {
			color: theme.palette.colors.coralPink,
		},
		isStub: {
			"& $headerSmile": {
				height: "36px",
				width: "36px",
				borderRadius: "10px",
				backgroundColor: theme.palette.colors.disabled,
			},
			"& $headerNameProvider": {
				height: "16px",
				borderRadius: "10px",
				backgroundColor: theme.palette.colors.disabled,
				width: "100px",
			},
			"& $headerInfoWrapper": {
				height: "16px",
				borderRadius: "10px",
				backgroundColor: theme.palette.colors.disabled,
				width: "100%",
			},
			"& $body": {
				height: "200px",
				backgroundColor: theme.palette.colors.disabled,
			},
			"& $bodyButton": {
				display: "none",
			},
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			headerNameProvider: {
				maxWidth: "245px",
			},
			likeText: {
				display: "inline-block",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			headerNameProvider: {
				maxWidth: "275px",
			},
			likeText: {
				display: "inline-block",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				width: "100%",
				marginRight: "0",
				marginBottom: "16px",
			},
			header: {
				marginBottom: "20px",
			},
			headerNameProvider: {
				maxWidth: "100000px",
			},
			headerSmile: {
				marginRight: "18px",
			},
			body: {
				padding: "20px 8.33% 26px",
			},
			bodyText: {
				overflow: "hidden",
				maxHeight: "97px",
				"& pre": {
					fontSize: "14px",
				},
			},
			like: {
				bottom: "15px",
			},
			likeText: {
				display: "inline-block",
			},
		},
		[`@media (max-width: ${theme.media.verySmall})`]: {
			like: {
				bottom: "37px",
			},
			likeText: {
				display: "inline-block",
			},
		},
	};
};

const ReviewItem = ({
	classes,
	text,
	name,
	linkContainer,
	logo,
	webpTransformer,
	stub,
	urlToProvider,
	owner,
	date,
	status,
	onClickHandler,
	providerPhones,
	rating,
}) => {
	const nameToView = !!name ? name : "Неизвестный гость";

	const providerPhone = providerPhones?.length ? providerPhones[0] : null;

	const children = (
		<>
			<span itemProp="name">{owner}</span>
			<meta itemProp="telephone" content={providerPhone} />
		</>
	);

	const childrenLogo = (
		<>
			<div className={classes.logo}>
				<picture>
					<source type="image/webp" srcSet={!!webpTransformer ? webpTransformer(logo) : logo} />
					<source srcSet={logo} />
					<img width={240} height={120} alt={"reviewType"} className={classes.logo} src={logo} />
				</picture>
			</div>
		</>
	);

	return (
		<div
			className={classNames(classes.root, {
				[classes.isStub]: stub,
			})}
			onClick={onClickHandler}
			itemScope
			itemType="https://schema.org/Review"
		>
			<div className={classes.header}>
				{stub && <div className={classes.headerSmile} />}
				<div className={classes.headerInfo}>
					<div
						className={classes.headerNameProvider}
						itemProp="author"
						itemScope
						itemType="https://schema.org/Person"
					>
						<span itemProp="name">{!stub ? nameToView : null}</span>
					</div>
					<RatingStarTen provider={true} rating={rating} />
					{!stub && (
						<div className={classes.headerInfoWrapper}>
							<span className={classes.headerInfoDate} itemProp="datePublished">
								{date}
							</span>
						</div>
					)}
					{stub && <div className={classes.headerInfoWrapper} />}
				</div>
				{!stub && (
					<div className={classes.headerLogo}>
						<div className={classes.logo}>
							{React.cloneElement(linkContainer, {
								datatest: "reviews_provider_button",
								to: urlToProvider,
								itemProp: "itemReviewed",
								itemScope: true,
								itemType: "https://schema.org/Organization",
								onClick: (e) => e.stopPropagation(),
								children: childrenLogo,
							})}
						</div>
						<div className={classes.headerProviderWrapper}>
							{React.cloneElement(linkContainer, {
								datatest: "reviews_provider_button",
								to: urlToProvider,
								className: classes.headerInfoOwner,
								itemProp: "itemReviewed",
								itemScope: true,
								itemType: "https://schema.org/Organization",
								onClick: (e) => e.stopPropagation(),
								children: children,
							})}
						</div>
					</div>
				)}
			</div>
			<div className={classNames(classes.body)}>
				<div className={classes.bodyText} itemProp="reviewBody description">
					<pre
						dangerouslySetInnerHTML={{
							__html: text,
						}}
					/>
				</div>
			</div>
			{status}
		</div>
	);
};

ReviewItem.defaultProps = {
	markReviewAsViewed: () => {},
	item: {},
	classes: {},
	type: "",
	name: "",
	urlToProvider: "",
	owner: "",
	date: "",
	counter: "",
	text: "",
	status: null,
	onClickHandler: () => {},
};

export default withStyles(styles)(ReviewItem);

export { ReviewItem as __ReviewItem };
