import React from "react";
import loadable from "@loadable/component";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import * as Routes from "../routes/Routes";
import configRouters from "Configs/routes";
import OnlySSR from "HOC/OnlySSR";
import CtrlEnterSendContainer from "Containers/CtrlEnterSendContainer";
import Metrika from "Services/Metrika";
import RedirectCheck from "HOC/RedirectCheck";
import MainLayout from "Layouts/MainLayout";
import MainLayoutV2 from "Layouts/MainLayoutV2";
import * as RoutesRating from "./rating/routes/Routes";
import * as RoutesActions from "./actions/routes/Routes";
import * as RoutesProviders from "./providers/routes/Routes";
import * as RoutesOperators from "./operators/routes/Routes";
import * as RatesRoutes from "./rates/routes/Routes";
import * as ReviewsRoutes from "./reviews/routes/Routes";
import * as OrdersRoutes from "./orders/routes/Routes";
import * as AddressRoutes from "./address/routes/Routes";
import * as TextRoutes from "./texts/routes/Routes";
import * as Feedback from "./feedback/Routes";
import { Redirect404 } from "ComponentsUI/Redirects";
import withDefaultParams from "Services/withDefaultParams";
import RegionRedirectHOC from "HOC/RegionRedirectHOC";
import CookieNotificationHOCContainer from "Containers/CookieNotificationHOCContainer";
import PhoneFixedButtonContainer from "Containers/PhoneFixedButtonContainer";
import { noIndex } from "Utils/routeCheck";

const LoaderBarContainer = loadable(() => import("Containers/LoaderBarContainer"), { ssr: false });

const OurModal = loadable(() => import("ComponentsUI/OurModal"), {
	ssr: false,
});

const OurModalExit = loadable(() => import("ComponentsUI/OurModalExit"), {
	ssr: false,
});

const OurModalAgreement = loadable(() => import("ComponentsUI/OurModalAgreement"), {
	ssr: false,
});

const OurModalMtsConnect = loadable(() => import("ComponentsUI/OurModalMtsConnect"), {
	ssr: false,
});

const LoadingSpinnerContainer = loadable(() => import("Containers/LoadingSpinnerContainer"), {
	ssr: false,
});
const FixedPanel = loadable(() => import("ComponentsUI/SideWidgets/FixedPanel"), { ssr: false });

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
class Main extends React.Component {
	componentDidMount() {
		Metrika.getInstance(this.props.ApplicationModel.MetrikaId);
	}

	helmet() {
		const {
			ApplicationModel: { siteCode },
			location: { pathname, search },
		} = this.props;

		const no_index = noIndex(pathname + search, siteCode);

		return <Helmet>{no_index ? <meta name="robots" content="noindex" /> : ""}</Helmet>;
	}

	render() {
		return (
			<RegionRedirectHOC>
				<div>
					{/*{this.helmet()}*/}
					<LoaderBarContainer />
					<Switch>
						<Route path={configRouters.AddReview.route} component={Routes.AddReview} />

						<Route path={configRouters.ContactFeedback.route} component={Feedback.Feedback} exact />

						<Route exact path={configRouters.SelectRegion.route} component={Routes.SelectRegions} />

						<MainLayout
							withBanner={true}
							exact
							path={configRouters.Business.route}
							component={Routes.Business}
							route={configRouters.Business}
							viewMobilePopup={false}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.Actions.route}
							component={RoutesActions.Actions}
							route={configRouters.Actions}
							whatsappType={"actions"}
						/>
						<MainLayout
							// withBanner={true}
							exact
							path={configRouters.ActionsItem.route}
							component={Routes.Actions}
							route={configRouters.ActionsItem}
							whatsappType={"actions_id"}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.Providers.route}
							component={RoutesProviders.Catalog}
							route={configRouters.Providers}
						/>
						<MainLayout
							exact
							path={configRouters.ProvidersActionsList.route}
							component={Routes.ProviderDetails}
							route={configRouters.ProvidersActionsList}
						/>
						<MainLayout
							exact
							path={configRouters.ProviderRates.route}
							component={Routes.ProviderDetails}
							route={configRouters.ProviderRates}
						/>
						<MainLayout
							exact
							path={configRouters.ProviderRatesTags.route}
							component={Routes.ProviderDetails}
							route={configRouters.ProviderRatesTags}
						/>
						<MainLayout
							path={configRouters.ProviderDetails.route}
							component={Routes.ProviderDetails}
							route={configRouters.ProviderDetails}
						/>

						<MainLayout
							exact
							withBanner={true}
							path={configRouters.Rating.route}
							component={RoutesRating.RatingTable}
							route={configRouters.Rating}
							whatsappType={"rating"}
						/>
						<MainLayout
							exact
							path={configRouters.ProvidersReviewListOld.route}
							component={Routes.ProviderDetails}
							route={configRouters.ProvidersReviewListOld}
						/>
						<MainLayout
							exact
							withBanner={true}
							path={configRouters.Reviews.route}
							component={ReviewsRoutes.Reviews}
							route={configRouters.Reviews}
							whatsappType={"reviews"}
						/>
						<MainLayout
							// withBanner={true}
							exact
							path={configRouters.RatesMobile.route}
							component={Routes.RatesMobile}
							route={configRouters.RatesMobile}
							viewMobilePopup={false}
							changeWhatsappHeader={true}
						/>
						<MainLayout
							// withBanner={true}
							exact
							path={configRouters.BeautifulNumbers.route}
							component={Routes.BeautifulNumbers}
							route={configRouters.BeautifulNumbers}
							viewMobilePopup={false}
							changeWhatsappHeader={true}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.Operatory.route}
							component={RoutesOperators.Catalog}
							route={configRouters.Operatory}
							viewMobilePopup={false}
							changeWhatsappHeader={true}
						/>
						<MainLayout
							exact
							path={configRouters.OperatoryTariffs.route}
							component={Routes.OperatorDetails}
							route={configRouters.OperatoryTariffs}
							viewMobilePopup={false}
							changeWhatsappHeader={true}
						/>
						<MainLayout
							exact
							path={configRouters.OperatoryBeautifulNumbers.route}
							component={Routes.OperatorDetails}
							route={configRouters.OperatoryBeautifulNumbers}
							viewMobilePopup={false}
							changeWhatsappHeader={true}
						/>
						<MainLayout
							path={configRouters.OperatoryActions.route}
							component={Routes.OperatorDetails}
							route={configRouters.OperatoryActions}
							viewMobilePopup={false}
							changeWhatsappHeader={true}
						/>
						<MainLayout
							path={configRouters.OperatorDetails.route}
							component={Routes.OperatorDetails}
							route={configRouters.OperatorDetails}
							viewMobilePopup={false}
							changeWhatsappHeader={true}
						/>

						<MainLayout
							withBanner={true}
							exact
							route={configRouters.OrderHome}
							path={configRouters.OrderHome.route}
							component={OrdersRoutes.Home}
							viewMobilePopup={false}
						/>

						<MainLayout
							exact
							route={configRouters.OrderOuthome}
							path={configRouters.OrderOuthome.route}
							component={OrdersRoutes.Outhome}
							viewMobilePopup={false}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.OrderToHome.route}
							component={OrdersRoutes.ToHome}
							route={configRouters.OrderToHome}
						/>
						<MainLayout
							exact
							route={configRouters.OrderOffice}
							path={configRouters.OrderOffice.route}
							component={OrdersRoutes.Office}
							viewMobilePopup={false}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.AddressDistricts.route}
							component={AddressRoutes.Districts}
							route={configRouters.AddressDistricts}
							whatsappType={"address"}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.AddressStreets.route}
							component={AddressRoutes.Streets}
							route={configRouters.AddressStreets}
							withDistrict={true}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.AddressStreet.route}
							component={AddressRoutes.Street}
							route={configRouters.AddressStreet}
							withDistrict={true}
							withStreet={true}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.RatesInHouse.route}
							component={AddressRoutes.House}
							route={configRouters.RatesInHouse}
							withHouseId={true}
							withoutMarginTopSeoText={true}
							viewMobilePopup={false}
							whatsappType={"rates_house_id"}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.DomaNzl.route}
							component={AddressRoutes.DomaNzl}
							route={configRouters.DomaNzl}
							withHouseId={true}
							withoutMarginTopSeoText={true}
							viewMobilePopup={false}
							whatsappType={"rates_house_id"}
						/>
						<MainLayout
							exact
							path={configRouters.Compare.route}
							route={configRouters.Compare}
							component={Routes.Compare}
						/>
						<MainLayout
							withBanner={true}
							exact
							path={configRouters.Rates.route}
							component={RatesRoutes.Rates}
							route={configRouters.Rates}
							withHouseId={true}
							withoutMarginTopSeoText={true}
							viewMobilePopup={false}
						/>
						<MainLayout
							// withBanner={true}
							exact
							path={configRouters.RatesTags.route}
							component={Routes.Rates}
							route={configRouters.RatesTags}
							withoutMarginTopSeoText={true}
							viewMobilePopup={false}
						/>
						<MainLayout
							withBanner={true}
							path={configRouters.TermsOfUse.route}
							route={configRouters.TermsOfUse}
							component={TextRoutes.TermsOfUse}
						/>
						<MainLayout
							withBanner={true}
							route={configRouters.PersonalData}
							path={configRouters.PersonalData.route}
							component={TextRoutes.PersonalData}
						/>
						<MainLayout
							withBanner={true}
							path={configRouters.Partner.route}
							component={Routes.Partner}
							route={configRouters.Partner}
						/>
						<MainLayout
							withBanner={true}
							path={configRouters.Contacts.route}
							component={Routes.Contacts}
							route={configRouters.Contacts}
						/>
						<MainLayout
							withBanner={true}
							path={configRouters.PayWarranty.route}
							route={configRouters.PayWarranty}
							component={Routes.PayWarranty}
						/>
						{/*<MainLayout*/}
						{/*    path={configRouters.Profile.route}*/}
						{/*    route={configRouters.Profile}*/}
						{/*    component={Routes.Profile}*/}
						{/*/>*/}
						<MainLayout
							withBanner={true}
							path={configRouters.SiteMap.route}
							route={configRouters.SiteMap}
							component={Routes.SiteMap}
						/>

						<MainLayout
							withBanner={true}
							path={configRouters.AboutCompany.route}
							route={configRouters.AboutCompany}
							component={Routes.AboutCompany}
						/>
						<MainLayout
							path={configRouters.ConnectInternet.route}
							route={configRouters.ConnectInternet}
							component={Routes.ConnectInternet}
						/>
						<MainLayout
							exact
							path={configRouters.Default.route}
							route={configRouters.Default}
							component={Routes.MainPage}
							notBlocked
						/>
						<Route render={() => <Redirect404 message404={"Введен неизвестный тип урла"} />} />
					</Switch>
					{false && <CtrlEnterSendContainer />}
					<Route
						render={() => (
							<div className="__fixed">
								<FixedPanel />
							</div>
						)}
					/>
					<OurModal />
					<OurModalExit />
					<OurModalAgreement />
					<OurModalMtsConnect />
					<LoadingSpinnerContainer />
					<div id="popups" />
					<div id="forSelectField" />
					<PhoneFixedButtonContainer />
					<CookieNotificationHOCContainer text={this.props.ApplicationModel.text} />
				</div>
			</RegionRedirectHOC>
		);
	}
}

class Main_2 extends React.Component {
	render() {
		return (
			<>
				<Route
					path={configRouters.Default.route}
					render={() => (
						<React.Fragment>
							<OnlySSR>
								<RedirectCheck />
							</OnlySSR>
						</React.Fragment>
					)}
				/>
				<Switch>
					<Route path={configRouters.Default.route} render={Main} />
				</Switch>
			</>
		);
	}
}

export default Main_2;
