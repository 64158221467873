import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import { Experiment, Variant } from "react-abtesting";
import { useMutation } from "@apollo/client";
import { MUTATE_AB } from "Services/queries";
import withDefaultParams from "Services/withDefaultParams";
import { pipe } from "Utils/functionalUtils";
import Cookies from "js-cookie";
import ABTestContainerAudio from "./ABTestContainerAudio";

const ABTestWrapperAudio = (props) => {
	let {
		ApplicationModel: {
			siteConfig: { experiments },
		},
		defaultContainer,
		testName,
		changeComplete,
		ABtest,
		functionUtils,
	} = props;
	let infoTariff;

	experiments = experiments ? experiments : [];
	let currentTest = experiments.find((elem) => elem.name === testName);
	const [sendABGoal] = useMutation(MUTATE_AB);

	useEffect(() => {
		const variant_raw = Cookies.get("AB_" + testName);

		if (variant_raw) {
			let variant;

			try {
				variant = JSON.parse(variant_raw);
			} catch (e) {
				console.warn(`AB variant parsing error. Test: ${testName}, error:`, e);
			}

			sendABGoal({
				variables: {
					uuid: Cookies.get("uuid"),
					experiment: testName,
					variant: variant?.name || "no variant found",
					user_agent: navigator.userAgent,
					goal: true,
				},
			});
		}
	}, []);

	if (!currentTest || !ABtest) {
		return defaultContainer;
	}

	let otherProps = Object.assign({}, infoTariff);

	return (
		<Experiment name={testName}>
			{currentTest.variants.map((variantTest) => {
				if (
					Boolean(variantTest?.use_default_react || variantTest.name === "Default") &&
					defaultContainer
				) {
					return (
						<Variant name={variantTest.name} key={variantTest.name}>
							{defaultContainer}
						</Variant>
					);
				}
				return (
					<Variant name={variantTest.name} key={variantTest.name}>
						<ABTestContainerAudio
							testName={testName}
							variantName={variantTest.name}
							otherProps={otherProps}
							changeComplete={changeComplete}
							functionUtils={functionUtils}
						/>
					</Variant>
				);
			})}
		</Experiment>
	);
};

ABTestWrapperAudio.propTypes = {
	testName: PropTypes.string.isRequired,
	defaultContainer: PropTypes.node.isRequired,
	changeComplete: PropTypes.func,
};

export default pipe(inject("ApplicationModel"), withDefaultParams, withRouter)(ABTestWrapperAudio);
