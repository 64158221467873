import React from "react";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			width: "60px",
			height: "60px",
			border: `solid 1px ${theme.palette.colors.lightGrayishViolet}`,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			cursor: "pointer",
			borderRadius: "50%",
			marginRight: "20px",
			"& span": {
				color: theme.palette.colors.lightGrayishViolet,
				fontSize: "24px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				width: "40px",
				height: "40px",
				marginRight: "13px",
				"& span": {
					fontSize: "12px",
				},
			},
		},
	};
};

class PaginatorItem extends React.Component {
	static defaultProps = {
		value: null,
	};

	constructor(props) {
		super(props);
	}

	onClickHandle(value) {
		let page = value;
		this.props.onChangePage(page);
	}
	render() {
		let classes = this.props.classes;
		return (
			<div className={classes.root} onClick={() => this.onClickHandle(this.props.pageCurrent)}>
				{this.props.value}
			</div>
		);
	}
}

export default withStyles(styles)(PaginatorItem);
