class Google {
	sendEvent(category, type) {
		if (typeof ga != "undefined") {
			ga("send", {
				hitType: "event",
				eventCategory: category,
				eventAction: type,
			});
		}

		let dataLayer = [];
		if (typeof window !== "undefined") {
			dataLayer = window.dataLayer;
		}

		try {
			if (dataLayer) {
				dataLayer.push({ event: type });
			}
		} catch (e) {
			console.warn(e);
		}
	}

	sendRoistatEvent(data) {
		if (typeof ga != "undefined") {
			ga("send", {
				hitType: "event",
				eventCategory: "callback",
				eventAction: "click_lead_catcher",
				eventLabel: "roistat",
				eventValue: data,
			});
		}
	}

	getCID() {
		let cid = "";
		if (typeof ga != "undefined") {
			if (!!ga && !!ga.getAll) {
				cid = ga.getAll()[0].get("clientId");
			}
		}
		return cid;
	}
}

export default Google;
