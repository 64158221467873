import { action, computed, observable } from "mobx";

export default class MobileFiltersTouchModel {
	@observable
	focusState = [];

	@action
	setFocus() {
		this.focusState.push(true);
	}

	@action
	removeFocus() {
		this.focusState.pop();
	}

	@computed
	get haveFocus() {
		return !!this.focusState.length;
	}
}
