import React from "react";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import Cookies from "js-cookie";

import { useMutation } from "@apollo/client";
import { MUTATE_SEND_POLL } from "Services/queries";
import { CatRating } from "./CatRating";
import { Popup } from "./Popup";
import { CatWithHeart } from "Constants/SVG";
import { sendMetriks } from "Services/sendMetriks";

const useStyles = createUseStyles((theme) => ({
	title: {
		margin: 0,
		padding: 0,
		color: "#121a2f",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "20px",
	},
	textarea: {
		height: "7.25rem",
		padding: "12px 20px",
		fontSize: "16px",
		lineHeight: "20px",
		color: "#121a2f",
		resize: "none",
		overflow: "hidden",
		width: "100%",
		border: "1px solid #dce0e9",
		borderRadius: "16px",
		"&::placeholder": {
			fontSize: "14px",
			lineHeight: "20px",
			color: "#9ca3af",
		},
	},
	accent: {
		cursor: "pointer",
		color: "#ffffff",
		fontWeight: "600",
		appearance: "button",
		border: "none",
		backgroundColor: "#f97316",
		height: "2.25rem",
		padding: "8px 16px",
		fontSize: "14px",
		lineHeight: "20px",
		borderRadius: "128px",
		width: "fit-content",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	StepTwoText: {
		fontSize: "14px",
		lineHeight: "20px",
		color: "#121a2f",
		textAlign: "center",
		fontWeight: 600,
	},
	CatIcon: {
		width: "104px",
		height: "104px",
		"& svg": {
			width: "104px",
			height: "104px",
		},
	},
	["@media (min-width: 1280px)"]: {
		CatIcon: {
			width: "120px",
			height: "120px",
			"& svg": {
				width: "120px",
				height: "120px",
			},
		},
		title: {
			fontSize: "18px",
			lineHeight: "24px",
		},
		StepTwoText: {
			fontSize: "18px",
			lineHeight: "24px",
		},
	},
}));

export const WidgetRatingPopup = (props) => {
	const { isOpen, onClose, ip } = props;
	const classes = useStyles();
	const [sendPoll] = useMutation(MUTATE_SEND_POLL);

	const [checked, setChecked] = useState("");
	const [comment, setComment] = useState("");
	const [show, setShow] = useState(false);
	const [step, setStep] = useState(1);
	const [timer, setTimer] = useState(null);

	const onCatIconClick = (event) => {
		setChecked(event.target.value);
		setShow(true);
	};

	const onCloseButtonClick = () => {
		if (timer) {
			clearTimeout(timer);
			setTimer(null);
		}

		onClose();

		setStep(1);
	};

	const onSubmitButtonClick = () => {
		sendMetriks("CLICK_SUBMIT_BUTTON_IN_WIDGET_RATING");
		const submitData = {
			score: Number(checked),
			comment,
			current_url: typeof window !== "undefined" ? window.location.href : "",
			user_info: {
				uuid_user: Cookies.get("uuid"),
				user_agent: navigator.userAgent,
				width: window.innerWidth,
				height: window.innerHeight,
				theme_site: "light",
				first_url: Cookies.get("firstURL"),
				ip: Cookies.get("ip"),
				search_system: Cookies.get("ip"),
			},
		};

		sendPoll({ variables: submitData });
		setStep(2);
		setChecked("");
		setComment("");
		setShow(false);

		setTimer(
			setTimeout(() => {
				onCloseButtonClick();
			}, 3000)
		);
	};

	return (
		<>
			{step !== 0 && (
				<Popup isOpen={isOpen} onClose={onCloseButtonClick}>
					<>
						{step === 1 && (
							<>
								<h2 className={classes.title}>{"Как вам наш сайт ?"}</h2>
								<CatRating checked={checked} onChange={onCatIconClick} />
								{show && (
									<textarea
										value={comment}
										onChange={(event) => setComment(event.target.value)}
										placeholder="Расскажите, если что-то не так - мы постараемся исправить"
										className={classes.textarea}
									/>
								)}
								{checked && (
									<button type="submit" onClick={onSubmitButtonClick} className={classes.accent}>
										{"Отправить"}
									</button>
								)}
							</>
						)}
						{step === 2 && (
							<>
								<div className={classes.CatIcon}>{CatWithHeart}</div>
								<h2 className={classes.StepTwoText}>
									{checked === "5"
										? "Спасибо, очень приятно! Пойду передам команде :)"
										: "Спасибо, что помогаете нам стать лучше!"}
								</h2>
							</>
						)}
					</>
				</Popup>
			)}
		</>
	);
};
