import React from "react";
import { Redirect404 } from "ComponentsUI/Redirects";
import configRouters from "Configs/routes";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withDefaultParams from "Services/withDefaultParams";
import Cookies from "js-cookie";
import { Redirect } from "react-router";
import regionRedirectCalc from "Helpers/regionRedirect";

const arrayCheckRegionUrl = [
	"orders",
	"providers",
	"news",
	"reviews",
	"compare",
	"actions",
	"rates",
	"business",
	"rating",
	"address",
	"feedback",
	// "about",
	"quiz",
	"user",
	"contact-feedback",
	"operatory",
	"nomera",
	"ratesmobile",
	"doma-nzl",
	"oplata-i-garantii",
	"site-map",
	"dom",
	"podklyuchit-internet",
];

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
export default class RegionRedirectHOC extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			wasMount: false,
		};
	}

	componentDidMount() {
		this.initComponent();
	}

	initComponent() {
		this.setState({
			wasMount: true,
		});
	}

	render() {
		// TODO если редирект уже был, то ничего не рендерим вообще
		if (
			this.props.staticContext &&
			this.props.staticContext.location &&
			this.props.staticContext.location.state &&
			this.props.staticContext.location.state.isRedirect
		) {
			return null;
		}
		if (!this.state.wasMount || !this.props.match.params.regionUrl) {
			const regionUrl = this.props.match.params.regionUrl;
			const regionUrlComing = this.props.match.params.regionUrlComing;

			let siteConfig = this.props.ApplicationModel.getSiteConfig();

			let regions = siteConfig.regions.available;
			let regionDefault = siteConfig.regions.default;
			if (!!regionDefault && !!regionDefault.id) {
				// Если есть регион по умолчанию
				let region = regions.find((item) => regionUrl == item.url);

				if (!!regionUrlComing && regionUrlComing.indexOf("select-region") === -1) {
					if (!region) {
						return (
							<Redirect404
								message404={"Не правильно прописан регион, где есть регион по умолчанию"}
							/>
						);
					}
					if (regionDefault && regionDefault.url == regionUrlComing) {
						return <Redirect404 message404={"Дефолтный регион прописан в урле"} />;
					}
					//TODO возможно исправит костыль с регионом
					// if (this.props.match.url.search(regionDefault.url)  !== -1) {
					//     return <Redirect404 message404={"Дефолтный регион прописан в урле"}/>;
					// }

					if (this.props.staticContext) {
						this.props.staticContext.setCoockie("current_region", region.id);
					}
				}
			} else {
				// Если региона по умолчанию нету
				if (regionUrlComing) {
					const withoutRegions = ["select-region", "about"];
					if (withoutRegions.indexOf(regionUrlComing) < 0) {
						// проверяем на попадание в наши URL
						if (arrayCheckRegionUrl.indexOf(regionUrlComing) >= 0) {
							// если попали на наш URL, то смотрим есть ли в куках регион, чтобы сразу на него перевести пользователя
							let currentRegion = null;
							if (this.props.staticContext) {
								currentRegion = this.props.staticContext.getCoockie("current_region");
							} else {
								currentRegion = Cookies.get("current_region");
							}
							if (!currentRegion) {
								let pathname = regionRedirectCalc(this.props.location.pathname);
								return (
									<Redirect
										to={{
											pathname: configRouters.SelectRegion.route,
											state: {
												isRedirect: true,
												backUrl: pathname,
												redirectCode: 302,
											},
											search: `?backUrl=${pathname}`,
										}}
									/>
								);
							} else {
								return (
									<Redirect
										to={{
											pathname: `/${
												regions.find((r) => r.id == currentRegion).url
											}${this.props.location.pathname}`,
											state: {
												isRedirect: true,
												redirectCode: 302,
											},
										}}
									/>
								);
							}
						} else {
							// ищем регион в доступных для сайта регионах
							const currentRegion = regions.find((r) => r.url == regionUrlComing);
							if (!currentRegion) {
								// тут 404 по несовпадению региона
								return (
									<Redirect404
										message404={"Не правильно прописан регион, где нет региона по умолчанию"}
									/>
								);
							} else {
								// пишем в кукe выбранный регион

								if (this.props.staticContext) {
									this.props.staticContext.setCoockie("current_region", currentRegion.id);
								}
							}
						}
					}
				} else {
					// Если регион был в куках
					let currentRegion = null;
					if (this.props.staticContext) {
						currentRegion = this.props.staticContext.getCoockie("current_region");
					} else {
						currentRegion = Cookies.get("current_region");
					}
					if (currentRegion) {
						return (
							<Redirect
								to={{
									pathname: `/${regions.find((r) => r.id == currentRegion).url}`,
									state: {
										isRedirect: true,
										redirectCode: 302,
									},
								}}
							/>
						);
					}
				}
			}
		}

		return this.props.children;
	}
}
