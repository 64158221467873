import React from "react";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				margin: "0 -20px",
			},
		},
	};
};

const NoContainer = ({ classes, children }) => <div className={classes.root}>{children}</div>;

export default withStyles(styles)(NoContainer);

export { NoContainer as __NoContainer };
