import React from "react";
import BaseTheme from "ComponentsUI/Styles/Themes/Base";
import withStyles from "react-jss";
import classNames from "classnames";

const styles = (theme) => ({
	shiftY25: {
		objectPosition: "50% 25% !important",
	},
	img: {
		position: "absolute",
		top: "0",
		left: "0",
		bottom: "0",
		right: "0",
		zIndex: "-1",
		objectPosition: "50% 0",
		objectFit: "cover",
		opacity: ({ opacity }) => (opacity ? 0.7 : 1),
		transform: "translate3d(0,0,0)",
		overflow: "hidden",
		width: "100%",
		height: "100%",
	},
	zIndexZero: {
		zIndex: 0,
	},
	backgroundNone: {
		display: "none",
	},
});

const Background = (props) => {
	let classes = props.classes;
	let background = props.background || {};

	return (
		<>
			<picture>
				<source
					type="image/webp"
					srcSet={props.webpTransformer(background.lg)}
					media={`(min-width : ${BaseTheme.media.lg})`}
				/>
				<source
					type="image/webp"
					srcSet={props.webpTransformer(background.md)}
					media={`(min-width : ${BaseTheme.media.md})`}
				/>
				<source
					type="image/webp"
					srcSet={props.webpTransformer(background.sm)}
					media={`(min-width : ${BaseTheme.media.sm})`}
				/>
				<source
					type="image/webp"
					srcSet={props.webpTransformer(background.xs)}
					media={`(min-width : 100px)`}
				/>
				<source srcSet={background.lg} media={`(min-width : ${BaseTheme.media.lg})`} />
				<source srcSet={background.md} media={`(min-width : ${BaseTheme.media.md})`} />
				<source srcSet={background.sm} media={`(min-width : ${BaseTheme.media.sm})`} />
				<source srcSet={background.xs} media={`(min-width : 100px)`} />
				<img
					src={background.lg}
					alt="Фоновое изображение"
					className={classNames(classes.img, {
						[classes.zIndexZero]: props.zIndexZero,
						[classes.shiftY25]: props.shiftY == 25,
						[classes.backgroundNone]: props.backgroundNone,
					})}
				/>
			</picture>
		</>
	);
};

Background.defaultProps = {
	background: {},
	opacity: false,
	zIndexZero: false,
};

export default withStyles(styles)(Background);
