import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withDefaultParams from "Services/withDefaultParams";
import { WaitCallPhoneButton } from "ComponentsUI/Navigation";
import { withPhone } from "HOC/WithPhone";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";
import { EVENT_CLICK_PHONE_MAIN } from "Constants/metriks/GoogleActions";
import Metrika from "Services/Metrika";
import { checkTimeMatch } from "Services/Time";

@withRouter
@withDefaultParams
@withPhone
@inject("ApplicationModel")
@observer
export default class PhoneFixedButtonContainer extends React.Component {
	onClickHandler() {
		Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_CLICK_BUTTON, EVENT_CLICK_PHONE_MAIN);
	}
	render() {
		let {
			ApplicationModel,
			location: { pathname },
		} = this.props;

		const notUsedUrls = ["nomera", "operatory", "ratesmobile", "sim-karty"];

		if (notUsedUrls.find((url) => pathname.includes(url) == true)) {
			return null;
		}

		const floating_phone = ApplicationModel?.siteConfig?.settings?.floating_phone;

		let text = this.props.ApplicationModel.text;

		if (!this.props.siteRegionPhones) {
			return null;
		}
		if (!this.props.siteRegionPhones.phone) {
			return null;
		}

		if (!floating_phone?.enabled) {
			return null;
		}

		if (floating_phone.time_start && floating_phone.time_end) {
			const show = checkTimeMatch(floating_phone.time_start, floating_phone.time_end);
			if (!show) {
				return null;
			}
		}

		return (
			<WaitCallPhoneButton
				store={this.props.ApplicationModel}
				onClickHandler={() => this.onClickHandler()}
				text={text}
				fixedTheme={this.props.fixedTheme}
				passiveTheme={this.props.passiveTheme}
			/>
		);
	}
}
