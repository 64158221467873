import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { MoreLink } from "ComponentsUIV2/Links";

const useStyles = createUseStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: "4px",
		"& >:first-child": {
			marginBottom: "4px",
		},
	},
	moreButton: {
		marginTop: "4px",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			gap: "16px",
			"& >:first-child": {
				marginBottom: 0,
			},
		},
		moreButton: {
			marginTop: 0,
			marginBottom: "10px",
		},
	},
}));

const FooterTopList = (props) => {
	const { children, moreLink, moreLink_text, moreLinkSvg, isButton, isOpen, subtitlesList } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{children}
			{isButton && !isOpen ? null : (
				<>
					{subtitlesList}
					{moreLink ? (
						<div className={classes.moreButton}>
							<MoreLink text={moreLink_text} svg={moreLinkSvg} src={moreLink} />
						</div>
					) : null}
				</>
			)}
		</div>
	);
};

FooterTopList.propTypes = {
	children: PropTypes.node,
	isButton: PropTypes.bool,
	isOpen: PropTypes.bool,
	subtitles: PropTypes.array,
	moreLink: PropTypes.string,
	moreLink_text: PropTypes.string,
	moreLinkSvg: PropTypes.node,
};

export default FooterTopList;
