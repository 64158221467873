const compileTerms = (domain, location, shema) => {
	let text = `<p>Настоящее Пользовательское соглашение разработано в соответствии с требованиями действующего российского законодательства и направлено на регулирование взаимоотношений между Администрацией и Пользователями сайта, организациями-провайдерами; определение их основных прав и обязанностей, пределов ответственности и оснований освобождения от нее. </p>

<p>Использование информационного портала ${domain} Пользователем означает, что Пользователь принимает и обязуется соблюдать все нижеприведенные условия настоящего Соглашения.</p>

<p>Администрация ${domain} оставляет за собой право вносить в Соглашение изменения, которые вступают в силу с момента публикации. Текст действующей редакции Соглашения всегда доступен по адресу: <a href='${shema}${domain}${location}' target="_blank">${shema}${domain}${location}</a>. Дальнейшее использование Пользователем сайта ${domain} после внесения подобных изменений означает его согласие с ними.</p>

<p>Пользователь обязуется самостоятельно осуществлять контроль изменений в положениях настоящего Соглашения и несет ответственность, а также все негативные последствия, связанные с несоблюдением данной обязанности. При несогласии Пользователя с соответствующими изменениями Пользователь обязан прекратить использование Сайта, в противном случае продолжение использования Пользователем Сайта означает, что Пользователь согласен с условиями настоящего Пользовательского соглашения в новой редакции. </p>

<div>
<h2>Основные термины</h2>
<p>Сайт – совокупность размещенных в сети электронных документов (файлов), объединенных единой темой, дизайном и единым адресным пространством домена ${domain}. Стартовая страница Сайта размещена в сети Интернет по адресу <a target='_blank' href='${shema}${domain}'> ${shema}${domain}</a>. </p>
<p>Администрация Сайта (Администрация) – Юридическое лицо, которому принадлежат все соответствующие имущественные права на Сайт, включая права на доменное имя Сайта,  осуществляющее его администрирование. </p>
<p>Пользователь Сайта (Пользователь) – лицо, прошедшее процедуру регистрации, получившее индивидуальный логин и пароль, а также имеющее свой Аккаунт. Для целей Пользовательского соглашения под Пользователем понимается также лицо, которое не прошло процедуру регистрации, но осуществляет доступ к Сайту и/или использует и/или использовало его. Любое лицо, осуществляющее доступ к Сайту, этим автоматически подтверждает, что оно полностью согласно с положениями Пользовательского соглашения, и что в отношении него применимы требования, установленные Пользовательским соглашением.</p>
<p>Учетная запись пользователя (Аккаунт) – интернет-пространство, защищенное паролем. Содержит информацию о пользователе и Контент, сгенерированный Пользователем. Учетная запись содержит личные и контактные данные Пользователя, включая, но не ограничиваясь, такими как имя, почтовый и электронный адреса, номер телефона, дополнительные данные, заполняемые с согласия пользователя для подключения его к провайдеру с помощью сайта.</p>
<p>Провайдер – поставщик услуг сети Интернет, подключающий Пользователей к сети Интернет через компьютерные устройства (компьютеры).</p>
<p>Контент – любое информационно-значимое наполнение информационного ресурса, в том числе в виде текстов, комментариев, анонсов, фото, видео, а также новости и прочие материалы, оставленные пользователем на Сайте под своей Учетной записью.</p>
</div>

<div>
<h2>1. Предмет Пользовательского соглашения </h2>
<p>1.1. Предметом настоящего Пользовательского соглашения является оказание Администрацией сайта Пользователям услуг по предоставлению в пользование сайта <a href="${shema}${domain}" target="_blank">${shema}${domain}</a> (далее Сайт). </p>
<p>1.2. Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями и действует в течение всего времени предоставления и использования услуг.</p>
<p>1.3. Пользователь считается принявшим условия настоящего Пользовательского соглашения с момента первого использования Сайта (первого захода на Сайт).</p>
<p>1.4. Администрация сайта осуществляет сбор, хранение, обработку, передачу, представление и иные аналогичные действия в отношении информации, носящей открытый и общедоступный характер, в частности о доменах, системах управления сайтом, о хостинге, об IP-адресах и т.д.</p>
</div>

<div>
<h2> 2. Особенности размещения на Сайте информации Пользователями </h2>
<p>2.1. Пользователь сайта вправе размещать информацию о качестве оказываемых тем или иным провайдером телематических услуг связи. Информация о провайдере, размещаемая Пользователем, носит оценочный характер и представляет собой исключительно личное (субъективное) мнение Пользователя сайта об оказываемых соответствующим провайдером услугах, в частности о скорости интернет-соединения, условиях технической поддержки, недостатках, доступности и т.д.</p>
<p>2.2. Пользователь сайта обязуется размещать на Сайте достоверную и объективную информацию о соответствующем провайдере, имеющуюся у него в связи с использованием услуг, предоставляемых ему таким провайдером.</p>
<p>2.3. Настоящим Пользователь сайта дает согласие на передачу информации о своем IP адресе представителю Провайдера, прошедшему процедуру регистрации на Сайте и имеющему свой специальный аккаунт. Передача информации об IP адресе Пользователя сайта представителю Провайдера осуществляется исключительно в случаях, если соответствующий Пользователь сайта разместил на Сайте информацию, указанную в пункте 2.1 данного Соглашения, о таком Провайдере.</p>
<p>2.4. Ответственность за информацию, размещенную Пользователем на Сайте, в частности за нарушение деловой репутации Провайдера, несет непосредственно сам Пользователь сайта. Администрация сайта не несет ответственности за размещенные Пользователем отзывы. При этом Администрация сайта осуществляет контроль над размещаемой информацией, в частности за предотвращением оскорблений, унижений третьих лиц, в частности должностных лиц Провайдера, за нарушение деловой репутации Провайдера без оснований и доказательств.</p>
<p>2.5. Администрация сайта вправе в любой момент потребовать у Пользователя сайта предоставить доказательства для подтверждения информации, размещенной им о конкретном провайдере. Объективность и допустимость соответствующих доказательств определяется исключительно по усмотрению Администрации сайта.</p>
<p>2.6. Администрация сайта вправе в любой момент без предварительного уведомления удалить информацию Пользователя, размещенную на Сайте о конкретном провайдере. При этом такой Пользователь обязуется не предъявлять к Администрации сайта какие-либо претензии и требования, а при наличии таковых – они подлежат отклонению без рассмотрения.</p>
<p>2.7. Формирование рейтинга организаций-провайдеров производится исключительно на основании представленной в отзывах Пользователей сайта информации. Ввиду этого Администрация сайта не несет ответственность за полную достоверность и объективность рейтинга провайдеров.</p>
</div>

<div>
<h2> 3. Администрация имеет право: </h2>
<p>3.1. В любое время изменять оформление Сайта, его Контент, список сервисов, изменять или дополнять используемые скрипты, программное обеспечение и другие объекты, используемые или хранящиеся на Сайте.</p>
<p>3.2. При необходимости отправлять Пользователям по электронной почте сообщения, касающиеся использования Сайта.</p>
<p>3.3. Изменять (модерировать) или удалять любой Контент, нарушающий настоящее Соглашение, а также приостанавливать, ограничивать или прекращать доступ Пользователя ко всем или к любому из разделов или сервисов Сайта с предварительным уведомлением или без такового.</p>
<p>3.4. Заблокировать аккаунт пользователя за спам, некорректное поведение, накрутку просмотров/рейтингов/репутации любыми способами (в том числе за провокации на взаимное плюсование отзывов) или за грубые нарушения других пунктов правил.</p>
<p>3.5. Заблокировать аккаунты в случае регистрации дополнительных аккаунтов одним и тем же лицом.</p>
<p>3.6. Повышать личный коэффициент авторам за интересные и полезные отзывы и соблюдающим кодекс чести. А также понижать репутацию и личный коэффициент авторам за малограмотные и малосодержательные отзывы или нарушения правил.</p>
<p>3.7. Отказать в размещении, изменении и удалении любых материалов на свое усмотрение.</p>
<p>3.8. Требовать необходимые материалы для проверки достоверности и правдивости информации (включая фото объектов и документов).</p>
<p>3.9. Не удалять аккаунты и отзывы по запросу авторов. Если Пользователь согласился с правилами и зарегистрировался, а в будущем решил покинуть проект, то все публикации Пользователя будут оставаться частью проекта.</p>
<p>3.10. Изменять данные правила с целью улучшения качества предоставляемого сервиса. </p>
</div>

<div>
<h2> 4. Пользователь имеет право: </h2>
<p> 4.1. Размещать Контент, не противоречащий данному Соглашению. </p>
<p> 4.2. Обращаться к Администрации Сайта с целью разрешения спорных вопросов. </p>
<p> 4.3. Бесплатно пользоваться всем Контентом сайта, как редакционным, так и пользовательским, в личных целях, не сопряженных с целями получения коммерческой выгоды.</p>
</div>

<div>
<h2> 5. Пользователь обязуется: </h2>
<p> 5.1. Принимать надлежащие меры для обеспечения сохранности личного логина и пароля для доступа к Сайту. </p>
<p> 5.2. Регулярно знакомиться с содержанием настоящего Соглашения в целях своевременного ознакомления с его изменениями. </p>
<p> 5.3. Нести полную ответственность за любые действия, совершенные Пользователем с использованием его Аккаунта, а также за любые последствия, которые могло повлечь или повлекло подобное его использование. </p>
<p> 5.4. Соблюдать правила пользования каждого раздела сайта, если таковые существуют и описаны в этом разделе.</p>
<p> 5.5. Используя информацию с Сайта, осознавать и принимать риски, связанные с ее возможной недостоверностью, а также с тем, что некоторая информация может показаться ему угрожающей, оскорбительной, клеветнической, заведомо ложной, грубой, непристойной. Если это произошло, Пользователь должен немедленно сообщить Администрации о наличии подобной информации. </p>
</div>

<div>
<h2> 6. Ограничения и запреты для пользователей </h2>
<p> Пользователь Сайта не вправе: </p>
<p>6.1. Опубликовывать отзывы или иную информацию, нарушающую требования действующего законодательства, в частности содержащие угрозы и оскорбления, дискредитирующие других лиц, нарушающие права граждан на частную жизнь или публичный порядок, носящие характер непристойности; нарушающие в той или иной степени честь и достоинство, права и охраняемые законом интересы других лиц; способствующие или содержащие призывы к разжиганию религиозной, расовой или межнациональной розни, содержащие попытки разжигания вражды или призывы к насилию. </p>
<p>6.2. Указывать при регистрации или размещать в аккаунте недостоверную информацию о себе. </p>
<p>6.3. Размещать на Сайте информацию в форме рекламы третьих лиц и/или товаров (работ, услуг), реализуемых (выполняемых, оказываемых) данными лицами.</p>
<p>6.4. Использовать в противоправных целях информацию о Пользователе или о других лицах, в частности об их IP адресе, операционной системе, браузере, полученную посредством использования специальных программ.</p>
<p>6.5. Распространять или организовывать распространение на Сайте вредоносных компьютерных программ и файлов (вирусы, трояны, «временные бомбы», «червяки» или любые другие программы, причиняющие вред или приводящие к удалению информации на Сайте). </p>
<p>6.6. Осуществлять автоматический сбор информации с Сайта посредством использования специальных компьютерных программ (парсеров). </p>
<p>6.7. Совершать попытки несанкционированного доступа в аккаунты других Пользователей или завладения информацией об их паролях. </p>
<p>6.8. За нарушение положений, установленных пунктом 6 настоящего Соглашения, Администрация сайта вправе применить к Пользователю меры, установленные пунктом 3 данного Соглашения, а также потребовать возмещения всех убытков, понесенных в связи с несоблюдением указанных ограничений. </p>
</div>

<div>
<h2>7. Ответственность сторон</h2>
<p>7.1. За неисполнение или ненадлежащее исполнение условий настоящего Пользовательского соглашения Стороны несут ответственность в соответствии с требованиями действующего законодательства Российской Федерации. </p>
<p>7.2. Администрация сайта не несет ответственности за неисполнение или ненадлежащее исполнение своих обязательств, если такое неисполнение или ненадлежащее исполнение обязательств обусловлено действиями третьих лиц, в частности лиц, предоставляющих хостинг для Сайта, указанного в пункте 1.1 настоящего Пользовательского соглашения.</p>
<p>7.3. Администрация сайта не несет ответственности за расходы, убытки и иной ущерб, возникший у Пользователя сайта в связи с ознакомлением с рекламой, рекламными баннерами и объявлениями, контекстной рекламой, гиперссылки на которые размещены на Сайте. Пользователь сайта обязуется не предъявлять к Администрации сайта претензии в отношении данных расходов, убытков и иного ущерба, а поступившие претензии подлежат отклонению. Пользователь сайта настоящим подтверждает дачу согласия на использование сторонними организациями информации (за исключением имени, адреса, адреса электронной почты или номера телефона) о посещениях сайтов, гиперссылки на которые размещены на Сайте и по которым кликал Пользователь, с целью предоставления наиболее релевантных объявлений о товарах и услугах.</p>
<p>7.4. Во всем ином, что не оговорено в настоящем разделе, Пользователь сайта и Администрация сайта несут ответственность в соответствии с положениями настоящего Пользовательского соглашения, а также требованиями действующего законодательства Российской Федерации.</p>
<p>7.5. Администрация сайта и Пользователь сайта освобождаются от ответственности за нарушение условий настоящего Пользовательского соглашения, если такое нарушение вызвано действием форс-мажорных обстоятельств (обстоятельств непреодолимой силы). Такими действиями в частности являются действия органов государственной власти, местного самоуправления, пожар, наводнение, землетрясение, другие стихийные бедствия, отсутствие электроэнергии и/или сбои работы компьютерной сети, забастовки, гражданские волнения, беспорядки.</p>
</div>

<div>
<h2> 8. Права на Контент, размещенный на Сайте </h2>
<p>8.1. Все используемые и размещенные на Сайте результаты интеллектуальной деятельности, а также сам Сайт являются интеллектуальной собственностью их законных правообладателей и охраняются законодательством об интеллектуальной собственности Российской Федерации, а также соответствующими международными правовыми конвенциями. </p>
<p>8.2. Никакой Контент не может быть скопирован (воспроизведен), переработан, распространен, отображен во фрейме, опубликован, скачан, передан, продан или иным способом использован целиком или по частям без предварительного разрешения правообладателя, кроме случаев, когда правообладатель явным образом выразил свое согласие на свободное использование материала любым лицом, за исключением случаев, установленных настоящим Соглашением, а также действующим законодательством Российской Федерации.</p>
<p>8.3. Использование Контента, к которому Пользователь получил доступ исключительно для личного некоммерческого использования, допускается при условии сохранения всех знаков авторства (копирайтов) или других уведомлений об авторстве, сохранения имени автора в неизменном виде, сохранении произведения в неизменном виде.</p>
<p>8.4. Все материалы, права на которые принадлежат Администрации Сайта <a target="_blank" href="${shema}${domain}">${shema}${domain}</a>, могут быть воспроизведены в любых средствах массовой информации, на серверах сети Интернет или на любых иных носителях без каких-либо ограничений по объему и срокам публикации. Это разрешение в равной степени распространяется на газеты, журналы, радиостанции, телеканалы, сайты и страницы сети Интернет. Единственным условием перепечатки и ретрансляции является прямая ссылка на первоисточник <a target="_blank" href="${shema}${domain}" >${shema}${domain}</a> .Предварительного согласия на перепечатку со стороны издателей или авторов Сайта не требуется.</p>
<p>8.5. При воспроизведении материалов не допускается переработка их оригинального текста. Сокращение или перекомпоновка частей материала допускается, но только в той мере, в какой это не приводит к искажению его смысла.
</div>

<div>
<h2> 9. Прочие условия </h2>
<p>9.1. Администрация сайта имеет право направлять определяемую ей информацию на адрес электронной почты Пользователя сайта, на номер его мобильного телефона и т.д. Настоящим Пользовательским соглашением Пользователь сайта подтверждает дачу согласия на получение соответствующих сообщений (информации) на адрес свой электронной почты, на номер мобильного телефона и т.д. Пользователь сайта вправе отказаться от рассылок и получения информации от Администрации сайта путем снятия/отказа от данной функции в своем личном кабинете или направлением письма администрации сайта. </p>
<p>9.2. Администрация сайта вправе устанавливать в отношении функций (приложений) разделов Сайта условия об их платном использовании. Стоимость использования соответствующей функции (приложения), порядок оплаты и иные условия определяются исключительно Администрацией сайта и доводятся до сведения Пользователей сайта путем размещения информации на Сайте. </p>
<p>9.3. Администрация сайта создает Пользователю, прошедшему процедуру регистрации, личный кабинет, в котором указывается информация о дате регистрации, провайдере, IP адресе, история измерений и иная информация, определяемая по усмотрению Администрации сайта. </p>
<p>9.4. Пользователь сайта с момента первого посещения сайта соглашается с использованием файлов cookie, которые необходимы для полноценной работы сайта. </p>
</div>`;
	return text;
};

export default compileTerms;
