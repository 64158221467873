import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { ContainerPaddingBottom } from "ComponentsUI/Spacings";
import AddressPopupHOC from "HOC/AddressPopupHOC";
import { Col } from "ComponentsUI/Styles/Grid";
import AddressAdverBlock from "./Address";
import AddressOneStringAdverBlock from "./AddressOneString";
import withDefaultParams from "Services/withDefaultParams";

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
class AddressAdverBlockContainer extends React.Component {
	render() {
		const {
			ApplicationModel: {
				siteConfig: { settings },
			},
			match: {
				params: { regionUrl },
			},
			autoExpansion,
		} = this.props;
		let one_string_search = settings?.one_string_search?.enabled;

		if (!regionUrl) {
			return null;
		}

		return (
			<ContainerPaddingBottom>
				<Col lg={6} md={6} sm={6}>
					<AddressPopupHOC>
						{one_string_search ? (
							<AddressOneStringAdverBlock
								isRatesAddForm={this.props.isRatesAddForm}
								withQuiz={true}
								providerId={this.props.providerId}
								datatest="providers_find_adress_one_string"
								onHeightChangeHandler={this.props.onHeightChangeHandler}
							/>
						) : (
							<AddressAdverBlock
								withQuiz={true}
								providerId={this.props.providerId}
								datatest="providers_find_adress"
								onHeightChangeHandler={this.props.onHeightChangeHandler}
								autoExpansion={autoExpansion}
							/>
						)}
					</AddressPopupHOC>
				</Col>
			</ContainerPaddingBottom>
		);
	}
}

export default AddressAdverBlockContainer;
