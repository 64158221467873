import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import loadable from "@loadable/component";
import { v4 } from "uuid";
import { sendMetriks } from "Services/sendMetriks";
import AddressPopupHOC from "HOC/AddressPopupHOC";

const AddressSearchHomeContainerB = loadable(
	() => import("Containers/AddressSearchHomeContainerB")
);

const styles = (theme) => {
	return {
		root: {
			position: "relative",
		},
		wrapper: {
			padding: "40px 30px 170px",
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			background: theme.palette.colors.purplishBlue,
			boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.5)",
			borderRadius: "10px",
			width: "380px",
		},
		cutWrapper: {
			padding: "40px 30px",
		},
		typeB: {},
		close: {
			position: "absolute",
			top: "20px",
			right: "20px",
			cursor: "pointer",
			"& span": {
				color: theme.palette.colors.disabled,
				fontSize: "16px",
			},
		},
		header: {
			fontFamily: theme.fonts.bold,
			fontSize: "18px",
			lineHeight: 1,
			color: theme.palette.colors.white,
			maxWidth: "320px",
			textAlign: "center",
			marginBottom: "10px",
		},
		subtext: {
			fontFamily: theme.fonts.light,
			fontSize: "14px",
			lineHeight: 1.29,
			color: theme.palette.colors.white,
			maxWidth: "270px",
			textAlign: "center",
			margin: "20px 0",
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				alignSelf: "flex-end",
			},
			wrapper: {
				padding: "40px 20px 0",
				width: "auto",
				height: "calc(100vh - 60px)",
				boxShadow: "none",
				borderRadius: "0",
			},
			cutWrapper: {
				padding: "40px 20px 0",
			},
			header: {
				width: "100%",
			},
			close: {
				display: "none",
			},
			typeB: {
				"& $close": {
					display: "block",
					"& span": {
						fontSize: "16px",
					},
				},
			},
		},
	};
};

class FindTariffs extends React.Component {
	constructor(props) {
		super(props);
	}

	handleClick() {
		sendMetriks("CLICK_HEADER");
		// Metrika.getInstance().google.sendEvent(
		//     EVENT_CATEGORY_CLICK_BUTTON,
		//     EVENT_ACTION_CLICK_FIND_TARIFFS_HEADER
		// );
		this.props.onClose();
	}

	render() {
		const providerInfo = {
			name: this.props.data?.name,
			id: this.props.data?.id,
		};
		let classes = this.props.classes;
		let text = this.props.text;
		let fromTariff = false;
		if (this.props.fromTariff == 1) {
			fromTariff = true;
		}

		let newViewSearch = this.props.showNewViewSearch;
		return (
			<div
				ref={(div) => {
					if (this.props.refs) {
						if (!this.props.refs.length) {
							this.props.refs.push(div);
						}
					}
				}}
				className={classNames(classes.root, {
					[classes.typeB]: !!this.props.typeB,
				})}
			>
				<div
					className={classNames(classes.wrapper, {
						[classes.cutWrapper]: !!this.props.cutWrapper,
					})}
				>
					<div className={classes.header}>{this.props.header}</div>
					<AddressPopupHOC>
						<AddressSearchHomeContainerB
							isPopup={true}
							onCallback={() => {}}
							fromTariff={fromTariff}
							withQuiz={true}
							cbBeforeAction={() => this.handleClick()}
							whiteTheme={true}
							withoutContainer={true}
							isFixedList={true}
							providerId={this.props.data?.id}
							providerInfo={providerInfo}
							datatestButton={this.props.datatestForAddressButton}
							successCallback={this.props.successCallback}
							hideProviderLogo={this.props.hideProviderLogo}
							alertNotAbsolute={true}
							houseInputId={v4()}
							streetInputId={v4()}
							layoutB={!newViewSearch}
							layoutBV2={newViewSearch}
							apartment={newViewSearch}
							autoExpansion={!newViewSearch}
						/>
					</AddressPopupHOC>

					<div className={classes.subtext}>{text.heroTextToVideo}</div>
				</div>
				<div className={classes.close} onClick={this.props.onClose}>
					<span className="icon24 icon-close" />
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(FindTariffs);
