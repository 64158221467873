import React from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";

const styles = (theme) => ({
	root: {
		fontSize: "0",
		lineHeight: "0",
		position: "relative",
		bottom: "5px",
		"& > span": {
			display: "block",
			fontFamily: theme.fonts.medium,
			fontSize: "10px",
			lineHeight: "1",
			letterSpacing: "2px",
			color: theme.palette.colors.oneMoreGrey,
			textTransform: "uppercase",
		},
		"& > span:nth-child(1)": {
			position: "relative",
			top: "2px",
			marginBottom: "5px",
		},
		"& > span:nth-child(1):before": {
			content: '""',
			height: "1px",
			width: "25px",
			backgroundColor: theme.palette.colors.spanishGrey,
			position: "absolute",
			bottom: "-1px",
			left: "2px",
		},
	},
});

const Measurement = ({ classes, header, subheader }) => {
	return (
		<div className={classes.root}>
			<span>{header}</span>
			<span>{subheader}</span>
		</div>
	);
};

Measurement.defaultProps = {
	header: "",
	subheader: "",
};

Measurement.propTypes = {
	header: PropTypes.string.isRequired,
	subheader: PropTypes.string.isRequired,
};

export default withStyles(styles)(Measurement);
