import React from "react";
import ReactDOM from "react-dom";
import { forwardRef } from "react";
import classNames from "classnames";

const staticStyles = {
	position: "static",
};

class DropdownList extends React.Component {
	portal = null;

	componentDidMount() {
		this.initDropdown();
	}

	initDropdown() {
		this.props.onMount(this.portal);
		const target = ReactDOM.findDOMNode(this.props.target);
		let targetCoords = target.getBoundingClientRect();
		let list = this.portal.querySelector("ul");
		let heightPortal = parseInt(window.getComputedStyle(list).getPropertyValue("max-height")) + 20;
		if (!!this.props.multiselect) {
			heightPortal += 50;
		}
		let targetBottom = null;
		if (!this.props.listContainerFunc) {
			targetBottom = !this.props.isFixedList
				? document.body.clientHeight - targetCoords.bottom
				: window.innerHeight - targetCoords.bottom;
		} else {
			let container = this.props.listContainerFunc();
			targetBottom = container.clientHeight - targetCoords.bottom;
		}

		if (targetBottom > heightPortal) {
			this.calculateListFromBottom();
			return;
		}

		if (targetCoords.top > heightPortal) {
			this.calculateListFromTop();
			return;
		}
		this.calculateListFromBottom();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let isChanged = false;
		Object.keys(this.props.windowSizes).forEach((item) => {
			if (this.props.windowSizes[item] !== prevProps.windowSizes[item]) {
				isChanged = true;
			}
		});

		if (this.props.focus !== prevProps.focus) {
			isChanged = true;
		}

		isChanged && this.initDropdown();
	}

	calculateListFromTop() {
		this.props.setDirection("up");
		const target = ReactDOM.findDOMNode(this.props.target);
		const portal = this.portal;
		let targetCoords = target.getBoundingClientRect();
		let width = targetCoords.width;
		let body = document.body;
		let left = null;
		let top = null;
		if (!this.props.listContainerFunc) {
			left = targetCoords.left;
			top = window.pageYOffset || body.scrollTop;
			top = !this.props.isFixedList ? top + targetCoords.top : targetCoords.top;
		} else {
			let container = this.props.listContainerFunc();
			let containerCoords = container.getBoundingClientRect();
			left = targetCoords.left - containerCoords.left;
			top = !this.props.isFixedList ? targetCoords.top - containerCoords.top : targetCoords.top;
		}

		portal.style.top = top + "px";
		portal.style.left = left + "px";
		portal.style.width = width + "px";
	}

	calculateListFromBottom() {
		this.props.setDirection("down");
		const target = ReactDOM.findDOMNode(this.props.target);
		const portal = this.portal;
		let targetCoords = target.getBoundingClientRect();
		let body = document.body;
		let width = targetCoords.width;
		let left = null;
		let top = null;

		if (!this.props.listContainerFunc) {
			left = targetCoords.left;
			top = window.pageYOffset || body.scrollTop;
			top = !this.props.isFixedList
				? top + targetCoords.top + targetCoords.height
				: targetCoords.top + targetCoords.height;
		} else {
			let container = this.props.listContainerFunc();
			let containerCoords = container.getBoundingClientRect();
			left = targetCoords.left - containerCoords.left;
			top = !this.props.isFixedList
				? targetCoords.top - containerCoords.top + targetCoords.height
				: targetCoords.top;
		}

		portal.style.top = top + "px";
		portal.style.left = left + "px";
		portal.style.width = width + "px";
	}

	generateContent() {
		return null;
	}

	render() {
		let classes = this.props.classes;
		let text = this.props.text;
		let container = document.querySelector("#forSelectField");
		if (!!this.props.listContainerFunc) {
			let newContainer = this.props.listContainerFunc();
			if (!!newContainer) {
				container = newContainer;
			}
		}
		return ReactDOM.createPortal(
			<div className={this.props.rootClassName} style={staticStyles}>
				<div className={this.props.wrapperClassName} style={staticStyles}>
					<div
						className={classNames(this.props.bodyClassName)}
						ref={(portal) => (this.portal = portal)}
					>
						<div>
							<ul
								ref={this.props.forwardedRef}
								className={classNames(classes.list, {
									[classes.listProviders]: !!this.props.height200,
								})}
							>
								{this.props.multiselect
									? this.props.buildOptionsListMultiSelect()
									: this.props.buildOptionsListSelect()}
							</ul>

							{this.props.multiselect && (
								<div className={classes.footer}>
									<div onClick={(e) => this.props.cancelChoose()} className={classes.footerCancel}>
										{text.CANCEL}
									</div>
									<div
										datatest="provider_filter_apply"
										onClick={(e) => this.props.acceptChoose()}
										className={classes.footerOk}
									>
										{text.APPLAY}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>,
			container
		);
	}
}

export default forwardRef((props, ref) => <DropdownList {...props} forwardedRef={ref} />);
