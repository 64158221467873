import { observable, action } from "mobx";
import orderBy from "lodash/orderBy";

export default class SortingModel {
	@observable sortAvailable = [];
	@observable selected = {};
	@observable items = [];

	constructor(data) {
		this.sortAvailable = data.sortAvailable;
		this.selected = data.sort;
		this.getItems = data.getItems;
	}

	@action
	reset() {
		this.sortAvailable = [];
		this.selected = null;
	}

	@action
	setSelected(data) {
		this.selected = data;
	}

	@action
	setSortAvailable(data) {
		this.sortAvailable = data;
	}

	getSortAvailable() {
		return this.sortAvailable;
	}

	getSelected() {
		return this.selected;
	}

	sortingItems() {
		let items = this.getItems();
		return (
			items &&
			orderBy(
				items,
				(o) => this.selected.item.key.split(".").reduce((o, i) => o[i], o),
				this.selected.up ? "desc" : "ask"
			)
		);
	}
}
