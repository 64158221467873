import React from "react";
import { PopupA } from "ComponentsUI/Popup";
import { inject, observer } from "mobx-react";
import Metrika from "Services/Metrika";
import { EVENT_CATEGORY_LEAD } from "Constants/metriks/GoogleCategorys";
import { CLICK_POPUP_TC } from "Constants/metriks/GoogleActions";
import { withPhone } from "HOC/WithPhone";
import { withRouter } from "react-router-dom";
import withDefaultParams from "Services/withDefaultParams";
import userInfo from "Services/UserInfo";
import { addAddressData, addABData, addRefererData } from "Utils";
@withRouter
@withDefaultParams
@withPhone
@inject("ApplicationModel")
@observer
export default class PopupAContainer extends React.Component {
	state = {
		isError: false,
		error: "",
		phone_connection: "",
		isSuccess: false,
		componentHeight: 0,
	};

	onChangeInputHandler = (e) => {
		this.setState({
			error: "",
			phone_connection: e.target.value,
		});
	};

	onFocus = () => {
		this.setState({
			isError: false,
		});
	};

	onBlur = () => {
		this.setState({
			isError:
				this.state.phone_connection.length !== 11 && this.state.phone_connection.length !== 1,
		});
	};

	onClickHandler = async (e) => {
		if (!!e) {
			e.preventDefault();
			e.stopPropagation();
		}

		let {
			ApplicationModel: { sendingStore, currentRegion, popupModel },
		} = this.props;

		let data = {
			phone_connection: this.state.phone_connection,
			region_id: !!currentRegion ? currentRegion.id : null,
			tariff_id: this.props.tariffId,
			lead_form_type: userInfo("popup_tc"),
		};

		data = addAddressData(data, this.props.ApplicationModel);
		data = addABData(data);
		data = addRefererData(data, this.props.ApplicationModel);

		let infoUser = {
			name_popup: "PopupA",
		};
		popupModel.setInfoUser(infoUser);

		let error = await sendingStore.onCallback(data);
		if (!error) {
			Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_LEAD, CLICK_POPUP_TC);
			this.setState({
				isSuccess: true,
			});
		} else {
			this.setState({
				error: "Заполните поле телефон",
			});
		}
	};

	render() {
		let text = this.props.ApplicationModel.text;
		let {
			ApplicationModel: {
				sendingStore,
				windowSizeModel: { windowSizes },
			},
		} = this.props;
		// let isSuccess = this.state.isSuccess || sendingStore.send;
		let isSuccess = this.state.isSuccess;
		let showPhone = true;
		if (this.props.siteRegionPhones) {
			if (this.props.siteRegionPhones.hasOwnProperty("show_on_site")) {
				showPhone = this.props.siteRegionPhones.show_on_site;
			}
		} else {
			showPhone = false;
		}
		return (
			<PopupA
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onChangeInputHandler={this.onChangeInputHandler}
				onClickHandler={this.onClickHandler}
				inputValue={this.state.phone_connection}
				text={text}
				closeModal={this.props.closeModal}
				success={isSuccess}
				windowSizes={windowSizes}
				errorText={this.state.error}
				showPhone={showPhone}
			/>
		);
	}
}
