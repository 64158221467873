import React from "react";
import loadable from "@loadable/component";
import LoadingSpinnerHOC from "HOC/LoadingSpinnerHOC";

export const Home = loadable(() => import("./home"), {
	fallback: <LoadingSpinnerHOC />,
});
export const ToHome = loadable(() => import("./tohome"), {
	fallback: <LoadingSpinnerHOC />,
});
export const Office = loadable(() => import("./office"), {
	fallback: <LoadingSpinnerHOC />,
});
export const Outhome = loadable(() => import("./outhome"), {
	fallback: <LoadingSpinnerHOC />,
});
export const Mobile = loadable(() => import("./mobile"));
export const Ass = loadable(() => import("./ass"));
