import React from "react";
import { observer, inject } from "mobx-react";
import isEmpty from "lodash/isEmpty";
import loadable from "@loadable/component";
import CounterContainer from "Containers/CounterContainer";
import AddressPopupHOC from "HOC/AddressPopupHOC";
import { Banner, BannerFrame, UnderBanner } from "ComponentsUI/Banner";
import DeclinationContainer from "Containers/DeclinationContainer";
import { withRouter } from "react-router-dom";
import HeroBannerSelectRegionContainer from "Containers/HeroBannerSelectRegionContainer";
import { graphql } from "@apollo/client/react/hoc";
import { PROVIDERS_COUNT_QUERY } from "Services/queries";
import withDefaultParams from "Services/withDefaultParams";
import { generateUrl } from "Services/withDefaultParams";
import images from "Configs/images.config";
import Metrika from "Services/Metrika";
import { YANDEX_TARGETS } from "Constants/metriks/yandex-targets";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";
import {
	EVENT_ACTION_CLICK_FLAT_BUTTON,
	EVENT_ACTION_CLICK_OFFICE_BUTTON,
} from "Constants/metriks/GoogleActions";
import { v4 } from "uuid";

const AddressSearchHomeContainerB = loadable(
	() => import("Containers/AddressSearchHomeContainerB")
);

var Routes = require("Configs/routes");
import { replaceStaticHost } from "Helpers/ImageManager";

//TODO переделать в сетку
@inject("ApplicationModel")
@observer
class HeroBannerContainer extends React.Component {
	onConnectOrder() {}

	onConnectOfficeOrder() {}

	onConnectOutHomeOrder() {}

	onClickRating() {}

	onClickCompare() {}

	onClickRates() {
		Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.CLICK_3IN1_FROM_HOMEPAGE, []);
	}

	handleChoose(value) {
		if (value == "home") {
			Metrika.getInstance().google.sendEvent(
				EVENT_CATEGORY_CLICK_BUTTON,
				EVENT_ACTION_CLICK_FLAT_BUTTON
			);
		} else {
			Metrika.getInstance().google.sendEvent(
				EVENT_CATEGORY_CLICK_BUTTON,
				EVENT_ACTION_CLICK_OFFICE_BUTTON
			);
		}
	}

	render() {
		let applicationModel = this.props.ApplicationModel;
		let pathName = this.props.location.pathname;
		let windowSizes = this.props.ApplicationModel.windowSizeModel.windowSizes;
		let errorModel = this.props.ApplicationModel.errorModel;
		let region = this.props.ApplicationModel.currentRegion;
		let textConfig = applicationModel.text;
		let ratingUrl = generateUrl(Routes.Rating.route, {
			regionUrl: this.props.match.params.regionUrl,
		});
		let compareUrl = generateUrl(Routes.Compare.route, {
			regionUrl: this.props.match.params.regionUrl,
		});
		let orderToHomeUrl = generateUrl(Routes.OrderToHome.route, {
			regionUrl: this.props.match.params.regionUrl,
		});
		let orderToOfficeUrl = generateUrl(Routes.OrderOffice.route, {
			regionUrl: this.props.match.params.regionUrl,
		});
		let orderToOutHomeUrl = generateUrl(Routes.OrderOuthome.route, {
			regionUrl: this.props.match.params.regionUrl,
		});
		let tariffTagInternetTvMobile = generateUrl(Routes.RatesTags.route, {
			regionUrl: this.props.match.params.regionUrl,
			tagCode: "internet-tv-mobile",
		});
		let siteCode = this.props.ApplicationModel.siteCode;

		let preHomeHintString = "";
		if (this.props.data) {
			if (this.props.data.providers) {
				preHomeHintString = `${textConfig.HERO_BANNER_MAIN_1_1} ${this.props.data.providers.pagination.items_total} ${textConfig.HERO_BANNER_MAIN_1_2} `;
			}
		}
		let toHomeHint = (
			<DeclinationContainer
				defaultString={textConfig.HERO_BANNER_MAIN_2}
				type="prepositional"
				preString={preHomeHintString}
			/>
		);
		let toOutHomeHint = (
			<DeclinationContainer
				defaultString={textConfig.TO_OUTHOME_TEXT_DEFAULT}
				type="prepositional"
				preString={textConfig.TO_OUTHOME_TEXT}
			/>
		);

		let imageHeroBannerSvg = {
			lg: replaceStaticHost(images.heroBannerSvgLg),
			md: replaceStaticHost(images.heroBannerSvgMd),
			sm: replaceStaticHost(images.heroBannerSvgSm),
			xs: replaceStaticHost(images.heroBannerSvgXs),
		};

		let imageConsultation = replaceStaticHost(images.callback);

		let newViewSearch = this.props.ApplicationModel?.siteConfig?.settings?.show_new_view_search;

		let middleComponent = (
			<AddressPopupHOC>
				<AddressSearchHomeContainerB
					withoutBorder={true}
					withoutContainer={true}
					withQuiz={true}
					sendGAWhenFulled={true}
					isMainPage={true}
					alertNotAbsolute={true}
					houseInputId={v4()}
					streetInputId={v4()}
					renderFormV2={newViewSearch}
					apartment={newViewSearch}
					autoExpansion={!newViewSearch}
					redirectToSelectRegion={isEmpty(region)}
				/>
			</AddressPopupHOC>
		);

		let content = (
			<BannerFrame
				siteCode={siteCode}
				key={0}
				title={this.props.h1Banner}
				subheader={this.props.h1After || textConfig.heroTextToVideo}
				heroVideoButton={textConfig.heroVideoButton}
				center={true}
				bottomComponent={middleComponent}
				mainPage={true}
				hideH1After={true}
				pathName={pathName}
			/>
		);

		let BottomComponent = (
			<UnderBanner
				datatest="main_widget"
				heroInternet={textConfig.heroInternet}
				heroRatingProviders={textConfig.heroRatingProviders}
				heroCompareTariffs={textConfig.heroCompareTariffs}
				heroFreeConsultation={textConfig.heroFreeConsultation}
				heroMode={textConfig.heroMode}
				toHome={textConfig.toHome}
				toOutHome={textConfig.IN_OWN_HOUSE}
				toOutHomeHint={toOutHomeHint}
				toOffice={textConfig.toOffice}
				toOfficeHint={textConfig.toOfficeHint}
				toHomeHint={toHomeHint}
				counter={<CounterContainer />}
				imageConsultation={imageConsultation}
				onConnectOrder={() => this.onConnectOrder()}
				onConnectOfficeOrder={() => this.onConnectOfficeOrder()}
				onConnectOutHomeOrder={() => this.onConnectOutHomeOrder()}
				onClickRating={() => this.onClickRating()}
				onClickCompare={() => this.onClickCompare()}
				isPurple={true}
				ratingUrl={ratingUrl}
				compareUrl={compareUrl}
				orderToHomeUrl={orderToHomeUrl}
				orderToOutHomeUrl={orderToOutHomeUrl}
				orderToOfficeUrl={orderToOfficeUrl}
				tariffTagInternetTvMobile={tariffTagInternetTvMobile}
				onClickRates={() => this.onClickRates()}
			/>
		);
		return (
			<Banner
				content={content}
				windowSizes={windowSizes}
				background={imageHeroBannerSvg}
				datatest="main_hero_banner"
				bottomComponent={!this.props.withoutBenefits ? BottomComponent : null}
			/>
		);
	}
}

export default withRouter(
	withDefaultParams(
		graphql(PROVIDERS_COUNT_QUERY, {
			options: (props) => {
				return {
					variables: {
						filter: `region.url=${props.match.params.regionUrl}`,
					},
				};
			},
		})(HeroBannerContainer)
	)
);
