import { useQuery } from "@apollo/client";
import { REGION_QUERY } from "Services/queries";
import { useParams } from "react-router-dom";
import useDefaultParams from "Services/useDefaultParams";

const useCurrentRegion = (regionUrl = null) => {
	if (!regionUrl) {
		const routeParams = useParams();
		const params = useDefaultParams(routeParams);
		regionUrl = params?.regionUrl;
	}

	return useQuery(REGION_QUERY, {
		variables: {
			filter: `url=${regionUrl}`,
		},
	});
};

export default useCurrentRegion;
