import React from "react";
import classNames from "classnames";
import Button from "ComponentsUI/Button";
import Row from "reactstrap/lib/Row";
import { Dropdown } from "ComponentsUI/Dropdown";
import { SelectField } from "ComponentsUI/Selectfield";
import withStyles from "react-jss";
import { sendMetriks } from "Services/sendMetriks";
import { v4 } from "uuid";
import { generateUrl } from "Services/withDefaultParams";
import routes from "Configs/routes";

const styles = (theme) => {
	return {
		root: {
			position: "relative",
		},
		typeConnectionError: {
			color: "#ff586c",
			width: "100%",
			fontSize: "16px",
			fontFamily: "MuseoSansCyrl-500",
			lineHeight: "1.15",
			paddingTop: "0",
			transformOrigin: "top left",
		},
		withoutContainer: {},
		button: {
			position: "absolute",
			fontFamily: theme.fonts.bold,
			fontSize: "16px",
			lineHeight: 0.8,
			letterSpacing: "2px",
			color: theme.palette.colors.purplishBlue,
			top: "50%",
			transform: "translateY(-50%)",
			right: "26px",
			cursor: "pointer",
			zIndex: "100",
			textTransform: "uppercase",
		},
		smallButton: {
			fontSize: "14px",
		},
		smWrapper: {
			boxShadow: "0 0 10px rgba(0,0,0,0.1)",
			borderRadius: "5px",
		},
		xsWrapper: {},
		layoutAStreet: {
			width: "440px",
			marginRight: "20px",
		},
		layoutAStreetV2: {
			width: "440px",
		},
		layoutAHouse: {
			width: "180px",
		},
		layoutAHouseV2: {
			width: "180px",
			marginRight: "20px",
		},
		layoutAHouseSwitcherWrapper: {
			display: "flex",
			alignItems: "center",
			marginRight: "20px",
		},
		layoutASwitcher: {
			width: "220px",
			marginRight: "20px",
		},
		smWrapperV2: {
			width: "220px",
			boxShadow: "0 0 10px rgba(0,0,0,0.1)",
			borderRadius: "5px",
		},
		layoutASButton: {
			width: "280px",
		},
		layoutASButtonV2: {
			width: "280px",
		},
		layoutAStreetHouseWrapper: {
			position: "relative",
			display: "flex",
			marginRight: "20px",
		},
		layoutB: {
			display: "flex",
			alignItems: "center",
			position: "relative",
		},
		layoutBStreet: {
			width: "66%",
			marginRight: "10px",
			flexShrink: 0,
		},
		layoutBStreetV2: {
			flexShrink: 0,
			width: "100%",
			marginRight: 0,
		},
		layoutBHouse: {
			width: "50%",
		},
		layoutBButton: {
			width: "100%",
			marginTop: "16px",
		},
		layoutBSwitcher: {
			marginTop: "16px",
		},
		layoutBSwitcherV2: {
			width: "100%",
			marginLeft: "16px",
		},
		layoutBContainer: {
			display: "flex",
			alignItems: "center",
			marginTop: "16px",
		},
		layoutAStreetHouseWrapperV2: {},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			layoutAStreet: {
				width: "300px",
				marginRight: "20px",
			},
			layoutAStreetV2: {
				width: "300px",
			},
			layoutAHouse: {
				width: "140px",
			},
			layoutAHouseV2: {
				width: "140px",
				marginRight: "20px",
			},
			layoutASwitcher: {
				width: "220px",
				marginRight: "20px",
			},
			smWrapperV2: {
				width: "220px",
			},
			layoutASButton: {
				width: "220px",
			},
			layoutASButtonV2: {
				width: "220px",
			},
			layoutAStreetHouseWrapper: {
				position: "relative",
				display: "flex",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: 1199px)`]: {
			button: {
				fontSize: "16px",
			},
			smWrapper: {
				marginTop: "10px",
			},
			layoutAStreet: {
				width: "290px",
				marginRight: "10px",
			},
			layoutAStreetV2: {
				width: "100%",
				marginRight: 0,
			},
			layoutAHouse: {
				width: "290px",
			},
			layoutAHouseV2: {
				width: "calc(50% - 5px)",
				marginRight: "10px",
			},
			layoutASwitcher: {
				width: "290px",
				marginRight: "10px",
			},
			smWrapperV2: {
				width: "calc(50% - 5px)",
				marginRight: 0,
			},
			layoutASButton: {
				width: "290px",
			},
			layoutASButtonV2: {
				width: "100%",
			},
			layoutAStreetHouseWrapper: {
				position: "relative",
				display: "flex",
				marginRight: "0",
			},
			layoutAHouseSwitcherWrapper: {
				width: "100%",
				marginTop: "10px",
				marginBottom: "10px",
				marginRight: "0",
			},
			layoutAStreetHouseWrapperV2: {
				width: "100%",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				margin: 0,
			},
			button: {
				fontSize: "14px",
				right: "10px",
				padding: "10px",
				"& span": {
					fontSize: "20px",
				},
			},
			withoutContainer: {
				margin: 0,
			},
			xsWrapper: {
				marginRight: "10px",
			},
			smWrapper: {
				marginTop: "10px",
			},
			smWrapperV2: {
				width: "100%",
			},
			layoutAStreet: {
				width: "calc(66% - 10px)",
				marginRight: "10px",
			},
			layoutAStreetV2: {
				width: "100%",
				marginRight: 0,
			},
			layoutAHouse: {
				width: "34%",
			},
			layoutAHouseV2: {
				marginRight: "10px",
			},
			layoutASwitcher: {
				width: "100%",
				marginRight: "0",
			},
			layoutASButton: {
				width: "100%",
			},
			layoutASButtonV2: {
				width: "100%",
			},
			layoutAStreetHouseWrapper: {
				position: "relative",
				display: "flex",
				width: "100%",
				marginRight: "0",
			},
			layoutAHouseSwitcherWrapper: {
				width: "100%",
				marginTop: "10px",
				marginBottom: "10px",
				marginRight: "0",
			},
		},
	};
};

class SearchAddress extends React.Component {
	static defaultProps = {
		placeholder: null,
	};
	constructor(props) {
		super(props);
	}
	clickInputHandler(type) {
		if (type === "street") {
			if (this.props.redirectToSelectRegion) {
				const regionSelectUrl = generateUrl(routes.SelectRegion.route, {});
				sendMetriks("CLICK_POISK_GLAVNAYA_BEZ_REGIONA");
				window.location.replace(regionSelectUrl);
			} else {
				sendMetriks("CLICK_FIELD_STREET");
			}
		} else if (type === "house") {
			sendMetriks("CLICK_FIELD_HOUSE");
		}

		this.props.clickInputHandler && this.props.clickInputHandler();
	}

	renderStreetDropdown() {
		let text = this.props.text;
		let defaultPlaceholderStreet = !!this.props.placeholder
			? this.props.placeholder
			: text.ENTER_YOUR_STREET;
		return (
			<Dropdown
				clickInputHandler={() => this.clickInputHandler("street")}
				isFixedList={this.props.isFixedList}
				error={this.props.error && !this.props.currentStreet.id}
				errorCounter={this.props.errorCounter}
				items={this.props.itemsStreets}
				showList={this.props.showList && this.props.popupBView == false}
				onItemSelect={(item) => {
					this.props.onItemSelect("street", item);
				}}
				onInput={(value) => {
					this.props.onInputHandler("street", value);
				}}
				// autoExpansion={true}
				id={this.props.streetInputId}
				autoExpansion={this.props.autoExpansion}
				value={this.props.streetValue}
				placeholder={defaultPlaceholderStreet}
				// disabled={!this.props.popupBVie}
				inModal={this.props.modal}
				// tender={this.props.tender}
				datatest="main_input_street_home_new"
				withBigButton={!this.props.withoutButton}
				currentAddress={this.props.currentAddress}
				smallButton={this.props.smallButton}
				onDropdownOpenCallback={() => this.props.onDropdownOpenCallback("street")}
				onDropdownCloseCallback={this.props.onDropdownCloseCallback}
				withoutButton={true}
				withBorder={this.props.withBorder}
				withoutBorder={this.props.withoutBorder}
				withoutShadow={this.props.withoutShadow}
				direction={this.props.direction}
				onEnter={this.props.focusOnDropdownHouse}
				whiteTheme={this.props.whiteTheme}
				text={text}
				loading={this.props.loading}
				listContainerFunc={this.props.listContainerFunc}
				windowSizes={this.props.windowSizes}
				minLenValue={3}
				correctAddress={!!this.props.currentStreet.id}
				size={(this.props.layoutB || this.props.layoutBV2) && "medium"}
				isStreetDropdownToTC={true}
				whiteInput={true}
				ariaLabel={defaultPlaceholderStreet}
			/>
		);
	}

	renderHouseDropdown() {
		let text = this.props.text;
		return (
			<Dropdown
				size={(this.props.layoutB || this.props.layoutBV2) && "medium"}
				clickInputHandler={() => this.clickInputHandler("house")}
				isFixedList={this.props.isFixedList}
				error={this.props.errorHouse && !this.props.currentAddress.id}
				errorCounter={this.props.errorCounter}
				items={this.props.itemsAddresses}
				showList={
					this.props.showList &&
					this.props.popupBView == false &&
					this.props.itemsAddresses.length > 0
				}
				onItemSelect={(item) => {
					this.props.onItemSelect("address", item);
				}}
				onInput={(value) => {
					this.props.onInputHandler("address", value);
				}}
				id={this.props.houseInputId}
				value={this.props.addressValue}
				placeholder={text.ENTER_YOUR_HOUSE}
				disabled={!this.props.currentStreet.id}
				inModal={this.props.modal}
				// tender={this.props.tender}
				datatest="main_input_street_home_new"
				withBigButton={!this.props.withoutButton}
				currentAddress={this.props.currentAddress}
				smallButton={this.props.smallButton}
				onDropdownOpenCallback={() => this.props.onDropdownOpenCallback("house")}
				onDropdownCloseCallback={this.props.onDropdownCloseCallback}
				withoutButton={true}
				withBorder={this.props.withBorder}
				withoutBorder={this.props.withoutBorder}
				withoutShadow={this.props.withoutShadow}
				direction={this.props.direction}
				onEnter={this.props.onEnter}
				whiteTheme={this.props.whiteTheme}
				text={text}
				loading={this.props.loading}
				listContainerFunc={this.props.listContainerFunc}
				windowSizes={this.props.windowSizes}
				withoutClear={true}
				minLenValue={1}
				correctAddress={!!this.props.currentAddress.id}
				setRef={this.props.setRef}
			/>
		);
	}

	componentDidMount() {}

	renderSelectRouteSwitcher() {
		let text = this.props.text;
		let classes = this.props.classes;
		return (
			<div>
				<SelectField
					id={v4()}
					multiselect={false}
					showList={true}
					windowSizes={this.props.windowSizes}
					transparentType={true}
					items={this.props.dropdownSelectorVariants}
					inHeroBannerView={true}
					onlyInputDisabled={true}
					error={this.props.errorTypeConnection}
					errorCounter={this.props.errorCounter}
					onItemSelect={(items) => this.props.changeSelectRoute(items[0])}
					text={text}
					whiteTheme={this.props.whiteTheme}
					withoutClose={true}
					placeholder={
						this.props.apartment || this.props.tender != true ? this.props.selectPlaceholder : ""
					}
					activePlaceHolder={
						this.props.apartment || this.props.tender != true ? this.props.selectPlaceholder : ""
					}
					onMount={(e) => this.props.onMountSelFil(e)}
					findItems={
						(this.props.apartment || this.props.tender) && [
							{
								...this.props.dropdownSelectorVariants[this.props.defaultVariantDropdown],
							},
						]
					}
				/>
			</div>
		);
	}

	renderSelectRouteSwitcherB() {
		let text = this.props.text;
		let classes = this.props.classes;
		return (
			<div>
				<SelectField
					id={v4()}
					multiselect={false}
					windowSizes={this.props.windowSizes}
					items={this.props.dropdownSelectorVariants}
					onlyInputDisabled={true}
					// inHeroBannerView={true}
					onItemSelect={(items) => this.props.changeSelectRoute(items[0])}
					text={text}
					whiteTheme={this.props.whiteTheme}
					withoutClose={true}
					isFixedList={true}
					placeholder={
						this.props.apartment || this.props.tender != true ? this.props.selectPlaceholder : ""
					}
					activePlaceHolder={
						this.props.apartment || this.props.tender != true ? this.props.selectPlaceholder : ""
					}
					onMount={(e) => this.props.onMountSelFil(e)}
					findItems={
						(this.props.apartment || this.props.tender) && [
							{
								...this.props.dropdownSelectorVariants[this.props.defaultVariantDropdown],
							},
						]
					}
				/>
				{/* {this.props.errorTypeConnection && this.props.currentStreet.id && <div className={classes.typeConnectionError}>Выберете тип подключения</div>} */}
			</div>
		);
	}

	renderButton() {
		let text = this.props.text;
		let classes = this.props.classes;
		return (
			<Button
				onClick={this.props.onClickButton}
				// disabled={!this.props.addressValue}
				fullWidth={true}
				noPaddings={true}
				size="big"
				datatest={this.props.datatestButton || "find_tohome_button"}
				typeText={this.props.windowSizes.md ? "always16" : "verybig"}
				status="purple"
			>
				{!this.props.windowSizes.xs
					? !!this.props.buttonName
						? this.props.buttonName
						: text.heroShowTariffs
					: !!this.props.shortButtonName
						? this.props.shortButtonName
						: text.FIND}
			</Button>
		);
	}

	renderSmallButton() {
		let text = this.props.text;
		let classes = this.props.classes;
		if (!this.props.whiteTheme) {
			return (
				<Button
					onClick={this.props.onClickButton}
					// disabled={!this.props.addressValue}
					fullWidth={true}
					noPaddings={true}
					datatest={this.props.datatestButton || "find_tohome_button"}
					size="middle"
					typeText={"middle"}
					status="purple"
				>
					{!this.props.windowSizes.xs ? text.heroShowTariffs : text.FIND}
				</Button>
			);
		}

		return (
			<Button
				onClick={this.props.onClickButton}
				fullWidth={true}
				noPaddings={true}
				datatest={this.props.datatestButton || "find_tohome_button"}
				size="middle"
				typeText={"middle"}
				status={!this.props.addressValue ? "info" : "tertiaryAccent"}
			>
				{!this.props.windowSizes.xs ? text.heroShowTariffs : text.FIND}
			</Button>
		);
	}

	renderForm() {
		let text = this.props.text;
		let classes = this.props.classes;
		let defaultPlaceholderStreet = !!this.props.placeholder
			? this.props.placeholder
			: text.ENTER_YOUR_STREET;
		return (
			<>
				<div className={classes.layoutAStreetHouseWrapper}>
					<div className={classes.layoutAStreet}>{this.renderStreetDropdown()}</div>
					<div className={classes.layoutAHouse}>{this.renderHouseDropdown()}</div>
				</div>
				<div className={classes.layoutASwitcher}>
					<div className={classes.smWrapper}>{this.renderSelectRouteSwitcher()}</div>
				</div>
				<div
					className={classes.layoutASButton}
					ref={(button) => {
						!!this.props.refButton && this.props.refButton(button);
					}}
				>
					<div className={classes.smWrapper}>{this.renderButton()}</div>
				</div>
			</>
		);
	}

	renderFormV2() {
		let text = this.props.text;
		let classes = this.props.classes;
		let defaultPlaceholderStreet = !!this.props.placeholder
			? this.props.placeholder
			: text.ENTER_YOUR_STREET;
		return (
			<>
				<div
					className={classNames(
						classes.layoutAStreetHouseWrapper,
						classes.layoutAStreetHouseWrapperV2
					)}
				>
					<div className={classes.layoutAStreetV2}>{this.renderStreetDropdown()}</div>
				</div>
				<div className={classes.layoutAHouseSwitcherWrapper}>
					<div className={classes.layoutAHouseV2}>{this.renderHouseDropdown()}</div>
					<div className={classes.smWrapperV2}>{this.renderSelectRouteSwitcher()}</div>
				</div>
				<div
					className={classes.layoutASButtonV2}
					ref={(button) => {
						!!this.props.refButton && this.props.refButton(button);
					}}
				>
					<div className={classes.smWrapper}>{this.renderButton()}</div>
				</div>
			</>
		);
	}
	renderLayoutB() {
		let text = this.props.text;
		let classes = this.props.classes;
		let defaultPlaceholderStreet = !!this.props.placeholder
			? this.props.placeholder
			: text.ENTER_YOUR_STREET;
		return (
			<>
				<div className={classes.layoutB}>
					<div className={classes.layoutBStreet}>{this.renderStreetDropdown()}</div>
					<div className={classes.layoutBHouse}>{this.renderHouseDropdown()}</div>
				</div>

				{!this.props.withoutSwitcher && (
					<div className={classes.layoutBSwitcher}>{this.renderSelectRouteSwitcherB()}</div>
				)}

				{!this.props.withoutButton && (
					<div className={classes.layoutBButton}>{this.renderSmallButton()}</div>
				)}
			</>
		);
	}

	renderLayoutBV2() {
		let text = this.props.text;
		let classes = this.props.classes;
		let defaultPlaceholderStreet = !!this.props.placeholder
			? this.props.placeholder
			: text.ENTER_YOUR_STREET;
		return (
			<>
				<div className={classes.layoutB}>
					<div className={classes.layoutBStreetV2}>{this.renderStreetDropdown()}</div>
				</div>
				<div className={classes.layoutBContainer}>
					<div className={classes.layoutBHouse}>{this.renderHouseDropdown()}</div>
					{!this.props.withoutSwitcher && (
						<div className={classes.layoutBSwitcherV2}>{this.renderSelectRouteSwitcherB()}</div>
					)}
				</div>
				{!this.props.withoutButton && (
					<div className={classes.layoutBButton}>{this.renderSmallButton()}</div>
				)}
			</>
		);
	}

	render() {
		let classes = this.props.classes;
		let text = this.props.text;
		let defaultPlaceholder = !!this.props.placeholder
			? this.props.placeholder
			: text.ENTER_YOUR_ADDRESS;
		if (this.props.layoutB) {
			return <div className={classes.root}>{this.renderLayoutB()}</div>;
		} else if (this.props.layoutBV2) {
			return <div className={classes.root}>{this.renderLayoutBV2()}</div>;
		} else if (this.props.renderFormV2) {
			return (
				<div className={classes.root}>
					<Row
						className={classNames({
							"justify-content-center": !this.props.notJustifyCenter,
						})}
					>
						{this.renderFormV2()}
					</Row>
				</div>
			);
		}
		return (
			<div className={classes.root}>
				<Row
					className={classNames({
						"justify-content-center": !this.props.notJustifyCenter,
					})}
				>
					{this.renderForm()}
				</Row>
			</div>
		);
	}
}
export default withStyles(styles)(SearchAddress);
