import React from "react";
import withStyles from "react-jss";
import { PurplePopup } from "./../index";
import { SVGHint } from "Constants/SVG";

const styles = (theme) => ({
	exclusive: {
		position: "absolute",
		left: "0px",
		top: "0px",
	},
	svgImage: {},
	[`@media (max-width: ${theme.media.sm})`]: {
		svgImage: {
			borderTopLeftRadius: "10px",
		},
	},
});

const ElitePopup = ({ classes, path, insideText }) => {
	return (
		<div className={classes.exclusive}>
			<svg
				className={classes.svgImage}
				width={"36"}
				height={"30"}
				viewBox={"0 0 36 30"}
				fill={"none"}
				xmlns={"http://www.w3.org/2000/svg"}
			>
				<path d={SVGHint[0]} fill={"#F88E34"}></path>
				<path d={SVGHint[1]} fill={"white"}></path>
			</svg>
		</div>
	);
};

export default withStyles(styles)(ElitePopup);
