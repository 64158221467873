export const ACTION_ZAYAVKA_KVARTIRA_OK = "zayavka_kvartira_ok";
export const ACTION_OTZIV_OK = "otziv_ok";

export const EVENT_ACTION_ACTION_CLICK_SUBSCRIBE = "click_subscribe";
export const EVENT_ACTION_CLICK_CALLBACK = "click_callback";
export const EVENT_ACTION_CLICK_WAIT_CALL = "click_wait_call";
export const EVENT_ACTION_CLICK_SEND_ORDER_WITH_TARIFF = "click_send_order_with_tariff";
export const EVENT_ACTION_CLICK_SEND_ORDER_WITHOUT_TARIFF = "click_send_order_without_tariff";
export const EVENT_ACTION_CLICK_SEND_ORDER_OFFICE = "click_send_order_office";

export const EVENT_ACTION_CLICK_BANNER = "click_banner";
export const EVENT_ACTION_EMAIL_WARRANTY = "click_ewarranty";
export const EVENT_ACTION_CLICK_QUIZ_CALLBACK = "click_quiz";
export const EVENT_ACTION_CLICK_FLAT_BUTTON = "click_main_flat_button";
export const EVENT_ACTION_CLICK_OFFICE_BUTTON = "click_main_office_button";
export const EVENT_ACTION_CLICK_FIND_TARIFFS_HEADER = "click_header";
export const EVENT_ACTION_CLICK_MAIN_BUTTON_QUIZ = "click_main_button_quiz";
export const EVENT_ACTION_CONNECT_PROVIDER = "click_providers_form_inspect_connect_tariff_button";
export const EVENT_ACTION_CLICK_QUIZ_DROPDOWN = "click_main_input_street_home_quiz";
export const EVENT_ACTION_CLICK_BUTTON_MAIN_BUTTON_GIFT = "click_main_button_gift";
export const EVENT_ACTION_CLICK_BUTTON_SEND_REVIEW = "click_feedback_button";
export const EVENT_ACTION_CLICK_BUTTON_SUBSCR_MAIN = "click_main_input_email";
export const EVENT_ACTION_CLICK_BUTTON_ADDRESS_HEADER = "click_button_upper_compare_tariffs";
export const EVENT_ACTION_CLICK_COMPASSION_PHONE_BUTTON_REAL = "click_compassion_phone_button_real";
export const EVENT_ACTION_CLICK_CONNECT_TARIF = "click_connect_tarif";

//SendOrder popups
export const EVENT_ACTION_CLICK_TARIFF_ORDER_SEND_PHONE = "click_pop_up_input_tel";
export const EVENT_ACTION_CLICK_TARIFF_ORDER_SEND_EMAIL = "click_pop_up_input_email";
export const EVENT_ACTION_CLICK_TARIFF_ORDER_FINISH = "click_registration_button_register";
export const EVENT_ACTION_CLICK_TARIFF_ORDER_CLOSE = "click_pop_up_close";
export const EVENT_ACTION_CLICK_SEND_MOBILE_ORDER = "click_send_mobile_order";

//rates from quiz popups
export const EVENT_ACTION_RATES_FROM_QUIZ_POPUP1_SEND_PHONE =
	"click_rates_popup1_from_quiz_send_phone";
export const EVENT_ACTION_RATES_FROM_QUIZ_POPUP2_SEND_PHONE =
	"click_rates_popup2_from_quiz_send_phone";
export const EVENT_ACTION_RATES_FROM_QUIZ_POPUP3_SEND_PHONE = "popup_sucsess_adress_input_tel";
export const EVENT_ACTION_RATES_FROM_QUIZ_POPUP2_WITHOUT_SALES =
	"click_rates_popup2_from_quiz_without_sale";

//quizv2
export const EVENT_ACTION_QUIZ_V2_CLICK1 = "click_quiz_v2_step1_next";
export const EVENT_ACTION_QUIZ_V2_HOME_CLICK2 = "click_quiz_v2_branchhome_step2_next";
export const EVENT_ACTION_QUIZ_V2_HOME_CLICK3 = "click_quiz_v2_branchhome_step3_next";
export const EVENT_ACTION_QUIZ_V2_HOME_CLICK4 = "click_quiz_v2_branchhome_step4_next";
export const EVENT_ACTION_QUIZ_V2_HOME_CLICK5 = "click_quiz_v2_branchhome_step5_next";
export const EVENT_ACTION_QUIZ_V2_OFFICE_CLICK2 = "click_quiz_v2_branchoffice_step2_next";
export const EVENT_ACTION_QUIZ_V2_OFFICE_CLICK3 = "click_quiz_v2_branchoffice_step3_next";
export const EVENT_ACTION_QUIZ_V2_OFFICE_CLICK4 = "click_quiz_v2_branchoffice_step4_next";
export const EVENT_ACTION_QUIZ_V2_OFFICE_CLICK5 = "click_quiz_v2_branchoffice_step5_next";
export const EVENT_ACTION_QUIZ_V2_OFFICE_CLICK6 = "click_quiz_v2_branchoffice_step6_next";
export const EVENT_ACTION_QUIZ_V2_OFFICE_CLICK7 = "click_quiz_v2_branchoffice_step7_next";
export const EVENT_ACTION_QUIZ_V2_OFFICE_CLICK8 = "click_quiz_v2_branchoffice_step8_next";

export const EVENT_ACTION_CLICK_LOGIN = "click_main_button_enter";
export const EVENT_ACTION_CLICK_REGISTRATION = "click_registration_button_register";
export const EVENT_ACTION_CLICK_LOGIN_FORM = "click_main_button_signin";
export const EVENT_ACTION_CLICK_HAVE_ACCOUNT_YET = "click_main_button_enter_account";
export const EVENT_ACTION_CLICK_AUTH_BUTTON_SOCIAL = "click_autorization_button_";

export const EVENT_ACTION_CLICK_COMPASSION_BUTTON_FAKE = "click_compassion_phone_button_fake";
export const EVENT_ACTION_CLICK_TARIFF_PHONE_BUTTON_FAKE = "click_tariff_phone_button_fake";

export const EVENT_ACTION_CLICK_POPUP_WRONG_HOUSE = "click_popup_wrong_house";
export const EVENT_ACTION_CLICK_FIND_TOHOME_BUTTON = "click_find_tohome_button";
export const EVENT_ACTION_CLICK_FIND_OFFICE_BUTTON = "click_find_office_button";
export const EVENT_ACTION_CLICK_FIND_DACHA_BUTTON = "click_find_dacha_button";
export const EVENT_ACTION_CLICK_FEEDBACK_AFTER_ORDER = "click_feedback_after_order";
export const EVENT_ACTION_CLICK_EXIT_POPUP = "click_exit_popup";
export const EVENT_ACTION_CLICK_TAB_SITY = "click_tab_sity";
export const EVENT_ACTION_CLICK_FIELD_STREET = "click_field_street";
export const EVENT_ACTION_CLICK_SELECT_STREET = "click_select_street";
export const EVENT_ACTION_CLICK_SELECT_HOUSE = "click_select_house";
export const EVENT_ACTION_CLICK_READ_FEEDBACK = "click_read_feedback";
export const EVENT_ACTION_CLICK_WRONG_HOUSE = "click_popup_wrong_house_load";
export const EVENT_ACTION_CLICK_FULLED_ADDRESS_FORM_STREET = "click_search_form_pre_entered_street";
export const EVENT_ACTION_CLICK_FULLED_ADDRESS_FORM_HOME = "click_search_form_pre_entered_home";

export const EVENT_ACTION_CLICK_EXPAND_DODYS = "click_open_dudo";

export const EVENT_CLICK_COVERAGE_PROVIDER = "check_coveradge";

export const EVENT_SUCCESS_SEARCH_COVERAGE_PROVIDER = "find_tohome_button_popup";

export const EVENT_CLICK_PHONE_MAIN = "click_phone_button_main";

export const EVENT_CALLBACK_PROVIDER_PAGE = "callback_prov_page";

export const EVENT_CLOSE_POPUP1_FROM_QUIZ_INPUT_TEL = "close_popup1_from_quiz_input_tel";
export const EVENT_CLICK_BUTTON_CONTEST_VK = "click_popup_vk";
export const CLICK_LOAD_POPUP_MOBILE_NEW_VISITOR = "click_load_popup_mobile_new_visitor";
export const CLICK_CALL_POPUP_MOBILE_NEW_VISITOR = "click_call_popup_mobile_new_visitor";
export const CLICK_POPUP_MOBILE_NEW_VISITOR = "click_popup_mobile_new_visitor";
export const CLICK_LOAD_POPUP_TC = "click_load_popup_tc";
export const CLICK_CALL_POPUP_TC = "click_call_popup_tc";
export const CLICK_POPUP_TC = "click_popup_tc";
export const CLICK_REDIRECT_LANDING = "click_redirect_landing";
export const CLICK_CALLBACK = "click_callback";
export const CLICK_CALLBACK_SAT = "click_callback_sat";
export const CLICK_LOAD_CALLBACK = "click_load_callback";
