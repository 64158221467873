import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	root: {},
	table: {
		borderRadius: "10px",
		width: "100%",
	},
	thead: {},
	[`@media (max-width: ${theme.media.sm})`]: {
		thead: {
			display: "none",
		},
	},
}));

const Component = ({ datatest, tableHeader, children }) => {
	const classes = useStyles();
	return (
		<div className={classes.table} datatest={datatest}>
			<div className={classes.thead}>{tableHeader}</div>
			<div>{children}</div>
		</div>
	);
};

export default Component;
