import React from "react";
import Container from "reactstrap/lib/Container";
import { PaddingBottom } from "ComponentsUI/Spacings";

const ContainerPaddingBottom = (props) => {
	return (
		<Container>
			<PaddingBottom>{props.children}</PaddingBottom>
		</Container>
	);
};

export default ContainerPaddingBottom;
