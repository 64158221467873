import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import HeroBannerContainer from "Containers/HeroBannerContainer";
import Container from "reactstrap/lib/Container";
import { generateUrl } from "Services/withDefaultParams";
import { sendMetriks } from "Services/sendMetriks";
import configRouters from "Configs/routes";
import { isMainSiteCode, getCompanyNameByCode, getCompanyInfoByCode } from "Utils/siteConfig";
import { CDN_URL } from "Constants/CDN";
import SeoBlockHOC from "HOC/SeoBlockHOC";
import WithSeoText from "Layouts/WithSeoText";
import { SeoBlockContainer } from "Containers/SeoBlock";
import { AddressAdverBlockContainer } from "Containers/AdBlocksContainer";
import Districts from "Containers/DistrictsContainer";
import DeclinationContainerNew from "Containers/DeclinationContainerNew";
import TopProviderContainer from "Containers/TopProviderContainer";
import OneClick from "Containers/OneClick";
import YandexWidgetReviews from "Containers/YandexWidgetReviews";
import TariffsContainer from "./containers/Tariffs";
import ReviewsContainer from "./containers/Reviews";

@withRouter
@inject("ApplicationModel")
@observer
export default class Mainpage extends React.Component {
	canonical() {
		const {
			ApplicationModel: { siteConfig },
		} = this.props;

		const location = generateUrl(configRouters.Default.route, this.props.match.params);

		return (
			<Helmet>
				<link
					rel="canonical"
					href={`${siteConfig.ssl === true ? `https` : "http"}://${
						siteConfig.domain_name_full
					}${location}`}
				/>
			</Helmet>
		);
	}

	microMarkupOpenGraf() {
		const {
			ApplicationModel: { siteConfig, siteCode },
		} = this.props;
		return (
			<div style={{ display: "none" }}>
				<div itemScope itemType="http://schema.org/Organization">
					<img itemProp="logo" src={`${CDN_URL}/images/logo_101.png`} alt="Логотип организации" />
					<span itemProp="name">{getCompanyNameByCode(siteCode)}</span>
					<div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
						<span itemProp="streetAddress">{getCompanyInfoByCode(siteCode)?.address}</span>
						<span itemProp="postalCode">{getCompanyInfoByCode(siteCode)?.postalCode}</span>
						<span itemProp="addressLocality">{getCompanyInfoByCode(siteCode)?.region}</span>,
					</div>
					<span itemProp="telephone">
						<a href={`tel:${siteConfig?.contacts?.contact_phone?.replace(/[\s-()]/g, "")}`}>
							{siteConfig?.contacts?.contact_phone?.replace(/[\s-()]/g, "")}
						</a>
					</span>
					<span itemProp="email">{getCompanyInfoByCode(siteCode)?.email}</span>
				</div>
				<div itemScope itemType="http://schema.org/OpeningHoursSpecification">
					<meta itemProp="opens" content="00:00" />
					<meta itemrop="closes" content="23:59" />
				</div>
			</div>
		);
	}

	componentDidMount() {
		sendMetriks("OPEN_MAIN");
	}

	render() {
		const {
			ApplicationModel: { text, currentRegion },
			route,
		} = this.props;
		const { seoData } = this.props;
		const siteCode = this.props.ApplicationModel.siteCode;
		let newViewSearch = this.props.ApplicationModel?.siteConfig?.settings?.show_new_view_search;

		return (
			<>
				{this.canonical()}
				{this.microMarkupOpenGraf()}
				{isMainSiteCode(siteCode) && <div></div>}
				<HeroBannerContainer h1Banner={seoData?.h1} h1After={seoData?.h1After} />
				<WithSeoText seoData={seoData} text={text}>
					<SeoBlockHOC route={route} placeText="Tariffs">
						<TariffsContainer isMainPage={true} />
					</SeoBlockHOC>

					<Container>
						<SeoBlockContainer route={route} placeText="SeoBlock1" bgText={true} />
					</Container>

					{currentRegion && (
						<Container>
							<OneClick />
						</Container>
					)}

					<SeoBlockHOC route={route} placeText="TopProvider">
						<TopProviderContainer />
					</SeoBlockHOC>

					<Container>
						<SeoBlockContainer route={route} placeText="SeoBlock2" bgText={true} />
					</Container>

					<SeoBlockHOC route={route} placeText="Reviews">
						<ReviewsContainer />
					</SeoBlockHOC>

					<AddressAdverBlockContainer autoExpansion={!newViewSearch} />

					<SeoBlockHOC route={route} placeText="YandexWidget">
						<YandexWidgetReviews />
					</SeoBlockHOC>

					<Container>
						<SeoBlockHOC route={route} placeText="Districts">
							<DeclinationContainerNew>
								<Districts />
							</DeclinationContainerNew>
						</SeoBlockHOC>
					</Container>
				</WithSeoText>
			</>
		);
	}
}
