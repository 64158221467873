import React, { useMemo } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { pipe } from "Utils/functionalUtils";
import { useSeo } from "hooks/useSeo";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";

const seoBlockContainer = (props) => {
	let { ApplicationModel, route, placeText, bgText, hideTitle, textAlign, testText } = props;
	let seoData = useSeo(route, ApplicationModel);
	const seoBlock = useMemo(() => {
		if (!seoData) {
			return undefined;
		}
		return seoData.seoBlock?.find((elem) => elem.place === placeText);
	}, [seoData]);

	if (!seoBlock) {
		return null;
	}

	return (
		<HeaderTextSeo
			header={seoBlock.seoHeader}
			text={seoBlock.seoText}
			bgText={bgText}
			hideTitle={hideTitle}
			textAlign={textAlign}
		/>
	);
};

seoBlockContainer.propTypes = {
	route: PropTypes.object.isRequired,
	placeText: PropTypes.string,
	defaultBG: PropTypes.bool,
	hideTitle: PropTypes.bool,
	testText: PropTypes.string,
};

export default pipe(observer, inject("ApplicationModel"))(seoBlockContainer);
