import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withDefaultParams from "Services/withDefaultParams";
import { PhoneButton, WaitCallPhoneButton } from "ComponentsUI/Navigation";
import { withPhone } from "HOC/WithPhone";
import { sendMetriks } from "Services/sendMetriks";
import ProviderConnectWithPhoneHOC from "HOC/ProviderConnectWithPhoneHOC";
import { isMobilePage } from "Utils/routeCheck";
import { pipe } from "Utils/functionalUtils";

const PhoneContainerHeader = (props) => {
	const onClickHandler = () => {
		sendMetriks("CLICK_COMPASSION_PHONE_BUTTON_REAL");
	};

	let { siteRegionPhones, phone404 } = props;
	let text = props.ApplicationModel.text;
	let insinePhone = "";
	let numbers = "";
	let code = "";
	let formatPhone = "";
	let windowSizes = props.ApplicationModel?.windowSizeModel?.windowSizes;
	const isDesktop = windowSizes?.md || windowSizes?.lg;

	if (phone404) {
		insinePhone = "+78001008406";
		formatPhone = "+7 (800) 100-84-06";
		return !!isDesktop ? (
			<ProviderConnectWithPhoneHOC header={true}>
				<PhoneButton
					onClickPhone={onClickHandler}
					text={text}
					fixedTheme={props.fixedTheme}
					passiveTheme={props.passiveTheme}
					phone={formatPhone}
					insinePhone={insinePhone}
				/>
			</ProviderConnectWithPhoneHOC>
		) : (
			<PhoneButton
				onClickPhone={onClickHandler}
				text={text}
				fixedTheme={props.fixedTheme}
				passiveTheme={props.passiveTheme}
				phone={formatPhone}
				insinePhone={insinePhone}
			>
				{props.children}
			</PhoneButton>
		);
	}

	if (!props.siteRegionPhones) {
		return null;
	}
	let xs = props.ApplicationModel.windowSizeModel.windowSizes.xs;

	if (isMobilePage(props?.match?.path)) {
		if (
			!props.siteRegionPhones?.operators?.show_on_site ||
			props.siteRegionPhones?.operators?.phone_view === "hide"
		) {
			return null;
		}
		if (!props.siteRegionPhones?.operators?.phone) {
			return null;
		}
	} else {
		if (!props.siteRegionPhones.show_on_site && xs) {
			return (
				<WaitCallPhoneButton
					store={props.ApplicationModel}
					onClickPhone={onClickHandler}
					text={text}
					fixedTheme={props.fixedTheme}
					passiveTheme={props.passiveTheme}
				/>
			);
		}
		if (!props.siteRegionPhones.phone) {
			return null;
		}
	}

	code = siteRegionPhones.code;
	numbers = siteRegionPhones.phone;
	insinePhone = `+7${siteRegionPhones.code}${siteRegionPhones.phone.replace(/\-/g, "")}`;
	formatPhone = `+7 (${code}) ${numbers}`;

	if (isMobilePage(props?.match?.path) && siteRegionPhones?.operators?.phone) {
		code = siteRegionPhones?.operators?.code;
		numbers = siteRegionPhones?.operators?.phone;
		insinePhone = `+7${
			siteRegionPhones?.operators?.code
		}${siteRegionPhones?.operators?.phone.replace(/\-/g, "")}`;
		formatPhone = `+7 (${code}) ${numbers}`;
	}

	return !!isDesktop ? (
		<ProviderConnectWithPhoneHOC header={true}>
			<PhoneButton
				onClickPhone={onClickHandler}
				text={text}
				fixedTheme={props.fixedTheme}
				passiveTheme={props.passiveTheme}
				phone={formatPhone}
				insinePhone={insinePhone}
			/>
		</ProviderConnectWithPhoneHOC>
	) : (
		<PhoneButton
			onClickPhone={onClickHandler}
			text={text}
			fixedTheme={props.fixedTheme}
			passiveTheme={props.passiveTheme}
			phone={formatPhone}
			insinePhone={insinePhone}
		>
			{props.children}
		</PhoneButton>
	);
};

PhoneContainerHeader.defaultProps = {
	onClick: () => {},
};

export default pipe(
	observer,
	inject("ApplicationModel"),
	withPhone,
	withDefaultParams,
	withRouter
)(PhoneContainerHeader);
