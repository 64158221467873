import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import { TextField } from "ComponentsUI/TextField";
import Button from "ComponentsUI/Button";
import TermsOfUse from "ComponentsUI/TermsOfUse";
import { CDN_URL } from "Constants/CDN";

const styles = (theme) => ({
	root: {
		margin: "0 auto",
		backgroundColor: "#5813fe",
		display: "flex",
		objectFit: "contain",
		position: "relative",
		alignItems: "center",
		zIndex: 200000,
	},
	image: {},
	form: {},
	success: {},
	header: {
		fontSize: "32px",
		fontWeight: "bold",
		lineHeight: "36px",
		textAlign: "left",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.bold,
	},
	text: {
		fontSize: "24px",
		lineHeight: "22px",
		textAlign: "left",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.light,
		marginTop: "12px",
		marginBottom: "16px",
		width: "520px",
	},
	text2: {
		fontSize: "18px",
		lineHeight: "36px",
		textAlign: "left",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.medium,
		fontWeight: "bold",
		marginTop: "25px",
		fontStretch: "normal",
		letterSpacing: "normal",
	},
	phone: {
		fontSize: "32px",
		lineHeight: "36px",
		textAlign: "left",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.bold,
		paddingBottom: "35px",
	},
	input: {
		display: "flex",
		marginTop: "30px",
	},
	textfield: {
		width: "360px",
		marginRight: "10px",
	},
	close: {
		position: "absolute",
		width: "60px",
		height: "60px",
		cursor: "pointer",
		right: "18px",
		top: "18px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& span": {
			fontSize: "16px",
			color: theme.palette.colors.white,
		},
		border: "1px solid white",
		borderRadius: "50%",
	},
	btn: {},
	button: {},
	[`@media (min-width: ${theme.media.lg})`]: {
		root: {
			width: "1440px",
			height: "900px",
			background: `linear-gradient(90deg, rgba(88,19,254,1) 35%, rgba(251,251,251,0) 75%),url('${CDN_URL}/stage-static/images/popup/lg.png') top right no-repeat`,
		},
		form: {
			marginLeft: "200px",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			width: "1200px",
			height: "900px",
			background: `linear-gradient(90deg, rgba(88,19,254,1) 25%, rgba(251,251,251,0) 75%),url('${CDN_URL}/stage-static/images/popup/md.png') top right no-repeat`,
		},
		form: {
			marginLeft: "130px",
		},
		text2: {
			marginTop: "10px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			width: "800px",
			height: "900px",
			background: `linear-gradient(90deg, rgba(88,19,254,1) 15%, rgba(251,251,251,0) 65%),url('${CDN_URL}/stage-static/images/popup/sm.png') top right no-repeat`,
		},
		form: {
			marginLeft: "45px",
		},
		header: {
			fontSize: "24px",
			lineHeight: "30px",
		},
		text: {
			fontSize: "16px",
			lineHeight: "18px",
		},
		text2: {
			fontSize: "16px",
			lineHeight: "24px",
			marginTop: "10px",
		},
		textfield: {
			width: "230px",
		},
		phone: {
			fontSize: "24px",
			lineHeight: "36px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			width: "320px",
			minHeight: "520px",
			alignItems: "flex-end",
			background: `linear-gradient(to top, rgba(88,19,254,1) 51%, rgba(251,251,251,0) 90%),url('${CDN_URL}/stage-static/images/popup/xs.png') top no-repeat`,
		},
		input: {
			display: "block",
			marginTop: 0,
		},
		form: {
			padding: "0 20px",
			marginBottom: "40px",
		},
		header: {
			fontSize: "20px",
			lineHeight: "24px",
			textAlign: "center",
			color: theme.palette.colors.white,
			fontFamily: theme.fonts.bold,
		},
		text: {
			width: "100%",
			fontSize: "16px",
			lineHeight: "18px",
			textAlign: "center",
			color: theme.palette.colors.white,
			fontFamily: theme.fonts.light,
			marginTop: "12px",
			marginBottom: "16px",
			fontWeight: "300",
			height: "36px",
			fontStretch: "normal",
			fontStyle: "normal",
		},
		text2: {
			fontSize: "14px",
			lineHeight: "1.14",
			textAlign: "center",
			color: theme.palette.colors.white,
			fontFamily: theme.fonts.medium,
			fontWeight: "bold",
			marginTop: "20px",
			marginBottom: "16px",
			fontStretch: "normal",
			letterSpacing: "normal",
			padding: "0 12px",
		},
		phone: {
			fontSize: "20px",
			lineHeight: "24px",
			textAlign: "center",
			color: theme.palette.colors.white,
			fontFamily: theme.fonts.bold,
			paddingBottom: "35px",
		},
		close: {
			width: "16px",
			height: "16px",
			border: "0",
		},
		textfield: {
			width: "100%",
			"& > div:first-child": {
				marginBottom: "12px",
			},
		},
		button: {
			height: "50px",
			padding: "0 16px",
			letterSpacing: "2.29px",
			backgroundImage: "linear-gradient(to top, #fad961, #f7671c)",
			borderRadius: "10px",
			"& div": {
				border: "0",
				fontSize: "14px",
				fontWeight: "bold",
				fontStretch: "normal",
				fontStyle: "normal",
				lineHeight: "1.14",
				letterSpacing: "2.29px",
				color: "#ffffff",
			},
		},
		btn: {
			background: "white",
		},
		success: {
			alignItems: "center",
			marginBottom: "30px",
		},
		formSuccess: {
			margin: 0,
			padding: "0 20px",
		},
	},
});

class Popup extends React.Component {
	render() {
		let classes = this.props.classes;
		let isSuccess = this.props.success;
		let text = this.props.text;

		let clsName = classNames(classes.header, {
			[classes.success]: this.props.windowSizes.xs && isSuccess,
		});
		return (
			<div className={classes.root}>
				<div className={classes.close} onClick={this.props.closeModal}>
					<span className="icon24 icon-close" />
				</div>
				{!isSuccess ? (
					<div className={classes.form}>
						<div className={classes.header}>Телефона достаточно</div>
						<div className={classes.text}>
							Менеджер перезвонит в&nbsp;течение 5&nbsp;минут и сам оформит заявку
						</div>
						<div className={classes.input}>
							<div className={classes.textfield}>
								<TextField
									autocomplete="tel"
									name="tel"
									onFocus={() => this.props.onFocus()}
									onEnter={() => this.props.onClickHandler()}
									placeholder="+7"
									type={"phone"}
									formatting={"phone"}
									onChange={(e) => this.props.onChangeInputHandler(e)}
									errorText={this.props.errorText}
									value={this.props.inputValue}
									datatest="providers_form_helpchoose_input_tel"
									id="fix_callback_phone"
									onBlur={() => this.props.onBlur()}
									autoFocus
								/>
							</div>
							<div className={classes.button}>
								<Button
									className={classes.btn}
									transparent
									fullWidth={true}
									datatest="provider_services_waitcall_button"
									onClick={() => {
										this.props.onClickHandler();
									}}
								>
									{text.SEND}
								</Button>
							</div>
							<TermsOfUse buttonText={text.SEND} isPopupB={true} />
						</div>
						{/* <div className={classes.text2}>Или позвоните нам прямо сейчас</div>
                        <div className={classes.phone}><PhoneContainerPopup className={classes.phone} /></div> */}
					</div>
				) : (
					<div className={classes.formSuccess}>
						{" "}
						<div className={clsName}>
							<div>{text.ORDER_SUCCESS_0}</div>
							<br />
							<div>
								{text.ORDER_SUCCESS_1}
								<span style={{ whiteSpace: "nowrap" }}>{text.ORDER_SUCCESS_2}</span>
								{text.ORDER_SUCCESS_3}
							</div>
							<br />
							<div>{text.ORDER_SUCCESS_4}</div>
							<div>{text.ORDER_SUCCESS_5}</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(Popup);
