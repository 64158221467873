import React from "react";
import { observer, inject } from "mobx-react";
import withStyles from "react-jss";
import classNames from "classnames";
import { isMoscowSiteCode, isMainSiteCode, isPiterSiteCode } from "Utils/siteConfig";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";
import { ContainerPaddingBottom } from "ComponentsUI/Spacings";

const styles = (theme) => ({
	root: {
		margin: "0px auto 30px auto",
		padding: "0",
		width: "720px",
		height: "450px",
		overflow: "hidden",
		position: "relative",
	},
	widget: {
		width: "100%",
		height: "100%",
	},
	headerWrapper: {
		display: "grid",
		gridAutoFlow: "column",
		justifyContent: "space-between",
		paddingLeft: "40px",
		alignItems: "end",
		gap: "20px",
		gridTemplateColumns: "1fr",
		gridAutoColumns: "auto",
	},
	contacts: {
		paddingLeft: "0px !important",
		marginBottom: "0",
	},
	HeaderTextSeo: {
		marginLeft: "31px",
		marginBottom: "0",
	},
	HeaderTextSeoContacts: {
		marginLeft: "0px !important",
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			marginBottom: "0",
		},
		contacts: {
			paddingLeft: "0px !important",
			marginBottom: "0",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		headerWrapper: {
			paddingLeft: "21px",
		},
		root: {
			maxWidth: "710px",
			marginBottom: "0",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		headerWrapper: {
			gridAutoFlow: "row",
			justifyContent: "unset",
			paddingLeft: "0",
			gap: 0,
		},
		HeaderTextSeo: {
			marginLeft: "0px",
			marginBottom: "0",
		},
		root: {
			width: "auto",
			height: "520px",
		},
		contacts: {
			paddingLeft: "0px !important",
			marginBottom: "0",
		},
	},
});

const YandexWidgetReviews = (props) => {
	const {
		ApplicationModel: {
			siteConfig: {
				code,
				settings: { widget_yandex_reviews },
			},
		},
		seoBlock,
		classes,
		contacts,
	} = props;

	if (!widget_yandex_reviews || isPiterSiteCode(code)) {
		return null;
	}

	const FrameMOL = () => {
		return (
			<>
				<iframe
					style={{
						width: "100%",
						height: "100%",
						border: "1px solid #e6e6e6",
						borderRadius: "8px",
						boxSizing: "border-box",
					}}
					src="https://yandex.ru/maps-reviews-widget/1035796011?comments"
					title="Отзывы о Москва Онлайн на Яндекс Картах"
				></iframe>
				<a
					href="https://yandex.by/maps/org/moskva_on_line/1035796011/"
					target="_blank"
					style={{
						boxSizing: "border-box",
						textDecoration: "none",
						color: "#b3b3b3",
						fontSize: "10px",
						fontFamily: "YS Text,sans-serif",
						padding: "0 20px",
						position: "absolute",
						bottom: "8px",
						width: "100%",
						textAlign: "center",
						left: 0,
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "block",
						maxHeight: "14px",
						whiteSpace: "nowrap",
					}}
					rel="noreferrer"
				>
					Москва On-line на карте Москвы — Яндекс Карты
				</a>
			</>
		);
	};

	const Frame101 = () => {
		return (
			<>
				<iframe
					style={{
						width: "100%",
						height: "100%",
						border: "1px solid #e6e6e6",
						borderRadius: "8px",
						boxSizing: "border-box",
					}}
					src="https://yandex.ru/maps-reviews-widget/118153615879?comments"
					title="Отзывы о 101 интернет на Яндекс Картах"
				></iframe>
				<a
					href="https://yandex.by/maps/org/101_internet/118153615879/"
					target="_blank"
					style={{
						boxSizing: "border-box",
						textDecoration: "none",
						color: "#b3b3b3",
						fontSize: "10px",
						fontFamily: "YS Text,sans-serif",
						padding: "0 20px",
						position: "absolute",
						bottom: "8px",
						width: "100%",
						textAlign: "center",
						left: 0,
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "block",
						maxHeight: "14px",
						whiteSpace: "nowrap",
					}}
					rel="noreferrer"
				>
					Отзывы пользователей о 101 Интернет на Яндекс Картах
				</a>
			</>
		);
	};
	let header = seoBlock?.seoHeader;
	if (!header) {
		if (isMainSiteCode(code)) header = "Отзывы о 101 интернет на Яндекс Картах";
		if (isMoscowSiteCode(code)) header = "Отзывы о Москва Онлайн на Яндекс Картах";
	}

	return (
		<ContainerPaddingBottom>
			<div
				className={classNames(classes.headerWrapper, {
					[classes.contacts]: contacts,
				})}
			>
				<HeaderTextSeo
					className={classNames(classes.HeaderTextSeo, {
						[classes.HeaderTextSeoContacts]: contacts,
					})}
					header={header}
					text={seoBlock?.seoText}
				/>
			</div>
			<div className={classes.root}>
				<div className={classes.widget}>
					{isMainSiteCode(code) && <Frame101 />}
					{isMoscowSiteCode(code) && <FrameMOL />}
				</div>
			</div>
		</ContainerPaddingBottom>
	);
};

export default withStyles(styles)(inject("ApplicationModel")(observer(YandexWidgetReviews)));
