import { gql } from "@apollo/client";

const MUTATE_CLIENT_PORTRAIT = gql`
	mutation (
		$path: String
		$type: String
		$from: String
		$uuid_user: String
		$user_agent: String
		$search_system: String
		$width: Int
		$height: Int
		$ab_tests_json: String
		$region_id: Int
		$region_name: String
		$region_url: String
	) {
		clientPortrait(
			path: $path
			type: $type
			from: $from
			user_info: {
				uuid_user: $uuid_user
				user_agent: $user_agent
				search_system: $search_system
				width: $width
				height: $height
			}
			region: { id: $region_id, name: $region_name, url: $region_url }
			ab_tests_json: $ab_tests_json
		)
	}
`;

export default MUTATE_CLIENT_PORTRAIT;
