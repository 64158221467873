import React from "react";
import { observer, inject } from "mobx-react";

@inject("ApplicationModel")
@observer
export default class AddressPopupHOC extends React.Component {
	openPopup(data) {
		this.closeModal();
		let modalModel = this.props.ApplicationModel.modalModel;
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		let promise1 = import("Layouts/modalLayout");
		let promise2 = import("Containers/AddressPopupContainer");
		let promise3 = import("Containers/AddressPopupContainerV2");

		Promise.all([promise1, promise2, promise3]).then((modules) => {
			let ModalLayout = modules[0].default;
			let AddressPopupContainer = modules[1].default;
			let AddressPopupContainerV2 = modules[2].default;
			let isNewPopup =
				this.props.ApplicationModel?.siteConfig?.settings?.autosearch_address_not_success;
			modalModel.toggleModal(
				<ModalLayout
					authView={true}
					closeModal={() => this.closeModal()}
					withoutClose={true}
					fullCloseElement={true}
					closeOnClickLayout={true}
					onlyDesktopCloseElement={true}
				>
					{isNewPopup ? (
						<AddressPopupContainerV2 providerInfo={data} closeModal={() => this.closeModal()} />
					) : (
						<AddressPopupContainer providerInfo={data} closeModal={() => this.closeModal()} />
					)}
				</ModalLayout>
			);
		});
	}

	componentWillUnmount() {
		this.closeModal();
	}

	closeModal() {
		let modalModel = this.props.ApplicationModel.modalModel;
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		commonViewModel.setShowFakeHousePopup(false);
		if (modalModel.isOpen) {
			modalModel.toggleModal();
		}
	}

	render() {
		let children = React.cloneElement(this.props.children, {
			openPopup: () => this.openPopup(this.props.children.props.providerInfo),
		});
		return children;
	}
}
