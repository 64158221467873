import { observable, action } from "mobx";
import LoaderData from "Services/LoaderData";

export default class NewAuthModel {
	statusUrl = "/auth/status";
	createCodeUrl = "/api/v2/auth/moment-codes";
	loginPhoneUrl = "/auth/local/login-phone";
	loginPasswordUrl = "/auth/local/login-password";
	registrationUrl = "/auth/local/registration";
	approveCodeUrl = "/auth/local/approve-link";
	logoutUrl = "/auth/logout";
	@observable user = null;

	constructor() {}

	checkStatus() {
		return LoaderData.getFromLocal(this.statusUrl).then((data) => {
			return data;
		});
	}

	@action
	logout() {
		return LoaderData.getFromLocal(this.logoutUrl).then((data) => {
			this.user = null;
			return data;
		});
	}

	@action
	loginPassword(login, password) {
		let data = { login, password };
		return LoaderData.postToLocal(this.loginPasswordUrl, data).then((data) => {
			return this.timerForSmooth().then(() => {
				return this.processAnswer(data);
			});
		});
	}

	@action
	registration(data) {
		return LoaderData.postToLocal(this.registrationUrl, data).then((data) => {
			return this.timerForSmooth().then(() => {
				return this.processAnswer(data);
			});
		});
	}

	@action
	loginAsUser() {
		return this.checkStatus().then((data) => {
			if (data.data && data.data.id) {
				this.user = data.data;
			}
			return data;
		});
	}

	@action
	sendCodeToEmail(email) {
		let data = {
			action: "login-link",
			target: email,
		};
		return LoaderData.postToLocal(this.createCodeUrl, data).then((data) => {
			return this.processAnswer(data);
		});
	}

	@action
	activateByEmail(code) {
		return LoaderData.getFromLocal(this.approveCodeUrl + `?momentcode=${code}`).then((data) => {
			return this.processAnswer(data);
		});
	}

	@action
	activateByPhone(phone, code) {
		let data = {};
		data.phone = phone;
		data["moment-code"] = code;
		return LoaderData.postToLocal(this.loginPhoneUrl, data).then((data) => {
			return this.processAnswer(data);
		});
	}

	@action
	sendCodeToPhone(phone) {
		let data = {
			action: "login",
			target: phone,
		};
		return LoaderData.postToLocal(this.createCodeUrl, data).then((data) => {
			return this.processAnswer(data);
		});
	}

	processAnswer(data) {
		let code = data.__STATUS_CODE;
		if (code == 200 || code == 201) {
			return Promise.resolve(data);
		}
		return Promise.reject(data);
	}

	timerForSmooth() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, 2000);
		});
	}
}
