import React from "react";
import { observer, inject } from "mobx-react";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/ru";
import { withRouter } from "react-router-dom";
import { sendMetriks } from "Services/sendMetriks";
import { showExitPopup, isMobilePage } from "Utils/routeCheck";

moment.locale("ru");

@withRouter
@inject("ApplicationModel")
@observer
export default class PopupBHOC extends React.Component {
	static defaultProps = {
		userInSite: new Date().getTime(),
	};

	componentDidMount() {
		// Проверка на то что человек в первый раз, если да, то запустить отслеживание движения мыши
		const isViewPopupExit = Cookies.get("isViewPopupExit");
		const enablePopup = this.props.ApplicationModel?.siteConfig?.settings?.exit_popup?.enabled;
		const windowSizes = this.props.ApplicationModel.windowSizeModel.windowSizes;
		if (
			(isViewPopupExit == undefined || isViewPopupExit === "Testing") &&
			!!enablePopup &&
			!windowSizes.xs &&
			!windowSizes.sm
		) {
			this.addEvent();
		}
	}

	componentWillUnmount() {
		this.removeEvent();
	}

	randomInteger(min, max) {
		// случайное число от min до (max+1)
		let rand = min + Math.random() * (max + 1 - min);
		return Math.floor(rand);
	}

	// Получить количетсво дней. Для удаления куки
	getTimeDelete(days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		return date;
	}

	// Открыть попап
	openPopup() {
		if (
			!showExitPopup(
				this.props?.match?.path,
				this.props?.location,
				this.props?.ApplicationModel?.tariffsModel?.tariff_id,
				this.props.ApplicationModel.searcheModel.house_id
			) ||
			isMobilePage(this.props?.match?.path)
		) {
			return;
		}

		this.removeEvent();
		const timeDelete = this.getTimeDelete(30);
		let isViewPopupExit = Cookies.get("isViewPopupExit");
		if (isViewPopupExit !== "Testing") {
			Cookies.set("isViewPopupExit", "true", { expires: timeDelete });
		}
		const userOutSite = new Date().getTime();
		const userSpendSiteNanoSecond = userOutSite - this.props.userInSite;
		let modalModel = this.props.ApplicationModel.modalModel;
		const catNumber = this.randomInteger(1, 4);
		let promise1 = import("Layouts/modalLayout");
		let promise2 = import("Containers/ExitUserPopapContainer");
		Promise.all([promise1, promise2]).then((modules) => {
			let ModalLayout = modules[0].default;
			let ExitUserPopupContainer = modules[1].default;
			modalModel.toggleModalExit(
				<ModalLayout
					authView={true}
					closeModal={(info) => this.closeModal(info)}
					withoutClose={true}
					fullCloseElement={true}
					closeOnClickLayout={true}
					onlyDesktopCloseElement={true}
					catPopup={true}
				>
					<ExitUserPopupContainer
						userSpendSiteNanoSecond={userSpendSiteNanoSecond}
						closeModal={(info) => this.closeModal(info)}
						catNumber={catNumber}
						closeInfo={{ exitPopup: true, catNumber: catNumber }}
					/>
				</ModalLayout>
			);
		});
	}

	// Закрыть попап
	closeModal(info) {
		let modalModel = this.props.ApplicationModel.modalModel;
		const selectPopup = this.props.ApplicationModel?.siteConfig?.settings?.exit_popup?.enabled;
		if (!!info?.exitPopup && selectPopup == 1) {
			sendMetriks("CLICK_CLOSE_POPUP_EXIT_CAT");
		} else if (!!info?.exitPopup && selectPopup == 2) {
			sendMetriks("CLICK_CLOSE_POPUP_EXIT");
			sendMetriks(`CLICK_CLOSE_POPUP_EXIT${info.catNumber}`);
		}
		if (modalModel.isOpenExit) {
			modalModel.toggleModalExit();
		}
	}

	// Отслеживать движение мыши. Если мышь уходит вверх, то открыть попап
	mouseEvent(e) {
		const userExitSite = !e.toElement && !e.relatedTarget && e.clientY < 10;
		if (userExitSite) {
			this.openPopup();
		}
	}

	mouseEventFunc = (e) => this.mouseEvent(e);

	addEvent() {
		window.addEventListener("mouseout", this.mouseEventFunc);
	}

	removeEvent() {
		window.removeEventListener("mouseout", this.mouseEventFunc);
	}

	render() {
		return <></>;
	}
}
