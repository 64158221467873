import React from "react";
import { Phone } from "ComponentsUI/Phones";
import classNames from "classnames";

export default class PhoneContainerProvider extends React.Component {
	static defaultProps = {
		clickHandler: () => {},
	};

	render() {
		let { phone } = this.props;
		if (!phone) {
			return null;
		}
		if (typeof phone === "number") {
			phone = String(phone);
		}
		let insinePhone = phone;
		if (!!phone) {
			insinePhone = phone.replace(/[-()\s]/g, "");
		}

		return (
			<Phone
				onClick={this.props.clickHandler}
				insinePhone={insinePhone}
				phone={phone}
				className={classNames(this.props.className)}
			/>
		);
	}
}
