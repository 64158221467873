var gql = require("@apollo/client").gql;

const SEARCH_ADDRESS_QUERY_V3 = gql`
	query SEARCH_ADDRESS_QUERY_V3(
		$query_data: SearchQueryData
		$user_info: SearchAnalyticsUserInfo
		$experiments: [Experiment]
	) {
		searchStreetV3(query_data: $query_data, user_info: $user_info, experiments: $experiments) {
			data {
				data {
					street_id
					street
					full
					district
					district_id
					region
					region_url
					region_id
					region_is_capital
				}
				score
				highlight {
					full
				}
			}
		}
	}
`;

export default SEARCH_ADDRESS_QUERY_V3;
