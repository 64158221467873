import React from "react";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			fontFamily: "MuseoSansCyrl-300",
			fontSize: "14px",
			lineHeight: "1.14",
			color: theme.palette.colors.white,
			textTransform: "none",
			letterSpacing: "0px",
			marginTop: "8px",
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				fontSize: "12px",
				lineHeight: "1.17",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				fontSize: "12px",
				lineHeight: "1.17",
			},
		},
	};
};

const TooltipHeader = ({ classes, text }) => {
	return <div className={classes.root}>{text}</div>;
};

export default withStyles(styles)(TooltipHeader);
