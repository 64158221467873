import React from "react";

export default class BottomPoints extends React.Component {
	static defaultProps = {
		getButtonStyles: () => {},
		slideCount: 0,
		slidesToScroll: 0,
		currentSlide: 0,
		goToSlide: () => {},
	};

	render() {
		var self = this;
		var indexes = this.getIndexes(self.props.slideCount, self.props.slidesToScroll);
		return (
			<ul style={self.getListStyles()}>
				{indexes.map(function (index) {
					return (
						<li style={self.getListItemStyles()} key={index}>
							<button
								aria-label={`${index} slide`}
								style={self.props.getButtonStyles(
									self.props.currentSlide === index,
									indexes.length - 1 == index
								)}
								onClick={self.props.goToSlide.bind(null, index)}
							/>
						</li>
					);
				})}
			</ul>
		);
	}

	getIndexes(count, inc) {
		var arr = [];
		for (var i = 0; i < count; i += inc) {
			arr.push(i);
		}
		return arr;
	}

	getListStyles() {
		return {
			position: "relative",
			margin: 0,
			top: -10,
			padding: 0,
		};
	}

	getListItemStyles() {
		return {
			listStyleType: "none",
			display: "inline-block",
		};
	}
}
