import { useQuery } from "@apollo/client";
import { useParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";
import useDefaultParams from "Services/useDefaultParams";
import { SEO_QUERY_V3 } from "Services/queries";

export const useSeo = (route, AppModel) => {
	const { siteConfig, searcheModelOneString, searcheModel, tariffsModel } = AppModel;
	const routeParams = useParams();
	const { regionUrl } = useDefaultParams(routeParams);
	const { search } = useLocation();

	const one_string_search = siteConfig?.settings?.one_string_search?.enabled;
	const selectedSearchModel = one_string_search ? searcheModelOneString : searcheModel;

	let query = queryString.parse(search);
	for (let key in query) {
		if (query[key]) {
			query[key] = query[key].replace(/\s/g, "");
		}
	}

	let house_id;
	if (routeParams.house_id) {
		house_id = +routeParams.house_id;
	} else {
		house_id = selectedSearchModel.house_id;
	}

	if (route.name === "OrderHome") {
		query.tariff_id = tariffsModel.tariff_id;
	} else if ((route.name === "DomaNzl" || route.name === "RatesInHouse") && house_id) {
		query.house_id = house_id;
		// query.districtId = selectedSearchModel.currentAddress.district_id;
		// query.streetId = selectedSearchModel.currentAddress.street_id;
	}
	let params = { ...routeParams, regionUrl, ...query };

	const { data } = useQuery(SEO_QUERY_V3, {
		variables: route.seo(params),
	});

	return data?.seoV3;
};

useSeo.propTypes = {
	route: PropTypes.object.isRequired,
};
