export const CLICK_SWITCH_REGION = "CLICK_SWITCH_REGION";

export const CLICK_SELF = "CLICK_SELF";

export const CLICK_BUSINESS = "CLICK_BUSINESS";

export const CLICK_WAIT_CALL = "CLICK_WAIT_CALL";

export const CLICK_VIEW_TARIFFS = "CLICK_VIEW_TARIFFS";

export const CLICK_CONNECT_TARIFF = "CLICK_CONNECT_TARIFF";

export const CLICK_BUBBLE_RATING_PROVIDERS = "CLICK_BUBBLE_RATING_PROVIDERS";

export const CLICK_BUBBLE_COMPARE_TARIFFS = "CLICK_BUBBLE_COMPARE_TARIFFS";

export const CLICK_MODULE_HOME_ORDER = "CLICK_MODULE_HOME_ORDER";

export const CLICK_MODULE_OUTHOME_ORDER = "CLICK_MODULE_OUTHOME_ORDER";

export const CLICK_MODULE_OFFICE_ORDER = "CLICK_MODULE_OFFICE_ORDER";

export const CLICK_CALLBACK = "click_callback";
export const CALLBACK_PROV_PAGE = "callback_prov_page";

export const CLICK_ALL_RATING_PROVIDERS = "CLICK_ALL_RATING_PROVIDERS";

export const CLICK_ALL_REVIEWS = "CLICK_ALL_REVIEWS";

export const CLICK_ALL_NEWS = "CLICK_ALL_NEWS";

export const CLICK_CONNECT_FROM_PROVIDERS_LIST = "CLICK_CONNECT_FROM_PROVIDERS_LIST";

export const CLICK_CONNECT_FROM_PROVIDER_CARD = "CLICK_CONNECT_FROM_PROVIDER_CARD";

export const CLICK_COMPARE_TARIFF = "CLICK_COMPARE_TARIFF";

export const CLICK_PHONE_FOOTER = "CLICK_PHONE_FOOTER";

export const CLICK_PHONE_HEADER = "CLICK_PHONE_HEADER";

export const CLICK_OPEN_WIDGET = "CLICK_OPEN_WIDGET";

export const CLICK_CLOSE_WIDGET = "CLICK_CLOSE_WIDGET";

export const CLICK_POPUP_REVIEW = "CLICK_POPUP_REVIEW";

export const CLICK_COMPARE = "CLICK_COMPARE";

export const CLICK_PHONE_UNDER_SEARCH = "CLICK_PHONE_UNDER_SEARCH";

export const CLICK_VIEW_ALL_EXCLUSIVE = "CLICK_VIEW_ALL_EXCLUSIVE";

export const CLICK_VIEW_ALL_EXCLUSIVE_MORE_INFO = "CLICK_VIEW_ALL_EXCLUSIVE_MORE_INFO";

export const CLICK_CHANGE_CONSULTANT = "CLICK_CHANGE_CONSULTANT";

export const CLICK_SUBSCRIBE = "CLICK_SUBSCRIBE";

export const CLICK_VIEW_REVIEW = "CLICK_VIEW_REVIEW";

export const CLICK_OPEN_NEWS = "CLICK_OPEN_NEWS";

export const CLICK_REVIEWS_FROM_PROVIDERS_LIST = "CLICK_REVIEWS_FROM_PROVIDERS_LIST";

export const CLICK_SEND_ORDER = "CLICK_SEND_ORDER";
export const CLICK_SEND_ORDER_WITH_TARIFF = "click_send_order_with_tariff";
export const CLICK_SEND_ORDER_WITHOUT_TARIFF = "click_send_order_without_tariff";

export const CLICK_LOGO = "CLICK_LOGO";

export const CLICK_HEADER_MENU_PROVIDERS = "CLICK_HEADER_MENU_PROVIDERS";

export const CLICK_HEADER_MENU_RATING = "CLICK_HEADER_MENU_RATING";

export const CLICK_HEADER_MENU_REVIEWS = "CLICK_HEADER_MENU_REVIEWS";

export const CLICK_HEADER_MENU_COMPARE = "CLICK_HEADER_MENU_COMPARE";

export const CLICK_HEADER_MENU_NEWS = "CLICK_HEADER_MENU_NEWS";

export const CLICK_HEADER_MENU_ACTIONS = "CLICK_HEADER_MENU_ACTIONS";

export const CLICK_FOOTER_MENU_PROVIDERS = "CLICK_FOOTER_MENU_PROVIDERS";

export const CLICK_FOOTER_MENU_REVIEWS = "CLICK_FOOTER_MENU_REVIEWS";

export const CLICK_FOOTER_MENU_COMPARE = "CLICK_FOOTER_MENU_COMPARE";

export const CLICK_FOOTER_MENU_NEWS = "CLICK_FOOTER_MENU_NEWS";

export const CLICK_FOOTER_MENU_ACTIONS = "CLICK_FOOTER_MENU_ACTIONS";

export const CLICK_FOOTER_MENU_TARIFFS_BY_ADDRESS = "CLICK_FOOTER_MENU_TARIFFS_BY_ADDRESS";

export const CLICK_FOOTER_MENU_ABOUT_SELF = "CLICK_FOOTER_MENU_ABOUT_SELF";

export const CLICK_FOOTER_MENU_CONTACTS = "CLICK_FOOTER_MENU_CONTACTS";

export const CLICK_FOOTER_MENU_PARTNERSSHIP = "CLICK_FOOTER_MENU_PARTNERSSHIP";

export const CLICK_FACEBOOK = "CLICK_FACEBOOK";

export const CLICK_GOOGLE = "CLICK_GOOGLE";

export const CLICK_INSTAGRAM = "CLICK_INSTAGRAM";

export const CLICK_TWITTER = "CLICK_TWITTER";

export const CLICK_VK = "CLICK_VK";

export const CLICK_SEND_REVIEW = "CLICK_SEND_REVIEW";

export const CLICK_PROVIDER_MENU_TARIFFS = "CLICK_PROVIDER_MENU_TARIFFS";

export const CLICK_PROVIDER_MENU_SERVICES = "CLICK_PROVIDER_MENU_SERVICES";

export const CLICK_PROVIDER_MENU_REVIEWS = "CLICK_PROVIDER_MENU_REVIEWS";

export const CLICK_PROVIDER_MENU_NEWS = "CLICK_PROVIDER_MENU_NEWS";

export const CLICK_PROVIDER_MENU_ACTIONS = "CLICK_PROVIDER_MENU_ACTIONS";

export const CLICK_CONNECT_COMPARE_PITER = "ORDER_SEND_NEW";
export const SELECT_CALLBACK_FIELD_PHONE = "SELECT_CALLBACK_FIELD_PHONE";
export const CLICK_ABOUT_RATING = "CLICK_ABOUT_RATING";
export const CLICK_VOTE = "CLICK_VOTE";
export const CLICK_OPEN_REVIEW = "CLICK_OPEN_REVIEW";
export const CLICK_ALL_ACTION = "CLICK_ALL_ACTION";
export const CLICK_OPEN_ACTION = "CLICK_OPEN_ACTION";
export const CLICK_SEND_ORDER_OFFICE = "click_send_order_office";
export const CLICK_PAGINATION = "CLICK_PAGINATION";
export const CLICK_WRONG_HOUSE = "click_popup_wrong_house_load";
export const CLICK_PREENTERED_STREET = "click_search_form_pre_entered_street";
export const CLICK_PREENTERED_HOME = "click_search_form_pre_entered_home";
export const CLICK_EXPAND_DYDOS = "click_open_dudo";
export const CLICK_PROVIDER_COVERAGE = "check_coveradge";
export const SUCCESS_SEARCH_ADDRESS_PROVIDER_COVERAGE = "find_tohome_button_popup";
export const CLICK_BUTTON_CONTEST_VK = "click_popup_vk";
export const CLICK_FIELD_STREET_NEW_SEARCH = "click_field_street_new_search";
export const ENTERING_NUMBER_NEW_SEARCH = "entering_number_new_search";
export const CLICK_SELECT_STREET_AND_HOUSE_NEW_SEARCH = "click_select_street_and_house_new_search";
export const CLICK_SELECT_STREET_NEW_SEARCH = "click_select_street_new_search";
export const CLICK_POPUP_WRONG_HOUSE_LOAD_NEW_SEARCH = "click_popup_wrong_house_load_new_search";
export const CLICK_FIND_TOHOME_BUTTON_NEW_SEARCH = "click_find_tohome_button_new_search";
export const CLICK_FIND_TOHOME_BUTTON = "click_find_tohome_button";
export const CLICK_FIND_OFFICE_BUTTON_NEW_SEARCH = "click_find_office_button_new_search";
export const CLICK_FIND_SAT_BUTTON_NEW_SEARCH = "click_callback_sat_new_search";
export const CLICK_FIND_OFFICE_BUTTON = "click_find_office_button";
export const CLICK_START_TENDER = "click_start_tender";
export const CLICK_SEND_OFFICE_BUTTON = "click_send_office_button";
export const CLICK_START_TENDER_NEW_SEARCH = "click_start_tender_new_search";
export const CLICK_SEND_OFFICE_BUTTON_NEW_SEARCH = "click_send_office_button_new_search";
export const CLICK_PROVIDER_FROM_TOP = "click_provider_from_top";
export const open_quiz = "open_quiz";
export const PAGE_NOT_FOUND = "page_not_found";
export const OPEN_POPUP_EXIT = "open_popup_exit";
export const LOAD_WANT_SIM = "load_want_sim";
export const CLICK_CONNECT_WITH_ACTION = "click_connect_with_action";
export const CLICK_3IN1_FROM_HOMEPAGE = "click_3in1_from_homepage";
export const CLICK_RATES_POPUP1_FROM_QUIZ_SEND_PHONE = "click_rates_popup1_from_quiz_send_phone";
