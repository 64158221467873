import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = createUseStyles((theme) => ({
	root: {
		fontFamily: "Inter-Regular",
		color: "#6D7482",
		fontSize: "14px",
		lineHeight: "20px",
		display: "block",
		width: "100%",
		"&:hover": {
			color: "#65A3FF",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			fontSize: "12px",
			lineHeight: "16px",
		},
	},
}));

const FooterTopSubtitle = (props) => {
	const { text, src } = props;
	const classes = useStyles();

	return (
		<Link className={classes.root} to={src}>
			{text}
		</Link>
	);
};

FooterTopSubtitle.propTypes = {
	text: PropTypes.string,
	src: PropTypes.string,
};
export default FooterTopSubtitle;
