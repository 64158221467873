import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			height: "60px",
			userSelect: "none",
			"font-size": "16px",
			"line-height": "0.6",
			"border-radius": "10px",
			border: "solid 1px " + theme.palette.colors.lightGrayishViolet,
			"font-family": "MuseoSansCyrl-700",
			"letter-spacing": "2.3px",
			color: theme.palette.colors.lightGrayishViolet,
			"text-transform": "uppercase",
			cursor: "pointer",
			display: "inline-flex",
			"justify-content": "center",
			"align-items": "center",
			"background-color": theme.palette.colors.white,
			"&$square,&$circle": {
				padding: "0",
			},
			textAlign: "center",
			"&:hover": {
				color: theme.palette.colors.lightGrayishViolet,
			},
		},
		secondaryAccent: {
			"background-color": theme.palette.colors.white,
			"border-color": theme.palette.colors.purplishBlue,
			color: theme.palette.colors.purplishBlue,
			"&:hover": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		whatsappAccent: {
			"background-color": theme.palette.colors.white,
			"border-color": theme.palette.colors.whatsapp,
			color: theme.palette.colors.whatsapp,
			"&:hover": {
				color: theme.palette.colors.whatsapp,
			},
		},
		tertiaryAccent: {
			"background-color": theme.palette.colors.white,
			"border-color": theme.palette.colors.disabled,
			color: theme.palette.colors.purplishBlue,
			"&:hover": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		bannerAccent: {
			"background-color": theme.palette.colors.cloudyBlue,
			"border-color": theme.palette.colors.disabled,
			color: theme.palette.colors.purplishBlue,
			"&:hover": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		withoutAccent: {
			"background-color": theme.palette.colors.transparent,
			"border-color": theme.palette.colors.disabled,
			color: theme.palette.colors.disabled,
			"&:hover": {
				color: theme.palette.colors.disabled,
			},
		},
		simpleText: {
			"background-color": theme.palette.colors.transparent,
			"border-color": theme.palette.colors.transparent,
			color: theme.palette.colors.disabled,
			"&:hover": {
				color: theme.palette.colors.disabled,
			},
		},
		activeButton: {
			"background-color": theme.palette.colors.purplishBlue,
			color: theme.palette.colors.yellowGreen,
			border: "none",
			"&:hover": {
				color: theme.palette.colors.yellowGreen,
			},
		},
		checkoutAccent: {
			backgroundColor: theme.palette.colors.yellowGreen,
			color: theme.palette.colors.yellowGreen,
			border: "1px solid white",
			"&:hover": {
				color: theme.palette.colors.yellowGreen,
			},
		},
		shade: {
			"box-shadow": "0 2px 4px 0 rgba(43,29,92,.3)",
		},
		badButton: {
			"background-color": theme.palette.colors.black,
			color: theme.palette.colors.white,
			border: "none",
			"&:hover": {
				color: theme.palette.colors.white,
			},
		},
		coralButton: {
			"background-color": theme.palette.colors.coralPink,
			color: theme.palette.colors.white,
			border: "none",
			"&:hover": {
				color: theme.palette.colors.white,
			},
		},
		successButton: {
			"background-color": theme.palette.colors.yellowGreen,
			color: theme.palette.colors.purplishBlue,
			border: "none",
			"&:hover": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		purpleButton: {
			"background-color": theme.palette.colors.purplishBlue,
			color: theme.palette.colors.white,
			border: "none",
			"&:hover": {
				color: theme.palette.colors.white,
			},
		},
		greyButton: {
			"background-color": theme.palette.colors.paleGrey,
			color: theme.palette.colors.purplishBlue,
			border: "none",
			"&:hover": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		orangeButton: {
			"background-color": theme.palette.colors.orange2,
			color: theme.palette.colors.white,
			border: "none",
			"&:hover": {
				color: theme.palette.colors.white,
			},
		},
		lightOrangeButton: {
			"background-color": theme.palette.colors.orange,
			color: theme.palette.colors.white,
			border: "none",
			"&:hover": {
				color: theme.palette.colors.white,
			},
		},
		transparentWithBorders: {
			backgroundColor: theme.palette.colors.transparent,
			color: theme.palette.colors.white,
			border: `solid 1px ${theme.palette.colors.white}`,
			"&:hover": {
				color: theme.palette.colors.white,
			},
		},
		smallText: {
			"font-size": "14px",
			"line-height": "1",
		},
		defaultSizeHeight: {
			height: "60px",
		},
		mediumText: {
			"font-size": "14px",
			"line-height": "1",
		},
		bannerText: {
			fontSize: "16px",
		},
		small: {
			padding: "0 16px",
			height: "40px",
		},
		medium: {
			padding: "0 24px",
			height: "50px",
		},
		large: {
			padding: "0 27px",
			height: "60px",
		},
		always50: {
			padding: "0 24px",
			height: "50px",
		},
		circle: {
			"border-radius": "50%",
			width: `60px`,
			height: `60px`,
		},
		square: {
			width: `60px`,
			height: `60px`,
		},
		disabled: {
			"background-color": theme.palette.colors.disabled,
			border: "none",
			color: theme.palette.colors.white,
			"&:hover": {
				color: theme.palette.colors.white,
			},
		},
		fullWidth: {
			width: "100%",
		},
		noPaddings: {
			padding: "0 !important",
		},
		bigText: {
			"font-size": "16px",
			"line-height": "0.6",
		},
		always16: {
			"font-size": "16px",
			"line-height": "0.6",
		},
		veryBigText: {
			"font-size": "18px",
			"line-height": "0.6",
		},
		transparent: {
			backgroundColor: theme.palette.colors.transparent,
		},
		marginLeft: {
			marginLeft: "20px",
		},
		marginTop: {
			marginTop: "10px",
		},
		marginBottom: {
			marginBottom: "20px",
		},
		isStub: {
			backgroundColor: theme.palette.colors.disabled,
			width: "200px",
		},
		extraSmall: {
			padding: "0 16px",
			height: "40px",
			margin: "0px 5px 5px 0px",
		},
		smallTagButton: {},
		BrandButtonHeader: {
			maxWidth: "480px",
			width: "auto",
			"line-height": "22px",
			border: "none",
			height: "auto",
			minHeight: "60px",
			padding: "10px 27px",
		},
		BrandButtonFooter: {
			width: "100%",
			margin: 0,
			fontSize: "16px",
			lineHeight: 1,
		},
		fontFamilyMedium: {
			fontFamily: theme.fonts.medium,
		},
		fontFamilyLight: {
			fontFamily: theme.fonts.light,
		},
		onClickText: {
			fontSize: "14px",
			lineHeight: 1.6,
		},
		buttonMore: {
			width: "250px",
			height: "56px",
			color: theme.palette.colors.purplishBlue,
			borderColor: theme.palette.colors.purplishBlue,
			"&:hover": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		colorPurplishBlue: {
			color: theme.palette.colors.purplishBlue,
			"&:hover": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		buttonWant: {
			width: "140px",
			height: "40px",
			color: theme.palette.colors.white,
			backgroundColor: theme.palette.colors.transparent,
			borderColor: theme.palette.colors.white,
			borderRadius: "10px",
			fontSize: "18px",
			fontFamily: theme.fonts.bold,
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			mediumText: {
				"font-size": "14px",
			},
			buttonMore: {
				width: "165px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			mediumText: {
				"font-size": "12px",
			},
			bigText: {
				"font-size": "14px",
			},
			bannerText: {
				fontSize: "16px",
			},
			BrandButtonHeader: {
				width: "350px",
				marginLeft: "34px",
				"font-size": "14px",
				padding: "0px 0px",
			},
			buttonMore: {
				width: "140px",
			},
			buttonWant: {
				height: "48px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				"font-size": "14px",
				"line-height": 1,
				"letter-spacing": "2px",
			},
			smallText: {
				"font-size": "12px",
				"line-height": 1,
			},
			mediumText: {
				"font-size": "12px",
			},
			bannerText: {
				fontSize: "14px",
			},
			bigText: {
				"font-size": "12px",
				"line-height": 1,
				"letter-spacing": "2.3px",
			},
			small: {
				height: "40px",
			},
			large: {
				padding: "0 24px",
				height: "50px",
			},
			medium: {
				padding: "0 16px",
				height: "50px",
			},
			extraSmall: {
				padding: "0 10px",
				height: "25px",
			},
			BrandButtonHeader: {
				width: "280px",
				marginBottom: "20px",
				marginLeft: "0px",
				"font-size": "12px",
				"line-height": "16px",
				padding: "0px 0px",
				"letter-spacing": "1px",
			},
			BrandButtonFooter: {
				fontSize: "14px",
			},
			buttonMore: {
				width: "100%",
				padding: 0,
				border: 0,
				textAlign: "center",
			},
			buttonWant: {
				width: "80px",
				height: "40px",
			},
		},
		[`@media (max-width: ${theme.media.xs})`]: {
			smallTagButton: {
				fontSize: "11px",
				whiteSpace: "nowrap",
			},
		},
	};
};

const Button = (props) => {
	const onClickHandler = (e) => {
		props.onClick?.(e);
	};

	const className = classNames(props.classes.root, {
		[props.classes.smallText]: props.typeText === `small`,
		[props.classes.mediumText]: props.typeText === `medium`,
		[props.classes.bigText]: props.typeText === `big`,
		[props.classes.veryBigText]: props.typeText === "verybig",
		[props.classes.always16]: props.typeText === "always16",
		[props.classes.bannerText]: props.typeText === "bannerText",
		[props.classes.onClickText]: props.typeText === "onClick",
		[props.classes.disabled]: props.disabled,
		[props.classes.fullWidth]: props.fullWidth,
		[props.classes.extraSmall]: props.size === "extraLow",
		[props.classes.small]: props.size === "low",
		[props.classes.medium]: props.size === "middle",
		[props.classes.large]: props.size === "big",
		[props.classes.always50]: props.size === "always50",
		[props.classes.square]: props.square,
		[props.classes.circle]: props.circle,
		[props.classes.fullWidth]: props.fullWidth,
		[props.classes.activeButton]: props.status === "active",
		[props.classes.badButton]: props.status === "bad",
		[props.classes.successButton]: props.status === "success",
		[props.classes.purpleButton]: props.status === "purple",
		[props.classes.orangeButton]: props.status === "orange",
		[props.classes.lightOrangeButton]: props.status === "lightOrange",
		[props.classes.transparentWithBorders]: props.status === "transparentWithBorders",
		[props.classes.coralButton]: props.status === "coral",
		[props.classes.greyButton]: props.status === "grey",
		[props.classes.secondaryAccent]: props.status === "secondaryAccent",
		[props.classes.whatsappAccent]: props.status === "whatsappAccent",
		[props.classes.tertiaryAccent]: props.status === "tertiaryAccent",
		[props.classes.withoutAccent]: props.status === "withoutAccent",
		[props.classes.checkoutAccent]: props.status === "checkoutAccent",
		[props.classes.bannerAccent]: props.status === "bannerAccent",
		[props.classes.simpleText]: props.status === "simpleText",
		[props.classes.shade]: props.shade,
		[props.classes.transparent]: props.transparent,
		[props.classes.noPaddings]: props.noPaddings,
		[props.classes.isStub]: props.stub,
		[props.classes.smallTagButton]: props.smallTagButton,
		[props.classes.marginTop]: props.marginTop,
		[props.classes.marginLeft]: props.marginLeft,
		[props.classes.marginBottom]: props.marginBottom,
		[props.classes.BrandButtonHeader]: props.typeBrandButton === "Header",
		[props.classes.BrandButtonFooter]: props.typeBrandButton === "Footer",
		[props.classes.fontFamilyMedium]: props.typefontFamily === "medium",
		[props.classes.fontFamilyLight]: props.typefontFamily === "light",
		[props.classes.buttonMore]: props.buttonMore,
		[props.classes.colorPurplishBlue]: props.color === "purplishBlue",
		[props.classes.buttonWant]: props.buttonWant,
		[props.className]: props.className,
	});

	if (props.component && !props.stub) {
		return React.cloneElement(props.component, {
			onClick: (e) => onClickHandler(e),
			className: className,
			children: props.children,
			datatest: props.datatest,
		});
	}

	return (
		<div
			style={props.style}
			ref={props.buttonRef}
			onClick={onClickHandler}
			className={className}
			data-test={props.datatest}
		>
			{!props.stub && props.children}
		</div>
	);
};

Button.defaultProps = {
	datatest: null,
	onClick: () => {},
	typeText: "medium",
	status: "default",
	size: "middle",
	disabled: false,
	link: "",
	fullWidth: false,
	circle: false,
	square: false,
	shade: false,
	transparent: false,
	noPaddings: false,
	component: false,
	color: "",
};

const styledButton = withStyles(styles)(Button);
styledButton.displayName = "Button";

export default styledButton;
