import React from "react";
import withStyles from "react-jss";
import { FromFade } from "ComponentsUI/Animations";
import classNames from "classnames";

const formStyle = (theme) => ({
	root: {
		width: "100%",
		display: "flex",
		alignItems: "flex-end",
		position: "relative",
		marginTop: "16px",
		"flex-direction": "row",
	},
	rootSuccess: {
		flexDirection: "column",
	},
	textFieldWrapper: {
		width: "100%",
		marginRight: "auto",
		position: "relative",
	},
	buttonWrapper: {
		width: "auto",
		flexShrink: "0",
		marginLeft: "20px",
	},
	buttonOneWrapper: {
		width: "auto",
		flexShrink: "0",
	},
	successHeader: {
		fontSize: "24px",
		fontFamily: theme.fonts.bold,
		color: "#f5f7fa",
		lineHeight: "1.2",
		textAlign: "center",
		marginBottom: "22px",
	},
	successSubHeader: {
		fontSize: "16px",
		fontFamily: theme.fonts.light,
		color: "#f5f7fa",
		lineHeight: "1.2",
		textAlign: "center",
	},
	dropdownWrapper: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		position: "relative",
		"& div:first-child": {
			width: "100%",
			flexShrink: "1",
			marginRight: "20px",
		},
		"& div:last-child": {
			width: "100%",
			flexShrink: "2",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			flexWrap: "wrap",
			marginTop: "24px",
		},
		buttonWrapper: {
			width: "100%",
			marginTop: "10px",
			marginLeft: "0",
		},
		buttonOneWrapper: {
			width: "100%",
			marginTop: "10px",
			marginLeft: "0",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			flexWrap: "wrap",
		},
		buttonWrapper: {
			width: "100%",
			marginTop: "20px",
			marginLeft: "0",
		},
		buttonOneWrapper: {
			width: "100%",
			marginTop: "20px",
			marginLeft: "0",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			flexWrap: "wrap",
		},
		buttonWrapper: {
			width: "100%",
			marginTop: "10px",
			marginLeft: "0",
		},
		buttonOneWrapper: {
			width: "100%",
			marginTop: "10px",
			marginLeft: "0",
		},
	},
});

class AdFormColor extends React.Component {
	render() {
		let children = this.props.children;
		let classes = this.props.classes;
		let isSuccess = this.props.isSuccess;
		let successSubHeader = this.props.successSubHeader;
		let successHeader = this.props.successHeader;
		if (!isSuccess) {
			let dropdDownChildren = [];
			let newChildren = [];
			if (children.length) {
				children.map((child, i) => {
					if (!child) return null;
					if (child.type.displayName === "TextField") {
						return newChildren.push(
							<div key={i} className={classes.textFieldWrapper}>
								{React.cloneElement(child, {
									...child.props,
									key: i,
								})}
							</div>
						);
					}

					if (child.type.displayName === "Dropdown") {
						return dropdDownChildren.push(
							React.cloneElement(child, {
								...child.props,
								key: i,
							})
						);
					}

					if (child.type.displayName === "Button") {
						return newChildren.push(
							<div key={i} className={classes.buttonWrapper}>
								{React.cloneElement(child, {
									...child.props,
									key: i,
								})}
							</div>
						);
					}
					return newChildren.push(React.cloneElement(child, { ...child.props, key: i }));
				});
			} else {
				if (children.type.displayName === "Button") {
					return (
						<form className={classes.root}>
							<div key={"Button"} className={classes.buttonOneWrapper}>
								{React.cloneElement(children, {
									...children.props,
									key: "Button",
								})}
							</div>
						</form>
					);
				}
				return (
					<form className={classes.root}>
						<div key={"Button"} className={classes.buttonOneWrapper}>
							{React.cloneElement(children, {
								...children.props,
								key: "Button",
							})}
						</div>
					</form>
				);
			}

			if (dropdDownChildren.length) {
				return (
					<form className={classes.root}>
						<div className={classes.dropdownWrapper}>{dropdDownChildren}</div>
						{newChildren}
					</form>
				);
			}
			return <form className={classes.root}>{newChildren}</form>;
		} else {
			return (
				<FromFade>
					<div className={classNames(classes.root, classes.rootSuccess)}>
						<div className={classes.successHeader}>{successHeader}</div>
						<div className={classes.successSubHeader}>{successSubHeader}</div>
					</div>
				</FromFade>
			);
		}
	}
}

const StyledAdFormColor = withStyles(formStyle)(AdFormColor);

export default React.forwardRef((props, ref) => <StyledAdFormColor ref={ref} {...props} />);
