import React from "react";
import { createUseStyles } from "react-jss";
import { NavLink } from "react-router-dom";
import { PurplePopup } from "./../index";
import LazyImage from "ComponentsUI/Image";

const useStyles = createUseStyles((theme) => ({
	root: {
		maxWidth: "80%",
	},
	img: {
		maxHeight: "30px",
		maxWidth: "100%",
		objectFit: "scale-down",
	},
	imgPopup: {
		maxHeight: "60px",
		maxWidth: "100%",
		cursor: "pointer",
	},
	popupContent: {},
	textWrapper: {
		position: "relative",
		textAlign: "center",
	},
	link: {
		cursor: "pointer",
	},
	primaryText: {
		fontFamily: theme.fonts.medium,
		fontSize: "14px",
		letterSpacing: "2px",
		color: theme.palette.colors.white,
		textTransform: "uppercase",
	},
	secondaryText: {
		fontFamily: theme.fonts.medium,
		fontSize: "12px",
		transform: "translateX(100%)",
		color: theme.palette.colors.white,
		right: "-6px",
		top: "-9px",
		position: "relative",
	},
	rating: {
		marginBottom: "16px",
		padding: "0 15px",
		textAlign: "center",
	},
	noRating: {
		fontFamily: theme.fonts.medium,
		fontSize: "12px",
		letterSpacing: "2px",
		color: theme.palette.colors.white,
		textTransform: "uppercase",
	},
	[`@media (max-width: ${theme.media.lg})`]: {
		root: {
			display: "flex",
			justifyContent: "center",
		},
	},
}));

function ProviderLogo({
	webpTransformer,
	path,
	position,
	name,
	rating,
	texts,
	reviewsCounter,
	windowSizes,
	viewPopup,
	providerReviewUrl,
}) {
	let classes = useStyles();

	const image = (
		<LazyImage
			src={!!webpTransformer ? webpTransformer(path) : path}
			className={classes.img}
			alt={name}
		/>
	);
	return (
		<div className={classes.root}>
			{windowSizes.xs || !viewPopup ? (
				image
			) : (
				<PurplePopup element={image}>
					<div className={classes.popupContent}>
						<div className={classes.rating}>
							{!!rating ? (
								<div className={classes.noRating}>Рейтинг: {position} место</div>
							) : (
								<div className={classes.noRating}></div>
							)}
						</div>

						<div className={classes.textWrapper}>
							<NavLink to={providerReviewUrl} className={classes.link}>
								<span className={classes.primaryText}>{texts.REVIEWS}</span>
								<span className={classes.secondaryText}>{reviewsCounter}</span>
							</NavLink>
						</div>
					</div>
				</PurplePopup>
			)}
		</div>
	);
}

export default ProviderLogo;
