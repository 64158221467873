import React, { useState } from "react";
import { inject } from "mobx-react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { sendMetriks } from "Services/sendMetriks";
import WaitingCallContainer from "Containers/WaitingCallContainer";
import TermsOfUse from "ComponentsUI/TermsOfUse";

const useStyles = createUseStyles((theme) => ({
	root: {
		position: "relative",
	},
	wrapper: {
		padding: "40px 30px",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		background: theme.palette.colors.purplishBlue,
		boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.5)",
		borderRadius: "10px",
		width: "380px",
	},
	wrapperNewWaitingCall: {
		padding: "25px 30px",
	},
	typeB: {},
	close: {
		position: "absolute",
		top: "20px",
		right: "20px",
		cursor: "pointer",
		"& span": {
			color: theme.palette.colors.disabled,
			fontSize: "16px",
		},
	},
	header: {
		fontFamily: theme.fonts.bold,
		fontSize: "18px",
		lineHeight: 1,
		color: theme.palette.colors.white,
		maxWidth: "320px",
		textAlign: "center",
		marginBottom: "30px",
	},
	subtext: {
		fontFamily: theme.fonts.light,
		fontSize: "14px",
		lineHeight: 1.29,
		color: theme.palette.colors.white,
		textAlign: "center",
		margin: "20px 0",
	},
	newMargin: {
		margin: "20px 0 0",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			alignSelf: "flex-end",
		},
		wrapper: {
			padding: "40px 20px 0",
			width: "auto",
			height: "calc(100vh - 60px)",
			boxShadow: "none",
			borderRadius: "0",
		},
		wrapperNewWaitingCall: {
			padding: "25px 30px",
		},
		header: {
			width: "100%",
		},
		close: {
			display: "none",
		},
		typeB: {
			"& $close": {
				display: "block",
				"& span": {
					fontSize: "16px",
				},
			},
		},
	},
}));

const FindTariffs = (props) => {
	const {
		ApplicationModel: { text },
	} = props;
	const classes = useStyles();
	const [showSubText, setShowSubText] = useState(true);
	let header = text.waiting_call_1;
	let header2 = text.waiting_call_2;

	const handleClick = () => {
		sendMetriks("CLICK_HEADER");
		// Metrika.getInstance().google.sendEvent(
		//     EVENT_CATEGORY_CLICK_BUTTON,
		//     EVENT_ACTION_CLICK_FIND_TARIFFS_HEADER
		// );
		props.onClose();
	};

	const handleStepChange = () => {
		setShowSubText(false);
	};

	return (
		<div
			className={classNames(classes.root, {
				[classes.typeB]: !!props.typeB,
			})}
			datatest="find_tariff_with_phone"
		>
			<div
				className={classNames(classes.wrapper, {
					[classes.wrapperNewWaitingCall]: true,
				})}
			>
				<WaitingCallContainer
					successCallback={props.successCallback}
					onClose={props.onClose}
					header={header}
					header2={header2}
					onStepChange={handleStepChange}
					providerId={props.providerId}
				/>
				{showSubText && (
					<>
						<TermsOfUse
							buttonText={text.CALLBACK_FORM_BUTTON}
							isFindTariffs={true}
							largeMarginTop={true}
						/>
						<div
							className={classNames(classes.subtext, {
								[classes.newMargin]: true,
							})}
						>
							{text.callCenterTime}
						</div>
					</>
				)}
			</div>
			<div className={classes.close} onClick={props.onClose}>
				<span className="icon24 icon-close" />
			</div>
		</div>
	);
};

export default inject("ApplicationModel")(FindTariffs);
