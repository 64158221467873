import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Routes from "Configs/routes";

import { BannerSelectRegion } from "ComponentsUI/Banner";

@withRouter
@inject("ApplicationModel")
@observer
export default class HeroBannerSelectRegionContainer extends React.Component {
	render() {
		let textConfig = this.props.ApplicationModel.text;
		return (
			<BannerSelectRegion
				linkComponent={<Link to={Routes.SelectRegion.route} />}
				toggleModal={this.toggleModal}
				buttonText={textConfig.selectRegionHeroBannerButton}
				text={textConfig.selectRegionHeroBannerInfo}
			/>
		);
	}
}
