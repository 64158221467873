import { observable, action, runInAction } from "mobx";
import LoaderData from "Services/LoaderData";
import cookie from "js-cookie";

export default class DetectRegionModel {
	isDetect = false;
	isLoading = false;
	region = null;

	@observable show = false;

	constructor(preloadState) {}

	goDetect() {
		if (this.isDetect) {
			return;
		}

		// что бы не вызывать по 100 раз
		if (this.isLoading) {
			return;
		}

		this.isLoading = true;
		LoaderData.getFromLocal("/detect-region").then((json) => {
			runInAction(() => {
				this.saveRegion(json);
			});
		});
	}

	@action
	saveRegion(data) {
		if (data.need_detect == "1") {
			let region = cookie.get("current_region");
			if (!region) {
				this.region = data.data;
				this.isDetect = true;
				this.isLoading = false;
				this.show = true;
			}
		}
	}

	@action
	setAccept(region) {
		let region_id = region.id;

		//Если регион является районом
		if (region.district_region_id) {
			region_id = region.district_region_id;
		}
		cookie.set("current_region", region_id, { expires: 7 });
		this.show = false;
	}
}
