import React, { Component } from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import Metrika from "Services/Metrika";
import { YANDEX_TARGETS } from "Constants/metriks/yandex-targets";

const styles = (theme) => ({
	root: {
		margin: "10px 100px",
		width: "160px",
		display: "block",
		textAlign: "center",
	},
	header: {
		marginTop: "10px",
		textAlign: "center",
		color: theme.palette.colors.white,
	},
	wrapperImage: {
		display: "flex",
		height: "90px",
		width: "160px",
		backgroundColor: "rgba(210, 206, 224, 0.6)",
		borderRadius: "10px",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
	},
	image: {
		height: "auto",
		padding: "5px",
		maxWidth: "95%",
		maxHeight: "95%",
		verticalAlign: "middle",
		objectFit: "scale-down",
	},
	backgroundNoneText: {
		color: theme.palette.colors.darkSlateBlue,
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			margin: "10px 70px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			margin: "10px 29px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			margin: "10px",
		},
	},
});

class TopProviderBlock extends Component {
	onClickComponent(e) {
		e.stopPropagation();
		Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.CLICK_PROVIDER_FROM_TOP, []);
	}

	render() {
		let { classes, provider, linkComponent, linkGeneratorRates, backgroundNone } = this.props;
		return React.cloneElement(linkComponent, {
			onClick: (e) => this.onClickComponent(e),
			to: linkGeneratorRates(provider),
			className: classes.root,
			datatest: "top_provider_block",
			children: (
				<>
					<div className={classes.wrapperImage}>
						<img
							width={240}
							height={120}
							alt={`Тарифы провайдера ${provider.name}`}
							className={classes.image}
							src={provider.logo}
						/>
					</div>
					<div
						className={classNames(classes.header, {
							[classes.backgroundNoneText]: backgroundNone,
						})}
					>
						{provider.name}
					</div>
				</>
			),
		});
	}
}

export default withStyles(styles)(TopProviderBlock);
