import React from "react";
import withStyles from "react-jss";
import styles from "../styles";
import classNames from "classnames";

const Component = ({ classes, datatest, tableHeader, isRating, children }) => (
	<div
		className={classNames(classes.root, {
			[classes.isRating]: !!isRating,
		})}
		datatest={datatest}
	>
		<div className={classes.table}>
			<div className={classes.thead}>{tableHeader}</div>
			<div>{children}</div>
		</div>
	</div>
);

export default withStyles(styles)(Component);
