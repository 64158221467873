import React from "react";
import loadable from "@loadable/component";
import { Route, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Cookies from "js-cookie";
import { sendMetriks } from "Services/sendMetriks";
import withDefaultParams from "Services/withDefaultParams";
import HeaderContainer from "Containers/HeaderContainer";
import { FooterContainerV2 } from "Containers/Footer";
import WithBanner from "Layouts/withBanner";
import smoothScrollTo from "Helpers/Scroll";
import WithSEOv2 from "HOC/withSEOv2";
import PopupBHOC from "HOC/PopupBHOC";
import ExitUserPopupHoc from "HOC/ExitUserPopupHoc";
import WhatsappSendMessageContainer from "Containers/WhatsApp/WhatsappSendMessageContainer";
import InfoBlock from "../../componentsUI/InfoBlock";
import WidgetRating from "../../componentsUIV2/WidgetRating";

const FixedHeader = loadable(() => import("Containers/FixedHeaderContainer"), {
	ssr: false,
});

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
export default class MainLayout extends React.Component {
	componentDidUpdate(prevProps, prevState, snapshot) {
		let windowSizes = this.props.ApplicationModel.windowSizeModel.windowSizes;
		let windowSizeHeight = windowSizes.height;
		if (!!windowSizeHeight) {
			let metriksVisitor = Cookies.get("metriksVisitor");
			if (!metriksVisitor && !!windowSizes.height) {
				let dateMetriks = new Date();
				dateMetriks.setTime(dateMetriks.getTime() + 60 * 60 * 24 * 1000);
				if (windowSizes.xs) {
					sendMetriks("VISITOR_MOBILE");
				} else if (windowSizes.sm) {
					sendMetriks("VISITOR_TABLET");
				} else {
					sendMetriks("VISITOR_DESKTOP");
				}
				Cookies.set("metriksVisitor", true, { expires: dateMetriks });
			}
		}
	}

	getSnapshotBeforeUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			if (window) {
				smoothScrollTo(0, 0, 100);
			}
		}

		let applicationModel = this.props.ApplicationModel;
		let actionBrowser = !!this.props.history.action ? this.props.history.action : "";

		// Закрывать модальные окна, если они открыты, когда пользователь нажал кнопку назад и страница различается
		if (actionBrowser === "POP" && prevProps.path !== this.props.path) {
			const modal = applicationModel.modalModel;
			if (modal.isOpen) {
				modal.toggleModal();
			}
		}

		// Возвращать предыдущий регион при нажатии кнопки "назад"
		let currentRegion = applicationModel?.currentRegion;
		let currentRegionUrl = this.props?.match?.params?.regionUrl;
		if (currentRegion?.url !== currentRegionUrl) {
			let regions = applicationModel?.siteConfig?.regions?.available;
			if (regions?.length) {
				let foundRegion = regions.find((x) => x?.url === currentRegionUrl);
				if (foundRegion) {
					applicationModel.setRegion(foundRegion);
				} else {
					const defaultRegion = applicationModel?.siteConfig?.regions?.default;
					if (defaultRegion) {
						applicationModel.setRegion(defaultRegion);
					}
				}
			}
		}

		return null;
	}

	isBloked = () => {
		return !this.props.computedMatch.params.regionUrl;
	};

	render() {
		let { component: Component, ApplicationModel, viewMobilePopup, ...rest } = this.props;

		let windowSizes = this.props.ApplicationModel.windowSizeModel.windowSizes;
		const siteCode = this.props.ApplicationModel.siteCode;

		return (
			<>
				{windowSizes.xs && <PopupBHOC viewMobilePopup={viewMobilePopup} />}
				<Route
					{...rest}
					render={(matchProps) => {
						let extendedProps = {
							...matchProps,
							route: rest.route,
						};
						let COMP = rest.withBanner ? (
							<WithBanner
								siteCode={siteCode}
								withDistrict={rest.withDistrict}
								withHouseId={rest.withHouseId}
								withStreet={rest.withStreet}
								withoutMarginTopSeoText={this.props.withoutMarginTopSeoText}
								// withoutBackground={true}
								// darkBackground={true}
							>
								<Component {...extendedProps} />
							</WithBanner>
						) : null;

						const region = ApplicationModel?.currentRegion;
						const settings = ApplicationModel?.siteConfig?.settings;
						const { show_poll_quality_site, opening_time_poll_quality_site } = settings;

						return (
							<>
								<div className="__root" key="1">
									<InfoBlock current_region={region} settings={settings} />
									{this.props.whatsappType && (
										<WhatsappSendMessageContainer
											whatsappType={this.props.whatsappType}
											whatsappSettings={settings.whatsapp_send_message}
										/>
									)}
									<ExitUserPopupHoc />
									<HeaderContainer changeWhatsappHeader={this.props.changeWhatsappHeader} />
									<WithSEOv2 route={rest.route} withBannerText={!!rest.withBanner}>
										{!rest.withBanner ? <Component {...extendedProps} /> : COMP}
									</WithSEOv2>
									<div>
										<FooterContainerV2 />
									</div>
								</div>
								<WidgetRating show={show_poll_quality_site} time={opening_time_poll_quality_site} />
								<FixedHeader changeWhatsappHeader={this.props.changeWhatsappHeader} />
							</>
						);
					}}
				/>
			</>
		);
	}
}
