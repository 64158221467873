import React from "react";
import withStyles from "react-jss";

const styles = (theme) => ({
	root: {
		width: "100%",
		display: "flex",
		color: theme.palette.colors.purplishBlue,
		fontSize: "10px",
		alignItems: "center",
	},
	rating: {
		marginLeft: "7px",
		color: theme.palette.colors.purplishBlue,
		display: "flex",
		fontSize: "14px",
	},
});

class Component extends React.Component {
	static defaultProps = {
		onClickStarHandler: () => {},
	};

	countStars = 0;

	render() {
		const { rating = 10 } = this.props;
		let classes = this.props.classes;

		let countPainted = ~~rating;
		let countNotPainted = ~~(10 - rating);
		let countSemiPainted = 10 - (countPainted + countNotPainted);

		const returnArray = [];
		return (
			<div className={classes.root}>
				{returnArray.concat(
					this._renderFullStar(countPainted, "p"),
					this._renderSemiStar(countSemiPainted, "s"),
					this._renderEmptyStar(countNotPainted, "n")
				)}
				<div className={classes.rating}>{`${
					Number.isInteger(rating) ? rating : rating.toFixed(1)
				} из 10`}</div>
			</div>
		);
	}

	_renderFullStar(count, n) {
		let returnStar = [];
		let classNameStar = this.props.classNameStar || "";

		this.countStars = 0;
		let countStars = this.countStars;
		for (let i = 0; i < count; i++) {
			if (this.props.tariff) {
				returnStar.push(
					<span
						onClick={() => this.props.onClickStarHandler(countStars + i)}
						key={`${i}${n}`}
						className={`icon24 icon-star_to_tarif_1 ${classNameStar}`}
					/>
				);
			}

			if (this.props.provider) {
				returnStar.push(
					<span
						onClick={() => this.props.onClickStarHandler(countStars + i)}
						key={`${i}${n}`}
						className={`icon24 icon-star_to_provider_1 ${classNameStar}`}
					/>
				);
			}
		}

		this.countStars += count;
		return returnStar;
	}

	_renderSemiStar(count, n) {
		let returnStar = [];
		let classNameStar = this.props.classNameStar || "";
		let countStars = this.countStars;
		for (let i = 0; i < count; i++) {
			if (this.props.tariff) {
				returnStar.push(
					<span
						onClick={() => this.props.onClickStarHandler(countStars + i)}
						key={`${i}${n}`}
						className={`icon24 icon-star_to_tarif_05 ${classNameStar}`}
					/>
				);
			}

			if (this.props.provider) {
				returnStar.push(
					<span
						onClick={() => this.props.onClickStarHandler(countStars + i)}
						key={`${i}${n}`}
						className={`icon24 icon-star_to_provider_05 ${classNameStar}`}
					/>
				);
			}
		}
		this.countStars += count;
		return returnStar;
	}

	_renderEmptyStar(count, n) {
		let returnStar = [];
		let classNameStar = this.props.classNameStar || "";
		let countStars = this.countStars;
		for (let i = 0; i < count; i++) {
			if (this.props.tariff) {
				returnStar.push(
					<span
						onClick={() => this.props.onClickStarHandler(countStars + i)}
						key={`${i}${n}`}
						className={`icon24 icon-star_to_tarif_0 ${classNameStar}`}
					/>
				);
			}

			if (this.props.provider) {
				returnStar.push(
					<span
						onClick={() => this.props.onClickStarHandler(countStars + i)}
						key={`${i}${n}`}
						className={`icon24 icon-star_to_provider_0 ${classNameStar}`}
					/>
				);
			}
		}
		this.countStars += count;
		return returnStar;
	}
}

const RatingStarTen = withStyles(styles)(Component);
RatingStarTen.displayName = "RatingStarTen";

export default RatingStarTen;

export { Component as __RatingStar };
