var gql = require("@apollo/client").gql;

const MUTATE_LOG_SELECT_REGION = gql`
	mutation (
		$uuid: String
		$focus_select_region: Int
		$region_name: String
		$district_name: String
	) {
		logSelectRegion(
			uuid: $uuid
			focus_select_region: $focus_select_region
			region_name: $region_name
			district_name: $district_name
		)
	}
`;

export default MUTATE_LOG_SELECT_REGION;
