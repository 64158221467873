import React from "react";
import ReactDOM from "react-dom";
import { AdFormColorLayout, Form, FormError } from "Layouts/Form";
import Button from "ComponentsUI/Button";
import BenefitForm from "ComponentsUI/Benefit/Components/BenefitForm";
import { inject, observer } from "mobx-react";
import { sendMetriks } from "Services/sendMetriks";
import { CLICK_CALLBACK, CALLBACK_PROV_PAGE } from "Constants/metriks/Yandex";
import userInfo from "Services/UserInfo";
import ProviderConnectWithPhoneHOC from "HOC/ProviderConnectWithPhoneHOC";

@inject("ApplicationModel")
@observer
class CallbackAdverBlock extends React.Component {
	static defaultProps = {
		onHeightChangeHandler: () => {},
	};

	constructor(props) {
		super(props);

		this.wrapper = null;

		this.setWrapperRef = (element) => {
			this.wrapper = element;
		};
	}

	state = {
		phone_connection: "",
		isError: false,
		isSuccess: false,
		componentHeight: 0,
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isError !== this.state.isError) {
			this.props.onHeightChangeHandler();
		}
	}

	onChangeHandler(e) {
		this.setState({
			phone_connection: e.target.value,
		});
	}

	onFocus() {
		this.setState({
			isError: false,
		});
	}

	onBlur() {
		this.setState({
			isError:
				this.state.phone_connection.length !== 11 && this.state.phone_connection.length !== 1,
		});
	}

	async onClickHandler() {
		const { phone_connection } = this.state;
		let {
			ApplicationModel: { sendingStore, searcheModel, popupModel },
		} = this.props;
		if (!sendingStore.validatePhone(phone_connection)) {
			this.setState({
				isError: true,
			});
			return null;
		} else {
			this.setState({
				isSuccess: true,
				componentHeight: ReactDOM.findDOMNode(this.wrapper).clientHeight,
			});
			let data = {
				phone_connection: this.state.phone_connection,
				lead_form_type: userInfo(
					!!this.props.lead_form_type ? this.props.lead_form_type : CLICK_CALLBACK
				),
			};

			if (searcheModel.getCurrentAddress()) {
				if (searcheModel.getCurrentStreet().id) {
					data = {
						...data,
						street_id: searcheModel.getCurrentStreet().id,
					};
				}
				if (searcheModel.getCurrentAddress().id) {
					data = {
						...data,
						house_id: searcheModel.getCurrentAddress().id,
					};
				}
			}

			let infoUser = {
				name_popup: "Callback_AdBlock",
			};
			popupModel.setInfoUser(infoUser);

			let error = await sendingStore.onCallback(data);
			if (!error) {
				if (!!this.props.lead_form_type) {
					sendMetriks("CALLBACK_PROV_PAGE");
				} else {
					sendMetriks("CLICK_CALLBACK");
				}
			} else {
				this.setState({
					isError: true,
				});
			}
		}
	}

	render() {
		const { isError, isSuccess, componentHeight } = this.state;
		const { backgroundColor } = this.props;
		let errorText = "";
		let text = this.props.ApplicationModel.text;

		if (isError) errorText = text.CALLBACK_ERROR;

		return (
			<AdFormColorLayout
				datatest={this.props.datatest}
				backgroundColor={backgroundColor}
				ref={(wrapper) => (this.wrapper = wrapper)}
				height={componentHeight}
			>
				{!isSuccess && (
					<BenefitForm
						checkout
						header={text.CALLBACK_GREEN_TITLE}
						text={text.CALLBACK_GREEN_TEXT}
						icon={
							<span
								style={{
									fontSize: "48px",
									color: "#ccff00",
								}}
								className="icon48 icon-call"
							/>
						}
					/>
				)}
				<Form
					type={"adFormColor"}
					isSuccess={isSuccess}
					successHeader={text.WELCOME_CB_TITLE}
					successSubHeader={text.CALLBACK_GREEN_TEXT_SENDED}
				>
					<ProviderConnectWithPhoneHOC green_form={true}>
						<Button
							status={"checkoutAccent"}
							fullWidth={true}
							transparent={true}
							datatest="provider_services_waitcall_button"
							onClick={() => {
								this.onClickHandler();
							}}
						>
							{text.callbackWait}
						</Button>
					</ProviderConnectWithPhoneHOC>
				</Form>
			</AdFormColorLayout>
		);
	}
}

export default CallbackAdverBlock;
