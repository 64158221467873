import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			width: "100%",
			height: "180px",
			borderRadius: "10px",
			backgroundColor: theme.button.color,
			padding: "30px",
			boxSizing: "border-box",
			position: "relative",
			cursor: "pointer",
		},
		header: {
			fontFamily: theme.fonts.bold,
			fontSize: "18px",
			lineHeight: "1",
			color: theme.palette.colors.white,
			marginBottom: "36px",
		},
		icon: {
			position: "absolute",
			bottom: "30px",
			right: "30px",
			width: "48px",
			height: "48px",
			color: theme.palette.colors.yellowGreen,
			fontSize: "48px",
		},
		secondText: {
			color: theme.palette.colors.white,
			position: "absolute",
			bottom: "30px",
			left: "30px",
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			root: {
				padding: "30px 60px 30px 30px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				height: "170px",
				padding: "20px 20px 30px",
			},
			icon: {
				bottom: "20px",
				right: "20px",
			},
			secondText: {
				bottom: "20px",
				left: "20px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				height: "150px",
				padding: "10px 10px 20px",
			},
		},
	};
};

const BlueBlock = ({ classes, text, secondText, icon, onClick, datatest }) => {
	return (
		<div className={classes.root} onClick={onClick} datatest={datatest}>
			<div className={classes.header}>{text}</div>
			{!!secondText && <div className={classes.secondText}>{secondText}</div>}
			<div className={classes.icon}>{icon}</div>
		</div>
	);
};

BlueBlock.defaultProps = {
	onClick: () => {},
	datatest: null,
};

BlueBlock.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
	icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
	onClick: PropTypes.func,
};

export default withStyles(styles)(BlueBlock);
