var gql = require("@apollo/client").gql;

const SITE_REGION_PHONE_QUERY = gql`
	query SITE_REGION_PHONE_QUERY($regionUrl: String!) {
		siteRegionPhones(region_url: $regionUrl) {
			phone
			show_on_site
			show_callback_form
			tag_tell
			code
			code_country
			signature
			site_code
			operators {
				phone
				show_on_site
				phone_view
				tag_tell
				code
				code_country
			}
		}
	}
`;

export default SITE_REGION_PHONE_QUERY;
