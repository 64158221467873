var gql = require("@apollo/client").gql;

const TARIFF_TV = gql`
	query TariffsOne($id: Int) {
		tariffsOne(id: $id) {
			id
			name
			provider {
				id
				name
				quality {
					code
					name
				}
			}
			tv {
				channels
				channels_hd
				use_text
			}
			tvPackage {
				name
				channels {
					name: first_name
					second_name
					quality {
						name
						code
					}
				}
			}
		}
	}
`;

module.exports = TARIFF_TV;
