import React from "react";
import classNames from "classnames";
import { PaginatorItem } from "ComponentsUI/Pagination";
import withStyles from "react-jss";
import withPaginationSEO from "HOC/withPaginationSEO";
import { Col } from "ComponentsUI/Styles/Grid";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";

const styles = (theme) => {
	return {
		root: {
			padding: "0 0 60px",
		},
		presenceBrand: {
			padding: "20px 0 0",
		},
		wrapper: {
			display: "flex",
			justifyContent: "center",
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				padding: "0 0 32px",
			},
			presenceBrand: {
				padding: "20px 0 0",
			},
		},
	};
};

class Paginator extends React.Component {
	static defaultProps = {
		pageCurrent: 1,
		pageTotal: 0,
		wrapperItem: null,
		urlDefault: "/",
		onChangePage: () => {},
		itemsTotal: 1,
		itemsPerPage: 2,
		datatest: null,
	};

	constructor(props) {
		super(props);
	}

	render() {
		if (this.props.itemsTotal < this.props.itemsPerPage) return null;
		let datatestNextPageButton = `${this.props.datatest}_nextpage_button`;
		let el = null;
		let classes = this.props.classes;
		let child = [];
		let pageCurrent = parseInt(this.props.pageCurrent);
		let limit = !this.props.windowSizes.xs ? 4 : 2;
		let startPage = pageCurrent - limit / 2 > 1 ? pageCurrent - limit / 2 : 1;
		let lastPage =
			pageCurrent + limit / 2 > this.props.pageTotal
				? this.props.pageTotal
				: pageCurrent + limit / 2;
		if (startPage == 1) {
			lastPage = startPage + limit;
			if (lastPage > this.props.pageTotal) lastPage = this.props.pageTotal;
		}

		if (lastPage == this.props.pageTotal && startPage !== 1) {
			startPage = startPage - limit + (lastPage - pageCurrent + limit / 2);
			if (startPage < 1) startPage = 1;
		}

		if (startPage != 1) {
			el = React.cloneElement(
				this.props.wrapperItem,
				{
					first: true,
					"aria-label": "first",
					to: {
						pathname: this.props.urlDefault,
						search: this.props.location.search,
					},
				},
				[<span className="icon24 icon-arrow-1-left" key="page_first" />]
			);
			child.push(
				<PaginatorItem
					key="first"
					value={el}
					onChangePage={this.props.onChangePage}
					pageCurrent={1}
				/>
			);
		}

		for (let i = startPage; i <= lastPage; i++) {
			el = React.cloneElement(
				this.props.wrapperItem,
				{
					active: pageCurrent == i,
					datatest: i == pageCurrent + 1 ? datatestNextPageButton : null,
					to: {
						pathname: i == 1 ? `${this.props.urlDefault}` : `${this.props.urlDefault}/${i}`,
						search: this.props.location.search,
					},
				},
				[i.toString()]
			);

			child.push(
				<PaginatorItem key={i} value={el} onChangePage={this.props.onChangePage} pageCurrent={i} />
			);
		}

		if (lastPage != this.props.pageTotal) {
			el = React.cloneElement(
				this.props.wrapperItem,
				{
					last: true,
					"aria-label": "last",
					datatest: this.props.datatest,
					to: {
						pathname: `${this.props.urlDefault}/${this.props.pageTotal}`,
						search: this.props.location.search,
					},
				},
				[<span className="icon24 icon-arrow-1-right" key="page_last" />]
			);

			child.push(
				<PaginatorItem
					key="last"
					value={el}
					onChangePage={this.props.onChangePage}
					pageCurrent={this.props.pageTotal}
				/>
			);
		}

		return (
			<div
				className={classNames(classes.root, {
					[classes.presenceBrand]: this.props.presenceBrand,
				})}
			>
				<Container>
					<Row>
						<Col xs={12}>
							<div className={classes.wrapper}>{child}</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withPaginationSEO(withStyles(styles)(Paginator));
