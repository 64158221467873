import React from "react";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			marginBottom: "60px",
			wordWrap: "break-word",
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				marginBottom: "40px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				marginBottom: "32px",
			},
		},
	};
};

const PaddingBottom = ({ classes, children }) => <div className={classes.root}>{children}</div>;

export default withStyles(styles)(PaddingBottom);

export { PaddingBottom as __PaddingBottom };
