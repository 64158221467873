import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import classNames from "classnames";

const useFormStyle = createUseStyles((theme) => ({
	root: {
		width: "100%",
		position: "relative",
	},
	success: {
		height: "100%",
		padding: "16px 0 30px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexWrap: "wrap",
		flexDirection: "column",
		animation: "2s fromFade",
	},
	textFieldWrapper: {
		marginBottom: "27px",
		width: "100%",
	},
	buttonWrapper: {
		width: "100%",
	},
	header: {
		fontSize: "24px",
		marginTop: "16px",
		fontFamily: theme.fonts.bold,
		color: theme.palette.colors.darkSlateBlue,
		lineHeight: "1.2",
		textAlign: "center",
		marginBottom: "22px",
	},
	headerHomeOrder: {
		marginTop: "0",
	},
	subheader: {
		fontSize: "16px",
		fontFamily: theme.fonts.light,
		color: theme.palette.colors.oneMoreGrey,
		lineHeight: "1.2",
		textAlign: "center",
		marginBottom: "16px",
	},
	orderHomeTariffPad: {},
	[`@media (max-width: ${theme.media.md})`]: {
		header: {
			fontSize: "18px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			width: "290px",
		},
		orderHomeTariffPad: {
			width: "100%",
		},
	},
}));

const PopupForm = ({ children, isSuccess, successHeader, successSubHeader, successButton }) => {
	const classes = useFormStyle();

	if (!isSuccess) {
		let newChildren = children.map((child, i) => {
			if (!child) return null;
			if (child.type.displayName === "TextField") {
				return (
					<div key={i} className={classes.textFieldWrapper}>
						{React.cloneElement(child, {
							...child.props,
							key: i,
						})}
					</div>
				);
			}
			if (child.type.displayName === "Button") {
				return (
					<div key={i} className={classes.buttonWrapper}>
						{React.cloneElement(child, {
							...child.props,
							key: i,
						})}
					</div>
				);
			}
			return React.cloneElement(child, { ...child.props, key: i });
		});
		return <div className={classes.root}>{newChildren}</div>;
	} else {
		const className = classNames(classes.root, {
			[classes.success]: isSuccess,
			[classes.orderHomeTariffPad]: isSuccess,
		});
		let newChildren = children.map((child, i) => {
			if (!child) return null;
			if (child.type.displayName === "TitleForm") {
				return React.cloneElement(child, {
					...child.props,
					key: i,
				});
			}
		});
		return (
			<div className={className}>
				{newChildren}
				<div className={classes.header}>{successHeader}</div>
				<div className={classes.subheader}>{successSubHeader}</div>
				{successButton}
			</div>
		);
	}
};

PopupForm.propTypes = {
	children: PropTypes.node,
};

export default PopupForm;
