var gql = require("@apollo/client").gql;

const DISTRICTS_QUERY = gql`
	query Districts($filter: String, $regionUrl: String) {
		districts(filter: $filter, region_url: $regionUrl) {
			data {
				id
				parentId
				name
				service
				region {
					id
				}
			}
		}
	}
`;

export default DISTRICTS_QUERY;
