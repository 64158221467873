import { observable, action } from "mobx";
import Cookies from "js-cookie";
import { captureException, captureMessage } from "@sentry/react";
import LoaderData from "Services/LoaderData";
import { MUTATE_SITECONFIG_COUNTER, MUTATE_SEND_ORDER } from "Services/queries";
import Metrika from "Services/Metrika";

export default class TariffsModel {
	fetchUrl = "/orders";
	@observable rootStore = null;
	@observable selectedTariff = {};
	@observable selectedProvider = {};
	@observable tariff_id = 0;
	@observable ABTest = false;
	@observable tariffListRef = null;
	@observable buttonTagRef = null;

	address = {};

	constructor(rootStore) {
		this.rootStore = rootStore;
		// this.filtersModel = new FiltersModel(rootStore);
	}

	@action
	setTariff(tariff) {
		this.selectedTariff = tariff;
	}

	@action
	setABTest(bool) {
		this.ABTest = bool;
	}

	@action
	setProvider(provider) {
		this.selectedProvider = provider;
	}

	@action
	setTariffId(id) {
		this.tariff_id = id;
	}

	@action
	setAddress(address) {
		this.address = address;
	}

	@action
	reset() {
		this.selectedTariff = {};
		this.selectedProvider = {};
		this.address = {};
	}

	sendOrderMutation(data) {
		const apolloClient = this.rootStore.apolloTransportLayer;

		let url;
		if (typeof window !== "undefined") {
			url = window?.location?.href;
		}

		if (data.convergent_internet) {
			data.convergent_internet = +data.convergent_internet;
		}
		if (data.convergent_sms) {
			data.convergent_sms = +data.convergent_sms;
		}
		if (data.convergent_calls) {
			data.convergent_calls = +data.convergent_calls;
		}

		apolloClient.mutate({
			mutation: MUTATE_SEND_ORDER,
			variables: {
				...data,
				url,
			},
		});
	}

	sendOrderOne = async (data) => {
		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		const one_string_search = this.rootStore?.siteConfig?.settings?.one_string_search?.enabled;

		if (one_string_search) {
			if (
				!!this.rootStore.searcheModelOneString.house_id &&
				this.rootStore.searcheModelOneString.house_id < 0
			) {
				this.rootStore.searcheModelOneString.setHouseId(0);
			}
		} else {
			if (!!this.rootStore.searcheModel.house_id && this.rootStore.searcheModel.house_id < 0) {
				this.rootStore.searcheModel.setHouseId(0);
			}
		}

		let success = false;

		try {
			this.sendOrderMutation(data);
		} catch (error) {
			captureException(error, {
				tags: {
					"error.type": "order_send_log",
				},
				extra: {
					name: "sendOrderMutation. sendOrderOne",
					order: data,
				},
				level: "error",
			});
		}
		try {
			const json = await LoaderData.post2(
				`${this.fetchUrl}?type=site101-order-home`,
				data,
				this.rootStore?.apiHost,
				this.rootStore?.ordersRequestTimeout
			);

			this.rootStore.commonViewModel.changeDontShowExitPopup(true);
			if (json?.ok === 1) {
				success = true;
			} else {
				captureMessage("sendOrderOne fail", {
					extra: {
						name: "sendOrderOne",
						order: data,
						response: json,
					},
					level: "warning",
				});
			}
		} catch (e) {
			captureException(e, {
				tags: {
					"error.type": "order_send",
				},
				extra: {
					name: "sendOrderOne",
					order: data,
				},
				level: "error",
			});
		}

		return success;
	};

	sendOrder = async (data, count = 5) => {
		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		if (!!data.house_id && data.house_id < 0) {
			delete data.house_id;
		}

		let success = false;
		let failCounter = 0;

		while (!success && failCounter < count) {
			try {
				const json = await LoaderData.post2(
					`${this.fetchUrl}?type=site101-order-home`,
					data,
					this.rootStore?.apiHost,
					this.rootStore?.ordersRequestTimeout
				);

				this.rootStore.commonViewModel.changeDontShowExitPopup(true);
				if (json?.ok === 1) {
					success = true;
				} else {
					failCounter++;

					captureMessage("sendOrder fail", {
						extra: {
							name: "sendOrder",
							order: data,
							response: json,
							try: failCounter,
						},
						level: "warning",
					});
				}
			} catch (e) {
				failCounter++;

				captureException(e, {
					tags: {
						"error.type": "order_send",
					},
					extra: {
						name: "sendOrder",
						order: data,
						fails: failCounter,
					},
					level: "error",
				});
			}
		}

		return success;
	};

	sendOrderSAT = async (data) => {
		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		let success = false;
		let failCounter = 0;

		try {
			this.sendOrderMutation(data);
		} catch (error) {
			captureException(error, {
				tags: {
					"error.type": "order_send_log",
				},
				extra: {
					name: "sendOrderMutation. sendOrderSAT",
					order: data,
				},
				level: "error",
			});
		}
		while (!success && failCounter < 5) {
			try {
				const json = await LoaderData.post2(
					`${this.fetchUrl}?type=site101-order-sat`,
					data,
					this.rootStore?.apiHost,
					this.rootStore?.ordersRequestTimeout
				);

				this.rootStore.commonViewModel.changeDontShowExitPopup(true);
				if (json?.ok === 1) {
					success = true;
				} else {
					failCounter++;

					captureMessage("sendOrderSAT fail", {
						extra: {
							name: "sendOrderSAT",
							order: data,
							response: json,
							try: failCounter,
						},
						level: "warning",
					});
				}
			} catch (e) {
				failCounter++;

				captureException(e, {
					tags: {
						"error.type": "order_send",
					},
					extra: {
						name: "sendOrderSAT",
						order: data,
						fails: failCounter,
					},
					level: "error",
				});
			}
		}
	};

	@action
	counterIncrement() {
		let isIncremented = "";
		if (window.sessionStorage) {
			isIncremented = window.sessionStorage.getItem("isIncremented");
		}
		if (!isIncremented) {
			if (window.sessionStorage) {
				window.sessionStorage.setItem("isIncremented", 1);
			}
			const client = this.rootStore.apolloTransportLayer;
			client.mutate({
				mutation: MUTATE_SITECONFIG_COUNTER,
			});
		}
	}

	@action
	setTariffListRef(ref) {
		this.tariffListRef = ref;
	}

	@action
	setButtonTagRef(ref) {
		this.buttonTagRef = ref;
	}
}
