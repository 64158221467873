import React from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import classNames from "classnames";

const formLayoutStyle = (theme) => ({
	root: {
		width: "100%",
		borderRadius: "10px",
		backgroundColor: "#ffffff",
		border: "solid 1px #d3cee1",
		padding: "48px 28px 22px",
		display: "flex",
		WebkitFlexDirection: "column",
		MsFlexDirection: "column",
		flexDirection: "column",
		WebkitAlignItems: "center",
		MsFlexAlign: "center",
		alignItems: "center",
		overflow: "hidden",
		height: ({ height }) => (height ? `${height}px` : ""),
	},
	gradient: {
		backgroundImage: "linear-gradient(295deg, #c86dd7, #5813fe)",
	},
	tariffView: {
		marginBottom: "30px",
	},
	tariffViewSuccess: {},
	homeOrder: {
		padding: "15px 28px 10px",
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			marginTop: "20px",
			padding: "50px 40px 57px",
			flexDirection: "row",
			alignItems: "flex-start",
			justifyContent: "space-between",
		},
		homeOrder: {
			flexDirection: "column",
			alignItems: "center",
			padding: "30px",
		},
		tariffView: {
			marginTop: 0,
		},
		tariffViewSuccess: {
			flexDirection: "column",
			alignItems: "center",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			marginBottom: "20px",
			//	marginTop: "27px",
		},
		tariffView: {
			marginTop: 0,
			padding: "24px",
		},
	},
});

const FormLayout = React.forwardRef((props, ref) => {
	const { classes, children, tariffView, isSuccess, homeOrder, useGradient } = props;

	return (
		<form
			ref={ref}
			className={classNames(classes.root, {
				[classes.tariffView]: tariffView,
				[classes.tariffViewSuccess]: isSuccess === true,
				[classes.homeOrder]: homeOrder,
				[classes.gradient]: useGradient,
			})}
		>
			{children}
		</form>
	);
});

FormLayout.propTypes = {
	children: PropTypes.node,
};

const StyledFormLayout = withStyles(formLayoutStyle)(FormLayout);

export default StyledFormLayout;
