import React from "react";
import { JssProvider, jss } from "react-jss";
import { jssPreset } from "../jssPreset/jssPreset";

jss.setup(jssPreset());

export default ({
	children,
	disableStylesGeneration = false,
	generateId,
	classNamePrefix,
	registry,
}) => {
	return (
		<JssProvider
			jss={jss}
			disableStylesGeneration={disableStylesGeneration}
			generateId={generateId}
			classNamePrefix={classNamePrefix}
			registry={registry}
		>
			{children}
		</JssProvider>
	);
};
