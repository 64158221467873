import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withDefaultParams from "Services/withDefaultParams";
import { Phone } from "ComponentsUI/Phones";
import { withPhone } from "HOC/WithPhone";
import { isMobilePage } from "Utils/routeCheck";

const PurplePhoneCode = ({ code, phone }) => {
	return (
		<span
			style={{
				fontFamily: "MuseoSansCyrl-700",
				fontSize: "22px",
				lineHeight: "1.09",
				letterSpacing: "-0.6px",
			}}
		>
			<span style={{ color: "#4400fd" }} key={0}>
				{code}{" "}
			</span>
			<span style={{ color: "#2b1d5d" }} key={1}>
				{phone}
			</span>
		</span>
	);
};

@withRouter
@withDefaultParams
@withPhone
@inject("ApplicationModel")
@observer
export default class PhoneContainer extends React.Component {
	static defaultProps = {
		onClick: () => {},
	};

	handleClick = (e) => {
		this.props.onClickPhone?.();
		this.props.onClick(e);
	};

	render() {
		let { siteRegionPhones, isPurple, phone404 } = this.props;

		let code;
		let phone;
		let insinePhone;
		let formatPhone;

		if (phone404) {
			insinePhone = "+78001008406";
			code = "+7 800";
			phone = "100-84-06";
			formatPhone = "+7 (800) 100-84-06";
			return (
				<Phone
					insinePhone={insinePhone}
					onClick={this.handleClick}
					phone={formatPhone}
					big={this.props.big}
					className={this.props.roistatClass}
				>
					{isPurple ? (
						<PurplePhoneCode code={code} phone={phone} />
					) : (
						<span>
							<span className="_phone">{formatPhone}</span>
						</span>
					)}
				</Phone>
			);
		}

		if (!this.props.siteRegionPhones) {
			return null;
		}

		if (isMobilePage(this.props?.match?.path)) {
			if (!siteRegionPhones?.operators?.phone) {
				return null;
			}

			code = `+7 ${siteRegionPhones?.operators?.code}`;
			phone = siteRegionPhones?.operators?.phone;
			insinePhone = `+7${
				siteRegionPhones?.operators?.code
			}${siteRegionPhones?.operators?.phone.replace(/-/g, "")}`;
			formatPhone = `+7 (${siteRegionPhones?.operators?.code}) ${phone}`;
		} else {
			if (!siteRegionPhones.phone) {
				return null;
			}

			code = `+7 ${siteRegionPhones.code}`;
			phone = siteRegionPhones.phone;
			insinePhone = `+7${siteRegionPhones.code}${siteRegionPhones.phone.replace(/-/g, "")}`;
			formatPhone = `+7 (${siteRegionPhones.code}) ${siteRegionPhones.phone}`;
		}

		return (
			<Phone
				insinePhone={insinePhone}
				onClick={this.handleClick}
				phone={formatPhone}
				big={this.props.big}
				className={this.props.roistatClass}
			>
				{isPurple ? (
					<PurplePhoneCode code={code} phone={phone} />
				) : (
					<span>
						{/*{!this.props.withoutIcon && (*/}
						{/*<span className="icon24 icon-call" />*/}
						{/*)}*/}
						<span className="_phone">{formatPhone}</span>
					</span>
				)}
			</Phone>
		);
	}
}
