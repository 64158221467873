import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import classNames from "classnames";

const styles = (theme) => {
	return {
		root: {
			color: theme.palette.colors.darkSlateBlue2,
			fontFamily: theme.fonts.bold,
			fontSize: "16px",
		},
		big: {
			fontSize: "22px",
		},
	};
};

const Phone = ({ classes, children, phone, insinePhone, onClick, className, big }) => {
	if (!insinePhone) {
		insinePhone = phone;
	}
	return (
		<a
			href={`tel:${insinePhone}`}
			onClick={onClick}
			className={classNames(classes.root, className, {
				[classes.big]: big,
			})}
		>
			{children ? children : phone}
		</a>
	);
};

Phone.defaultProps = {
	onClick: () => {},
	big: false,
	insinePhone: "",
};

Phone.propTypes = {
	phone: PropTypes.string.isRequired,
	insinePhone: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.node,
		PropTypes.element,
	]),
	big: PropTypes.bool,
	className: PropTypes.string,
};

export default withStyles(styles)(Phone);
