import { observable, action, runInAction, toJS } from "mobx";

import SortingModel from "./viewsModels/SortingModel";

// require("es6-promise").polyfill();
// import "whatwg-fetch";

import LoaderData from "Services/LoaderData";

export default class RatesModel {
	fetchUrl = "/ratesMobile";
	lastFetchAddressId = null;
	sortAvailable = [
		{
			key: "internet.speed_in",
			name: "скорости",
			order: false,
		},
		{
			key: "price.license_fee_month",
			name: "по цене",
			order: true,
		},
		{
			key: "provider.rating.expert.final",
			name: "по рейтингу",
			order: false,
		},
	];

	queryServicesAdapter = {
		1: "1,!2",
		2: "!1,2",
		"1,2": "1,2",
		"2,1": "1,2",
	};
	// @observable rootStore = null;
	@observable tariffs = [];
	@observable load = false;
	@observable orientation = true;
	@observable tariffsOrientation = false;
	@observable reviewsOrientation = true;
	@observable sortingModel = {};
	@observable currentAddress = {};

	@observable filterAvailable = [];
	providerId = null;

	filter = {
		dody_id: "",
		is_exclusive: "",
		provider_id: "",
		service_id: "",
		price: "",
		speed: "",
	};

	@observable
	sort = {
		item: this.sortAvailable[1],
		up: this.sortAvailable[1].order,
	};

	// constructor(currentRegion, currentAddress, providerId) {
	constructor(rootStore) {
		this.rootStore = rootStore;
		// this.currentRegion = currentRegion;
		// this.currentAddress = currentAddress;
		// this.providerId = providerId;

		this.sortingModel = new SortingModel({
			sortAvailable: this.sortAvailable,
			sort: this.sort,
		});
	}

	@action
	setData(address) {
		// this.currentRegion = region;
		this.currentAddress = address;
	}

	@action
	saveTariffs(data) {
		this.tariffs.replace(data);
		this.finishLoad();
	}

	@action
	saveFilters(data) {
		let address = this.currentAddress;
		if (address) {
			this.lastFetchAddressId = address.id;
		}

		// if (this.address) {
		//     this.lastFetchAddressId = this.address.house.id;
		// }

		let exclusiveFilter = {
			key: "is_exclusive_my",
			values: [
				{
					name: "Все тарифы",
					value: "",
				},
				{
					name: "Только эксклюзивные",
					value: "1",
				},
			],
		};
		data.push(exclusiveFilter);
		this.filterAvailable = data;
	}

	@action
	changeSort(sort) {
		this.sort = sort;
	}

	@action
	startLoad() {
		this.load = false;
	}

	@action
	finishLoad() {
		this.load = true;
	}

	@action
	setOrientation(value) {
		this.orientation = value;
	}

	@action
	setTariffsOrientation(value) {
		this.tariffsOrientation = value;
	}

	@action
	setReviewsOrientation(value) {
		this.reviewsOrientation = value;
	}

	changeFilter(filter) {
		if (Array.isArray(filter.items)) {
			this.filter[filter.type] = filter.items.map((elem) => elem.value).join();
		} else {
			this.filter[filter.type] = filter.items.value;
		}

		this.filter.service_id = this.filter.service_id.length
			? this.queryServicesAdapter[this.filter.service_id]
			: "";
		this.loadTariffs(this.filter);
	}

	// @computed
	// get region() {
	//     return this.currentRegion();
	// }

	// @computed
	// get address() {
	//     return this.currentAddress();
	// }

	loadTariffs(data) {
		let address = this.currentAddress;
		let params = {
			...data,
			fields: `connection,price,internet,tv,dody,exclusive,after,provider`,
			house_id: address ? address.id : "",
		};

		if (this.providerId) {
			params.provider_id = this.providerId;
		}
		LoaderData.get(this.fetchUrl, params).then((json) =>
			runInAction(() => {
				this.saveTariffs(json.data);
				if (address) {
					if (address.id != this.lastFetchAddressId) {
						this.saveFilters(json.filters);
					}
				}
				if (this.providerId) {
					this.saveFilters(json.filters);
				}
			})
		);
	}

	getTariffs() {
		if (this.load) {
			return this.tariffs;
		} else {
			return [];
		}
	}

	getActiveSort() {
		return toJS(this.sort);
	}

	getFilters() {
		return toJS(this.filterAvailable);
	}

	getSortAvailable() {
		return this.sortAvailable;
	}
}
