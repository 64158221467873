var gql = require("@apollo/client").gql;

const REGION_QUERY = gql`
	query Region($filter: String!) {
		region(filter: $filter) {
			id
			name
			url
			tags {
				mobile {
					name
					translit
				}
				sim {
					name
					translit
				}
				beauty {
					name
					translit
				}
			}
		}
	}
`;

export default REGION_QUERY;
