import React from "react";
import withStyles from "react-jss";
import { replaceStaticHost } from "Helpers/ImageManager";
import { ContactsV2 } from "Layouts/TariffFormLayout";

const tariffInfoStyle = (theme) => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		marginBottom: "27px",
	},
	wrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	tariffName: {
		marginBottom: "10px",
		fontFamily: theme.fonts.bold,
		fontSize: "16px",
		lineHeight: "2",
		textAlign: "center",
		textTransform: "uppercase",
		color: theme.palette.colors.darkSlateBlue,
		letterSpacing: "2px",
	},
	providerWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		minWidth: "110px",
	},
	logo: {
		width: "100px",
		maxWidth: "100%",
		marginBottom: "10px",
	},
});

const TariffInfoV2 = withStyles(tariffInfoStyle)(({
	classes,
	tariff,
	provider,
	text,
	phone,
	urlLink,
	setEvent,
}) => {
	let logo = provider.logo || provider.logo_2;
	return (
		<div className={classes.root}>
			<span className={classes.tariffName}>«{tariff.name}»</span>
			<div className={classes.wrapper}>
				<div className={classes.providerWrapper}>
					<img className={classes.logo} alt={provider.name} src={replaceStaticHost(logo)} />
				</div>
				<ContactsV2
					setEvent={setEvent}
					urlLink={urlLink}
					headerName={text.PHONE}
					showName={text.show_phone}
					provider={provider}
					phone={phone}
					popupTariffOrder={true}
				/>
			</div>
		</div>
	);
});

export default TariffInfoV2;
