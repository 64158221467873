import { observable, action } from "mobx";
import SearcheModel from "./../SearcheModelB";

export default class TenderAddresesModel {
	@observable seacheModels = [];

	constructor(rootModel) {
		this.rootModel = rootModel;

		if (rootModel.searcheModel) {
			this.seacheModels.push(rootModel.searcheModel);
		} else {
			this.seacheModels.push(new SearcheModel(this.rootModel));
		}
	}

	@action
	reset() {
		this.seacheModels = [];
	}

	@action
	addSearcheModel() {
		let newSearcheModel = new SearcheModel(this.rootModel);
		this.seacheModels.push(newSearcheModel);
	}

	@action
	removeSearcheModel(i) {
		this.seacheModels.splice(i, 1);
	}

	@action
	initSearcheModelByData(index, streetData, addressData) {
		this.seacheModels[index].setCurrentStreet(streetData, true);
		this.seacheModels[index].setCurrentAddress(addressData, true);
	}
}
