import React, { useState } from "react";
import withStyles from "react-jss";
import Cookies from "js-cookie";
import Button from "ComponentsUI/Button";
import { TextField } from "ComponentsUI/TextField";
import TermsOfUse from "ComponentsUI/TermsOfUse";
import { sendMetriks } from "Services/sendMetriks";
import ABTestWrapperAudio from "Containers/ABTests/ABTestWrapperAudio";
import LazyImage from "ComponentsUI/Image";

const styles = (theme) => {
	return {
		root: {
			width: "100%",
			minHeight: "250px",
			display: "flex",
			justifyContent: "center",
		},
		body: {
			width: "auto",
			height: "100%",
			border: `1px solid ${theme.palette.colors.disabled}`,
			borderRadius: "10px",
			padding: "20px 0",
		},
		content: {
			width: "1100px",
			height: "auto",
			margin: "30px 40px",
			display: "flex",
			alignItems: "center",
		},
		img: {
			width: "190px",
			height: "190px",
			margin: "0px 30px 0px 0px",
		},
		info: {
			width: "100%",
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-around",
		},
		box: {},
		textH1: {
			fontFamily: theme.fonts.medium,
			fontSize: "24px",
			color: theme.palette.colors.white,
			marginBottom: "5px",
		},
		text: {
			fontFamily: theme.fonts.light,
			fontSize: "18px",
			color: theme.palette.colors.white,
			marginBottom: "15px",
			lineHeight: "1.2",
		},
		form: { display: "flex" },
		input: {},
		textField: {
			marginRight: "20px",
			flex: 1,
		},
		button: {
			minWidth: "320px",
		},
		buttonDesebled: {},
		footerText: {
			fontFamily: theme.fonts.light,
			fontSize: "14px",
			color: theme.palette.colors.white,
		},
		footerTextSend: {
			fontFamily: theme.fonts.light,
			fontSize: "18px",
			color: theme.palette.colors.yellow,
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			root: {
				minHeight: "260px",
			},
			body: {
				margin: "0 auto",
				padding: "0",
				maxWidth: "940px",
			},
			content: {
				width: "860px",
				alignItems: "center",
			},
			img: {
				width: "200px",
				height: "200px",
				margin: "0px 20px 0px 0px",
			},
			info: {
				maxWidth: "640px",
			},
			button: {
				minWidth: "257px",
				maxWidth: "430px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				minHeight: "290px",
			},
			body: {
				margin: "0 auto",
				padding: "0",
				maxWidth: "710px",
			},
			info: {
				maxWidth: "420px",
				justifyContent: "flex-start",
			},
			form: {
				flexDirection: "column",
			},
			textField: {
				width: "auto",
				marginRight: "0px",
				marginBottom: "10px",
				height: "40px",
			},
			button: {
				width: "auto",
			},
			text: {
				fontFamily: theme.fonts.light,
				fontSize: "16px",
				color: theme.palette.colors.white,
				maxWidth: "540px",
				marginBottom: "10px",
			},
			footerText: {},
			content: {
				alignItems: "center",
				margin: "30px 20px",
			},
			footerTextSend: {
				fontSize: "16px",
				marginTop: "10px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				width: "100%",
			},
			body: {
				width: "100%",
			},
			content: {
				flexDirection: "column",
				width: "auto",
				alignItems: "center",
				margin: "20px 25px",
			},
			form: {
				flexDirection: "column",
			},
			textField: {
				width: "auto",
				marginRight: "0px",
				marginBottom: "10px",
			},
			button: {
				minWidth: "auto",
			},
			img: {
				width: "120px",
				height: "120px",
				margin: "0px 0px 0px 0px",
			},
			text: {
				fontSize: "14px",
				marginBottom: "10px",
			},
			textH1: {
				fontSize: "18px",
			},
			footerText: {
				fontSize: "10px",
				marginTop: "10px",
			},
			footerTextSend: {
				fontSize: "14px",
				marginTop: "10px",
			},
		},
	};
};

const OneClickForm = (props) => {
	let { classes, form, phone, errorCounter, error, send, text, windowSizes, ABtest } = props;
	const [open, setOpen] = useState(false);
	const [click, setClick] = useState(false);

	if (form.enabled == "0") return null;

	if (!open) {
		sendMetriks("OPEN_FORM_1CLICK");
		setOpen(true);
	}

	function clickInput() {
		if (!click) {
			let testABVariant = Cookies.get("AB_testtest__Test5_zayavka_1_click_TS_audio");
			let testName = "No_name";
			if (Boolean(testABVariant)) {
				testName = JSON.parse(testABVariant)?.name || "No_name";
			}

			sendMetriks("CLICK_PHONE_1CLICK", [testName]);
			setClick(true);
		}
	}

	let styleBackground = {
		background: `linear-gradient(${form.background})`,
	};
	let styleButton = {
		backgroundColor: `${form.button_color}`,
		color: `${form.text_button_color}`,
		border: `1px solid transparent`,
	};
	let sizeInput = windowSizes.lg || windowSizes.md ? "medium" : "smaller";
	let sizeButton = windowSizes.lg || windowSizes.md ? "middle" : "low";

	const saveUserInfo = () => {};

	const changeComplete = () => {};

	return (
		<div id="OneClickForm" className={classes.root}>
			<div style={styleBackground} className={classes.body}>
				<div className={classes.content}>
					<LazyImage src={form.image} className={classes.img} alt={"Фото формы в один клик"} />
					<div className={classes.info}>
						<div className={classes.box}>
							<div className={classes.textH1}>{form.heading_text}</div>
							<div className={classes.text}>{form.main_text}</div>
							{ABtest && (
								<ABTestWrapperAudio
									testName={"testtest__Test5_zayavka_1_click_TS_audio"}
									ABtest={true}
									functionUtils={{ saveUserInfo }}
									defaultContainer={null}
									changeComplete={changeComplete}
								/>
							)}
							<div className={classes.form}>
								<div onClick={() => clickInput()} className={classes.textField}>
									<TextField
										autocomplete={"tel"}
										name={"tel"}
										placeholder={"Номер телефона"}
										type={"phone"}
										formatting={"phone"}
										id={"fix_callback_phone"}
										datatest={"callback"}
										width={"100%"}
										onChange={(e) => props.onChangePhone(e)}
										errorText={null}
										value={phone}
										errorCounter={errorCounter}
										searchWithPhone={true}
										autoFocus={false}
										onEnter={() => props.onPressEnter()}
										isError={error}
										size={sizeInput}
									/>
								</div>
								<div className={classes.button}>
									<Button
										onClick={props.onPressEnter}
										fullWidth={true}
										datatest={"waitingCall_button"}
										size={sizeButton}
										typeText={"onClick"}
										typefontFamily={"medium"}
										disabled={send}
										style={send ? null : styleButton}
									>
										{form.text_button}
									</Button>
								</div>
							</div>
						</div>
						{send ? (
							<div className={classes.footerTextSend}>{text.orderOnClickText}</div>
						) : (
							<div className={classes.footerText}>
								<TermsOfUse buttonText={form.text_button} isOneClick={true} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles)(OneClickForm);
