import parse from "url-parse";
let staticLocation = "";
let webpAllowed = null;

export const setStaticLocation = (loc) => {
	staticLocation = loc;
};

export const getStaticLocation = () => {
	return staticLocation;
};

export const replaceStaticHost = (url) => {
	//const urlData = parse(url);
	//urlData.set("hostname", getStaticLocation());
	//return `${urlData.hostname}/${urlData.pathname}`;
	return url;
};

export const webpSimpleTransformer = (url) => {
	return url;
	let urlParts = url.split(".");
	urlParts.splice(-1, 1);
	return urlParts.join(".") + ".webp";
};

export const webpTransformer = (url) => {
	return url;
	function webpCanBeUsed() {
		var elem = document.createElement("canvas");

		if (!!(elem.getContext && elem.getContext("2d"))) {
			// was able or not to get WebP representation
			return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
		}

		// very old browser like IE 8, canvas not supported
		return false;
	}

	if (webpAllowed === null) {
		if (typeof window !== "undefined") {
			webpAllowed = webpCanBeUsed();
		}
	}

	if (!!webpAllowed) {
		let urlParts = url.split(".");
		urlParts.splice(-1, 1);
		return urlParts.join(".") + ".webp";
	}

	return url;
};

export const webpCanBeUsed = () => {
	var elem = document.createElement("canvas");

	if (!!(elem.getContext && elem.getContext("2d"))) {
		// was able or not to get WebP representation
		return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
	}

	// very old browser like IE 8, canvas not supported
	return false;
};
