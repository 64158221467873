import React from "react";

export default class StylesForButtons extends React.Component {
	static greenPoints(active) {
		return {
			border: 1,
			borderStyle: "solid",
			borderColor: active ? "transparent" : "#c0bbd2",
			borderRadius: "50%",
			background: active ? "#ccff00" : "transparent",
			marginRight: 10,
			color: "pink",
			cursor: "pointer",
			padding: 5,
			outline: 0,
			fontSize: 24,
		};
	}

	static smallGreenPoints(active, last) {
		return {
			border: 1,
			borderStyle: "solid",
			borderColor: active ? "transparent" : "#c0bbd2",
			borderRadius: "50%",
			background: active ? "#ccff00" : "transparent",
			marginRight: !last ? 7 : 0,
			color: "pink",
			cursor: "pointer",
			padding: 3,
			outline: 0,
			fontSize: 24,
		};
	}

	static greyPointsTariffs(active, last) {
		return {
			position: "relative",
			top: "25px",
			border: 1,
			borderStyle: "solid",
			borderColor: active ? "transparent" : "#c0bbd2",
			borderRadius: "50%",
			background: active ? "#c0bbd2" : "transparent",
			marginRight: !last ? 7 : 0,
			color: "pink",
			cursor: "pointer",
			padding: 3,
			outline: 0,
			fontSize: 24,
		};
	}

	static greyPoints(active, last) {
		return {
			border: 1,
			borderStyle: "solid",
			borderColor: active ? "transparent" : "#c0bbd2",
			borderRadius: "50%",
			background: active ? "#c0bbd2" : "transparent",
			marginRight: !last ? 7 : 0,
			color: "pink",
			cursor: "pointer",
			padding: 3,
			outline: 0,
			fontSize: 24,
		};
	}

	static bigWhitePoints(active, last) {
		return {
			border: 1,
			borderStyle: "solid",
			borderColor: active ? "transparent" : "transparent",
			borderRadius: "50%",
			background: active ? "white" : "rgba(255, 255, 255, 0.2)",
			marginRight: !last ? 20 : 0,
			color: "pink",
			cursor: "pointer",
			padding: 5,
			outline: 0,
			fontSize: 24,
		};
	}
}
