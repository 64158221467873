import React, { useState, useEffect, useCallback } from "react";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import configRouters from "Configs/routes";
import { sendMetriks } from "Services/sendMetriks";
import { addAddressData, addABData, addRefererData } from "Utils";
import userInfo from "Services/UserInfo";
import OneClickForm from "ComponentsUI/Form/OneClickForm";
import { PaddingBottom } from "ComponentsUI/Spacings";
import Cookies from "js-cookie";

const Type = {
	"/:regionUrl?": "main",
	"/:regionUrl?/podklyuchit-internet": "main",
	"/:regionUrl?/reviews/:page([0-9]+)?": "reviews",
	"/:regionUrl?/doma-nzl/:page([0-9]+)?": "TC",
	"/:regionUrl?/dom/:house_url-id:house_id/:page([0-9]+)?": "from_popular_house",
	"/:regionUrl?/orders/tohome": "tohome",
	"/:regionUrl?/exclusive": "exclusive",
	"/:regionUrl?/rating": "rating",
	"/:regionUrl?/providers/:page([0-9]+)?": "catalog",
};

const OneClick = (props) => {
	const [seen, setSeen] = useState(false);
	const [send, setSend] = useState(false);
	const [errorCounter, setErrorCounter] = useState(0);
	const [error, setError] = useState(false);
	const [phone, setPhone] = useState("");

	const {
		ApplicationModel: {
			siteConfig,
			sendingStore,
			currentRegion,
			windowSizeModel: { windowSizes },
			text,
			popupModel,
		},
		match: { path },
	} = props;

	const page = Type[path];

	const scrollToElement = useCallback(() => {
		let elementTarget = document.getElementById("OneClickForm");
		if (elementTarget) {
			if (!seen && window.scrollY > elementTarget.offsetTop - elementTarget.offsetHeight) {
				window.removeEventListener("scroll", scrollToElement);
				sendMetriks("SCROLL_TO_1CLICK");
				setSeen(() => true);
			}
		}
	}, [seen]);

	useEffect(() => {
		if (!seen) window.addEventListener("scroll", scrollToElement);
		return () => {
			window.removeEventListener("scroll", scrollToElement);
		};
	}, [scrollToElement, seen]);

	if (!page) return null;

	const dataForm = siteConfig?.settings?.form_orders_one_click
		? siteConfig.settings.form_orders_one_click
		: null;
	if (!dataForm) return null;

	const form = dataForm[page];

	if (!form) return null;

	const goToRates = async () => {
		let regionId = currentRegion?.id || null;
		let data = {
			phone_connection: phone,
			region_id: regionId,
			lead_form_type: userInfo("one_click", page),
		};

		if (props.providerId) {
			data.provider_id = props.providerId;
		}
		data = addAddressData(data, props.ApplicationModel);
		data = addABData(data);
		data = addRefererData(data, props.ApplicationModel);

		let infoUser = {
			name_popup: "OneClick",
		};
		popupModel.setInfoUser(infoUser);

		let errorSending = await sendingStore.onCallback(data);
		if (!errorSending) {
			setSend(true);
		}
	};

	const deleteError = () => {
		setErrorCounter(0);
		setError(false);
	};

	const onChangePhone = (e) => {
		let {
			ApplicationModel: { sendingStore },
		} = props;
		if (phone.length > 9 && configRouters.RatesInHouse.route === path) {
			Cookies.set("connectTariffFrom", "from_popular_house");
		}

		deleteError();
		sendingStore.setPhone(e.target.value);
		setPhone(e.target.value);
	};

	const onClickButton = () => {
		if (phone.length != 11) {
			setError(true);
			setErrorCounter((errorCounter) => errorCounter + 1);
			return;
		}

		let testABVariant = Cookies.get("AB_testtest__Test5_zayavka_1_click_TS_audio");
		let testName = "No_name";
		if (Boolean(testABVariant)) {
			testName = JSON.parse(testABVariant)?.name || "No_name";
		}

		sendMetriks("LEAD_ALL");
		sendMetriks("SEND_1CLICK", [testName]);
		goToRates();
	};

	const onPressEnter = () => {
		if (!send) onClickButton();
	};

	return (
		<PaddingBottom>
			<OneClickForm
				form={form}
				onChangePhone={onChangePhone}
				phone={phone}
				onPressEnter={onPressEnter}
				errorCounter={errorCounter}
				error={error}
				send={send}
				text={text}
				windowSizes={windowSizes}
				ABtest={page === "TC"}
			></OneClickForm>
		</PaddingBottom>
	);
};
export default withRouter(inject("ApplicationModel")(OneClick));
