import React from "react";
import classNames from "classnames";
import Col from "reactstrap/lib/Col";

export default ({ className, lgHidden, mdHidden, smHidden, xsHidden, ...rest }) => (
	<Col
		{...rest}
		className={classNames(className, {
			onlyNotLg: lgHidden,
			onlyNotMd: mdHidden,
			onlyNotSm: smHidden,
			onlyNotXs: xsHidden,
		})}
	/>
);
