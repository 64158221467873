import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";
import { observer, inject } from "mobx-react";

const styles = (theme) => {
	return {
		root: {
			display: "none",
			position: "fixed",
			padding: 0,
			margin: 0,
			top: 0,
			left: 0,
			overflowX: "hidden",
			overflowY: "auto",
			overflowScrolling: "touch",
			"-webkit-overflow-scrolling": "touch",
			border: "none",
			borderRadius: 0,
			zIndex: "115",
			width: "100%",
			height: "100%",
		},
		content: {
			position: "absolute",
			padding: 0,
			margin: 0,
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			overflowX: "hidden",
			overflowY: "hidden",
			zIndex: "10000",
			overflowScrolling: "touch",
			"-webkit-overflow-scrolling": "touch",
		},
		isOpenExit: {
			display: "block",
		},
	};
};

@inject("ApplicationModel")
@observer
class OurModalExit extends React.Component {
	static counter = 0;

	static forOpenedModalBody() {
		OurModalExit.counter++;
		document.querySelector("html").classList.add("_modalExit-open");
	}

	static forClosedModalBody() {
		OurModalExit.counter--;
		if (OurModalExit.counter <= 0) {
			document.querySelector("html").classList.remove("_modalExit-open");
			OurModalExit.counter = 0;
		}
	}

	static scrollToTop(duration, selector = ".ourModalExit__content", endYParam = 0) {
		let element = document.querySelector(selector);
		let endX = 0,
			endY = endYParam;
		var startX = element.scrollX || element.pageXOffset,
			startY = element.scrollY || element.scrollTop,
			distanceX = endX - startX,
			distanceY = endY - startY,
			startTime = new Date().getTime();

		var easeInOutQuart = function (time, from, distance, duration) {
			if ((time /= duration / 2) < 1) return (distance / 2) * time * time * time * time + from;
			return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
		};

		var timer = setInterval(function () {
			var time = new Date().getTime() - startTime,
				newX = easeInOutQuart(time, startX, distanceX, duration),
				newY = easeInOutQuart(time, startY, distanceY, duration);
			if (time >= duration) {
				clearInterval(timer);
			}
			element.scrollTo(newX, newY);
		}, 1000 / 60);
	}

	render() {
		let modalModel = this.props.ApplicationModel.modalModel;
		let classes = this.props.classes;

		return (
			<div
				className={classNames(classes.root, {
					[classes.isOpenExit]: modalModel.isOpenExit,
				})}
			>
				<div className={classNames(classes.content, "ourModalExit__content")}>
					{modalModel.contentExit}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(OurModalExit);
