import React from "react";
import classNames from "classnames";
import { generateTime } from "Services/Time";
import { EmptyStub } from "ComponentsUI/EmptyStub";
import { Col } from "ComponentsUI/Styles/Grid";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import withStyles from "react-jss";
import { BottomPoints, StylesForButtons } from "ComponentsUI/CarouselComponents";
import ReviewItemContainer from "Containers/ReviewItemContainer";
import { ReviewItemStatus, ReviewItemWrapper } from "ComponentsUI/Reviews";
import { inject, observer } from "mobx-react";
import { AdverBlockContainer } from "Containers/AdBlocksContainer";
import { generateUrl } from "Services/withDefaultParams";
import { withRouter } from "react-router-dom";
import Tariffs from "../../../routes/provider/routes/tarifficationList/containers/Tariffs";
import Button from "ComponentsUI/Button";
import { NoContainer } from "ComponentsUI/Spacings";

var Routes = require("Configs/routes");

var Carousel = require("nuka-carousel");

const STATUS_MODERATION = "moderation"; //Отзыв проверяется
const STATUS_CHECKED = "checked"; //Отзыв проверен - отзыв принят, но оценка не засчитана (отклонён)
const STATUS_COUNTED = "counted"; //Отзыв засчитан - отзыв принят и оценка засчитана
const STATUS_PENDING = "pending"; //Отзыв в ожидании
const styles = (theme) => {
	return {
		root: {
			marginBottom: "-40px",
			"& .adverBlock": {
				marginBottom: "66px",
			},
		},
		headerWrapper: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			// lineHeight: "0.71",
			marginTop: "15px",
			marginBottom: "15px",
			fontSize: "18px",
			fontFamily: theme.fonts.bold,
		},
		rootWithCarousel: {},
		withoutCarousel: {},
		withCarousel: {
			display: "none",
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				marginBottom: "0",
			},
			headerWrapper: {
				fontSize: "15px",
			},
			withoutCarousel: {},
			withCarousel: {},
			rootWithCarousel: {
				marginBottom: "20px",
				"& .slider-decorator-0": {
					bottom: "-19px !important",
				},
				"& $withoutCarousel": {
					display: "none",
				},
				"& $withCarousel": {
					display: "block",
				},
			},
		},
	};
};

const RowWrapper = withStyles(styles)(({ children, classes }) => {
	const className = classNames(classes.headerWrapper);
	return <Row className={className}>{children}</Row>;
});

@inject("ApplicationModel")
@observer
class ReviewsLayout extends React.Component {
	static defaultProps = {
		items: [],
		useCarusel: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			heightSlide: 300,
		};
	}

	reviewTypes = [
		{ type: `negative`, max: 2.4 },
		{ type: `neutral`, max: 3.9 },
		{ type: `positive`, max: 5 },
	];
	//paid

	componentDidMount() {
		this.calculateHeightOfCarousel();
	}

	calculateHeightOfCarousel = () => {
		if (this.carousel) {
			this.carousel.setDimensions();
		}
	};

	render() {
		let decorator = [
			{
				component: (props) => (
					<BottomPoints {...props} getButtonStyles={StylesForButtons.greyPoints} />
				),
				position: "BottomCenter",
			},
		];
		let titleText = this.props.titleText;
		let text = this.props.ApplicationModel.text;
		let textHeaders;
		if (titleText) {
			textHeaders =
				text.menuExclusives + " " + titleText.provider + " в " + titleText.region.prepositional;
		}
		if (!this.props.items.length) {
			return (
				<Container>
					<Row>
						<Col
							lg={{ size: 4, offset: 4 }}
							md={{ size: 4, offset: 4 }}
							sm={{ size: 4, offset: 4 }}
							xs={{ size: 6, offset: 3 }}
						>
							<EmptyStub
								header={text.REVIEWS_EMPTY_STUB_HEADER}
								text={text.REVIEWS_EMPTY_STUB_TEXT}
								icon={
									<span
										className="icon48 icon-balance"
										style={{
											fontSize: "48px",
											color: "#5812fe",
										}}
									/>
								}
								button={
									<Button typeText={"small"} size={"low"} status={"withoutAccent"}>
										>
										<span
											className="icon48 icon-playlist-add"
											style={{
												fontSize: "24px",
												color: "#d3cee1",
											}}
										/>
										{text.WRITE_REVIEW}
									</Button>
								}
							/>
						</Col>
					</Row>
				</Container>
			);
		}

		let reviewList = this.props.items.map((elem, i) => {
			if (this.props.stub) {
				return (
					<Col xs="12" sm="6" md="4" lg="3" key={i}>
						<ReviewItemContainer stub={this.props.stub} textConst={text} />
					</Col>
				);
			}
			let urlToProvider =
				!this.props.stub && !!elem.provider.url_name
					? generateUrl(Routes.ProviderRates.route, {
							regionUrl: this.props.match.params.regionUrl,
							providerName: elem.provider.url_name,
						})
					: "/";

			return (
				<Col xs="12" sm="6" md="4" lg="3" key={elem.id}>
					<ReviewItemContainer
						type={elem.rating_type}
						stub={this.props.stub}
						name={elem.author.id ? elem.author.name : null}
						owner={elem.provider.name}
						city={elem.region.name}
						item={elem}
						textConst={text}
						date={generateTime(elem.date)}
						counter={elem.views_all}
						text={elem.review}
						urlToProvider={urlToProvider}
						providerPhones={elem?.provider?.contacts?.phones}
						status={this.getStatus(elem)}
						markReviewAsViewed={(id) =>
							this.props.ApplicationModel.sendingStore.markReviewAsViewed(id)
						}
						withoutPaddings={this.props.withoutPaddings}
					/>
				</Col>
			);
		});

		let windowSizeModel = this.props.ApplicationModel.windowSizeModel;

		let insertAdBlockAfter = 8;
		if (windowSizeModel.windowSizes.md) insertAdBlockAfter = 6;
		// // Добавляем блок
		let adBlock2 = (
			<>
				{this.props.tariffsAction ? (
					<>
						<RowWrapper>{textHeaders}</RowWrapper>
						<Tariffs tariffs={this.props.tariffsAction} />
					</>
				) : null}
			</>
		);

		let adBlock = (
			<Col xs="12" key="adBlock">
				<NoContainer>
					<AdverBlockContainer
						providerId={this.props.providerId}
						lead_form_type={this.props.lead_form_type}
					/>
				</NoContainer>
			</Col>
		);

		if (
			this.props.adBlock2 &&
			!this.props.stub &&
			(!windowSizeModel.windowSizes.xs || !this.props.useCarusel)
		) {
			if (reviewList.length > insertAdBlockAfter) {
				reviewList.splice(insertAdBlockAfter / 2, 0, adBlock2);
			} else {
				reviewList.push(adBlock2);
			}
		}

		if (
			this.props.adBlock &&
			!this.props.stub &&
			(!windowSizeModel.windowSizes.xs || !this.props.useCarusel)
		) {
			if (reviewList.length > insertAdBlockAfter) {
				reviewList.splice(
					this.props.adBlock2 ? insertAdBlockAfter + 1 : insertAdBlockAfter,
					0,
					adBlock
				);
			} else {
				reviewList.push(adBlock);
			}
		}

		let classes = this.props.classes;

		return (
			<div
				className={classNames(classes.root, {
					[classes.rootWithCarousel]: this.props.useCarusel,
				})}
			>
				<div className={classes.withoutCarousel}>
					<Container>
						<Row>{reviewList}</Row>
					</Container>
				</div>
				<div className={classes.withCarousel}>
					<Carousel
						ref={(carousel) => (this.carousel = carousel)}
						wrapAround={true}
						decorators={decorator}
						needRecalculateHeight={true}
						heightMode="max"
					>
						{reviewList.map((o, key) => (
							<ReviewItemWrapper key={key}>{o}</ReviewItemWrapper>
						))}
					</Carousel>
				</div>
			</div>
		);
	}

	getStatus(elem) {
		if (elem.status.code === STATUS_COUNTED) {
			let text = this.props.ApplicationModel.text;
			return (
				<ReviewItemStatus
					svg={<span className="icon48 icon-bar-chart" />}
					text={text.REVIEW_STATUS_1}
				/>
			);
		}
		return null;
	}
}

export default withRouter(withStyles(styles)(ReviewsLayout));
