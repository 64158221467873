import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { generateUrl } from "Services/withDefaultParams";
import Routes from "Configs/routes";

const useStyle = createUseStyles((theme) => ({
	displayNone: {
		display: "none",
	},
	rowOffers: {},
	bodyRowOffers: {
		"&:last-child": {
			"& > div > div:first-child": {
				borderBottomLeftRadius: "10px",
			},
			"& > div > div:last-child": {
				borderBottomRightRadius: "10px",
			},
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		rowOffers: {
			"&:last-child": {
				marginBottom: 0,
			},
		},
	},
}));

const OfferLayout = (props) => {
	let { tariff, update_date, siteConfig, skipLayout } = props;
	const classes = useStyle();
	if (skipLayout) {
		return props.children;
	}
	let orderToHomeUrl = generateUrl(Routes.OrderHome.route, {
		regionUrl: tariff.region.url,
	});
	let href = `${siteConfig.ssl === true ? `https` : "http"}://${
		siteConfig.domain_name_full
	}${orderToHomeUrl}`;

	let date = update_date?.split(".")?.join("-");
	return (
		<div
			itemProp="offers"
			itemType="https://schema.org/Offer"
			itemScope
			className={classNames(classes.rowOffers, classes.bodyRowOffers)}
		>
			<link className={classes.displayNone} itemProp="url" href={href} />
			<meta itemProp="availability" content="https://schema.org/InStock" />
			<meta itemProp="priceCurrency" content="RUB" />
			<meta itemProp="price" content={tariff.displayPrice} />
			{date && <meta itemProp="priceValidUntil" content={date} />}
			{props.children}
		</div>
	);
};

export default OfferLayout;
