const styles = (theme) => ({
	root: {
		borderRadius: "10px",
	},
	isRating: {},
	table: {
		width: "100%",
	},
	thead: {},
	label: {
		fontSize: "12px",
		fontFamily: theme.fonts.medium,
		lineHeight: 0.71,
		letterSpacing: "2px",
		color: "#000000",
		position: "relative",
	},
	row: {
		position: "relative",
		display: "flex",
		alignItems: "stretch",
		"& > div": {
			border: `solid 1px ${theme.palette.colors.disabled}`,
		},
		"& > div:not(:first-child)": {
			borderLeft: 0,
		},
	},
	bodyRow: {
		"& > div": {
			borderTop: 0,
		},
	},
	headerRow: {
		"& > div:first-child": {
			borderTopLeftRadius: "10px",
		},
		"& > div:last-child": {
			borderTopRightRadius: "10px",
		},
	},
	header: {
		textAlign: "center",
		textTransform: "uppercase",
		lineHeight: 1,
		padding: "13px 0",
		backgroundColor: "#f5f5f5",
		flexShrink: 0,
	},
	commonInfoHeader: {
		width: "260px",
		maxWidth: "260px",
	},
	onlySM: {
		display: "none",
	},
	speedHeader: {
		width: "105px",
		maxWidth: "105px",
	},
	channelsHeader: {
		width: "140px",
		maxWidth: "140px",
	},
	mobileHeader: {
		width: "170px",
		maxWidth: "170px",
	},
	optionsHeader: {
		width: "205px",
		maxWidth: "205px",
	},
	connectHeader: {
		width: "300px",
		maxWidth: "300px",
	},
	baseTD: {
		padding: "16px 0",
		backgroundColor: theme.palette.colors.white,
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexShrink: 0,
	},
	providerNameHidden: {
		alignItems: "inherit",
	},
	baseTDWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		width: "100%",
	},
	infoWrapper: {
		display: "block",
	},
	providerNameHide: {
		display: "flex",
		flexDirection: "column",
		flex: "1 1 auto",
		overflow: "hidden",
	},
	imageWrapper: {
		position: "absolute",
		right: 0,
		top: "5px",
		width: "60px",
	},
	actionImageWrapper: {
		textAlign: "center",
	},
	exclusive: {
		position: "absolute",
		left: "0px",
		top: "0px",
		border: "none !important",
	},
	svgImage: {},
	nameWrapper: {
		fontSize: "14px",
		color: theme.palette.colors.darkSlateBlue,
		textAlign: "center",
		overflow: "hidden",
		fontFamily: theme.fonts.medium,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		margin: "18px 16px 0 16px",
	},
	nameTextWrap: {
		whiteSpace: "normal",
		margin: "9px 16px 0 16px",
	},
	providerNameWrapper: {
		fontSize: "14px",
		color: theme.palette.colors.darkSlateBlue,
		textAlign: "center",
		overflow: "hidden",
		fontFamily: theme.fonts.medium,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		margin: "0 16px",
		"& a": {
			color: theme.palette.colors.veryBlackGrey,
		},
	},
	providerDistrictsWrapper: {
		fontSize: "14px",
		color: theme.palette.colors.veryBlackGrey,
		textAlign: "center",
		overflow: "hidden",
		fontFamily: theme.fonts.medium,
		textOverflow: "ellipsis",
		width: "100%",
		padding: "0 16px",
		whiteSpace: "nowrap",
		// margin: "0 16px"
	},
	providerRegionNameWrapper: {
		fontSize: "14px",
		color: theme.palette.colors.veryBlackGrey,
		textAlign: "center",
		overflow: "hidden",
		fontFamily: theme.fonts.medium,
		textOverflow: "ellipsis",
		width: "100%",
		padding: "0 16px",
		whiteSpace: "nowrap",
	},
	name: {
		fontSize: "14px",
		fontFamily: theme.fonts.medium,
		lineHeight: 1.21,
		color: theme.palette.colors.oneMoreGrey,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		maxWidth: "120px",
	},
	hideCompare: {
		display: "none !important",
	},
	roundConnectBtn: {
		border: `1px solid ${theme.palette.colors.disabled} !important`,
		borderRadius: "10px !important",
		width: "210px !important",
	},
	connectButtonWrapper: {
		display: "flex",
		userSelect: "none",
	},
	compareButton: {
		width: "60px",
		height: "40px",
		borderTop: `1px solid ${theme.palette.colors.disabled}`,
		borderLeft: `1px solid ${theme.palette.colors.disabled}`,
		borderBottom: `1px solid ${theme.palette.colors.disabled}`,
		borderRadius: "10px 0 0 10px",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	compareButtonText: {
		display: "none",
	},
	compareButtonTextIsGrey: {},
	connectButton: {
		width: "150px",
		height: "40px",
		backgroundColor: theme.palette.colors.purplishBlue,
		borderTop: `1px solid ${theme.palette.colors.disabled}`,
		borderRight: `1px solid ${theme.palette.colors.disabled}`,
		borderBottom: `1px solid ${theme.palette.colors.disabled}`,
		borderRadius: "0 10px 10px 0",
		cursor: "pointer",
		"& span": {
			height: "100%",
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "14px",
			fontFamily: theme.fonts.medium,
			color: theme.palette.colors.white,
			letterSpacing: "2px",
			textTransform: "uppercase",
			paddingRight: "10px",
			textAlign: "center",
		},
	},
	connectWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		padding: " 0 10px",
	},
	priceWrapper: {
		marginRight: "2px",
	},
	icon: {
		width: "24px",
		height: "24px",

		"& path": {
			fill: theme.palette.colors.purplishBlue,
		},
	},
	iconIsGrey: {
		"& path": {
			fill: theme.palette.colors.cloudyBlue,
		},
	},
	addIcon: {
		marginTop: "7px",
	},
	notExistXs: {},
	hideXs: {},
	nameExist: {
		"& $imageWrapper": {
			display: "block",
		},
		"& $nameWrapper": {
			display: "block",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		commonInfoHeader: {
			width: "155px",
			maxWidth: "155px",
		},
		speedHeader: {
			width: "105px",
			maxWidth: "105px",
		},
		channelsHeader: {
			width: "120px",
			maxWidth: "120px",
		},
		mobileHeader: {
			width: "140px",
			maxWidth: "140px",
		},
		optionsHeader: {
			width: "180px",
			maxWidth: "180px",
		},
		connectHeader: {
			width: "240px",
			maxWidth: "240px",
		},
		imageWrapper: {
			marginLeft: 0,
		},
		nameWrapper: {},
		connectWrapper: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
		},
		priceWrapper: {
			marginBottom: "10px",
			marginRight: 0,
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		label: {
			fontSize: "10px",
		},
		commonInfoHeader: {
			width: "150px",
			maxWidth: "150px",
		},
		onlySM: {
			display: "block",
			marginTop: "12px",
		},
		speedHeader: {
			width: "95px",
			maxWidth: "95px",
		},
		channelsHeader: {
			width: "90px",
			maxWidth: "90px",
		},
		mobileHeader: {
			width: "95px",
			maxWidth: "95px",
		},
		optionsHeader: {
			width: "75px",
			maxWidth: "75px",
		},
		connectHeader: {
			width: "205px",
			maxWidth: "205px",
		},
		imageWrapper: {
			marginLeft: 0,
		},
		nameWrapper: {},
		connectWrapper: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
		},
		priceWrapper: {
			marginBottom: "10px",
			marginRight: 0,
		},
		compareButton: {
			width: "40px",
			height: "40px",
		},
		connectButton: {
			width: "130px",
			"& a": {
				fontSize: "12px",
				paddingRight: 0,
			},
		},
		roundConnectBtn: {
			width: "170px !important",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		isRating: {
			padding: "0 15px",
		},
		thead: {
			display: "none",
		},
		table: {
			width: "100%",
		},
		row: {
			width: "100%",
			borderRadius: "10px",
			marginBottom: "20px",
			backgroundColor: "white",
			border: `1px solid #e4e7f1`,
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: "center",
			padding: "20px 20px 60px",
			"& > div": {
				border: "none !important",
				borderRadius: "0 !important",
			},
		},
		baseTD: {
			padding: 0,
			flexShrink: 0,
			position: "static",
		},
		commonInfoHeader: {
			minWidth: "100%",
			width: "auto",
			maxWidth: "100%",
			marginBottom: "16px",
			marginTop: "10px",
		},
		svgImage: {
			borderTopLeftRadius: "10px",
		},
		providerNameHidden: {
			width: "0",
		},
		imageWrapper: {
			marginLeft: 0,
		},
		speedHeader: {
			width: "50%",
			minWidth: "50%",
			maxWidth: "50%",
			margin: "0 auto",
			marginBottom: "16px",
		},
		channelsHeader: {
			width: "50%",
			minWidth: "50%",
			maxWidth: "50%",
			margin: "0 auto",
			marginBottom: "16px",
		},
		mobileHeader: {
			marginBottom: "16px",
			minWidth: "100%",
		},
		optionsHeader: {
			width: "auto",
			margin: "0 auto 0 0",
		},
		connectHeader: {
			width: "auto",
			margin: "0 0 0 auto",
		},
		notExistXs: {
			display: "none",
		},
		hideXs: {
			visibility: "hidden",
			margin: 0,
		},
		priceWrapper: {
			marginRight: 0,
		},
		connectButtonWrapper: {
			position: "absolute",
			left: 0,
			right: 0,
			bottom: 0,
			border: "none",
			borderRadius: "0",
		},
		compareButton: {
			width: "50%",
			height: "50px",
			borderRadius: "0 0 0 10px",
			borderTop: `1px solid ${theme.palette.colors.disabled}`,
		},
		compareButtonText: {
			display: "inline",
			marginLeft: "5px",
			fontSize: "12px",
			fontFamily: theme.fonts.medium,
			color: theme.palette.colors.purplishBlue,
			textTransform: "uppercase",
			letterSpacing: "1.5px",
		},
		compareButtonTextIsGrey: {
			color: theme.palette.colors.cloudyBlue,
		},
		connectButton: {
			width: "50%",
			height: "50px",
			borderRadius: "0 0 10px 0",
			"& a": {
				fontSize: "12px",
				paddingRight: 0,
				letterSpacing: "1.71px",
			},
		},
		roundConnectBtn: {
			width: "100% !important",
		},
		icon: {
			width: "20px",
			height: "20px",
		},
		connectWrapper: {
			padding: 0,
		},
	},
	[`@media (max-width: ${theme.media.tariffsSmall})`]: {
		optionsHeader: {
			width: "100%",
			alignContent: "center",
			margin: "0 0 16px 0",
		},
		connectHeader: {
			width: "100%",
			margin: 0,
		},
		connectWrapper: {
			justifyContent: "center",
		},
	},
});

export default styles;
