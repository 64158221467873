import React, { useState, useEffect, useRef } from "react";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { sendMetriks } from "Services/sendMetriks";
import userInfo from "Services/UserInfo";
import useCurrentRegion from "Services/useCurrentRegion";
import { addAddressData, addABData, addRefererData } from "Utils";
import { FormLayout, TariffInfoV2 } from "Layouts/TariffFormLayout";
import { Form, FormError } from "Layouts/Form";
import { TextField } from "ComponentsUI/TextField";
import Button from "ComponentsUI/Button";
import TermsOfUse from "ComponentsUI/TermsOfUse";
import { CLICK_SEND_ORDER_WITH_TARIFF } from "Constants/metriks/Yandex";
import { useWithDefaultParams } from "Services/useWithDefaultParams";

function TariffFormContainer(props) {
	let urlRegion = props?.location?.pathname.split("/")?.[1];
	let match = useWithDefaultParams(urlRegion);
	let regionUrl = match.params.regionUrl;
	const wrapperRef = useRef(null);

	const regionData = useCurrentRegion(regionUrl);
	const [phoneConnection, setPhone] = useState("");

	const [isError, setError] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	let providerInfo = props.providerInfo;
	const { provider } = props.tariff;
	const { contacts } = provider;
	let region = null;
	let text = props.text;
	let windowSizes = props.ApplicationModel.windowSizeModel.windowSizes;
	const popupModel = props.ApplicationModel.popupModel;
	if (!regionData.loading) {
		region = regionData.data.region;
	}

	const onChangePhoneHandler = (e) => {
		setPhone(e.target.value);
	};

	const onBlurPhone = () => {
		setError(phoneConnection.length !== 11 && phoneConnection.length !== 1);
	};

	const onFocusPhone = () => {
		setError(false);
	};

	useEffect(() => {
		if (wrapperRef.current) {
			const input =
				wrapperRef.current.querySelector("input") || wrapperRef.current.querySelector("textarea");
			if (input) {
				if (windowSizes.xs || windowSizes.sm) {
					input.scrollIntoView({ block: "center", behavior: "smooth" });
				}
			}
		}
	}, [windowSizes]);
	const onClickConnectHandler = (e) => {
		e.preventDefault();
		if (!props.validatePhone(phoneConnection)) {
			setError(true);
			return null;
		} else {
			setSuccess(true);
			sendMetriks("SEND_CONNECT_TARIFF");
			let data = {
				fio: "",
				phone_connection: phoneConnection,
				provider_id: provider.id,
				tariff_id: props.tariff.id,
				region_id: region?.id || 200,
				lead_form_type: userInfo(CLICK_SEND_ORDER_WITH_TARIFF),
			};

			data = addAddressData(data, props.ApplicationModel);
			data = addABData(data);
			data = addRefererData(data, props.ApplicationModel);

			let infoUser = {
				name_popup: "TariffFormContainer",
			};
			popupModel.setInfoUser(infoUser);

			props.sendOrder(data).then((dataPromise) => {
				sendMetriks("LEAD_ALL");
				if (!dataPromise) {
					props.sendOrderRepeat(data);
				}
			});
		}
	};

	let phone = "";

	if (provider) {
		if (provider.hasOwnProperty("contacts")) {
			if (provider.contacts.hasOwnProperty("phones")) {
				phone = contacts.phones.length && contacts.phones[0];
			}
		}
	} else if (providerInfo) {
		if (providerInfo.hasOwnProperty("contacts")) {
			if (providerInfo.contacts.hasOwnProperty("phones")) {
				phone = providerInfo.contacts.phones.length && providerInfo.contacts.phones[0];
			}
		}
	}
	let successButton = (
		<Button
			status={"secondaryAccent"}
			transparent={true}
			datatest={"give_feedback"}
			onClick={props.closeModal}
		>
			{text.WELCOME_CB_WIDGET_TITLE}
		</Button>
	);

	return (
		<FormLayout ref={wrapperRef}>
			<Form
				type={"popupForm"}
				isSuccess={isSuccess}
				successHeader={
					<>
						<div>{text.ORDER_SUCCESS_0}</div>
						<div>{text.ORDER_SUCCESS_4}</div>
						<div>{text.ORDER_SUCCESS_5}</div>
					</>
				}
				successButton={successButton}
			>
				{!!provider.vip_provider && (
					<TariffInfoV2 tariff={props.tariff} provider={provider} text={text} phone={phone} />
				)}
				<TextField
					autocomplete="tel"
					name="tel"
					onChange={(e) => {
						onChangePhoneHandler(e);
					}}
					onEnter={(e) => {
						onClickConnectHandler(e);
					}}
					label={text.ORDER_PHONE_PLACEHOLDER}
					placeholder={"+7"}
					datatest="popup_tariff_order_input_tel"
					inputBackgroundColor={"transparent"}
					type={"phone"}
					size={"medium"}
					onBlur={() => onBlurPhone()}
					onFocus={() => onFocusPhone()}
					autoFocus={!windowSizes.xs && !windowSizes.sm}
					isError={isError}
				/>
				<FormError
					show={isError}
					hidden={!isError}
					datatest={"popup_tariff_order_incorrect_data"}
					tariffFormContainer={true}
				>
					{text.SEND_ORDER_ERROR_1}
				</FormError>
				<Button
					status={"secondaryAccent"}
					datatest="popup_tariff_order_form_input_connect_button"
					onClick={(e) => {
						onClickConnectHandler(e);
					}}
					onKeyDown={(e) => onClickConnectHandler(e)}
					fullWidth={true}
				>
					{text.SEND_ORDER}
				</Button>
				<TermsOfUse buttonText={text.SEND_ORDER} isTariffOrderPopup={true} textAlignCenter={true} />
			</Form>
		</FormLayout>
	);
}

export default inject("ApplicationModel")(withRouter(TariffFormContainer));
