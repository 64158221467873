import React from "react";
import { observer, inject } from "mobx-react";
import FindTariffsContainer from "Containers/FindTariffsContainer";
import Metrika from "Services/Metrika";
import { CLICK_PROVIDER_COVERAGE } from "Constants/metriks/Yandex";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";
import { EVENT_CLICK_COVERAGE_PROVIDER } from "Constants/metriks/GoogleActions";
import DeclinationContainerNew from "Containers/DeclinationContainerNew";

@inject("ApplicationModel")
@observer
export default class TariffsConnectHOC extends React.Component {
	openPopup(data) {
		this.closeModal();
		Metrika.getInstance().google.sendEvent(
			EVENT_CATEGORY_CLICK_BUTTON,
			EVENT_CLICK_COVERAGE_PROVIDER
		);
		let texts = this.props.ApplicationModel.text;
		let modalModel = this.props.ApplicationModel.modalModel;
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		let promise1 = import("Layouts/modalLayout");
		Promise.all([promise1]).then((modules) => {
			let ModalLayout = modules[0].default;
			commonViewModel.setShowSearchAddressPopup(true);
			modalModel.toggleModal(
				<ModalLayout authView={true} withoutClose={true}>
					<DeclinationContainerNew>
						<FindTariffsContainer
							data={data}
							show={true}
							text={texts}
							onClose={() => this.closeModal()}
							fromTariff={1}
							hideProviderLogo={this.props.hideProviderLogo}
						/>
					</DeclinationContainerNew>
				</ModalLayout>
			);
		});
	}

	closeModal() {
		let modalModel = this.props.ApplicationModel.modalModel;
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		commonViewModel.setShowSearchAddressPopup(false);
		if (modalModel.isOpen) {
			modalModel.toggleModal();
		}
	}

	render() {
		let children = React.cloneElement(this.props.children, {
			onClick: (data) => this.openPopup(data),
		});
		return children;
	}
}
