import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			margin: 0,
			padding: 0,
			listStyle: "none",
			display: "flex",
			alignItems: "center",
			position: "relative",
			justifyContent: "center",
		},
		upperMenu: {
			paddingInlineStart: 0,
		},
		menu: {
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			margin: 0,
			padding: 0,
			listStyle: "none",
		},
		inWidget: {
			"& $menu": {
				flexWrap: "wrap",
				flexDirection: "column",
				justifyContent: "center",
			},
		},
		providerTheme: {
			"& $menu": {
				justifyContent: "flex-start",
			},
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			menu: {
				paddingLeft: "11px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				flexWrap: "wrap",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			menu: {
				flexWrap: "wrap",
				flexDirection: "column",
				justifyContent: "center",
			},
		},
	};
};

class Menu extends React.Component {
	render() {
		let classes = this.props.classes;
		return (
			<div
				itemScope
				itemType="http://schema.org/SiteNavigationElement"
				className={classNames(classes.root, {
					[classes.inWidget]: this.props.inWidget,
					[classes.providerTheme]: this.props.providerTheme,
				})}
			>
				<menu
					className={classes.upperMenu}
					itemProp="about"
					itemScope
					itemType="http://schema.org/ItemList"
				>
					<ul className={classes.menu}>{this.props.items}</ul>
				</menu>
			</div>
		);
	}
}

export default withStyles(styles)(Menu);
