import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { FooterTopTitleContainer } from "Containers/Footer";

const useStyles = createUseStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: "4px",
	},
	bigMarginBottom: {
		marginBottom: "48px",
	},
	mainMarginBottom: {
		marginBottom: "36px",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		mainMarginBottom: {
			marginBottom: "32px",
		},
	},
}));

const FooterTopMobileBlock = (props) => {
	const { children, windowSizes, mobileBlockTitle, clickedBlock, isOpen } = props;
	const classes = useStyles();

	let windowSizesXs = windowSizes?.xs;

	return (
		<div className={classes.root}>
			{!windowSizesXs && !isOpen ? (
				<div className={classes.bigMarginBottom}>
					<FooterTopTitleContainer isButton text={mobileBlockTitle} clickedBlock={clickedBlock} />
				</div>
			) : (
				<div className={classes.mainMarginBottom}>{children}</div>
			)}
		</div>
	);
};

FooterTopMobileBlock.propTypes = {
	children: PropTypes.node,
	windowSizes: PropTypes.object,
	mobileBlockTitle: PropTypes.string,
};

export default FooterTopMobileBlock;
