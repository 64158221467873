import { gql } from "@apollo/client";

const MUTATE_CLIENT_ACTION = gql`
	mutation (
		$open: String
		$uuid: String
		$from: String
		$monoTariffs: Int
		$internetTvTariffs: Int
		$convergentTariffs: Int
		$tvTariffs: Int
		$mobileTariffs: Int
		$providers: String
		$search_url: String
		$click_on_page: String
		$hotSlot: Int
		$availableSlot: Int
		$unavailableSlot: Int
	) {
		clientAction(
			open: $open
			uuid: $uuid
			from: $from
			tariffs: {
				mono: $monoTariffs
				internetTv: $internetTvTariffs
				convergent: $convergentTariffs
				tv: $tvTariffs
				mobile: $mobileTariffs
			}
			providers: $providers
			search_url: $search_url
			click_on_page: $click_on_page
			slot: { hot: $hotSlot, available: $availableSlot, unavailable: $unavailableSlot }
		)
	}
`;

export default MUTATE_CLIENT_ACTION;
