import { gql } from "@apollo/client";

const REGION_DETECT_QUERY = gql`
	query REGION_DETECT_QUERY($ip_address: String) {
		regionDetect(ip_address: $ip_address) {
			id
			image_lg
			image_md
			image_sm
			image_xs
			info_popup
			is_capital
			is_show_qwiz
			name
			url
		}
	}
`;

export default REGION_DETECT_QUERY;
