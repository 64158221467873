import React from "react";
import FooterTopPartContainer from "../FooterTopPartContainer";
import FooterBottomPartContainer from "../FooterBottomPartContainer";

const FooterContainerV2 = (props) => {
	let { is404Layout } = props;
	return (
		<>
			<FooterTopPartContainer is404Layout={is404Layout} />
			<FooterBottomPartContainer is404Layout={is404Layout} />
		</>
	);
};

export default FooterContainerV2;
