import React from "react";
import withStyles from "react-jss";
import { Link } from "react-router-dom";
import classNames from "classnames";

const styles = (theme) => {
	return {
		root: {
			fontFamily: theme.fonts.light,
			fontSize: "24px",
			lineHeight: 0.75,
			color: theme.palette.colors.oneMoreGrey,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: "50%",
			backgroundColor: theme.palette.colors.transparent,
			width: "calc(100% + 2px)",
			height: "calc(100% + 2px)",
		},
		isActive: {
			backgroundColor: theme.palette.colors.purplishBlue,
			color: theme.palette.colors.white,
		},
		isLast: {
			"& span": {
				marginLeft: "5px",
			},
		},
		isFirst: {
			"& span": {
				marginRight: "5px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				fontSize: "18px",
				lineHeight: 1,
			},
			isLast: {
				"& span": {
					marginLeft: "2px",
				},
			},
			isFirst: {
				"& span": {
					marginRight: "2px",
				},
			},
		},
	};
};

class PaginatorElem extends React.Component {
	static defaultProps = {
		to: "#",
		className: "",
		onClickHandler: () => {},
	};

	onClickHandler(e) {
		this.props.onClickHandler && this.props.onClickHandler();
	}

	render() {
		let { className } = this.props;
		let classes = this.props.classes;

		return (
			<Link
				onClick={(e) => this.onClickHandler(e)}
				className={classNames(classes.root, className, {
					[classes.isActive]: this.props.active,
					[classes.isFirst]: this.props.first,
					[classes.isLast]: this.props.last,
				})}
				to={this.props.to}
				aria-label="Переключить страницу"
			>
				{this.props.children}
			</Link>
		);
	}
}

export default withStyles(styles)(PaginatorElem);
