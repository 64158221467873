import { action } from "mobx";
import cookie from "js-cookie";
import { MUTATE_INFO_USER_POPUP } from "Services/queries";

export default class PopupModel {
	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	setInfoUser(userInfo) {
		const client = this.rootStore.apolloTransportLayer;
		let variables = {
			name_popup: userInfo.name_popup,
			view_quiz: userInfo.view_quiz,
			uuid_user: cookie.get("uuid") || "Не был задан uuid",
			phone_user: userInfo.phone_user,
			user_agent: navigator.userAgent,
			width: userInfo.width,
			height: userInfo.height,
			tariffs_count: userInfo.tariffs_count,
			min_price: userInfo.min_price,
			max_speed: userInfo.max_speed,
			close_button: userInfo.close_button,
			time_show_popup: userInfo.time_show_popup,
		};

		if (userInfo.ab_test) {
			variables = {
				...variables,
				ab_enabled: userInfo.ab_test.enabled || false,
				ab_variant: userInfo.ab_test.variant || "No_name",
			};
		}

		if (userInfo.address) {
			variables = {
				...variables,
				region: userInfo.address.region,
				region_id: userInfo.address.region_id,
				district: userInfo.address.district,
				district_id: userInfo.address.district_id,
				full_street: userInfo.address.full_street,
				street_id: userInfo.address.street_id,
				house: userInfo.address.house,
				house_id: userInfo.address.house_id,
			};
		}

		client.mutate({
			mutation: MUTATE_INFO_USER_POPUP,
			variables,
		});
	}
}
