import Cookies from "js-cookie";

function getUserInfo(leadType, from = null) {
	return JSON.stringify({
		lead_form_type: leadType,
		userAgent: navigator.userAgent,
		screenWidth: screen.width,
		screenHeight: screen.height,
		url: window.location.href,
		utm_campaign: Cookies.get("utm_campaign"),
		utm_medium: Cookies.get("utm_medium"),
		utm_source: Cookies.get("utm_source"),
		utm_content: Cookies.get("utm_content"),
		utm_term: Cookies.get("utm_term"),
		from: from || Cookies.get("connectTariffFrom"),
	});
}

export default getUserInfo;
