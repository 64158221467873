export const pipe =
	(...functions) =>
	(arg) =>
		functions.reduce((accum, func) => func(accum), arg);

export const hasOwnProperty = (target, key) => {
	return Object.prototype.hasOwnProperty.call(target, key);
};

export const createDownload = (elem) => {
	fetch(elem.url).then((response) => {
		response.blob().then((blob) => {
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			a.download = `${elem.name}.pdf`;
			a.click();
		});
	});
};

// Функция перемешивания методом Фишера-Йетса
export const shuffle = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
};

const translit_data = {
    Ё: "YO",
    Й: "I",
    Ц: "TS",
    У: "U",
    К: "K",
    Е: "E",
    Н: "N",
    Г: "G",
    Ш: "SH",
    Щ: "SCH",
    З: "Z",
    Х: "H",
    Ъ: "'",
    ё: "yo",
    й: "i",
    ц: "ts",
    у: "u",
    к: "k",
    е: "e",
    н: "n",
    г: "g",
    ш: "sh",
    щ: "sch",
    з: "z",
    х: "h",
    ъ: "'",
    Ф: "F",
    Ы: "I",
    В: "V",
    А: "A",
    П: "P",
    Р: "R",
    О: "O",
    Л: "L",
    Д: "D",
    Ж: "ZH",
    Э: "E",
    ф: "f",
    ы: "i",
    в: "v",
    а: "a",
    п: "p",
    р: "r",
    о: "o",
    л: "l",
    д: "d",
    ж: "zh",
    э: "e",
    Я: "Ya",
    Ч: "CH",
    С: "S",
    М: "M",
    И: "I",
    Т: "T",
    Ь: "'",
    Б: "B",
    Ю: "YU",
    я: "ya",
    ч: "ch",
    с: "s",
    м: "m",
    и: "i",
    т: "t",
    ь: "'",
    б: "b",
    ю: "yu",
};

export function transliterate(word) {
    return word
        .split("")
        .map(function(char) {
            return translit_data[char] || char;
        })
        .join("");
}

export const generateHouseNameForUrl = address => {
    let url = "d-";

    if (address.full) {
        url += `${address.full}`;
    }

    url = transliterate(url);
    url = url.replace(/\//g, "-");
    url = url.replace(/[^a-zA-Z0-9-]+/g, "");

    return url;
};
