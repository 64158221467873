import React from "react";
import { Col } from "ComponentsUI/Styles/Grid";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import CallbackAdverBlock from "./Callback";
import AddressAdverBlock from "./Address";
import AddressOneStringAdverBlock from "./AddressOneString";
import FreeConsultationAdverBlock from "./FreeConsultation";
import withStyles from "react-jss";
import classNames from "classnames";
import { inject, observer } from "mobx-react";
import PhoneContainerNew from "Containers/PhoneContainerNew";
import HideBeforeMount from "Layouts/HideBeforeMount";
import AddressPopupHOC from "HOC/AddressPopupHOC";

const rowWrapperStyle = (theme) => ({
	root: {
		marginBottom: "20px",
	},
	fromReviews: {
		marginTop: "40px",
	},
	fromTariffs: {
		marginBottom: "60px",
	},
	noMargin: {
		marginBottom: 0,
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			marginBottom: "16px",
			"&>div:first-child": {
				marginBottom: "16px",
			},
		},
	},
});

const RowWrapper = withStyles(rowWrapperStyle)(({
	children,
	classes,
	fromReviews,
	fromNews,
	fromTariffs,
	noMargin,
}) => {
	const className = classNames(classes.root, {
		[classes.fromReviews]: fromReviews,
		[classes.noMargin]: noMargin,
		_masonryItem: fromNews,
		[classes.fromTariffs]: fromTariffs,
	});
	return <Row className={className}>{children}</Row>;
});

@inject("ApplicationModel")
@observer
class AdverBlockContainer extends React.Component {
	render() {
		let one_string_search =
			this.props.ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled;

		let newViewSearch = this.props.ApplicationModel?.siteConfig?.settings?.show_new_view_search;

		return (
			<HideBeforeMount>
				<Container>
					{!this.props.freeConsultation && (
						<RowWrapper
							fromReviews={this.props.fromReviews}
							fromNews={this.props.fromNews}
							fromTariffs={this.props.fromTariffs}
							noMargin={this.props.noMargin}
						>
							<Col lg={6} md={6} sm={6}>
								<AddressPopupHOC>
									{one_string_search ? (
										<AddressOneStringAdverBlock
											isRatesAddForm={this.props.isRatesAddForm}
											withQuiz={true}
											providerId={this.props.providerId}
											datatest="providers_find_adress_one_string"
											onHeightChangeHandler={this.props.onHeightChangeHandler}
										/>
									) : (
										<AddressAdverBlock
											autoExpansion={!newViewSearch}
											withQuiz={true}
											providerId={this.props.providerId}
											datatest="providers_find_adress"
											onHeightChangeHandler={this.props.onHeightChangeHandler}
										/>
									)}
								</AddressPopupHOC>
							</Col>
							<Col lg={6} md={6} sm={6}>
								<CallbackAdverBlock
									datatest="providers_callback2"
									backgroundColor={"green"}
									lead_form_type={this.props.lead_form_type}
									onHeightChangeHandler={this.props.onHeightChangeHandler}
								/>
							</Col>
						</RowWrapper>
					)}
					{this.props.freeConsultation && (
						<RowWrapper
							fromReviews={this.props.fromReviews}
							fromNews={this.props.fromNews}
							fromTariffs={this.props.fromTariffs}
							noMargin={this.props.noMargin}
						>
							<Col lg={6} md={6} sm={6}>
								<CallbackAdverBlock
									backgroundColor={"purple"}
									lead_form_type={this.props.lead_form_type}
									onHeightChangeHandler={this.props.onHeightChangeHandler}
								/>
							</Col>
							<Col lg={6} md={6} sm={6}>
								<FreeConsultationAdverBlock
									phone={<PhoneContainerNew />}
									onHeightChangeHandler={this.props.onHeightChangeHandler}
								/>
							</Col>
						</RowWrapper>
					)}
				</Container>
			</HideBeforeMount>
		);
	}
}

export default AdverBlockContainer;
