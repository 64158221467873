import React from "react";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import withDefaultParams from "Services/withDefaultParams";
import { pipe } from "Utils/functionalUtils";
import { getFullUrlSite } from "Utils/siteConfig";
import { CDN_URL } from "Constants/CDN";
import { useSeo } from "hooks/useSeo";

const withSEOv2 = (props) => {
	/*let {
		ApplicationModel: { tariffsModel },
	} = props;
	const params = props.match.params;
	const one_string_search =
		props.ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled;
	const searchModel = one_string_search
		? props.ApplicationModel.searcheModelOneString
		: props.ApplicationModel.searcheModel;

	let query = queryString.parse(props.location.search);
	if (!!query.provider_id) {
		query.providerId = query.provider_id;
	}

	// Убрать все пробельные символы в свойствах query
	for (let key in query) {
		query[key] = query[key].replace(/\s/g, "");
	}

	let house_id;
	if (params.house_id) {
		house_id = +params.house_id;
	} else {
		house_id = searchModel.house_id;
	}

	if (props.route.name === "OrderHome") {
		query.tariff_id = tariffsModel.tariff_id;
	} else if ((props.route.name === "DomaNzl" || props.route.name === "RatesInHouse") && house_id) {
		query.house_id = house_id;
	}

	const { data } = useQuery(SEO_QUERY_V3, {
		variables: {
			...props.route.seo({
				...props.match.params,
				...query,
			}),
		},
	});

	const seoData = data?.seoV3;*/

	let seoData = useSeo(props.route, props.ApplicationModel);

	let { children, ...rest } = props;
	let childrenPrimary = React.cloneElement(props.children, {
		...rest,
		seoData: seoData,
		matchProps: rest,
	});
	if (props.withBannerText) {
		childrenPrimary = React.cloneElement(childrenPrimary, {
			bannerText: seoData && seoData.h1,
			bannerTextAfter: seoData && seoData.h1After,
		});
	}

	return (
		<>
			<Helmet>
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content={getFullUrlSite(props.ApplicationModel.siteConfig, props.match.url)}
				/>
				<meta property="og:locale" content="ru_RU" />
				<meta property="og:image" content={`${CDN_URL}/images/logo_101.png`} />
				<meta
					itemProp="description"
					property="og:description"
					name="description"
					content={seoData && seoData.description ? seoData.description : ""}
				/>
				<meta name="keywords" content={seoData && seoData.keywords ? seoData.keywords : ""} />
				<meta property="og:title" content={seoData && seoData.title ? seoData.title : ""} />
				<title itemProp="headline">{seoData && seoData.title ? seoData.title : ""}</title>
			</Helmet>
			{childrenPrimary}
		</>
	);
};

export default pipe(observer, inject("ApplicationModel"), withDefaultParams, withRouter)(withSEOv2);
