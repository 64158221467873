var gql = require("@apollo/client").gql;

const CHECK_PROVIDER_ADDRESS_QUERY_V2 = gql`
	query CHECK_PROVIDER_ADDRESS_QUERY_V2($address_id: Int!, $provider_id: Int) {
		checkProviderAddressV2(address_id: $address_id, provider_id: $provider_id) {
			current_provider
			any_provider
			all_tariffs
		}
	}
`;

export default CHECK_PROVIDER_ADDRESS_QUERY_V2;
