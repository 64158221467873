import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Experiment, Variant } from "react-abtesting";
import { useMutation } from "@apollo/client";
import { MUTATE_AB } from "Services/queries";
import { pipe } from "Utils/functionalUtils";
import ProviderOrderPopupB from "ABTesting/Tests/PopapInTariff/ComponentsUI/ProviderOrderPopupB";

const ABTestTariffOrderPopup = (props) => {
	let {
		ApplicationModel: {
			siteConfig: { experiments },
		},
		defaultContainer,
		testName,
	} = props;

	experiments = experiments ? experiments : [];
	let currentTest = experiments.find((elem) => elem.name === testName);

	const [sendABGoal] = useMutation(MUTATE_AB);
	const [event, setEvent] = useState(undefined);
	useEffect(() => {
		const variantRaw = Cookies.get("AB_" + testName);
		if (variantRaw) {
			let variant;

			try {
				variant = JSON.parse(variantRaw);
			} catch (e) {
				console.warn(`AB variant parsing error. Test: ${testName}, error:`, e);
			}

			sendABGoal({
				variables: {
					uuid: Cookies.get("uuid"),
					experiment: testName,
					variant: variant?.name || "no variant found",
					user_agent: navigator.userAgent,
					goal: true,
					event: event,
				},
			});
		}
	}, [event]);

	if (!currentTest) {
		return defaultContainer;
	}

	return (
		<Experiment name={testName}>
			{currentTest.variants.map((variantTest) => {
				if (
					Boolean(variantTest?.use_default_react || variantTest.name === "Default") &&
					defaultContainer
				) {
					return (
						<Variant key={variantTest.name} name={variantTest.name}>
							{defaultContainer}
						</Variant>
					);
				}
				if (variantTest.name === "B") {
					return (
						<Variant key={variantTest.name} name={variantTest.name}>
							<ProviderOrderPopupB setEvent={setEvent} {...props} />
						</Variant>
					);
				}
				return (
					<Variant key={variantTest.name} name={variantTest.name}>
						{defaultContainer}
					</Variant>
				);
			})}
		</Experiment>
	);
};

ABTestTariffOrderPopup.propTypes = {
	defaultContainer: PropTypes.object,
	testName: PropTypes.string,
};

ABTestTariffOrderPopup.defaultProps = {
	showABtest: false,
	testName: "testtest__popap_in_tariff",
};

export default pipe(inject("ApplicationModel"))(ABTestTariffOrderPopup);
