import React from "react";
import { notRegionUrls } from "Constants/defaultParams";
import Url from "./Url";

const withDefaultParams = (ComposedComponent) => {
	return class extends React.Component {
		render() {
			let params = Url.getDefaultParams();

			let match = JSON.parse(JSON.stringify(this.props.match));
			let regionUrl = match.params.regionUrl;
			match.params.regionUrlComing = regionUrl;
			if (notRegionUrls.find((r) => r == regionUrl)) {
				regionUrl = "";
			}

			if (!regionUrl && params.hasOwnProperty("regionUrl")) {
				regionUrl = params.regionUrl;
				//TODO возможно исправит костыль с регионом
				// match.params.regionUrlComing = regionUrl;
			}
			match.params.regionUrl = regionUrl;
			// console.log('---------------');
			// console.log(`params : ${params}`);
			// console.log(params);
			// console.log(`router : ${match}`);
			// console.log(this.props.match);
			// console.log('---------------');
			return <ComposedComponent {...this.props} match={match} />;
		}
	};
};

export default withDefaultParams;
export let setDefaultParams = Url.setDefaultParams;
export let generateUrl = Url.generateUrl;
