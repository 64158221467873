import React from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { captureException } from "@sentry/react";
import queryString from "query-string";
import smoothScrollTo from "Helpers/Scroll";
import Metrika from "Services/Metrika";

@withRouter
@inject("ApplicationModel")
@observer
export default class AppControl extends React.Component {
	componentDidUpdate(prevProps) {
		let prevLocation = prevProps.location;
		let currLocation = this.props.location;

		if (prevLocation.pathname !== currLocation.pathname) {
			if (typeof window !== "undefined") {
				if (!!window.ga) {
					window.ga(() => {
						window.ga.getAll()[0].send({
							hitType: "pageview",
							location: document.location.origin + currLocation.pathname,
						});
					});
				}

				Metrika.getInstance().yandex.sendHitEvent(
					document.location.origin + this.props.location.pathname
				);
			}
		}
	}

	removeABTestsFromSearch(search) {
		const experimentMap = window.__EXPERIMENT_MAP__ || [];

		let params = queryString.parse(search);
		for (let key in params) {
			let found = null;
			found = experimentMap.find((item) => item.name == key);
			if (!!found) {
				delete params[key];
			}
		}
		let stringParams = queryString.stringify(params);
		return !!stringParams ? `?${stringParams}` : ``;
	}

	getSnapshotBeforeUpdate(prevProps) {
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		let urlNow =
			prevProps.location.pathname + this.removeABTestsFromSearch(prevProps.location.search);
		let futureUrl =
			this.props.location.pathname + this.removeABTestsFromSearch(this.props.location.search);
		if (urlNow !== futureUrl) {
			commonViewModel.setPreviousPath(urlNow);
		}

		return null;
	}

	componentDidMount() {
		smoothScrollTo(0, 0);
		this.initRoistatActions();
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		let authModel = this.props.ApplicationModel.newAuthModel;
		authModel.loginAsUser();
		// let region = this.props.ApplicationModel.currentRegion;
		// if (!region) {
		//     this.blocking = this.props.history.block((location, action) => {
		//         commonViewModel.setBlockedPath(location.pathname);
		//         this.toggleModal();
		//         return false;
		//     });
		//
		//     commonViewModel.setUnblocking(this.blocking);
		// }

		// this.props.history.listen((location, action) => {
		//     this.props.ApplicationModel.commonViewModel.toggleMobileMenu();
		// });

		let svgInlineModel = this.props.ApplicationModel.svgInlineModel;
		svgInlineModel.initialize();

		window.onerror = (...params) => this.handleConsoleErrors(params);
	}

	handleConsoleErrors(params) {
		let errorObj = params[4];
		let url = window.location.href;
		let siteCode = this.props.ApplicationModel.siteCode;
		captureException(errorObj, {
			contexts: { siteCode: siteCode, url: url },
		});
		return false;
	}

	initRoistatActions() {
		window.onRoistatAllModulesLoaded = () => {
			window.roistat.leadHunter.onAfterSubmit = (leadData) => {
				try {
					if (dataLayer) {
						dataLayer.push({ event: "click_lead_catcher" });
					}
				} catch (e) {
					console.warn(e);
				}

				// ga("send", { hitType: "event", eventCategory: "callback", eventAction: "click_lead_catcher", eventLabel : "roistat"})
			};
		};
	}

	render() {
		return this.props.children;
	}
}
