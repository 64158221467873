import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";

const formLayoutStyle = (theme) => ({
	root: {
		width: "100%",
		borderRadius: "10px",
		backgroundColor: "white",
		border: "solid 1px #d3cee1",
		padding: "30px",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
		height: "100%",
	},
	green: {
		backgroundColor: "#00c853",
		border: "solid 1px #00c853",
	},
	purple: {
		backgroundColor: "#5812fe",
		border: "solid 1px #5812fe",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			width: "100%",
			padding: "20px",
			marginBottom: "0",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			width: "100%",
			marginBottom: "0",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			width: "100%",
			padding: "20px",
			marginBottom: "0",
		},
	},
});

class Layout extends React.Component {
	static defaultProps = {
		children: null,
		backgroundColor: "purple",
	};

	render() {
		let classes = this.props.classes;
		let backgroundColor = this.props.backgroundColor;
		const className = classNames(classes.root, {
			[classes.green]: backgroundColor === "green",
			[classes.purple]: backgroundColor === "purple",
		});
		return (
			<div datatest={this.props.datatest} className={className}>
				{this.props.children}
			</div>
		);
	}
}

const StyledLayout = withStyles(formLayoutStyle)(Layout);

export default React.forwardRef((props, ref) => <StyledLayout ref={ref} {...props} />);
