import { observable, action } from "mobx";
import OurModal from "ComponentsUI/OurModal";

export default class FixedPanelModel {
	@observable callbackIsOpen = false;
	@observable callbackCB = null;
	@observable giftWidgetIsOpen = false;
	@observable showGiftWidget = true;
	@observable fixedPanelIsVisible = true;
	@observable filtersIsCollapsed = true;
	@observable addReviewIsOpen = false;
	@observable filtersWidgetIsVisible = false;
	@observable isSended = false;
	@observable filtersWidgetContent = null;
	timeOutId = null;

	constructor() {}

	@action
	openCallback() {
		this.callbackIsOpen = true;
		if (this.callbackCB) {
			this.callbackCB();
		}
		this.addReviewIsOpen = false;
	}

	@action
	setShowGiftWidget(value) {
		this.showGiftWidget = value;
	}

	@action
	closeCallback() {
		this.callbackIsOpen = false;
	}

	@action
	send(isSended) {
		this.isSended = isSended;
	}

	@action
	openAddReview() {
		this.addReviewIsOpen = true;
		this.callbackIsOpen = false;
		OurModal.forOpenedModalBody();
	}

	@action
	closeAddReview() {
		this.addReviewIsOpen = false;
		OurModal.forClosedModalBody();
	}

	@action
	showFiltersWidget() {
		this.filtersWidgetIsVisible = true;
	}

	@action
	hideFiltersWidget() {
		this.filtersWidgetIsVisible = false;
	}

	@action
	setFiltersWidgetContent(value) {
		this.filtersWidgetContent = value;
	}

	@action
	hideFixedPanel() {
		this.fixedPanelIsVisible = false;
	}

	@action
	showFixedPanel() {
		this.fixedPanelIsVisible = true;
	}

	@action
	collapseFilters() {
		this.filtersIsCollapsed = true;
	}

	@action
	uncollapseFilters() {
		this.filtersIsCollapsed = false;
	}

	@action
	toggleFilters() {
		this.filtersIsCollapsed = !this.filtersIsCollapsed;
	}

	@action
	setOpenCallbackCB(func) {
		this.callbackCB = func;
	}

	@action.bound
	openGiftWidget() {
		if (this.timeOutId != null) clearTimeout(this.timeOutId);

		this.giftWidgetIsOpen = true;
		this.fixedPanelIsVisible = false;
	}

	@action
	closeGiftWidget() {
		this.giftWidgetIsOpen = false;
		this.fixedPanelIsVisible = true;
	}

	@action
	openGiftWidgetWithDelay() {
		this.timeOutId = setTimeout(this.openGiftWidget, 1000 * 10);
	}

	@action
	cancelOpenGiftWidgetWithDelay() {
		if (this.timeOutId != null) clearTimeout(this.timeOutId);
	}
}
