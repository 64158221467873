import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";
import Metrika from "Services/Metrika";
import { MainNoRegion } from "Constants/url";
import { isMainSiteCode } from "Utils/siteConfig";

const styles = (theme) => ({
	root: {
		position: "relative",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		zIndex: 1,
	},
	wrapper: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		paddingTop: "40px",
	},
	wrapperCenter: {
		textAlign: "center",
		width: "1000px",
		margin: "0 auto",
	},
	wrapperCenterHeader: {
		fontFamily: theme.fonts.bold,
		fontSize: "64px",
		lineHeight: 1,
		color: theme.palette.colors.white,
		margin: 0,
	},
	wrapperCenterSubheader: {
		fontFamily: theme.fonts.light,
		width: "100%",
		fontSize: "24px",
		lineHeight: 1.17,
		textAlign: "center",
		color: theme.palette.colors.white,
		margin: "4vh auto 0",
	},
	bottom: {
		width: "100%",
		margin: "40px auto 0",
		paddingBottom: "155px",
	},
	bottomMainPage: {},
	wrapperCenterSubheaderNone: {},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		wrapperCenter: {
			width: "800px",
		},
		bottom: {
			width: "100%",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		wrapper: {
			paddingTop: "40px",
		},
		wrapperCenter: {
			width: "600px",
		},
		wrapperCenterHeader: {
			fontSize: "48px",
			textAlign: "center",
			padding: 0,
		},
		wrapperCenterSubheader: {
			width: "100%",
			fontSize: "18px",
			lineHeight: 1.22,
		},
		bottom: {
			width: "100%",
			paddingBottom: "150px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		wrapper: {
			padding: "90px 0 0",
			width: "100%",
		},
		wrapperCenter: {
			width: "100%",
			textAlign: "center",
		},
		wrapperCenterHeader: {
			fontSize: "30px",
			padding: 0,
			textAlign: "center",
		},
		wrapperCenterSubheader: {
			width: "100%",
			fontSize: "14px",
			lineHeight: "1.29",
			margin: "40px auto 0",
			padding: "0 8.33%",
		},
		wrapperCenterSubheaderNone: {
			display: "none",
		},
		bottom: {
			width: "100%",
			paddingBottom: "20px",
		},
		bottomMainPage: {
			margin: "10px auto 0",
		},
	},
});

class BannerFrame extends React.Component {
	componentDidMount() {
		Metrika.getInstance().google.sendEvent("GO", "LOAD_GO");
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		Metrika.getInstance().google.sendEvent("GO", "LOAD_GO");
	}

	render() {
		let classes = this.props.classes;
		return (
			<div className={classNames(classes.root)}>
				<div className="container">
					<div className={classes.wrapper}>
						<div className={classes.wrapperCenter}>
							<h1 className={classes.wrapperCenterHeader}>{this.props.title}</h1>
							{this.props.subheader && (
								<div
									className={classNames(classes.wrapperCenterSubheader, {
										[classes.wrapperCenterSubheaderNone]:
											isMainSiteCode(this.props.siteCode) && this.props.pathName === MainNoRegion,
									})}
								>
									{this.props.subheader}
								</div>
							)}
						</div>
						<div
							className={classNames(classes.bottom, {
								[classes.bottomMainPage]: this.props.mainPage,
							})}
						>
							{this.props.bottomComponent}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(BannerFrame);
