import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	icon: {
		display: "inline-block",
		transform: "rotate(90deg)",
		verticalAlign: "middle",
		width: ({ size }) => size,
		height: ({ size }) => size,
		"& svg": {
			fill: theme.palette.colors.purplishBlue,
			stroke: theme.palette.colors.purplishBlue,
		},
		"& path": {
			strokeWidth: "11px",
		},
	},
}));

const SimCardIcon = ({ size = 24, color = "#000", className }) => {
	const classes = useStyles({ size });

	return (
		<span className={classNames(classes.icon)}>
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 511.893 511.893"
				width={size}
				fill={color}
				stroke={color}
				className={className}
			>
				<path
					d="M331.04,202.667H164.107c-14.507,0-25.493,12.053-25.493,27.947v186.133c0,15.04,9.707,31.253,25.493,31.253H331.04
				c15.36,0,31.573-16.107,31.573-31.253V230.613C362.613,214.827,345.653,202.667,331.04,202.667z M341.28,416.747
				c0,3.307-6.827,9.92-10.24,9.92h-21.76v-64c0-5.867-4.8-10.667-10.667-10.667c-5.867,0-10.667,4.8-10.667,10.667v64h-32v-64
				c0-5.867-4.8-10.667-10.667-10.667c-5.867,0-10.667,4.8-10.667,10.667v64H213.28v-64c0-5.867-4.8-10.667-10.667-10.667
				c-5.867,0-10.667,4.8-10.667,10.667v64h-27.84c-0.96,0-4.16-4.267-4.16-9.92V230.613c0-0.32,0-6.613,4.16-6.613h27.84v53.333
				c0,5.867,4.8,10.667,10.667,10.667c5.867,0,10.667-4.8,10.667-10.667V224h21.333v53.333c0,5.867,4.8,10.667,10.667,10.667
				c5.867,0,10.667-4.8,10.667-10.667V224h32v53.333c0,5.867,4.8,10.667,10.667,10.667c5.867,0,10.667-4.8,10.667-10.667V224h21.76
				c4.587,0,10.24,4.48,10.24,6.613V416.747z"
				/>
				<path
					d="M445.707,77.867l-64-64.747C373.6,4.8,362.4,0,350.667,0H97.333C73.013,0,53.28,19.947,53.28,44.48v422.933
				c0,24.533,19.733,44.48,44.053,44.48H414.56c24.32,0,44.053-19.947,44.053-44.48V109.227
				C458.613,97.493,454.027,86.187,445.707,77.867z M437.28,467.52c0.107,12.693-10.027,23.04-22.72,23.147H97.333
				c-12.693-0.107-22.827-10.56-22.72-23.147V44.48c-0.107-12.587,10.027-23.04,22.72-23.147h253.333c5.973,0,11.84,2.453,16,6.72
				L430.56,92.8c4.267,4.373,6.72,10.347,6.72,16.427V467.52z"
				/>
			</svg>
		</span>
	);
};

export default SimCardIcon;
