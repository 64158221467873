import { observable, action } from "mobx";
import Cookies from "js-cookie";

export default class RegionConfirmationModel {
	@observable isViewRegionConfirm = false;

	constructor() {
		this.isViewRegionConfirm = false;
		let isViewRegionConfirm = Cookies.get("isViewRegionConfirm");
		if (isViewRegionConfirm) {
			if (isViewRegionConfirm == "false") {
				this.isViewRegionConfirm = false;
			} else {
				this.isViewRegionConfirm = true;
			}
		} else {
			this.isViewRegionConfirm = true;
		}
	}

	getConfirmView = () => {
		return this.isViewRegionConfirm;
	};

	toggle = () => {
		this.isViewRegionConfirm = !this.isViewRegionConfirm;
		Cookies.set("isViewRegionConfirm", false, { expires: 14 });
	};
}
