import * as React from "react"; // React is now patched
import { withRouter } from "react-router-dom";
import loadable from "@loadable/component";

const Main = loadable(() => import("./../routes"));
const Layout404 = loadable(() => import("Layouts/404Layout"));
const YandexCaptcha = loadable(() => import("Layouts/Yandex/Captcha"));

@withRouter
export default class AppHOC extends React.Component {
	componentDidCatch(e) {
		//Нужно ли здесь отправлять событие в Sentry как-то по-особенному
	}

	render() {
		let location = this.props.location;
		if (location.state?.isYandexCaptcha) {
			return <YandexCaptcha ipAddress={location.state?.ipAddress} />;
		}

		if (location.state && location.state.is404) {
			return <Layout404 />;
		}

		return <Main />;
	}
}
