import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.colors.paleGrey,
		position: "relative",
		"&$newsView, &$authView": {
			width: "100vw",
			minHeight: "100%",
			zIndex: 10,
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			backgroundColor: "rgba(43, 29, 92, 0.3)",
			"& $closeWrapper": {
				backgroundColor: "rgba(0, 0, 0, 0.7)",
				border: "none",
				borderRadius: "50%",
				"& span": {
					color: theme.palette.colors.white,
				},
			},
		},
	},
	closeWrapper: {
		cursor: "pointer",
		width: "60px",
		height: "60px",
		borderRadius: "50%",
		border: `solid 1px ${theme.palette.colors.lightGrayishViolet}`,
		position: "absolute",
		top: "50px",
		right: "50px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		zIndex: "10001",
		backgroundColor: theme.palette.colors.paleGrey,
		"& span": {
			fontSize: "16px",
			color: theme.palette.colors.lightGrayishViolet,
		},
	},
	fullCloseWrapper: {},
	closeWrapperOnlyDesktop: {},
	newsView: {},
	authView: {},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		closeWrapper: {
			top: "41px",
			right: "41px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		newsView: {},
		authView: {},
		root: {
			"&$newsView, &$authView": {
				height: "1px",
				padding: "20px 0 0 0",
				"& $closeWrapper": {
					position: "absolute",
					right: "20px",
					top: "-25px",
					width: "50px",
					height: "50px",
					"& span": {
						color: theme.palette.colors.white,
					},
				},
			},
		},
		closeWrapper: {
			width: "auto",
			height: "auto",
			borderRadius: 0,
			border: "none",
			top: "20px",
			right: "20px",
			"& span": {
				fontSize: "16px",
			},
		},
		fullCloseWrapper: {
			width: "50px",
			height: "50px",
			borderRadius: "50%",
			border: `solid 1px ${theme.palette.colors.purplishBlue}`,
			top: "20px",
			right: "20px",
		},
		closeWrapperOnlyDesktop: {
			display: "none",
		},
	},
	[`@media (max-width: 450px)`]: {
		newsView: {
			position: "absolute",
			bottom: 0,
			alignItems: "flex-end",
		},
	},
});

class ModalLayout extends React.Component {
	static defaultProps = {
		closeModal: () => {},
		closeOnClickLayout: false,
		withoutClose: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			counter: null,
		};
	}

	timer = null;
	layout = null;

	componentWillUnmount() {
		this.clearInterval();
	}

	clearInterval() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	startCounter() {
		if (this.state.counter === 0) {
			return;
		}
		this.timer = setInterval(() => {
			if (this.state.counter === 1) {
				!!this.props.closeModal && this.props.closeModal();
				return;
			}
			this.setState({
				counter: this.state.counter ? this.state.counter - 1 : 15,
			});
		}, 1000);
	}

	checkOnClose(e) {
		if (e.target == this.layout && this.props.closeOnClickLayout) {
			!!this.props.closeModal && this.props.closeModal();
		}
	}

	render() {
		let classes = this.props.classes;
		if (this.props.autoclose && !this.timer) {
			this.startCounter();
		}
		return (
			<div
				className={classNames(classes.root, {
					[classes.newsView]: this.props.newsView,
					[classes.authView]: this.props.authView,
				})}
				ref={(layout) => (this.layout = layout)}
				onClick={(e) => this.checkOnClose(e)}
			>
				{!this.props.withoutClose && (
					<div
						className={classNames(classes.closeWrapper, {
							[classes.fullCloseWrapper]: this.props.fullCloseElement,
							[classes.closeWrapperOnlyDesktop]: this.props.onlyDesktopCloseElement,
						})}
						role={"button"}
						onClick={this.props.closeModal}
					>
						{!this.state.counter && <span className="icon24 icon-close" />}
						{this.state.counter && <span> {this.state.counter}</span>}
					</div>
				)}
				{this.props.children}
			</div>
		);
	}
}

export default withStyles(styles)(ModalLayout);
export { ModalLayout as __Modal };
