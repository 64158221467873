import React from "react";
import loadable from "@loadable/component";
import LoadingSpinnerHOC from "HOC/LoadingSpinnerHOC";

export const Autonomous = loadable(() => import("./autonomous"));
export const Districts = loadable(() => import("./districts"), {
	fallback: <LoadingSpinnerHOC />,
});
export const Streets = loadable(() => import("./streets"), {
	fallback: <LoadingSpinnerHOC />,
});
export const Street = loadable(() => import("./street"), {
	fallback: <LoadingSpinnerHOC />,
});
export const House = loadable(() => import("./house"), {
	fallback: <LoadingSpinnerHOC />,
});
export const DomaNzl = loadable(() => import("./domaNzl"), {
	fallback: <LoadingSpinnerHOC />,
});
export const AdministrativeDistricts = loadable(() => import("./administrativeDistricts"));
