import Metrika from "Services/Metrika";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";

const REQ_NAMES = {
	ORDER_HOME: "order_home",
	ORDER_OUTHOME: "order_outhome",
	SUBSCRIBE: "subscribe",
	ADD_REVIEW: "add_review",
	FEEDBACK: "feedback",
	ADD_COMMENT: "add_comment",
	CALLBACK: "callback",
	TENDER: "tender",
	REVIEW_COUNTER_VIEW: "review_counter_view",
};

function failHandler(name, outData, inData) {
	let params = {
		error: {
			name,
			outData,
			inData: inData || "Апи лежит",
			url: !!window && window.location.href,
		},
	};
	Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_CLICK_BUTTON, "click_error_request");
}

export { failHandler, REQ_NAMES };
