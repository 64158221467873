import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			display: "flex",
			alignItems: "center",
			cursor: "pointer",
			position: "relative",
		},
		showMobile: {},
		icon: {
			fontSize: "24px",
			color: theme.palette.colors.white,
			marginRight: "23px",
		},
		name: {
			fontFamily: theme.fonts.bold,
			fontSize: "14px",
			lineHeight: 1,
			letterSpacing: "2px",
			color: theme.palette.colors.white,
			textTransform: "uppercase",
			maxWidth: "100%",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
		},
		purpleTheme: {
			"& $icon": {
				color: theme.palette.colors.purplishBlue,
			},
			"& $name": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		wrapText: {
			whiteSpace: "normal",
			lineHeight: 2,
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			icon: {
				fontSize: "20px",
				marginRight: "13px",
			},
			name: {
				fontFamily: theme.fonts.medium,
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			icon: {
				fontSize: "20px",
				marginRight: "12px",
			},
			name: {
				fontSize: "12px",
				lineHeight: 1.17,
				letterSpacing: "1.7px",
				fontFamily: theme.fonts.medium,
			},
			wrapText: {
				lineHeight: 2,
			},
		},
	};
};

class LocationBar extends React.Component {
	static defaultProps = {
		clickCallback: () => {},
	};

	render() {
		let currentRegion = this.props.currentRegion;
		let classes = this.props.classes;
		let text = this.props.text;
		return (
			<div
				className={classNames(classes.root, {
					[classes.purpleTheme]: this.props.purpleTheme,
				})}
				onClick={this.props.clickCallback}
			>
				<span className={classNames(classes.icon, "icon24", "icon-location-2")} />
				<span
					className={classNames(classes.name, {
						[classes.wrapText]: this.props.wrapNameRegion,
					})}
				>
					{!!currentRegion.name ? currentRegion.name : text.CHOOSE_CITY}
				</span>
				{/* {this.props.viewConfirm && (
                    <React.Fragment>
                        <DeclinationContainerNew>
                            <RegionConfirmationContainer
                                toggle={this.props.toggle}
                                mobileMenu={this.props.mobileMenu}
                            />
                        </DeclinationContainerNew>
                    </React.Fragment>
                )} */}
			</div>
		);
	}
}

export default withStyles(styles)(LocationBar);

// import React from "react";
// import classNames from "classnames";
// import { withStyles } from "react-jss";
// import RegionConfirmationContainer from "Containers/RegionConfirmationContainer";
// import DeclinationContainerNew from "Containers/DeclinationContainerNew";
// import { observer, inject } from "mobx-react";

// const styles = theme => {
//     return {
//         root: {
//             display: "flex",
//             alignItems: "center",
//             cursor: "pointer",
//             position: "relative",
//         },
//         showMobile: {},
//         icon: {
//             fontSize: "24px",
//             color: theme.palette.colors.white,
//             marginRight: "23px",
//         },
//         name: {
//             fontFamily: theme.fonts.bold,
//             fontSize: "14px",
//             lineHeight: 1,
//             letterSpacing: "2px",
//             color: theme.palette.colors.white,
//             textTransform: "uppercase",
//             maxWidth: "100%",
//             whiteSpace: "nowrap",
//             textOverflow: "ellipsis",
//             overflow: "hidden",
//         },
//         purpleTheme: {
//             "& $icon": {
//                 color: theme.palette.colors.purplishBlue,
//             },
//             "& $name": {
//                 color: theme.palette.colors.purplishBlue,
//             },
//         },
//         [`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
//             icon: {
//                 fontSize: "20px",
//                 marginRight: "13px",
//             },
//             name: {
//                 fontFamily: theme.fonts.medium,
//             },
//         },
//         [`@media (max-width: ${theme.media.sm})`]: {
//             icon: {
//                 fontSize: "20px",
//                 marginRight: "12px",
//             },
//             name: {
//                 fontSize: "12px",
//                 lineHeight: 1.17,
//                 letterSpacing: "1.7px",
//                 fontFamily: theme.fonts.medium,
//             },
//         },
//     };
// };

// @withStyles(styles)
// export default class LocationBar extends React.Component {
//     static defaultProps = {
//         clickCallback: () => {},
//     };

//     render() {
//         let currentRegion = this.props.currentRegion;
//         let classes = this.props.classes;
//         let text = this.props.text;
//         return (
//             <div
//                 className={classNames(classes.root, {
//                     [classes.purpleTheme]: this.props.purpleTheme,
//                 })}
//                 onClick={this.props.clickCallback}
//             >
//                 <span
//                     className={classNames(
//                         classes.icon,
//                         "icon24",
//                         "icon-location-2"
//                     )}
//                 />
//                 <span className={classes.name}>
//                     {!!currentRegion.name
//                         ? currentRegion.name
//                         : text.CHOOSE_CITY}
//                 </span>
//                 {this.props.viewConfirm && (
//                     <React.Fragment>
//                         <DeclinationContainerNew>
//                             <RegionConfirmationContainer
//                                 toggle={this.props.toggle}
//                                 mobileMenu={this.props.mobileMenu}
//                             />
//                         </DeclinationContainerNew>
//                     </React.Fragment>
//                 )}
//             </div>
//         );
//     }
// }
