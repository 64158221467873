var gql = require("@apollo/client").gql;

const REFINE_FULL_ADDRESS_QUERY_V2 = gql`
	query REFINE_FULL_ADDRESS_QUERY_V2(
		$region_guid: String
		$house_guid: String
		$street_guid: String
		$house: String
		$building: String
		$construction: String
	) {
		refineFullAddressV2(
			region_guid: $region_guid
			house_guid: $house_guid
			street_guid: $street_guid
			house: $house
			building: $building
			construction: $construction
		) {
			address_id
			street_id
			district_id
			region_id
		}
	}
`;

export default REFINE_FULL_ADDRESS_QUERY_V2;
