import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import loadable from "@loadable/component";
import { isMainSiteCode } from "Utils/siteConfig";

const DetectRegionContainer = loadable(() => import("Containers/DetectRegionContainer"), {
	ssr: false,
});

const styles = (theme) => {
	return {
		root: {
			backgroundColor: theme.palette.colors.transparent,
			position: "absolute",
			// top: 0,
			left: 0,
			width: "100%",
			zIndex: "9000",
		},
		showMobile: {},
		upper: {},
		bottom: {},
		[`@media (max-width: ${theme.media.sm})`]: {
			bottom: {
				display: "none",
			},
		},
	};
};

class Header extends React.Component {
	static defaultProps = {
		upperPart: null,
		bottomPart: null,
	};

	staticScrollFunc = (e) => this.scrollFunc(e);

	componentDidMount() {
		document.addEventListener("scroll", this.staticScrollFunc);
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.staticScrollFunc);
	}

	scrollFunc(event) {
		var scrolled = window.pageYOffset || document.documentElement.scrollTop;
		if (scrolled > this.header.clientHeight && !this.props.showNavigation) {
			this.props.changeShowNavigation(true);
		}

		if (scrolled <= this.header.clientHeight && this.props.showNavigation) {
			this.props.changeShowNavigation(false);
		}
	}

	render() {
		let classes = this.props.classes;
		return (
			<div
				id="HeaderMenu"
				ref={(header) => (this.header = header)}
				className={classNames(classes.root, {
					[classes.showMobile]: this.props.showMobile,
				})}
			>
				<div className={classes.upper}>{this.props.upperPart}</div>
				<div className={classes.bottom}>{this.props.bottomPart}</div>
				{this.props.mobileWindow}
				{/*{this.props.fixedNavigation}*/}
				{isMainSiteCode(this.props.siteCode) && <DetectRegionContainer />}
			</div>
		);
	}
}

export default withStyles(styles)(Header);
