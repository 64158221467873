var gql = require("@apollo/client").gql;

const SEARCH_HOUSE_QUERY_V6 = gql`
	query SEARCH_HOUSE_QUERY_V6(
		$query_data: SearchAddressQueryData
		$user_info: SearchAnalyticsUserInfo
		$experiments: [Experiment]
	) {
		searchHousesV6(query_data: $query_data, user_info: $user_info, experiments: $experiments) {
			data {
				highlight {
					full
				}
				data {
					id
					region_id
					district_id
					street_id
					house
					building
					construction
					full
					url
					district_name
					street_name
					street_type
				}
			}
		}
	}
`;
export default SEARCH_HOUSE_QUERY_V6;
