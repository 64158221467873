import React, { Component } from "react";
import withStyles from "react-jss";
import Metrika from "Services/Metrika";
import { YANDEX_TARGETS } from "Constants/metriks/yandex-targets";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";

const styles = (theme) => ({
	root: {
		display: "none",
		width: "100%",
		padding: "0 20px",
		marginBottom: "32px",
	},
	header: {
		fontFamily: theme.fonts.bold,
		fontSize: "24px",
		color: theme.palette.colors.darkSlateBlue,
		textAlign: "center",
		marginBottom: "10px",
	},
	generalWrapper: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
	},
	wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "1px solid #e4e7f1",
		borderRadius: "10px",
		backgroundColor: theme.palette.colors.white,
		width: "29%",
		margin: "10px 5px",
		height: "80px",
		textAlign: "center",
	},
	image: {
		maxWidth: "95%",
		maxHeight: "95%",
		padding: "5px",
		height: "auto",
		objectFit: "scale-down",
	},
	[`@media (max-width:${theme.media.sm})`]: {
		root: {
			display: "block",
		},
	},
});

class TopProviders extends Component {
	onClickComponent(e) {
		e.stopPropagation();
		Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.CLICK_PROVIDER_FROM_TOP, []);
	}
	render() {
		let { classes, data, title, declinations, linkComponent, linkGeneratorRates, seoBlock } =
			this.props;
		return (
			<div className={classes.root}>
				<HeaderTextSeo
					header={seoBlock?.seoHeader || `${title} ${declinations}`}
					text={seoBlock?.seoText}
				/>
				<div className={classes.generalWrapper}>
					{data.map((elem, index) => {
						return React.cloneElement(linkComponent, {
							onClick: (e) => this.onClickComponent(e),
							to: linkGeneratorRates(elem),
							className: classes.wrapper,
							key: index,
							children: (
								<img
									width={360}
									height={180}
									alt={elem.name}
									className={classes.image}
									src={elem.logo}
								/>
							),
							datatest: "top_provider_component",
						});
					})}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(TopProviders);
