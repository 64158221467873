import React from "react";
import withStyles from "react-jss";

const styles = (theme) => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: "0 20px",
	},
	mobileInfoWrapper: {
		display: "flex",
		justifyContent: "center",
		margin: "0 -8px",
	},
	mobileInfoItem: {
		margin: "0 8px",
	},
	mobileInfoItemValue: {
		fontFamily: theme.fonts.bold,
		textTransform: "uppercase",
		fontSize: "18px",
		lineHeight: "1.29",
		color: theme.palette.colors.darkSlateBlue,
		textAlign: "center",
		marginBottom: "5px",
		"& span": {
			fontSize: "18px",
			color: theme.palette.colors.darkSlateBlue,
		},
	},
	mobileInfoItemMeasure: {
		fontFamily: theme.fonts.medium,
		textTransform: "uppercase",
		fontSize: "12px",
		lineHeight: "1.29",
		letterSpacing: "2px",
		color: theme.palette.colors.oneMoreGrey,
		textAlign: "center",
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			padding: "0 10px",
		},
		mobileInfoWrapper: {
			flexWrap: "wrap",
			margin: "0 -8px -5px",
		},
		mobileInfoItem: {
			margin: "0 5px 5px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			padding: "0",
			alignItems: "center",
		},
		mobileInfoItem: {
			display: "flex",
			alignItems: "center",
		},
		mobileInfoItemValue: {
			fontSize: "20px",
			marginBottom: 0,
			marginRight: "5px",
			"& span": {
				fontSize: "18px",
			},
		},
		mobileInfoWrapper: {
			width: "100%",
		},
	},
});

const MobileData = ({ data, classes }) => (
	<div className={classes.root}>
		<div className={classes.mobileInfoWrapper}>
			{!data && <div className={classes.mobileInfoItemValue}> - </div>}
			{!!data &&
				data.map((item, index) => (
					<div key={index} className={classes.mobileInfoItem}>
						<div className={classes.mobileInfoItemValue}>
							{!item.type && "-"}
							{item.type === 1 && <span className="icon24 icon-infinite" />}
							{item.type === 2 && item.value}
						</div>
						<div className={classes.mobileInfoItemMeasure}>{item.measure}</div>
					</div>
				))}
		</div>
	</div>
);

export default withStyles(styles)(MobileData);
