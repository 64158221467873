import React from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Cookies from "js-cookie";
import { sendMetriks } from "Services/sendMetriks";
import configRouters from "Configs/routes";
import FindTariffsProviderWithPhoneContainer from "Containers/FindTariffsProviderWithPhoneContainer";

@withRouter
@inject("ApplicationModel")
@observer
export default class ProviderConnectWithPhoneHOC extends React.Component {
	openPopup(data) {
		data?.preventDefault && data.preventDefault();
		this.closeModal();

		if (configRouters.RatesInHouse.route === this.props.match.path) {
			Cookies.set("connectTariffFrom", "from_popular_house");
		} else if (configRouters.AddressStreet.route === this.props.match.path) {
			Cookies.set("connectTariffFrom", "from_street");
		} else if (configRouters.AddressStreets.route === this.props.match.path) {
			Cookies.set("connectTariffFrom", "from_district");
		} else if (this.props.ratingClick) {
			Cookies.set("connectTariffFrom", "rating_from_main");
			sendMetriks("CLICK_CALLBACK_FROM_RATING_MAIN");
		} else if (this.props.footer) {
			Cookies.set("connectTariffFrom", "callback_footer");
		} else if (this.props.header) {
			Cookies.set("connectTariffFrom", "callback_phone_button_1");
		} else if (this.props.phone_button) {
			Cookies.set("connectTariffFrom", "callback_phone_button");
		} else if (this.props.banner_phone) {
			Cookies.set("connectTariffFrom", "callback_banner_phone_button");
		} else if (this.props.green_form) {
			Cookies.set("connectTariffFrom", "callback_green_form");
		} else if (this.props.installer) {
			Cookies.set("connectTariffFrom", "TC");
		}

		sendMetriks("OPEN_CALLBACK");

		let texts = this.props.ApplicationModel.text;
		let modalModel = this.props.ApplicationModel.modalModel;
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		commonViewModel.setShowFindTariffs(false);
		let promise1 = import("Layouts/modalLayout");
		Promise.all([promise1]).then((modules) => {
			let ModalLayout = modules[0].default;
			commonViewModel.setShowSearchAddressPopup(true);
			modalModel.toggleModal(
				<ModalLayout
					authView={true}
					closeModal={() => this.closeModal()}
					withoutClose={true}
					fullCloseElement={true}
					closeOnClickLayout={true}
					onlyDesktopCloseElement={true}
				>
					<FindTariffsProviderWithPhoneContainer
						text={texts}
						onClose={() => this.closeModal()}
						providerId={this.props.providerId}
					/>
				</ModalLayout>
			);
		});
	}

	closeModal() {
		let modalModel = this.props.ApplicationModel.modalModel;
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		commonViewModel.setShowSearchAddressPopup(false);
		if (modalModel.isOpen) {
			modalModel.toggleModal();
		}
	}

	render() {
		return React.cloneElement(this.props.children, {
			onClick: (data) => this.openPopup(data),
		});
	}
}
