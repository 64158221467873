import React from "react";
import ReactDOM from "react-dom";
import * as classNames from "classnames";

const staticStyles = {
	position: "static",
};

export default class DropdownList extends React.Component {
	portal = null;

	componentDidMount() {
		this.initDropdownList();
	}

	componentDidUpdate(prevProps) {
		let isChanged = false;

		// Когда вводишь данные, переоперелить положение выпадающего списка
		if (
			this.props.datatest === "main_input_full_address_home_new_with_phone" &&
			prevProps.value !== this.props.value
		) {
			isChanged = true;
		}

		Object.keys(this.props.windowSizes).forEach((item) => {
			if (this.props.windowSizes[item] !== prevProps.windowSizes[item]) {
				isChanged = true;
			}
		});

		isChanged && this.initDropdownList();
	}

	initDropdownList() {
		this.props.onMount(this.portal);
		const target = ReactDOM.findDOMNode(this.props.target);
		let targetCoords = target.getBoundingClientRect();
		let heightPortal = parseInt(
			window.getComputedStyle(this.portal).getPropertyValue("max-height")
		);

		let targetBottom = null;
		if (!this.props.listContainerFunc) {
			targetBottom = !this.props.isFixedList
				? document.body.clientHeight - targetCoords.bottom
				: window.innerHeight - targetCoords.bottom;
		} else {
			let container = this.props.listContainerFunc();
			targetBottom = container.clientHeight - targetCoords.bottom;
		}

		if (targetBottom > heightPortal) {
			this.calculateListFromBottom();
			return;
		}

		if (targetCoords.top > heightPortal) {
			this.calculateListFromTop();
			return;
		}
		this.calculateListFromBottom();
	}

	calculateListFromTop() {
		this.props.setDirection("up");
		const target = ReactDOM.findDOMNode(this.props.target);
		const portal = this.portal;
		let targetCoords = target.getBoundingClientRect();
		let body = document.body;
		let width = targetCoords.width;
		let left = null;
		let top = null;
		if (!this.props.listContainerFunc) {
			left = targetCoords.left;
			top = window.pageYOffset || body.scrollTop;
			top = !this.props.isFixedList ? top + targetCoords.top : targetCoords.top;
		} else {
			let container = this.props.listContainerFunc();
			let containerCoords = container.getBoundingClientRect();
			left = targetCoords.left - containerCoords.left;
			top = !this.props.isFixedList ? targetCoords.top - containerCoords.top : targetCoords.top;
		}

		portal.style.top = top + "px";
		portal.style.left = left + "px";
		portal.style.width = width + "px";
	}

	calculateListFromBottom() {
		this.props.setDirection("down");
		const target = ReactDOM.findDOMNode(this.props.target);
		const portal = this.portal;
		let targetCoords = target.getBoundingClientRect();
		let body = document.body;
		let width = targetCoords.width;
		let left = null;
		let top = null;

		if (!this.props.listContainerFunc) {
			left = targetCoords.left;
			top = window.pageYOffset || body.scrollTop;
			top = !this.props.isFixedList
				? top + targetCoords.top + targetCoords.height
				: targetCoords.top + targetCoords.height;
		} else {
			let container = this.props.listContainerFunc();
			let containerCoords = container.getBoundingClientRect();
			left = targetCoords.left - containerCoords.left;
			top = !this.props.isFixedList
				? targetCoords.top - containerCoords.top + targetCoords.height
				: targetCoords.top;
		}

		portal.style.top = top + "px";
		portal.style.left = left + "px";
		portal.style.width = width + "px";
	}

	generateContent() {
		let text = this.props.text;
		let classes = this.props.classes;
		if (
			!this.props.items.length &&
			this.props.value.length >= this.props.minLenValue &&
			!this.props.correctAddress
		) {
			return (
				<li className={classNames(classes.listItem, classes.listItemError)}>
					{text.SEARCH_ADDRESS_NO_VARIANTS}
				</li>
			);
		}

		return this.props.items.map((item, index) => {
			const text = this.props.textField ? item[this.props.textField] : item.highlight?.full;

			return (
				<li
					key={index}
					className={classNames(classes.listItem, {
						[classes.listItemIsSelected]: index === this.props.currentElementPos - 1,
					})}
					datatest="dropdown_list_main"
					onMouseDown={(e) => {
						this.props.selectItem(e, item);
					}}
					dangerouslySetInnerHTML={{
						__html: text,
					}}
				/>
			);
		});
	}

	render() {
		let classes = this.props.classes;

		let container = document.querySelector("#forSelectField");
		if (!!this.props.listContainerFunc) {
			let newContainer = this.props.listContainerFunc();
			if (!!newContainer) {
				container = newContainer;
			}
		}

		return ReactDOM.createPortal(
			<div className={this.props.rootClassName} style={staticStyles}>
				<div className={this.props.wrapperClassName} style={staticStyles}>
					<div
						className={classNames(classes.portal, classes.list, classes.listIsOpen, {
							[classes.isFixedList]: !!this.props.isFixedList,
						})}
						ref={(portal) => {
							this.portal = portal;
							this.props.refList(portal);
						}}
					>
						{this.generateContent()}
					</div>
				</div>
			</div>,
			container
		);
	}
}
