import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
	DISTRICT_ONE_QUERY,
	HOUSE_FULL_QUERY_V4,
	STREET_ONE_QUERY,
	ADDRESS_INTER_LINKS_V2,
	STREET_INTER_LINK,
} from "Services/queries";
import useDefaultParams from "Services/useDefaultParams";
import withDefaultParams, { generateUrl } from "Services/withDefaultParams";
import images from "Configs/images.config";
import Routes from "Configs/routes";
import { replaceStaticHost } from "Helpers/ImageManager";
import UrlManager from "Helpers/UrlManager";
import SeoBlockHOC from "HOC/SeoBlockHOC";
import { useGetCountProviders, useGetCountTariffs } from "hooks/countQueries";
import WithSeoText from "Layouts/WithSeoText";
import DeclinationContainerNew from "Containers/DeclinationContainerNew";
import BreadCrumbsContainer from "Containers/BreadCrumbsContainer";
import { PageTitleWrapper, PageTitleControls, PageTitleHeader } from "ComponentsUI/PageTitleBanner";
import { CardsLinkNew } from "ComponentsUI/CardsLinkNew";

const WithBanner = (props) => {
	const {
		ApplicationModel: { compareTarifficationsModel, text, routesModel, sendingStore },
		match: { path },
		route,
	} = props;

	const routerParams = useParams();
	const defaultParams = useDefaultParams(routerParams);

	const one_string_search =
		props.ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled;
	const searchModel = one_string_search
		? props.ApplicationModel.searcheModelOneString
		: props.ApplicationModel.searcheModel;

	const onClickHouseHandler = (house_id) => {
		props.ApplicationModel.commonViewModel.changeShowModalOnRates(true);
		props.ApplicationModel.commonViewModel.changeNameModalOnRates("cardLink");

		searchModel.setHouseId(house_id);
		window.scroll(0, 0);
	};

	let house_id;
	if (routerParams.house_id) {
		house_id = +routerParams.house_id;
	} else {
		house_id = searchModel.house_id;
	}

	const windowSizes = props.ApplicationModel.windowSizeModel.windowSizes;
	const region = props.ApplicationModel.currentRegion;

	let page = props?.match?.params?.page;
	let routeName = props?.route?.name || "";
	let filter = [];
	let hideText;

	const districtOneQuery = useQuery(DISTRICT_ONE_QUERY, {
		skip: !props.withDistrict || !Number.isInteger(parseInt(routerParams.districtId)),
		variables: {
			id: parseInt(routerParams.districtId),
		},
	});

	const districtOne = districtOneQuery?.data?.districtsOne;
	if (districtOne?.id) {
		routesModel.setDistrict(districtOne);
	}

	const streetOneQuery = useQuery(STREET_ONE_QUERY, {
		skip: !props.withStreet || !Number.isInteger(parseInt(routerParams.streetId)),
		variables: {
			id: parseInt(routerParams.streetId),
		},
	});

	const addressInterQuery = useQuery(ADDRESS_INTER_LINKS_V2, {
		skip: route?.name !== "RatesInHouse" || !Number.isInteger(parseInt(routerParams.house_id)),
		variables: {
			address_id: parseInt(routerParams.house_id),
		},
		// fetchPolicy: "network-only",
	});

	// Запрос для перелинковки улиц
	let streetInterQuery = useQuery(STREET_INTER_LINK, {
		skip: route?.name !== "AddressStreet" || !Number.isInteger(parseInt(routerParams.streetId)),
		variables: {
			street_id: parseInt(routerParams.streetId),
		},
		// fetchPolicy: "network-only",
	});

	let streets = null;

	if (streetInterQuery) {
		streets = streetInterQuery.data?.streetInterLink?.data;
	}

	const streetsOne = streetOneQuery?.data?.streetsOne;
	if (streetsOne) {
		routesModel.setStreet(streetsOne);
	}

	const houseFullQuery = useQuery(HOUSE_FULL_QUERY_V4, {
		skip: !props.withHouseId || !Number.isInteger(parseInt(house_id)),
		variables: {
			id: parseInt(house_id),
		},
	});

	const houseFull = houseFullQuery?.data?.houseFullV4;
	if (houseFull?.district_id) {
		routesModel.setHouseFull(houseFull);
	}

	if (region?.url) {
		filter.push(`region.url=${region.url}`);
	}
	if (routeName.includes("Operatory")) {
		filter.push("mobile_operator=1");
	} else if (routeName.includes("RatesMobile")) {
		filter.push("provider.mobile_operator=1");
	}

	let { countProviders } = useGetCountProviders(filter.join("&"), !routeName.includes("Operatory"));
	let { countTariffs } = useGetCountTariffs(filter.join("&"), !routeName.includes("RatesMobile"));

	const imagePageTitleSvg = {
		lg: replaceStaticHost(images.heroBannerSvgLg),
		md: replaceStaticHost(images.heroBannerSvgMd),
		sm: replaceStaticHost(images.heroBannerSvgSm),
		xs: replaceStaticHost(images.heroBannerSvgXs),
	};

	const upper = <BreadCrumbsContainer white={!props.withoutBackground || props.darkBackground} />;

	const bottom = (
		<PageTitleHeader
			white={!props.withoutBackground || props.darkBackground}
			text={props.bannerText}
			textAfter={props.bannerTextAfter}
		/>
	);

	const controllers = (
		<PageTitleControls
			button={props.buttonControllers}
			visibleControllers={compareTarifficationsModel.visibleControllers}
			leftControllerIsDisabled={!compareTarifficationsModel.currentSlide}
			leftControllerClickHandler={compareTarifficationsModel.previusSlide}
			rightControllerIsDisabled={
				compareTarifficationsModel.currentSlide + compareTarifficationsModel.maxAmountCols >=
				compareTarifficationsModel.tariffs.length
			}
			rightControllerClickHandler={compareTarifficationsModel.nextSlide}
		/>
	);

	const seoData = props.seoData;
	const sortHouses = [];
	const sortStreets = [];

	let ContainerLink;
	let button = null;
	let marginBottom40 = false;
	const addressData = addressInterQuery?.data?.addressInterLinksV2?.data;

	// Некоторые дома должны переводить на уникальный урл
	if (addressData) {
		for (const address of addressData) {
			if (address.id !== house_id) {
				sortHouses.push({
					key: address.id,
					id: address.id,
					name: address.house,
					link: address.url
						? `${generateUrl(Routes.RatesInHouse.route, {
								regionUrl: defaultParams.regionUrl,
								house_url: address.url,
								house_id: address.id,
							})}`
						: `${generateUrl(Routes.DomaNzl.route, {
								regionUrl: defaultParams.regionUrl,
							})}`,
					onClickHandler: () => onClickHouseHandler(address.id),
				});
			}
		}

		// let header = `Другие дома в городе`;

		ContainerLink = (
			<SeoBlockHOC route={route} placeText="CardsLink">
				<DeclinationContainerNew>
					<CardsLinkNew data={addressData} place={"дома"} defaultItems={sortHouses} />
				</DeclinationContainerNew>
			</SeoBlockHOC>
		);
	}

	if (streets) {
		for (const street of streets) {
			sortStreets.push({
				id: street.id,
				name: street.full,
				link: generateUrl(Routes.AddressStreet.route, {
					regionUrl: defaultParams.regionUrl,
					districtName: UrlManager.generateValidChankUrl(street.district.name),
					districtId: street.district.id,
					streetName: UrlManager.generateStreetPart({
						type: street.type,
						name: street.name,
					}),
					streetId: street.id,
				}),
				onClickHandler: () => onClickHouseHandler(street),
			});
		}

		ContainerLink = (
			<SeoBlockHOC route={route} placeText="CardsLink">
				<DeclinationContainerNew>
					<CardsLinkNew data={streets} place={"улицы"} defaultItems={sortStreets} />
				</DeclinationContainerNew>
			</SeoBlockHOC>
		);
	}

	if (
		page ||
		(routeName.includes("Operatory") && !countProviders) ||
		(routeName.includes("RatesMobile") && !countTariffs)
	) {
		hideText = true;
	}
	// Изменить marginBottom у картинки по ссылке Providers
	if (Routes.Providers.route == path) {
		marginBottom40 = true;
	}

	return (
		<>
			<PageTitleWrapper
				upper={!props.withoutUpper ? upper : null}
				bottom={bottom}
				background={!props.withoutBackground ? imagePageTitleSvg : null}
				controllers={props.withControllers ? controllers : null}
				button={button}
				windowSizes={windowSizes}
				marginBottom40={marginBottom40}
				isMain={true}
				darkBackground={props.darkBackground}
			/>
			<WithSeoText
				seoData={seoData}
				hideText={hideText}
				withoutMarginTopSeoText={props.withoutMarginTopSeoText}
				text={text}
				routeName={routeName}
				streetId={+routerParams.streetId}
				house_id={+routerParams.house_id}
				sendingStore={sendingStore}
			>
				{props.children}
			</WithSeoText>
			{ContainerLink}
		</>
	);
};

export default withRouter(withDefaultParams(inject("ApplicationModel")(observer(WithBanner))));
