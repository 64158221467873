import React from "react";
import { createUseStyles } from "react-jss";
import TariffFormContainer from "../Containers/TariffOrderFormContainer";
import ABTestTariffOrderPopup from "Containers/ABTests/ABTestTariffOrderPopup";

const useStyles = createUseStyles((theme) => ({
	wrapper: {
		position: "relative",
	},
	root: {
		margin: "0 auto",
		maxWidth: "380px",
	},
	close: {
		position: "absolute",
		top: "20px",
		right: "20px",
		cursor: "pointer",
		"& span": {
			color: theme.palette.colors.disabled,
			fontSize: "16px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			maxWidth: "710px",
			flexWrap: "wrap",
		},
		close: {
			top: "35px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			maxWidth: "100%",
		},
		close: {
			top: "45px",
		},
	},
}));

const Component = (props) => {
	const { wrapper, root, close } = useStyles();

	return (
		<div className={wrapper}>
			<div className={root}>
				<ABTestTariffOrderPopup
					{...props}
					testName="testtest__popap_in_tariff"
					defaultContainer={<TariffFormContainer {...props} />}
				/>
			</div>
			<div tabIndex={0} role={"button"} className={close} onClick={props.closeModal}>
				<span className="icon24 icon-close" />
			</div>
		</div>
	);
};
export default Component;
