var gql = require("@apollo/client").gql;

const SEO_QUERY = gql`
	query Seo($key: String!, $query: String) {
		seoV3(key: $key, query: $query) {
			id
			title
			description
			keywords
			h1
			h1After
			main_text
			ttltext
			aboutWETitle
			aboutWEText
			faq {
				question
				answer
			}
		}
	}
`;
module.exports = SEO_QUERY;
