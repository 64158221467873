var trim = require("lodash/trim");

var generateValidChankUrl = function (url) {
	if (url == undefined || url == null) {
		return "";
	}

	if (typeof url == "number") {
		url = url.toString();
	}

	if (typeof url !== "string") {
		return "";
	}

	let regex1 = /[^A-zА-я0-9]/g;
	let regex2 = /-{2,}/g;
	let validUrl = trim(
		url.toLowerCase().replace(["\\", "[", "]"], "-").replace(regex1, "-").replace(regex2, "-"),
		"-"
	);
	return validUrl;
};

const generateStreetPart = function (streetLikeObj) {
	return generateValidChankUrl(`${streetLikeObj.type || ""}-${streetLikeObj.name || ""}`);
};

module.exports = {
	generateValidChankUrl: generateValidChankUrl,
	generateStreetPart: generateStreetPart,
};
