import { gql } from "@apollo/client";

const DECLINATION_QUERY = gql`
	query Declination($filter: String!) {
		region(filter: $filter) {
			id
			name
			is_capital
			declinations {
				nominative
				genitive
				dative
				accusative
				ablative
				prepositional
			}
		}
	}
`;

export default DECLINATION_QUERY;
