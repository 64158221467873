import React from "react";
import classNames from "classnames";
import { Col } from "ComponentsUI/Styles/Grid";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			padding: "36px 0 0",
		},
		wrapper: {},
		providerTheme: {
			padding: 0,
			"& $wrapper": {
				paddingLeft: "30px",
			},
		},
		whiteTheme: {
			backgroundColor: theme.palette.colors.white,
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				paddingTop: "15px",
			},
			wrapper: {},
			providerTheme: {
				paddingTop: 0,
				"& $wrapper": {
					paddingLeft: 0,
				},
			},
		},
	};
};

class BottomPart extends React.Component {
	render() {
		let classes = this.props.classes;
		return (
			<div
				className={classNames(classes.root, {
					[classes.showMobile]: this.props.showMobile,
					[classes.providerTheme]: this.props.providerTheme,
					[classes.whiteTheme]: this.props.whiteTheme,
				})}
			>
				<Container>
					<Row>
						<Col xs="12">
							<div className={classes.wrapper}>{this.props.menu}</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withStyles(styles)(BottomPart);
