import React from "react";
import { FindTariffs } from "ComponentsUI/Navigation";

export default class extends React.Component {
	render() {
		if (!this.props.declinations) {
			return null;
		}
		let text = this.props.text;
		let decl = this.props.declinations;
		let newViewSearch = this.props.showNewViewSearch;
		let header = `${text.FIND_TARIFFS_IN_TOWN_1} ${decl.prepositional} ${text.FIND_TARIFFS_IN_TOWN_2}`;
		return <FindTariffs {...this.props} header={header} showNewViewSearch={newViewSearch} />;
	}
}
