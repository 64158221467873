import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { DECLINATION_QUERY } from "Configs/queries";
import withDefaultParams from "Services/withDefaultParams";

const DeclinationContainerNew = (props) => {
	let regionUrl =
		props.currentRegionUrl || props.match.params.regionUrl || props.currentRegion?.url;

	const { data } = useQuery(DECLINATION_QUERY, {
		skip: !regionUrl,
		variables: {
			filter: `url=${regionUrl}`,
		},
	});

	const declinations = data?.region?.declinations;

	if (!declinations && !props.showContainer) {
		return null;
	}

	if (!declinations && props.showContainer) {
		return React.cloneElement(props.children, {
			...props,
			declinations: undefined,
		});
	}
	return React.cloneElement(props.children, {
		...props,
		declinations: declinations,
	});
};

export default withRouter(withDefaultParams(DeclinationContainerNew));
