import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			display: "flex",
		},
		button: {
			height: "auto",
			width: "180px",
		},
		slider: {
			height: "50px",
			width: "180px",
			marginLeft: "20px",
			backgroundColor: theme.palette.colors.transparent,
			borderRadius: "10px",
			border: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
			display: "flex",
		},
		sliderItem: {
			width: "50%",
			height: "50px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer",
			margin: 0,
			padding: 0,
			border: "none",
			backgroundColor: theme.palette.colors.transparent,
			"& span": {
				transition: "fill .3s ease",
				color: theme.palette.colors.lightGrayishViolet,
				fontSize: "24px",
			},
		},
		previous: {
			borderRight: `1px solid ${theme.palette.colors.lightGrayishViolet}`,
		},
		next: {},
		disabled: {
			"& span": {
				color: theme.palette.colors.oneMoreGrey,
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			button: {
				width: "140px",
			},
			slider: {
				height: "40px",
				width: "140px",
			},
			sliderItem: {
				height: "40px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				display: "none",
			},
		},
	};
};

class PageTitleControls extends React.Component {
	static defaultProps = {
		button: null,
		visibleControllers: false,
		leftControllerIsDisabled: false,
		leftControllerClickHandler: () => {},
		rightControllerIsDisabled: false,
		rightControllerClickHandler: () => {},
		classes: {},
	};

	static propTypes = {
		button: PropTypes.element,
		visibleControllers: PropTypes.bool,
		leftControllerIsDisabled: PropTypes.bool,
		leftControllerClickHandler: PropTypes.func,
		rightControllerIsDisabled: PropTypes.bool,
		rightControllerClickHandler: PropTypes.func,
	};

	render() {
		let classes = this.props.classes;
		return (
			<div className={classes.root}>
				<div className={classes.button}>{this.props.button}</div>
				{this.props.visibleControllers && (
					<div className={classes.slider}>
						<div
							className={classNames(classes.sliderItem, classes.previous, {
								[classes.disabled]: this.props.leftControllerIsDisabled,
							})}
							onClick={
								!this.props.leftControllerIsDisabled ? this.props.leftControllerClickHandler : null
							}
						>
							<span className="icon24 icon-arrow-1-left" />
						</div>
						<div
							className={classNames(classes.sliderItem, classes.next, {
								[classes.disabled]: this.props.rightControllerIsDisabled,
							})}
							onClick={
								!this.props.rightControllerIsDisabled
									? this.props.rightControllerClickHandler
									: null
							}
						>
							<span className="icon24 icon-arrow-1-right" />
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(PageTitleControls);
