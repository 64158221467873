import React from "react";
import withDefaultParams, { generateUrl } from "Services/withDefaultParams";
import { withRouter } from "react-router-dom";
import { EmptyStub } from "ComponentsUI/EmptyStub";
import Container from "reactstrap/lib/Container";
import { Col } from "ComponentsUI/Styles/Grid";
import Row from "reactstrap/lib/Row";
import Button from "ComponentsUI/Button";
import { observer, inject } from "mobx-react";
var Routes = require("Configs/routes");
import PlanBContainer from "Containers/PlanBContainer";

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
export default class TariffsContainer extends React.Component {
	resetFilters() {
		const {
			match: { params },
		} = this.props;
		const providersUrl = generateUrl(Routes.ProviderRates.route, {
			regionUrl: params.regionUrl,
			providerName: params.providerName,
		});
		this.props.history.push({
			pathname: providersUrl,
			search: ``,
		});
	}

	render() {
		const {
			match: { params },
		} = this.props;
		let text = this.props.ApplicationModel.text;

		const providersUrl = generateUrl(Routes.ProviderRates.route, {
			regionUrl: params.regionUrl,
			providerName: params.providerName,
		});

		if (!this.props.tariffs.length) {
			return (
				<Container>
					<Row>
						<Col
							lg={{ size: 4, offset: 4 }}
							md={{ size: 4, offset: 4 }}
							sm={{ size: 4, offset: 4 }}
							xs={{ size: 6, offset: 3 }}
						>
							<EmptyStub
								header={text.OOPS}
								text={text.OOPS_TEXT}
								icon={
									<span
										className="icon48 icon-hearts"
										style={{
											fontSize: "48px",
											color: "#5812fe",
										}}
									/>
								}
								button={
									<Button
										typeText={"small"}
										size={"low"}
										status={"purple"}
										onClick={() => this.resetFilters()}
									>
										<span
											className="icon48 icon-playlist-add"
											style={{
												fontSize: "24px",
												color: "#d3cee1",
											}}
										/>
										{text.RESET_FILTERS}
									</Button>
								}
							/>
						</Col>
					</Row>
				</Container>
			);
		}

		return (
			<PlanBContainer
				tariffs={this.props.tariffs}
				pagination={this.props.pagination}
				urlDefault={providersUrl}
				isRates={this.props.isRates}
				update_date={this.props.update_date}
				presenceBrand={this.props.presenceBrand}
				pageName={this.props.pageName}
				skipLayout={this.props.skipLayout}
			/>
		);
	}
}
