var gql = require("@apollo/client").gql;

const DISTRICT_ONE_QUERY = gql`
	query District($id: Int!) {
		districtsOne(id: $id) {
			id
			parentId
			name
			service
			region {
				id
				name
			}
		}
	}
`;

export default DISTRICT_ONE_QUERY;
