import React from "react";
import ReactDOM from "react-dom";
import Base from "ComponentsUI/Styles/Themes/Base";
import withStyles from "react-jss";
import classNames from "classnames";

const styles = (theme) => {
	return {
		root: {
			padding: "30px",
			boxShadow: "0 2px 20px 0 rgba(43, 29, 92, 0.3)",
			backgroundColor: theme.palette.colors.charcoalGrey,
			position: "absolute",
			top: "-20px",
			left: "0",
			maxWidth: "280px",
			minWidth: "210px",
			transform: "translate(-50%, -100%)",
			opacity: "1",
			visibility: "visible",
			transition: "opacity .3s ease",
			zIndex: "100002",
			textAlign: "left",
		},
		background: {
			position: "relative",
			width: "100vw",
			minHeight: "100vh",
		},
		priceSale: {
			padding: "15px 20px",
		},
		fromBottom: {
			transform: "translate(-50%, 0)",
			"& $triangle": {
				top: `0`,
			},
		},
		fromLeft: {
			transform: "translate(-100%, -50%)",
			"& $triangle": {
				top: `50%`,
				left: `100%`,
			},
		},
		fromRight: {
			transform: "translate(0, -50%)",
			"& $triangle": {
				top: `50%`,
				left: `-1px`,
			},
		},
		text: {
			fontFamily: "MuseoSansCyrl-300",
			fontSize: "14px",
			lineHeight: "1.14",
			color: "#ffffff",
			textTransform: "none",
			letterSpacing: "0px",
			marginTop: "8px",
		},
		triangle: {
			content: "",
			position: "absolute",
			width: "25px",
			height: "25px",
			transform: " rotate(45deg) translateX(-17px)",
			left: "50%",
			backgroundColor: theme.palette.colors.charcoalGrey,
			boxShadow: "0 2px 20px 0 rgba(43, 29, 92, 0.3)",
			bottom: "-20px",
		},
		triangleV2: {
			content: "",
			position: "absolute",
			width: "25px",
			height: "25px",
			transform: " rotate(45deg) translateX(-17px)",
			left: "35%",
			backgroundColor: theme.palette.colors.purplishBlue,
			boxShadow: "0 2px 20px 0 rgba(43, 29, 92, 0)",
			bottom: "-20px",
		},
		triangleV3: {
			left: "45%",
		},
		_hidden: {
			opacity: "0",
			visibility: "hidden",
		},
		tariffName: {
			padding: "10px 20px 20px",
			minWidth: "0",
			maxWidth: "100%",
			borderRadius: "10px",
			backgroundColor: theme.palette.colors.purplishBlue,
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			root: {
				maxWidth: "300px",
				minWidth: "220px",
			},
			tariffName: {
				padding: "10px 20px 20px",
				minWidth: "0",
				maxWidth: "100%",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				maxWidth: "230px",
				minWidth: "170px",
			},
			text: {
				fontSize: "12px",
				lineHeight: "1.17",
			},
			tariffName: {
				padding: "10px 20px 20px",
				minWidth: "0",
				maxWidth: "100%",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				maxWidth: "100000px",
				minWidth: "0%",
				width: "calc(100%-40px)",
				right: "20px",
				left: "20px",
				transform: "translate(0, -100%)",
			},
			priceSale: {
				// width: "50%"
			},
			fromBottom: {
				transform: "translate(0, 0)",
			},

			fromLeft: {
				"& $triangle": {
					top: "auto",
					bottom: `-16px`,
				},
			},
			fromRight: {
				"& $triangle": {
					top: "auto",
					bottom: `-16px`,
				},
			},
			text: {
				fontSize: "12px",
				lineHeight: "1.17",
			},
			triangle: {
				transform: "rotate(45deg) translateY(-4px)",
				bottom: "-16px",
			},
			triangleV3: {
				left: "40%",
			},
		},
		[`@media (max-width: ${theme.media.tariffsSmall})`]: {
			triangleV3: {
				left: "90px",
			},
		},
	};
};

class Tooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
	}

	target = null;

	static defaultProps = {
		position: "top",
	};

	onMouseEnterHandler = () => {
		this.setState({
			show: true,
		});
	};

	onMouseLeaveHandler = () => {
		this.setState({
			show: false,
		});
	};

	onMouseUp = () => {
		this.setState({
			show: true,
		});
		document.addEventListener("mousedown", this.onMouseDown);
	};

	onMouseDown = (e) => {
		const div = document.querySelector("#Tooltip");
		const withinBoundaries = e.composedPath().includes(div);
		if (!withinBoundaries) {
			this.setState({
				show: false,
			});
			document.removeEventListener("mousedown", this.onMouseDown);
		}
	};

	componentDidMount() {
		const target = ReactDOM.findDOMNode(this.target);
		let lg = this.props.windowSizeModel?.windowSizes.lg;
		let md = this.props.windowSizeModel?.windowSizes.md;
		if (!!target) {
			if (this.props.openClick && (lg || md)) {
				target.addEventListener("mouseup", this.onMouseUp);
			} else {
				target.addEventListener("mouseenter", this.onMouseEnterHandler);
				target.addEventListener("mouseleave", this.onMouseLeaveHandler);
			}
		}
	}

	componentWillUnmount() {
		const target = ReactDOM.findDOMNode(this.target);
		let lg = this.props.windowSizeModel?.windowSizes.lg;
		let md = this.props.windowSizeModel?.windowSizes.md;
		if (!!target) {
			if (this.props.openClick && (lg || md)) {
				target.removeEventListener("mouseup", this.onMouseUp);
			} else {
				target.removeEventListener("mouseenter", this.onMouseEnterHandler);
				target.removeEventListener("mouseleave", this.onMouseLeaveHandler);
			}
		}
	}

	render() {
		const { children, ...rest } = this.props;
		const child = React.cloneElement(React.Children.only(children), {
			ref: (target) => (this.target = target),
			key: 1,
		});

		return [
			child,
			this.state.show && <StyledTooltipPortal target={this.target} key={2} {...rest} />,
		];
	}
}

class TooltipPortal extends React.Component {
	tooltip = null;

	componentDidMount() {
		if (this.props.position === "top") {
			this.calculateTooltipFromTop();
		}

		if (this.props.position === "bottom") {
			this.calculateTooltipFromBottom();
		}

		if (this.props.position === "left") {
			this.calculateTooltipFromLeft();
		}

		if (this.props.position === "right") {
			this.calculateTooltipFromRight();
		}

		if (this.props.position === "auto") {
			const target = ReactDOM.findDOMNode(this.props.target);
			let targetCoords = target.getBoundingClientRect();
			let tooltipCoords = this.tooltip.getBoundingClientRect();
			let heightTooltip = tooltipCoords.height + 20;
			let widthTooltip = tooltipCoords.width + 20;
			if (targetCoords.top > heightTooltip && tooltipCoords.left > 0 && tooltipCoords.right > 0) {
				this.calculateTooltipFromTop();
				return;
			}

			let targetBottom = window.innerHeight - targetCoords.bottom;

			if (targetBottom > heightTooltip && tooltipCoords.left > 0 && tooltipCoords.right > 0) {
				this.tooltip.classList.add(this.props.classes.fromBottom);
				this.calculateTooltipFromBottom();
				return;
			}

			if (targetCoords.left > widthTooltip) {
				this.tooltip.classList.add(this.props.classes.fromLeft);
				this.calculateTooltipFromLeft();
				return;
			}

			let targetRight = window.innerWidth - targetCoords.right;

			if (targetRight > widthTooltip) {
				this.tooltip.classList.add(this.props.classes.fromRight);
				this.calculateTooltipFromRight();
				return;
			}

			this.calculateTooltipFromTop();
		}
	}

	calculateTooltipFromTop = () => {
		const target = ReactDOM.findDOMNode(this.props.target);
		const tooltip = this.tooltip;
		let targetCoords = target.getBoundingClientRect();
		let left = targetCoords.left + targetCoords.width / 2;
		let body = document.body;
		let top = window.pageYOffset || body.scrollTop;
		top = top + targetCoords.top;
		tooltip.style.top = top - 20 + "px";
		tooltip.style.left = window.innerWidth > Base.breakpoints.sm ? left + "px" : "20px";
		while (
			this.props.tariffName &&
			window.innerWidth > Base.breakpoints.sm &&
			parseInt(tooltip.getBoundingClientRect().left) < 0
		) {
			tooltip.style.left = parseInt(tooltip.style.left) + 10 + "px";
		}
		if (this.props.priceSale && window.innerWidth <= Base.breakpoints.sm) {
			tooltip.style.left = left - 130 + "px";
		}
		if (window.innerWidth <= Base.breakpoints.sm && !this.props.priceSale) {
			this.calculateTriangle();
		}
	};

	calculateTooltipFromBottom = () => {
		const target = ReactDOM.findDOMNode(this.props.target);
		const tooltip = this.tooltip;
		let targetCoords = target.getBoundingClientRect();
		let left = targetCoords.left + targetCoords.width / 2;
		let body = document.body;
		let top = window.pageYOffset || body.scrollTop;
		top = top + targetCoords.top + targetCoords.height;
		tooltip.style.top = top + 20 + "px";
		tooltip.style.left = window.innerWidth > Base.breakpoints.sm ? left + "px" : "20px";
		if (window.innerWidth <= Base.breakpoints.sm) {
			this.calculateTriangle();
		}
	};

	calculateTooltipFromLeft = () => {
		if (window.innerWidth <= Base.breakpoints.sm) {
			this.calculateTooltipFromTop();
			return;
		}
		const target = ReactDOM.findDOMNode(this.props.target);
		const tooltip = this.tooltip;
		let targetCoords = target.getBoundingClientRect();
		let left = targetCoords.left - 20;
		let body = document.body;
		let top = window.pageYOffset || body.scrollTop;
		top = top + targetCoords.top + targetCoords.height / 2;
		tooltip.style.top = top + "px";
		tooltip.style.left = left + "px";
	};

	calculateTooltipFromRight = () => {
		if (window.innerWidth <= Base.breakpoints.sm) {
			this.calculateTooltipFromTop();
			return;
		}
		const target = ReactDOM.findDOMNode(this.props.target);
		const tooltip = this.tooltip;
		let targetCoords = target.getBoundingClientRect();
		let left = targetCoords.left + targetCoords.width + 20;
		let body = document.body;
		let top = window.pageYOffset || body.scrollTop;
		top = top + targetCoords.top + targetCoords.height / 2;
		tooltip.style.top = top + "px";
		tooltip.style.left = left + "px";
	};

	calculateTriangle = () => {
		let triangle = this.tooltip.querySelector(`.triangle`);
		const target = ReactDOM.findDOMNode(this.props.target);
		let targetCoords = target.getBoundingClientRect();
		triangle.style.left = targetCoords.left - 20 + targetCoords.width / 2 - 15 + "px";
	};

	render() {
		const { header, body, classes, tariffName, v3 } = this.props;
		const className = classNames(classes.root, {
			[classes.tariffName]: this.props.tariffName,
			[classes.fromBottom]: this.props.position === `bottom`,
			[classes.fromLeft]: this.props.position === `left`,
			[classes.fromRight]: this.props.position === `right`,
			[classes.priceSale]: this.props.priceSale,
		});

		return ReactDOM.createPortal(
			<div id="Tooltip" ref={(tooltip) => (this.tooltip = tooltip)} className={className}>
				{header}
				{body}
				<span
					className={classNames("triangle", {
						[classes.triangle]: !tariffName,
						[classes.triangleV2]: tariffName,
						[classes.triangleV3]: v3,
					})}
				/>
				{/*<span className={`${classes.triangle} triangle`} />*/}
			</div>,
			document.querySelector("#forSelectField")
		);
	}
}

const StyledTooltipPortal = withStyles(styles)(TooltipPortal);

export default withStyles(styles)(Tooltip);

export { Tooltip as __Tooltip };
