import Cookies from "js-cookie";

export function addAddressData(order, ApplicationModel) {
	const one_string_search = ApplicationModel?.siteConfig?.settings?.one_string_search?.enabled;

	if (one_string_search) {
		let searchModelOneString = ApplicationModel.searcheModelOneString;
		const currentFullAddress = searchModelOneString.getCurrentFullAddress();

		if (currentFullAddress) {
			if (currentFullAddress?.data?.street_full) {
				order.street = currentFullAddress.data.street_full;
			}
			if (currentFullAddress?.data?.street_id) {
				order.street_id = currentFullAddress.data.street_id;
			}
			if (currentFullAddress?.data?.house) {
				order.house = currentFullAddress.data.house;
			}
			if (currentFullAddress?.data?.address_id) {
				order.house_id = currentFullAddress.data.address_id;
			}
		}

		if (!order.tariff_id && !!searchModelOneString.actionSelectedTariffId) {
			order.tariff_id = searchModelOneString.actionSelectedTariffId;
		}

		if (!order.region_id) {
			order.region_id = ApplicationModel?.currentRegion?.id;
		}
	} else {
		let searchModel = ApplicationModel.searcheModel;
		let currentStreet = searchModel.getCurrentStreet();
		let currentAddress = searchModel.getCurrentAddress();

		if (currentStreet) {
			if (currentStreet?.full_street) {
				order.street = currentStreet.full_street;
			}
			if (currentStreet?.id) {
				order.street_id = currentStreet.id;
			}
			if (currentStreet?.district?.id) {
				order.district_id = currentStreet.district.id;
			}
			if (currentStreet.district?.name) {
				order.district = currentStreet.district.name;
			}
		}
		if (currentAddress) {
			if (currentAddress?.full) {
				order.house = currentAddress.full;
			}
			if (currentAddress?.id) {
				order.house_id = currentAddress.id;
				order.address_id = currentAddress.id;
			}
			if (currentAddress?.building) {
				order.building = currentAddress.building;
			}
			if (currentAddress.construction) {
				order.construction = currentAddress.construction;
			}
		}

		if (currentStreet && currentAddress) {
			const district = Boolean(currentAddress.district_street_name)
				? currentAddress.district_street_name
				: currentStreet.district?.name;

			if (district) {
				order.district = district;
			}

			const district_id = Boolean(currentAddress?.district_id)
				? currentAddress.district_id
				: currentStreet?.district?.id;

			if (district_id) {
				order.district_id = district_id;
			}
		} else {
			order.house = searchModel?.searcheForm?.fields?.address?.value;
		}

		if (!order.tariff_id && !!searchModel.recentlyConnectedSelectedTariffId) {
			order.tariff_id = searchModel.recentlyConnectedSelectedTariffId;
		}
		if (!order.region_id) {
			order.region_id = ApplicationModel?.currentRegion?.id;
		}

		// Какой тариф был выбран с акционного контейнера.
		if (!!searchModel.actionSelectedTariffId) {
			order.tariff_id = searchModel.actionSelectedTariffId;
		}
	}

	return order;
}

export function addABData(order) {
	const experimentMap = window.__EXPERIMENT_MAP__ || [];

	if (experimentMap?.length) {
		const abData = {};

		for (const ex of experimentMap) {
			const ex_name = `AB_${ex?.name}`;

			const variantRaw = Cookies.get(ex_name);

			if (variantRaw) {
				try {
					const variant = JSON.parse(variantRaw);
					abData[ex_name] = variant?.name;
				} catch (e) {
					abData[ex_name] = "Variant parse error";
				}
			} else {
				abData[ex_name] = "Variant has empty value";
			}
		}

		if (Object.keys(abData).length) {
			order["ab"] = abData;
		} else {
			order["ab"] = {
				error: "abData is empty",
			};
		}
	}

	return order;
}

export function addLogsData(dataLogs, ApplicationModel) {
	const { currentRegion, windowSizeModel } = ApplicationModel;
	const experimentMap = window.__EXPERIMENT_MAP__ || [];

	if (ApplicationModel.referer) {
		dataLogs.search_system = ApplicationModel.referer;
	} else {
		if (typeof window !== "undefined" && typeof document !== "undefined") {
			const order_referer = document.referrer;
			if (order_referer) {
				dataLogs.search_system = order_referer;
			}
		}
	}

	if (experimentMap?.length) {
		const abData = {};

		for (const ex of experimentMap) {
			const ex_name = `AB_${ex?.name}`;

			const variantRaw = Cookies.get(ex_name);

			if (variantRaw) {
				try {
					const variant = JSON.parse(variantRaw);
					abData[ex_name] = variant?.name;
				} catch (e) {
					abData[ex_name] = "Variant parse error";
				}
			} else {
				abData[ex_name] = "Variant has empty value";
			}
		}

		if (Object.keys(abData).length) {
			dataLogs["ab_tests_json"] = JSON.stringify(abData);
		} else {
			dataLogs["ab_tests_json"] = "abData is empty";
		}
	} else {
		dataLogs["ab_tests_json"] = "abData is empty";
	}

	dataLogs.region_id = currentRegion?.id;
	dataLogs.region_url = currentRegion?.url;
	dataLogs.region_name = currentRegion?.name;
	dataLogs.width = windowSizeModel.windowWidth;
	dataLogs.height = windowSizeModel.windowHeight;

	return dataLogs;
}

export function addRefererData(order, ApplicationModel) {
	if (ApplicationModel.referer) {
		order.order_referer = ApplicationModel.referer;
	} else {
		if (typeof window !== "undefined" && typeof document !== "undefined") {
			const order_referer = document.referrer;
			if (order_referer) {
				order.order_referer = order_referer;
			}
		}
	}

	const yandex_login = Cookies.get("yandex_login");
	if (yandex_login) {
		order.yandex_login = yandex_login;
	}

	const first_url = Cookies.get("firstURL");
	if (first_url) {
		order.first_url = first_url;
	}

	return order;
}
