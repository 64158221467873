import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import Breadcrumbs from "ComponentsUI/Breadcrumbs/Breadcrumbs";
import BreadcrumbsItem from "ComponentsUI/Breadcrumbs/BreadcrumbsItem";
import withDefaultParams from "Services/withDefaultParams";
import { useQuery } from "@apollo/client";
import { DECLINATION_QUERY } from "Configs/queries";

const BreadCrumbsContainer = (props) => {
	const {
		ApplicationModel: { routesModel },
	} = props;

	let regionUrl = props.ApplicationModel?.currentRegion?.url;
	let filter = `url=${regionUrl}`;
	const declinationsQuery = useQuery(DECLINATION_QUERY, {
		skip: !regionUrl,
		variables: { filter },
	});
	let data = declinationsQuery.data;
	if (data?.region?.declinations) {
		routesModel.setRegion(data.region);
	}
	let RoutesModel = props.ApplicationModel.routesModel;
	let breadCrumbs = RoutesModel.getBreadCrumbs(props.location, props.match.params);
	let siteConfig = props.ApplicationModel.siteConfig;
	let currentUrl = `${siteConfig?.ssl === true ? `https` : "http"}://${
		siteConfig.domain_name_full
	}${props?.location?.pathname ? props.location.pathname : "/"}`;

	if (breadCrumbs.length > 1) {
		breadCrumbs[breadCrumbs.length - 1].url = "";
		if (!breadCrumbs[breadCrumbs.length - 1].name) {
			breadCrumbs.pop();
			breadCrumbs[breadCrumbs.length - 1].url = "";
		}
	}

	return (
		<Breadcrumbs background={props.background} white={props.white} grey={props.grey}>
			{breadCrumbs.map((elem, i) => (
				<BreadcrumbsItem key={i}>
					{!!elem.url ? (
						<NavLink itemProp="item" to={elem.url} exact>
							<span itemProp="name">{elem.name}</span>
						</NavLink>
					) : (
						<>
							<span itemProp="name">{elem.name}</span>
							<meta itemProp="item" content={currentUrl} />
						</>
					)}
					<meta itemProp="position" content={i + 1} />
				</BreadcrumbsItem>
			))}
		</Breadcrumbs>
	);
};

export default withRouter(
	withDefaultParams(inject("ApplicationModel")(observer(BreadCrumbsContainer)))
);
