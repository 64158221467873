import React from "react";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";

export default class ReviewItemWrapper extends React.PureComponent {
	render() {
		return (
			<Container>
				<Row>{this.props.children}</Row>
			</Container>
		);
	}
}
