import React, { forwardRef } from "react";
import withStyles from "react-jss";

const styles = (theme) => ({
	root: {
		maxWidth: "50px",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	value: {
		maxWidth: "50px",
		fontSize: "18px",
		fontFamily: theme.fonts.bold,
		lineHeight: 1.1,
		color: theme.palette.colors.liteGreen,
		letterSpacing: "-0.5px",
		position: "relative",
		wordWrap: "break-word",
	},
	asterisk: {
		position: "relative",
		color: theme.palette.colors.liteGreen,
		top: "-2px",
		right: "1px",
	},
	measure: {
		width: "50px",
		fontSize: "12px",
		fontFamily: theme.fonts.medium,
		lineHeight: 1,
		color: theme.palette.colors.oneMoreGrey,
		letterSpacing: "2px",
		textTransform: "uppercase",
	},
	[`@media (min-width: ${theme.media.lg})`]: {
		value: {
			marginBottom: "6px",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			flexDirection: "row",
			alignItems: "center",
			marginLeft: "10px",
			maxWidth: "210px",
		},
		value: {
			maxWidth: "145px",
		},
		measure: {
			marginLeft: "5px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			flexDirection: "row",
			alignItems: "center",
			marginLeft: "10px",
			maxWidth: "185px",
		},
		value: {
			maxWidth: "130px",
		},
		measure: {
			marginLeft: "5px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			flexDirection: "row",
			alignItems: "center",
			maxWidth: "110px",
		},
		value: {
			maxWidth: "55px",
		},
		measure: {
			marginLeft: "5px",
		},
	},
	[`@media (max-width: ${theme.media.tariffsSmall})`]: {
		root: {
			maxWidth: "179px",
		},
		value: {
			maxWidth: "125px",
		},
	},
});

const MeasureA = forwardRef((props, ref) => {
	const { classes, valueSale, onClickHandler, withAsterisk, measure } = props;

	return (
		<div ref={ref} className={classes.root}>
			<div onClick={onClickHandler} className={classes.value}>
				{valueSale}
				{withAsterisk && <span className={classes.asterisk}> * </span>}
			</div>
			{!!measure && <div className={classes.measure}>{measure}</div>}
		</div>
	);
});

export default withStyles(styles)(MeasureA);
