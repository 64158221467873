import React from "react";
import withStyles from "react-jss";
import Button from "ComponentsUI/Button";

const styles = (theme) => {
	return {
		root: {
			width: "100%",
			textAlign: "center",
		},
		wrapper: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			marginBottom: "20px",
		},
		buttonWrapper: {
			width: "280px",
			height: "60px",
		},
		text: {
			fontFamily: theme.fonts.light,
			fontSize: "18px",
			lineHeight: 1,
			textAlign: "center",
			color: theme.palette.colors.white,
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			buttonWrapper: {
				width: "300px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			buttonWrapper: {
				width: "270px",
				height: "50px",
			},
			text: {
				fontSize: "16px",
				lineHeight: 1.13,
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			buttonWrapper: {
				width: "200px",
				height: "40px",
			},
			text: {
				fontSize: "14px",
				lineHeight: 1.14,
			},
		},
	};
};

class HeroBannerSelectRegion extends React.Component {
	render() {
		let classes = this.props.classes;
		return (
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<div className={classes.buttonWrapper}>
						<Button
							component={this.props.linkComponent}
							fullWidth={true}
							noPaddings={true}
							typeText="verybig"
							size="big"
							status="active"
							datatest="choose_region_button"
						>
							{this.props.buttonText}
						</Button>
					</div>
				</div>
				<div className={classes.text}>{this.props.text}</div>
			</div>
		);
	}
}

export default withStyles(styles)(HeroBannerSelectRegion);
