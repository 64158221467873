var gql = require("@apollo/client").gql;

const COUNT_TARIFFS_QUERY = gql`
	query Count_Tariffs($filter: String) {
		countTariffsQuery(filter: $filter) {
			count
		}
	}
`;

export default COUNT_TARIFFS_QUERY;
