import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { REVIEWS_MAIN_PAGE_V3 } from "Services/queries";
import { generateUrl } from "Services/withDefaultParams";
import withDefaultParams from "Services/withDefaultParams";
import configRouters from "Configs/routes";
import Block from "ComponentsUI/Block";
import Button from "ComponentsUI/Button";
import { ReviewsLayout } from "ComponentsUI/Reviews";
import { HeaderTextSeo } from "ComponentsUI/SeoBlock";

@inject("ApplicationModel")
@observer
class Reviews extends React.Component {
	onClickHandler() {}

	render() {
		const {
			ApplicationModel: { windowSizeModel, text: textConfig },
			data,
			seoBlock,
		} = this.props;

		if (windowSizeModel.windowSizes.xs || !data.reviewsMainPage) {
			return null;
		}

		let reviewsUrl = generateUrl(configRouters.Reviews.route, {
			regionUrl: this.props.match.params.regionUrl,
		});

		let feedbackUrl = generateUrl(configRouters.AddReview.route, {
			regionUrl: this.props.match.params.regionUrl,
		});

		let reviews = this.props.data.reviewsMainPage.data;

		reviews = reviews.slice(0, windowSizeModel.windowSizes.md ? 3 : 4);

		return (
			<>
				<HeaderTextSeo
					header={seoBlock?.seoHeader || textConfig.prvidersReviewsTitle}
					text={seoBlock?.seoText}
				/>
				<Block
					header=" "
					hideUnderLine={true}
					datatest="main_review"
					btn={
						<Button
							typeText="big"
							component={<Link to={!!reviewsUrl ? reviewsUrl : "/"} />}
							size={"middle"}
							status={"tertiaryAccent"}
							transparent={true}
							onClick={() => this.onClickHandler()}
							datatest="main_allreviews_button"
							fullWidth={true}
						>
							{textConfig.reviewsAll}
						</Button>
					}
					secondButton={
						<Button
							typeText="big"
							component={<Link to={!!feedbackUrl ? feedbackUrl : "/"} />}
							size={"middle"}
							status={"purple"}
							fullWidth={true}
							onClick={() => this.onClickHandler()}
							datatest="main_allreviews_button"
						>
							{textConfig.ratingLeaveReview}
						</Button>
					}
				>
					<ReviewsLayout useCarusel={true} items={reviews} />
				</Block>
			</>
		);
	}
}

export default withRouter(
	withDefaultParams(
		graphql(REVIEWS_MAIN_PAGE_V3, {
			options: (props) => {
				let variables = {};
				if (props.match.params.regionUrl) {
					variables.filter = `region.url=${props.match.params.regionUrl}`;
				}
				return {
					variables: {
						...variables,
						providerJoin: true,
					},
				};
			},
		})(Reviews)
	)
);
