import React from "react";
import { LoginHeaderButton } from "ComponentsUI/Login";
import { observer, inject } from "mobx-react";
import Metrika from "Services/Metrika";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";
import { EVENT_ACTION_CLICK_LOGIN } from "Constants/metriks/GoogleActions";

@inject("ApplicationModel")
@observer
export default class LoginHeaderContainer extends React.Component {
	clickButton() {
		let { commonViewModel } = this.props.ApplicationModel;
		commonViewModel.closeMobileMenu();
		this.props.openRegistrationHandler();

		Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_CLICK_BUTTON, EVENT_ACTION_CLICK_LOGIN);
	}

	render() {
		let authModel = this.props.ApplicationModel.newAuthModel;
		let text = this.props.ApplicationModel.text;
		if (authModel.user && authModel.user.id) {
			return (
				<LoginHeaderButton
					onClickButton={() => this.props.logout()}
					onlyLogo={this.props.onlyLogo}
					inWidget={this.props.inWidget}
					purpleTheme={this.props.purpleTheme}
					greyTheme={this.props.greyTheme}
					text={text.LOGOUT}
				/>
			);
		}
		return (
			<LoginHeaderButton
				onClickButton={() => this.clickButton()}
				onlyLogo={this.props.onlyLogo}
				inWidget={this.props.inWidget}
				purpleTheme={this.props.purpleTheme}
				greyTheme={this.props.greyTheme}
				text={text.LOGIN}
			/>
		);
	}
}
