import { observable, action } from "mobx";
import SearchModel from "./../SearchModelOneString";

export default class TenderAddressOneStringModel {
	@observable searchModels = [];

	constructor(rootModel) {
		this.rootModel = rootModel;

		if (rootModel.searcheModelOneString) {
			this.searchModels.push(rootModel.searcheModelOneString);
		} else {
			this.searchModels.push(new SearchModel(this.rootModel));
		}
	}

	@action
	reset() {
		this.searchModels = [];
	}

	@action
	addSearchModel() {
		let newSearchModel = new SearchModel(this.rootModel);
		this.searchModels.push(newSearchModel);
	}

	@action
	removeSearchModel(i) {
		this.searchModels.splice(i, 1);
	}

	@action
	initSearchModelByData(index, fullAddressData) {
		this.searchModels[index].setCurrentFullAddress(fullAddressData, false, false);
	}
}
