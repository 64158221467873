import React from "react";
import { observer, inject } from "mobx-react";
import { NavLink, withRouter } from "react-router-dom";
import { Menu, MenuItem } from "ComponentsUI/Navigation";
import Routes from "Configs/routes";
import { generateUrl } from "Services/withDefaultParams";
import withDefaultParams from "Services/withDefaultParams";
import SwitchCityWindowContainer from "Containers/SwitchCityWindowContainer";
import DetectRegionContainer from "Containers/DetectRegionContainer";
import { CLICK_HEADER_MENU_PROVIDERS, CLICK_HEADER_MENU_RATING } from "Constants/metriks/Yandex";

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
export default class MainMenuContainer extends React.Component {
	static defaultProps = {
		purpleColor: false,
		withoutMain: false,
	};

	onClickLogoHandler() {}

	onClickHandler() {
		this.props.onClickHandler && this.props.onClickHandler();
	}

	render() {
		let textConfig = this.props.ApplicationModel.text;

		let regionUrl = this.props.match.params.regionUrl;
		let arrayNotRegionUrl = [
			"providers",
			"news",
			"reviews",
			"compare",
			"actions",
			"rates",
			"orders",
			"business",
			"rating",
			"address",
			"terms-of-use",
			"personal-data",
			"feedback",
			"about",
			"contact-feedback",
		];

		let regionUrlReal = arrayNotRegionUrl.indexOf(regionUrl) >= 0 ? "" : regionUrl;

		let currentRegionUr = this.props.ApplicationModel.currentRegion?.url;
		let providerUrl = generateUrl(
			Routes.Providers.route,
			{
				regionUrl: regionUrlReal,
			},
			currentRegionUr
		);

		let ratingUrl = generateUrl(
			Routes.Rating.route,
			{
				regionUrl: regionUrlReal,
			},
			currentRegionUr
		);

		let findInternet = generateUrl(
			Routes.OrderToHome.route,
			{
				regionUrl: regionUrlReal,
			},
			currentRegionUr
		);

		let ratesUrl = generateUrl(
			Routes.Rates.route,
			{
				regionUrl: regionUrlReal,
			},
			currentRegionUr
		);

		const officeUrl = generateUrl(
			Routes.OrderOffice.route,
			{
				regionUrl: regionUrlReal,
			},
			currentRegionUr
		);

		let defaultData = [
			{
				name: textConfig.upperMenuFindInternet,
				url: findInternet,
				datatest: "main_internet_button",
			},
			{
				name: textConfig.menuProviders,
				url: providerUrl,
				goal: CLICK_HEADER_MENU_PROVIDERS,
				datatest: "main_providers_button",
				isActive: () => {
					if (Routes.ProvidersReviewListOld.route === this.props.match.path) {
						return true;
					}

					if (this.props.match.path.indexOf(Routes.Providers.route) >= 0) {
						return true;
					}

					return this.props.match.path.indexOf(Routes.ProviderDetails.route) >= 0;
				},
			},
			{
				name: textConfig.menuRating,
				url: ratingUrl,
				goal: CLICK_HEADER_MENU_RATING,
				datatest: "main_rating_button",
				isActive: () => {
					if (Routes.ProvidersReviewListOld.route === this.props.match.path) {
						return false;
					}

					return this.props.match.path.indexOf(Routes.Rating.route) >= 0;
				},
			},
			{
				name: textConfig.menuRates,
				url: ratesUrl,
				datatest: "main_tariff_button",
			},
			{
				name: "Интернет в офис",
				url: officeUrl,
			},
		];

		let mainElement = (
			<React.Fragment>
				<SwitchCityWindowContainer />
				{/* <DetectRegionContainer /> */}
			</React.Fragment>
		);

		let items = defaultData.map((item, index) => {
			return (
				<MenuItem
					key={index}
					clickMenuItem={() => {
						this.onClickHandler(item.goal);
					}}
					counter={item.counter}
					purpleColor={this.props.purpleColor}
					compare={item.compare}
					onlyLg={item.onlyLg}
					inWidget={this.props.inWidget}
					onClick={() => this.onClickHandler()}
					subMenuContent={item.subContent}
					isMainMenu={true}
				>
					<NavLink
						to={item.url}
						exact={!!item.exact}
						datatest={item.datatest}
						activeClassName="_active"
						isActive={item.isActive}
						itemProp="url"
					>
						{item.name}
						<meta itemProp="name" content={item.name} />
					</NavLink>
				</MenuItem>
			);
		});

		return <Menu inWidget={this.props.inWidget} mainElement={mainElement} items={items} />;
	}
}
