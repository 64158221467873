import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import LoginLogic from "HOC/LoginLogic";
import { SVGLogoBlue } from "Constants/SVG";
import PhoneContainerHeader from "Containers/PhoneContainerHeader";
import LoginHeaderContainer from "Containers/LoginHeaderContainer";
import DeclinationContainerNew from "Containers/DeclinationContainerNew";
import FindTariffsHeaderContainer from "Containers/FindTariffsHeaderContainer";
import WhatsappSendMessageContainer from "Containers/WhatsApp/WhatsappSendMessageContainer";
import { Col } from "ComponentsUI/Styles/Grid";
import { MenuWidget } from "ComponentsUI/Navigation";

const useStyles = createUseStyles((theme) => {
	return {
		root: {
			backgroundColor: theme.palette.colors.paleGrey,
			boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.5)",
			position: "fixed",
			zIndex: "4",
			transform: "translateY(0)",
			transition: "transform .5s ease",
			width: "100%",
			top: 0,
			left: 0,
		},
		widgetMenu: {
			display: "block",
		},
		upperPart: {
			position: "relative",
			zIndex: 2,
			backgroundColor: theme.palette.colors.paleGrey,
		},
		isHidden: {
			transform: "translateY(-200%)",
		},
		showMobile: {},
		upperWrapper: {
			display: "flex",
			alignItems: "center",
			padding: "10px 0",
		},
		menuIsHidden: {
			transform: "translate(150%, 100%)",
		},
		bottomMenu: {},
		phoneButton: {
			marginRight: "auto",
			marginLeft: "20px",
			width: "280px",
		},
		login: {
			marginLeft: "auto",
			marginTop: "-2px",
		},
		logoWrapper: {
			display: "flex",
			marginRight: "auto",
			cursor: "pointer",
			flexShrink: 0,
			height: "40px",
			width: "40px",
			alignItems: "center",
			justifyContent: "center",
			"& a": {
				width: "40px",
				height: "40px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				"&:active": {
					borderRadius: "50%",
					backgroundColor: "rgba(89, 19, 254, 0.08)",
				},
			},
			"& svg": {
				height: "30px",
				width: "auto",
				pointerEvents: "none",
			},
		},
		mobileMenu: {
			display: "block",
			marginLeft: "40px",
			flexShrink: 0,
			cursor: "pointer",
			"& span": {
				fontSize: "24px",
				color: theme.palette.colors.purplishBlue,
			},
		},
		searchTariffs: {
			width: "280px",
			marginLeft: "auto",
		},
		desktop: {},
		mobile: {
			display: "none",
		},
		adblock: {
			width: "100%",
			position: "absolute",
			bottom: "1px",
			transform: "translateY(100%)",
			zIndex: -1,
			transition: "transform .3s ease",
			animation: "toDown .3s ease",
			boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.5)",
		},
		adblockHidden: {
			transform: "translateY(-50%)",
		},
		carouselItem: {
			width: "100%",
		},
		carouselItemPurple: {
			backgroundColor: theme.palette.colors.purplishBlue,
		},
		carouselItemOrange: {
			backgroundColor: theme.palette.colors.orange,
		},
		carouselItemWrapper: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: "10px 0",
		},
		noPhone: {
			marginLeft: "0 !important",
		},
		carouselItemTextWrapper: {
			width: "100%",
			paddingRight: "40px",
			textAlign: "center",
		},
		carouselItemText: {
			fontFamily: theme.fonts.light,
			color: theme.palette.colors.white,
			lineHeight: 1,
			fontSize: "16px",
		},
		carouselCloseWrapper: {
			position: "relative",
		},
		carouselItemClose: {
			flexShrink: 0,
			cursor: "pointer",
			position: "absolute",
			top: "10px",
			right: "10px",
			zIndex: 1,
			"& span": {
				fontSize: "16px",
				color: theme.palette.colors.white,
			},
		},
		findTariffsWrapper: {
			position: "absolute",
			bottom: "-20px",
			transform: "translateY(100%)",
			left: "210px",
			visibility: "visible",
			opacity: 1,
			transition: "opacity .3s ease",
		},
		findTariffsWrapperIsHidden: {
			visibility: "hidden",
			opacity: 0,
		},
		subtlety: {
			animation: "jittery 4s infinite",
		},
		pulsing: {
			animation: "pulsing 2.5s linear infinite",
		},
		buttonDubstep: {
			width: "40px",
			height: "40px",
			zIndex: "-10",
		},
		buttonDubstepWatsapp: {
			position: "relative",
			bottom: "44px",
		},
		buttonDubstepPhone: {
			position: "fixed",
		},
		colorWhatsapp: {
			backgroundColor: theme.palette.colors.whatsapp,
			borderRadius: "5px",
		},
		colorPhone: {
			backgroundColor: theme.palette.colors.orange,
			borderRadius: "5px",
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			location: {
				width: "220px",
				marginRight: "20px",
				paddingLeft: "3px",
			},
			login: {
				marginLeft: "auto",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			searchTariffs: {
				width: "auto",
			},
			phoneButton: {
				width: "auto",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			upperWrapper: {
				padding: "10px 0",
			},
			widgetMenu: {
				display: "none",
			},
			logoWrapper: {
				marginRight: "0",
				display: "flex",
				height: "40px",
				width: "40px",
				alignItems: "center",
				justifyContent: "center",
				"& a": {
					height: "40px",
					width: "40px",
				},
				"& svg": {
					height: "20px",
					width: "auto",
				},
			},
			upperMenu: {
				display: "none",
			},
			login: {
				display: "none",
			},
			phoneButton: {
				width: "40px",
				flexShrink: 0,
				marginLeft: "15px",
				display: "block",
				marginRight: 0,
			},
			searchTariffs: {
				margin: "0 auto",
				width: "140px",
			},
			mobileMenu: {
				display: "flex",
				height: "40px",
				width: "40px",
				alignItems: "center",
				justifyContent: "center",
				marginLeft: "8px",
				"& span": {
					fontSize: "20px",
				},
			},
			carouselItemText: {
				fontSize: "14px",
			},
			carouselItemClose: {
				marginLeft: "10px",
				"& span": {
					fontSize: "14px",
				},
			},
			findTariffsWrapper: {
				bottom: "0",
				left: "-20px",
				right: "-20px",
				zIndex: 2,
				width: "auto",
				transition: "transform .3s ease",
				transform: "translate(0, 100%)",
			},
			findTariffsWrapperIsHidden: {
				visibility: "visible",
				opacity: 1,
				transform: "translate(120%, 100%)",
			},
		},
	};
});

const FixedNavigation = (props) => {
	let classes = useStyles();
	let text = props.text;
	let onClickMenu = props.showFindTariffs ? props.toggleFindTariffs : props.openMobileMenu;
	let classNameWidget = classNames(classes.searchTariffs, {
		[classes.noPhone]: !props.showOnSite,
	});

	const { search_address_header, whatsapp_header, phone_button_header } =
		props.animation_frontend || {};
	// onClickMenu = props.openMobileMenu;

	const dubstepWhatsapp = whatsapp_header?.status === "DUBSTEP" && props.xs;
	const dubstepPhoneButton = phone_button_header?.status === "DUBSTEP" && props.xs;

	return (
		<div
			id={"fixed_navigation"}
			className={classNames(classes.root, "fixed", {
				[classes.isHidden]: !props.showNavigation,
			})}
		>
			<div className={classes.upperPart}>
				<Container>
					<Row>
						<Col xs="12">
							<div className={classes.widgetMenu}>{props.widgetMenu}</div>
							<div className={classes.upperWrapper}>
								<div className={classes.logoWrapper}>
									<Link aria-label={props.hrefToMain} to={props.hrefToMain}>
										{SVGLogoBlue}
									</Link>
								</div>
								<div className={classNames(classNameWidget)}>
									<MenuWidget
										onClick={props.toggleFindTariffs}
										icon={<span className="icon24 icon-magnifier-1" />}
										text={text.compareTariffs}
										passiveTheme={false}
										phone404={props.phone404}
										heartbeat={search_address_header?.status === "RAINBOW" && props.xs}
										flashing={search_address_header?.status === "FLASHING" && props.xs}
									/>
								</div>
								{props.xs && (
									<div
										className={classNames({
											[classes.subtlety]: whatsapp_header?.status === "SUBTLETY" && props.xs,
										})}
									>
										<WhatsappSendMessageContainer
											whatsappType="fixed_navigation"
											whatsappSettings={props.settingsWhatsapp}
											changeStyle={"header"}
										>
											<div
												className={classNames({
													[classes.buttonDubstep]: dubstepWhatsapp,
													[classes.colorWhatsapp]: dubstepWhatsapp,
													[classes.pulsing]: dubstepWhatsapp,
													[classes.buttonDubstepWatsapp]: dubstepWhatsapp,
												})}
											/>
										</WhatsappSendMessageContainer>
									</div>
								)}
								{props.xs ? (
									<div
										className={classNames(classes.phoneButton, {
											[classes.subtlety]: phone_button_header?.status === "SUBTLETY" && props.xs,
										})}
									>
										<PhoneContainerHeader
											fixedTheme={true}
											passiveTheme={false}
											phone404={props.phone404}
										>
											<div
												className={classNames({
													[classes.buttonDubstep]: dubstepPhoneButton,
													[classes.colorPhone]: dubstepPhoneButton,
													[classes.pulsing]: dubstepPhoneButton,
													[classes.buttonDubstepPhone]: dubstepPhoneButton,
												})}
											/>
										</PhoneContainerHeader>
									</div>
								) : (
									(props.showOnSite || props.phone404) && (
										<div className={classNames(classes.phoneButton)}>
											<PhoneContainerHeader
												fixedTheme={true}
												passiveTheme={false}
												phone404={props.phone404}
											/>
										</div>
									)
								)}
								<div className={classes.login}>
									<LoginLogic>
										<LoginHeaderContainer purpleTheme={true} onlyLogo={true} />
									</LoginLogic>
								</div>
								<div
									className={classNames(classes.mobileMenu, "onlyNotXs")}
									onClick={props.openMenu}
								>
									<span className="icon24 icon-hamburger-menu-1" />
								</div>
								<div className={classNames(classes.mobileMenu, "onlyXs")} onClick={onClickMenu}>
									{!props.showFindTariffs && !props.showMobileMenu && (
										<span className="icon24 icon-hamburger-menu-1" />
									)}
									{(props.showFindTariffs || props.showMobileMenu) && (
										<span className="icon24 icon-close" />
									)}
								</div>
								<div
									className={classNames(classes.findTariffsWrapper, {
										[classes.findTariffsWrapperIsHidden]: !props.showFindTariffs,
									})}
								>
									<DeclinationContainerNew>
										<FindTariffsHeaderContainer
											text={props.text}
											refs={props.refs}
											onClose={props.toggleFindTariffs}
											cutWrapper={true}
											showNewViewSearch={props.showNewViewSearch}
										/>
									</DeclinationContainerNew>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default FixedNavigation;
