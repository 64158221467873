import { observable, action, computed, runInAction } from "mobx";
import debounce from "lodash/debounce";
import inRange from "lodash/inRange";

export default class WindowSizeModel {
	@observable screenWindow = null;
	@observable windowWidth = null;
	@observable windowHeight = null;

	constructor() {
		if (typeof window !== "undefined") {
			this.setWindow();
		}
	}

	@computed
	get windowSizes() {
		let sizes = {};
		if (this.windowWidth) {
			sizes = {
				xs: inRange(this.windowWidth, 0, 765),
				sm: inRange(this.windowWidth, 765, 1200),
				md: inRange(this.windowWidth, 1200, 1438),
				lg: inRange(this.windowWidth, 1438, Number.POSITIVE_INFINITY),
				height: this.windowHeight,
			};
		} else {
			sizes = {
				xs: false,
				sm: false,
				md: true,
				lg: false,
				height: 0,
			};
		}

		return sizes;
	}

	// Устанавливаем Window
	@action
	setWindow = () => {
		if (typeof window === "object") {
			this.screenWindow = window;
			this.handleWindowWidthChange();
			this.screenWindow.addEventListener("resize", this.handleWindowWidthChange);
		}
	};

	// Устанавливаем ширину Window
	@action
	setWindowWidth = (width) => {
		this.windowWidth = width;
		return this.windowWidth;
	};

	@action
	setWindowHeight = (height) => {
		this.windowHeight = height;
		return this.windowHeight;
	};

	handleWindowWidthChange = debounce(() => {
		runInAction(() => {
			const width = this.screenWindow.innerWidth;
			const height = this.screenWindow.innerHeight;
			this.setWindowHeight(height);
			this.setWindowWidth(width);
		});
	}, 100);
}
