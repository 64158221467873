import React from "react";
import withStyles from "react-jss";
import Row from "reactstrap/lib/Row";
import VisibilitySensor from "react-visibility-sensor";
import classNames from "classnames";
import { sendMetriks } from "Services/sendMetriks";
import { Dropdown } from "ComponentsUI/Dropdown";
import { SelectField } from "ComponentsUI/Selectfield";
import Button from "ComponentsUI/Button";

const styles = (theme) => {
	return {
		root: {
			position: "relative",
		},
		typeConnectionError: {
			color: "#ff586c",
			width: "100%",
			fontSize: "16px",
			fontFamily: "MuseoSansCyrl-500",
			lineHeight: "1.15",
			paddingTop: "0",
			transformOrigin: "top left",
		},
		withoutContainer: {},
		button: {
			position: "absolute",
			fontFamily: theme.fonts.bold,
			fontSize: "16px",
			lineHeight: 0.8,
			letterSpacing: "2px",
			color: theme.palette.colors.purplishBlue,
			top: "50%",
			transform: "translateY(-50%)",
			right: "26px",
			cursor: "pointer",
			zIndex: "100",
			textTransform: "uppercase",
		},
		smallButton: {
			fontSize: "14px",
		},
		smWrapper: {
			boxShadow: "0 0 10px rgba(0,0,0,0.1)",
			borderRadius: "5px",
		},
		xsWrapper: {},
		layoutAStreet: {
			width: "640px",
		},
		layoutASwitcher: {
			width: "220px",
			marginRight: "20px",
		},
		layoutASButton: {
			width: "280px",
		},
		layoutAStreetHouseWrapper: {
			position: "relative",
			display: "flex",
			marginRight: "20px",
		},
		layoutB: {
			display: "flex",
			alignItems: "center",
			position: "relative",
		},
		layoutBStreet: {
			width: "100%",
			marginRight: "10px",
			flexShrink: 0,
		},
		layoutBButton: {
			width: "100%",
			marginTop: "16px",
		},
		layoutBSwitcher: {
			marginTop: "16px",
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			layoutAStreet: {
				width: "460px",
			},
			layoutASwitcher: {
				width: "220px",
				marginRight: "20px",
			},
			layoutASButton: {
				width: "220px",
			},
			layoutAStreetHouseWrapper: {
				position: "relative",
				display: "flex",
				marginRight: "20px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: 1199px)`]: {
			button: {
				fontSize: "16px",
			},
			smWrapper: {
				marginTop: "10px",
			},
			layoutAStreet: {
				width: "590px",
			},
			layoutASwitcher: {
				width: "290px",
				marginRight: "10px",
			},
			layoutASButton: {
				width: "290px",
			},
			layoutAStreetHouseWrapper: {
				position: "relative",
				display: "flex",
				marginRight: "0",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				margin: 0,
			},
			button: {
				fontSize: "14px",
				right: "10px",
				padding: "10px",
				"& span": {
					fontSize: "20px",
				},
			},
			withoutContainer: {
				margin: 0,
			},
			smWrapper: {
				marginTop: "10px",
			},
			xsWrapper: {
				marginRight: "10px",
			},
			layoutAStreet: {
				width: "100%",
			},
			layoutASwitcher: {
				width: "100%",
				marginRight: "0",
			},
			layoutASButton: {
				width: "100%",
			},
			layoutAStreetHouseWrapper: {
				position: "relative",
				display: "flex",
				width: "100%",
				marginRight: "0",
			},
		},
	};
};

class SearchAddress extends React.Component {
	static defaultProps = {
		placeholder: null,
	};
	constructor(props) {
		super(props);
	}
	clickInputHandler() {
		sendMetriks("CLICK_FIELD_STREET_NEW_SEARCH");
		this.props.clickInputHandler && this.props.clickInputHandler();
	}

	checkCorrectAddress(address) {
		return address?.data?.address_id || (address?.data?.street_guid && address?.data?.house);
	}

	renderFullAddressDropdown() {
		let text = this.props.text;
		let defaultPlaceholderStreet = !!this.props.placeholder
			? this.props.placeholder
			: text.ENTER_YOUR_ADDRESS;

		return (
			<Dropdown
				textField="highlight"
				clickInputHandler={() => this.clickInputHandler()}
				isFixedList={this.props.isFixedList}
				error={this.props.error}
				errorCounter={this.props.errorCounter}
				items={this.props.itemsFullAddresses}
				showList={this.props.showList && this.props.popupBView == false}
				onItemSelect={(item) => {
					this.props.onItemSelect(item);
				}}
				onInput={(value) => {
					this.props.onInputHandler(value);
				}}
				id={this.props.id}
				value={this.props.fullAddressValue}
				placeholder={defaultPlaceholderStreet}
				inModal={this.props.modal}
				datatest="main_input_full_address_home"
				withBigButton={!this.props.withoutButton}
				currentFullAddress={this.props.currentFullAddress}
				smallButton={this.props.smallButton}
				onDropdownOpenCallback={this.props.onDropdownOpenCallback}
				onDropdownCloseCallback={this.props.onDropdownCloseCallback}
				withoutButton={true}
				withBorder={this.props.withBorder}
				withoutBorder={this.props.withoutBorder}
				withoutShadow={this.props.withoutShadow}
				direction={this.props.direction}
				onEnter={this.props.focusOnDropdownHouse}
				whiteTheme={this.props.whiteTheme}
				text={text}
				loading={this.props.loading}
				listContainerFunc={this.props.listContainerFunc}
				windowSizes={this.props.windowSizes}
				minLenValue={3}
				correctAddress={this.checkCorrectAddress(this.props.currentFullAddress)}
				size={this.props.layoutB && "medium"}
				isStreetDropdownToTC={true}
				whiteInput={true}
				setRef={this.props.setRef}
			/>
		);
	}

	renderSelectRouteSwitcher() {
		let enabled = !!(
			this.props.currentFullAddress?.data?.address_id || this.props.currentFullAddress?.data?.house
		);
		let text = this.props.text;

		return (
			<div>
				<SelectField
					multiselect={false}
					showList={true}
					windowSizes={this.props.windowSizes}
					transparentType={true}
					items={this.props.dropdownSelectorVariants}
					inHeroBannerView={true}
					onlyInputDisabled={true}
					allowInput={enabled}
					error={this.props.errorTypeConnection}
					errorCounter={this.props.errorCounter}
					onItemSelect={(items) => this.props.changeSelectRoute(items[0])}
					text={text}
					whiteTheme={this.props.whiteTheme}
					withoutClose={true}
					placeholder={this.props.tender != true ? "Тип подключения" : ""}
					activePlaceHolder={this.props.tender != true ? "Тип подключения" : ""}
					onMount={(e) => this.props.onMountSelFil(e)}
					findItems={
						this.props.tender && [
							{
								...this.props.dropdownSelectorVariants[this.props.defaultVariantDropdown],
							},
						]
					}
				/>
			</div>
		);
	}

	renderSelectRouteSwitcherB() {
		let enabled = !!(
			this.props.currentFullAddress?.data?.address_id || this.props.currentFullAddress?.data?.house
		);

		let text = this.props.text;
		return (
			<div>
				<SelectField
					multiselect={false}
					windowSizes={this.props.windowSizes}
					items={this.props.dropdownSelectorVariants}
					onlyInputDisabled={true}
					allowInput={enabled}
					error={this.props.errorTypeConnection}
					errorCounter={this.props.errorCounter}
					onItemSelect={(items) => this.props.changeSelectRoute(items[0])}
					text={text}
					whiteTheme={this.props.whiteTheme}
					withoutClose={true}
					isFixedList={true}
					placeholder={"Тип подключения"}
					activePlaceHolder={"Тип подключения"}
					onMount={(e) => this.props.onMountSelFil(e)}
				/>
				{/* {this.props.errorTypeConnection && this.props.currentStreet.id && <div className={classes.typeConnectionError}>Выберете тип подключения</div>} */}
			</div>
		);
	}

	renderButton() {
		const { text, windowSizes, shortButtonName, buttonName, onClickButton, datatestButton } =
			this.props;

		const buttonText = windowSizes.xs
			? shortButtonName || text.FIND
			: buttonName || text.heroShowTariffs;

		return (
			<Button
				onClick={onClickButton}
				// disabled={!this.props.fullAddressValue}
				fullWidth={true}
				noPaddings={true}
				size="big"
				datatest={datatestButton || "find_tohome_button"}
				typeText={"verybig"}
				status="purple"
			>
				{buttonText}
			</Button>
		);
	}

	renderSmallButton() {
		let text = this.props.text;
		if (!this.props.whiteTheme) {
			return (
				<Button
					onClick={this.props.onClickButton}
					disabled={!this.props.fullAddressValue}
					fullWidth={true}
					noPaddings={true}
					datatest={this.props.datatestButton || "find_tohome_button"}
					size="middle"
					typeText={"middle"}
					status="purple"
				>
					{!this.props.windowSizes.xs ? text.heroShowTariffs : text.FIND}
				</Button>
			);
		}

		return (
			<Button
				onClick={this.props.onClickButton}
				fullWidth={true}
				disabled={!this.props.fullAddressValue}
				noPaddings={true}
				datatest={this.props.datatestButton || "find_tohome_button"}
				size="middle"
				typeText="middle"
				status="tertiaryAccent"
			>
				{!this.props.windowSizes.xs ? text.heroShowTariffs : text.FIND}
			</Button>
		);
	}

	renderForm() {
		const { classes } = this.props;

		return (
			<>
				<div className={classes.layoutAStreetHouseWrapper}>
					<div className={classes.layoutAStreet}>{this.renderFullAddressDropdown()}</div>
				</div>
				<div className={classes.layoutASwitcher}>
					<div className={classes.smWrapper}>{this.renderSelectRouteSwitcher()}</div>
				</div>
				<div
					className={classes.layoutASButton}
					ref={(button) => {
						!!this.props.refButton && this.props.refButton(button);
					}}
				>
					<div className={classes.smWrapper}>{this.renderButton()}</div>
				</div>
			</>
		);
	}

	renderLayoutB() {
		let classes = this.props.classes;
		return (
			<>
				<div className={classes.layoutB}>
					<div className={classes.layoutBStreet}>{this.renderFullAddressDropdown()}</div>
				</div>

				{!this.props.withoutSwitcher && (
					<div className={classes.layoutBSwitcher}>{this.renderSelectRouteSwitcherB()}</div>
				)}

				{!this.props.withoutButton && (
					<div className={classes.layoutBButton}>{this.renderSmallButton()}</div>
				)}
			</>
		);
	}

	wasViewedHandler(visible) {
		if (visible) {
			sendMetriks("VIEW_TEST_SKOR");
		}
	}

	render() {
		let classes = this.props.classes;

		if (!this.props.layoutB) {
			return (
				<div className={classes.root}>
					<Row
						className={classNames({
							"justify-content-center": !this.props.notJustifyCenter,
						})}
					>
						<VisibilitySensor onChange={this.wasViewedHandler}>
							{this.renderForm()}
						</VisibilitySensor>
					</Row>
				</div>
			);
		}

		return <div className={classes.root}>{this.renderLayoutB()}</div>;
	}
}
export default withStyles(styles)(SearchAddress);
