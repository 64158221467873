import moment from "moment";

export const generateTime = (date) => {
	return moment(date).format("DD.MM.YYYY");
};

export const checkTimeMatch = (start, end) => {
	const format = "hh:mm:ss";

	const startTime = moment(start, format);

	const endTime = moment(end, format);
	return moment().isBetween(startTime, endTime);
};

export const getCurrentDayOfWeek = () => {
	const format = "ddd";

	return moment().locale("en").format(format).toLowerCase();
};
