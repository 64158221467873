let queryString = require("query-string");
const UrlManager = require("../application/helpers/UrlManager");

const generateValidChankUrl = UrlManager.generateValidChankUrl;
const generateStreetPart = UrlManager.generateStreetPart;

const routes = {
	TestProvidersPage: {
		route: "/testproviders",
		depend: "",
		bcName: "",
		name: "TestProvidersPage",
	},
	// u,s
	AddressDistricts: {
		route: "/:regionUrl?/address/:page([1-9]+)?",
		depend: "",
		bcName: "Карта покрытия",
		name: "AddressDistricts",
		nameHuman: "Округа или Районы",
		nameSeo: ["ADRESKA"],
		seo: (params) => ({
			key: "ADRESKA",
			query: queryString.stringify(params),
		}),
	},
	AddressAutonomous: {
		route: "/:regionUrl?/address/districts",
		depend: "AddressDistricts",
		bcName: "",
		nameHuman: "Административные адреса",
		name: "AddressAutonomous",
		nameSeo: ["ADRESS_AUTONOMUS"],
		seo: (params) => ({
			key: "ADRESS_AUTONOMUS",
			query: queryString.stringify(params),
		}),
	},
	// u,s
	AddressAdministrativeDistricts: {
		route: "/:regionUrl?/address/districts/:districtName-id:districtId",
		depend: "AddressDistricts",
		nameHuman: "Административные адреса -> районы",
		name: "AddressAdministrativeDistricts",
		nameSeo: ["ADRESS_ADMINISTRATIVE_DISTRICTS"],
		seo: (params) => {
			delete params.districtName;
			return {
				key: "ADRESS_ADMINISTRATIVE_DISTRICTS",
				query: queryString.stringify(normalizedRouteObject(params)),
			};
		},
	},
	// u,s
	AddressStreets: {
		route: "/:regionUrl?/address/:districtName-id:districtId",
		depend: "AddressDistricts",
		bcName: ({ district }) => {
			if (!district) {
				return "";
			}
			return `Интернет провайдеры в ${district?.name}`;
		},
		nameHuman: "Район",
		name: "AddressStreets",
		nameSeo: ["PROVAIDERI_RAIONA"],
		seo: (params) => {
			delete params.districtName;
			return {
				key: "PROVAIDERI_RAIONA",
				query: queryString.stringify(normalizedRouteObject(params)),
			};
		},
	},
	// u,s
	AddressStreet: {
		route: "/:regionUrl?/address/:districtName-id:districtId/:streetName-id:streetId",
		depend: "AddressStreets",
		name: "AddressStreet",
		nameHuman: "Улица",
		nameSeo: ["PROVAIDERI_PO_ULICE"],
		bcName: ({ street }) => {
			if (!street) {
				return "";
			}
			return street?.name;
		},
		seo: (params) => {
			// delete params.districtName;
			// delete params.streetName;
			return {
				key: "PROVAIDERI_PO_ULICE",
				query: queryString.stringify(normalizedRouteObject(params)),
			};
		},
	},

	// Не используется
	Exclusive: {
		route: "/:regionUrl?/exclusive",
		depend: "",
		bcName: `Выгодные тарифы`,
		name: "Exclusive",
		nameHuman: "Эксклюзивы",
		nameSeo: ["TARIFI_SO_SKIDKAMI"],
		seo: (params) => ({
			key: "TARIFI_SO_SKIDKAMI",
			query: queryString.stringify(params),
		}),
	},

	// Не используется
	Orders: {
		route: "/:regionUrl?/orders",
		depend: "",
		bcName: "",
		name: "Orders",
	},
	// u,s
	OrderHome: {
		route: "/:regionUrl?/orders/home",
		depend: "",
		bcName: "Заявка на подключение интернета",
		name: "OrderHome",
		nameHuman: "Заявка на интернет домой",
		nameSeo: ["ZAYAVKA"],
		seo: (params) => ({
			key: "ZAYAVKA",
			query: queryString.stringify(params),
		}),
	},
	// u,s
	OrderToHome: {
		route: "/:regionUrl?/orders/tohome",
		depend: "",
		bcName: "поиск по адресу",
		name: "OrderToHome",
		nameHuman: "Поиск для интернета в дом",
		nameSeo: ["POISK_PO_ADRESU"],
		seo: (params) => ({
			key: "POISK_PO_ADRESU",
			query: queryString.stringify(params),
		}),
	},
	// u,s
	OrderOffice: {
		route: "/:regionUrl?/orders/office",
		depend: "",
		bcName: "Для бизнеса",
		name: "OrderOffice",
		nameHuman: "Поиск для интернета в офис",
		nameSeo: ["INTERNET_V_OFIS"],
		seo: (params) => ({
			key: "INTERNET_V_OFIS",
			query: queryString.stringify(params),
		}),
	},
	// u,s
	OrderOuthome: {
		route: "/:regionUrl?/orders/sat/:step([1-9]+)?",
		depend: "",
		bcName: "Заявка в загородный дом",
		name: "OrderOuthome",
		nameHuman: "Поиск для интернета в загородный дом",
		nameSeo: ["INTERNET_V_CHASTNII_DOM"],
		seo: (params) => ({
			key: "INTERNET_V_CHASTNII_DOM",
			query: queryString.stringify(params),
		}),
	},
	// u,s
	OrderMobile: {
		route: "/:regionUrl?/orders/mobile/:step([1-9]+)?",
		depend: "Orders",
		bcName: "",
		name: "OrderMobile",
		nameHuman: "Заявка на интернет мобильный",
		nameSeo: ["ORDER_MOBILE_STEP_ONE_PAGE", "ORDER_MOBILE_WITHOUT_REGION_PAGE"],
		seo: (params) => ({
			key: params.regionUrl ? "ORDER_MOBILE_STEP_ONE_PAGE" : "ORDER_MOBILE_WITHOUT_REGION_PAGE",
			query: queryString.stringify(params),
		}),
	},

	OrderAss: {
		route: "/:regionUrl?/orders/ass/:step([1-9]+)?",
		depend: "Orders",
		bcName: "",
		name: "OrderAss",
		nameHuman: "Заявка на интернет хоть какой-нибудь",
		nameSeo: ["ORDER_ASS_STEP_ONE_PAGE"],
		seo: (params) => ({
			key: "ORDER_ASS_STEP_ONE_PAGE",
			query: queryString.stringify(params),
		}),
	},

	Providers: {
		route: "/:regionUrl?/providers/:page([0-9]+)?",
		depend: "",
		bcName: ({ region }) => {
			if (!region?.declinations) {
				return "Каталог интернет-провайдеров";
			}
			return `Провайдеры ${region.declinations.genitive}`;
		},
		name: "Providers",
		nameHuman: "Каталог провайдеров",
		nameSeo: ["KATALOG_PAGINACIYA", "KATALOG"],
		seo: (params) => ({
			key: params.page && params.page > 1 ? "KATALOG_PAGINACIYA" : "KATALOG",
			query: queryString.stringify(params),
		}),
	},

	ProviderDetails: {
		route: "/:regionUrl?/providers/:providerName",
		depend: "Providers",
		bcName: ({ provider }) => {
			return `${provider?.name}`;
		},
		name: "ProviderDetails",
		nameHuman: "Карточка провайдера",
		nameSeo: ["KARTOCHKA_PROVAIDERA"],
		seo: (params) => ({
			key: "KARTOCHKA_PROVAIDERA",
			query: queryString.stringify({
				...params,
				tagType: "provider",
			}),
		}),
	},

	ProviderRates: {
		route: "/:regionUrl?/providers/:providerName/rates/:page([0-9]+)?",
		depend: "ProviderDetails",
		bcName: "Тарифы",
		name: "ProviderRates",
		nameHuman: "Тарифы провайдера",
		nameSeo: ["KARTOCHKA_PROVAIDERA_RATES_PAGINACIYA", "KARTOCHKA_PROVAIDERA_RATES"],
		seo: (params) => ({
			key:
				params.page && params.page > 1
					? "KARTOCHKA_PROVAIDERA_RATES_PAGINACIYA"
					: "KARTOCHKA_PROVAIDERA_RATES",
			query: queryString.stringify({
				...params,
				tagType: "provider",
			}),
		}),
	},

	ProviderRatesTags: {
		route: "/:regionUrl?/providers/:providerName/rates/:tagCode/:page([0-9]+)?",
		depend: "ProviderRates",
		bcName: ({ tag }) => {
			return tag?.name || "";
		},
		name: "ProviderRatesTags",
		nameHuman: "Тарифы провайдера с тегом",
		nameSeo: ["KARTOCHKA_PROVAIDERA_RATES_TAGS"],
		seo: (params) => ({
			key: "KARTOCHKA_PROVAIDERA_RATES_TAGS",
			query: queryString.stringify({
				...params,
				tagType: "provider",
			}),
		}),
	},

	// u,s
	ProvidersReviewListOld: {
		route: "/:regionUrl?/rating/:providerName/:page([0-9]+)?",
		depend: "ProviderDetails",
		bcName: "Отзывы",
		name: "ProvidersReviewListOld",
		nameHuman: "Отзывы провайдера",
		nameSeo: ["OTZIVI_O_PROVAIDERE_PAGINACIYA", "OTZIVI_O_PROVAIDERE"],
		seo: (params) => ({
			key:
				params.page && params.page > 1 ? "OTZIVI_O_PROVAIDERE_PAGINACIYA" : "OTZIVI_O_PROVAIDERE",
			query: queryString.stringify({
				...params,
				tagType: "provider",
			}),
		}),
	},
	// Не используется
	ProvidersActions: {
		route: "/:regionUrl?/providers/actions/:page([0-9]+)?",
		depend: "Providers",
		bcName: "акции",
		name: "ProvidersActions",
	},
	// u,s
	ProvidersActionsList: {
		route: "/:regionUrl?/providers/actions/:providerName/:page([0-9]+)?",
		depend: "ProviderDetails",
		bcName: "Акции",
		name: "ProvidersActionsList",
		nameHuman: "Акции провайдера",
		nameSeo: ["AKCII_PROVAIDERA"],
		seo: (params) => ({
			key: "AKCII_PROVAIDERA",
			query: queryString.stringify({
				...params,
				tagType: "provider",
			}),
		}),
	},

	// Не используется
	ProvidersActionsDetails: {
		route: "/:regionUrl?/providers/:providerName/actions/:title-:actionId",
		depend: "ProvidersActions",
		bcName: "",
		name: "ProvidersActionsDetails",
	},
	// не используется
	ProvidersConnectTariff: {
		route: "/:regionUrl?/providers/:providerName/connect",
		depend: "ProviderDetails",
		bcName: "подключение",
		name: "ProvidersConnectTariff",
	},
	// u,s
	Reviews: {
		route: "/:regionUrl?/reviews/:page([0-9]+)?",
		depend: "",
		bcName: "Отзывы об интернет-провайдерах",
		name: "Reviews",
		nameHuman: "Отзывы провайдеров региона",
		nameSeo: ["OTZIVI_PAGINACIYA", "OTZIVI"],
		seo: (params) => ({
			key: params.page && params.page > 1 ? "OTZIVI_PAGINACIYA" : "OTZIVI",
			query: queryString.stringify(params),
		}),
	},
	// u,s
	ReviewItem: {
		route: "/:regionUrl?/rating/:providerName/otzyv-:reviewId",
		depend: "ProvidersReviewListOld",
		bcName: "Отзыв клиента",
		nameHuman: "Конкретный отзыв",
		name: "ReviewItem",
		nameSeo: ["OTZIV"],
		seo: (params) => ({
			key: "OTZIV",
			query: queryString.stringify(params),
		}),
	},
	// u,s
	Rating: {
		route: "/:regionUrl?/rating",
		depend: "",
		bcName: "Рейтинг провайдеров",
		name: "Rating",
		nameHuman: "Рейтинг провайдеров региона",
		nameSeo: ["REITING"],
		seo: (params) => ({
			key: "REITING",
			query: queryString.stringify(params),
		}),
	},

	Business: {
		route: "/:regionUrl?/business",
		depend: "OrderOffice",
		noDefaultBc: true,
		bcName: "Заявка на подключение",
		name: "Business",
		nameHuman: "Заявка на подключение для бизнеса",
		nameSeo: ["USLUGI_DLYA_BIZNESA"],
		seo: (params) => ({
			key: "USLUGI_DLYA_BIZNESA",
			query: queryString.stringify(params),
		}),
	},

	Compare: {
		route: "/:regionUrl?/rates/compare",
		depend: "Rates",
		bcName: "Сравнить тарифы",
		name: "Compare",
		nameHuman: "Сранение тарифов",
		nameSeo: ["SRAVNENIE_TARIFOV"],
		seo: (params) => ({
			key: "SRAVNENIE_TARIFOV",
			query: queryString.stringify(params),
		}),
	},

	Rates: {
		route: "/:regionUrl?/rates/:page([0-9]+)?",
		depend: () => {
			return "";
		},
		bcName: () => {
			return "Тарифы на интернет";
		},
		name: "Rates",
		nameHuman: "Тарифы в регионе",
		nameSeo: ["TARIFI"],
		seo: (params) => ({
			key: "TARIFI",
			query: queryString.stringify(params),
		}),
	},

	//"Незолотые" дома
	DomaNzl: {
		route: "/:regionUrl?/doma-nzl/:page([0-9]+)?",
		depend: ({ houseFull, house_id }) => {
			return houseFull && houseFull.house_full && house_id ? "BreadcrumbStreetStub" : "";
		},
		bcName: ({ houseFull, house_id }) => {
			if (!house_id || !houseFull?.house_full) {
				return "Тарифы на интернет";
			}
			return houseFull.house_full;
		},
		name: "DomaNzl",
		nameHuman: "Тарифы по адресу",
		nameSeo: ["TARIFI_PO_ADRESU_DOMA"],
		seo: (params) => ({
			key: "TARIFI_PO_ADRESU_DOMA",
			query: queryString.stringify(params),
		}),
	},

	//"Золотые" дома
	RatesInHouse: {
		route: "/:regionUrl?/dom/:house_url-id:house_id/:page([0-9]+)?",
		depend: ({ houseFull, house_id }) => {
			return houseFull && houseFull.house_full && house_id ? "BreadcrumbStreetStub" : "";
		},
		bcName: ({ houseFull, house_id }) => {
			if (!house_id || !houseFull?.house_full) {
				return "Тарифы на интернет";
			}

			return houseFull.house_full;
		},
		name: "RatesInHouse",
		nameHuman: "Тарифы по адресу",
		nameSeo: ["TARIFI_PO_ADRESU_YNIKALNOVO_DOMA"],
		seo: (params) => ({
			key: "TARIFI_PO_ADRESU_YNIKALNOVO_DOMA",
			query: queryString.stringify(params),
		}),
	},

	RatesTags: {
		route: "/:regionUrl?/rates/:tagCode/:page([0-9]+)?",
		depend: "Rates",
		bcName: ({ tag }) => {
			return tag?.name || "";
		},
		name: "RatesTags",
		nameHuman: "Тарифы в регионе или по адресу с тегом",
		nameSeo: ["TARIFI_TAGS"],
		seo: (params) => ({
			key: "TARIFI_TAGS",
			query: queryString.stringify({
				...params,
				tagType: "provider",
			}),
		}),
	},

	BreadcrumbDistrictStub: {
		route: "/:regionUrl?/address/:districtName-id:districtId",
		depend: "OrderToHome",
		bcName: ({ houseFull, region }) => {
			if (!houseFull || !region) {
				return null;
			}
			return `${houseFull.district_street_name}`;
		},
		bcRoute: ({ params, houseFull }) => {
			if (!houseFull?.district_street_name || !houseFull?.district_street_id) {
				return {
					route: routes.Default.route,
					params: {
						regionUrl: params.regionUrl,
					},
				};
			}
			return {
				route: routes.AddressStreets.route,
				params: {
					regionUrl: params.regionUrl,
					districtName: generateValidChankUrl(houseFull.district_street_name),
					districtId: houseFull.district_street_id,
				},
			};
		},
		nameHuman: "ХК Район",
		name: "BreadcrumbDistrictStub",
	},
	BreadcrumbStreetStub: {
		route: "/:regionUrl?/address/:districtName-id:districtId/:streetName-id:streetId",
		depend: "BreadcrumbDistrictStub",
		bcName: ({ houseFull, region }) => {
			if (!houseFull?.street || !region) {
				return null;
			}
			return `${houseFull.street}`;
		},
		bcRoute: ({ params, houseFull }) => {
			if (
				!houseFull?.district_street_name ||
				!houseFull?.district_street_id ||
				!houseFull?.street
			) {
				return {
					route: routes.Default.route,
					params: {
						regionUrl: params.regionUrl,
					},
				};
			}
			return {
				route: routes.AddressStreet.route,
				params: {
					regionUrl: params.regionUrl,
					districtName: generateValidChankUrl(houseFull.district_street_name),
					districtId: houseFull.district_street_id,
					streetName: generateStreetPart({
						name: houseFull.street,
						type: houseFull.socr,
					}),
					streetId: houseFull.street_id,
				},
			};
		},
		nameHuman: "ХК Улица",
		name: "BreadcrumbStreetStub",
	},
	// u,s
	DetectRegion: {
		route: "/detect-region",
		depend: "",
		bcName: "",
		name: "DetectRegion",
	},

	// u,-s
	Actions: {
		route: "/:regionUrl?/actions/:page([0-9]+)?",
		depend: "",
		bcName: "Акции на домашний интернет",
		name: "Actions",
		nameHuman: "Акции региона",
		nameSeo: ["AKCII_PAGINACIYA", "AKCII"],
		seo: (params) => ({
			key: params.page && params.page > 1 ? "AKCII_PAGINACIYA" : "AKCII",
			query: queryString.stringify(params),
		}),
	},

	ActionsItem: {
		route: "/:regionUrl?/providers/actions/:providerName/:newsName-:newsId([0-9]+)",
		depend: "Actions",
		name: "ActionsItem",
		nameHuman: "Конкретная акция",
		bcName: ({ currentNews }) => {
			return currentNews?.provider?.name;
		},
		bcRoute: ({ params, currentNews }) => {
			let providerName = currentNews?.provider?.url_name;
			if (!providerName) return null;

			return {
				route: routes.ProviderDetails.route,
				params: {
					regionUrl: params.regionUrl,
					providerName: providerName,
				},
			};
		},
		nameSeo: ["AKCIYA_PROVAIDERA"],
		seo: (params) => {
			let newParams = params;
			delete newParams.newsName;
			return {
				key: "AKCIYA_PROVAIDERA",
				query: queryString.stringify(normalizedRouteObject(newParams)),
			};
		},
	},

	TermsOfUse: {
		route: "/:regionUrl?/about/terms-of-use",
		depend: "",
		bcName: "Пользовательское соглашение",
		name: "TermsOfUse",
		nameHuman: "Пользовательское соглашение",
		nameSeo: ["POLZOVATELSKOE_SOGLASHENIE"],
		seo: (params) => ({
			key: "POLZOVATELSKOE_SOGLASHENIE",
			query: queryString.stringify(params),
		}),
	},

	PersonalData: {
		route: "/:regionUrl?/about/personal-data",
		depend: "",
		bcName: "Политика обработки персональных данных",
		name: "PersonalData",
		nameHuman: "Персональные данные",
		nameSeo: ["PERSONALNIE_DANNIE"],
		seo: (params) => ({
			key: "PERSONALNIE_DANNIE",
			query: queryString.stringify(params),
		}),
	},

	AddReview: {
		route: "/:regionUrl?/feedback",
		depend: "",
		bcName: "Написать отзыв",
		name: "AddReview",
		nameHuman: "Оставить отзыв",
		nameSeo: ["DOBAVIT_OTZIV"],
		seo: (params) => ({
			key: "DOBAVIT_OTZIV",
			query: queryString.stringify(params),
		}),
	},

	Partner: {
		route: "/:regionUrl?/about/partners",
		depend: "",
		bcName: "Сотрудничество",
		name: "Partner",
		nameHuman: "Сотрудничество",
		nameSeo: ["PARTNERS"],
		seo: (params) => ({
			key: "PARTNERS",
			query: queryString.stringify(params),
		}),
	},

	Contacts: {
		route: "/:regionUrl?/about/contacts",
		depend: "",
		bcName: "Контакты",
		name: "Contacts",
		nameHuman: "Контакты",
		nameSeo: ["CONTACTS"],
		seo: (params) => ({
			key: "CONTACTS",
			query: queryString.stringify(params),
		}),
	},

	PayWarranty: {
		route: "/:regionUrl?/about/oplata-i-garantii",
		depend: "",
		bcName: "Оплата и гарантия",
		name: "PayWarranty",
		nameHuman: "Оплата и гарантия",
		nameSeo: ["PAY_WARRANTY"],
		seo: (params) => ({
			key: "PAY_WARRANTY",
			query: queryString.stringify(params),
		}),
	},

	ContactFeedback: {
		route: "/:regionUrl?/contact-feedback",
		depend: "",
		bcName: "Обратная связь",
		name: "ContactFeedback",
		nameHuman: "Обратная связь",
		nameSeo: ["CONTACT_FEEDBACK"],
		seo: (params) => ({
			key: "CONTACT_FEEDBACK",
			query: queryString.stringify(params),
		}),
	},

	Quiz: {
		route: "/:regionUrl?/quiz",
		depend: "",
		bcName: "Quiz",
		name: "Quiz",
		nameHuman: "Quiz",
		nameSeo: ["QUIZ"],
		seo: (params) => ({
			key: "QUIZ",
			query: queryString.stringify(params),
		}),
	},

	SelectRegion: {
		route: "/select-region",
		depend: "",
	},

	Page404: {
		route: "/:regionUrl?/404",
		depend: "",
		bcName: "404",
		name: "Page404",
		nameHuman: "URL 404",
		nameSeo: ["ADD_REVIEW"],
		seo: (params) => ({
			key: "ADD_REVIEW",
			query: queryString.stringify(params),
		}),
	},

	Operatory: {
		route: "/:regionUrl?/operatory/:page([0-9]+)?",
		depend: "",
		bcName: "Мобильные операторы",
		name: "Operatory",
		nameHuman: "Каталог операторов",
		nameSeo: ["OPERATORY_PAGINACIYA", "OPERATORY"],
		seo: (params) => ({
			key: params.page && params.page > 1 ? "OPERATORY_PAGINACIYA" : "OPERATORY",
			query: queryString.stringify({
				find_operator: 1,
				...params,
			}),
		}),
	},

	OperatorDetails: {
		route: "/:regionUrl?/operatory/:operator",
		depend: "Operatory",
		bcName: ({ provider }) => {
			return provider?.name;
		},
		name: "OperatorDetails",
		nameHuman: "Карточка оператора",
		nameSeo: ["KARTOCHKA_OPERATORA"],
		seo: (params) => ({
			key: "KARTOCHKA_OPERATORA",
			query: queryString.stringify({
				find_operator: 1,
				...params,
			}),
		}),
	},

	OperatoryTariffs: {
		route: "/:regionUrl?/operatory/:operator/ratesmobile/:tagCode?",
		depend: ({ params }) => {
			if (!!params.tagCode) {
				return "BreadcrumbOperatoryTariffs";
			}
			return "OperatorDetails";
		},
		bcName: ({ params, tag }) => {
			if (!!params?.tagCode) {
				return tag?.name || "";
			}
			return `Тарифы`;
		},
		name: "OperatoryTariffs",
		nameHuman: "Мобильные тарифы оператора и с тегом",
		nameSeo: ["RATESMOBILE_OPERATORA_TEGY", "RATESMOBILE_OPERATORA"],
		seo: (params) => ({
			key: !!params.tagCode ? "RATESMOBILE_OPERATORA_TEGY" : "RATESMOBILE_OPERATORA",
			query: queryString.stringify({
				tagType: "mobile",
				find_operator: 1,
				...params,
			}),
		}),
	},

	BreadcrumbOperatoryTariffs: {
		route: "/:regionUrl?/operatory/:operator/ratesmobile",
		depend: "OperatorDetails",
		bcName: "Тарифы",
		name: "BreadcrumbOperatoryTariffs",
		nameHuman: "ХК Мобильные тарифы оператора",
	},

	OperatoryBeautifulNumbers: {
		route: "/:regionUrl?/operatory/:operator/nomera/:tagCode?",
		depend: ({ params }) => {
			if (!!params.tagCode) {
				return "BradcrumbsOperatoryBeautifulNumbers";
			}
			return "OperatorDetails";
		},
		bcName: ({ params, tag }) => {
			if (!!params?.tagCode) {
				return tag?.name || "";
			}
			return `Номера`;
		},
		name: "OperatoryBeautifulNumbers",
		nameHuman: "Красивые номера оператора и с тегом",
		nameSeo: ["KRASIVIE_NOMERA_OPERATORA_TEGY", "KRASIVIE_NOMERA_OPERATORA"],
		seo: (params) => ({
			key: params.tagCode ? "KRASIVIE_NOMERA_OPERATORA_TEGY" : "KRASIVIE_NOMERA_OPERATORA",
			query: queryString.stringify({
				tagType: "beauty",
				find_operator: 1,
				...params,
			}),
		}),
	},

	BradcrumbsOperatoryBeautifulNumbers: {
		route: "/:regionUrl?/operatory/:operator/nomera",
		depend: "OperatorDetails",
		bcName: "Номера",
		name: "BradcrumbsOperatoryBeautifulNumbers",
		nameHuman: "ХК Красивые номера",
	},

	OperatoryActions: {
		route: "/:regionUrl?/operatory/:operator/actions",
		depend: "OperatorDetails",
		bcName: "Акции",
		name: "OperatoryActions",
		nameHuman: "Акции оператора",
		nameSeo: ["ACTIONS_OPERATORA"],
		seo: (params) => ({
			key: "ACTIONS_OPERATORA",
			query: queryString.stringify({
				find_operator: 1,
				tagType: "actions",
				...params,
			}),
		}),
	},

	RatesMobile: {
		route: "/:regionUrl?/ratesmobile/:tagCode?",
		depend: ({ params }) => {
			if (!!params.tagCode) {
				return "BreadcrumbRatesMobile";
			}
			return "";
		},
		bcName: ({ params, tag }) => {
			if (!!params.tagCode) {
				return tag?.name || "";
			}
			return "Тарифы сотовой связи";
		},
		name: "RatesMobile",
		nameHuman: "Мобильные тарифы района и с тегом",
		nameSeo: ["RATESMOBILE_TEGY", "RATESMOBILE"],
		seo: (params) => ({
			key: params.tagCode ? "RATESMOBILE_TEGY" : "RATESMOBILE",
			query: queryString.stringify({
				tagType: "mobile",
				find_operator: 1,
				...params,
			}),
		}),
	},

	BreadcrumbRatesMobile: {
		route: "/:regionUrl?/ratesmobile",
		depend: "",
		bcName: "Тарифы сотовой связи",
		name: "BreadcrumbRatesMobile",
		nameHuman: "ХК Мобильные тарифы",
	},

	BeautifulNumbers: {
		route: "/:regionUrl?/nomera/:tagCode?",
		depend: ({ params }) => {
			if (!!params.tagCode) {
				return "BreadcrumbBeautifulNumbers";
			}
			return "";
		},
		bcName: ({ params, tag }) => {
			if (!!params.tagCode) {
				return tag?.name || "";
			}
			return "Номера";
		},
		name: "BeautifulNumbers",
		nameHuman: "Красивые номера и с тегом",
		nameSeo: ["KRASIVIE_NOMERA_TEGY", "KRASIVIE_NOMERA"],
		seo: (params) => ({
			key: !!params.tagCode ? "KRASIVIE_NOMERA_TEGY" : "KRASIVIE_NOMERA",
			query: queryString.stringify({
				tagType: "beauty",
				find_operator: 1,
				...params,
			}),
		}),
	},

	BreadcrumbBeautifulNumbers: {
		route: "/:regionUrl?/nomera",
		depend: "",
		bcName: "Номера",
		name: "BreadcrumbBeautifulNumbers",
		nameHuman: "ХК Красивые номера",
	},

	SiteMap: {
		route: "/:regionUrl?/site-map",
		depend: "",
		bcName: "Карта сайта",
		name: "SiteMap",
		nameHuman: "Карта сайта",
		nameSeo: ["SITE_MAP"],
		seo: (params) => ({
			key: "SITE_MAP",
			query: queryString.stringify({
				...params,
			}),
		}),
	},

	AboutCompany: {
		route: "/:regionUrl?/about/company",
		depend: "",
		bcName: "о нас",
		name: "AboutCompany",
		nameHuman: "Информация о компании",
		nameSeo: ["ABOUT_COMPANY"],
		seo: (params) => ({
			key: "ABOUT_COMPANY",
			query: queryString.stringify({
				...params,
			}),
		}),
	},

	ConnectInternet: {
		route: "/:regionUrl?/podklyuchit-internet",
		depend: "",
		bcName: "Подключить интернет",
		name: "ConnectInternet",
		nameHuman: "Подключить интернет",
		nameSeo: ["CONNECT_INTERNET"],
		seo: (params) => {
			let { regionUrl, regionUrlComing } = params;
			let newParams = { regionUrl, regionUrlComing };
			return {
				key: "CONNECT_INTERNET",
				query: queryString.stringify(newParams),
			};
		},
	},

	Default: {
		route: "/:regionUrl?",
		depend: "",
		bcName: "Подключить интернет",
		name: "Default",
		nameHuman: "Главная",
		nameSeo: ["GLAVNAYA", "GLAVNAYA-BEZ-REGIONA"],
		seo: (params) => {
			let { regionUrl, regionUrlComing, ...other } = params;
			let newParams = { regionUrl, regionUrlComing };
			return {
				key: params.regionUrl ? "GLAVNAYA" : "GLAVNAYA-BEZ-REGIONA",
				query: params.regionUrl ? queryString.stringify(newParams) : "",
			};
		},
	},
};

module.exports = routes;

const normalizedRouteObject = (params) => {
	let normalizedParams = {};
	for (var i in params) {
		normalizedParams[i] = decodeURIComponent(params[i]);
	}

	return normalizedParams;
};
