import React from "react";
import loadable from "@loadable/component";
import LoadingSpinnerHOC from "HOC/LoadingSpinnerHOC";

export const TermsOfUse = loadable(() => import("./termsOfUse"), {
	fallback: <LoadingSpinnerHOC />,
});
export const PersonalData = loadable(() => import("./personalData"), {
	fallback: <LoadingSpinnerHOC />,
});
