import React from "react";
import { inject, observer } from "mobx-react";

@inject("ApplicationModel")
@observer
export default class RecentlyConnectedTariffConnectHOC extends React.Component {
	openPopup(tariff) {
		this.closeModal();
		let texts = this.props.ApplicationModel.text;
		let modalModel = this.props.ApplicationModel.modalModel;
		let searcheModel = this.props.ApplicationModel.searcheModel;
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		let promise1 = import("Layouts/modalLayout");
		let promise2 = import("Containers/RecentlyConnectedCheckConnectionContainer");
		Promise.all([promise1, promise2]).then((modules) => {
			let ModalLayout = modules[0].default;
			let RecentlyConnectedCheckConnectionContainer = modules[1].default;
			commonViewModel.setShowSearchAddressPopup(true);
			if (this.props.recently) {
				searcheModel.setRecentlyConnectedSelectedTariffId(tariff.id);
			} else if (this.props.action) {
				searcheModel.setActionSelectedTariffId(tariff.id);
			}
			modalModel.toggleModal(
				<ModalLayout authView={true} withoutClose={true}>
					<RecentlyConnectedCheckConnectionContainer
						provider={tariff?.provider}
						text={texts}
						onClose={() => this.closeModal()}
						action={this.props.action}
						recently={this.props.recently}
					/>
				</ModalLayout>
			);
		});
	}

	closeModal() {
		let modalModel = this.props.ApplicationModel.modalModel;
		let commonViewModel = this.props.ApplicationModel.commonViewModel;
		commonViewModel.setShowSearchAddressPopup(false);
		if (modalModel.isOpen) {
			modalModel.toggleModal();
		}
	}

	render() {
		return React.cloneElement(this.props.children, {
			onCheckButtonClick: (data) => this.openPopup(data),
		});
	}
}
