import { createLocation } from "history";
function useBaseTag(createHistory) {
	return function (options = {}) {
		let history = null;
		let { ...historyOptions } = options;
		const tgs = document.getElementsByTagName("base");
		// Если имеется тэг base, то устанавливаем флаг forceRefresh и устанавливаем правельный location
		if (tgs[0]) {
			history = createHistory({ ...historyOptions, forceRefresh: true });
			const url = new URL(tgs[0].getAttribute("href"));
			history.location = createLocation(`${url.pathname}${url.search}${url.hash}`);
			history.push = function (path, state) {
				window.location.href = path;
			};
			history.replace = function (path, state) {
				window.location.href = path;
			};
		} else {
			history = createHistory(historyOptions);
		}

		return history;
	};
}

export { useBaseTag };
