import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { setDisplayName } from "recompose";

const styles = (theme) => ({
	root: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	},
	textWrapper: {
		display: "block",
	},
	icon: {
		"& span": {
			maxWidth: "48px",
			maxHeight: "48px",
		},
	},
	header: {
		fontFamily: theme.fonts.bold,
		fontSize: "24px",
		lineHeight: "1",
		color: theme.palette.colors.white,
		marginBottom: "20px",
	},
	text: {
		fontFamily: theme.fonts.light,
		fontSize: "16px",
		lineHeight: "1.25",
		color: theme.palette.colors.white,
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		header: {
			fontSize: "18px",
			marginBottom: "13px",
		},
		text: {
			fontSize: "14px",
			lineHeight: "1.29",
		},
	},
});

const Benefit = ({ header, icon, text, backgroundColor, classes, datatest }) => {
	return (
		<div className={classes.root} datatest={datatest}>
			<div className={classes.textWrapper}>
				<div className={classes.header}>{header}</div>
				<div className={classes.text}>{text}</div>
			</div>
			<div className={classes.icon}>{icon}</div>
		</div>
	);
};

Benefit.propTypes = {
	header: PropTypes.string.isRequired,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
	icon: PropTypes.element,
	marginBottomZero: PropTypes.bool,
	yellowTheme: PropTypes.bool,
};

Benefit.defaultProps = {
	header: "",
	icon: null,
	text: "",
	marginBottomZero: false,
	yellowTheme: false,
	checkout: false,
	datatest: null,
};
export default setDisplayName("BenefitDefault")(withStyles(styles)(Benefit));
