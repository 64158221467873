import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			listStyle: "none",
			display: "flex",
			padding: 0,
			margin: 0,
		},
		showMobile: {},
		item: {
			marginRight: "20px",
			"& a": {
				height: "50px",
				borderRadius: "10px",
				width: "180px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				fontFamily: theme.fonts.medium,
				color: theme.palette.colors.white,
				fontSize: "14px",
				textAlign: "center",
				lineHeight: 1,
				letterSpacing: "2px",
				textTransform: "uppercase",
			},
			"&:last-child": {
				marginRight: 0,
			},
		},
		activeItem: {
			"& a": {
				backgroundColor: theme.palette.colors.purplishBlue,
			},
		},
		inWidget: {
			borderRadius: "10px",
			overflow: "hidden",
			width: "100%",
			"& $item": {
				margin: 0,
				"& a": {
					width: "auto",
					height: "50px",
					borderRadius: 0,
					padding: "0 20px",
					backgroundColor: theme.palette.colors.cloudyBlue,
					fontFamily: theme.fonts.bold,
					fontSize: "12px",
					letterSpacing: "1.7px",
				},
				"&:first-child": {
					width: "60%",
				},
				"&:last-child": {
					width: "40%",
				},
			},
			"& $activeItem": {
				"& a": {
					backgroundColor: `${theme.palette.colors.purplishBlue} !important`,
				},
			},
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			item: {
				marginRight: 0,
				"& a": {
					width: "auto",
					padding: "0 15px",
				},
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			item: {
				marginRight: "10px",
				"& a": {
					width: "170px",
				},
				"&:last-child": {
					marginRight: 0,
					"& a": {
						width: "130px",
					},
				},
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				borderRadius: "10px",
				overflow: "hidden",
				maxWidth: "100%",
			},
			item: {
				margin: 0,
				"& a": {
					width: "auto",
					height: "50px",
					borderRadius: 0,
					padding: "0 20px",
					backgroundColor: theme.palette.colors.cloudyBlue,
					fontFamily: theme.fonts.bold,
					fontSize: "12px",
					letterSpacing: "1.7px",
				},
			},
			activeItem: {
				"& a": {
					backgroundColor: theme.palette.colors.purplishBlue,
				},
			},
		},
	};
};

class UpperMenu extends React.Component {
	render() {
		let classes = this.props.classes;
		return (
			<ul
				className={classNames(classes.root, {
					[classes.inWidget]: this.props.inWidget,
				})}
			>
				{React.Children.map(this.props.children, (item) => {
					return (
						<li
							className={classNames(classes.item, {
								[classes.activeItem]: item.props.isActive(),
							})}
						>
							{item}
						</li>
					);
				})}
			</ul>
		);
	}
}

export default withStyles(styles)(UpperMenu);
