var gql = require("@apollo/client").gql;

const STREET_ONE_QUERY = gql`
	query STREET_ONE_QUERY($id: Int) {
		streetsOne(id: $id) {
			id
			region_id
			name
			full
			type
			district {
				id
				name
			}
			region {
				id
				name
				url
				is_capital
			}
		}
	}
`;

export default STREET_ONE_QUERY;
