var gql = require("@apollo/client").gql;

const COUNT_PROVIDERS_QUERY = gql`
	query Count_Providers($filter: String) {
		countProvidersQuery(filter: $filter) {
			count
		}
	}
`;

export default COUNT_PROVIDERS_QUERY;
