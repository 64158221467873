import { CDN_URL } from "Constants/CDN";

export const AggrementPdfFile = {
	"101internet": {
		app1: `${CDN_URL}/docs/agreement/101_app_1.pdf`,
		app2: `${CDN_URL}/docs/agreement/101_app_2.pdf`,
		processing: `${CDN_URL}/docs/agreement/101_agreement.pdf`,
		manual: `${CDN_URL}/docs/agreement/manual.pdf`,
		functional: `${CDN_URL}/docs/agreement/functional_description.pdf`,
	},
	moskvaonline: {
		app1: `${CDN_URL}/docs/agreement/mol_app_1.pdf`,
		app2: `${CDN_URL}/docs/agreement/mol_app_2.pdf`,
		processing: `${CDN_URL}/docs/agreement/mol_agreement.pdf`,
	},
	piteronline: {
		app1: `${CDN_URL}/docs/agreement/pol_app_1.pdf`,
		app2: `${CDN_URL}/docs/agreement/pol_app_2.pdf`,
		processing: `${CDN_URL}/docs/agreement/pol_agreement.pdf`,
	},
};

export const SupportCat = `${CDN_URL}/images/cat_icons/cat_headset.png`;

export const MainNoRegion = "/";

export const RetentionLogoStep2 = `${CDN_URL}/images/cat_icons/cat_headset.png`;

export const AntifilterHeaderGif = `${CDN_URL}/images/gifs/antifilter.gif`;

export const ReviewCat = `${CDN_URL}/images/cat_icons/cat_review.png`;
