import React from "react";
import { FindTariffsWithPhone } from "ComponentsUI/Navigation";
import { sendMetriks } from "Services/sendMetriks";
import Metrika from "Services/Metrika";
import {
	EVENT_CATEGORY_CLICK_BUTTON,
	EVENT_CATEGORY_PAGE_VIEW,
} from "Constants/metriks/GoogleCategorys";
import {
	EVENT_SUCCESS_SEARCH_COVERAGE_PROVIDER,
	CLICK_LOAD_CALLBACK,
} from "Constants/metriks/GoogleActions";

export default class extends React.Component {
	componentDidMount() {
		sendMetriks("OPEN_ORDER_FORM");
		Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_PAGE_VIEW, CLICK_LOAD_CALLBACK);
	}

	successSearchCallback() {
		Metrika.getInstance().google.sendEvent(
			EVENT_CATEGORY_CLICK_BUTTON,
			EVENT_SUCCESS_SEARCH_COVERAGE_PROVIDER
		);
	}

	render() {
		return (
			<FindTariffsWithPhone
				{...this.props}
				typeB={true}
				successCallback={() => this.successSearchCallback()}
			/>
		);
	}
}
