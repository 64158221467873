import React from "react";
import withStyles from "react-jss";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isMainSiteCode } from "Utils/siteConfig";
import { svgPlane } from "Constants/SVG";

const formStyle = (theme) => ({
	root: {
		width: "100%",
		position: "relative",
		padding: "16px 0 30px",
	},
	rootSend: {
		width: "100%",
		position: "relative",
		padding: "16px 0 30px",
	},
	wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-start",
	},
	success: {
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexWrap: "wrap",
		flexDirection: "column",
		animation: "2s fromFade",
	},
	successHomeOrder: {
		padding: "16px 0 30px",
	},
	textFieldWrapper: {
		marginBottom: "27px",
		width: "100%",
	},
	buttonWrapper: {
		width: "100%",
	},
	header: {
		fontSize: "24px",
		marginTop: "16px",
		fontFamily: theme.fonts.bold,
		color: theme.palette.colors.darkSlateBlue,
		lineHeight: "1.2",
		textAlign: "center",
		marginBottom: "22px",
	},
	headerHomeOrder: {
		marginTop: "0",
	},
	headerSend: {
		fontSize: "24px",
		marginTop: "16px",
		marginBottom: "20px",
		fontFamily: theme.fonts.bold,
		color: theme.palette.colors.darkSlateBlue,
		lineHeight: "1.2",
		textAlign: "center",
	},
	textSend: {
		fontSize: "22px",
		fontFamily: theme.fonts.bold,
		color: theme.palette.colors.darkSlateBlue,
		textAlign: "center",
	},
	subheader: {
		fontSize: "16px",
		fontFamily: theme.fonts.light,
		color: theme.palette.colors.oneMoreGrey,
		textAlign: "center",
		marginBottom: "16px",
	},
	phone: {
		fontSize: "22px",
		marginTop: "30px",
		color: theme.palette.colors.purplishBlue,
		textAlign: "center",
		whiteSpace: "nowrap",
		fontFamily: theme.fonts.medium,
	},
	icon: {
		width: "50px",
		height: "50px",
		"& path": {
			fill: theme.palette.colors.purplishBlue,
		},
	},
	orderHomeTariffPad: {},
	homeOrder: {},
	[`@media (min-width: ${theme.media.lg})`]: {
		successHomeOrder: {
			padding: "16px 50px 30px",
		},
	},
	[`@media (max-width: ${theme.media.md})`]: {
		header: {
			fontSize: "18px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			padding: "0",
			width: "290px",
		},
		rootSend: {
			width: "100%",
			padding: "0",
		},
		orderHomeTariffPad: {
			width: "100%",
		},
		homeOrder: {
			width: "100%",
		},
		icon: {
			// justifyContent: "center"
		},
		phone: {
			marginTop: "20px",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			padding: "16px 0 0",
		},
		rootSend: {
			padding: "16px 0 0",
		},
		homeOrder: {
			padding: "0",
		},
		phone: {
			marginTop: "20px",
		},
	},
});

const TariffFormV2 = withStyles(formStyle)(({
	children,
	classes,
	isSuccess,
	isSend,
	statusSend,
	successHeader,
	successSubHeader,
	successButton,
	orderPhone,
	inlineOrderPhone,
	homeOrder,
	text,
	siteCode,
	windowSizes,
}) => {
	if (!isSuccess && !isSend) {
		let newChildren = children.map((child, i) => {
			if (!child) return null;
			if (child.type.displayName === "TextField") {
				return (
					<div key={i} className={classes.textFieldWrapper}>
						{React.cloneElement(child, {
							...child.props,
							key: i,
						})}
					</div>
				);
			}
			if (child.type.displayName === "Button") {
				return (
					<div key={i} className={classes.buttonWrapper}>
						{React.cloneElement(child, {
							...child.props,
							key: i,
						})}
					</div>
				);
			}
			return React.cloneElement(child, { ...child.props, key: i });
		});
		return (
			<div
				className={classNames(classes.root, {
					[classes.homeOrder]: homeOrder,
				})}
			>
				{newChildren}
			</div>
		);
	} else if (isSend && (statusSend == 1 || !isSuccess)) {
		return (
			<div className={classes.rootSend}>
				<div className={classes.wrapper}>
					<div className={classes.headerSend}>{text.send_order_1}</div>
					<svg className={classes.icon} width="50" height="50" viewBox={"0 0 2000 2000"}>
						<path d={svgPlane}></path>
					</svg>
				</div>
				<div className={classes.textSend}>{text.send_order_2}</div>
				<div className={classes.textSend}>{text.send_order_3}</div>
				<div className={classes.phone}>
					<a href={`tel:${inlineOrderPhone}`} className={classes.phone}>
						{orderPhone}
					</a>
				</div>
			</div>
		);
	} else if ((!statusSend || statusSend == 2) && isSuccess) {
		if (isMainSiteCode(siteCode) && (windowSizes.xs || windowSizes.sm)) {
			window.location.replace("https://101internet.ru/articles/routers");
			return;
		}
		return (
			<div
				className={classNames(classes.root, {
					[classes.success]: isSuccess,
					[classes.successHomeOrder]: homeOrder,
					[classes.orderHomeTariffPad]: isSuccess,
				})}
			>
				<div
					className={classNames(classes.header, {
						[classes.headerHomeOrder]: homeOrder,
					})}
				>
					{successHeader}
				</div>
				<div className={classes.subheader}>{successSubHeader}</div>
				{successButton}
			</div>
		);
	}
});

TariffFormV2.propTypes = {
	children: PropTypes.node,
};

export default TariffFormV2;
