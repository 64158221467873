import React from "react";
import { withRouter } from "react-router-dom";
import { pipe } from "Utils/functionalUtils";
import { WhatsappSendMessage } from "ComponentsUI/Whatsapp";
import { inject, observer } from "mobx-react";

const WhatsappSendMessageContainer = (props) => {
	let {
		ApplicationModel: { tariffsModel, siteConfig },
		whatsappType,
		whatsappSettings = [],
		text,
		children,
	} = props;
	const one_string_search = siteConfig?.settings?.one_string_search?.enabled;

	let houseId;
	if (one_string_search) {
		houseId = props.ApplicationModel?.searcheModelOneString?.house_id;
	} else {
		houseId = props.ApplicationModel?.searcheModel?.house_id;
	}

	if (whatsappType === "rates_house_id" && !houseId) {
		return null;
	}
	let tariff_id = tariffsModel.tariff_id;
	if (whatsappType === "connect_tariff" && !tariff_id) {
		return null;
	}

	let currentSetting = whatsappSettings[whatsappType];
	if (!currentSetting || !currentSetting.enabled) {
		return null;
	}

	return (
		<WhatsappSendMessage
			whatsappType={whatsappType}
			changeStyle={props.changeStyle}
			whatssappUrl={currentSetting.text}
			text={text}
		>
			{children}
		</WhatsappSendMessage>
	);
};

export default pipe(observer, inject("ApplicationModel"), withRouter)(WhatsappSendMessageContainer);
