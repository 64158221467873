import React from "react";
import withStyles from "react-jss";
import classNames from "classnames";
import PhoneContainerProvider from "Containers/PhoneContainerProvider";
import Metrika from "Services/Metrika";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";
import { EVENT_ACTION_CLICK_TARIFF_PHONE_BUTTON_FAKE } from "Constants/metriks/GoogleActions";
import { sendMetriks } from "Services/sendMetriks";

const style = (theme) => ({
	root: {
		padding: "14px 0 0",
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
	},
	header: {
		fontFamily: theme.fonts.medium,
		fontSize: "14px",
		lineHeight: "1.86",
		letterSpacing: "2px",
		color: theme.palette.colors.oneMoreGrey,
		textTransform: "uppercase",
	},
	headerLink: {
		fontFamily: theme.fonts.medium,
		fontSize: "14px",
		lineHeight: "1.86",
		marginBottom: "2px",
		color: theme.palette.colors.purplishBlue,
		textTransform: "uppercase",
	},
	phoneContainer: {
		fontFamily: theme.fonts.bold,
		fontSize: "18px",
		lineHeight: "1.44",
		textAlign: "right",
		color: theme.palette.colors.darkSlateBlue,
	},
	link: {
		borderBottom: "1px dashed",
	},
	yandexReview: {
		justifyContent: "center",
	},
	homeOrder: {
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end",
		"& div:first-child": {},
	},
	popupTariffOrder: {
		padding: 0,
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-end",
		"& div:first-child": {},
	},
	nowrap: {
		whiteSpace: "nowrap",
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			marginBottom: "0",
			display: "flex",
			justifyContent: "flex-start",
			padding: "0",
		},
		homeOrder: {
			justifyContent: "flex-end",
		},
		popupTariffOrder: {
			justifyContent: "flex-start",
		},
		yandexReview: {
			justifyContent: "center",
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			marginBottom: "0",
			display: "flex",
			justifyContent: "space-between",
			flexWrap: "wrap",
			padding: "0",
		},
		yandexReview: {
			justifyContent: "center",
		},
		homeOrder: {
			justifyContent: "flex-end",
		},
		popupTariffOrder: {
			justifyContent: "flex-start",
		},
	},
});

const ContactsV2 = withStyles(style)(({
	classes,
	provider,
	headerName,
	showName,
	phone,
	urlLink,
	setEvent,
	...props
}) => {
	const clickPhoneHandler = () => {
		Metrika.getInstance().google.sendEvent(
			EVENT_CATEGORY_CLICK_BUTTON,
			EVENT_ACTION_CLICK_TARIFF_PHONE_BUTTON_FAKE
		);
		if (setEvent) {
			setEvent("PHONE");
		}
	};

	const clickLinkHandler = () => {
		sendMetriks("CLICK_GO_SITE");
		if (setEvent) {
			setEvent("CLICK_ON_SITE");
		}
	};

	return (
		<div
			className={classNames(classes.root, {
				[classes.homeOrder]: props.homeOrder,
				[classes.popupTariffOrder]: props.popupTariffOrder,
			})}
		>
			{urlLink ? (
				<a className={classes.headerLink} href={urlLink} onClick={() => clickLinkHandler()}>
					Перейти на сайт
				</a>
			) : (
				<div className={classes.header}>{headerName}</div>
			)}
			<div
				className={classNames(classes.phoneContainer, {
					[classes.nowrap]: props.nowrap,
				})}
			>
				{provider.contacts.card == 3 ? (
					<a
						href={provider.contacts.link}
						className={classes.link}
						target="_blank"
						rel="noreferrer"
					>
						{showName}
					</a>
				) : (
					<PhoneContainerProvider clickHandler={() => clickPhoneHandler()} phone={phone} />
				)}
			</div>
		</div>
	);
});

export default ContactsV2;
