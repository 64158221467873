var gql = require("@apollo/client").gql;

const MUTATE_SEARCH_EVENT_QUERY = gql`
	mutation (
		$query: String!
		$action: SearchAction
		$user_info: SearchAnalyticsUserInfo
		$experiments: [Experiment]
	) {
		sendAction(query: $query, action: $action, user_info: $user_info, experiments: $experiments) {
			id
		}
	}
`;

export default MUTATE_SEARCH_EVENT_QUERY;
