var gql = require("@apollo/client").gql;
const DECLINATION_QUERY = gql`
	query Declination($filter: String!) {
		region(filter: $filter) {
			id
			name
			is_capital
			declinations {
				nominative
				genitive
				dative
				accusative
				ablative
				prepositional
			}
		}
	}
`;

module.exports = DECLINATION_QUERY;
