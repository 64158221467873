import { action, observable } from "mobx";
import LoaderData from "Services/LoaderData";
import pathToRegexp from "path-to-regexp";
import { captureException, captureMessage } from "@sentry/react";
import Metrika from "Services/Metrika";
import Cookies from "js-cookie";
import { failHandler, REQ_NAMES } from "Helpers/failSender";
import {
	SEND_YANDEX_CAPTCHA_QUERY,
	MUTATE_INFO_CLICK,
	MUTATE_SEND_ORDER,
	MUTATE_CLIENT_PORTRAIT,
	MUTATE_CLIENT_ACTION,
} from "Services/queries";
import { sendMetriks } from "Services/sendMetriks";

export default class SendingStore {
	subscribeUrl = "/subscribers";
	callbackUrl = "/orders?type=site101-callback";
	orderOfficeUrl = "/orders?type=site101-order-office";
	addReviewUrl = "/reviews-providers";
	addShortReviewUrl = "/reviews-short";
	feedbackUrl = "/feedbacks";
	addComment = "/reviews-providers/:review_id/answers";
	phone_connection = "";

	@observable sendOfficeOrder = false;
	@observable send = false;
	@observable sendFromPopupB = false;
	@observable metriksArray = [];

	constructor(rootModel) {
		this.rootModel = rootModel;
	}

	@action
	setPhone(value) {
		this.phone = value;
	}

	@action
	setFromPopupB(value) {
		this.sendFromPopupB = value;
	}

	@action
	setSend(send) {
		this.send = send;
	}
	onSubscribe(data) {
		let params = {
			email: data.email,
			group_id: 2,
		};

		if (!!data.name) {
			params.name = data.name;
		}

		LoaderData.post(this.subscribeUrl, params)
			.then((data) => {
				return data;
			})
			.catch((err) => {});
		this.rootModel.commonViewModel.changeSubscribe();
		return true;
	}

	@action
	onSubscribePopup(email) {
		let params = {
			email: email,
			group_id: 1,
		};

		if (!this.validateEmail(params.email)) {
			return false;
		}
		return LoaderData.post(this.subscribeUrl, params)
			.then((data) => {
				if (!!data.data) {
					this.rootModel.commonViewModel.changeSubscribe();
				}
				return data;
			})
			.catch((err) => {});
	}

	onSendReview(data) {
		return LoaderData.post(this.addReviewUrl, data)
			.then((answer) => {
				return answer;
			})
			.catch((err) => {});
	}

	onSendShortReview(data) {
		return LoaderData.post(this.addShortReviewUrl, data)
			.then((answer) => {
				return answer;
			})
			.catch((err) => {});
	}

	onSendFeedback(data) {
		return LoaderData.post(this.feedbackUrl, data)
			.then((answer) => {
				return answer;
			})
			.catch((err) => {});
	}

	onSendComment(id, data) {
		let addCommentUrl = pathToRegexp.compile(this.addComment);
		addCommentUrl = addCommentUrl({
			review_id: id,
		});
		data = {
			...data,
			site_code: this.rootModel.siteCode,
			region_id: this.rootModel.currentRegion.id,
		};
		return LoaderData.post(addCommentUrl, data)
			.then((answer) => {
				if (!answer.data) {
					failHandler(REQ_NAMES.ADD_COMMENT, { ...data, commentUrl: addCommentUrl }, answer);
				}
				return answer;
			})
			.catch((err) => {
				failHandler(REQ_NAMES.ADD_COMMENT, { ...data, commentUrl: addCommentUrl }, err);
			});
	}

	sendOrderMutation(data) {
		const apolloClient = this.rootModel.apolloTransportLayer;

		let url;
		if (typeof window !== "undefined") {
			url = window?.location?.href;
		}

		if (data.convergent_internet) {
			data.convergent_internet = +data.convergent_internet;
		}
		if (data.convergent_sms) {
			data.convergent_sms = +data.convergent_sms;
		}
		if (data.convergent_calls) {
			data.convergent_calls = +data.convergent_calls;
		}

		apolloClient.mutate({
			mutation: MUTATE_SEND_ORDER,
			variables: {
				...data,
				url,
			},
		});
	}

	onCallback = async (data) => {
		let phone = data.phone_connection.replace(/-/g, "");
		if (phone.length === 0) {
			return true;
		}
		if (!this.validatePhone(phone)) {
			return true;
		}

		data.phone_connection = phone;
		data.region_id = !!this?.rootModel?.currentRegion ? this.rootModel.currentRegion.id : null;

		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		const utm_campaign = Cookies.get("utm_campaign");
		const utm_medium = Cookies.get("utm_medium");
		const utm_source = Cookies.get("utm_source");
		const utm_content = Cookies.get("utm_content");
		const utm_term = Cookies.get("utm_term");

		if (utm_campaign) {
			data.utm_campaign = utm_campaign;
		}
		if (utm_medium) {
			data.utm_medium = utm_medium;
		}
		if (utm_source) {
			data.utm_source = utm_source;
		}
		if (utm_content) {
			data.utm_content = utm_content;
		}
		if (utm_term) {
			data.utm_term = utm_term;
		}

		if (!!data.house_id && data.house_id < 0) {
			delete data.house_id;
		}

		let success = false;
		let failCounter = 0;

		try {
			this.sendOrderMutation(data);
		} catch (error) {
			captureException(error, {
				tags: {
					"error.type": "order_send_log",
				},
				extra: {
					name: "sendOrderMutation. onCallback",
					order: data,
				},
				level: "error",
			});
		}

		while (!success && failCounter < 5) {
			try {
				const json = await LoaderData.post2(
					this.callbackUrl,
					data,
					this.rootModel?.apiHost,
					this.rootModel?.ordersRequestTimeout
				);

				this.rootModel.commonViewModel.changeDontShowExitPopup(true);

				if (json?.ok === 1) {
					success = true;
					this.setSend(true);
				} else {
					failCounter++;

					captureMessage("onCallback fail", {
						extra: {
							name: "onCallback",
							order: data,
							response: json,
							try: failCounter,
						},
						level: "warning",
					});
				}
			} catch (e) {
				failCounter++;

				captureException(e, {
					tags: {
						"error.type": "order_send",
					},
					extra: {
						name: "onCallback",
						order: data,
						fails: failCounter,
					},
					level: "error",
				});
			}
		}

		this.rootModel.commonViewModel.changeCallback();
		return false;
	};

	sendCallbackOne = async (data) => {
		const utm_campaign = Cookies.get("utm_campaign");
		const utm_medium = Cookies.get("utm_medium");
		const utm_source = Cookies.get("utm_source");
		const utm_content = Cookies.get("utm_content");
		const utm_term = Cookies.get("utm_term");

		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		if (utm_campaign) {
			data.utm_campaign = utm_campaign;
		}
		if (utm_medium) {
			data.utm_medium = utm_medium;
		}
		if (utm_source) {
			data.utm_source = utm_source;
		}
		if (utm_content) {
			data.utm_content = utm_content;
		}
		if (utm_term) {
			data.utm_term = utm_term;
		}
		if (!!data.house_id && data.house_id < 0) {
			delete data.house_id;
		}

		let success = false;

		try {
			this.sendOrderMutation(data);
		} catch (error) {
			captureException(error, {
				tags: {
					"error.type": "order_send_log",
				},
				extra: {
					name: "sendOrderMutation. sendCallbackOne",
					order: data,
				},
				level: "error",
			});
		}

		try {
			const json = await LoaderData.post2(
				this.callbackUrl,
				data,
				this.rootModel?.apiHost,
				this.rootModel?.ordersRequestTimeout
			);

			this.rootModel.commonViewModel.changeDontShowExitPopup(true);

			if (json?.ok === 1) {
				success = true;
			} else {
				captureMessage("sendCallbackOne fail", {
					extra: {
						name: "sendCallbackOne",
						order: data,
						response: json,
					},
					level: "warning",
				});
			}
		} catch (e) {
			captureException(e, {
				tags: {
					"error.type": "order_send",
				},
				extra: {
					name: "sendCallbackOne",
					order: data,
				},
				level: "error",
			});
		}

		return success;
	};

	async sendCallback(data, count = 5) {
		const utm_campaign = Cookies.get("utm_campaign");
		const utm_medium = Cookies.get("utm_medium");
		const utm_source = Cookies.get("utm_source");
		const utm_content = Cookies.get("utm_content");
		const utm_term = Cookies.get("utm_term");

		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		if (utm_campaign) {
			data.utm_campaign = utm_campaign;
		}
		if (utm_medium) {
			data.utm_medium = utm_medium;
		}
		if (utm_source) {
			data.utm_source = utm_source;
		}
		if (utm_content) {
			data.utm_content = utm_content;
		}
		if (utm_term) {
			data.utm_term = utm_term;
		}

		if (!!data.house_id && data.house_id < 0) {
			delete data.house_id;
		}

		let success = false;
		let failCounter = 0;

		try {
			this.sendOrderMutation(data);
		} catch (error) {
			captureException(error, {
				tags: {
					"error.type": "order_send_log",
				},
				extra: {
					name: "sendOrderMutation. sendCallback",
					order: data,
				},
				level: "error",
			});
		}

		while (!success && failCounter < count) {
			try {
				const json = await LoaderData.post2(
					this.callbackUrl,
					data,
					this.rootModel?.apiHost,
					this.rootModel?.ordersRequestTimeout
				);

				this.rootModel.commonViewModel.changeDontShowExitPopup(true);
				if (json?.ok === 1) {
					success = true;
				} else {
					failCounter++;

					captureMessage("sendCallback fail", {
						extra: {
							name: "sendCallback",
							order: data,
							response: json,
							try: failCounter,
						},
						level: "warning",
					});
				}
			} catch (e) {
				failCounter++;

				captureException(e, {
					tags: {
						"error.type": "order_send",
					},
					extra: {
						name: "sendCallback",
						order: data,
						fails: failCounter,
					},
					level: "error",
				});
			}
		}

		return success;
	}

	async onTender(data, count = 5) {
		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		let success = false;
		let failCounter = 0;

		try {
			this.sendOrderMutation(data);
		} catch (error) {
			captureException(error, {
				tags: {
					"error.type": "order_send_log",
				},
				extra: {
					name: "sendOrderMutation. onTender",
					order: data,
				},
				level: "error",
			});
		}

		while (!success && failCounter < count) {
			try {
				const json = await LoaderData.post2(
					this.orderOfficeUrl,
					data,
					this.rootModel?.apiHost,
					this.rootModel?.ordersRequestTimeout
				);

				this.rootModel.commonViewModel.changeDontShowExitPopup(true);
				this.setSend(true);
				this.sendOfficeOrder = true;

				if (json?.ok === 1) {
					success = true;
				} else {
					failCounter++;

					captureMessage("onTender fail", {
						extra: {
							name: "onTender",
							order: data,
							response: json,
							try: failCounter,
						},
						level: "warning",
					});
				}
			} catch (e) {
				failCounter++;

				captureException(e, {
					tags: {
						"error.type": "order_send",
					},
					extra: {
						name: "onTender",
						order: data,
						fails: failCounter,
					},
					level: "error",
				});
			}
		}
	}

	@action
	markReviewAsViewed(id) {
		// TODO удаление отправки о просмотрах отзывов
		// clearTimeout(this.timerForSendingViewsReviews);
		// let finded = false;
		// this.reviewsWasViewed.forEach(item => {
		//     if (item === id) finded = true;
		// });
		//
		// if (finded) return;
		// this.reviewsWasViewed.push(id);
		// let data = {
		//     value: 1
		// };
		//
		// this.timerForSendingViewsReviews = setTimeout(() => {
		//     let reviewsNotSended = this.reviewsWasViewed.filter(
		//         v => this.reviewsWasSended.indexOf(v) === -1
		//     );
		//
		//     let viewsUrl = pathToRegexp.compile(this.reviewsViewsIncrementUrl);
		//     viewsUrl = viewsUrl({
		//         id: reviewsNotSended.join(",")
		//     });
		//     this.reviewsWasSended = [
		//         ...this.reviewsWasViewed,
		//         reviewsNotSended
		//     ];
		//     LoaderData.post(viewsUrl.trim(), data)
		//         .then(json => {
		//             if (!json.data) {
		//             }
		//         })
		//         .catch(err => {});
		// }, 500);
	}

	validateEmail(email) {
		const re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	validatePhone(phone) {
		const re = /^(8|\+7|7)-?\d{3}\-?\d{3}-?\d{2}-?\d{2}$/;
		return re.test(String(phone).toLowerCase());
	}

	@action
	onMobileOrder = async (orderType, data, count = 5) => {
		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		let success = false;
		let failCounter = 0;

		try {
			this.sendOrderMutation(data);
		} catch (error) {
			captureException(error, {
				tags: {
					"error.type": "order_send_log",
				},
				extra: {
					name: "sendOrderMutation. onMobileOrder",
					order: data,
				},
				level: "error",
			});
		}

		while (!success && failCounter < count) {
			try {
				const json = await LoaderData.post2(
					`/orders?type=${orderType}`,
					data,
					this.rootModel?.apiHost,
					this.rootModel?.ordersRequestTimeout
				);

				if (json?.ok === 1) {
					success = true;
				} else {
					failCounter++;

					captureMessage("onMobileOrder fail", {
						extra: {
							name: "onMobileOrder",
							order: data,
							response: json,
							try: failCounter,
						},
						level: "warning",
					});
				}
			} catch (e) {
				failCounter++;

				captureException(e, {
					tags: {
						"error.type": "order_send",
					},
					extra: {
						name: "onMobileOrder",
						order: data,
						fails: failCounter,
					},
					level: "error",
				});
			}
		}

		return success;
	};

	// Один раз отправить заявку
	@action onMobileOrderOne = async (orderType, data) => {
		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		let success = false;
		try {
			this.sendOrderMutation(data);
		} catch (error) {
			captureException(error, {
				tags: {
					"error.type": "order_send_log",
				},
				extra: {
					name: "sendOrderMutation. onMobileOrderOne",
					order: data,
				},
				level: "error",
			});
		}

		try {
			const json = await LoaderData.post2(
				`/orders?type=${orderType}`,
				data,
				this.rootModel?.apiHost,
				this.rootModel?.ordersRequestTimeout
			);

			if (json?.ok === 1) {
				success = true;
			} else {
				captureMessage("onMobileOrderOne fail", {
					extra: {
						name: "onMobileOrderOne",
						order: data,
						response: json,
					},
					level: "warning",
				});
			}
		} catch (e) {
			captureException(e, {
				tags: {
					"error.type": "order_send",
				},
				extra: {
					name: "onMobileOrderOne",
					order: data,
				},
				level: "error",
			});
		}

		return success;
	};

	// Несколько раз отправить заявку
	@action onMobileOrderRepeat = async (orderType, data, count = 5) => {
		const uuid = Cookies.get("uuid");
		const ga_client_id = Metrika.getInstance().google.getCID();
		const ya_client_id = Metrika.getInstance().yandex.getCID();
		const ya_counter_id = Metrika.getInstance().yandex.getCounter();

		if (uuid) {
			data.uuid = uuid;
		}
		if (ga_client_id) {
			data.ga_client_id = ga_client_id;
		}
		if (ya_client_id) {
			data.ya_client_id = ya_client_id;
		}
		if (ya_counter_id) {
			data.ya_counter_id = +ya_counter_id;
		}

		let success = false;
		let failCounter = 0;

		while (!success && failCounter < count) {
			try {
				const json = await LoaderData.post2(
					`/orders?type=${orderType}`,
					data,
					this.rootModel?.apiHost,
					this.rootModel?.ordersRequestTimeout
				);

				if (json?.ok === 1) {
					success = true;
				} else {
					failCounter++;

					captureMessage("onMobileOrderRepeat fail", {
						extra: {
							name: "onMobileOrderRepeat",
							order: data,
							response: json,
							try: failCounter,
						},
						level: "warning",
					});
				}
			} catch (e) {
				failCounter++;

				captureException(e, {
					extra: {
						name: "onMobileOrderRepeat",
						order: data,
						fails: failCounter,
					},
					level: "error",
				});
			}
		}

		return success;
	};

	@action
	onSendYandexCaptcha = async (ipAddress, token) => {
		const apolloClient = this.rootModel.apolloTransportLayer;

		let query = apolloClient.query({
			query: SEND_YANDEX_CAPTCHA_QUERY,
			variables: { ipAddress, token },
		});

		return query.then((data) => {
			return data?.data?.sendYandexCaptcha?.result;
		});
	};

	@action
	sendMetriksAndSaveArray(data, id = 0, params) {
		const isMatchingElement = (elem) =>
			elem.metrics === data && (id === 0 || elem.id === id) && (!params || elem.params === params);

		if (!this.metriksArray.some(isMatchingElement)) {
			if (params) {
				sendMetriks(data, params);
				this.metriksArray.push({
					metrics: data,
					...(id && {
						id,
					}),
					...(params && { params }),
				});
			} else {
				sendMetriks(data);
				this.metriksArray.push({
					metrics: data,
					...(id && {
						id,
					}),
				});
			}
		}
	}

	@action
	sendInfoClick(info) {
		const client = this.rootModel.apolloTransportLayer;
		let variables = {
			name: info.name,
			position: info.position,
			search_url: info.searchUrl,
			uuid_user: Cookies.get("uuid") || "Не был задан uuid",
			user_agent: navigator.userAgent,
			width: info.width,
			height: info.height,
		};

		if (info.abTest) {
			variables = {
				...variables,
				experiment: info.abTest.experiment,
				variant: info.abTest.variant,
			};
		}

		client.mutate({
			mutation: MUTATE_INFO_CLICK,
			variables,
		});
	}

	@action
	sendClientPortrait(info) {
		const client = this.rootModel.apolloTransportLayer;
		const variables = {
			path: info.path,
			type: info.type,
			from: Cookies.get("connectTariffFrom"),
			search_system: info.search_system,
			uuid_user: Cookies.get("uuid"),
			user_agent: navigator.userAgent,
			width: info.width,
			height: info.height,
			region_id: info.region_id,
			region_name: info.region_name,
			region_url: info.region_url,
			ab_tests_json: info.ab_tests_json,
		};

		client.mutate({
			mutation: MUTATE_CLIENT_PORTRAIT,
			variables,
		});
	}

	@action
	sendClientAction(info) {
		const client = this.rootModel.apolloTransportLayer;
		const variables = {
			open: info.open,
			uuid: Cookies.get("uuid"),
			from: Cookies.get("connectTariffFrom"),
			monoTariffs: info.monoTariffs,
			internetTvTariffs: info.internetTvTariffs,
			convergentTariffs: info.convergentTariffs,
			tvTariffs: info.tvTariffs,
			mobileTariffs: info.mobileTariffs,
			providers: info.providers,
			search_url: info.search_url,
			click_on_page: info.click_on_page,
			hotSlot: info.hotSlot,
			availableSlot: info.availableSlot,
			unavailableSlot: info.unavailableSlot,
		};

		client.mutate({
			mutation: MUTATE_CLIENT_ACTION,
			variables,
		});
	}
}
