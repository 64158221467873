import React from "react";
import PlansBTableContainer from "Containers/PlansBTableContainer";
import Container from "reactstrap/lib/Container";
import PropTypes from "prop-types";
import { PaddingBottom } from "ComponentsUI/Spacings";

const PlanBContainer = (props) => {
	let component = (
		<Container data-test={props.dataTest} data-count={props.tariffs.length}>
			<PlansBTableContainer
				showNames={props.showNames}
				tariffs={props.tariffs}
				useYandexEvent={props.useYandexEvent}
				urlDefault={props.urlDefault}
				isTS={props.isTS}
				isRates={props.isRates}
				isExclusives={props.isExclusives}
				isReviewsProviders={props.isReviewsProviders}
				pageName={props.pageName}
				addFormOneClick={props.addFormOneClick}
				pagination={props.pagination}
				update_date={props.update_date}
				presenceBrand={props.presenceBrand}
				isProviderRatesTags={props.isProviderRatesTags}
				skipLayout={props.skipLayout}
			/>
		</Container>
	);
	return props.presenceBrand ? component : <PaddingBottom>{component}</PaddingBottom>;
};

PlanBContainer.propTypes = {
	tariffs: PropTypes.array.isRequired,
	pageName: PropTypes.string.isRequired,
	dataTest: PropTypes.string,
	urlDefault: PropTypes.string,
	isTS: PropTypes.bool,
	isRates: PropTypes.bool,
	isExclusives: PropTypes.bool,
	isProviderRatesTags: PropTypes.bool,
	isReviewsProviders: PropTypes.bool,
	useYandexEvent: PropTypes.bool,
	addFormOneClick: PropTypes.bool,
	showNames: PropTypes.bool,
};

export default PlanBContainer;
