import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";

const styles = (theme) => {
	return {
		root: {
			width: "100%",
			color: theme.palette.colors.white,
			display: "inline-block",
			verticalAlign: "top",
			height: "180px",
			borderRadius: "10px",
			backgroundImage: "linear-gradient(295deg, #fbda61, #f7671c)",
			padding: "32px 0",
			boxSizing: "border-box",
			position: "relative",
		},
		count: {
			fontFamily: theme.fonts.medium,
			fontSize: "72px",
			lineHeight: "0.94",
			textAlign: "center",
			marginBottom: "11px",
		},
		text: {
			fontSize: "16px",
			lineHeight: "1.13",
			textAlign: "center",
			padding: "0 50px",
			fontFamily: theme.fonts.light,
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			root: {
				height: "180px",
				padding: "28px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			root: {
				height: "170px",
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {},
	};
};

const CounterBlock = ({ classes, text, count, onClick }) => {
	return (
		<div className={classes.root} onClick={onClick}>
			<div className={classes.count}>{count}</div>
			<div className={classes.text}>{text}</div>
		</div>
	);
};

CounterBlock.defaultProps = {
	onClick: () => {},
};

CounterBlock.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
	count: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.node,
		PropTypes.element,
	]).isRequired,
	onClick: PropTypes.func,
};

export default withStyles(styles)(CounterBlock);
