import React from "react";
import { FooterBottomPart } from "ComponentsUIV2/Footer";
import withDefaultParams from "Services/withDefaultParams";
import useDefaultParams from "Services/useDefaultParams";
import routes from "Configs/routes";

import { pipe } from "Utils";
import { observer, inject } from "mobx-react";
import { withPhone } from "HOC/WithPhone";
import { withRouter, useParams } from "react-router-dom";
import { generateUrl } from "Services/withDefaultParams";

const FooterBottomPartContainer = (props) => {
	const {
		ApplicationModel: {
			windowSizeModel: { windowSizes },
			siteCode,
			currentRegion,
			text,
		},
		siteRegionPhones,
		match: { path },
		is404Layout,
	} = props;

	const routeParams = useParams();
	const { regionUrl } = useDefaultParams(routeParams);

	let defaultUrl = generateUrl(routes.Default.route, {
		regionUrl,
	});

	let currentDate = new Date();
	let currentYear = currentDate.getFullYear();
	let currentMonth =
		currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
	let currentDay = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate();

	const regionName = currentRegion?.name;
	const phone = siteRegionPhones?.tag_tell;

	return (
		<FooterBottomPart
			siteCode={siteCode}
			currentRegion={regionName}
			currentPhone={phone}
			currentYear={currentYear}
			currentMonth={currentMonth}
			currentDay={currentDay}
			defaultUrl={defaultUrl}
			windowSizes={windowSizes}
			is404Layout={is404Layout}
			path={path}
			text={text}
		/>
	);
};

export default pipe(
	observer,
	inject("ApplicationModel"),
	withPhone,
	withDefaultParams,
	withRouter
)(FooterBottomPartContainer);
