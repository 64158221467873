import React from "react";
import { sendMetriks } from "Services/sendMetriks";
import { useWithDefaultParams } from "Services/useWithDefaultParams";
import OrderForm from "./Component/index";

const ProvidersOrderPopup = (props) => {
	const { params } = useWithDefaultParams();

	const onClickHandler = () => {
		sendMetriks("OPEN_CONNECT_TARIFF");
		sendMetriks("OPEN_ORDER_FORM");
		let modalModel = props.modalModel;
		let promise1 = import("Layouts/modalLayout");
		promise1.then((modules) => {
			let ModalLayout = modules.default;
			modalModel.toggleModal(
				<ModalLayout
					closeModal={closeModal}
					fullCloseElement={true}
					onlyDesktopCloseElement={true}
					closeOnClickLayout={false}
					popupB={true}
					withoutClose
				>
					<OrderForm
						closeModal={closeModal}
						tariff={props.tariff}
						sendOrder={props.sendingStore.sendCallbackOne}
						sendOrderRepeat={props.sendingStore.onCallback}
						validatePhone={props.sendingStore.validatePhone}
						text={props.text}
						providerInfo={props.providerInfo}
						params={params}
					/>
				</ModalLayout>
			);
		});
	};

	const closeModal = () => {
		let modalModel = props.modalModel;
		modalModel.toggleModal();
	};

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			onClick={onClickHandler}
		>
			{props.children}
		</div>
	);
};

export default ProvidersOrderPopup;
