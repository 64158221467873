import React from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Cookies from "js-cookie";
import loadable from "@loadable/component";
import { AdFormColorLayout, Form, FormError } from "Layouts/Form";
import Button from "ComponentsUI/Button";
import BenefitForm from "ComponentsUI/Benefit/Components/BenefitForm";
import withDefaultParams, { generateUrl } from "Services/withDefaultParams";
import { Col } from "ComponentsUI/Styles/Grid";

const AddressSearchHomeContainerB = loadable(
	() => import("Containers/AddressSearchHomeContainerB")
);

var Routes = require("Configs/routes");

@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
export default class AddressAdverBlock extends React.Component {
	static defaultProps = {
		modal: false,
		tender: false,
		onHeightChangeHandler: () => {},
	};

	constructor(props) {
		super(props);

		this.state = {
			isError: false,
		};

		let text = props.ApplicationModel.text;
		this.errorTextCommon = text.ADDRESS_ERROR;
		this.errorTextWithoutHouse = text.ADDRESS_WITHOUT_HOUSE;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isError !== this.state.isError) {
			this.props.onHeightChangeHandler();
		}
	}

	onClickHandler() {
		Cookies.set("connectTariffFrom", "");
		let searcheModel = this.props.ApplicationModel.searcheModel;
		let configSites = this.props.ApplicationModel.__CONFIG_SITES;
		if (!searcheModel.checkErrors()) {
			let link = null;
			let currentAddress = searcheModel.getCurrentAddress();
			if (!currentAddress.id) {
				let addresses = searcheModel.getAddresses();
				let finded = addresses.find(
					(item) =>
						item.data.full.toLowerCase() ===
						searcheModel.searcheForm.fields.address.value.toLowerCase()
				);
				if (!!finded) {
					currentAddress = finded.data;
					searcheModel.setCurrentAddress(finded.data);
				} else {
					currentAddress.id = -1;
				}
			}

			if (currentAddress.id === -1) {
				!!this.props.openPopup && this.props.openPopup();
				return;
			}

			let commonViewModel = this.props.ApplicationModel.commonViewModel;
			commonViewModel.changeShowModalOnRates(true);
			commonViewModel.changeNameModalOnRates("quiz_search");

			if (!!this.props.providerId) {
				let providerModel = this.props.ApplicationModel.providerModel;
				providerModel.setProviderIdForOrder(this.props.providerId);
			}

			let newRegionUrl = this.props.match.params.regionUrl;
			let regions = this.props.ApplicationModel.siteConfig.regions.available;
			let region = regions.find((item) => item.id == currentAddress.region_id);
			if (region.url != newRegionUrl) {
				newRegionUrl = region.url;
				this.props.ApplicationModel.detectRegionModel.setAccept(region);
				this.props.ApplicationModel.setRegion(region);
			}
			if (!this.props.withQuiz || !configSites.QUIZ_PAGE_EXIST) {
				// Некоторые дома должны переводить на уникальный урл
				link = Boolean(currentAddress.url)
					? generateUrl(Routes.RatesInHouse.route, {
							regionUrl: newRegionUrl,
							house_url: currentAddress.url,
							house_id: currentAddress.id,
						})
					: generateUrl(Routes.DomaNzl.route, {
							regionUrl: newRegionUrl,
						});
				searcheModel.setHouseId(currentAddress.id);
			} else {
				link = generateUrl(Routes.Quiz.route, {
					regionUrl: this.props.match.params.regionUrl,
				});
			}

			// searcheModel.submit();
			this.props.history.push(link);
		} else {
			this.setState({
				isError: true,
			});
		}
	}

	disableError() {
		this.setState({
			isError: false,
		});
	}

	render() {
		const { isError } = this.state;
		let searcheModel = this.props.ApplicationModel.searcheModel;
		let text = this.props.ApplicationModel.text;
		let textError = searcheModel.checkOnUnselectedStreet()
			? this.errorTextCommon
			: this.errorTextWithoutHouse;
		return (
			<AdFormColorLayout datatest={this.props.datatest} backgroundColor={"purple"}>
				<BenefitForm
					checkout
					header={text.ADDRESS_BENEFIT_TITLE}
					text={text.ADDRESS_BENEFIT_TEXT}
					icon={
						<span
							style={{
								fontSize: "48px",
								color: "#ccff00",
							}}
							className="icon48 icon-house-search"
						/>
					}
				/>
				<Form type={"adFormColor"}>
					<AddressSearchHomeContainerB
						autoExpansion={this.props.autoExpansion}
						tender={true}
						withoutContainer={true}
						withoutButton={true}
						layoutB={true}
						error={isError}
						withoutSwitcher={true}
						onEnterHandler={() => {
							this.onClickHandler();
						}}
						onInputCallback={() => this.disableError()}
						onCallback={() => this.disableError()}
					/>
					<Col lgHidden style={{ padding: 0 }}>
						<FormError show={isError} hidden={!isError}>
							{textError}
						</FormError>
					</Col>
					<Button
						status={"checkoutAccent"}
						datatest="providers_button_verify"
						fullWidth={true}
						transparent={true}
						onClick={() => {
							this.onClickHandler();
						}}
					>
						{text.ADDRESS_BENEFIT_BUTTON}
					</Button>
				</Form>
				<Col mdHidden smHidden xsHidden style={{ padding: 0 }}>
					<FormError show={isError} hidden={!isError}>
						{textError}
					</FormError>
				</Col>
			</AdFormColorLayout>
		);
	}
}
