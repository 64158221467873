import camelCase from "jss-plugin-camel-case";
import global from "jss-plugin-global";
import nested from "jss-plugin-nested";
import sort from "jss-plugin-props-sort";
import vendorPrefixer from "jss-plugin-vendor-prefixer";
import functionRule from "jss-plugin-rule-value-function";

export const jssPreset = () => {
	const plugins = [global(), nested(), functionRule(), camelCase(), sort()];

	// Disable the vendor prefixer server-side, it does nothing
	if (typeof window !== "undefined") {
		plugins.push(vendorPrefixer());
	}

	return {
		plugins,
	};
};
