import React from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import Routes from "Configs/routes";
import { LocationBar } from "ComponentsUI/Navigation";
import { withRouter } from "react-router-dom";
import withDefaultParams from "Services/withDefaultParams";
import regionRedirectCalc from "Helpers/regionRedirect";
import Cookies from "js-cookie";
@withRouter
@withDefaultParams
@inject("ApplicationModel")
@observer
export default class SwitchCityWindowContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isMounted: false,
		};
	}

	componentDidMount() {
		this.setState({ isMounted: true });
		let currentRegion = !!this.props.ApplicationModel.currentRegion
			? this.props.ApplicationModel.currentRegion
			: {};
		let currentRegionUrl = !!this.props.match.params.regionUrl
			? this.props.match.params.regionUrl
			: {};

		if (currentRegion.url !== currentRegionUrl) {
			let regions = this.props.ApplicationModel?.siteConfig?.regions?.available;
			if (regions?.length) {
				let foundRegion = regions.find((x) => x?.url === currentRegionUrl);
				if (foundRegion) {
					this.props.ApplicationModel.setRegion(foundRegion);
				} else {
					const defaultRegion = this.props.ApplicationModel?.siteConfig?.regions?.default;
					if (defaultRegion) {
						this.props.ApplicationModel.setRegion(defaultRegion);
					}
				}
			}
		}
	}

	render() {
		let currentRegion = !!this.props.ApplicationModel.currentRegion
			? this.props.ApplicationModel.currentRegion
			: {};
		let pathname = this.props.location.pathname;
		let text = this.props.ApplicationModel.text;
		let linkObject = {
			pathname: Routes.SelectRegion.route,
		};

		let calcSearch = regionRedirectCalc(pathname);
		if (!!calcSearch && calcSearch !== "/") {
			linkObject.search = `?backUrl=${calcSearch}`;
		}
		let {
			ApplicationModel: { siteCode, regionConfirmationModel },
		} = this.props;
		let withLink = !!Object.keys(currentRegion).length;
		let isViewRegionConfirmCookie = Cookies.get("isViewRegionConfirm");
		if (isViewRegionConfirmCookie) {
			if (isViewRegionConfirmCookie == "false") {
				isViewRegionConfirmCookie = false;
			} else {
				isViewRegionConfirmCookie = true;
			}
		} else {
			isViewRegionConfirmCookie = true;
		}
		let isViewRegionConfirm = regionConfirmationModel.getConfirmView();
		let viewConfirm = isViewRegionConfirm && withLink;
		// let viewConfirm =
		//     isViewRegionConfirm &&
		//     isViewRegionConfirmCookie &&
		//     withLink &&
		//     this.state.isMounted &&
		//     siteCode == "101internet";

		return (
			<Link to={linkObject}>
				<LocationBar
					clickCallback={this.props.clickCallback}
					text={text}
					purpleTheme={this.props.purpleTheme}
					currentRegion={currentRegion}
					viewConfirm={viewConfirm}
					toggle={regionConfirmationModel.toggle}
					mobileMenu={this.props.mobileMenu}
					wrapNameRegion={this.props.wrapNameRegion}
				/>
			</Link>
		);
	}
}
