var gql = require("@apollo/client").gql;

const PROVIDERS_COUNT_QUERY = gql`
	query ProvidersCount($filter: String) {
		providers(filter: $filter, limit: 1) {
			pagination {
				items_total
			}
		}
	}
`;

export default PROVIDERS_COUNT_QUERY;
