/**
 * Класс для перемещения по массиву отображаемого в виде матрице
 */
export class MatrixWalker {
	/**
	 * @param {number} cnt ко-во элементов в массиве
	 * @param {number} columns ко-во колонн
	 * @param {number} [current=1] текущая позиция
	 */
	static create(cnt, columns, current = 1) {
		return new MatrixWalker(cnt, columns, current);
	}

	cnt = 0;
	columns = 0;
	current = 0;

	/**
	 * @param {number} cnt ко-во элементов в массиве
	 * @param {number} columns ко-во колонн
	 * @param {number} [current=1] текущая позиция
	 */
	constructor(cnt, columns, current = 1) {
		this.cnt = cnt;
		this.columns = columns;
		this.current = current;
	}

	/**
	 * @param {number} current
	 * @returns {MatrixWalker}
	 */
	setCurrent(current) {
		this.current = current;

		return this;
	}

	/**
	 * @returns {MatrixWalker}
	 */
	left() {
		if (this.current - 1 > 0) {
			this.current -= 1;
		} else {
			this.current = this.cnt;
		}

		return this;
	}

	/**
	 * @returns {MatrixWalker}
	 */
	top() {
		if (this.current - this.columns > 0) {
			this.current -= this.columns;
		} else {
			this.current = (this.getRowCnt() - 1) * this.columns + this.current;
			// если последняя строка не полная, то ограничиваемся длиной массива
			if (this.current > this.cnt) {
				this.current = this.cnt;
			}
		}

		return this;
	}

	/**
	 * @returns {MatrixWalker}
	 */
	right() {
		if (this.current + 1 <= this.cnt) {
			this.current += 1;
		} else {
			this.current = 1;
		}

		return this;
	}

	/**
	 * @returns {MatrixWalker}
	 */
	bottom() {
		if (this.current + this.columns <= this.cnt) {
			this.current += this.columns;
		} else {
			this.current -= (Math.ceil(this.current / this.columns) - 1) * this.columns;
		}

		return this;
	}

	/**
	 * @returns {number}
	 */
	getCurrent() {
		return this.current;
	}

	/**
	 * @returns {number}
	 */
	getRowCnt() {
		return Math.ceil(this.cnt / this.columns);
	}
}
