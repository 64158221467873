var gql = require("@apollo/client").gql;

const HOUSE_FULL_QUERY_V4 = gql`
	query HOUSE_FULL_QUERY_V4($id: Int!, $regionUrl: String) {
		houseFullV4(id: $id, regionUrl: $regionUrl, with_tariffs: 1) {
			address_id
			region_id
			district_id
			street_id
			house
			building
			construction
			house_full
			address_full
			popular
			url
			region
			district
			street
			socr
			district_street_id
			district_street_name
		}
	}
`;

module.exports = HOUSE_FULL_QUERY_V4;
