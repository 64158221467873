export const EVENT_CATEGORY_ORDER = "order";
export const EVENT_CATEGORY_SUBSCRIBE = "subscribe";
export const EVENT_CATEGORY_BANNER = "BANNER";
export const EVENT_CATEGORY_CALLBACK = "callback";
export const EVENT_CATEGORY_EWARRANTY = "ewarranty";
export const EVENT_CATEGORY_CLICK_BUTTON = "click_button";
export const EVENT_CATEGORY_CHOOSE_REGION = "choose_region";
export const EVENT_CATEGORY_FILTER_DATA = "filter_data";
export const EVENT_CATEGORY_SORT_DATA = "sort_data";
export const EVENT_CATEGORY_LEAD = "lead";
export const EVENT_CATEGORY_PAGE_VIEW = "page_view";
