import { observable, action } from "mobx";

export default class ErrorModel {
	@observable errorAddressInput = false;
	@observable errorCounter = 0;
	@observable
	errorAddressText = "Адрес выбран не из предложенных вариантов. Попробуйте еще раз.";
	@observable
	errorAddressStreet =
		"Что-то пошло не так… Если вашей улицы нет в списке, проверьте верно ли выбран город";
	@observable
	errorAddressHouse = "Что то пошло не так… Вы не указали номер дома";

	@observable
	commonError =
		"Похоже по клавитуре прошелся кот, или вы забыли переключить раскладку. Пожалуйта проверьте это поле еще раз и исправьте ошибки.";

	constructor(applicationModel) {
		this.rootModel = applicationModel;
	}

	@action
	checkAddressErrors() {
		let searcheModel = this.rootModel.searcheModel;
		let address = searcheModel.getCurrentAddress();
		if (!address.house.id && !searcheModel.searcheForm.fields.house.value) {
			this.errorAddressInput = true;
			this.errorCounter++;
		} else {
			this.errorAddressInput = false;
		}
		return this.errorAddressInput;
	}

	@action
	checkStreetError() {
		let searcheModel = this.rootModel.searcheModel;
		let address = searcheModel.getCurrentAddress();
		if (!this.errorAddressInput) return false;
		return !address.street.id;
	}

	@action
	checkHouseError() {
		let searcheModel = this.rootModel.searcheModel;
		let address = searcheModel.getCurrentAddress();
		if (!this.errorAddressInput) return false;
		return !address.house.id;
	}

	@action
	resetAddressError() {
		this.errorAddressInput = false;
		this.errorCounter = 0;
	}

	getErrorText() {
		if (this.errorAddressInput) {
			if (this.checkStreetError()) return this.errorAddressStreet;
			return this.errorAddressHouse;
		}
	}
}
