import React from "react";
import withStyles from "react-jss";

const styles = (theme) => ({
	root: {
		fontSize: "18px",
		fontFamily: theme.fonts.bold,
		lineHeight: 1,
		color: theme.palette.colors.darkSlateBlue2,
		letterSpacing: "-0.5px",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			display: "none",
		},
	},
});

const EmptyCell = ({ classes }) => <div className={classes.root}>-</div>;

export default withStyles(styles)(EmptyCell);
