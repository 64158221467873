import React from "react";
import withStyles from "react-jss";

const styles = (theme) => ({
	cookiePolicy: {
		position: "fixed",
		zIndex: 12101,
		bottom: "0px",
		left: "0px",
		width: "100%",
		textAlign: "center",
		pointerEvents: "none",
	},
	cookiePolicyContainer: {
		position: "relative",
		display: "inline-block",
		margin: "0px 15px 15px",
		padding: "9px 23px 9px 14px",
		fontSize: "12px",
		fontFamily: theme.fonts.light,
		borderRadius: "4px",
		backgroundColor: "rgba(255, 255, 255, 0.95)",
		textAlign: "left",
		pointerEvents: "auto",
		borderColor: theme.palette.colors.purplishBlue,
		border: "solid 1px",
	},
	textContainer: {
		display: "flex",
		alignItems: "center",
	},
	cookiePolicyLink: {
		marginRight: "15px",
		color: theme.palette.violet,
	},
	textClose: {
		display: "inline-block",
		marginLeft: "10px",
		padding: "6px 5px 4px",
		border: `1px solid ${theme.palette.colors.purplishBlue}`,
		borderRadius: "5px",
		color: theme.palette.colors.purplishBlue,
		fontSize: "14px",
		fontFamily: theme.fonts.bold,
		textTransform: "uppercase",
		cursor: "pointer",
		lineHeight: 1,
	},
	iconClose: {
		cursor: "pointer",
		display: "inline-block",
		margin: "0px",
		padding: "0px",
		position: "absolute",
		top: "9px",
		right: "7px",
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		cookiePolicy: {
			top: "65px",
		},
	},
});

const NotificationAboutCookie = ({ classes, onClose, link, text }) => {
	return (
		<div className={classes.cookiePolicy}>
			<div className={classes.cookiePolicyContainer}>
				<div className={classes.textContainer}>
					<span>
						{text.USE_COOKIE}
						<a className={classes.cookiePolicyLink} href={link}>
							{text.PROCESSING_PERSONAL_DATA}
						</a>
					</span>
					<span className={classes.textClose} onClick={onClose}>
						{text.CONFIRMATION}
					</span>
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles)(NotificationAboutCookie);
