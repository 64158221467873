import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles((theme) => {
	return {
		root: {
			width: "100%",
			backgroundColor: theme.palette.colors.orange,
			height: "50px",
			borderRadius: "10px",
			overflow: "hidden",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer",
			transition: "background-color .3s ease",
			padding: "0 13px",
		},
		iconPart: {
			lineHeight: 0,
			"& span": {
				color: theme.palette.colors.white,
				transition: "color .3s ease",
				fontSize: "24px",
			},
		},
		textPart: {
			marginLeft: "23px",
		},
		phone: {
			fontFamily: theme.fonts.bold,
			fontSize: "14px",
			letterSpacing: ".3px",
			color: theme.palette.colors.white,
			transition: "color .3s ease",
			marginBottom: "1px",
		},
		text: {
			fontFamily: theme.fonts.bold,
			fontSize: "9px",
			letterSpacing: "0.8px",
			color: theme.palette.colors.white,
			transition: "color .3s ease",
		},
		fixedTheme: {
			"& $text": {
				display: "none",
			},
		},
		passiveTheme: {
			backgroundColor: theme.palette.colors.transparent,
			"& $iconPart": {
				"& span": {
					color: theme.palette.colors.purplishBlue,
				},
			},
			"& $phone": {
				color: theme.palette.colors.purplishBlue,
			},
			"& $text": {
				color: theme.palette.colors.purplishBlue,
			},
		},
		[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
			textPart: {
				marginLeft: "10px",
			},
			text: {
				fontSize: "8px",
			},
		},
		[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
			iconPart: {
				"& span": {
					fontSize: "20px",
				},
			},
			textPart: {
				display: "none",
			},
			fixedTheme: {
				padding: "0 20px",
				"& $textPart": {
					display: "block",
					marginLeft: "15px",
				},
			},
		},
		[`@media (max-width: ${theme.media.sm})`]: {
			root: {
				height: "40px",
			},
			iconPart: {
				"& span": {
					fontSize: "20px",
				},
			},
			textPart: {
				display: "none",
			},
		},
	};
});

const PhoneButton = (props) => {
	let classes = useStyles();
	const { text, phone, insinePhone, fixedTheme, passiveTheme } = props;
	let renderPhone = insinePhone;
	if (!renderPhone) {
		renderPhone = phone;
	}

	const handleClick = (e) => {
		props.onClickPhone?.();
		props.onClick(e);
	};

	return (
		<a
			onClick={handleClick}
			aria-label="call"
			href={`tel:${renderPhone}`}
			className={classNames(classes.root, "roistat-phone-tel", {
				[classes.fixedTheme]: fixedTheme,
				[classes.passiveTheme]: passiveTheme,
			})}
		>
			<div className={classes.iconPart}>
				<span className="icon24 icon-call" />
			</div>
			<div className={classes.textPart}>
				<div className={classNames(classes.phone, "roistat-phone")}>{phone}</div>
				<div className={classes.text}>{text.CONSULTATION_FREE.toUpperCase()}</div>
			</div>
			{props.children}
		</a>
	);
};

PhoneButton.defaultProps = {
	onClick: () => {},
};

export default PhoneButton;
