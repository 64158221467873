import React, { useState, useEffect, useRef } from "react";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { sendMetriks } from "Services/sendMetriks";
import userInfo from "Services/UserInfo";
import useCurrentRegion from "Services/useCurrentRegion";
import { addAddressData, addABData, addRefererData } from "Utils";
import { FormLayout, TariffInfoV2 } from "Layouts/TariffFormLayout";
import { Form, FormError } from "Layouts/Form";
import { TextField } from "ComponentsUI/TextField";
import Button from "ComponentsUI/Button";
import TermsOfUse from "ComponentsUI/TermsOfUse";
import { CLICK_SEND_ORDER_WITH_TARIFF } from "Constants/metriks/Yandex";

const PROVIDERS = [
	{ id: 5, name: "Москва МТС", url: "https://mts-home-gpon.ru/mts?region=moskva&f=1" },
	{ id: 2782, name: "Москва Ростелеком", url: "https://rtk-ru.online/rtk?region=moskva&f=1" },
	{ id: 51, name: "Москва Билайн", url: "https://beeline-ru.online/beeline?region=moskva&f=1" },
	{ id: 2142, name: "Москва Мегафон", url: "https://mega-premium.ru/megafon?region=moskva&f=1" },
	{ id: 5100, name: "Москва Домру", url: "https://dom-provider.online/domru?region=moskva&f=1" },
	{
		id: 302,
		name: "Санкт-Петербург МТС",
		url: "https://mts-home-gpon.ru/mts?region=sankt-peterburg&f=1",
	},
	{
		id: 2126,
		name: "Санкт-Петербург Ростелеком",
		url: "https://rtk-ru.online/rtk?region=sankt-peterburg&f=2",
	},
	{
		id: 265,
		name: "Санкт-Петербург Билайн",
		url: "https://beeline-ru.online/beeline?region=sankt-peterburg&f=1",
	},
	{
		id: 2637,
		name: "Санкт-Петербург Мегафон",
		url: "https://mega-premium.ru/megafon?region=sankt-peterburg&f=1",
	},
	{
		id: 344,
		name: "Санкт-Петербург Домру",
		url: "https://dom-provider.online/domru?region=sankt-peterburg&f=2",
	},
	{ id: 2551, name: "Краснодар МТС", url: "https://mts-home-gpon.ru/mts?region=krasnodar&f=1" },
	{
		id: 2101,
		name: "Краснодар Ростелеком",
		url: "https://rtk-ru.online/rtk?region=krasnodar&f=1",
	},
	{
		id: 1013,
		name: "Краснодар Билайн",
		url: "https://beeline-ru.online/beeline?region=krasnodar&f=1",
	},
	{
		id: 2291,
		name: "Краснодар Мегафон",
		url: "https://mega-premium.ru/megafon?region=krasnodar&f=1",
	},
	{
		id: 5106,
		name: "Краснодар Домру",
		url: "https://dom-provider.online/domru?region=krasnodar&f=1",
	},
	{
		id: 621,
		name: "Екатеринбург МТС",
		url: "https://mts-home-gpon.ru/mts?region=ekaterinburg&f=1",
	},
	{
		id: 1967,
		name: "Екатеринбург Ростелеком",
		url: "https://rtk-ru.online/rtk?region=ekaterinburg&f=3",
	},
	{
		id: 647,
		name: "Екатеринбург Билайн",
		url: "https://beeline-ru.online/beeline?region=ekaterinburg&f=1",
	},
	{ id: 2323, name: "Екатеринбург Мегафон", url: undefined },
	{
		id: 607,
		name: "Екатеринбург Домру",
		url: "https://dom-provider.online/domru?region=ekaterinburg&f=1",
	},
	{
		id: 1802,
		name: "Новосибирск МТС",
		url: "https://mts-home-gpon.ru/mts?region=novosibirsk&f=1",
	},
	{
		id: 586,
		name: "Новосибирск Ростелеком",
		url: "https://rtk-ru.online/rtk?region=novosibirsk&f=5",
	},
	{ id: 2056, name: "Новосибирск Билайн", url: undefined },
	{ id: 2297, name: "Новосибирск Мегафон", url: undefined },
	{
		id: 573,
		name: "Новосибирск Домру",
		url: "https://dom-provider.online/domru?region=novosibirsk&f=2",
	},
];

function ProviderOrderPopupB(props) {
	let regionUrl = props.match.params.regionUrl;
	const wrapperRef = useRef(null);
	const regionData = useCurrentRegion(regionUrl);
	const [phoneConnection, setPhone] = useState("");
	const setEvent = props.setEvent;
	const [isError, setError] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	let providerInfo = props.providerInfo;
	const { provider } = props.tariff;
	const { contacts } = provider;
	let region = null;
	let text = props.text;
	let windowSizes = props.ApplicationModel.windowSizeModel.windowSizes;
	const popupModel = props.ApplicationModel.popupModel;
	if (!regionData.loading) {
		region = regionData.data.region;
	}

	const onChangePhoneHandler = (e) => {
		setPhone(e.target.value);
	};

	const onBlurPhone = () => {
		setError(phoneConnection.length !== 11 && phoneConnection.length !== 1);
	};

	const onFocusPhone = () => {
		setError(false);
	};

	useEffect(() => {
		if (wrapperRef.current) {
			const input =
				wrapperRef.current.querySelector("input") || wrapperRef.current.querySelector("textarea");
			if (input) {
				if (windowSizes.xs || windowSizes.sm) {
					input.scrollIntoView({ block: "center", behavior: "smooth" });
				}
			}
		}
	}, [windowSizes]);
	const onClickConnectHandler = (e) => {
		e.preventDefault();
		if (setEvent) {
			setEvent("SEND_ORDER");
		}
		if (!props.validatePhone(phoneConnection)) {
			setError(true);
			return null;
		} else {
			setSuccess(true);
			sendMetriks("SEND_CONNECT_TARIFF");
			let data = {
				fio: "",
				phone_connection: phoneConnection,
				provider_id: provider.id,
				tariff_id: props.tariff.id,
				region_id: region?.id,
				lead_form_type: userInfo(CLICK_SEND_ORDER_WITH_TARIFF),
			};

			data = addAddressData(data, props.ApplicationModel);
			data = addABData(data);
			data = addRefererData(data, props.ApplicationModel);

			let infoUser = {
				name_popup: "TariffFormContainer",
			};
			popupModel.setInfoUser(infoUser);

			props.sendOrder(data).then((dataPromise) => {
				sendMetriks("LEAD_ALL");
				if (!dataPromise) {
					props.sendOrderRepeat(data);
				}
			});
		}
	};

	let phone = "";

	if (provider) {
		if (provider.hasOwnProperty("contacts")) {
			if (provider.contacts.hasOwnProperty("phones")) {
				phone = contacts.phones.length && contacts.phones[0];
			}
		}
	} else if (providerInfo) {
		if (providerInfo.hasOwnProperty("contacts")) {
			if (providerInfo.contacts.hasOwnProperty("phones")) {
				phone = providerInfo.contacts.phones.length && providerInfo.contacts.phones[0];
			}
		}
	}
	let successButton = (
		<Button
			status={"secondaryAccent"}
			transparent={true}
			datatest={"give_feedback"}
			onClick={props.closeModal}
		>
			{text.WELCOME_CB_WIDGET_TITLE}
		</Button>
	);

	const isTrueLink = (id) => {
		return PROVIDERS.find((item) => item.id === id)?.url;
	};

	return (
		<FormLayout ref={wrapperRef}>
			<Form
				type={"popupForm"}
				isSuccess={isSuccess}
				successHeader={
					<>
						<div>{text.ORDER_SUCCESS_0}</div>
						<div>{text.ORDER_SUCCESS_4}</div>
						<div>{text.ORDER_SUCCESS_5}</div>
					</>
				}
				successButton={successButton}
			>
				{!!provider.vip_provider && (
					<TariffInfoV2
						setEvent={setEvent}
						urlLink={isTrueLink(provider.id)}
						tariff={props.tariff}
						provider={provider}
						text={text}
						phone={phone}
					/>
				)}
				<hr />
				<TextField
					autocomplete="tel"
					name="tel"
					onChange={(e) => {
						onChangePhoneHandler(e);
					}}
					onEnter={(e) => {
						onClickConnectHandler(e);
					}}
					label={text.ORDER_PHONE_PLACEHOLDER}
					placeholder={"+7"}
					datatest="popup_tariff_order_input_tel"
					inputBackgroundColor={"transparent"}
					type={"phone"}
					size={"medium"}
					onBlur={() => onBlurPhone()}
					onFocus={() => onFocusPhone()}
					autoFocus={!windowSizes.xs && !windowSizes.sm}
					isError={isError}
				/>
				<FormError
					show={isError}
					hidden={!isError}
					datatest={"popup_tariff_order_incorrect_data"}
					tariffFormContainer={true}
				>
					{text.SEND_ORDER_ERROR_1}
				</FormError>
				<Button
					status={"secondaryAccent"}
					datatest="popup_tariff_order_form_input_connect_button"
					onClick={(e) => {
						onClickConnectHandler(e);
					}}
					onKeyDown={(e) => onClickConnectHandler(e)}
					fullWidth={true}
				>
					{text.SEND_ORDER}
				</Button>
				<TermsOfUse buttonText={text.SEND_ORDER} isTariffOrderPopup={true} textAlignCenter={true} />
			</Form>
		</FormLayout>
	);
}

export default inject("ApplicationModel")(withRouter(ProviderOrderPopupB));
