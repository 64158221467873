import React, { useState } from "react";
import PropTypes from "prop-types";
import FooterTopMobileBlock from "ComponentsUIV2/Footer/FooterTopMobileBlock";

const FooterTopMobileBlockContainer = (props) => {
	const { children, windowSizes, mobileBlockTitle } = props;

	const [isOpen, setIsOpen] = useState(false);

	return (
		<FooterTopMobileBlock
			windowSizes={windowSizes}
			mobileBlockTitle={mobileBlockTitle}
			clickedBlock={() => setIsOpen(true)}
			isOpen={isOpen}
		>
			{children}
		</FooterTopMobileBlock>
	);
};

FooterTopMobileBlockContainer.propTypes = {
	children: PropTypes.node,
	windowSizes: PropTypes.object,
	mobileBlockTitle: PropTypes.string,
};

export default FooterTopMobileBlockContainer;
