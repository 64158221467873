import React from "react";
import classNames from "classnames";

export default class RightArrow extends React.Component {
	static defaultProps = {
		nextSlide: () => {},
		hideWhenEnd: false,
		currentSlide: 0,
		slideCount: 0,
		font: "icon24",
	};

	rightArrowClickHandler() {
		this.props.nextSlide();
	}

	renderElement() {
		return (
			<div
				className={this.props.className || "components__slider-button"}
				onClick={() => this.rightArrowClickHandler()}
			>
				<span className={classNames(this.props.font, "icon-arrow-1-right")} />
			</div>
		);
	}

	render() {
		if (this.props.hideWhenEnd)
			if (this.props.currentSlide != this.props.slideCount - 1) {
				return this.renderElement();
			} else {
				return null;
			}
		return this.renderElement();
	}
}
