import { observable, action, toJS } from "mobx";
import SearcheModel from "./SearcheModelB";
import SearcheModelOneString from "./SearchModelOneString";
import ModalModel from "./viewsModels/ModalModel";
import RegionConfirmationModel from "./viewsModels/RegionConfirmationModel";
import WindowSizeModel from "./viewsModels/WindowSizeModel";
import SendingStore from "./viewsModels/SendingStore";
import CompareTarifficationsModel from "./viewsModels/CompareTarifficationsModel";
import CommonViewModel from "./viewsModels/CommonViewModel";
import DetectRegionModel from "./viewsModels/DetectRegionModel";
import TenderAddresesModel from "./viewsModels/TenderAddresesModel";
import TenderAddressOneStringModel from "./viewsModels/TenderAddressOneStringModel";
import FiltersModel from "./viewsModels/FiltersModel";
import FixedPanelModel from "./viewsModels/FixedPanelModel";
import SVGInlineModel from "./viewsModels/SVGInlineModel";
import ErrorModel from "./viewsModels/ErrorModel";
import NewAuthModel from "./viewsModels/NewAuthModel";
import RoutesModel from "./RoutesModel";
import images from "../../configs/images.config";
import RatesModel from "./Rates";
import TariffsModel from "./TariffsModel";
import { setDefaultParams } from "Services/withDefaultParams";
import { setStaticLocation } from "Helpers/ImageManager";
import { ProviderModel } from "./ProviderModel";
import { OperatorModel } from "./OperatorModel";
import MobileFiltersTouchModel from "./components/MobileFiltersTouchModel";
import PopupModel from "./viewsModels/PopupModel";

export default class ApplicationModel {
	text = {};
	images = images;
	MetrikaId = null;
	siteCode = "";
	staticLocation = "";
	states = [];
	__CONFIG_SITES = {};

	defaultRegion = null;
	detectedRegion = null;
	ordersRequestTimeout = 2000;
	providerModel = new ProviderModel(this);
	operatorModel = new OperatorModel(this);
	@observable windowSizeModel = {};
	@observable searcheModel = {};
	@observable compareTarifficationsModel = {};
	@observable ratesModel = {};
	@observable tariffsModel = {};
	@observable routesModel = {};
	@observable reviewsModelBusiness = {};
	@observable commonViewModel = {};
	@observable sendingStore = {};
	@observable tenderAddresesModel = {};
	@observable tenderAddressOneStringModel = {};
	@observable filtersModel = {};
	@observable fixedPanelModel = {};
	@observable svgInlineModel = {};
	@observable isRatesFromMainPage = false;
	@observable errorModel = {};
	@observable newAuthModel = {};
	@observable regionConfirmationModel = {};
	@observable PopupModel = {};
	@observable siteConfig = null;
	@observable currentRegion = null;
	@observable viewPhone = true;
	@observable mobileFiltersTouchModel = null;
	@observable yandexCaptchaKeyClient = "";
	@observable isAntifilterTariffId = 0;
	detectRegionModel = null;
	referer = "";

	constructor(history, transportLayer, apolloTransportLayer) {
		// Общий класс для общения с API клиента и сервера
		this.transportLayer = transportLayer;
		this.apolloTransportLayer = apolloTransportLayer;
		this.searcheModel = new SearcheModel(this);
		this.searcheModelOneString = new SearcheModelOneString(this);
		this.commonViewModel = new CommonViewModel(this);
		this.sendingStore = new SendingStore(this);
		this.tariffsModel = new TariffsModel(this);
		this.ratesModel = new RatesModel(this);
		this.detectRegionModel = new DetectRegionModel(this);
		this.tenderAddresesModel = new TenderAddresesModel(this);
		this.tenderAddressOneStringModel = new TenderAddressOneStringModel(this);
		this.filtersModel = new FiltersModel(this);
		this.popupModel = new PopupModel(this);
		this.fixedPanelModel = new FixedPanelModel();
		this.svgInlineModel = new SVGInlineModel();
		this.errorModel = new ErrorModel(this);
		this.mobileFiltersTouchModel = new MobileFiltersTouchModel();
	}

	async preloadData(preloadState) {
		//TODO удалить
		// if (preloadState.data) {
		//     preloadState.data.defaultRegion = {};
		//     preloadState.data.currentRegion = {};
		// }

		if (!!preloadState.data?.currentRegion?.id) {
			this.setRegion(preloadState.data.currentRegion);
		}
		if (!!preloadState.data.defaultRegion) {
			this.defaultRegion = preloadState.data.defaultRegion;
			if (!preloadState.data.currentRegion) {
				this.setRegion(preloadState.data.defaultRegion);
			}
			setDefaultParams({
				regionUrl: this.defaultRegion.url,
			});
		}
		// if (preloadState.data.detectedRegion) {
		// }
		this.windowSizeModel = new WindowSizeModel();
		this.newAuthModel = new NewAuthModel();
		this.compareTarifficationsModel = new CompareTarifficationsModel(this);
		this.modalModel = new ModalModel();
		this.regionConfirmationModel = new RegionConfirmationModel();
		this.routesModel = new RoutesModel(this);
		if (preloadState.MetrikaId) {
			this.MetrikaId = preloadState.MetrikaId;
		}

		if (preloadState.siteCode) {
			this.siteCode = preloadState.siteCode.code;
			this.staticLocation = preloadState.siteCode.static_location;
			setStaticLocation(this.staticLocation);
			this.siteConfig = preloadState.siteCode;
			this.commonViewModel.ratesMobilePage = preloadState.siteCode.settings?.mobile_tariffs_mode
				? preloadState.siteCode.settings.mobile_tariffs_mode
				: "list";
		}

		if (preloadState.house_id) {
			const one_string_search = this.siteConfig?.settings?.one_string_search?.enabled;
			const searchModel = one_string_search ? this.searcheModelOneString : this.searcheModel;

			searchModel.setHouseId(preloadState.house_id);
		}

		if (preloadState.referer) {
			this.referer = preloadState.referer;
		}

		this.__CONFIG_SITES = preloadState.__CONFIG_SITES;
	}

	setOrdersRequestTimeout(timeout) {
		if (timeout) {
			this.ordersRequestTimeout = timeout;
		}
	}

	setYandexCaptchaClientKey(key) {
		if (key) {
			this.yandexCaptchaKeyClient = key;
		}
	}

	getSiteConfig() {
		return toJS(this.siteConfig);
	}

	preloadTexts(texts) {
		this.text = texts;
	}

	getSearcheModel() {
		return this.searcheModel;
	}

	@action
	setRegion(region) {
		//Если регионом является район
		if (region.district_region_id) {
			region.id = region.district_region_id;
		}

		this.currentRegion = region;
	}

	@action
	setViewPhone(viewPhone) {
		this.viewPhone = viewPhone;
	}

	@action
	setRatesFromMainPage(isRatesFromMainPage) {
		this.isRatesFromMainPage = isRatesFromMainPage;
	}

	@action
	setIsAntifilterTariffId(value) {
		this.isAntifilterTariffId = value;
	}
}
