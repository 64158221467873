import React from "react";
import withStyles from "react-jss";
import { TextField } from "ComponentsUI/TextField";
import Button from "ComponentsUI/Button";
import classNames from "classnames";
import Metrika from "Services/Metrika";
import { EVENT_CATEGORY_CLICK_BUTTON } from "Constants/metriks/GoogleCategorys";
import { CLICK_CALL_POPUP_TC } from "Constants/metriks/GoogleActions";
import { CDN_URL } from "Constants/CDN";

const styles = (theme) => ({
	root: {
		margin: "0 auto",
		backgroundColor: "#5813fe",
		display: "flex",
		objectFit: "contain",
		position: "relative",
		alignItems: "center",
		zIndex: 200000,
	},
	image: {},
	form: {},
	success: {},
	button: {
		backgroundImage: "linear-gradient(to top, #fad961, #f7671c)",
		borderRadius: "10px",
		height: "50px",
		"& div": {
			border: "0",
			fontSize: "16px",
			fontWeight: "bold",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: "1.13",
			letterSpacing: "2.29px",
			color: "#ffffff",
		},
	},
	header: {
		fontSize: "32px",
		fontWeight: "bold",
		lineHeight: "36px",
		textAlign: "left",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.bold,
	},
	text: {
		fontSize: "24px",
		lineHeight: "22px",
		textAlign: "left",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.light,
		marginTop: "12px",
		marginBottom: "16px",
		width: "520px",
	},
	text2: {
		fontSize: "18px",
		lineHeight: "36px",
		textAlign: "left",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.medium,
		fontWeight: "bold",
		marginTop: "25px",
		fontStretch: "normal",
		letterSpacing: "normal",
	},
	phone: {
		fontSize: "32px",
		lineHeight: "36px",
		textAlign: "left",
		color: theme.palette.colors.white,
		fontFamily: theme.fonts.bold,
		paddingBottom: "35px",
	},
	input: {
		display: "flex",
		marginTop: "30px",
	},
	textfield: {
		width: "360px",
		marginRight: "10px",
		"& input": {
			border: "1px solid white",
		},
	},
	close: {
		position: "absolute",
		width: "60px",
		height: "60px",
		cursor: "pointer",
		right: "18px",
		top: "18px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& span": {
			fontSize: "16px",
			color: theme.palette.colors.white,
		},
		border: "1px solid white",
		borderRadius: "50%",
	},

	[`@media (min-width: ${theme.media.lg})`]: {
		root: {
			width: "1440px",
			height: "900px",
			background: `linear-gradient(90deg, rgba(88,19,254,1) 35%, rgba(251,251,251,0) 75%),url('${CDN_URL}/stage-static/images/popupA/lg2.png') top right no-repeat`,
		},
		form: {
			marginLeft: "200px",
		},
		text: {
			fontSize: "22px",
			marginTop: "12px",
			lineHeight: "24px",
			marginBottom: "16px",
		},
		input: {
			marginTop: "0px",
		},
		text2: {
			fontSize: "18px",
			marginTop: "24px",
			lineHeight: "1.14",
			fontWeight: "normal",
		},
		phone: {
			fontSize: "24px",
			lineHeight: "1.14",
		},
	},
	[`@media (min-width: ${theme.media.md}) and (max-width: ${theme.media.lg})`]: {
		root: {
			width: "1200px",
			height: "900px",
			background: `linear-gradient(90deg, rgba(88,19,254,1) 25%, rgba(251,251,251,0) 75%),url('${CDN_URL}/stage-static/images/popupA/md2.png') top right no-repeat`,
		},
		header: {
			lineHeight: "1.14",
		},
		text: {
			fontSize: "20px",
			lineHeight: "1.14",
		},
		form: {
			marginLeft: "130px",
		},
		text2: {
			fontSize: "18px",
			lineHeight: "1.14",
			marginTop: "24px",
		},
	},
	[`@media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.md})`]: {
		root: {
			width: "800px",
			height: "900px",
			background: `linear-gradient(90deg, rgba(88,19,254,1) 15%, rgba(251,251,251,0) 65%),url('${CDN_URL}/stage-static/images/popupA/sm2.png') top right no-repeat`,
		},
		form: {
			marginLeft: "45px",
		},
		header: {
			fontSize: "28px",
			lineHeight: "1.14",
		},
		text: {
			fontSize: "20px",
			lineHeight: "1.14",
			width: "320px",
			marginBottom: 0,
		},
		text2: {
			fontSize: "16px",
			lineHeight: "24px",
			marginTop: "24px",
		},
		textfield: {
			width: "230px",
		},
		phone: {
			fontSize: "24px",
			lineHeight: "1.14",
		},
		input: {
			marginTop: "24px",
		},
		button: {
			"& div": {
				border: "0",
				fontSize: "14px",
				fontWeight: "bold",
				fontStretch: "normal",
				fontStyle: "normal",
				lineHeight: "1.14",
				letterSpacing: "2.29px",
			},
		},
	},
	[`@media (max-width: ${theme.media.sm})`]: {
		root: {
			width: "320px",
			minHeight: "580px",
			alignItems: "center",
			background: `linear-gradient(to top, rgba(88,19,254,1) 55%, rgba(251,251,251,0) 75%),url('${CDN_URL}/stage-static/images/popupA/xs2.png') top no-repeat`,
		},
		input: {
			display: "block",
			margin: 0,
		},
		form: {
			// backgroundColor: "#5813fe",
			padding: "0 20px",
			marginTop: "150px",
		},
		header: {
			fontSize: "20px",
			lineHeight: "24px",
			textAlign: "center",
			color: theme.palette.colors.white,
			fontFamily: theme.fonts.bold,
		},
		text: {
			width: "100%",
			fontSize: "16px",
			lineHeight: "18px",
			textAlign: "center",
			color: theme.palette.colors.white,
			fontFamily: theme.fonts.light,
			marginTop: "12px",
			marginBottom: "16px",
		},
		text2: {
			fontSize: "14px",
			lineHeight: "1.14",
			textAlign: "center",
			color: theme.palette.colors.white,
			fontFamily: theme.fonts.medium,
			fontWeight: "bold",
			margin: "20px 0 10px 0",
			fontStretch: "normal",
			letterSpacing: "normal",
			padding: "0 12px",
		},
		phone: {
			fontSize: "20px",
			lineHeight: "24px",
			textAlign: "center",
			color: theme.palette.colors.white,
			fontFamily: theme.fonts.bold,
			paddingBottom: "35px",
		},
		close: {
			width: "16px",
			height: "16px",
			border: "0",
		},
		textfield: {
			width: "100%",
			marginBottom: "6px",
			"& input": {
				border: "1px solid white",
			},
		},
		button: {
			"& div": {
				fontSize: "14px",
				fontWeight: "bold",
				fontStretch: "normal",
				fontStyle: "normal",
				lineHeight: "1.14",
				letterSpacing: "2.29px",
				color: "#ffffff",
			},
		},
		success: {
			marginBottom: "50px",
		},
	},
});

class Popup extends React.Component {
	onClickPhone = () => {
		Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_CLICK_BUTTON, CLICK_CALL_POPUP_TC);
	};
	render() {
		let classes = this.props.classes;
		let isSuccess = this.props.success;

		let clsName = classNames(classes.header, {
			[classes.success]: this.props.windowSizes.xs && isSuccess,
		});
		return (
			<div className={classes.root}>
				<div className={classes.close} onClick={this.props.closeModal}>
					<span className="icon24 icon-close" />
				</div>
				{!isSuccess ? (
					<div className={classes.form}>
						<div className={classes.header}>Оставьте телефон</div>
						<div className={classes.text}>
							Менеджер перезвонит в&nbsp;течение 5&nbsp;минут и&nbsp;поможет подключить тариф
						</div>
						<div className={classes.input}>
							<div className={classes.textfield}>
								<TextField
									autocomplete="tel"
									name="tel"
									onFocus={() => this.props.onFocus()}
									onEnter={() => this.props.onClickHandler()}
									placeholder="+7"
									type={"phone"}
									formatting={"phone"}
									onChange={(e) => this.props.onChangeInputHandler(e)}
									errorText={this.props.errorText}
									value={this.props.inputValue}
									datatest={CLICK_CALL_POPUP_TC}
									id="fix_callback_phone"
									onBlur={() => this.props.onBlur()}
								/>
							</div>
							<div className={classes.button}>
								<Button
									transparent
									fullWidth={true}
									datatest="provider_services_waitcall_button"
									onClick={() => {
										this.props.onClickHandler();
									}}
								>
									Отправить
								</Button>
							</div>
						</div>
						{/* {this.props.showPhone && (
                            <>
                                <div className={classes.text2}>Или позвоните нам прямо сейчас</div>
                                <div className={classes.phone}><PhoneContainerPopup className={classes.phone} onClick={this.onClickPhone} /></div>
                            </>)} */}
					</div>
				) : (
					<div className={classes.form}>
						{" "}
						<div className={clsName}>
							<div>Ваша заявка на подключение принята в работу.</div>
							<br />
							<div>
								Наш консультант позвонит вам в ближайшее время с номера{" "}
								<span style={{ whiteSpace: "nowrap" }}>+7 (499) 372-12-76</span> или с регионального
								номера офиса.
							</div>
							<br />
							<div>Спасибо, что доверили нам своё подключение!</div>
							<div>Мы всё сделаем в лучшем виде!</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(Popup);
